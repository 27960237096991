/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { Heading } from '../../components/Heading'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { boxShadows, colors } from '../../styles/theme'
import { getIconAndTitle } from '../../utils/helperFunctions'
import { leagueFrequencyToString } from '../League/LeaguePage'
import { HomePageLeague, LeagueDisplayType } from './queries'

export const LeagueItemSubscribe = ({
  league,
  subscribe,
  unsubscribe,
  className,
  display,
}: {
  league: HomePageLeague
  subscribe?: (e: any) => void
  unsubscribe?: (e: any) => void
  className?: string
  display?: LeagueDisplayType
}) => {
  const t = useT()

  const quizzesViewerCanStart = league.quizzes.filter((quiz) => {
    return (
      quiz.viewerCanStartQuiz || Boolean(quiz.viewerData.activeQuizInstance?.id)
    )
  })

  const { icon, title } =
    display === 'latestQuiz'
      ? getIconAndTitle(quizzesViewerCanStart[0]?.title ?? league.title)
      : getIconAndTitle(league.title)

  return (
    <Flex onClick={subscribe || unsubscribe}>
      {/* onClick on Flex is bad practice usually, but here it is fine
     since another button inside has the same onClick */}
      <Flex
        css={[
          css`
            min-width: 220px;
            max-width: 220px;
            min-height: 250px;
            text-decoration: none;
            cursor: pointer;
          `,
        ]}
        column
        className={className}
      >
        <Flex
          column
          vertical="space-between"
          css={[
            css`
              flex: 1;
              border-radius: 2px;
              box-shadow: ${boxShadows.default2};
              position: relative;
            `,
            league.leagueCardImageUrl
              ? css`
                  background: linear-gradient(
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0.2),
                      rgba(0, 0, 0, 0.5),
                      black,
                      black
                    ),
                    url(${league.leagueCardImageUrl});
                  background-size: cover;
                  background-position: center center;
                `
              : css`
                  background-color: white;
                `,
          ]}
        >
          {league.leagueCardImageUrl ? (
            <div />
          ) : (
            <Flex
              column
              css={css`
                padding: 6px 15px;
              `}
            >
              <Flex
                css={css`
                  justify-content: space-between;
                  align-items: center;
                  margin: 10px 0 20px 0;
                `}
              >
                {icon ? (
                  <p
                    css={css`
                      font-size: 40px;
                    `}
                  >
                    {icon}
                  </p>
                ) : (
                  <Logo />
                )}
                {league.frequency && (
                  <Flex css={margin.top('small')}>
                    <Chip
                      label={leagueFrequencyToString(t, league.frequency)}
                      backgroundColor={colors.grey100}
                      style={css`
                        height: 30px;
                      `}
                      color="black"
                    />
                  </Flex>
                )}
              </Flex>

              <Heading
                level={3}
                looksLikeLevel={5}
                css={css`
                  font-size: 20px;
                `}
              >
                {title}
              </Heading>
            </Flex>
          )}

          <div>
            <Flex
              vertical="flex-end"
              horizontal="space-between"
              css={[
                css`
                  padding: 0 15px;
                `,
              ]}
            >
              <Flex column>
                {league.leagueCardImageUrl && (
                  <Heading
                    level={3}
                    wide
                    color="white"
                    css={css`
                      font-size: 21px;
                      font-weight: 800;
                    `}
                  >
                    {title}
                  </Heading>
                )}
              </Flex>
            </Flex>
            {subscribe && (
              <Flex
                horizontal="center"
                css={css`
                  border-bottom-left-radius: 8px;
                  border-bottom-right-radius: 8px;
                  padding: 15px 0;
                `}
              >
                <PrimaryButton
                  css={css`
                    width: calc(100% - 30px);
                    height: 34px;
                    span {
                      font-size: 18px;
                    }
                  `}
                  variant={league.leagueCardImageUrl ? 'white' : 'black'}
                  size="small"
                  onClick={subscribe}
                >
                  {t(`Subscribe`)}
                </PrimaryButton>
              </Flex>
            )}
            {unsubscribe && (
              <Flex
                horizontal="center"
                css={css`
                  border-bottom-left-radius: 8px;
                  border-bottom-right-radius: 8px;
                  padding: 15px 0;
                `}
              >
                <PrimaryButton
                  css={css`
                    width: calc(100% - 30px);
                    height: 34px;
                    span {
                      font-size: 18px;
                    }
                  `}
                  variant={league.leagueCardImageUrl ? 'white' : 'black'}
                  size="small"
                  onClick={unsubscribe}
                >
                  {t(`Pick another quiz`)}
                </PrimaryButton>
              </Flex>
            )}
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}
