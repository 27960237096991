/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'
import { getTextColorForClub } from '../../Challenge/DisplayPage/util'
import { LiveQuizDisplayInstance } from '../../LiveQuiz/queries'
import { QuestionHeader } from './QuestionHeader'

export const ReadableDisplayLiveQuiz = ({
  instance,
  isLandscape,
}: {
  instance: LiveQuizDisplayInstance
  isLandscape: boolean
}) => {
  const club = instance.club
  const textColor = club ? getTextColorForClub(club) : colors.black

  const numCompletedQuestions = instance.completedQuestions?.length || 0

  const readableQuestion = instance.readableQuestion

  if (!readableQuestion) {
    return null
  }

  return (
    <Flex
      column
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <QuestionHeader
        club={club}
        locale={instance.locale}
        questionNumber={numCompletedQuestions + 1}
      />
      <Flex
        horizontal="center"
        vertical="center"
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: ${isLandscape ? '10vw' : '5vw'};
        `}
      >
        <TextNew
          italic
          condensed
          color={textColor}
          textAlign="center"
          css={[
            css`
              font-size: 7vw;
              font-weight: 600;
              letter-spacing: 0.07vw;
            `,
          ]}
        >
          {readableQuestion.text}
        </TextNew>
      </Flex>
    </Flex>
  )
}
