/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { colors } from '../../styles/theme'
import { LeagueItem } from '../Home/LeagueItem'
import { HomePageLeague, useLeaguesByCategoryQuery } from '../Home/queries'
import { Loader } from '../../components/Loader'
import { filterDaily } from '../../utils/util'
import { ErrorMessage } from '../../components/TextInput'

export default function LeaguesByCategory({
  selectedLeagueCategory,
}: {
  selectedLeagueCategory: string
}) {
  const { data, loading, error } = useLeaguesByCategoryQuery(
    selectedLeagueCategory
  )

  // Show recommended leagues if user has any, else show leagues by category
  const [leaguesToShow, setLeaguesToShow] = useState<HomePageLeague[]>([])

  useEffect(() => {
    if (data) {
      setLeaguesToShow(
        filterDaily(data).sort(
          (a, b) => b.quizzesPlayedLastTwoWeeks - a.quizzesPlayedLastTwoWeeks
        )
      )
    }
  }, [data])

  if (error) {
    return (
      <ErrorMessage>{t(`Please refresh the page and try again`)}</ErrorMessage>
    )
  }

  if (loading) {
    return <Loader center variant="yellow" />
  }

  if (data?.length === 0) {
    return null
  }

  return (
    <Flex
      column
      gap={'large'}
      css={css`
        height: 100%;
      `}
    >
      <Text size={'medium'} color={colors.white}>
        {t('Series')}
      </Text>
      <Flex
        column
        vertical={'center'}
        horizontal={'center'}
        gap={'large'}
        css={css`
          display: grid;
          justify-items: center;
          width: 100%;
          grid-template-columns: 1fr 1fr 1fr;
          @media (max-width: 780px) {
            grid-template-columns: 1fr 1fr;
          }
          @media (max-width: 525px) {
            grid-template-columns: 1fr;
          }
        `}
      >
        {leaguesToShow.map((league) => (
          <LeagueItem wide={true} key={league.id} league={league} />
        ))}
      </Flex>
    </Flex>
  )
}
