/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { colors } from '../styles/theme'
import { useId } from '../utils/useId'
import { Text } from './Text'
import { Flex } from './layout/Flex'

type ToggleSwitchProps = {
  value: boolean
  onValue: (value: boolean) => void
  label: string | ReactNode
  className?: string
  textColor?: string
  disabled?: boolean
}
export const ToggleSwitch = ({
  value,
  onValue,
  label,
  className,
  textColor = 'black',
  disabled = false,
}: ToggleSwitchProps) => {
  const [id] = useId()

  return (
    <label
      htmlFor={id}
      css={css`
        display: block;
        opacity: ${disabled ? 0.5 : 1};
      `}
      className={className}
      tabIndex={0}
      role="button"
      onKeyDown={(event) => {
        if (event.code === 'Space') {
          onValue(!value)
        }
      }}
    >
      <Flex vertical="center" horizontal="space-between" gap="small">
        <Text
          css={[
            css`
              line-height: 1.2;
              color: ${textColor};
            `,
          ]}
        >
          {label}
        </Text>

        <div
          css={[
            css`
              flex-shrink: 0;
              flex-grow: 0;
              width: 32px;
              height: 18px;
              border-radius: 28px;
              position: relative;
              background-color: ${colors.grey300};
              cursor: ${disabled ? 'not-allowed' : 'pointer'};
              transition: background-color 200ms ease-in-out;
            `,
            value &&
              css`
                background-color: ${colors.green300};
              `,
          ]}
        >
          <div
            css={[
              css`
                width: 12px;
                height: 12px;
                border-radius: 12px;
                position: absolute;
                left: 3px;
                top: 3px;
                background-color: ${colors.white};
                transition: left 200ms ease-in-out;
              `,
              value &&
                css`
                  left: 16px;
                `,
            ]}
          />
          <input
            id={id}
            type="checkbox"
            disabled={disabled}
            checked={value}
            onChange={(event) => onValue(event.target.checked)}
            css={css`
              margin: 0;
              height: 0;
              width: 0;
              visibility: hidden;
            `}
          />
        </div>
      </Flex>
    </label>
  )
}
