/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { Page } from '../../components/embed/Page'
import { Flex } from '../../components/layout/Flex'
import { LeagueQuiz } from '../../components/leagueQuiz/LeagueQuiz'
import { useAuthContext } from '../../lib/AuthContext'
import { margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import { useLeagueQuizInstanceQuery } from '../LeagueQuiz/queries'
import { EmbedLoadingPage } from './SubPages'
import { useShowVideoAdForClubLeagueSubscriptionQuery } from './queries'
import { useActiveCampaignForLeague } from '../../lib/sanity/sanity'

const Content = ({
  quizId,
  clubLeagueSubscriptionId,
  quizType,
}: {
  quizId: string
  clubLeagueSubscriptionId: string
  quizType?: string
}) => {
  const instanceQuery = useLeagueQuizInstanceQuery(quizId, quizType)

  const showAdQuery = useShowVideoAdForClubLeagueSubscriptionQuery(
    clubLeagueSubscriptionId
  )
  const {
    setCampaign,
    setLeagueId,
    setQuizId,
    setInstanceId,
    setClubLeagueSubscriptionId,
  } = useLeagueQuizContext()

  const { data: campaign } = useActiveCampaignForLeague(
    instanceQuery.data?.leagueQuizInstance?.leagueQuiz.league.id
  )

  useEffect(() => {
    if (instanceQuery.data?.leagueQuizInstance) {
      setLeagueId(instanceQuery.data.leagueQuizInstance.leagueQuiz.league.id)
      setQuizId(instanceQuery.data.leagueQuizInstance.leagueQuiz.id)
      setInstanceId(instanceQuery.data.leagueQuizInstance.id)
      setClubLeagueSubscriptionId(clubLeagueSubscriptionId)
    }
  }, [quizId, setQuizId, instanceQuery])

  useEffect(() => {
    if (campaign) {
      setCampaign(campaign)
    }
  }, [campaign, setCampaign])

  if (instanceQuery.loading || showAdQuery.loading) {
    return <EmbedLoadingPage />
  }

  if (
    !instanceQuery.data?.leagueQuizInstance ||
    instanceQuery.error ||
    !showAdQuery.data ||
    showAdQuery.error
  ) {
    const reason = !instanceQuery.data?.leagueQuizInstance
      ? 'No leagueQuizInstance'
      : instanceQuery.error
      ? instanceQuery.error
      : !showAdQuery.data
      ? 'No showAdQuery.data'
      : showAdQuery.error
      ? showAdQuery.error
      : 'Unknown'

    return (
      <Page colorTheme="white">
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`ClubLeagueSubQuizEmbedPage | quizId: ${quizId}, ${reason}`}
        />
      </Page>
    )
  }

  return (
    <Flex column grow>
      <QuizAd
        isDailyCareerPath={
          instanceQuery.data.leagueQuizInstance.leagueQuiz.league.slug ===
          'the-daily-career-path'
        }
        leagueId={instanceQuery.data.leagueQuizInstance.leagueQuiz.league.id}
        quizId={instanceQuery.data.leagueQuizInstance.leagueQuiz.id}
        campaign={campaign}
      />

      <LeagueQuiz
        leagueQuizInstance={instanceQuery.data.leagueQuizInstance}
        colorTheme="white"
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        isEmbed
      />
    </Flex>
  )
}

const ClubLeagueSubQuizEmbedPage = () => {
  const quizId = useParams<'id'>().id!
  const clubLeagueSubscriptionId =
    useParams<'clubLeagueSubscriptionId'>().clubLeagueSubscriptionId!
  const { loading, authUser } = useAuthContext()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const quizType = searchParams.get('quizType')

  const isCareerPath = quizType === 'careerPath'

  if (loading) {
    return <EmbedLoadingPage />
  }

  if (!loading && !authUser) {
    return (
      <Navigate
        to={{
          pathname: `/embed/club-league/${clubLeagueSubscriptionId}`,
        }}
      />
    )
  }

  if (!quizId) {
    return null
  }

  return (
    <Flex
      column
      grow
      vertical="center"
      css={css`
        background-color: ${colors.grey100};
        border-radius: 8px;
        border: 1px solid ${colors.grey200};
        height: ${isCareerPath ? 'auto' : '100vh'};
        width: 100vw;
        min-height: 550px;
        overflow: ${isCareerPath ? 'auto' : 'hidden'};
      `}
    >
      <LeagueQuizContextProvider>
        <Content
          quizId={quizId}
          clubLeagueSubscriptionId={clubLeagueSubscriptionId}
          quizType={quizType ?? 'text'}
        />
      </LeagueQuizContextProvider>
    </Flex>
  )
}
export default ClubLeagueSubQuizEmbedPage
