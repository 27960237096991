/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentPropsWithRef, forwardRef, ReactNode, useState } from 'react'
import { gap, MarginSize } from '../../styles/margin'

type FlexAlign =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch'
  | 'space-around'
  | 'space-between'
  | 'space-evenly'

export type FlexProps = {
  column?: boolean
  wrap?: boolean
  vertical?: FlexAlign
  horizontal?: FlexAlign
  grow?: boolean
  gap?: MarginSize | number
  children?: ReactNode
  className?: string
  bounce?: boolean
  columnWhenNarrow?: boolean
} & ComponentPropsWithRef<'div'>

export const Flex = forwardRef<HTMLDivElement, FlexProps>(function flex(
  {
    column,
    wrap,
    vertical,
    horizontal,
    grow,
    gap: _gap,
    children,
    className,
    bounce,
    columnWhenNarrow,
    ...rest
  },
  ref
) {
  const [prevScrollWidth, setPrevScrollWidth] = useState(-1)

  const bounceEvent = (e: any) => {
    const me = e.target
    if (prevScrollWidth != me.scrollWidth) {
      // If an container changes its size (e.x. new elements inside a scrollbox)
      // Then we would like to skip the animation
      setPrevScrollWidth(me.scrollWidth)
      return
    }
    const left = me.scrollLeft <= 0
    const right = me.scrollLeft + me.clientWidth >= me.scrollWidth
    if ((left || right) && me.clientWidth < me.scrollWidth) {
      const sign = left ? '' : '-'
      me.style.transform = `translate(${sign}50px, 0px)` // Scale up when at the boundaries
      setTimeout(() => {
        me.style.transform = 'translate(0px, 0px)'
      }, 200)
    }
  }

  return (
    <div
      ref={ref}
      css={[
        css`
          display: flex;
          flex-direction: ${column ? 'column' : 'row'};
          align-items: ${column ? horizontal : vertical};
          justify-content: ${column ? vertical : horizontal};
        `,
        wrap &&
          css`
            flex-wrap: wrap;
          `,
        grow &&
          css`
            flex-grow: 1;
          `,
        bounce &&
          css`
            transition: transform 0.2s ease; /* Smooth transition for the bounce effect */
          `,
        columnWhenNarrow &&
          css`
            flex-direction: row;
            @media (max-width: 599px) {
              width: 100%;
              align-items: center;
              flex-direction: column;
            }
          `,
        _gap &&
          (typeof _gap === 'number'
            ? css`
                gap: ${_gap}px;
              `
            : gap(_gap)),
      ]}
      className={className}
      {...rest}
      onScroll={bounce ? bounceEvent : undefined}
    >
      {children}
    </div>
  )
})
