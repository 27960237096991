/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useRecommendedLeagueQuizzes } from '../../views/Home/queries'
import { Heading } from '../Heading'
import { Loader } from '../Loader'
import { Flex } from '../layout/Flex'
import { PlayMoreQuizComponent } from './PlayMoreQuizComponent'

export const PlayMoreQuizzes = ({
  isEmbed = false,
  className,
  scrollable = true,
  title,
}: {
  isEmbed?: boolean
  className?: string
  scrollable?: boolean
  title?: string
}) => {
  const playMoreQuizzes = useRecommendedLeagueQuizzes()

  const [showSpinner, setShowSpinner] = useState(playMoreQuizzes.length === 0)

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined
    if (playMoreQuizzes.length === 0) {
      timer = setTimeout(() => {
        setShowSpinner(false)
      }, 5000)
      return () => clearTimeout(timer)
    }

    if (playMoreQuizzes.length >= 0) {
      try {
        if (timer) {
          clearTimeout(timer)
        }
      } catch (_) {}
      setShowSpinner(false)
    }
  }, [playMoreQuizzes])

  return (
    <Flex
      className={className}
      column
      css={css`
        width: 100%;
      `}
    >
      {showSpinner && playMoreQuizzes.length === 0 && (
        <Flex grow horizontal="center" css={margin.top('large')}>
          <Loader />
        </Flex>
      )}

      {title && (
        <>
          <Heading level={5} color={colors.black}>
            {t('Do you want to play more?')}
          </Heading>
          <Spacer height={16} />
        </>
      )}

      <Flex
        css={css`
          overflow-x: ${scrollable ? 'auto' : 'hidden'};
        `}
        gap="small"
      >
        {playMoreQuizzes.map((quiz) => {
          return (
            <PlayMoreQuizComponent
              key={quiz.id}
              quiz={quiz}
              isEmbed={isEmbed}
              hideShareButton
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
