/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { MdLockOutline } from 'react-icons/md'

import { useT } from '../lib/i18n/useT'
import { MaskShape } from '../lib/imageMask'
import { margin } from '../styles/margin'
import { boxShadows } from '../styles/theme'
import { Club } from '../views/Clubs/queries'
import { RightArrow, Verified } from './icons'
import { Flex } from './layout/Flex'
import { Link } from './Link'
import { ClubLogo, FramedClubLogo } from './Picture'
import { Text } from './Text'

export const ClubElement: FC<{
  club: Club
  className?: string
}> = ({ club, className }) => {
  const t = useT()

  const { backgroundColor, textColor } = club

  return (
    <Link to={`/clubs/${club.slug}`} underline={false}>
      <Flex
        css={[
          css`
            padding: 20px;
            background-color: ${backgroundColor};
            border-radius: 20px;
            box-shadow: ${boxShadows.default};
          `,
        ]}
        horizontal="space-between"
        vertical="center"
        className={className}
      >
        <Flex vertical="center" gap="medium">
          <ClubLogo size={40} url={club.logoUrl} shape={club.logoShape} />
          <Flex column>
            <Flex vertical="center" gap="small">
              <Text
                color={textColor}
                wide
                css={css`
                  text-transform: uppercase;
                  font-size: 18px;
                  line-height: 20px;
                  font-weight: 800;
                `}
              >
                {club.name}
                {club.verified && (
                  <Verified
                    height={15}
                    width={12}
                    css={css`
                      margin-left: 5px;
                    `}
                  />
                )}
                {!club.public && (
                  <MdLockOutline
                    height={15}
                    width={12}
                    color={textColor}
                    css={css`
                      margin-left: 5px;
                    `}
                  />
                )}
              </Text>
            </Flex>
            <Text size="tiny" color={textColor}>
              {t('{{count}} member', { count: club.membersCount })}
            </Text>
          </Flex>
        </Flex>
        <RightArrow color={textColor} />
      </Flex>
    </Link>
  )
}

export const HomePageClubElement = ({
  id,
  name,
  backgroundColor,
  textColor,
  membersCount,
  logoUrl,
  logoShape,
}: {
  id: string
  name: string
  backgroundColor: string
  textColor: string
  membersCount: number
  logoUrl?: string
  logoShape?: MaskShape
}) => {
  const t = useT()
  return (
    <Link to={`/clubs/${id}`} key={id} underline={false}>
      <Flex
        column
        horizontal="center"
        vertical="space-between"
        css={css`
          background-color: ${backgroundColor};
          min-width: 240px;
          max-width: 240px;
          padding: 16px;
          padding-bottom: 20px;
          border-radius: 20px;
          height: 100%;
          box-shadow: ${boxShadows.default};
        `}
      >
        <Flex column horizontal="center">
          <Text color={textColor} size="tiny">
            {t('{{count}} member', { count: membersCount })}
          </Text>

          <Text
            color={textColor}
            wide
            css={css`
              text-transform: uppercase;
              text-align: center;
              font-size: 21px;
              line-height: 25px;
              font-weight: 800;
              ${margin.vertical('small', 'medium')};
            `}
          >
            {name}
          </Text>
        </Flex>

        {logoUrl && logoShape && (
          <FramedClubLogo
            size={50}
            url={logoUrl}
            shape={logoShape}
            frameColor={textColor}
          />
        )}
      </Flex>
    </Link>
  )
}
