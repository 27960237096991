/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Button } from '../../../components/Button'
import { Chip } from '../../../components/Chip'
import { HeadingNew } from '../../../components/HeadingNew'
import { Logo } from '../../../components/icons'
import { Flex } from '../../../components/layout/Flex'
import { Link } from '../../../components/Link'
import { ClubLogo } from '../../../components/Picture'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { margin } from '../../../styles/margin'
import { buttonReset } from '../../../styles/styles'
import { boxShadows, colors } from '../../../styles/theme'
import { getIconAndTitle } from '../../../utils/helperFunctions'
import { LeagueDisplayType } from '../../Home/queries'
import { leagueFrequencyToString } from '../../League/LeaguePage'
import { ClubPageLeague } from '../queries'

const NotificationCircle = ({ number }: { number: number }) => {
  return (
    <Flex
      vertical="center"
      horizontal="center"
      css={css`
        position: absolute;
        top: -12px;
        right: -3px;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        background-color: ${colors.red400};
      `}
    >
      <TextNew
        size="small"
        color="white"
        css={css`
          margin-bottom: 4px;
        `}
      >
        {number}
      </TextNew>
    </Flex>
  )
}

export const ClubPageLeagueItem = ({
  league,
  className,
  display,
  onClick,
  selected,
  selectedColor = colors.gold,
  viewerCanEdit = false,
}: {
  league: ClubPageLeague
  className?: string
  display?: LeagueDisplayType
  onClick: () => void
  selected: boolean
  selectedColor?: string
  viewerCanEdit?: boolean
}) => {
  const t = useT()

  const quizzesViewerCanStart = league.quizzes.filter((quiz) => {
    return (
      quiz.viewerCanStartQuiz || Boolean(quiz.viewerData.activeQuizInstance?.id)
    )
  })
  const viewerCanStartQuiz = quizzesViewerCanStart.length > 0

  const { title } =
    display === 'latestQuiz'
      ? getIconAndTitle(quizzesViewerCanStart[0]?.title ?? league.title)
      : getIconAndTitle(league.title)

  const ownerClub = league.ownerClub

  return (
    <Flex column>
      <Button
        onClick={onClick}
        css={[
          buttonReset,
          css`
            min-width: 220px;
            min-height: 180px;
            max-width: 220px;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
          `,
        ]}
        className={className}
      >
        <Flex
          column
          vertical="space-between"
          css={[
            css`
              width: 100%;
              flex: 1;
              border-radius: 16px;
              box-shadow: ${boxShadows.leagueQuizItem};
              position: relative;
            `,
            league.leagueCardImageUrl
              ? css`
                  background: linear-gradient(
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0.2),
                      rgba(0, 0, 0, 0.5),
                      black,
                      black
                    ),
                    url(${league.leagueCardImageUrl});
                  background-size: cover;
                  background-position: center center;
                `
              : css`
                  background-color: white;
                `,
            selected &&
              css`
                border: 5px solid
                  ${league.leagueCardImageUrl ? colors.white : selectedColor};
              `,
          ]}
        >
          {viewerCanStartQuiz && (
            <NotificationCircle number={quizzesViewerCanStart.length} />
          )}

          {league.leagueCardImageUrl ? (
            <div />
          ) : (
            <Flex
              column
              css={css`
                padding: 6px 15px;
              `}
            >
              <Flex
                css={css`
                  justify-content: space-between;
                  margin: 10px 0 20px 0;
                  align-items: center;
                `}
              >
                {ownerClub && ownerClub.logoUrl ? (
                  <ClubLogo
                    url={ownerClub.logoUrl}
                    size={40}
                    shape={ownerClub.logoShape}
                  />
                ) : (
                  <Logo size={40} />
                )}
                {league.frequency && (
                  <Flex css={margin.top('small')}>
                    <Chip
                      label={leagueFrequencyToString(t, league.frequency)}
                      backgroundColor={colors.grey100}
                      style={css`
                        height: 30px;
                      `}
                      color="black"
                    />
                  </Flex>
                )}
              </Flex>

              <HeadingNew
                level={3}
                looksLikeLevel={5}
                italic
                uppercase
                css={css`
                  font-size: 20px;
                  font-weight: 900;
                `}
              >
                {title}
              </HeadingNew>
            </Flex>
          )}

          <div>
            <Flex
              vertical="flex-end"
              horizontal="space-between"
              css={[
                css`
                  padding: 0 15px;
                `,
                !league.leagueCardImageUrl &&
                  !viewerCanStartQuiz &&
                  css`
                    > * {
                      opacity: 0.5;
                    }
                  `,
              ]}
            >
              <Flex column>
                {league.leagueCardImageUrl && (
                  <HeadingNew
                    level={3}
                    color="white"
                    italic
                    uppercase
                    css={css`
                      font-size: 21px;
                      font-weight: 900;
                      margin-bottom: 40px;
                    `}
                  >
                    {title}
                  </HeadingNew>
                )}
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Button>
      {viewerCanEdit && (
        <Link
          to={`/series/${league.slug}?editMode=true`}
          css={css`
            margin: 0 auto;
            background-color: ${colors.white};
            color: ${colors.black};
            padding: 6px 12px;
            border-radius: 0 0 8px 8px;
            z-index: 1;
            box-shadow: ${boxShadows.default};
            opacity: 0.7;
          `}
          underline={false}
        >
          <TextNew size={'small'} italic weight={600}>
            {t('Edit')}
          </TextNew>
        </Link>
      )}
    </Flex>
  )
}
