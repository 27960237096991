import { TypePolicies } from '@apollo/client'

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      clubs: {
        keyArgs: ['input', ['query']],
        merge(existing, incoming, options) {
          const merged = existing ? existing.slice(0) : []
          for (let i = 0; i < incoming.length; ++i) {
            merged[(options?.args?.input?.offset ?? 0) + i] = incoming[i]
          }
          return merged
        },
      },
    },
  },
  Group: {
    fields: {
      members: {
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
    },
  },
  League: {
    fields: {
      leaderboard: {
        keyArgs: false,
        merge(existing = [], incoming = [], options) {
          const merged = existing ? existing.slice(0) : []
          for (let i = 0; i < incoming.length; ++i) {
            merged[(options?.args?.offset ?? 0) + i] = incoming[i]
          }
          return merged
        },
      },
      clubsLeaderboard: {
        keyArgs: false,
        merge(existing = [], incoming = [], options) {
          const merged = existing ? existing.slice(0) : []
          for (let i = 0; i < incoming.length; ++i) {
            merged[(options?.args?.offset ?? 0) + i] = incoming[i]
          }
          return merged
        },
      },
      badges: {
        keyArgs: false,
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
    },
  },
  LeagueQuiz: {
    fields: {
      leaderboard: {
        keyArgs: false,
        merge(existing = [], incoming = [], options) {
          const merged = existing ? existing.slice(0) : []
          for (let i = 0; i < incoming.length; ++i) {
            merged[(options?.args?.offset ?? 0) + i] = incoming[i]
          }
          return merged
        },
      },
    },
  },

  LiveQuizDisplayInstance: {
    fields: {
      leaderboard: {
        keyArgs: false,
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
      leaderboardCurrentQuestion: {
        keyArgs: false,
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
      participants: {
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
      completedQuestions: {
        merge(_existing = [], incoming: any[]) {
          // if incoming lenght is shorter than existing, keep existing
          if (incoming.length <= _existing.length) {
            return _existing
          }
          return incoming
        },
      },
    },
  },
  LiveQuizHostInstance: {
    fields: {
      completedQuestions: {
        merge(_existing = [], incoming: any[]) {
          // if incoming lenght is shorter than existing, keep existing
          if (incoming.length <= _existing.length) {
            return _existing
          }
          return incoming
        },
      },
    },
  },

  DbQuestion: {
    keyFields: ['id', 'locale'],
  },
  ChallengeQuiz: {
    keyFields: ['id', 'challengeGroupId'],
  },
  ChallengeGroup: {
    fields: {
      activeQuizzes: {
        merge(_existing = [], incoming: any[]) {
          return incoming
        },
      },
    },
  },
  ongoingLiveQuizzes: {
    merge(_existing = [], incoming: any[]) {
      return incoming
    },
  },
  LeagueQuizInstance: {
    fields: {
      completedQuestionsLive: {
        merge(_existing = [], incoming: any[]) {
          // if incoming lenght is shorter than existing, keep existing
          if (incoming.length <= _existing.length) {
            return _existing
          }
          return incoming
        },
      },
    },
  },
}
