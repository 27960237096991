/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { colors, hexOpacity } from '../../styles/theme'

export const GradientBackground = ({
  backgroundImagePath,
}: {
  backgroundImagePath?: string
}) => {
  const gradientCutoff = 60
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: -1;
        background-color: ${backgroundImagePath
          ? colors.black
          : colors.green500};
      `}
    >
      {backgroundImagePath && (
        <div
          css={[
            css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: ${gradientCutoff}%;

              background-image: url(${backgroundImagePath});
              background-size: cover;
              background-position: center top;

              background-repeat: no-repeat;
              z-index: -1;
            `,
          ]}
        />
      )}

      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background: linear-gradient(
            to bottom,
            transparent 0%,
            ${hexOpacity(colors.black, 1)} ${gradientCutoff}%
          );
        `}
      />
    </div>
  )
}
