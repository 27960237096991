/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { ConfirmModal } from '../../../components/ConfirmModal'
import { CroppedImageForm } from '../../../components/CroppedImageForm'
import { Heading } from '../../../components/Heading'
import { Flex } from '../../../components/layout/Flex'
import { ClubLogo } from '../../../components/Picture'
import { Text } from '../../../components/Text'
import { ErrorMessage, TextInput } from '../../../components/TextInput'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import { PROFILE_PICTURE_SIZE } from '../../../config/config'
import { useT } from '../../../lib/i18n/useT'
import { uploadImageToFirebase } from '../../../lib/uploadToStorage'
import { margin, Spacer } from '../../../styles/margin'
import { buttonReset, flexCenter } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { useForm } from '../../../utils/useForm'
import { hexColorRegex } from '../../Admin/Utils'
import { ChallengeLeaguePreviewComponent } from '../../Challenge/components/ChallengeLeaguePreviewComponent'
import { LanguagePicker } from '../ClubProfilePage/EmbedCodeModal'
import { Club, useDeleteClubMutation, useUpdateClubMutation } from '../queries'

export const validName = /^.{5,32}$/

export const ProfileSubPage = ({ club }: { club: Club }) => {
  const t = useT()
  const navigate = useNavigate()
  const [updateClub] = useUpdateClubMutation()
  const [deleteClub, { loading: deleteClubLoading }] = useDeleteClubMutation()

  const form = useForm({
    initialValues: {
      name: club.name,
      description: club.description || '',
      logo: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      logoShape: club.logoShape,
      backgroundImage: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      isPublic: club.public,
      backgroundColor: club.backgroundColor,
      textColor: club.textColor,
      locale: club.locale,
    },
    validate: {
      name: (val) =>
        validName.test(val)
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
      backgroundColor: (val) =>
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
      textColor: (val) =>
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        const [logoUri, backgroundImageUri] = await Promise.all([
          values.logo?.file
            ? uploadImageToFirebase(
                `clubs/${club.id}/logo`,
                values.logo.file
              ).getImageUri()
            : Promise.resolve(undefined),
          values.backgroundImage?.file
            ? uploadImageToFirebase(
                `clubs/${club.id}/background`,
                values.backgroundImage.file
              ).getImageUri()
            : Promise.resolve(undefined),
        ])

        const promises: Promise<any>[] = []

        promises.push(
          updateClub({
            variables: {
              input: {
                clubId: club.id,
                name: values.name,
                description: values.description,
                isPublic: values.isPublic,
                logoShape: values.logoShape,
                logoUri,
                backgroundImageUri,
                backgroundColor: values.backgroundColor,
                textColor: values.textColor,
                locale: values.locale,
              },
            },
          })
        )

        await Promise.all(promises)
        navigate(`/clubs/${club.id}`)
      } catch (error) {
        console.error('Error while saving club', error)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  return (
    <>
      <Heading
        level={2}
        looksLikeLevel={4}
        css={margin.bottom('large')}
        color="white"
      >
        {t('Edit club')}
      </Heading>

      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <CroppedImageForm
          label={t('Background image')}
          minWidth={PROFILE_PICTURE_SIZE.width}
          minHeight={PROFILE_PICTURE_SIZE.height}
          currentImage={
            club.backgroundImageUrl ? club.backgroundImageUrl : undefined
          }
          setCropped={(croppedOptions) => {
            form.setValue('backgroundImage', croppedOptions)
          }}
          textColor="white"
        />
        <Spacer height="large" />
        <CroppedImageForm
          label={t('Logo')}
          minWidth={PROFILE_PICTURE_SIZE.width}
          minHeight={PROFILE_PICTURE_SIZE.height}
          currentImage={club.logoUrl ? club.logoUrl : undefined}
          setCropped={(croppedOptions) => {
            form.setValue('logo', croppedOptions)
          }}
          maskShape={form.values.logoShape}
          textColor="white"
        />
        <Spacer height="large" />

        {club.logoUrl && (
          <>
            <Spacer height="large" />
            <Text color="white">Shape</Text>
            <Flex css={margin.top('small')}>
              <button
                onClick={(event) => {
                  event.preventDefault()
                  form.setValue('logoShape', 'circle')
                }}
                css={[
                  buttonReset,
                  css`
                    width: 60px;
                    height: 60px;
                    border-radius: 8px;
                    background-color: ${form.values.logoShape === 'circle'
                      ? colors.green300
                      : 'transparent'};
                    ${flexCenter};
                  `,
                ]}
                color="white"
              >
                <ClubLogo url={club.logoUrl} size={50} shape="circle" />
              </button>
              <Spacer width="medium" />
              <button
                onClick={(event) => {
                  event.preventDefault()
                  form.setValue('logoShape', 'shield')
                }}
                css={[
                  buttonReset,
                  css`
                    width: 60px;
                    height: 60px;
                    border-radius: 8px;
                    background-color: ${form.values.logoShape === 'shield'
                      ? colors.green300
                      : 'transparent'};
                    ${flexCenter};
                  `,
                ]}
                color="white"
              >
                <ClubLogo url={club.logoUrl} size={50} shape="shield" />
              </button>
              <Spacer width="medium" />
              <button
                onClick={(event) => {
                  event.preventDefault()
                  form.setValue('logoShape', 'uncropped')
                }}
                css={[
                  buttonReset,
                  css`
                    width: 60px;
                    height: 60px;
                    border-radius: 8px;
                    background-color: ${form.values.logoShape === 'uncropped'
                      ? colors.green300
                      : 'transparent'};
                    ${flexCenter};
                  `,
                ]}
                color="white"
              >
                <ClubLogo url={club.logoUrl} size={50} shape="uncropped" />
              </button>
            </Flex>
          </>
        )}
        <TextInput
          label={t('Club name')}
          value={form.values.name}
          onValue={(val) => form.setValue('name', val)}
          disabled={form.submitting || !club.viewerCanEdit}
          css={css`
            flex: 1;
            ${margin.top('large')}
          `}
          error={form.submitAttempted && form.fieldErrors.name}
          textColor="white"
        />

        <TextInput
          label={t('Club description')}
          value={form.values.description}
          onValue={(val) => form.setValue('description', val)}
          disabled={form.submitting || !club.viewerCanEdit}
          css={css`
            flex: 1;
            ${margin.top('large')}
          `}
          error={form.submitAttempted && form.fieldErrors.description}
          textColor="white"
        />

        {club.verified && (
          <>
            <Flex css={[margin.top('large')]} column>
              <Text css={margin.bottom('tiny')} color={colors.white}>
                {t(`Club language`)}
              </Text>

              <LanguagePicker
                locale={form.values.locale}
                onLocale={(value) => {
                  form.setValue('locale', value)
                }}
              />
            </Flex>

            <Flex vertical="flex-end" horizontal="space-between">
              <TextInput
                id="backgroundColor"
                name="backgroundColor"
                label={
                  <Text css={margin.bottom('tiny')} color={colors.white}>
                    {t(`Background color`)}
                  </Text>
                }
                placeholder={t('Background color')}
                autoComplete="off"
                value={form.values.backgroundColor}
                maxLength={7}
                onValue={(value) => {
                  form.setValue('backgroundColor', value.trim().toUpperCase())
                }}
                error={form.submitAttempted && form.fieldErrors.backgroundColor}
                css={margin.top('medium')}
              />
              <input
                aria-label={t('Background color')}
                css={css`
                  height: 48px;
                  width: 48px;
                  margin-bottom: 8px;
                `}
                type="color"
                value={form.values.backgroundColor}
                onChange={(e) =>
                  form.setValue('backgroundColor', e.target.value)
                }
              />
            </Flex>
            <Flex
              vertical="center"
              horizontal="space-between"
              css={margin.top('medium')}
            >
              <Text css={margin.bottom('tiny')} color={colors.white}>
                {t('Text color')}
              </Text>
              <Flex vertical="center">
                <label htmlFor="black">
                  <Text css={margin.bottom('tiny')} color={colors.white}>
                    {t('Black')}
                  </Text>
                </label>

                <input
                  id="black"
                  type="radio"
                  name="textColor"
                  value="black"
                  checked={
                    form.values.textColor.toLowerCase() ===
                    colors.black.toLowerCase()
                  }
                  onChange={() => form.setValue('textColor', colors.black)}
                  css={css`
                    height: 40px;
                    width: 40px;
                  `}
                />
                <Spacer width={16} />
                <label htmlFor="white">
                  <Text css={margin.bottom('tiny')} color={colors.white}>
                    {t('White')}
                  </Text>
                </label>
                <input
                  id="white"
                  type="radio"
                  name="textColor"
                  value="black"
                  checked={
                    form.values.textColor.toLowerCase() ===
                    colors.white.toLowerCase()
                  }
                  onChange={() => form.setValue('textColor', colors.white)}
                  css={css`
                    height: 40px;
                    width: 40px;
                  `}
                />
              </Flex>
            </Flex>

            <ChallengeLeaguePreviewComponent
              title={form.values.name}
              backgroundColor={form.values.backgroundColor}
              textColor={form.values.textColor}
              css={margin.top('large')}
            />
          </>
        )}
        {!club.verified && (
          <ToggleSwitch
            value={form.values.isPublic}
            onValue={(val) => form.setValue('isPublic', val)}
            label={t('Public group')}
            css={margin.top('large')}
            textColor="white"
          />
        )}

        <Flex
          horizontal="space-between"
          vertical="center"
          css={margin.top('huge')}
          gap="medium"
        >
          <SecondaryButton
            type="button"
            onClick={() => {
              navigate(`/clubs/${club.id}`)
            }}
            variant="white"
          >
            {t('Cancel')}
          </SecondaryButton>

          <Flex column horizontal="flex-end">
            {form.submitError && (
              <ErrorMessage css={margin.bottom()}>
                {form.submitError}
              </ErrorMessage>
            )}
            <PrimaryButton
              type="submit"
              disabled={form.submitting}
              variant="white"
            >
              {t('Save')}
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>

      {club.viewerRole === 'admin' && (
        <Flex column horizontal="flex-start" css={margin.top('huge')}>
          <Heading level={3} looksLikeLevel={5} color="white">
            {t('Delete club')}
          </Heading>
          <Text color="white">
            {t('Do you want to delete {{name}}?', { name: club.name })}
          </Text>
          <Spacer height="small" />
          <ConfirmModal
            button={({ openModal }) => (
              <SecondaryButton
                variant="dangerRed"
                onClick={openModal}
                loading={deleteClubLoading}
                noWrap
              >
                {t('Delete club')}
              </SecondaryButton>
            )}
            onConfirm={() => {
              deleteClub({ variables: { clubId: club.id } }).then(() => {
                navigate('/clubs')
              })
            }}
            loading={deleteClubLoading}
          />
        </Flex>
      )}
    </>
  )
}
