/** @jsxImportSource @emotion/react */

import { useParams } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { Loader } from '../../components/Loader'
import { margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useTrainingQuizInstanceQuery } from './queries'
import { TrainingQuiz } from './TrainingQuiz'

const Content = ({ trainingQuizId }: { trainingQuizId: string }) => {
  const query = useTrainingQuizInstanceQuery(trainingQuizId)

  if (query.loading) {
    return (
      <Page>
        <Flex horizontal="center" css={margin.top('huge')}>
          <Loader variant="yellow" />
        </Flex>
      </Page>
    )
  }
  if (!query.data || !query.data.trainingQuizInstance || query.error) {
    const reason = !query.data?.trainingQuizInstance
      ? 'no instance'
      : query.error?.message

    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`TrainingQuizPage | trainingQuizId: ${trainingQuizId}, ${reason}`}
        />
      </Page>
    )
  }

  return <TrainingQuiz trainingQuizInstance={query.data.trainingQuizInstance} />
}

export const TrainingQuizPage = () => {
  const trainingQuizId = useParams<{ trainingQuizId: string }>().trainingQuizId!

  return (
    <Layout hideNavbar backgroundColor={colors.yellow400}>
      <Content trainingQuizId={trainingQuizId} />
    </Layout>
  )
}
