/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useState } from 'react'
import theme from '../../styles/theme'

import { useInterval } from '../../utils/useInterval'

import { fadeIn } from '../../styles/animations'

import Lottie from 'lottie-react'
import { Flex } from '../../components/layout/Flex'
import { Loader } from '../../components/Loader'
import {
  QuizState,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'

import { PoweredByFcQuiz } from '../../components/icons'
import countdownAnimation from '../../components/leagueQuiz/assets/lottie/countdown.json'
import { CHALLENGE_HEADER_HEIGHT } from './ChallengeQuizHeader'

export const ChallengeQuizCountdown: FC<{
  nextQuestion: () => void
  colorTheme: 'red' | 'white' | 'green'

  nextQuestionLoading: boolean
}> = ({ nextQuestion, colorTheme, nextQuestionLoading }) => {
  const backgroundColor =
    colorTheme === 'red'
      ? theme.colors.red500
      : colorTheme === 'green'
      ? theme.colors.green600
      : theme.colors.grey100

  const [remaining, setRemaining] = useState(2)
  const { setQuizState, campaignHeaderHeight } = useLeagueQuizContext()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 ? null : 1000
  )

  if (nextQuestionLoading) {
    return (
      <Flex
        column
        grow
        horizontal="center"
        css={css`
          padding: 16px;
          opacity: 0;
          animation: ${fadeIn} 2s ease-in-out;
          animation-delay: 1s;
          animation-fill-mode: forwards;
        `}
      >
        <Loader />
      </Flex>
    )
  }

  return (
    <Flex
      column
      horizontal="center"
      vertical="space-between"
      grow
      css={[
        css`
          background-color: ${backgroundColor};
          min-width: 300px;
          padding: 16px;
          height: calc(
            100vh - ${campaignHeaderHeight} - ${CHALLENGE_HEADER_HEIGHT}
          );
          padding-bottom: 20px;
          position: relative;
        `,
      ]}
    >
      <Flex
        column
        grow
        horizontal="center"
        vertical="center"
        css={css`
          max-width: 390px;
          margin: 0 auto;
          animation: ${fadeIn} 350ms ease-in-out;
          animation-fill-mode: forwards;
        `}
      >
        <Lottie
          animationData={countdownAnimation}
          loop={false}
          onComplete={() => {
            setQuizState(QuizState.ACTIVE_QUIZ)
            nextQuestion()
          }}
          style={{
            width: '120%',
          }}
        />
      </Flex>

      <PoweredByFcQuiz />
    </Flex>
  )
}
