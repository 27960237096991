/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FcQuizQrCode } from '../../../components/QrCodeComponent'
import { ShareLinkComponent } from '../../../components/ShareLinkComponent'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { rootWebAppUrl } from '../../../config/config'
import { Spacer } from '../../../styles/margin'
import { ChallengeLeague } from '../queries'
import { ChallengeLeaguePreviewComponent } from './ChallengeLeaguePreviewComponent'

export const ShareLeagueModal = ({
  league,
  closeModal,
}: {
  league: ChallengeLeague
  closeModal: () => void
}) => {
  const leagueUrl = `${rootWebAppUrl()}/league/${league.slug}`
  return (
    <Modal closeModal={closeModal}>
      <Flex
        column
        css={css`
          padding: 24px;
          max-width: 400px;
          margin: 0 auto;
        `}
      >
        <ChallengeLeaguePreviewComponent
          title={league.title}
          backgroundColor={league.backgroundColor}
          textColor={league.textColor}
        />
        <Spacer height={24} />

        <FcQuizQrCode
          url={leagueUrl}
          backgroundColor={league.backgroundColor}
          foregroundColor={league.textColor}
        />
        <Spacer height={24} />
        <ShareLinkComponent url={leagueUrl} />
        <Spacer height={40} />
      </Flex>
    </Modal>
  )
}
