/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { SecondaryButton } from '../../components/Button'
import { GroupInvitationModal } from '../../components/ChallengeInviteModal'
import { DefaultError } from '../../components/DefaultError'
import { Header } from '../../components/Header'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { ModalTrigger } from '../../components/modal/Modal'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { LeagueQuiz } from '../LeagueQuiz/queries'
import {
  ChallengeScoreboard,
  UserWithResult,
} from './components/ChallengeScoreboard'
import { LeagueQuizCard } from './components/LeagueQuizCard'
import {
  ChallengeGroup,
  useChallengeGroupQuery,
  useVersusInvitationQuery,
} from './queries'

export const ChallengeGroupQuizResults = ({
  group,
  quiz,
}: {
  group: ChallengeGroup
  quiz: LeagueQuiz
}) => {
  const t = useT()

  const results = quiz.challengeGroupResults ?? []

  const { data: invitationData } = useVersusInvitationQuery(group.id, quiz.id)

  const invitationId = invitationData?.group.versusInvitation

  const mapped: UserWithResult[] = results
    .map((result) => {
      const member = group.members.find((member) => member.id === result.userId)
      if (!member) {
        return undefined
      }
      return {
        userId: result.userId,
        displayName: member.displayName,
        avatarData: member.avatarData,
        result: {
          correctAnswersCount: result.correctAnswersCount ?? 0,
          correctAnswerScore: result.correctAnswerScore ?? 0,
          timeBonus: result.timeBonus ?? 0,
          totalScore: result.totalScore ?? 0,
        },
      } as UserWithResult
    })
    .filter((result) => result !== undefined) as UserWithResult[]

  return (
    <Flex key={quiz.id} column gap="small">
      <Spacer height={32} />
      {group.viewerIsOwner && invitationId && (
        <Flex horizontal="flex-end">
          <ModalTrigger
            button={({ openModal }) => (
              <SecondaryButton
                onClick={openModal}
                css={css`
                  height: 48px;
                `}
              >
                <Text strong condensed size="large">
                  {t('Invite')}
                </Text>
              </SecondaryButton>
            )}
            modal={({ closeModal }) => (
              <GroupInvitationModal
                closeModal={closeModal}
                invitationId={invitationId}
              />
            )}
          />
        </Flex>
      )}
      <LeagueQuizCard quiz={quiz} />

      <Spacer height={32} />
      <ChallengeScoreboard results={mapped} hideHeader />
    </Flex>
  )
}

const ContentLoader = ({
  groupId,
  quizId,
}: {
  groupId: string
  quizId: string
}) => {
  const { data: groupData, loading } = useChallengeGroupQuery(groupId)

  if (loading) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Loader />
      </Flex>
    )
  }

  if (!groupData || !groupData.group) {
    const reason = !groupData
      ? 'no group data'
      : !groupData.group
      ? 'no group'
      : 'unknown'
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeGroupQuizResultPage | groupId: ${groupId}, reason: ${reason}`}
      />
    )
  }

  const group = groupData.group

  const quiz = group.quizzes.find((quiz) => quiz.id === quizId)

  if (!quiz) {
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeGroupQuizResultPage | groupId: ${groupId}, quizId: ${quizId}, reason: quiz not found`}
      />
    )
  }

  return <ChallengeGroupQuizResults group={group} quiz={quiz} />
}

export const ChallengeGroupQuizResultPage = () => {
  const { groupId, quizId } = useParams()

  if (!groupId || !quizId) {
    const reason = !groupId ? 'no groupId' : !quizId ? 'no quizId' : 'unknown'
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeGroupQuizResultPage | groupId: ${groupId}, quizId: ${quizId}, reason: ${reason}`}
      />
    )
  }

  return (
    <Layout backgroundColor={colors.grey100}>
      <Header color="grey" />
      <Page dense>
        <ContentLoader groupId={groupId} quizId={quizId} />
      </Page>
    </Layout>
  )
}
