/** @jsxImportSource @emotion/react */

import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../Button'
import { useT } from '../../lib/i18n/useT'
import { css } from '@emotion/react'
import { colors } from '../../styles/theme'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'

export const ChallengeButton = ({ instanceId }: { instanceId: string }) => {
  const navigate = useNavigate()
  const t = useT()

  return (
    <PrimaryButton
      onClick={() => {
        navigate(`/challenge/${instanceId}`)
      }}
      css={css`
        height: 60px;
        margin: 0 16px;
        background-color: ${colors.purple500};
      `}
    >
      <Flex horizontal="center" gap="small" vertical="center">
        <Text size="huge">💥</Text>
        <Text
          strong
          condensed
          css={css`
            color: ${colors.white};
            font-size: 24px;
            line-height: 33px;
          `}
        >
          {t('Challenge a friend')}
        </Text>
        <Text size="huge">💥</Text>
      </Flex>
    </PrimaryButton>
  )
}
