import { OperationVariables, QueryResult } from '@apollo/client'
import { isProduction } from '../config/config'
import { LeagueQuiz } from '../views/LeagueQuiz/queries'
import { Viewer } from '../lib/AuthContext'
import { Locale } from '../lib/i18n/locale'

export const titleFromQuiz = (leagueQuiz: LeagueQuiz, t: any) =>
  leagueQuiz.league.slug === 'the-daily' ? t('The Daily') : leagueQuiz.title

// Retries the timeout every 10ms until it succeeds
export const setTimeoutRetry = (
  predicate: () => boolean,
  action: (delayedMs: number) => void,
  maxDurationMs: number,
  _currentMs: number = 0 // Don't fill in this
) => {
  if (maxDurationMs <= 0) return
  setTimeout(
    () =>
      predicate()
        ? action(_currentMs)
        : setTimeoutRetry(
            predicate,
            action,
            maxDurationMs - 10,
            _currentMs + 10
          ),
    10
  )
}

// For testing purposes
export function times<T>(list: T[], amount: number) {
  if (isProduction) return list
  return [...new Array(amount)].flatMap((_) => [...list])
}

export function containsJapanese(text: string): boolean {
  // This regex matches any character in the Hiragana, Katakana, Common Kanji,
  // and Full-width Roman characters ranges, along with the less common Kanji characters.
  // Adjust the ranges if you need to be more or less inclusive.
  const japaneseRegex =
    /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF\uFF00-\uFFEF\u3400-\u4DBF]+/g
  return japaneseRegex.test(text)
}

export function filterDaily<T>(list: T[]) {
  return list.filter((it: any) => it.slug !== 'the-daily')
}

export function inLocales(
  viewerQuery: QueryResult<
    {
      viewer?: Viewer | undefined
    },
    OperationVariables
  >,
  locales: Locale[]
) {
  const myLocale = viewerQuery?.data?.viewer?.locale
  if (!myLocale) return false
  return locales.includes(myLocale)
}
