/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { useAuthContext, useViewerQuery } from '../lib/AuthContext'
import { getCurrentLocale, useSetLocale } from '../lib/i18n/locale'
import { useT } from '../lib/i18n/useT'
import { gap } from '../styles/margin'
import { colors } from '../styles/theme'
import { UserAvatarMe } from '../views/Avatar/UserAvatar'
import { LocaleForm } from '../views/EditProfile/EditProfilePage'
import { PrimaryButton } from './Button'
import { Link } from './Link'
import { FcQuizLogo } from './icons'
import { Flex } from './layout/Flex'
import { PageContent } from './layout/PageContent'
import { LoginModalInner } from './modal/LoginModalInner'
import { ModalTrigger } from './modal/Modal'

const HomeLink: FC = ({ children }) => {
  const t = useT()
  return (
    <Link to="/" underline={false} aria-label={t('Home')}>
      {children}
    </Link>
  )
}

export const Header = ({
  color = 'white',
  hideSignupButton = false,
  hideAvatar = false,
  headerForegroundColor,
  className,
}: {
  headerForegroundColor?: string
  color?: 'white' | 'green' | 'grey'
  hideSignupButton?: boolean
  hideAvatar?: boolean
  className?: string
}) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  const viewerQuery = useViewerQuery({ skip: !authenticated })
  const isAdmin = viewerQuery.data?.viewer?.isAdmin
  const locale = getCurrentLocale()
  const setLocale = useSetLocale()

  // const { isNativeApp, openNativeLoginModal } = useNativeApp()

  // const showNativeLogin = isNativeApp && !isProduction

  const foregroundColor = headerForegroundColor
    ? headerForegroundColor
    : color === 'white'
    ? colors.white
    : color === 'green'
    ? colors.green500
    : colors.grey400

  return (
    <header className={className}>
      <Flex
        vertical="center"
        css={css`
          padding-top: 50px;
          padding-bottom: 50px;
        `}
      >
        <PageContent>
          {isAdmin && (
            <Link
              to="/admin"
              color={foregroundColor}
              css={css`
                position: absolute;
                left: 50%;
                transform: translateX(-50%) translateY(calc(-100% - 15px));
              `}
            >
              {t('Admin')}
            </Link>
          )}
          <Flex horizontal="space-between" vertical="center">
            <HomeLink>
              <FcQuizLogo width={92} height={20} color={foregroundColor} />
            </HomeLink>

            {authenticated && !authUser?.isAnonymous ? (
              <div
                css={css`
                  position: relative;
                `}
              >
                {!hideAvatar && (
                  <Link to="/profile" aria-label={t('Profile')}>
                    {viewerQuery.data?.viewer && (
                      <Flex
                        css={css`
                          width: 60px;
                          height: 60px;
                          background-color: #1a1a1a;
                          border: 2px solid #8e8e8e;
                          border-left-color: #716d6d;
                          border-right-color: #716d6d;
                          border-radius: 10px;
                          opacity: 1;
                        `}
                        horizontal="center"
                        vertical="flex-end"
                      >
                        <UserAvatarMe height={80} />
                      </Flex>
                    )}
                  </Link>
                )}
              </div>
            ) : !authenticated || authUser?.isAnonymous ? (
              <Flex vertical="center" css={gap()}>
                <LocaleForm
                  locale={locale}
                  onLocale={(locale) => setLocale(locale)}
                  hideLabel
                  onlyFlag
                  textColor={headerForegroundColor}
                />
                {!hideSignupButton && (
                  <ModalTrigger
                    button={({ openModal }) => (
                      <PrimaryButton
                        variant="white"
                        css={css`
                          border: 1px solid ${foregroundColor};
                        `}
                        onClick={
                          // disable signup button for native apps
                          // showNativeLogin ? openNativeLoginModal :
                          openModal
                        }
                      >
                        {t('Sign up')}
                      </PrimaryButton>
                    )}
                    modal={({ closeModal }) => (
                      <LoginModalInner closeModal={closeModal} signupFirst />
                    )}
                  />
                )}
              </Flex>
            ) : (
              <div
                css={css`
                  width: 40px;
                  height: 40px;
                  border-radius: 40px;
                  background-color: ${foregroundColor};
                `}
              />
            )}
          </Flex>
        </PageContent>
      </Flex>
    </header>
  )
}
