/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, FC } from 'react'
import { margin } from '../styles/margin'
import { FormParameters, useForm } from '../utils/useForm'
import { PrimaryButton } from './Button'
import { Flex } from './layout/Flex'
import { Text } from './Text'
import { ErrorMessage, TextInput } from './TextInput'

type Props = {
  label: string
  formParameters: FormParameters<{ value: string }>
  className?: string
  submitText: string
  successText: string
  placeholder?: string
} & ComponentPropsWithoutRef<'input'>

export const TextInputForm: FC<Props> = ({
  label,
  formParameters,
  className,
  submitText,
  placeholder,
  successText,
  ...rest
}) => {
  const form = useForm(formParameters)

  const error =
    form.submitError ?? (form.submitAttempted && form.fieldErrors.value)

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          form.submitForm().then(() => {
            form.clearSubmitAttempt()
            form.setValue('value', '')
          })
        }}
        className={className}
      >
        <Flex gap="medium">
          <TextInput
            label={label}
            placeholder={placeholder}
            onValue={(value) => form.setValue('value', value)}
            value={form.values.value}
            css={css`
              flex-grow: 1;
            `}
            {...rest}
          />
          <Flex vertical="flex-end">
            <PrimaryButton
              type="submit"
              disabled={form.submitting}
              css={css`
                height: 38px;
              `}
            >
              {submitText}
            </PrimaryButton>
          </Flex>
        </Flex>
      </form>
      {error && <ErrorMessage css={margin.top('tiny')}>{error}</ErrorMessage>}
      {!error && form.submitSuccess && (
        <Text strong css={margin.top('tiny')}>
          {successText}
        </Text>
      )}
    </>
  )
}
