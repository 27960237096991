import { DependencyList, useEffect, useState } from 'react'

export const useWindowSize = (deps?: DependencyList) => {
  const [size, setSize] = useState({ width: 0, height: 0, loaded: false })

  useEffect(() => {
    const updateSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        loaded: true,
      })
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? [])])

  return size
}
