import * as React from 'react'

import { Brows } from './brows/_index'
import { Eyes } from './eyes/_index'
import { Mouth } from './mouth/_index'
import { Collar } from './collar/_index'

export default class Face extends React.Component {
  render() {
    return (
      <g>
        <Mouth />
        <Eyes />
        <Brows />
        <Collar />
      </g>
    )
  }
}
