import { LocaleString } from "./i18n/locale"

export type MaybePromise<T> = T | Promise<T>

export type Category = {
  _id: string
  name: LocaleString
}

export type Alternative = {
  _key: string
  content: string
  correct: boolean
}

export type Question = {
  _id: string
  question: string
  alternatives: Alternative[]
  category: {
    _ref: string
  }
  difficulty: 'easy' | 'medium' | 'hard'
}

export enum LeagueStatus {
  upcoming = 'upcoming',
  active = 'active',
  completed = 'completed',
}

export enum LeagueQuizStatus {
  upcoming = 'upcoming',
  active = 'active',
  completed = 'completed',
}
