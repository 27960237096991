/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'

export const ChallengeLeaguePreviewComponent = ({
  title,
  backgroundColor,
  textColor,
  className,
}: {
  title?: string
  backgroundColor: string
  textColor: string
  className?: string
}) => {
  const t = useT()

  title = title?.trim() === '' ? undefined : title
  return (
    <Flex
      horizontal="center"
      className={className}
      css={css`
        padding: 24px;
        background-color: ${backgroundColor};
        box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        border: 1px solid ${textColor};
      `}
    >
      <TextNew
        condensed
        shadow
        italic
        strong
        css={css`
          font-size: 32px;
          line-height: 1;
          color: ${textColor};
        `}
      >
        {title ?? t('League title')}
      </TextNew>
    </Flex>
  )
}
