import { StringMap, TOptions } from 'i18next'
import { groupBy, uniq } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isDevelopment } from '../../config/config'

import arCommonTranslations from './locales/ar/common.json'
import deCommonTranslations from './locales/de/common.json'
import enCommonTranslations from './locales/en/common.json'
import esCommonTranslations from './locales/es/common.json'
import frCommonTranslations from './locales/fr/common.json'
import itCommonTranslations from './locales/it/common.json'
import jaCommonTranslations from './locales/ja/common.json'
import noCommonTranslations from './locales/no/common.json'
import ptCommonTranslations from './locales/pt/common.json'

const translations = {
  arCommonTranslations,
  deCommonTranslations,
  enCommonTranslations,
  esCommonTranslations,
  frCommonTranslations,
  itCommonTranslations,
  jaCommonTranslations,
  noCommonTranslations,
  ptCommonTranslations,
}

type TranslationKeys = keyof typeof enCommonTranslations

export type TFunction = (
  key: TranslationKeys,
  options?: TOptions<StringMap> | string
) => string

export const useT = (language?: string) => {
  const { t } = useTranslation()

  const typedT: TFunction = React.useCallback(
    (key: TranslationKeys, options?: TOptions<StringMap> | string) => {
      if (isDevelopment) warnMissingKey(key)

      return t(key, options)
    },
    [t]
  )

  const typedTWithLng = (
    key: TranslationKeys,
    options?: TOptions<StringMap> | string
  ) => {
    if (typeof options === 'string') {
      return typedT(key, options) // Shouldn't occur, but here is a fallback atleast
    }
    return typedT(key, { ...options, lng: language })
  }
  return typedTWithLng
}

function warnMissingKey(key: string) {
  Object.entries(translations).map(([lng, translations]) => {
    if (!Object.keys(translations).includes(key)) {
      missing.push({ lng, key })
      printMissing()
    }
  })
}

let missing: Array<{ lng: string; key: string }> = []
let timeout: ReturnType<typeof setTimeout> | null = null
function printMissing() {
  if (timeout) clearTimeout(timeout)

  timeout = setTimeout(() => {
    Object.entries(groupBy(missing, 'lng')).map(([lng, missing]) => {
      const keys = uniq(missing.map(({ key }) => key))
      console.groupCollapsed(`Missing keys in ${lng} (${keys.length})`)
      console.log(keys)
      console.groupEnd()
    })

    missing = []
  }, 3000)
}
