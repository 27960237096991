/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { PrimaryButton } from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import {
  challengeGroupQuery,
  ChallengeLeague,
  useLeaveChallengeGroupMutation,
} from '../queries'
import { ChallengeLeaguePreviewComponent } from './ChallengeLeaguePreviewComponent'

export const LeaveChallengeLeagueModal = ({
  league,
  closeModal,
}: {
  league: ChallengeLeague
  closeModal: () => void
}) => {
  const [leaveGroup, { loading }] = useLeaveChallengeGroupMutation()
  const t = useT()
  const apolloClient = useApolloClient()

  return (
    <Modal closeModal={closeModal}>
      <Flex column>
        <ChallengeLeaguePreviewComponent
          title={league.title}
          backgroundColor={league.backgroundColor}
          textColor={league.textColor}
        />
        <Spacer height={40} />

        {loading && <Loader center />}

        {league.viewerIsOwner && (
          <TextNew>{t(`League owners can't leave the league.`)}</TextNew>
        )}

        {!loading && !league.viewerIsOwner && (
          <>
            <TextNew>
              {t(`Do you want to leave {{leagueTitle}}?`, {
                leagueTitle: league.title,
              })}
            </TextNew>
            <Spacer height={8} />
            <PrimaryButton
              css={css`
                padding: 8px 0;
              `}
              onClick={async () => {
                await leaveGroup({
                  variables: {
                    groupId: league.id,
                  },
                })
                await apolloClient.refetchQueries({
                  include: [challengeGroupQuery],
                })
                closeModal()
              }}
            >
              <TextNew
                condensed
                strong
                color={colors.white}
                css={css`
                  font-size: 24px;
                `}
              >
                {t(`Leave League`)}
              </TextNew>
            </PrimaryButton>
          </>
        )}
      </Flex>
    </Modal>
  )
}
