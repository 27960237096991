/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillEdit } from 'react-icons/ai'
import { PrimaryButton } from '../../components/Button'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import CareerPath, {
  CareerPathEntry,
} from '../../components/careerPath/CareerPath'
import { Flex } from '../../components/layout/Flex'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { getCountryFlag } from '../../utils/nation'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { EditCareerPathComponent } from './EditCareerPath'
import { CareerPathQuestion } from './GenerateQuestion'
import {
  CareerPathTeam,
  CareerPathTransfer,
  useGetAuthenticTeamsByNameQuery,
  useGetCareerPathQuestionQuery,
  useGetPlayerByNameQuery,
  useGetTransferHistoryQuery,
} from './queries'

interface CareerPathQuestionFormProps {
  setCareerPathPlayer: (value: CareerPathQuestion) => void
  careerPathQuestion?: CareerPathQuestion
}

export const CareerPathQuestionForm: React.FC<CareerPathQuestionFormProps> = (
  props
) => {
  const { t } = useTranslation()
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })
  const [selectedPlayer, setSelectedPlayer] =
    useState<CareerPathQuestion | null>(null)

  const [predefinedPlayer, setPredefinedPlayer] = useState<
    CareerPathQuestion | undefined
  >(props.careerPathQuestion)

  const queryResult = useGetPlayerByNameQuery(debouncedSearchQuery)
  const careerPathQuestionQuery =
    useGetCareerPathQuestionQuery(selectedPlayerId)
  const getTransferHistory = useGetTransferHistoryQuery(
    selectedPlayer?.id ?? ''
  )
  const [fetchedTransfers, setFetchedTransfers] = useState<
    CareerPathTransfer[] | undefined
  >(undefined)

  const [editCareerPath, setEditCareerPath] = useState(false)
  const [editPlayerMetaData, setEditPlayerMetaData] = useState(false)

  useEffect(() => {
    if (props.careerPathQuestion && !predefinedPlayer) {
      setPredefinedPlayer(props.careerPathQuestion)
    }
  }, [props.careerPathQuestion])

  useEffect(() => {
    if (careerPathQuestionQuery.data?.getCareerPathQuestion?.careerPathPlayer) {
      setPredefinedPlayer(careerPathQuestionQuery.data?.getCareerPathQuestion)
      props.setCareerPathPlayer(
        careerPathQuestionQuery.data?.getCareerPathQuestion
      )
    }
  }, [careerPathQuestionQuery.data])

  useEffect(() => {
    if (
      selectedPlayer &&
      !predefinedPlayer &&
      selectedPlayer.careerPathPlayer.careerPath &&
      selectedPlayer.careerPathPlayer.careerPath?.length > 0
    ) {
      props.setCareerPathPlayer(selectedPlayer)
    }
  }, [selectedPlayer])

  useEffect(() => {
    if (getTransferHistory.data?.getTransferHistory) {
      setFetchedTransfers(getTransferHistory.data.getTransferHistory)
      if (
        predefinedPlayer &&
        predefinedPlayer.careerPathPlayer.careerPath.length <= 0
      ) {
        setPredefinedPlayer({
          ...predefinedPlayer,
          careerPathPlayer: {
            ...predefinedPlayer.careerPathPlayer,
            careerPath: getCareerPath(
              getTransferHistory.data.getTransferHistory
            ),
          },
        })
        props.setCareerPathPlayer({
          ...predefinedPlayer,
          careerPathPlayer: {
            ...predefinedPlayer.careerPathPlayer,
            careerPath: getCareerPath(
              getTransferHistory.data.getTransferHistory
            ),
          },
        })
      }
    }
  }, [getTransferHistory.data, predefinedPlayer])

  const saveEditCareerPath = (careerPathPlayer: CareerPathQuestion) => {
    props.setCareerPathPlayer(careerPathPlayer)
    setPredefinedPlayer(careerPathPlayer)
    setEditCareerPath(false)
  }

  const saveEditPlayerMetaData = (player: CareerPathQuestion) => {
    props.setCareerPathPlayer(player)
    setPredefinedPlayer(player)
    setEditPlayerMetaData(false)
  }

  if (careerPathQuestionQuery.loading || getTransferHistory.loading) {
    return <div>Loading...</div>
  }

  // If a player is already selected, show the player's career path
  if (predefinedPlayer) {
    return (
      <Flex column>
        {editCareerPath ? (
          <EditCareerPathComponent
            careerPathPlayer={{
              ...predefinedPlayer,
              careerPathPlayer: {
                playerName: predefinedPlayer.careerPathPlayer.playerName,
                firstName: predefinedPlayer.careerPathPlayer.firstName,
                lastName: predefinedPlayer.careerPathPlayer.lastName,
                photoUrl: predefinedPlayer.careerPathPlayer.photoUrl,
                team: predefinedPlayer.careerPathPlayer.team,
                careerPath:
                  predefinedPlayer?.careerPathPlayer.careerPath?.map(
                    (transfer) => ({
                      team: {
                        id: transfer.team.id,
                        name: transfer.team.name,
                        logoUrl: transfer.team.logoUrl,
                      },
                      year: transfer.year,
                      type: transfer.type,
                    })
                  ) ?? [],
                nationality: predefinedPlayer.careerPathPlayer.nationality,
                position: predefinedPlayer.careerPathPlayer.position,
              },
            }}
            saveCareerPath={saveEditCareerPath}
          />
        ) : (
          <Flex column>
            <TextInput
              value={searchQuery}
              placeholder={'Search for player'}
              onValue={() => {
                setSearchQuery
                setPredefinedPlayer(undefined)
              }}
            />

            <Spacer height={'medium'} />
            <Flex
              css={css`
                padding: 8px;
                border: 1px solid ${colors.grey200};
                border-radius: 5px;
                background-color: ${colors.white};
              `}
              horizontal={'center'}
            >
              <Flex horizontal={'center'} column gap={'small'}>
                {editPlayerMetaData ? (
                  <EditPlayerMetaData
                    player={predefinedPlayer as CareerPathQuestion}
                    setPlayerData={saveEditPlayerMetaData}
                  />
                ) : (
                  <PlayerCard
                    playerPhoto={predefinedPlayer.careerPathPlayer.photoUrl}
                    clubLogo={predefinedPlayer.careerPathPlayer.team.logoUrl}
                    playerName={predefinedPlayer.careerPathPlayer.playerName}
                    flag={getCountryFlag(
                      predefinedPlayer.careerPathPlayer.nationality
                    )}
                    leagueLogo={
                      predefinedPlayer.careerPathPlayer.team.league?.logoUrl
                    }
                    edit={setEditPlayerMetaData}
                  />
                )}
                <Spacer height={'medium'} />
                <Flex horizontal={'center'} column>
                  <Flex
                    column={false}
                    gap={'small'}
                    vertical={'center'}
                    horizontal={'center'}
                  >
                    <Text size={'large'}>{t(`Career path`)}</Text>

                    <Flex
                      css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: ${colors.black};
                        padding: 3px;
                        border-radius: 20%;
                        cursor: pointer;
                        &:hover {
                          background-color: ${colors.grey400};
                        }
                      `}
                      onClick={() => setEditCareerPath(true)}
                    >
                      {' '}
                      <AiFillEdit size={16} color={colors.white} />
                    </Flex>
                  </Flex>
                  <CareerPath
                    careerPath={
                      predefinedPlayer.careerPathPlayer.careerPath.length > 0
                        ? predefinedPlayer.careerPathPlayer.careerPath
                        : fetchedTransfers
                        ? getCareerPath(fetchedTransfers)
                        : []
                    }
                    showYear={true}
                  />
                  <Spacer height={'medium'} />
                  <Text size={'small'} textAlign={'center'}>
                    *The first year is most likely not correct when auto
                    generated
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    )
  } else {
    return (
      <Flex column>
        {editCareerPath && selectedPlayer ? (
          <EditCareerPathComponent
            careerPathPlayer={{
              ...selectedPlayer,
              careerPathPlayer: {
                playerName: selectedPlayer.careerPathPlayer.playerName,
                firstName: selectedPlayer.careerPathPlayer.firstName,
                lastName: selectedPlayer.careerPathPlayer.lastName,
                photoUrl: selectedPlayer.careerPathPlayer.photoUrl,
                team: selectedPlayer.careerPathPlayer.team,
                careerPath:
                  selectedPlayer?.careerPathPlayer.careerPath?.map(
                    (transfer) => ({
                      team: {
                        id: transfer.team.id,
                        name: transfer.team.name,
                        logoUrl: transfer.team.logoUrl,
                      },
                      year: transfer.year,
                      type: transfer.type,
                    })
                  ) ?? [],
                nationality: selectedPlayer.careerPathPlayer.nationality,
                position: selectedPlayer.careerPathPlayer.position,
              },
            }}
            saveCareerPath={saveEditCareerPath}
          />
        ) : (
          <Flex column>
            <TextInput
              value={searchQuery}
              placeholder={'Search for player'}
              onValue={setSearchQuery}
            />

            <Spacer height={'medium'} />

            {queryResult.loading && <div>Loading...</div>}
            {queryResult.error && <div>Error: {queryResult.error.message}</div>}
            {queryResult.data && (
              <Flex column gap={'small'}>
                {queryResult.data.careerPathPlayersByName.map((player) => (
                  <Flex
                    key={player.id}
                    gap={'small'}
                    vertical={'center'}
                    css={css`
                      padding: 6px 12px;
                      border: 1px solid ${colors.grey400};
                      border-radius: 5px;
                      background-color: ${colors.white};

                      &:hover {
                        background-color: ${colors.grey200};
                      }
                    `}
                    onClick={() => {
                      setSelectedPlayerId(player.id)
                      setSelectedPlayer({
                        ...player,
                        careerPathPlayer: {
                          ...player.careerPathPlayer,
                          careerPath: getCareerPath(
                            getTransferHistory.data?.getTransferHistory ?? []
                          ),
                        },
                      })
                      setSearchQuery('')
                    }}
                  >
                    <img
                      width={32}
                      height={32}
                      src={player.careerPathPlayer.photoUrl}
                      css={css`
                        border-radius: 50%;
                        overflow: hidden;
                      `}
                    />

                    <Text>{player.careerPathPlayer.playerName}</Text>
                  </Flex>
                ))}
              </Flex>
            )}

            {selectedPlayer?.careerPathPlayer.careerPath &&
              selectedPlayer?.careerPathPlayer.careerPath.length > 0 && (
                <Flex
                  css={css`
                    padding: 8px;
                    border: 1px solid ${colors.grey200};
                    border-radius: 5px;
                    background-color: ${colors.white};
                  `}
                  horizontal={'center'}
                >
                  <Flex horizontal={'center'} column gap={'small'}>
                    <Flex
                      css={css`
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                      `}
                      onClick={() => setEditCareerPath(true)}
                    >
                      <Flex
                        css={css`
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          background-color: ${colors.black};
                          padding: 4px;
                          border-radius: 20%;
                        `}
                      >
                        {' '}
                        <AiFillEdit color={colors.white} />
                      </Flex>
                    </Flex>
                    <PlayerCard
                      playerPhoto={selectedPlayer.careerPathPlayer.photoUrl}
                      clubLogo={selectedPlayer.careerPathPlayer.team.logoUrl}
                      playerName={selectedPlayer.careerPathPlayer.playerName}
                      flag={getCountryFlag(
                        selectedPlayer.careerPathPlayer.nationality
                      )}
                      leagueLogo={
                        selectedPlayer.careerPathPlayer.team.league?.logoUrl
                      }
                    />

                    <Spacer height={'medium'} />
                    {selectedPlayer.careerPathPlayer.careerPath && (
                      <Flex horizontal={'center'} column>
                        <Text size={'large'}>{t(`Career Path`)}</Text>
                        <CareerPath
                          key={selectedPlayer.id}
                          careerPath={
                            selectedPlayer.careerPathPlayer.careerPath.length >
                            0
                              ? selectedPlayer.careerPathPlayer.careerPath
                              : fetchedTransfers
                              ? getCareerPath(fetchedTransfers)
                              : []
                          }
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              )}
          </Flex>
        )}
      </Flex>
    )
  }
}

export const PlayerCard: React.FC<{
  playerPhoto: string
  clubLogo: string
  playerName: string
  flag: string
  leagueLogo?: string
  edit?: (editPlayerMetaData: boolean) => void
}> = ({ playerPhoto, clubLogo, playerName, flag, leagueLogo, edit }) => {
  return (
    <Flex column horizontal={'center'} gap={'small'}>
      <Flex
        column
        css={css`
          position: relative;
        `}
      >
        <img
          width={52}
          height={52}
          src={playerPhoto}
          css={css`
            border-radius: 50%;
            overflow: hidden;
          `}
        />
        {edit && (
          <Flex
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${colors.black};
              padding: 3px;
              border-radius: 50%;
              cursor: pointer;
              z-index: 1;
              position: absolute;
              top: -3px;
              right: -3px;
              // Hover
              &:hover {
                background-color: ${colors.grey400};
              }
            `}
            onClick={() => edit(true)}
          >
            {' '}
            <AiFillEdit size={16} color={colors.white} />
          </Flex>
        )}
      </Flex>

      <Text>{playerName}</Text>
      <Flex gap={'medium'}>
        <img width={32} height={32} src={flag} />
        <img height={32} src={leagueLogo} />
        <img height={32} src={clubLogo} />
      </Flex>
    </Flex>
  )
}

function getCareerPath(transfers: CareerPathTransfer[]): CareerPathEntry[] {
  const careerPath: CareerPathEntry[] = []

  if (transfers.length === 0) {
    return careerPath
  }

  const sortedTransfers = [...transfers].sort((a, b) => {
    // Sort by year, then by month, date format is 'YYYY-MM-DD'
    const dateA = new Date(a.transferDate)
    const dateB = new Date(b.transferDate)

    return dateA.getTime() - dateB.getTime()
  })

  careerPath.push({
    team: {
      id: sortedTransfers[0].fromTeam.id,
      name: sortedTransfers[0].fromTeam.name,
      logoUrl: sortedTransfers[0].fromTeam.logoUrl,
    },

    year: sortedTransfers[0].transferDate.split('-')[0],
    type: sortedTransfers[0].transferType,
  })

  sortedTransfers.forEach((transfer) => {
    careerPath.push({
      team: {
        id: transfer.toTeam.id,
        name: transfer.toTeam.name,
        logoUrl: transfer.toTeam.logoUrl,
      },

      year: transfer.transferDate.split('-')[0],
      type: transfer.transferType,
    })
  })

  return careerPath
}

const EditPlayerMetaData: React.FC<{
  player: CareerPathQuestion
  setPlayerData: (player: CareerPathQuestion) => void
}> = ({ player, setPlayerData }) => {
  const { t } = useTranslation()

  const [name, setName] = useState(player.careerPathPlayer.playerName)
  const [team, setTeam] = useState<CareerPathTeam | null>(
    player.careerPathPlayer.team
  )
  const [searchQuery, setSearchQuery] = useState(team?.name || '')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })
  const queryResult = useGetAuthenticTeamsByNameQuery(
    debouncedSearchQuery != team?.name ? debouncedSearchQuery : ''
  )

  console.log('player', player)

  return (
    <Flex column gap={'small'} horizontal={'center'}>
      <img
        width={52}
        height={52}
        src={player.careerPathPlayer.photoUrl}
        css={css`
          border-radius: 50%;
          overflow: hidden;
        `}
      />
      <Text>{t('Name')}</Text>
      <TextInput
        css={css`
          border: 1px solid ${colors.grey200};
          border-radius: 5px;
        `}
        value={name}
        onValue={setName}
      />
      <Text>{t(`Team`)}</Text>
      <TextInput
        css={css`
          border: 1px solid ${colors.grey200};
          border-radius: 5px;
        `}
        value={searchQuery}
        onValue={setSearchQuery}
      />
      {queryResult.loading && <div>Loading...</div>}
      {queryResult.error && <div>Error: {queryResult.error.message}</div>}
      {queryResult.data && queryResult.data.getTeamsByName && (
        <Flex column gap={'small'}>
          {queryResult.data.getTeamsByName.map((team) => (
            <Flex
              key={team.id}
              gap={'small'}
              vertical={'center'}
              css={css`
                padding: 6px 12px;
                border: 1px solid ${colors.grey400};
                border-radius: 5px;
                background-color: ${colors.white};

                &:hover {
                  background-color: ${colors.grey200};
                }
              `}
              onClick={() => {
                setTeam(team)
                setSearchQuery(team.name)
              }}
            >
              <img
                width={28}
                height={28}
                src={team.logoUrl || ''}
                css={css`
                  border-radius: 50%;
                  overflow: hidden;
                `}
              />

              <Text size={'small'}>{team.name}</Text>
            </Flex>
          ))}
        </Flex>
      )}
      <PrimaryButton
        onClick={() =>
          setPlayerData({
            ...player,
            careerPathPlayer: {
              ...player.careerPathPlayer,
              playerName: name,
              team: team ?? player.careerPathPlayer.team,
            },
          })
        }
      >
        {t('Save')}
      </PrimaryButton>
    </Flex>
  )
}
