import { ReactComponent as Shoulders0 } from './Shoulders 0.svg'
import { ReactComponent as ShouldersA } from './Shoulders A.svg'
import { ReactComponent as ShouldersC } from './Shoulders C.svg'
import { ReactComponent as ShouldersD } from './Shoulders D.svg'
import { ReactComponent as ShouldersE } from './Shoulders E.svg'
import { ReactComponent as ShouldersF } from './Shoulders F.svg'
import { ReactComponent as ShouldersG } from './Shoulders G.svg'

export const shouldersStyles = {
  Shoulders0: {
    icon: <Shoulders0 />,
    usedColors: 0,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ShouldersA: {
    icon: <ShouldersA />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ShouldersC: {
    icon: <ShouldersC />,
    usedColors: 2,
    width: 15,
    centerIndex: 0,
    rotation: 15 + 45,
    offset: 0,
    premium: false,
  },
  ShouldersD: {
    icon: <ShouldersD />,
    usedColors: 2,
    width: 20,
    centerIndex: undefined,
    rotation: 15 - 45,
    offset: 3,
    premium: false,
  },
  ShouldersE: {
    icon: <ShouldersE />,
    usedColors: 2,
    width: 11,
    centerIndex: 1,
    rotation: 15 - 45,
    offset: 0,
    premium: false,
  },
  ShouldersF: {
    icon: <ShouldersF />,
    usedColors: 2,
    width: 50,
    centerIndex: 1,
    rotation: 15 - 45,
    offset: 17.5,
    premium: false,
  },
  ShouldersG: {
    icon: <ShouldersG />,
    usedColors: 2,
    width: 30,
    centerIndex: 1,
    rotation: 15 - 45 + 90,
    offset: 2.5,
    premium: false,
  },
}
