/** @jsxImportSource @emotion/react */

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import 'react'
import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { isDevelopment, isProduction } from './config/config'
import {
  AuthContextProvider,
  useAuthContext,
  useViewerQuery,
} from './lib/AuthContext'
import { NotificationProvider } from './lib/NotificationContext'
import { analytics, AnalyticsEventType, trackEvent } from './lib/analytics'
import { apolloClient } from './lib/apollo/apollo'
import { setCurrentLocale, useCurrentLocale } from './lib/i18n/locale'
import { NativeAppProvider, useNativeApp } from './lib/native/NativeAppContext'
import { router } from './router'
import { ThirdPartyScrips } from './utils/thirdPartyScripts'

const EnsureCorrectLocale = () => {
  const { authUser } = useAuthContext()
  const viewerLocale = useViewerQuery({ skip: !authUser }).data?.viewer?.locale
  const currentLocale = useCurrentLocale()
  analytics.setLocale(currentLocale)

  useEffect(() => {
    if (viewerLocale && currentLocale !== viewerLocale) {
      setCurrentLocale(viewerLocale)
    }
  }, [currentLocale, viewerLocale, router])

  return null
}

const InitSentry = () => {
  const { isNativeApp } = useNativeApp()

  useEffect(() => {
    if (isDevelopment) {
      console.log('Sentry is not initialized in development')
      return
    }

    Sentry.init({
      dsn: 'https://2524aea59619a913b3b4eab698b4e544@o4506534548930560.ingest.sentry.io/4506534743834624',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost'],
        }),
      ],
      environment: isNativeApp
        ? 'native-app'
        : isProduction
        ? 'production'
        : 'test',
      // Performance Monitoring
      sampleRate: 0.1, //10% of events are sent to Sentry
    })
  }, [])
  return null
}

export const TrackReferrer = () => {
  // check if current path includes /embed to avoid tracking referrer for embeds
  const { isNativeApp } = useNativeApp()

  const currentPath = window.location.pathname

  try {
    useEffect(() => {
      if (isNativeApp) {
        return
      }

      if (currentPath.includes('/embed')) {
        return
      }

      if (document.referrer) {
        // handle referrer from our own embeds
        if (document.referrer.includes('fcquiz.app')) {
          const embedIdentifier = 'fcquiz.app/embed/club-league-quiz/'
          if (document.referrer.includes(embedIdentifier)) {
            const clubSubscriptionId = document.referrer
              .split(embedIdentifier)[1]
              .split('/')[0]

            trackEvent(AnalyticsEventType.Refferer, {
              referrer: clubSubscriptionId,
            })
          }
          return
        }

        // external referrer
        trackEvent(AnalyticsEventType.Refferer, {
          referrer: document.referrer,
        })
      }
    }, [])
  } catch (error) {
    if (!isProduction) {
      console.error(error)
    }
  }

  return null
}

export const App = () => {
  return (
    <>
      <AuthContextProvider>
        <ApolloProvider client={apolloClient}>
          <EnsureCorrectLocale />
          <NativeAppProvider>
            <NotificationProvider>
              <ThirdPartyScrips />
              <TrackReferrer />
              <InitSentry />
              <RouterProvider router={router} />
            </NotificationProvider>
          </NativeAppProvider>
        </ApolloProvider>
      </AuthContextProvider>
    </>
  )
}
