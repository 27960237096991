/** @jsxImportSource @emotion/react */

import { useCallback } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import {
  LiveQuizHostInstance,
  useHostActivateQuestionMutation,
} from '../queries'
import { HostModeHeader } from './HostModeHeader'

export const ReadableQuestionLiveQuiz = ({
  instance,
}: {
  instance: LiveQuizHostInstance
}) => {
  const [activateQuestionMutation] = useHostActivateQuestionMutation()

  const activateQuestion = useCallback(
    () =>
      activateQuestionMutation({
        variables: {
          input: { instanceId: instance.id },
        },
      }),
    [instance.id, activateQuestionMutation]
  )

  const readableQuestion = instance.readableQuestion

  const correctAlternatives = readableQuestion?.alternatives.filter(
    (alternative) =>
      readableQuestion.correctAlternativeIds?.includes(alternative.id)
  )

  return (
    <Flex column grow>
      <PageContent gap={'medium'}>
        <HostModeHeader />
        <HeadingNew level={1}>Question is readable</HeadingNew>
        <TextNew>{readableQuestion?.text}</TextNew>

        {correctAlternatives?.length && (
          <>
            {correctAlternatives.map((alternative) => (
              <TextNew italic key={alternative.id}>
                - {alternative.text}
              </TextNew>
            ))}
          </>
        )}

        <PrimaryButton onClick={activateQuestion}>
          Activate question
        </PrimaryButton>
      </PageContent>
    </Flex>
  )
}
