/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Loader } from '../../components/Loader'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext } from '../../lib/AuthContext'
import { useMyLeagues } from './queries'

import { ChallengeLeagueListComponent } from './components/ChallengeLeagueListComponent'

const UserLeaguesList = ({
  limit,
  daysLimit,
}: {
  limit?: number
  daysLimit?: number
}) => {
  const { authUser, loading: authLoading } = useAuthContext()
  const { data: groups, loading } = useMyLeagues({
    limit,
    daysLimit,
  })

  if (loading || authLoading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  const displayGroups = limit ? groups?.slice(0, limit) : groups

  if (!authUser) {
    return null
  }

  if (displayGroups.length === 0) {
    return null
  }

  return (
    <Flex
      grow
      wrap
      gap="medium"
      horizontal="space-between"
      column
      css={css`
        width: 100%;
      `}
    >
      <PageContent>
        {displayGroups.map((group) => (
          <ChallengeLeagueListComponent
            league={group}
            key={group.id}
            marginTop={24}
          />
        ))}
      </PageContent>
    </Flex>
  )
}

export default UserLeaguesList
