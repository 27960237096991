/** @jsxImportSource @emotion/react */

import { CareerPathQuestion } from './GenerateQuestion'
import { css } from '@emotion/react'
import { colors } from '../../styles/theme'
import { Flex } from '../../components/layout/Flex'
import { getCountryFlag } from '../../utils/nation'
import { Spacer } from '../../styles/margin'
import { Text } from '../../components/Text'
import CareerPath, {
  CareerPathEntry,
} from '../../components/careerPath/CareerPath'
import { PlayerCard } from './CareerPathQuestionForm'
import {
  BiChevronDown,
  BiChevronUp,
  BiPlus,
  BiSolidTrash,
} from 'react-icons/bi'
import { RiSaveFill } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { TextInput } from '../../components/TextInput'
import { PrimaryButton } from '../../components/Button'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { CareerPathTeam, useGetAuthenticTeamsByNameQuery } from './queries'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'

export const EditCareerPathComponent = (props: {
  careerPathPlayer: CareerPathQuestion
  saveCareerPath: (careerPath: CareerPathQuestion) => void
}) => {
  const [careerPath, setCareerPath] = useState(
    props.careerPathPlayer.careerPathPlayer.careerPath
  )
  const [updateKey, setUpdateKey] = useState(0)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const removeEntry = (index: number) => {
    const newCareerPath = [...careerPath]
    newCareerPath.splice(index, 1)
    setCareerPath(newCareerPath)
  }

  const addEntry = () => {
    const newCareerPath = [...careerPath]
    newCareerPath.push({
      team: {
        id: '',
        name: '',
        logoUrl: '',
      },
      year: '',
      type: '',
    })
    setCareerPath(newCareerPath)
  }

  const moveUpOrDown = (up: boolean, index: number) => {
    // Check for boundary conditions first
    if (up && index === 0) return // No operation if trying to move the first element up
    if (!up && index === careerPath.length - 1) return // No operation if trying to move the last element down

    const currentPath = [...careerPath]
    const itemToMove = currentPath.splice(index, 1)[0]
    currentPath.splice(up ? index - 1 : index + 1, 0, itemToMove)

    setCareerPath(currentPath)
    setUpdateKey((prevKey) => prevKey + 1)
  }

  const updateEntry = (index: number, entry: CareerPathEntry) => {
    const newCareerPath = [...careerPath]
    newCareerPath[index] = entry
    setCareerPath(newCareerPath)
  }

  const validateEntries = () => {
    const errors: string[] = []
    careerPath.forEach((entry, index) => {
      if (!/^\d{4}$/.test(entry.year)) {
        errors.push(`Year at entry ${index + 1} must be a 4-digit number`)
      }
      if (!entry.team.id) {
        errors.push(`Team at entry ${index + 1} must be selected`)
      }
      if (!entry.type) {
        errors.push(`Type at entry ${index + 1} must be filled out`)
      }
    })
    setValidationErrors(errors)
    return errors.length === 0
  }

  useEffect(() => {
    validateEntries()
  }, [careerPath])

  return (
    <Flex column horizontal={'center'}>
      <Flex
        column
        css={css`
          padding: 8px;
          width: fit-content;
          border: 1px solid ${colors.grey200};
          border-radius: 5px;
          background-color: ${colors.white};

          @media (max-width: 768px) {
            padding: 8px 2px;
          }
        `}
        horizontal={'center'}
      >
        <PlayerCard
          playerPhoto={props.careerPathPlayer.careerPathPlayer.photoUrl}
          clubLogo={props.careerPathPlayer.careerPathPlayer.team.logoUrl}
          playerName={props.careerPathPlayer.careerPathPlayer.playerName}
          flag={getCountryFlag(
            props.careerPathPlayer.careerPathPlayer.nationality
          )}
          leagueLogo={
            props.careerPathPlayer.careerPathPlayer.team.league?.logoUrl
          }
        />
        <Spacer height={'medium'} />
        <Text size={'large'}>Career path</Text>

        <Spacer height={'small'} />

        <Text size={'small'} textAlign={'center'}>
          *The first year is most likely not correct when auto generated
        </Text>
        <Text size={'small'} textAlign={'center'}>
          *If the transfer was a loan, write "loan" in the transfer type field.
        </Text>

        <CareerPath careerPath={careerPath} showYear={true} />

        <Spacer height={'large'} />

        <Flex
          css={css`
            display: flex;
            width: 100%;
            padding: 0 8px;
            flex-direction: column;
            gap: 20px;
          `}
          key={updateKey}
        >
          {careerPath &&
            careerPath.map((it, index) => (
              <EditCareerPathEntry
                key={index}
                entry={it}
                removeEntry={removeEntry}
                index={index}
                moveUpOrDown={moveUpOrDown}
                length={careerPath.length}
                updateEntry={updateEntry}
              />
            ))}
          <PrimaryButton
            css={css`
              background-color: ${colors.green200};
              border: 1px solid ${colors.green400};
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            onClick={addEntry}
          >
            <BiPlus size={24} />
            <Text color={colors.black} size={'large'}>
              Add a new entry
            </Text>
          </PrimaryButton>
          <PrimaryButton
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
            `}
            onClick={() => {
              if (validateEntries()) {
                props.saveCareerPath({
                  ...props.careerPathPlayer,
                  careerPathPlayer: {
                    ...props.careerPathPlayer.careerPathPlayer,
                    careerPath: careerPath,
                  },
                })
              }
            }}
            disabled={validationErrors.length > 0}
          >
            <RiSaveFill color={colors.white} size={24} />
            <Text color={colors.white} size={'large'}>
              Save Career Path
            </Text>
          </PrimaryButton>
          {validationErrors.length > 0 && (
            <Flex column>
              {validationErrors.map((error, index) => (
                <Text key={index} size={'small'} color={colors.red500}>
                  {error}
                </Text>
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const EditCareerPathEntry = (props: {
  entry: CareerPathEntry
  removeEntry: (index: number) => void
  moveUpOrDown: (up: boolean, index: number) => void
  index: number
  length: number
  updateEntry: (index: number, entry: CareerPathEntry) => void
}) => {
  const [year, setYear] = useState(props.entry.year)
  const [team, setTeam] = useState<CareerPathTeam | null>(props.entry.team)
  const [type, setType] = useState(props.entry.type)
  const [searchQuery, setSearchQuery] = useState(team?.name || '')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })
  const queryResult = useGetAuthenticTeamsByNameQuery(
    debouncedSearchQuery != team?.name ? debouncedSearchQuery : ''
  )

  useEffect(() => {
    // Update the parent component with the new entry
    if (team) {
      props.updateEntry(props.index, {
        year: year,
        team: team,
        type: type,
      })
    }
  }, [year, team, type])

  return (
    <Flex
      css={css`
        width: 100%;
        max-width: 100%;

        @media (max-width: 601px) {
          border-top: 1px solid ${colors.grey200};
          padding-top: 21px;
        }
      `}
      gap={'small'}
      column={false}
      horizontal={'space-between'}
      vertical={'center'}
    >
      <BiSolidTrash
        color={colors.red500}
        size={32}
        css={css`
          cursor: pointer;
          min-width: 32px;
        `}
        onClick={() => {
          props.removeEntry(props.index)
        }}
      />
      {/* Edit index */}
      <Flex
        column
        vertical={'center'}
        css={css`
          height: 100%;
        `}
      >
        {props.index !== 0 && (
          <BiChevronUp
            size={20}
            onClick={() => {
              props.moveUpOrDown(true, props.index)
            }}
            css={css`
              cursor: pointer;
            `}
          />
        )}
        {props.index !== props.length - 1 && (
          <BiChevronDown
            size={20}
            onClick={() => {
              props.moveUpOrDown(false, props.index)
            }}
            css={css`
              cursor: pointer;
            `}
          />
        )}
      </Flex>
      <Flex
        column
        horizontal={'center'}
        css={css`
          width: fit-content;
          max-width: 100%;
          align-items: flex-start;
          @media (min-width: 601px) {
            flex-direction: row;
            width: fit-content;
            max-width: 100%;
          }
        `}
        gap={'small'}
      >
        {/* Year */}
        <TextInput
          css={css`
            width: 60px;
            border: 1px solid ${colors.grey200};
            border-radius: 5px;

            @media (max-width: 768px) {
              width: 100%;
            }
          `}
          value={year}
          placeholder={'Enter a year'}
          onValue={(value) => {
            if (/^\d{0,4}$/.test(value)) {
              setYear(value)
            }
          }}
        />

        {/* Club */}
        <Flex column gap={'small'}>
          <TextInput
            css={css`
              border: 1px solid ${colors.grey200};
              border-radius: 5px;
            `}
            value={searchQuery}
            placeholder={'Enter a team'}
            onValue={setSearchQuery}
          />
          {queryResult.loading && <div>Loading...</div>}
          {queryResult.error && <div>Error: {queryResult.error.message}</div>}
          {queryResult.data && queryResult.data.getTeamsByName && (
            <Flex column gap={'small'}>
              {queryResult.data.getTeamsByName.map((team) => (
                <Flex
                  key={team.id}
                  gap={'small'}
                  vertical={'center'}
                  css={css`
                    padding: 6px 12px;
                    border: 1px solid ${colors.grey400};
                    border-radius: 5px;
                    background-color: ${colors.white};

                    &:hover {
                      background-color: ${colors.grey200};
                    }
                  `}
                  onClick={() => {
                    setTeam(team)
                    setSearchQuery(team.name)
                  }}
                >
                  <img
                    width={28}
                    height={28}
                    src={team.logoUrl || ''}
                    css={css`
                      border-radius: 50%;
                      overflow: hidden;
                    `}
                  />

                  <Text size={'small'}>{team.name}</Text>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
        {/* Type */}
        <TextInput
          css={css`
            width: 120px;
            border: 1px solid ${colors.grey200};
            border-radius: 5px;

            @media (max-width: 768px) {
              width: 100%;
            }
          `}
          value={type}
          placeholder={'Enter a type'}
          onValue={setType}
        />
      </Flex>

      <Flex
        css={css`
          min-width: 32px;
        `}
      >
        {year !== '' && team !== null && type !== '' ? (
          <FaThumbsUp size={28} color={colors.green400} />
        ) : (
          <FaThumbsDown size={28} color={colors.red400} />
        )}
      </Flex>

      <Spacer height={'small'} />
    </Flex>
  )
}
