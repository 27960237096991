/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import {
  CLUB_GRID_ELEMENT_SIZE,
  ClubGridElement,
} from '../../components/ClubGridElement'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Fire, Heart, Search } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { LayoutLoading } from '../../components/layout/LayoutLoading'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useDebouncedValue } from '../../utils/useDebouncedValue'
import { LeaguesContainer } from '../Home/LeaguesContainer'
import { AcceptInvite } from './AcceptInvite'
import { CreateClubModal } from './CreateClubForm'
import { useClubsQuery, useViewerClubsQuery } from './queries'

const Content: FC<{}> = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const [clubQuery, setClubQuery] = useState('')
  const debouncedClubQuery = useDebouncedValue(clubQuery, 300)
  const clubsQuery = useClubsQuery({
    query: debouncedClubQuery,
    limit: debouncedClubQuery.length > 0 ? undefined : 50,
  })

  const viewerClubsQuery = useViewerClubsQuery()
  const viewerClubs =
    viewerClubsQuery.data?.viewer?.clubMemberships.map(
      (membership) => membership.club
    ) ?? []

  return (
    <>
      <PageContent>
        <AcceptInvite />
        <Flex horizontal="space-between" vertical="center">
          <Flex vertical="center" gap={'tiny'}>
            <Heart color={colors.green300} size={24} />
            <HeadingNew
              level={2}
              looksLikeLevel={5}
              weight={700}
              italic
              color="white"
              size={24}
              condensed
              css={css`
                text-transform: capitalize;
              `}
            >
              {t('My clubs')}
            </HeadingNew>
          </Flex>
          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton
                onClick={() => {
                  openModal()
                }}
                variant="white"
                rounded
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  justify-content: center;
                  padding: 2px 4px 2px 12px;
                `}
              >
                <TextNew
                  color={colors.black}
                  weight={700}
                  italic
                  size={24}
                  condensed
                  css={css`
                    text-transform: capitalize;
                    white-space: nowrap;
                    margin-bottom: 2px;
                    margin-right: -12px;
                  `}
                >
                  {t('Create new club')}
                </TextNew>
                <BiPlus size={32} />
              </PrimaryButton>
            )}
            modal={({ closeModal }) =>
              !authUser || authUser?.isAnonymous ? (
                <LoginModalInner closeModal={closeModal} />
              ) : (
                <CreateClubModal closeModal={closeModal} />
              )
            }
          />
        </Flex>
        <Spacer height={'small'} />
      </PageContent>

      <LeaguesContainer>
        <Flex
          gap={10}
          css={css`
            padding-bottom: 20px;
          `}
        >
          {viewerClubs.map((club) => (
            <ClubGridElement key={club.id} club={club} />
          ))}
        </Flex>
      </LeaguesContainer>

      <PageContent>
        {!viewerClubsQuery.loading && viewerClubs.length === 0 && (
          <Text color="white">
            <p css={margin.top()}>{t('You are not member of a club yet.')}</p>
            <p css={margin.top()}>{t('You can either:')}</p>
            <ul
              css={css`
                list-style: inside;
              `}
            >
              <li>{t('Be invited by to an existing club.')}</li>
              <li>{t('Or create a new club and invite your friends.')}</li>
            </ul>
          </Text>
        )}

        <Flex vertical="center" gap={'tiny'}>
          <Search color={colors.yellow400} size={24} />
          <HeadingNew
            level={2}
            looksLikeLevel={5}
            weight={700}
            italic
            color="white"
            size={24}
            condensed
            css={css`
              text-transform: capitalize;
            `}
          >
            {t('Find club')}
          </HeadingNew>

          {clubsQuery.loading && <Loader size={16} />}
        </Flex>

        <TextInput
          value={clubQuery}
          onValue={setClubQuery}
          placeholder={t('Search...')}
          css={margin.top('small')}
        />
        <Spacer height="large" />

        {debouncedClubQuery.trim().length === 0 && !clubsQuery.loading && (
          <Flex vertical="center" gap={'tiny'} css={margin.bottom('small')}>
            <Fire size={24} />
            <HeadingNew
              level={2}
              looksLikeLevel={5}
              weight={700}
              italic
              color="white"
              size={24}
              condensed
              css={css`
                text-transform: capitalize;
              `}
            >
              {t('Popular clubs')}
            </HeadingNew>
          </Flex>
        )}

        {!clubsQuery.loading && (
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(
                auto-fill,
                minmax(${CLUB_GRID_ELEMENT_SIZE}px, 1fr)
              );
              gap: 16px 4px;
              place-items: center;
              justify-content: space-evenly;
            `}
          >
            {clubsQuery.clubs.map((club) => (
              <ClubGridElement club={club} key={club.id} />
            ))}
          </div>
        )}

        {!clubQuery && clubsQuery.hasMore && (
          <Flex horizontal="center" css={margin.top('large')}>
            <SecondaryButton
              onClick={() => clubsQuery.fetchMore()}
              variant="white"
            >
              {t('More')}
            </SecondaryButton>
          </Flex>
        )}
      </PageContent>
    </>
  )
}

export const ClubsPage = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      title={`fcQuiz | ${t('Clubs')}`}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      backgroundColor={colors.green600}
      profileBanner
      withFooter
      showCookiebotButton
    >
      <Content />
    </Layout>
  )
}

export function ClubsLoaderComponent() {
  return (
    <LayoutLoading backgroundColor={colors.green100}>
      <Header color="green" />
    </LayoutLoading>
  )
}
