/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { ErrorMessage } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { useCheckTitleQuery, useScrapeUrlQuery } from './queries'
import { smartSplit, textToFacts } from './util'

export const AIVerify = (props: {
  AIPrompt: string
  facts: string[]
  setFacts: any
  setUrl: any
  start: any
  setFactIndex: any
  useUserUI: boolean
}) => {
  const checkTitleQuery = useCheckTitleQuery(props.AIPrompt)
  const { data, loading, error } = useScrapeUrlQuery(props.AIPrompt)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) {
      return
    }
    if (data && checkTitleQuery.data) {
      let _text = data?.scrapeUrl?.text ?? ''

      const checkmarkData = checkTitleQuery.data?.checkTitle
      const checkmark = checkmarkData?.checkmark ?? 0
      const checkmarkMax = checkmarkData?.checkmarkMax ?? 0

      if (checkmarkMax !== 0 && !props.useUserUI) {
        let prompt = ''
        if (checkmark + 1 < checkmarkMax) {
          prompt = `${
            checkmark + 1
          }/${checkmarkMax} questions are already completed. Continue where you left of?`
          props.setFactIndex(checkmark + 1)
        } else {
          prompt = `All questions are already completed.\nWant to generate questions from the start of the page?`
        }
        if (!confirm(prompt)) {
          navigate(0)
          return
        }
      }

      props.setFacts(textToFacts(_text))
      props.setUrl(props.AIPrompt)
      props.start()
    } else {
      /*if (dataSplit) {
        const facts =
          dataSplit.splitText.length > 0
            ? dataSplit.splitText
            : textToFacts(props.AIPrompt)
        props.setFacts(facts)
        props.start()
      }*/
      props.setFacts(smartSplit(props.AIPrompt))
      props.start()
    }
  }, [data, loading, checkTitleQuery.data])

  return (
    <Flex column gap="large" horizontal="center">
      <Flex vertical="center" gap="small">
        <Heading level={3}>Facts</Heading>
      </Flex>
      <Flex
        column
        gap="medium"
        css={css`
          height: 500px;

          margin-left: -60px;

          overflow-y: scroll;
          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
          }
        `}
        horizontal="center"
      >
        {loading && (
          <Flex
            column
            horizontal="center"
            css={css`
              margin-top: 100px;
              margin-left: 60px;
            `}
            gap="large"
          >
            <Text>Retrieving facts from URL</Text>
            <div
              css={css`
                width: 50px;
                height: 50px;
                border: 10px solid rgba(0, 0, 0, 0.1);
                border-top: 10px solid #3498db;
                border-left: 10px solid #3498db;
                border-radius: 50%;
                animation: spin 2s linear infinite;

                @keyframes spin {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }
              `}
            ></div>
          </Flex>
        )}
        {data?.scrapeUrl.text === '' && !loading && (
          <ErrorMessage
            css={css`
              margin-top: 40px;
              padding-right: 30px;
              margin-left: 60px;
            `}
          >
            Could not retrieve content from url.
          </ErrorMessage>
        )}
        {error && (
          <ErrorMessage
            css={css`
              margin-top: 40px;
              padding-right: 30px;
              margin-left: 60px;
            `}
          >
            Access was denied
          </ErrorMessage>
        )}
      </Flex>
    </Flex>
  )
}
