/** @jsxImportSource @emotion/react */

import { css, Global } from '@emotion/react'
import { FC } from 'react'
import { margin } from '../../styles/margin'
import { Logo } from '../icons'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'

export const Page: FC<{
  backgroundColor?: string
  showFcQuizLogo?: boolean
  className?: string
  logoColor?: string
  dense?: boolean
}> = ({
  backgroundColor,
  showFcQuizLogo,
  children,
  className,
  logoColor,
  dense,
}) => {
  return (
    <Flex
      grow
      column
      css={[
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `,
      ]}
      className={className}
    >
      {showFcQuizLogo && (
        <Logo
          color={logoColor || backgroundColor}
          css={css`
            width: calc(100vw - 40px);
            max-width: 400px;
            aspect-ratio: 1;
            height: unset;
            margin: 0 auto;
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
            opacity: 0.2;
          `}
        />
      )}
      {backgroundColor && (
        <Global
          styles={css`
            body {
              background-color: ${backgroundColor};
            }
          `}
        />
      )}
      <PageContent
        grow
        css={css`
          ${margin.vertical(dense ? 8 : 24)}
          @media (min-width: 800px) {
            ${margin.vertical(dense ? 8 : 40)}
          }
        `}
      >
        {children}
      </PageContent>
    </Flex>
  )
}
