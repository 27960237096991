/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { User } from 'firebase/auth'
import { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import appleLogo from '../../assets/apple.png'
import facebookLogo from '../../assets/facebook.png'
import googleLogo from '../../assets/google.png'
import { Text } from '../Text'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { useCurrentLocale, useSetLocale } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import theme, { colors } from '../../styles/theme'
import { isValidEmail } from '../../utils/email'
import { useForm } from '../../utils/useForm'
import {
  LocaleForm,
  validUsername,
} from '../../views/EditProfile/EditProfilePage'
import { useRegisterUserMutation } from '../../views/auth/queries'
import { PrimaryButton, SecondaryButton } from '../Button'
import { Heading } from '../Heading'
import { ErrorMessage, TextInput } from '../TextInput'
import { ToggleSwitch } from '../ToggleSwitch'
import { Flex } from '../layout/Flex'
import { Modal } from './Modal'

const NavButton = ({
  label,
  onClick,
  active,
}: {
  label: string
  onClick: () => void
  active?: boolean
}) => {
  return (
    <button
      aria-label={label}
      role="navigation"
      css={[
        buttonReset,
        css`
          min-height: 40px;
          padding: 4px 12px;
          text-decoration: ${active ? 'underline' : 'none'};
          border-radius: 4px;
          opacity: ${active ? 1 : 0.5};
        `,
      ]}
      onClick={onClick}
    >
      <Heading
        level={1}
        looksLikeLevel={3}
        color={colors.black}
        strong
        extraCondensed
      >
        {label}
      </Heading>
    </button>
  )
}

export const LoginModalInner = ({
  closeModal,
  destinationUrl,
  signupFirst = true,
}: {
  closeModal: () => void
  destinationUrl?: string
  signupFirst?: boolean
}) => {
  const t = useT()
  const [registerUserMutation] = useRegisterUserMutation()

  const formInitType = signupFirst ? 'signup' : 'login'

  const [selectedTab, setSelectedTab] = useState<
    'signup' | 'login' | 'reset-password'
  >(formInitType)

  const locale = useCurrentLocale()
  const setLocale = useSetLocale()
  const {
    signin,
    customTokenSignin,
    sendResetPasswordEmail,
    signInWithGoogle,
    signInWithApple,
    signInWithFacebook,
    checkForRedirectResult,
    clubLeagueSubscriptionId,
  } = useAuthContext()

  const [emailAlreadyInUse, setEmailAlreadyInUse] = useState(false)
  const [usernameAlreadyInUse, setUsernameAlreadyInUse] = useState(false)
  const [invalidUsername, setInvalidUsername] = useState(false)
  const [
    accountExistsWithDifferentCredential,
    setAccountExistsWithDifferentCredential,
  ] = useState(false)

  const form = useForm({
    initialValues: {
      type: formInitType as 'signup' | 'login' | 'reset-password',
      username: '',
      email: '',
      password: '',
      repeatPassword: '',
      visiblePassword: false,
      emailNewLeagueQuiz: false,
      acceptTermsAndPrivacy: false,
      resetPasswordEmailSent: false,
    },
    validate: {
      username: (val, values) =>
        values.type === 'login' || values.type === 'reset-password'
          ? undefined
          : validUsername.test(val.trim())
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
      email: (val) =>
        !isValidEmail(val) ? t('Use a valid email address') : undefined,
      password: (val, values) => {
        if (values.type === 'reset-password') {
          return undefined
        }

        return val.length < 6
          ? t('Password must be at least 8 characters')
          : undefined
      },
      repeatPassword: (val, values) =>
        values.type === 'login' ||
        values.type === 'reset-password' ||
        val === values.password
          ? undefined
          : t("The passwords doesn't match"),
      acceptTermsAndPrivacy: (val, values) =>
        values.type === 'login' || values.type === 'reset-password'
          ? undefined
          : val === false
          ? t('You must accept the terms and privacy policy')
          : undefined,
    },
    onSubmit: async ({ values, setSubmitError }) => {
      switch (values.type) {
        case 'signup': {
          try {
            const result = await registerUserMutation({
              variables: {
                input: {
                  username: values.username.trim(),
                  email: values.email,
                  password: values.password,
                  locale,
                  emailNewLeagueQuiz: values.emailNewLeagueQuiz,
                },
              },
            })

            if (result.data?.registerUser?.signInToken) {
              await customTokenSignin(
                result.data?.registerUser?.signInToken
              ).then(() => {
                closeModal()
                if (destinationUrl) {
                  window.location.href = destinationUrl
                } else {
                  window.location.reload()
                }
              })
            } else {
              setSubmitError(t('Something went wrong, try again'))
            }

            trackEvent(AnalyticsEventType.UserRegistered, {
              locale,
              fromEmbed: true,
              clubReferral: clubLeagueSubscriptionId,
            })
          } catch (error) {
            if (hasErrorCode(error, ErrorCode.USERNAME_NOT_AVAILABLE)) {
              setUsernameAlreadyInUse(true)
            } else if (hasErrorCode(error, ErrorCode.USERNAME_INVALID)) {
              setInvalidUsername(true)
            } else if (hasErrorCode(error, ErrorCode.EMAIL_ALREADY_EXISTS)) {
              setEmailAlreadyInUse(true)
            } else {
              setSubmitError(t('Something went wrong, try again'))
            }
          }
          break
        }
        case 'login': {
          try {
            await signin(values.email, values.password).then(async () => {
              if (destinationUrl) {
                window.location.href = destinationUrl
              } else {
                window.location.reload()
              }
            })
          } catch (error: any) {
            if (
              [
                'auth/user-not-found',
                'auth/wrong-password',
                'auth/user-disabled',
              ].includes(error.code)
            ) {
              setSubmitError(t('Wrong email or password'))
            } else {
              setSubmitError(t('Something went wrong, try again'))
            }
          }
          break
        }
        case 'reset-password': {
          try {
            await sendResetPasswordEmail(values.email)
            form.clearSubmitAttempt()
            form.setValue('type', 'login')
            form.setValue('resetPasswordEmailSent', true)
          } catch (error) {
            setSubmitError(t('Something went wrong, try again'))
          }
          break
        }
      }
    },
  })

  const handleExternalSignIn = async (
    signInMethod: () => Promise<User | string | undefined>,
    provider: string
  ) => {
    setAccountExistsWithDifferentCredential(false)

    const redirectUser = await checkForRedirectResult()

    if (redirectUser != undefined) {
      // If we got a user from the redirect, navigate to the destination
      navigateToDestination()
      return
    }

    try {
      const response = await signInMethod()
      if (
        response === 'auth/account-exists-with-different-credential' &&
        provider === 'facebook'
      ) {
        setAccountExistsWithDifferentCredential(true)
        console.log('account exists with different credential')
      } else if (response !== undefined) {
        navigateToDestination()
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  const navigateToDestination = () => {
    if (destinationUrl) {
      window.location.href = destinationUrl
    } else {
      window.location.reload()
    }
  }

  const handleGoogleSignIn = () =>
    handleExternalSignIn(signInWithGoogle, 'google')
  const handleAppleSignIn = () => handleExternalSignIn(signInWithApple, 'apple')
  const handleFacebookSignIn = () =>
    handleExternalSignIn(signInWithFacebook, 'facebook')

  return (
    <Modal
      closeModal={closeModal}
      styling={css`
        background-color: ${theme.colors.grey100};
        text-align: center;
      `}
    >
      <Flex
        css={css`
          margin-left: 12px;
        `}
      >
        <NavButton
          label={t('Sign up')}
          active={selectedTab === 'signup'}
          onClick={() => {
            setSelectedTab('signup')
            form.setValue('type', 'signup')
          }}
        />
        <NavButton
          label={t('Log in')}
          active={selectedTab === 'login'}
          onClick={() => {
            setSelectedTab('login')
            form.setValue('type', 'login')
          }}
        />
      </Flex>

      {selectedTab == 'signup' && (
        <>
          <Spacer height="small" />

          <Flex
            css={css`
              margin: 0 24px;
            `}
          >
            <LocaleForm
              locale={locale}
              onLocale={(locale) => setLocale(locale)}
              hideLabel
              onlyFlag
              textColor="black"
            />
          </Flex>
        </>
      )}

      {false && (
        <Flex column={false} horizontal={'space-between'} vertical={'center'}>
          {/* Google log in */}
          <PrimaryButton
            variant={'white'}
            onClick={handleGoogleSignIn}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0 0 0 1px ${theme.colors.grey200};
              height: 50px;
              width: 30%;
            `}
          >
            <img height={24} src={googleLogo} />
          </PrimaryButton>
          <PrimaryButton
            variant={'white'}
            onClick={handleAppleSignIn}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0 0 0 1px ${theme.colors.grey200};
              height: 50px;
              width: 30%;
            `}
          >
            <img height={24} src={appleLogo} />
          </PrimaryButton>

          <PrimaryButton
            variant={'white'}
            onClick={handleFacebookSignIn}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              height: 50px;
              width: 30%;
              box-shadow: 0 0 0 1px ${theme.colors.grey200};
            `}
          >
            <img height={24} src={facebookLogo} />
          </PrimaryButton>
        </Flex>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <Flex
          column
          css={css`
            border-radius: 8px;
            height: 100%;
            padding: 24px;
          `}
        >
          {form.values.resetPasswordEmailSent && (
            <Text>{t('Check your email to reset password')}</Text>
          )}
          {form.values.type === 'login' &&
            accountExistsWithDifferentCredential && (
              <Flex column gap={'tiny'}>
                <ErrorMessage>
                  {t('You already have an account with a different provider')}
                </ErrorMessage>
                <ErrorMessage>
                  {t('Please log in with the same provider')}
                </ErrorMessage>
              </Flex>
            )}
          {form.values.type === 'signup' && (
            <>
              <TextInput
                id="username"
                name="username"
                label={
                  <Text
                    color={theme.colors.green600}
                    size="small"
                    css={
                      (margin.bottom('tiny'),
                      css`
                        text-align: left;
                      `)
                    }
                  >
                    {t('Player name')}
                  </Text>
                }
                placeholder={t('A unique name')}
                autoComplete="off"
                value={form.values.username}
                onValue={(value) => {
                  form.setValue('username', value)
                  setUsernameAlreadyInUse(false)
                  setInvalidUsername(false)
                }}
                error={form.submitAttempted && form.fieldErrors.username}
                css={margin.top('medium')}
              />
              {usernameAlreadyInUse && (
                <ErrorMessage css={margin.top('tiny')}>
                  {t('Player name is taken')}
                </ErrorMessage>
              )}
              {invalidUsername && (
                <ErrorMessage css={margin.top('tiny')}>
                  {t('Invalid player name')}
                </ErrorMessage>
              )}
            </>
          )}
          <TextInput
            id="email"
            name="email"
            label={
              <Text
                color={theme.colors.green600}
                size="small"
                css={
                  (margin.bottom('tiny'),
                  css`
                    text-align: left;
                  `)
                }
              >
                {t('Email')}
              </Text>
            }
            placeholder="email@example.com"
            autoComplete="username"
            type="email"
            value={form.values.email}
            onValue={(value) => form.setValue('email', value.trim())}
            error={form.submitAttempted && form.fieldErrors.email}
            css={margin.top('medium')}
          />
          {emailAlreadyInUse && (
            <ErrorMessage css={margin.top('tiny')}>
              {t('The email address is already in use')}
            </ErrorMessage>
          )}

          {form.values.type !== 'reset-password' && (
            <>
              <Flex
                horizontal="space-between"
                vertical="center"
                css={margin.top('medium')}
              >
                <Text
                  color={theme.colors.green600}
                  size="small"
                  css={
                    (margin.bottom('tiny'),
                    css`
                      text-align: left;
                    `)
                  }
                >
                  {t('Password')}
                </Text>

                <button
                  css={[buttonReset]}
                  onClick={(event) => {
                    event.stopPropagation()
                    form.setValue(
                      'visiblePassword',
                      !form.values.visiblePassword
                    )
                  }}
                  tabIndex={-1}
                  type="button"
                >
                  {form.values.visiblePassword ? (
                    <FaEyeSlash color={theme.colors.green600} />
                  ) : (
                    <FaEye color={theme.colors.green600} />
                  )}
                </button>
              </Flex>

              <TextInput
                id="password"
                name="password"
                placeholder={t('Password')}
                autoComplete={
                  form.values.type === 'login'
                    ? 'current-password'
                    : 'new-password'
                }
                type={form.values.visiblePassword ? 'text' : 'password'}
                value={form.values.password}
                onValue={(value) => form.setValue('password', value)}
                error={form.submitAttempted && form.fieldErrors.password}
              />
            </>
          )}
          {form.values.type === 'login' && (
            <Flex vertical="flex-start">
              <SecondaryButton
                variant="green"
                onClick={() => {
                  form.clearSubmitAttempt()
                  form.setValue('type', 'reset-password')
                }}
                css={css`
                  border: none;
                  text-decoration: underline;
                `}
              >
                <Text size="small">{t('Forgot password?')}</Text>
              </SecondaryButton>
            </Flex>
          )}
          {form.values.type === 'signup' && (
            <>
              <Flex
                horizontal="space-between"
                vertical="center"
                css={margin.top('medium')}
              >
                <Text
                  color={theme.colors.green600}
                  size="small"
                  css={
                    (margin.bottom('tiny'),
                    css`
                      text-align: left;
                    `)
                  }
                >
                  {t('Repeat password')}
                </Text>

                <button
                  css={[buttonReset]}
                  onClick={(event) => {
                    event.stopPropagation()
                    form.setValue(
                      'visiblePassword',
                      !form.values.visiblePassword
                    )
                  }}
                  tabIndex={-1}
                  type="button"
                >
                  {form.values.visiblePassword ? (
                    <FaEyeSlash color={theme.colors.green600} />
                  ) : (
                    <FaEye color={theme.colors.green600} />
                  )}
                </button>
              </Flex>
              <TextInput
                id="new-repeatPassword"
                name="new-repeatPassword"
                type={form.values.visiblePassword ? 'text' : 'password'}
                placeholder={t('Repeat password')}
                autoComplete="new-password"
                value={form.values.repeatPassword}
                onValue={(value) => form.setValue('repeatPassword', value)}
                error={form.submitAttempted && form.fieldErrors.repeatPassword}
              />
            </>
          )}

          {form.values.type === 'signup' && (
            <div css={margin.top('medium')}>
              <ToggleSwitch
                value={form.values.emailNewLeagueQuiz}
                onValue={(value) => form.setValue('emailNewLeagueQuiz', value)}
                label={
                  <Text color={theme.colors.green600} size="small">
                    {t('Send me email about new quizzes')}
                  </Text>
                }
              />
              <ToggleSwitch
                value={form.values.acceptTermsAndPrivacy}
                onValue={(value) =>
                  form.setValue('acceptTermsAndPrivacy', value)
                }
                label={
                  <Link
                    to="/terms"
                    target="_blank"
                    color={theme.colors.green600}
                    tabIndex={-1}
                    css={css`
                      font-size: 15px;
                    `}
                  >
                    {t('I accept the terms and privacy policy')}
                  </Link>
                }
                css={margin.top('small')}
              />
              {form.submitAttempted &&
                form.fieldErrors.acceptTermsAndPrivacy && (
                  <ErrorMessage>
                    {form.fieldErrors.acceptTermsAndPrivacy}
                  </ErrorMessage>
                )}
            </div>
          )}

          <Flex css={margin.top('large')} column horizontal="center">
            <PrimaryButton
              variant="black"
              type="submit"
              loading={form.submitting}
            >
              {form.values.type === 'login'
                ? t('Log in')
                : form.values.type === 'reset-password'
                ? t('Reset password')
                : t('Create player')}
            </PrimaryButton>
          </Flex>

          {form.submitError && (
            <div css={margin.top()}>
              <ErrorMessage>{form.submitError}</ErrorMessage>
            </div>
          )}
        </Flex>
      </form>
    </Modal>
  )
}
