/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { FaShareSquare } from 'react-icons/fa'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { GroupMembersIcon } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { BadgeBanner } from '../Home/SpecialQuiz'
import {
  League,
  useSubscribeToLeague,
  useUnsubscribeFromLeague,
} from './queries'

const Buttons = ({ league }: { league: League }) => {
  const { authUser } = useAuthContext()
  const t = useT()
  const [subscribeToLeague] = useSubscribeToLeague()
  const [unsubscribeFromLeague] = useUnsubscribeFromLeague()
  const client = useApolloClient()
  const [copied, setCopied] = useState(false)

  const shareLeague = async () => {
    trackEvent(AnalyticsEventType.ShareLeagueClicked)

    const leagueLink = `${window.location.origin}/series/${league.slug}`

    const shareText = `⚽️ fcQuiz – ${league.title} ⚽️ \n\n${leagueLink}`

    if (window.navigator.share) {
      window.navigator.share({ text: shareText }).catch(() => {
        // ignore error
      })
    } else {
      navigator.clipboard.writeText(leagueLink)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }

  return (
    <Flex horizontal="center" gap="medium">
      {league.viewerIsSubscribed ? (
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton
              variant="white"
              css={css`
                border-radius: 40px;
              `}
              onClick={openModal}
            >
              {t('Following')}
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <Modal
              heading={t('Stop following')}
              closeModal={closeModal}
              maxWidth={500}
            >
              <Flex column>
                <Text>
                  {t(
                    'If you stop following this series, you will not receive notifications about new quizzes.'
                  )}
                </Text>
                <Text css={margin.top('small')}>
                  {t('Are you sure you want to stop following?')}
                </Text>

                <Flex
                  horizontal="flex-end"
                  vertical="center"
                  css={margin.top('large')}
                  gap="medium"
                >
                  <button css={buttonReset} onClick={closeModal}>
                    <Text underline>{t('Cancel')}</Text>
                  </button>
                  <PrimaryButton
                    onClick={() => {
                      unsubscribeFromLeague({
                        variables: { leagueId: league.id },
                      }).then(closeModal)
                    }}
                  >
                    {t('Yes, stop following')}
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Modal>
          )}
        />
      ) : (
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              variant="white"
              css={css`
                border-radius: 40px;
              `}
              onClick={() => {
                if (!authUser || authUser?.isAnonymous) {
                  openModal()
                  return
                }

                subscribeToLeague({
                  variables: { leagueId: league.id },
                }).then(() => client.refetchQueries({ include: 'active' }))
              }}
            >
              {t('Follow')}
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      )}
      <PrimaryButton
        variant="white"
        css={css`
          border-radius: 40px;
        `}
        onClick={shareLeague}
      >
        <Flex vertical="center" gap="small">
          <FaShareSquare color="black" size={20} />
          <span
            css={css`
              position: relative;
              top: 2px;
            `}
          >
            {copied ? t('Copied!') : t('Share')}
          </span>
        </Flex>
      </PrimaryButton>
    </Flex>
  )
}

const boxStyles = css`
  padding: 16px;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const Header = ({ league }: { league: League }) => {
  const t = useT()
  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
        `,
        boxStyles,
      ]}
    >
      <Flex column={false} horizontal="center">
        <Heading
          level={2}
          textAlign="center"
          color="white"
          wide
          strong
          css={css`
            font-weight: 800;
            font-size: 42px;
          `}
        >
          {league.title.split(' ')[0]}
        </Heading>
        <Heading
          level={1}
          textAlign="center"
          color="white"
          wide
          css={css`
            font-weight: 200;
            font-size: 42px;
          `}
        >
          {league.title.split(' ')[1]}
        </Heading>
      </Flex>
      <div
        css={css`
          justify-self: center;
          display: flex;
          align-items: center;
          gap: 6px;
        `}
      >
        <GroupMembersIcon color="white" height={17} />
        <TextNew
          color="white"
          size="large"
          textAlign="center"
          condensed
          italic
          spacingEm={0}
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          `}
        >
          {t('{{count}} player', {
            count: league.participantCount,
          }).toLowerCase()}
        </TextNew>
      </div>

      <div
        css={[
          css`
            display: grid;
            grid-gap: 20px;
          `,
        ]}
      >
        {league.description && (
          <Text textAlign="center" color="white" size="small">
            {league.description}
          </Text>
        )}
        <Buttons league={league} />
      </div>
    </div>
  )
}

export const TournamentTopContentV2 = ({ league }: { league: League }) => {
  const badge = league.badges[0]
  const earnedBadge = league.viewerEarnedBadges.find(
    (earnedBadge) => earnedBadge.id === badge.id
  )

  return (
    <Flex
      column
      horizontal="center"
      css={css`
        margin-top: 0vh;
      `}
    >
      <Flex
        column
        css={css`
          padding: 16px;
          width: 100%;
          max-width: 450px;
        `}
        horizontal="center"
      >
        <Header league={league} />
        <Spacer height={16} />
        {league.badges.length > 0 && (
          <BadgeBanner
            badge={league.badges[0]}
            numQuizzesPlayed={league.quizzesPlayed}
            earnedBadge={earnedBadge}
          />
        )}
      </Flex>
    </Flex>
  )
}
