/** @jsxImportSource @emotion/react */

import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { LiveQuizHostInstance, useHostCompleteQuizMutation } from '../queries'
import { useCallback } from 'react'
import { HostModeHeader } from './HostModeHeader'

export const FinishedLiveQuiz = ({
  instance,
}: {
  instance: LiveQuizHostInstance
}) => {
  const [completeQuizMutation] = useHostCompleteQuizMutation()

  const completeQuiz = useCallback(
    () =>
      completeQuizMutation({
        variables: {
          input: { instanceId: instance.id },
        },
      }),
    [instance.id, completeQuizMutation]
  )

  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Flex column grow>
      <PageContent>
        <HostModeHeader />
        <HeadingNew level={1}>Quiz finished</HeadingNew>
        <PrimaryButton
          onClick={async () => {
            await completeQuiz()
            goBack()
          }}
        >
          Set quiz completed
        </PrimaryButton>
      </PageContent>
    </Flex>
  )
}
