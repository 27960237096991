/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSwipeable } from 'react-swipeable'
import { LeftArrow, RightArrow } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'

const useRefWidth = <T extends HTMLElement>(initialWidth?: number) => {
  const ref = useRef<T>(null)
  const [width, setWidth] = useState(initialWidth ?? 0)

  useEffect(() => {
    const setSize = () => {
      if (ref.current) setWidth(ref.current.getBoundingClientRect().width)
    }
    window.addEventListener('resize', setSize)
    return () => {
      window.removeEventListener('resize', setSize)
    }
  }, [ref])

  return [ref, width] as const
}

const calcLeft = (
  containerWidth: number,
  index: number,
  itemWidth: number,
  itemSpacing: number
) => {
  return (
    containerWidth / 2 -
    itemWidth / 2 -
    (index * itemWidth + index * itemSpacing)
  )
}

export type SelectorProps = {
  items: ReactNode[]
  selectedIndex: number
  onSelectIndex: (index: number) => void
  itemWidth?: number
  itemSpacing?: number
  newLook?: boolean
  selectorColor?: string
}

export const LeagueQuizSelector = ({
  items,
  selectedIndex,
  onSelectIndex,
  itemWidth = 220,
  itemSpacing = 0,
  newLook = false,
  selectorColor = colors.white,
}: SelectorProps) => {
  const [containerRef, containerWidth] = useRefWidth<HTMLDivElement>(
    window.innerWidth
  )

  const goToIndex = useCallback(
    (index: number) => {
      if (index >= 0 && index < items.length) {
        onSelectIndex(index)
      }
    },
    [items.length, onSelectIndex]
  )
  const next = useCallback(() => {
    onSelectIndex(
      selectedIndex < items.length - 1 ? selectedIndex + 1 : selectedIndex
    )
  }, [items.length, onSelectIndex, selectedIndex])

  const prev = useCallback(() => {
    onSelectIndex(selectedIndex > 0 ? selectedIndex - 1 : selectedIndex)
  }, [onSelectIndex, selectedIndex])

  const goToLast = useCallback(() => {
    onSelectIndex(items.length - 1)
  }, [items.length, onSelectIndex])

  const goToFirst = useCallback(() => {
    onSelectIndex(0)
  }, [onSelectIndex])

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.defaultPrevented ||
        event.metaKey ||
        event.shiftKey ||
        event.altKey ||
        event.ctrlKey
      ) {
        return
      }

      if (event.key === 'ArrowRight') {
        next()
        event.preventDefault()
      } else if (event.key === 'ArrowLeft') {
        prev()
        event.preventDefault()
      }
    },
    [next, prev]
  )

  const swipeableHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    preventDefaultTouchmoveEvent: true,
  })

  const left = calcLeft(containerWidth, selectedIndex, itemWidth, itemSpacing)

  const animationSpeed = 150

  return (
    <Flex
      vertical={'center'}
      ref={containerRef}
      onKeyDown={onKeyDown}
      tabIndex={0}
      column
      css={css`
        outline: none;
        height: 100%;
      `}
    >
      <Flex
        css={css`
          position: relative;
          padding-bottom: 40px;
          overflow-x: hidden;
        `}
        {...swipeableHandlers}
      >
        <Flex
          vertical={'center'}
          css={[
            css`
              position: relative;
              min-height: 195px;
              height: 100%;
              padding-top: 4px;
            `,
            document.dir === 'rtl'
              ? css`
                  right: ${left}px;
                  transition: right ${animationSpeed}ms ease-in-out;
                `
              : css`
                  left: ${left}px;
                  transition: left ${animationSpeed}ms ease-in-out;
                `,
          ]}
        >
          {items.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => goToIndex(index)}
                css={[
                  css`
                    flex-shrink: 0;
                    min-width: ${itemWidth}px;
                    height: ${itemWidth}px;
                    margin-right: ${itemSpacing}px;
                    transition: transform ${animationSpeed}ms ease-in-out;
                    transform-origin: 50% 200%;
                    transform: scale(0.9);
                  `,
                  selectedIndex === index
                    ? css`
                        transform: scale(1);
                      `
                    : css`
                        &:hover {
                          transform: scale(0.95);
                          cursor: pointer;
                        }
                      `,
                ]}
              >
                {item}
              </div>
            )
          })}
        </Flex>
      </Flex>

      {!newLook && (
        <Controls
          numberOfItems={items.length}
          selectedIndex={selectedIndex}
          prev={prev}
          next={next}
          goToFirst={goToFirst}
          goToLast={goToLast}
          selectorColor={selectorColor}
          css={css`
            margin-top: 16px;
          `}
        />
      )}
    </Flex>
  )
}

const Controls = ({
  numberOfItems,
  selectedIndex,
  prev,
  next,
  goToFirst,
  goToLast,
  selectorColor,
  className,
}: {
  numberOfItems: number
  selectedIndex: number
  prev: () => void
  next: () => void
  goToFirst: () => void
  goToLast: () => void
  selectorColor: string
  className?: string
}) => {
  const t = useT()

  const isFirstItem = selectedIndex === 0
  const isLastItem = selectedIndex === numberOfItems - 1
  return (
    <Flex
      horizontal="center"
      vertical="center"
      gap={'small'}
      css={css`
        margin: 0 auto;
        padding: 0 16px;
      `}
      className={className}
    >
      <button
        css={[
          buttonReset,
          css`
            border: 1px solid ${selectorColor};
            border-radius: 4px;
            padding: 6px 12px;
            border-radius: 24px;
            text-transform: capitalize;
            opacity: ${isFirstItem ? 0 : 1};
          `,
        ]}
        onClick={goToFirst}
        disabled={isFirstItem}
      >
        <TextNew italic color={selectorColor}>
          {t('First')}
        </TextNew>
      </button>
      <ArrowButton
        onClick={prev}
        direction={document.dir === 'rtl' ? 'right' : 'left'}
        disabled={selectedIndex === 0}
        selectorColor={selectorColor}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          min-width: 48px;
        `}
      />

      <TextNew
        weight={600}
        condensed
        size={24}
        color={selectorColor}
        shadow
      >{`${selectedIndex + 1} / ${numberOfItems}`}</TextNew>

      <ArrowButton
        onClick={next}
        direction={document.dir === 'rtl' ? 'left' : 'right'}
        disabled={selectedIndex === numberOfItems - 1}
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          min-width: 48px;
        `}
        selectorColor={selectorColor}
      />

      <button
        css={[
          buttonReset,
          css`
            border: 1px solid ${selectorColor};
            border-radius: 4px;
            padding: 6px 12px;
            border-radius: 24px;
            text-transform: capitalize;
            opacity: ${isLastItem ? 0 : 1};
          `,
        ]}
        onClick={goToLast}
        disabled={isLastItem}
      >
        <TextNew italic color={selectorColor}>
          {t(`Last`)}
        </TextNew>
      </button>
    </Flex>
  )
}

const ArrowButton = ({
  onClick,
  direction,
  disabled,
  className,
  selectorColor,
}: {
  onClick: () => void
  direction: 'left' | 'right'
  disabled?: boolean
  className?: string
  selectorColor: string
}) => {
  return (
    <button
      onClick={onClick}
      css={[
        buttonReset,
        disabled &&
          css`
            opacity: 0.25;
            cursor: initial;
          `,
      ]}
      className={className}
      disabled={disabled}
    >
      <Flex vertical="center">
        {direction === 'left' ? (
          <LeftArrow color={selectorColor} />
        ) : (
          <RightArrow color={selectorColor} />
        )}
      </Flex>
    </button>
  )
}
