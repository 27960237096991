import { css } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { TextNew } from '../../components/TextNew'
import { colors } from '../../styles/theme'
import { TranslateButton } from './LeagueQuizItem'
import { LeagueQuizMinimal } from './queries'
import { useT } from '../../lib/i18n/useT'

/** @jsxImportSource @emotion/react */
export const EditLiveQuizzesModal = ({
  leagueId,
  quizzes,
  closeModal,
}: {
  leagueId: string
  quizzes: LeagueQuizMinimal[]
  closeModal: () => void
}) => {
  const t = useT()
  return (
    <Modal closeModal={closeModal} heading={t('Live quizzes')}>
      <Flex column gap="medium">
        {quizzes.map((quiz) => (
          <Flex
            column
            key={quiz.id}
            vertical="center"
            gap={'large'}
            css={css`
              padding: 16px;
              background-color: ${colors.grey100};
              border-radius: 4px;
            `}
          >
            <TextNew>{quiz.title}</TextNew>
            <Flex wrap gap={'small'}>
              <TranslateButton
                primary={false}
                leagueId={leagueId}
                quiz={quiz}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Modal>
  )
}
