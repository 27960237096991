import { useEffect, useState } from 'react'

// This hook is used to detect if the user has an adblocker installed
// from https://github.com/aruniverse/adblock-detect-react
// only modified to handle redirects
export const useDetectAdBlock = ({ skip }: { skip?: boolean }) => {
  const [adBlockDetected, setAdBlockDetected] = useState(false)

  useEffect(() => {
    if (skip) return

    const url = 'https://www3.doubleclick.net'
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(({ redirected }) => {
        if (redirected) setAdBlockDetected(true)
      })
      .catch(() => {
        setAdBlockDetected(true)
      })
  }, [])

  return adBlockDetected
}
