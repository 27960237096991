import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import 'firebase/storage'
import { getStorage } from 'firebase/storage'
import { shouldUseProductionFirebaseConfig } from '../config/config'

const firebaseConfig = shouldUseProductionFirebaseConfig()
  ? {
      apiKey: 'AIzaSyDNJWw-LNi7gAQfPXDXuXEodvJjkTlzZVY',
      authDomain: 'fcquiz-production.firebaseapp.com',
      projectId: 'fcquiz-production',
      storageBucket: 'fcquiz-production.appspot.com',
      messagingSenderId: '248208525299',
      appId: '1:248208525299:web:eeb494519c3798a1f03486',
      measurementId: 'G-1PYYW60EN3',
    }
  : {
      apiKey: 'AIzaSyBSYNcrLh9yYD0Ehl2fgPBHQzNl3lLLBvg',
      authDomain: 'fcquiz-test.firebaseapp.com',
      projectId: 'fcquiz-test',
      storageBucket: 'fcquiz-test.appspot.com',
      messagingSenderId: '659854224653',
      appId: '1:659854224653:web:e738b4851386eb839e5444',
    }

export const firebaseApp = initializeApp(firebaseConfig)

export const auth = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp)
