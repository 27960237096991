/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { LiveQuizHostInstance, useHostCloseQuestionMutation } from '../queries'
import { HostModeHeader } from './HostModeHeader'
import { useGetLiveQuizHostInstanceParticipantsQuery } from './JoinLiveQuiz'

export const ActiveQuestionLiveQuiz = ({
  instance,
}: {
  instance: LiveQuizHostInstance
}) => {
  const [closeQuestionMutation] = useHostCloseQuestionMutation()

  const participantsQuery = useGetLiveQuizHostInstanceParticipantsQuery(
    instance.id
  )

  useEffect(() => {
    participantsQuery.startPolling(2000)

    return () => {
      participantsQuery.stopPolling()
    }
  }, [])

  const activeQuestion = instance.activeQuestion

  const closeQuestion = useCallback(
    () =>
      closeQuestionMutation({
        variables: {
          input: {
            instanceId: instance.id,
            currentQuestionId: activeQuestion?.id,
          },
        },
      }),
    [instance.id, closeQuestionMutation]
  )

  const initialSecondsLeft = activeQuestion?.secondsLeft ?? 0

  const maxTimeToAnswerInSeconds =
    activeQuestion?.maxTimeToAnswerInSeconds || 30

  const [remaining, setRemaining] = useState(initialSecondsLeft)

  const [timerRunning] = useState(true)
  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 || !timerRunning ? null : 1000
  )

  const participantCount =
    participantsQuery.data?.getLiveQuizHostInstance.participantCount || 0

  const allPariticipantsAnswered =
    activeQuestion?.numberOfAnswers === participantCount

  useEffect(() => {
    if (!activeQuestion) {
      return
    }
    async function close() {
      await closeQuestion()

      return
    }

    if (remaining <= 0 || allPariticipantsAnswered) {
      close()
    }
  }, [remaining, allPariticipantsAnswered, closeQuestion, activeQuestion])

  const progressColorRemaining =
    remaining > 6
      ? colors.green300
      : remaining > 3
      ? colors.yellow400
      : colors.red300

  const shrink = keyframes`
      from {
        width: ${100 * (initialSecondsLeft / maxTimeToAnswerInSeconds)}%;
      }
      to {
        width: 0%;
      }
  `

  if (!activeQuestion) {
    return null
  }

  return (
    <Flex column grow>
      <PageContent gap={'medium'}>
        <HostModeHeader />
        <HeadingNew level={1}>Question is active, users can answer</HeadingNew>
        <TextNew>{activeQuestion?.text}</TextNew>
        <TextNew>Time to answer: {remaining} seconds</TextNew>
        <div
          css={css`
            position: relative;
            height: 8px;
            background-color: ${colors.green500};
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              animation: ${shrink} ${initialSecondsLeft}s linear;
              animation-play-state: ${timerRunning ? 'running' : 'paused'};
              background-color: ${progressColorRemaining};
              height: 8px;
            `}
          />
        </div>
        <TextNew>
          Number of answers: {instance.activeQuestion?.numberOfAnswers} /{' '}
          {participantCount}
        </TextNew>
      </PageContent>
    </Flex>
  )
}
