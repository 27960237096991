import { ReactComponent as Arms0 } from './Arms 0.svg'
import { ReactComponent as ArmsA } from './Arms A.svg'
import { ReactComponent as ArmsB } from './Arms B.svg'
import { ReactComponent as ArmsC } from './Arms C.svg'
import { ReactComponent as ArmsD } from './Arms D.svg'
import { ReactComponent as ArmsE } from './Arms E.svg'
import { ReactComponent as ArmsF } from './Arms F.svg'
import { ReactComponent as ArmsG } from './Arms G.svg'
import { ReactComponent as ArmsH } from './Arms H.svg'
import { ReactComponent as ArmsI } from './Arms I.svg'
import { ReactComponent as ArmsJ } from './Arms J.svg'
import { ReactComponent as ArmsK } from './Arms K.svg'
import { ReactComponent as ArmsL } from './Arms L.svg'

export const armsStyles = {
  Arms0: {
    icon: <Arms0 />,
    usedColors: 0,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ArmsA: {
    icon: <ArmsA />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  ArmsB: {
    icon: <ArmsB />,
    usedColors: 3,
    width: 12,
    centerIndex: 0,
    rotation: 15,
    offset: -26,
    premium: false,
  },
  ArmsC: {
    icon: <ArmsC />,
    usedColors: 2,
    width: 12,
    centerIndex: 1,
    rotation: 15,
    offset: 10,
    premium: false,
  },
  ArmsD: {
    icon: <ArmsD />,
    usedColors: 2,
    width: 32,
    centerIndex: undefined,
    rotation: 15 + 180, //reverses color order
    offset: 0,
    premium: false,
  },
  ArmsE: {
    icon: <ArmsE />,
    usedColors: 2,
    width: 27.5,
    centerIndex: undefined,
    rotation: 15 - 45,
    offset: 0,
    premium: false,
  },
  ArmsF: {
    icon: <ArmsF />,
    usedColors: 2,
    width: 20,
    centerIndex: 0,
    rotation: 15 - 45,
    offset: 0,
    premium: false,
  },
  ArmsG: {
    icon: <ArmsG />,
    usedColors: 2,
    width: 13,
    centerIndex: 0,
    rotation: 15 - 45,
    offset: 0,
    premium: false,
  },
  ArmsH: {
    icon: <ArmsH />,
    usedColors: 2,
    width: 46.5,
    centerIndex: 1,
    rotation: 15 - 45,
    offset: 0,
    premium: false,
  },
  ArmsI: {
    icon: <ArmsI />,
    usedColors: 3,
    width: 25,
    centerIndex: undefined,
    rotation: 90 + 15,
    offset: 6,
    premium: false,
  },
  ArmsJ: {
    icon: <ArmsJ />,
    usedColors: 2,
    width: 60,
    centerIndex: 1,
    rotation: 90 + 15,
    offset: 20,
    premium: false,
  },
  ArmsK: {
    icon: <ArmsK />,
    usedColors: 2,
    width: 25,
    centerIndex: undefined,
    rotation: 90 + 15 - 180,
    offset: 0,
    premium: false,
  },
  ArmsL: {
    icon: <ArmsL />,
    usedColors: 2,
    width: 15,
    centerIndex: undefined,
    rotation: 90 + 15,
    offset: -3,
    premium: false,
  },
}
