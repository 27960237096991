/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { AddFriendsButtonIcon } from '../../components/friends/AddFriends'
import { Flex } from '../../components/layout/Flex'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useHorizontalScroll } from '../Avatar/AvatarSelectUI'
import { UserAvatar } from '../Avatar/UserAvatar'
import { FriendUser } from './queries'

export const PROFILE_PAGE_FRIEND_AVATAR_SIZE = 80

export const FriendRequestList: FC<{
  friendRequests: FriendUser[]
  showAddFriendsButton?: boolean
}> = ({ friendRequests, showAddFriendsButton }) => {
  const t = useT()
  const navigate = useNavigate()
  const scrollRef = useHorizontalScroll()
  const numFriends = friendRequests.length
  return (
    <div>
      <Text size={'medium'} color={colors.white} strong italic>
        {`${t(`Friend requests`).toUpperCase()} (${
          numFriends > 0 ? numFriends : ''
        })`}
      </Text>
      <Spacer height={16} />
      <Flex
        ref={scrollRef}
        gap={'large'}
        css={css`
          overflow-x: auto;
          padding-left: 8px;
        `}
      >
        {showAddFriendsButton && <AddFriendsButtonIcon />}
        {friendRequests.map((friend) => (
          <Flex
            key={friend.id}
            column
            horizontal={'center'}
            role="link"
            aria-label={t(`Go to profile`) + ` ${friend?.displayName}`}
            onClick={() => {
              navigate(`/profile/${friend.slug}`)
            }}
          >
            <UserAvatar
              avatarData={friend.avatarData}
              height={PROFILE_PAGE_FRIEND_AVATAR_SIZE}
            />
            <TextNew
              size={'small'}
              color={colors.white}
              css={css`
                overflow: hidden;
                white-space: nowrap;
                text-align: center;
                text-overflow: ellipsis;
                padding: 4px 2px;
              `}
            >
              {friend?.displayName}
            </TextNew>
          </Flex>
        ))}
      </Flex>
    </div>
  )
}
