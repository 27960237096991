/** @jsxImportSource @emotion/react */

import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Modal, ModalTrigger } from '../../../components/modal/Modal'
import { TextInput } from '../../../components/TextInput'
import { TextNew } from '../../../components/TextNew'
import { useViewerQuery } from '../../../lib/AuthContext'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useDebouncedString } from '../../../utils/useDebouncedValue'
import { ChallengeLeaguePreviewComponent } from '../../Challenge/components/ChallengeLeaguePreviewComponent'
import { useChallengeLeagueQuery } from '../../Challenge/queries'
import { Members } from '../ClubProfilePage/Members'
import { Club } from '../queries'

// importMembersFromLeague(input: ImportMembersFromLeagueInput!): Club

type ImportMembersFromLeagueInput = {
  clubId: string
  leagueId: string
}

const importMembersFromLeagueMutation = gql`
  mutation importMembersFromLeague($input: ImportMembersFromLeagueInput!) {
    importMembersFromLeague(input: $input) {
      id
      membersCount
      members {
        id
        role
        user {
          id
          username
          slug
          avatarData
          displayName
        }
      }
    }
  }
`

const useImportMembersFromLeagueMutation = () => {
  return useMutation<
    { importMembersFromLeague: Club },
    { input: ImportMembersFromLeagueInput }
  >(importMembersFromLeagueMutation)
}

export const MembersSubPage = ({ club }: { club: Club }) => {
  const viewerQuery = useViewerQuery()

  const viewerisFcQuizAdmin = viewerQuery.data?.viewer?.isAdmin
  return (
    <Flex horizontal="center" column>
      {viewerisFcQuizAdmin && (
        <>
          <Spacer height="large" />
          <ModalTrigger
            button={({ openModal }) => (
              <SecondaryButton variant="white" onClick={openModal}>
                {'Import members from league'}
              </SecondaryButton>
            )}
            modal={({ closeModal }) => (
              <ImportMembersFromLeagueModal
                clubId={club.id}
                closeModal={closeModal}
                membersCount={club.membersCount}
              />
            )}
          />
        </>
      )}

      <Spacer height="large" />
      <Members
        clubId={club.id}
        clubSlug={club.slug}
        viewerRole={club.viewerRole}
        viewerCanEdit={club.viewerCanEdit}
        membersCount={club.membersCount}
      />
    </Flex>
  )
}

const ImportMembersFromLeagueModal = ({
  clubId,
  closeModal,
  membersCount,
}: {
  clubId: string
  closeModal: () => void
  membersCount: number
}) => {
  const [searchString, setSearchString] = useState('')

  const [userHasBeenWarned, setUserHasBeenWarned] = useState(false)

  const debounced = useDebouncedString(searchString, 500, { minLength: 3 })
  const leagueQuery = useChallengeLeagueQuery(debounced, {
    skip: debounced.length < 3,
  })

  const [importMembersFromLeague] = useImportMembersFromLeagueMutation()

  const league = leagueQuery.data?.group

  const noResults = !league && debounced.length >= 3 && !leagueQuery.loading

  return (
    <Modal closeModal={closeModal} heading="Import members">
      <TextNew>Current members: {membersCount}</TextNew>
      <TextInput
        value={searchString}
        onValue={setSearchString}
        placeholder="ID or slug"
        label="Search"
        textCss={css`
          border-radius: 4px;
          border: 1px solid ${colors.grey300};
        `}
      />
      <Spacer height="large" />

      {leagueQuery.loading && (
        <Flex column>
          <TextNew>Searching for league...</TextNew>
          <Spacer height="large" />
        </Flex>
      )}

      {noResults && (
        <Flex column>
          <TextNew>League not found</TextNew>
          <Spacer height="large" />
        </Flex>
      )}

      {league && (
        <Flex column gap={'small'} css={css``}>
          <ChallengeLeaguePreviewComponent
            title={league.title}
            textColor={league.textColor}
            backgroundColor={league.backgroundColor}
          />
          <TextNew>Members: {league.memberCount}</TextNew>
          <PrimaryButton
            onClick={async () => {
              if (!userHasBeenWarned) {
                setUserHasBeenWarned(true)
                return
              }

              try {
                await importMembersFromLeague({
                  variables: { input: { clubId, leagueId: league.id } },
                })

                closeModal()
              } catch (e) {
                setUserHasBeenWarned(false)
              }
            }}
          >
            {userHasBeenWarned ? 'Are you sure?' : 'Import members'}
          </PrimaryButton>
        </Flex>
      )}
    </Modal>
  )
}
