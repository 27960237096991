/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Text } from '../../../components/Text'
import { TextInput } from '../../../components/TextInput'
import { Flex } from '../../../components/layout/Flex'
import { Locale, LocaleString, localeToFlag } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'

export const TranslateQuizTitleComponent = ({
  quizTitle,
  fromLanguage,
  isValid,
  setTitle,
  title,
}: {
  quizTitle: LocaleString
  fromLanguage: Locale
  toLanguage: Locale
  isValid: boolean
  title: string | undefined | null
  setTitle: (value: string | undefined) => void
}) => {
  const t = useT()

  return (
    <Flex column>
      <Flex>
        <Text>{t('Title')}</Text>
      </Flex>

      <TextInput
        value={title ?? ''}
        onValue={(value) => {
          setTitle(value)
        }}
        css={css`
          width: 100%;
        `}
        textCss={css`
          border-color: black;
        `}
        error={!isValid ? t(`Title cannot be empty`) : undefined}
      />
      <Spacer height={4} />
      <Text color={colors.grey400}>
        {localeToFlag[fromLanguage]} {quizTitle[fromLanguage]}
      </Text>
    </Flex>
  )
}
