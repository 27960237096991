/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'

import { useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { useDetectAdBlock } from '../../components/campaign/useDetectAdBlock'
import { HeadingNew } from '../../components/HeadingNew'
import {
  AdId,
  AdSize,
  RevIqAdComponent,
} from '../../components/reviq/RevIqAdComponent'
import { TextNew } from '../../components/TextNew'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { setRevIqAdsEnabled } from '../../utils/thirdPartyScripts'

export const AdTestPage = () => {
  const adblockDetected = useDetectAdBlock({ skip: false })

  const [adsEnabled, setAdsEnabled] = useState(true)

  return (
    <Layout backgroundColor={colors.green600} hideNavbar>
      <Flex column grow>
        <PageContent gap={'huge'}>
          <HeadingNew color={colors.white} level={1}>
            {'Ad Test'}
          </HeadingNew>

          <TextNew color="white" size={'small'} italic>
            {`ads enabled: ${adsEnabled}`}
          </TextNew>
          <Flex gap={'medium'}>
            <PrimaryButton
              noWrap
              rounded
              variant="white"
              onClick={() => {
                setRevIqAdsEnabled(true)
                setAdsEnabled(true)
              }}
            >
              Enable RevIqAds
            </PrimaryButton>
            <PrimaryButton
              noWrap
              rounded
              variant="white"
              onClick={() => {
                setRevIqAdsEnabled(false)
                setAdsEnabled(false)
              }}
            >
              Disable RevIqAds
            </PrimaryButton>
          </Flex>

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_RECTANGLE} | data-ad=${AdId.videoTop} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_RECTANGLE}
            adId={AdId.videoTop}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.MEDIUM_RECTANGLE} | data-ad=${AdId.rightRail1} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.MEDIUM_RECTANGLE}
            adId={AdId.rightRail1}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_RECTANGLE} | data-ad=${AdId.rightRail2} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_RECTANGLE}
            adId={AdId.rightRail2}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.LARGE_BANNER} | data-ad=${AdId.leftRail1} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.LARGE_BANNER}
            adId={AdId.leftRail1}
            adblockDetected={adblockDetected}
          />

          <TextNew color="white" size={'small'} italic>
            {`size: ${AdSize.BANNER} | data-ad=${AdId.leftRail2} | adblockDetected: ${adblockDetected}`}
          </TextNew>
          <RevIqAdComponent
            adSize={AdSize.BANNER}
            adId={AdId.leftRail2}
            adblockDetected={adblockDetected}
          />

          <Spacer height={'huge'} />
        </PageContent>
      </Flex>
    </Layout>
  )
}
