/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { shuffle } from 'lodash'
import { useEffect, useState } from 'react'
import { FaRandom } from 'react-icons/fa'
import {
  makeBaseAvatar,
  makeRandomFaceAvatar,
} from '../../../common/avatarConfig'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { HeadingNew } from '../../../components/HeadingNew'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { useAuthContext, useViewerQuery } from '../../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { buttonReset, prettyLetterSpacing } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { Avatar } from '../../Avatar/Avatar'
import {
  AvatarContextProvider,
  useAvatarContext,
} from '../../Avatar/AvatarPage'
import { SelectableButtons } from '../../Avatar/AvatarSelectUI'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { useSetAvatarMutation } from '../../Avatar/queries'
import {
  AvatarParts,
  hasDefinedAvatar,
  makeAvatarData,
  makeAvatarParts,
} from '../../Avatar/util'
import { User, useUserProfileAvatarQuery } from '../../Profile/queries'

export const LiveWaitToStart = ({}: {}) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  const [timeUntilSaveAvailable, setTimeUntilSaveAvailable] =
    useState<number>(0)

  useInterval(
    () => {
      if (timeUntilSaveAvailable > 0) {
        setTimeUntilSaveAvailable(timeUntilSaveAvailable - 1)
      }
    },
    timeUntilSaveAvailable > 0 ? 1000 : null
  )

  const [showAvatarCreator, setShowAvatarCreator] = useState(false)
  const [editExistingAvatar, setEditExistingAvatar] = useState(false)

  const userSlug = viewerQuery.data?.viewer?.slug

  const query = useUserProfileAvatarQuery(
    userSlug ?? '',
    userSlug === undefined
  )
  const user = query?.data?.user

  // Only show avatar creator if user has no avatar previously
  useEffect(() => {
    if (user) {
      const userHasDefinedAvatar = hasDefinedAvatar(user?.avatarData)

      if (!userHasDefinedAvatar) {
        setShowAvatarCreator(true)
      }
    }
  }, [user?.slug])

  return (
    <Flex column>
      <PageContent gap={'medium'} horizontal="center">
        <HeadingNew
          level={1}
          looksLikeLevel={2}
          textAlign="center"
          italic
          css={[
            prettyLetterSpacing,
            css`
              margin-top: 24px;
              font-size: 45px;
              font-weight: 600;
            `,
          ]}
        >
          {t(`Waiting for the quiz to start`)}
        </HeadingNew>

        {showAvatarCreator && (
          <TextNew
            textAlign="center"
            italic
            css={[
              prettyLetterSpacing,
              css`
                font-size: 30px;
                font-weight: 600;
              `,
            ]}
          >
            {t(`Tweak your looks while you wait!`)}
          </TextNew>
        )}

        {/* Create avatar */}
        {user && (
          <AvatarContextProvider>
            {!showAvatarCreator && (
              <>
                <UserAvatar avatarData={user.avatarData} height={250} />
                <SecondaryButton
                  onClick={() => {
                    setShowAvatarCreator(true)
                    setEditExistingAvatar(true)
                  }}
                >
                  {t('Edit Avatar')}
                </SecondaryButton>
              </>
            )}

            {showAvatarCreator && (
              <EditAvatarLiveWaitToStart
                user={user}
                closeCreator={() => setShowAvatarCreator(false)}
                isExistingAvatar={editExistingAvatar}
                saveIsAvailable={timeUntilSaveAvailable === 0}
                onSave={() => {
                  setTimeUntilSaveAvailable(5)
                }}
              />
            )}
          </AvatarContextProvider>
        )}
      </PageContent>
      <Spacer height={100} />
    </Flex>
  )
}

const EditAvatarLiveWaitToStart = ({
  user,
  closeCreator,
  isExistingAvatar,
  saveIsAvailable,
  onSave,
}: {
  user: User
  closeCreator: () => void
  isExistingAvatar: boolean
  saveIsAvailable: boolean
  onSave: () => void
}) => {
  const { parts, setParts } = useAvatarContext()
  const [isBase, setIsBase] = useState(false)
  const [selectedShirt, setSelectedShirt] = useState<Shirt | undefined>(
    undefined
  )
  const [shirts, setShirts] = useState<Shirt[]>(predefinedKits)

  const [avatarData, setAvatarData] = useState<any | undefined>(undefined)
  const [avatarParts, setAvatarParts] = useState<AvatarParts | undefined>(
    undefined
  )

  const [hasMadeChanges, setHasMadeChanges] = useState(false)

  useEffect(() => {
    const shuffledShirts = shuffle(shirts)
    setShirts(shuffledShirts)
  }, [])

  useEffect(() => {
    const avatarDataJSON = user.avatarData
      ? JSON.parse(user.avatarData)
      : makeBaseAvatar()

    const data = avatarDataJSON.IsBase ? makeRandomFaceAvatar() : avatarDataJSON

    if (avatarDataJSON.IsBase) {
      setIsBase(true)
      setHasMadeChanges(true)
    }

    setParts(makeAvatarParts(data))
    setAvatarData(data)
    setAvatarParts(makeAvatarParts(data))
  }, [user?.avatarData])

  const [setAvatarMutation, { loading }] = useSetAvatarMutation()

  useEffect(() => {
    if (!selectedShirt || !avatarData) return
    // Combine the user avatar with the selected shirt as a JSON
    const newAvatarData = JSON.stringify({
      ...avatarData,
      ...selectedShirt,
    })
    setParts(makeAvatarParts(JSON.parse(newAvatarData)))
  }, [selectedShirt])

  if (!parts || !avatarParts) return null

  return (
    <PageContent>
      <Flex column horizontal={'center'}>
        <Flex
          className="Avatar"
          column
          horizontal="center"
          css={css`
            position: relative;
            width: 100%;
            z-index: 1;
          `}
        >
          <Avatar
            userId={user.id ?? ''}
            height={250}
            css={css`
              @media (min-width: 599px) {
                transform: scale(1.3);
                margin-top: 40px;
                margin-bottom: 40px;
              }
            `}
          />
          <Flex
            css={css`
              position: absolute;
              right: 10px;
              bottom: 0;
            `}
            horizontal="center"
          >
            <button
              css={[
                buttonReset,
                css`
                  height: 48px;
                  width: 48px;
                  background-color: ${colors.black};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 48px;
                  z-index: 2;
                `,
              ]}
              onClick={() => {
                const randomData = makeRandomFaceAvatar()
                const shirt = selectedShirt ?? {
                  Chest: avatarParts?.Chest,
                  Arms: avatarParts?.Arms,
                  Shoulders: avatarParts?.Shoulders,
                  Collar: avatarParts?.Collar,
                  Sleeves: avatarParts?.Sleeves,
                }

                const newAvatarData = JSON.stringify({
                  ...randomData,
                  ...shirt,
                })
                setParts(makeAvatarParts(JSON.parse(newAvatarData)))
                setAvatarData(JSON.parse(newAvatarData))
                setHasMadeChanges(true)
              }}
            >
              <FaRandom size={24} color={colors.white} />
            </button>
          </Flex>
        </Flex>

        <Spacer height={'medium'} />

        <TextNew
          textAlign="center"
          italic
          color={colors.black}
          css={[
            prettyLetterSpacing,
            css`
              font-size: 20px;
              font-weight: 600;
              width: 100%;
            `,
          ]}
        >
          {t(`Choose a kit`)}
        </TextNew>

        <SelectableButtons title={''} titleColor={colors.black}>
          {shirts.map((kit: Shirt, index) => (
            <KitButton
              isSelected={selectedShirt === kit}
              onClick={() => {
                setSelectedShirt(kit)
                setHasMadeChanges(true)
              }}
              height={'100%'}
              width={'fit-content'}
              key={index}
            >
              <Avatar
                userId={index.toString()} // Just an unique ID to differentiate each svg (They need to use different classes)
                height={100}
                FGColor={'#191919'}
                BGColor={colors.green600}
                partsOverride={{
                  ...parts,
                  Chest: { ...avatarParts.Chest, ...kit.Chest },
                  Arms: { ...avatarParts.Arms, ...kit.Arms },
                  Shoulders: { ...avatarParts.Shoulders, ...kit.Shoulders },
                  Collar: { ...avatarParts.Collar, ...kit.Collar },
                  Sleeves: { ...avatarParts.Sleeves, ...kit.Sleeves },
                }}
                //hideOthers //If you want to hide eyes/brows/mouth
              />
            </KitButton>
          ))}
        </SelectableButtons>

        <Spacer height={'medium'} />

        <Flex gap="medium" horizontal="center">
          {isExistingAvatar && (
            <SecondaryButton
              disabled={loading}
              onClick={() => {
                closeCreator()
              }}
            >
              {t(`Close`)}
            </SecondaryButton>
          )}

          {hasMadeChanges && (
            <PrimaryButton
              disabled={loading || !saveIsAvailable}
              onClick={async () => {
                trackEvent(AnalyticsEventType.UserAvatar, {
                  create: isBase,
                  edit: !isBase,
                })

                const avatarData = makeAvatarData(parts)
                await setAvatarMutation({
                  variables: {
                    input: avatarData,
                  },
                }).then(() => {
                  window.localStorage.removeItem('avatarData')
                  setSelectedShirt(undefined)
                })
                onSave()
                closeCreator()
              }}
            >
              {saveIsAvailable ? t(`Save`) : t('Please wait')}
            </PrimaryButton>
          )}
        </Flex>
        <Spacer height={'medium'} />
        <TextNew
          textAlign="center"
          italic
          color={colors.grey400}
          css={[
            prettyLetterSpacing,
            css`
              font-size: 20px;
              font-weight: 600;
            `,
          ]}
        >
          {t(`You can change your avatar at any time!`)}
        </TextNew>
      </Flex>
    </PageContent>
  )
}

export const KitButton = (props: {
  isSelected: boolean
  onClick: any
  children: any
  height?: string
  width?: string
  premium?: boolean
}) => (
  <PrimaryButton
    css={[
      css`
        border-radius: 4px;
        min-height: ${props.height ?? '62px'};
        max-height: ${props.height ?? '62px'};
        min-width: ${props.width ?? '62px'};
        max-width: ${props.width ?? '62px'};
        background-color: ${props.isSelected ? 'white' : colors._grey470};
        display: flex;
        align-items: center;
        justify-content: center;
      `,
      props.premium &&
        css`
          border: 1px solid #ffd700;
        `,
    ]}
    onClick={props.onClick}
  >
    {props.children}
  </PrimaryButton>
)

// Euros 2024 kits
const predefinedKits = [
  // Germany
  {
    Chest: { style: 'ChestA', colors: ['#ffffff', '#FECB17'] },
    Arms: { style: 'ArmsH', colors: ['#FECB17', '#ffffff'] },
    Shoulders: { style: 'ShouldersF', colors: ['#930A02', '#202020'] },
    Collar: { style: 'CollarA', colors: ['#202020', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#EE7836', '#FECB17'] },
    IsBase: false,
  },
  // Italy
  {
    Chest: { style: 'ChestA', colors: ['#003ACF', '#FECB17'] },
    Arms: { style: 'ArmsH', colors: ['#C63C34', '#003ACF'] },
    Shoulders: { style: 'ShouldersF', colors: ['#ffffff', '#1EBE41'] },
    Collar: { style: 'CollarA', colors: ['#003ACF', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#EE7836', '#FECB17'] },
    IsBase: false,
  },
  // England
  {
    Chest: { style: 'ChestA', colors: ['#ffffff', '#FECB17'] },
    Arms: { style: 'ArmsH', colors: ['#ffffff', '#ffffff'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#1EBE41'] },
    Collar: { style: 'CollarE', colors: ['#100191', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#100191', '#FECB17'] },
    IsBase: false,
  },
  // Spain
  {
    Chest: { style: 'ChestA', colors: ['#C63C34', '#FECB17'] },
    Arms: { style: 'Arms0', colors: ['#C63C34', '#ffffff'] },
    Shoulders: { style: 'ShouldersC', colors: ['#FECB17', '#C63C34'] },
    Collar: { style: 'CollarA', colors: ['#C63C34', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#C63C34', '#FECB17'] },
    IsBase: false,
  },
  // Belgium
  {
    Chest: { style: 'ChestA', colors: ['#7A9FFF', '#FECB17'] },
    Arms: { style: 'Arms0', colors: ['#C63C34', '#ffffff'] },
    Shoulders: { style: 'ShouldersC', colors: ['#ffffff', '#7A9FFF'] },
    Collar: { style: 'CollarE', colors: ['#ffffff', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#ffffff', '#FECB17'] },
    IsBase: false,
  },
  // France
  {
    Chest: { style: 'ChestA', colors: ['#003ACF', '#FECB17'] },
    Arms: { style: 'Arms0', colors: ['#C63C34', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#1EBE41'] },
    Collar: { style: 'CollarB', colors: ['#930A02', '#ffffff'] },
    Sleeves: { style: 'Sleeves0', colors: ['#ffffff', '#930A02'] },
    IsBase: false,
  },
  // Portugal
  {
    Chest: { style: 'ChestA', colors: ['#930A02', '#FECB17'] },
    Arms: { style: 'Arms0', colors: ['#C63C34', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#1EBE41'] },
    Collar: { style: 'CollarA', colors: ['#034B13', '#1EBE41'] },
    Sleeves: { style: 'SleevesA', colors: ['#034B13', '#930A02'] },
    IsBase: false,
  },
  // Denmark, Switzerland, Austria
  {
    Chest: { style: 'ChestA', colors: ['#930A02', '#FECB17'] },
    Arms: { style: 'Arms0', colors: ['#C63C34', '#ffffff'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#7A9FFF'] },
    Collar: { style: 'CollarA', colors: ['#ffffff', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#ffffff', '#C63C34'] },
    IsBase: false,
  },
  // Turkey
  {
    Chest: { style: 'ChestL', colors: ['#ffffff', '#C63C34'] },
    Arms: { style: 'ArmsA', colors: ['#ffffff', '#ffffff'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#7A9FFF'] },
    Collar: { style: 'CollarE', colors: ['#ffffff', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#ffffff', '#C63C34'] },
    IsBase: false,
  },
  // Georgia
  {
    Chest: { style: 'ChestC', colors: ['#ffffff', '#930A02'] },
    Arms: { style: 'ArmsD', colors: ['#ffffff', '#930A02'] },
    Shoulders: { style: 'ShouldersG', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#930A02', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#ffffff', '#C63C34'] },
    IsBase: false,
  },
  // Slovenia
  {
    Chest: { style: 'ChestB', colors: ['#003ACF', '#202020'] },
    Arms: { style: 'ArmsD', colors: ['#202020', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#003ACF', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#ffffff', '#C63C34'] },
    IsBase: false,
  },
  // Romania
  {
    Chest: { style: 'ChestA', colors: ['#FFE456', '#202020'] },
    Arms: { style: 'Arms0', colors: ['#202020', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarC', colors: ['#FFE456', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#C63C34', '#3A6DF2'] },
    IsBase: false,
  },
  // Slovakia
  {
    Chest: { style: 'ChestA', colors: ['#003ACF', '#202020'] },
    Arms: { style: 'ArmsD', colors: ['#930A02', '#003ACF'] },
    Shoulders: { style: 'Shoulders0', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#930A02', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#C63C34', '#3A6DF2'] },
    IsBase: false,
  },
  // Belgium home
  {
    Chest: { style: 'ChestA', colors: ['#C63C34', '#202020'] },
    Arms: { style: 'ArmsH', colors: ['#202020', '#C63C34'] },
    Shoulders: { style: 'ShouldersF', colors: ['#FECB17', '#930A02'] },
    Collar: { style: 'Collar0', colors: ['#202020', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#C63C34', '#3A6DF2'] },
    IsBase: false,
  },
  // Germany away
  {
    Chest: { style: 'ChestA', colors: ['#991D79', '#202020'] },
    Arms: { style: 'ArmsA', colors: ['#FFB7EC', '#C63C34'] },
    Shoulders: { style: 'ShouldersC', colors: ['#FFDDF6', '#FFB7EC'] },
    Collar: { style: 'CollarA', colors: ['#FFB7EC', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#C63C34', '#3A6DF2'] },
    IsBase: false,
  },
  // France alternative
  {
    Chest: { style: 'ChestA', colors: ['#3A6DF2', '#202020'] },
    Arms: { style: 'ArmsH', colors: ['#003ACF', '#3A6DF2'] },
    Shoulders: { style: 'ShouldersF', colors: ['#ffffff', '#930A02'] },
    Collar: { style: 'Collar0', colors: ['#7A9FFF', '#FECB17'] },
    Sleeves: { style: 'Sleeves0', colors: ['#C63C34', '#3A6DF2'] },
    IsBase: false,
  },
  // Netherlands
  {
    Chest: { style: 'ChestF', colors: ['#202020', '#EE7836'] },
    Arms: { style: 'ArmsA', colors: ['#EE7836', '#3A6DF2'] },
    Shoulders: { style: 'ShouldersA', colors: ['#EE7836', '#930A02'] },
    Collar: { style: 'CollarA', colors: ['#202020', '#FECB17'] },
    Sleeves: { style: 'SleevesA', colors: ['#202020', '#3A6DF2'] },
    IsBase: false,
  },
]

type Shirt = {
  Chest: { style: string; colors: string[] }
  Arms: { style: string; colors: string[] }
  Shoulders: { style: string; colors: string[] }
  Collar: { style: string; colors: string[] }
  Sleeves: { style: string; colors: string[] }
  IsBase: boolean
}
