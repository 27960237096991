/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from 'react'
import { DefaultError } from '../../components/DefaultError'
import { margin } from '../../styles/margin'
import { ChallengeLeagueQuizInstance } from '../ChallengeMatch/queries'
import {
  QuizState,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import {
  LeagueQuizInstanceStatus,
  useNextQuestionMutation,
} from '../LeagueQuiz/queries'
import { ChallengeActiveQuestion } from './ChallengeActiveQuestion'
import { ChallengeFinishedQuiz } from './ChallengeFinishedQuiz'
import { ChallengeLastCompletedQuestion } from './ChallengeLastCompletedQuestion'
import { ChallengeQuizCountdown } from './ChallengeQuizCountdown'
import { ChallengeQuizStartScreen } from './ChallengeQuizStartScreen'

export const ChallengeQuiz = ({
  instance,
}: {
  instance: ChallengeLeagueQuizInstance
}) => {
  const leagueQuiz = instance.leagueQuiz
  const completedQuestions = instance.challengeCompletedQuestions
  const activeQuestion = instance.activeQuestion

  const [nextQuestionMutation, { loading: nextQuestionLoading }] =
    useNextQuestionMutation()

  const [finished, setFinished] = useState<boolean>(
    instance.status === LeagueQuizInstanceStatus.completed
  )

  // This determines if the play count animation should be played | default: false
  const [playCountAnimation, setPlayCountAnimation] = useState<boolean>(false)

  const { quizState, setQuizState, setIsExpandedAdActive } =
    useLeagueQuizContext()

  const allQuestionsAnswered =
    completedQuestions.length === leagueQuiz.questionsCount

  const lastCompletedQuestion =
    !activeQuestion && completedQuestions.length > 0
      ? completedQuestions[completedQuestions.length - 1]
      : null

  useEffect(() => {
    if (!activeQuestion && !lastCompletedQuestion) {
      setIsExpandedAdActive(false)
      setQuizState(QuizState.START_SCREEN)
    } else {
      setQuizState(QuizState.ACTIVE_QUIZ)
    }
  }, [activeQuestion, lastCompletedQuestion])

  const nextQuestion = useCallback(
    () =>
      nextQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: instance.id,
            challengeQuizInstanceId: instance.challengeQuizInstanceId!,
            challengeMatchId: instance.challengeMatchId!,
          },
        },
      }),
    [instance.id, instance.challengeQuizInstanceId, nextQuestionMutation]
  )

  useEffect(() => {
    if (finished) {
      setQuizState(QuizState.FINISHED)
    }
  }, [finished])

  const triggerCalculation = () => {
    setIsExpandedAdActive(true)
    setPlayCountAnimation(true)
    setFinished(true)
  }

  if (!instance.challengeUser) {
    return (
      <DefaultError
        css={margin.top('huge')}
        sentryErrorMessage={`ChallengeQuiz | challengeUser is undefined`}
      />
    )
  }

  if (quizState === QuizState.START_SCREEN) {
    return (
      <ChallengeQuizStartScreen
        leagueQuiz={instance.leagueQuiz}
        colorTheme={'white'}
      />
    )
  } else if (!activeQuestion && !lastCompletedQuestion) {
    return (
      <ChallengeQuizCountdown
        nextQuestion={nextQuestion}
        colorTheme={'white'}
        nextQuestionLoading={nextQuestionLoading}
      />
    )
  } else if (finished) {
    return (
      <ChallengeFinishedQuiz
        instance={instance}
        playAnimation={playCountAnimation}
      />
    )
  } else if (activeQuestion) {
    return (
      <ChallengeActiveQuestion
        key={activeQuestion.id}
        instance={instance}
        numCompletedQuestions={
          completedQuestions.length + (activeQuestion ? 1 : 0)
        }
        activeQuestion={activeQuestion}
        quizInstanceId={instance.id}
      />
    )
  } else if (lastCompletedQuestion) {
    return (
      <ChallengeLastCompletedQuestion
        leagueQuiz={leagueQuiz}
        lastCompletedQuestion={lastCompletedQuestion}
        allQuestionsAnswered={allQuestionsAnswered}
        triggerCalculation={triggerCalculation}
        nextQuestionLoading={nextQuestionLoading}
        nextQuestion={nextQuestion}
        colorTheme={'white'}
        challengeUser={instance.challengeUser}
      />
    )
  }

  return null
}
