/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { BiChevronDown, BiChevronRight } from 'react-icons/bi'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { colors } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import { UserStats } from './queries'
import { useT } from '../../lib/i18n/useT'

export const UserStatsComponent = ({
  stats,
  className,
}: {
  stats: UserStats
  className?: string
}) => {
  const t = useT()
  const textColor = colors.white

  const [expanded, setExpanded] = useState(false)

  const formatDate = useFormatDate()
  return (
    <Flex
      column
      gap="small"
      css={css`
        border: 1px solid ${colors.white};
        padding: 16px;
        border-radius: 8px;
        width: 100%;
      `}
      className={className}
    >
      <Flex horizontal="space-between" vertical="center">
        <TextNew
          color={textColor}
          strong
          css={css`
            white-space: nowrap;
          `}
        >
          {t(`Stats`)}
        </TextNew>
        <Button
          onClick={() => setExpanded(!expanded)}
          css={css`
            display: flex;
            padding: 4px 0 4px 32px;
          `}
        >
          {expanded ? (
            <BiChevronDown color={textColor} size={32} />
          ) : (
            <BiChevronRight color={textColor} size={32} />
          )}
        </Button>
      </Flex>
      {expanded && (
        <>
          <Divider color={textColor} />
          <Flex horizontal="space-between">
            <TextNew color={textColor}>{t('Total score')}</TextNew>
            <TextNew color={textColor}>
              {Intl.NumberFormat('de-DE', {
                style: 'decimal',
              })
                .format(stats.score)
                .toString()
                .replaceAll('.', ' ')}
            </TextNew>
          </Flex>
          <Flex horizontal="space-between">
            <TextNew color={textColor}>{t('Quizzes played')}</TextNew>
            <TextNew color={textColor}>
              {Intl.NumberFormat('de-DE', {
                style: 'decimal',
              })
                .format(stats.quizzesPlayed)
                .toString()
                .replaceAll('.', ' ')}
            </TextNew>
          </Flex>
          <Flex horizontal="space-between">
            <TextNew color={textColor}>{t(`Text quizzes`)}</TextNew>
            <TextNew color={textColor}>
              {Intl.NumberFormat('de-DE', {
                style: 'decimal',
              })
                .format(stats.textQuizzes)
                .toString()
                .replaceAll('.', ' ')}
            </TextNew>
          </Flex>
          <Flex horizontal="space-between">
            <TextNew color={textColor}>{t(`Career path quizzes`)}</TextNew>
            <TextNew color={textColor}>
              {Intl.NumberFormat('de-DE', {
                style: 'decimal',
              })
                .format(stats.careerPathQuizzes)
                .toString()
                .replaceAll('.', ' ')}
            </TextNew>
          </Flex>
          <Flex horizontal="space-between">
            <TextNew color={textColor}>{t(`Quiz series played`)}</TextNew>
            <TextNew color={textColor}>
              {Intl.NumberFormat('de-DE', {
                style: 'decimal',
              })
                .format(stats.uniqueLeagues)
                .toString()
                .replaceAll('.', ' ')}
            </TextNew>
          </Flex>

          {stats.firstQuizDate && (
            <Flex horizontal="space-between">
              <TextNew color={textColor}>{t(`Played since`)}</TextNew>
              <TextNew color={textColor}>
                {formatDate(new Date(stats.firstQuizDate), 'full-date')}
              </TextNew>
            </Flex>
          )}

          {stats.lastQuizDate && (
            <Flex horizontal="space-between">
              <TextNew color={textColor}>{t(`Last played`)}</TextNew>
              <TextNew color={textColor}>
                {formatDate(new Date(stats.lastQuizDate), 'full-date')}
              </TextNew>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}
