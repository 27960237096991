/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { shuffle } from 'shuffle-seed'
import Fallback1 from '../../assets/fallbackImages/fallback1.jpg'
import Fallback2 from '../../assets/fallbackImages/fallback2.jpg'
import Fallback3 from '../../assets/fallbackImages/fallback3.jpg'
import Fallback4 from '../../assets/fallbackImages/fallback4.jpg'
import Fallback5 from '../../assets/fallbackImages/fallback5.jpg'
import { PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors, hexOpacity, hexToRgba } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import {
  calculateTimeRemaining,
  colorIsLight,
  datePlus,
  timeRemainingToStringClock,
} from '../Admin/Utils'
import { useStartQuickMatchMutation } from '../ChallengeMatch/queries'
import { LeagueQuizMinimal } from '../League/queries'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { HomePageLeagueQuiz } from './queries'

export const DeadlineAndPlayers = ({
  hideDeadline = false,
  color,
  currentQuiz,
  participantCount,
  live,
}: {
  hideDeadline?: boolean
  color: string
  currentQuiz?: LeagueQuizMinimal
  participantCount?: number
  live?: number
}) => {
  const _timeRemaining = () => {
    if (!currentQuiz) return ''
    const todaysQuiz = currentQuiz
    let endDate = datePlus(new Date(), { hours: 23, minutes: 59, seconds: 59 })
    if (todaysQuiz && todaysQuiz.availableTo) {
      endDate = new Date(todaysQuiz.availableTo)
    }
    return timeRemainingToStringClock(
      calculateTimeRemaining(new Date(), endDate)
    )
  }

  const [timeRemaining, setTimeRemaining] = useState(_timeRemaining())

  useEffect(() => {
    setTimeRemaining(_timeRemaining())
  }, [])

  useInterval(() => {
    setTimeRemaining(_timeRemaining())
  }, 1000)
  const t = useT()
  return (
    <>
      <Flex
        css={[
          css`
            position: absolute;
            left: 15px;
            bottom: 20px;
          `,
          hideDeadline &&
            css`
              display: none;
            `,
        ]}
        column
      >
        <TextNew size={6} strong italic uppercase color={color}>
          {t(`Deadline`)}
        </TextNew>
        <TextNew size={26} italic condensed color={color}>
          {timeRemaining}
        </TextNew>
      </Flex>
      <Flex
        css={css`
          position: absolute;
          right: 15px;
          bottom: 20px;
        `}
        horizontal="flex-end"
        column
      >
        <TextNew
          size={8} //PageContentNew: 10
          strong
          italic
          uppercase
          color={color}
        >
          {participantCount ?? 0} {t(`Players`)}
        </TextNew>
        {live && (
          <TextNew
            size={8} //PageContentNew: 10
            strong
            italic
            uppercase
            color={color}
          >
            {live} Live
          </TextNew>
        )}
      </Flex>
    </>
  )
}

export const GradientOverlay = (props: { gradient: string }) => (
  <Flex
    css={[
      css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 15px;
      `,
      props.gradient,
    ]}
  />
)

export const BlackOverlay = (props: {
  overlayDarker?: boolean
  gradientStartColor?: string
  gradient?: boolean
  gradientDarker?: boolean
}) => (
  <Flex
    css={[
      css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        z-index: -1;
        border-radius: 10px;
      `,
      props.gradient
        ? css`
            background: linear-gradient(
              180deg,
              ${props.gradientStartColor},
              rgba(0, 0, 0, ${props.gradientDarker ? '0.61' : '0'}) 100%
            );
          `
        : `background: ${hexOpacity(
            colors.black,
            props.overlayDarker ? 0.85 : 0.66
          )}`,
    ]}
  />
)

// Had to be its own object (and not a background of the parent flex)
// Else it would either:
// * block the button click
// * visually block the black overlay
export const Banner = (props: { imageURL: string }) => (
  <Flex
    css={css`
      background: url(${props.imageURL});
      opacity: 0.5;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      position: absolute;
      z-index: -1;
    `}
  />
)

const NoActiveQuiz = ({ textColor }: { textColor: string }) => {
  const t = useT()
  return (
    <HeadingNew
      color={textColor}
      italic
      condensed
      weight={500}
      level={3}
      looksLikeLevel={5}
    >
      {t('No active quizzes')}
    </HeadingNew>
  )
}

export const PopularQuizzesComponent = (props: {
  quizzes: HomePageLeagueQuiz[]
}) => {
  const { quizzes } = props
  const { authUser, signInAsAnonymous } = useAuthContext()

  const [startQuickMatchMutation, { loading: quizMatchLoading }] =
    useStartQuickMatchMutation()
  const [startLeagueQuizMutation, { loading: startQuizLoading }] =
    useStartLeagueQuizMutation()

  const [startCareerPathQuizMutation, { loading: startCareerPathQuizLoading }] =
    useStartCareerPathQuizMutation()

  const navigate = useNavigate()
  const t = useT()

  const fallBackImages = [Fallback1, Fallback2, Fallback3, Fallback4, Fallback5]

  const StartButton = ({ quiz }: { quiz: HomePageLeagueQuiz }) => (
    <PrimaryButton
      variant="white"
      css={css`
        border-radius: 99px;
        padding: 0px 40px;
      `}
      disabled={
        quizMatchLoading || startQuizLoading || startCareerPathQuizLoading
      }
      onClick={async () => {
        if (!authUser) {
          await signInAsAnonymous()
        }

        if (quiz.quizType === 'careerPath') {
          startCareerPathQuizMutation({
            variables: { input: { leagueQuizId: quiz.id } },
          }).then((result) => {
            const quizInstanceId = result.data?.startCareerPathQuiz.id
            if (quizInstanceId) {
              trackEvent(AnalyticsEventType.StartQuiz, {
                quizId: quiz.id,
                leagueId: quiz.league?.id,
                isCareerPath: true,
              })
              navigate(`/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`)
            }
          })
          return
        }

        if (quiz.viewerCanQuickMatch) {
          const res = await startQuickMatchMutation({
            variables: { input: { quizId: quiz.id } },
          })

          const match = res.data?.startQuickMatch

          if (match) {
            trackEvent(AnalyticsEventType.QuickMatchStarted, {
              leagueQuizId: quiz.id,
            })
            trackEvent(AnalyticsEventType.StartQuiz, {
              quizId: quiz.id,
              leagueId: quiz.league?.id,
              isEmbed: false,
            })

            navigate(`/challenge-match/${match.id}`)
            return
          }
        }

        startLeagueQuizMutation({
          variables: { input: { leagueQuizId: quiz.id } },
        }).then((result) => {
          const quizInstanceId = result.data?.startLeagueQuiz.id
          if (quizInstanceId) {
            trackEvent(AnalyticsEventType.StartQuiz, {
              quizId: quiz.id,
              leagueId: quiz.league?.id,
              isEmbed: false,
            })
            navigate(`/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`)
          }
        })
      }}
    >
      <TextNew
        size={24}
        condensed
        italic
        strong
        css={css`
          white-space: nowrap;
        `}
      >
        {t(`Play the Quiz`)}
      </TextNew>
    </PrimaryButton>
  )

  const CallToAction = ({
    textColor,
    quiz,
  }: {
    textColor: string
    quiz: HomePageLeagueQuiz
  }) => {
    const canStart =
      quiz &&
      (quiz.viewerCanStartQuiz ||
        (!quiz.viewerData.hasCompletedQuiz && quiz.status === 'active'))

    if (canStart) {
      return <StartButton quiz={quiz} />
    }

    return <NoActiveQuiz textColor={textColor} />
  }

  return (
    <Flex column gap={'medium'}>
      {quizzes.length > 1 &&
        quizzes.slice(0, 3).map((quiz, index) => {
          const league = quiz.league
          const leagueColor = league?.color ?? colors.black
          const textColor = colorIsLight(leagueColor)
            ? colors.black
            : colors.white

          const imageUrl =
            quiz.imageUrl ??
            league?.imageUrl ??
            shuffle(fallBackImages, league.id)[0]

          const title = quiz.title
          const leagueTitle = league?.title

          return (
            <Flex
              key={`popular-quiz-${index}-${quiz.id}`}
              vertical="center"
              css={css`
                height: 160px;
                position: relative;
                border-radius: 15px;
                filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.4));
              `}
              column
              horizontal="center"
            >
              {quiz.league.viewerIsSubscribed && (
                <Flex
                  css={css`
                    position: absolute;
                    top: -8px;
                    left: -8px;
                    padding: 4px 16px;
                    background-color: ${colors.yellow400};
                    border-radius: 16px;
                    box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.25)};
                  `}
                >
                  <TextNew
                    strong
                    italic
                    condensed
                    css={css`
                      font-size: 16px;
                    `}
                  >
                    {t(`New!`)}
                  </TextNew>
                </Flex>
              )}
              <GradientOverlay
                gradient={`background-color: ${
                  league?.imageUrl ? colors.black : leagueColor
                }`}
              />

              <>
                <Banner imageURL={imageUrl} />
                <GradientOverlay
                  gradient={`background: linear-gradient(171deg, ${hexToRgba(
                    leagueColor,
                    0.7
                  )} 0%, ${hexToRgba(leagueColor, 0)} 90%);`}
                />
              </>

              <Flex column>
                <Flex
                  column
                  horizontal="center"
                  css={css`
                    width: 100%;
                  `}
                >
                  {leagueTitle && (
                    <HeadingNew
                      css={css`
                        white-space: nowrap;
                        padding: 0 10px;
                        line-height: 1.2;

                        text-align: center;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0;
                        margin-bottom: -5px;

                        font-size: ${title.length > 30 ? 20 : 25}px;

                        @media (max-width: 750px) {
                          max-width: 500px;
                        }

                        @media (max-width: 599px) {
                          max-width: 350px;
                        }

                        @media (max-width: 450px) {
                          font-size: ${title.length > 30 ? 18 : 20}px;
                          max-width: 300px;
                        }
                      `}
                      italic
                      weight={500}
                      condensed
                      level={2}
                      color={textColor}
                    >
                      {leagueTitle}
                    </HeadingNew>
                  )}
                  <HeadingNew
                    css={css`
                      white-space: nowrap;
                      padding: 0 10px;
                      line-height: 1.2;
                      margin: 0;

                      text-align: center;
                      text-overflow: ellipsis;
                      overflow: hidden;

                      font-size: ${title.length > 30 ? 35 : 45}px;

                      @media (max-width: 750px) {
                        max-width: 500px;
                      }

                      @media (max-width: 599px) {
                        max-width: 350px;
                      }

                      @media (max-width: 450px) {
                        font-size: ${title.length > 30 ? 25 : 30}px;
                        max-width: 300px;
                      }
                    `}
                    italic
                    weight={600}
                    condensed
                    level={3}
                    color={textColor}
                  >
                    {title}
                  </HeadingNew>
                </Flex>
                <Spacer height={10} />
                <Flex column horizontal="center">
                  <Flex
                    css={css`
                      height: 40px;
                    `}
                  >
                    <CallToAction
                      textColor={league.imageUrl ? colors.white : textColor}
                      quiz={quiz}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <DeadlineAndPlayers
                hideDeadline
                color={league.imageUrl ? colors.white : textColor}
                participantCount={quiz.participantCount}
              />
            </Flex>
          )
        })}
    </Flex>
  )
}
