/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { SelectInput } from '../../components/SelectInput'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { useViewerQuery } from '../../lib/AuthContext'
import { Spacer, margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { EmbedCodeModal } from '../Clubs/ClubProfilePage/EmbedCodeModal'
import { HomePageLeague } from '../Home/queries'
import { useLibraryLeagueQuery } from '../Library/queries'
import {
  AdminPageClub,
  useAdminClubsQuery,
  useSetClubExternalSiteUrlMutation,
  useSubscribeClubtoLeagueMutation,
  useToggleClubSubShowVideoAdMutation,
  useUnsubscribeClubFromLeagueMutation,
} from './queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

const ManageClubSubscriptionModal = ({
  club,
  closeModal,
  onAdded,
  leagues,
}: {
  club: AdminPageClub
  closeModal: () => void
  onAdded: () => void
  leagues: HomePageLeague[]
}) => {
  const [addAsLeagueOwner, setAddAsLeagueOwner] = useState(false)

  const [subscribeClubToLeagueMutation] = useSubscribeClubtoLeagueMutation()
  const [unsubscribeClubFromLeagueMutation] =
    useUnsubscribeClubFromLeagueMutation()

  const [userWarnedAboutLeagueId, setUserWarnedAboutLeagueId] = useState<
    string | undefined
  >(undefined)
  useEffect(() => {
    if (!userWarnedAboutLeagueId) {
      return
    }

    const timeout = setTimeout(() => {
      setUserWarnedAboutLeagueId(undefined)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [userWarnedAboutLeagueId, setUserWarnedAboutLeagueId])

  return (
    <Modal closeModal={closeModal} heading={'Subscriptions'}>
      <Flex
        column
        css={css`
          width: 100%;
        `}
        gap="small"
      >
        <Text strong size="large">
          {club.name}
        </Text>
        {leagues.map((league) => {
          const clubIsSubscribed = club.leagueSubscriptions.some(
            (sub) => sub.leagueId === league.id
          )
          return (
            <Flex
              key={league.id}
              css={css`
                width: 100%;
                background-color: ${clubIsSubscribed
                  ? colors.green100
                  : colors.grey100};
                padding: 4px 8px;
                min-height: 64px;
              `}
              vertical="center"
              horizontal="space-between"
            >
              <Text>{league.title}</Text>
              {!clubIsSubscribed && (
                <Flex column>
                  <SecondaryButton
                    onClick={async () => {
                      await subscribeClubToLeagueMutation({
                        variables: {
                          clubIdOrSlug: club.slug,
                          leagueId: league.id,
                          isOwner: addAsLeagueOwner,
                        },
                      })
                      closeModal()
                      onAdded()
                    }}
                  >
                    <Text>{'Subscribe'}</Text>
                  </SecondaryButton>
                  <Spacer height="small" />
                  <ToggleSwitch
                    value={addAsLeagueOwner}
                    onValue={setAddAsLeagueOwner}
                    label={'Is owner of league'}
                  />
                </Flex>
              )}
              {clubIsSubscribed && (
                <SecondaryButton
                  variant="dangerRed"
                  onClick={async () => {
                    if (
                      !userWarnedAboutLeagueId ||
                      userWarnedAboutLeagueId !== league.id
                    ) {
                      setUserWarnedAboutLeagueId(league.id)
                      return
                    }

                    await unsubscribeClubFromLeagueMutation({
                      variables: {
                        clubIdOrSlug: club.slug,
                        leagueId: league.id,
                      },
                    })
                    closeModal()
                    onAdded()
                  }}
                >
                  <Text color={colors.red500}>
                    {userWarnedAboutLeagueId == league.id
                      ? 'Click again to confirm'
                      : 'Unsubscribe'}
                  </Text>
                </SecondaryButton>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Modal>
  )
}

// only hostname, ie fcquiz.app
const validUrl = (str: string) => {
  return (
    str.includes('.') &&
    !str.includes(' ') &&
    !str.includes('http') &&
    !str.includes('/') &&
    !str.includes('www')
  )
}

const EditClubUrlModal = ({
  club,
  closeModal,
  onUrlChanged,
}: {
  club: AdminPageClub
  closeModal: () => void
  onUrlChanged: () => void
}) => {
  const [edited, setEdited] = useState(false)
  const [url, setUrl] = useState(club.externalSiteUrl ?? '')
  const [urlIsvaild, setUrlIsValid] = useState(validUrl(url ?? ''))

  const [setClubExternalSiteURl] = useSetClubExternalSiteUrlMutation()

  const urlChanged = url !== club.externalSiteUrl

  return (
    <Modal closeModal={closeModal} heading={'Club external url'}>
      <Flex column>
        <Text extraCondensed size="medium">
          {'Enter the external site URL for this club, e.g : fcquiz.app'}
        </Text>
        <Spacer height="small" />
        <input
          type="text"
          value={url}
          onChange={(value) => {
            const str = value.target.value
            if (!edited) {
              setEdited(true)
            } else {
              if (str === club.externalSiteUrl) {
                setEdited(false)
              }
            }
            setUrl(str)
            setUrlIsValid(validUrl(str))
          }}
          css={css`
            width: 100%;
            height: 40px;
            padding-left: 8px;
          `}
        />
        <Spacer height="medium" />
        {edited && !urlIsvaild && (
          <>
            <Spacer height="small" />
            <Text extraCondensed size="medium">
              {'The URL is invalid, do not include http://, www. spaces or /'}
            </Text>
          </>
        )}
        <PrimaryButton
          css={css`
            opacity: ${urlIsvaild && urlChanged ? 1 : 0.5};
          `}
          onClick={async () => {
            if (!urlChanged || !urlIsvaild) {
              return
            }

            const res = await setClubExternalSiteURl({
              variables: {
                input: {
                  clubIdOrSlug: club.slug,
                  externalSiteUrl: url,
                },
              },
            })

            if (res.data) {
              closeModal()
              // invalidate cache
              onUrlChanged()
            }
          }}
        >
          <Text color="white">{'Update'}</Text>
        </PrimaryButton>
      </Flex>
    </Modal>
  )
}

const AdminClubComponent = ({
  club,
  refetch,
  leagues,
}: {
  club: AdminPageClub
  refetch: () => void
  leagues: HomePageLeague[]
}) => {
  const navigate = useNavigate()

  const [toggleShowAd] = useToggleClubSubShowVideoAdMutation()

  return (
    <Flex
      key={club.id}
      column
      css={css`
        background-color: ${colors.yellow100};
        padding: 12px 16px;
        border-radius: 8px;
      `}
    >
      <Flex horizontal="space-between">
        <Text extraCondensed strong size="huge">
          {club.name}
        </Text>
        <SecondaryButton
          onClick={() => {
            navigate(`/clubs/${club.slug}`)
          }}
        >
          <Text extraCondensed size="small">
            {'View'}
          </Text>
        </SecondaryButton>
      </Flex>
      <Text extraCondensed size="small">
        {club.slug}
      </Text>
      <Spacer height="small" />
      {club.verified &&
        club.externalSiteUrl &&
        validUrl(club.externalSiteUrl) && (
          <Flex
            vertical="center"
            horizontal="space-between"
            css={css`
              background-color: ${colors.grey100};
              padding: 4px 8px;
              border-radius: 4px;
            `}
          >
            <Text extraCondensed size="medium">
              {club.externalSiteUrl}
            </Text>

            <ModalTrigger
              button={({ openModal }) => (
                <SecondaryButton onClick={openModal}>
                  <Text extraCondensed size="small">
                    {'Edit'}
                  </Text>
                </SecondaryButton>
              )}
              modal={({ closeModal }) => (
                <EditClubUrlModal
                  club={club}
                  closeModal={closeModal}
                  onUrlChanged={refetch}
                />
              )}
            />
          </Flex>
        )}
      <Spacer height="medium" />
      {club.verified && !club.externalSiteUrl && (
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton
              onClick={openModal}
              css={css`
                margin-bottom: 16px;
              `}
            >
              <Text extraCondensed size="small">
                {'Add external site URL'}
              </Text>
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <EditClubUrlModal
              club={club}
              closeModal={closeModal}
              onUrlChanged={refetch}
            />
          )}
        />
      )}

      <Flex column gap="small">
        {club.leagueSubscriptions.map((sub) => {
          return (
            <Flex
              column
              gap="medium"
              key={sub.id}
              css={css`
                width: 100%;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: ${colors.green100};
                width: 100%;
              `}
            >
              <Flex horizontal="space-between">
                <button
                  css={[buttonReset]}
                  onClick={() => {
                    // copy to clipboard
                    navigator.clipboard.writeText(sub.id)
                  }}
                >
                  <Text extraCondensed size="small">
                    {sub.id}
                  </Text>
                </button>
                <Flex column horizontal="flex-end" vertical="center">
                  <Text extraCondensed size="small" strong>
                    {sub.leagueTitle}
                  </Text>
                  <button
                    css={[buttonReset]}
                    onClick={() => {
                      // copy to clipboard
                      navigator.clipboard.writeText(sub.leagueId)
                    }}
                  >
                    <Text extraCondensed size="small">
                      {sub.leagueId}
                    </Text>
                  </button>
                </Flex>
              </Flex>
              <ToggleSwitch
                value={sub.showVideoAd}
                onValue={async (_) => {
                  toggleShowAd({
                    variables: {
                      input: {
                        clubId: club.id,
                        clubLeagueSubscriptionId: sub.id,
                      },
                    },
                  })
                }}
                label={'Video ads'}
              />
              <ModalTrigger
                button={({ openModal }) => (
                  <SecondaryButton onClick={openModal}>
                    <Text>{'View embed'}</Text>
                  </SecondaryButton>
                )}
                modal={({ closeModal }) => (
                  <EmbedCodeModal
                    clubLeagueSubscriptionId={sub.id}
                    closeModal={closeModal}
                  />
                )}
              />
            </Flex>
          )
        })}
      </Flex>
      <Spacer height="small" />
      {club.verified && (
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton onClick={openModal}>
              <Text extraCondensed size="medium" color="white">
                {'Manage subscriptions'}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <ManageClubSubscriptionModal
              club={club}
              closeModal={closeModal}
              onAdded={refetch}
              leagues={leagues}
            />
          )}
        />
      )}
    </Flex>
  )
}

const Content = () => {
  const { data: viewerData, loading: viewerLoading } = useViewerQuery()
  const [onlyShowVerified, setOnlyShowVerified] = useState(true)
  const {
    data: clubsData,
    loading: clubsLoading,
    refetch,
  } = useAdminClubsQuery()

  const [displayClubs, setDisplayClubs] = useState<AdminPageClub[]>([])
  const [orderBy, setOrderBy] = useState<
    'name' | 'subscriptions' | 'externalUrl'
  >('name')

  const [search, setSearch] = useState('')

  const { data: activeLeagues } = useLibraryLeagueQuery()

  useEffect(() => {
    if (!clubsData) {
      return
    }

    const clubs = clubsData.clubs

    if (onlyShowVerified) {
      setDisplayClubs(clubs.filter((club) => club.verified))
    } else {
      setDisplayClubs(clubs)
    }
  }, [clubsData, onlyShowVerified])

  useEffect(() => {
    if (orderBy === 'name') {
      setDisplayClubs([
        ...displayClubs.sort((a, b) => {
          return a.name.localeCompare(b.name, 'en')
        }),
      ])
    }

    if (orderBy === 'externalUrl') {
      setDisplayClubs([
        ...displayClubs.sort((a, b) => {
          if (!a.externalSiteUrl) {
            return 1
          }

          if (!b.externalSiteUrl) {
            return -1
          }

          return a.externalSiteUrl.localeCompare(b.externalSiteUrl, 'en')
        }),
      ])
    }

    if (orderBy === 'subscriptions') {
      setDisplayClubs([
        ...displayClubs.sort((a, b) => {
          return b.leagueSubscriptions.length - a.leagueSubscriptions.length
        }),
      ])
    }
  }, [orderBy])

  useEffect(() => {
    if (!clubsData) {
      return
    }

    const clubs = clubsData.clubs

    if (search.length === 0) {
      setDisplayClubs(clubs)
      return
    }

    const searchLower = search.toLowerCase()

    setDisplayClubs(
      clubs.filter((club) => {
        return (
          club.name.toLowerCase().includes(searchLower) ||
          club.slug.toLowerCase().includes(searchLower) ||
          (club.externalSiteUrl &&
            club.externalSiteUrl.toLowerCase().includes(searchLower)) ||
          club.leagueSubscriptions.some((sub) => {
            return (
              sub.leagueId.toLowerCase().includes(searchLower) ||
              (sub.leagueTitle &&
                sub.leagueTitle.toLowerCase().includes(searchLower)) ||
              sub.id.toLowerCase().includes(searchLower)
            )
          })
        )
      })
    )
  }, [search])

  if (viewerLoading || clubsLoading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  const viewer = viewerData?.viewer

  if (!viewer || !viewer.isAdmin) {
    const reason = !viewer ? 'no viewer data' : 'viewer is not admin'
    return (
      <Flex horizontal="center">
        <DefaultError
          sentryErrorMessage={`AdminClubSubPage | reason: ${reason}`}
        />
      </Flex>
    )
  }

  const leaguesSorted = activeLeagues.sort((a, b) => {
    return a.title.localeCompare(b.title, 'en')
  })

  return (
    <div>
      <Heading level={1} looksLikeLevel={3}>
        Clubs
      </Heading>
      <Spacer height="medium" />
      <SelectInput
        label="Order by"
        value={orderBy}
        onValue={(value: 'subscriptions' | 'name' | 'externalUrl') => {
          setOrderBy(value)
        }}
      >
        <option value="name">{'Name'}</option>
        <option value="subscriptions">{'Subscriptions'}</option>
        <option value="externalUrl">{'External URL'}</option>
      </SelectInput>
      <Spacer height="medium" />
      <TextInput
        value={search}
        onValue={setSearch}
        label={'Search'}
        textCss={css`
          border: 1px solid black;
        `}
      />
      <Spacer height="medium" />
      <ToggleSwitch
        value={onlyShowVerified}
        onValue={setOnlyShowVerified}
        label={'Only show verified clubs'}
        css={margin.top('medium')}
      />
      <Spacer height="medium" />
      <Flex column gap="medium">
        {displayClubs.map((club) => {
          return (
            <AdminClubComponent
              key={club.id}
              club={club}
              refetch={refetch}
              leagues={leaguesSorted}
            />
          )
        })}
      </Flex>
    </div>
  )
}

export const AdminClubSubPage = () => {
  const navigate = useNavigate()
  return (
    <Layout>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <SecondaryButton
            onClick={() => {
              navigate(-1)
            }}
          >
            {'go back'}
          </SecondaryButton>
          <Spacer height="medium" />
          <Content />
        </PageContent>
        <Spacer height="huge" />
      </AdminAccessControlComponent>
    </Layout>
  )
}
