/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { Link as RRLink, LinkProps } from 'react-router-dom'
import { colors } from '../styles/theme'
import { Text, TextSize } from './Text'

/**
 * prop `color`, `underline`, `className` and `size` is only applicable when `children` is string
 */
export const Link: FC<
  LinkProps & {
    color?: string
    underline?: boolean
    size?: TextSize
    className?: string
    target?: '_blank'
    tabIndex?: number | undefined
  }
> = ({
  children,
  color,
  underline = true,
  size,
  target,
  tabIndex,
  className,
  ...rest
}) => {
  return (
    <RRLink
      css={[
        css`
          text-decoration: none;
          cursor: pointer;
        `,
        underline &&
          css`
            border-bottom: 1px solid ${color ?? colors.green500};
            &:hover {
              border-color: ${color === 'white'
                ? colors.grey300
                : colors.blue400};
            }
          `,
      ]}
      target={target}
      rel={target === '_blank' ? 'noreferrer' : undefined}
      className={className}
      tabIndex={tabIndex}
      {...rest}
    >
      {typeof children === 'string' ? (
        <Text color={color} size={size}>
          {children}
        </Text>
      ) : (
        <>{children}</>
      )}
    </RRLink>
  )
}
