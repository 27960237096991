import { useEffect, useState } from 'react'

export const useNow = ({
  updateFrequencyInMs = 1000,
}: {
  updateFrequencyInMs?: number
}) => {
  const [now, setNow] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
    }, updateFrequencyInMs)

    return () => clearInterval(interval)
  }, [updateFrequencyInMs])

  return now
}
