/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { PlayMoreQuizComponent } from '../../components/leagueQuiz/PlayMoreQuizComponent'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, useTrackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { LeaguesContainer } from '../Home/LeaguesContainer'
import { LeagueCategory, useLeagueCategoriesQuery } from '../Home/queries'
import LeaguesByCategory from './LeaguesByCategory'
import { QuizCollection, useLibraryContentQuery } from './queries'
import { Link } from '../../components/Link'
import { Heading } from '../../components/Heading'
import { TextNew } from '../../components/TextNew'
import { PrimaryButton } from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { isProduction } from '../../config/config'
import { MyActiveSeriesComponent } from '../../components/MyActiveSeriesComponent'
import { RecommendedLeagueQuizzesComponent } from '../../components/RecommendedLeagueQuizzesComponent'

const CategoryPicker: FC<{
  leagueCategories: LeagueCategory[]
  setSelectedLeagueCategory: (category: string) => void
  selectedLeagueCategory: string
}> = ({
  leagueCategories,
  setSelectedLeagueCategory,
  selectedLeagueCategory,
}) => {
  const t = useT()

  return (
    <Flex
      horizontal={'flex-start'}
      wrap
      gap={'small'}
      css={css`
        text-align: center;
        height: 100%;

        // hide scrollbar
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      `}
    >
      {leagueCategories.map((category) => (
        <Flex
          key={category.id}
          css={css`
            cursor: pointer;
            padding: 5px 15px;
            border-radius: 5px;
            border: 1px solid
              ${selectedLeagueCategory === category.id
                ? colors.white
                : colors.grey300};
            background-color: ${selectedLeagueCategory === category.id &&
            colors.white};

            &:hover {
              border: 1px solid ${colors.white};
            }
          `}
          onClick={() => {
            setSelectedLeagueCategory(category.id)
          }}
        >
          <Text
            size={'tiny'}
            color={
              selectedLeagueCategory === category.id
                ? colors.black
                : colors.grey100
            }
          >
            {category.title}
          </Text>
        </Flex>
      ))}
      <Flex
        css={css`
          cursor: pointer;
          padding: 5px 15px;
          border-radius: 5px;
          border: 1px solid
            ${selectedLeagueCategory === 'other'
              ? colors.white
              : colors.grey300};
          background-color: ${selectedLeagueCategory === 'other' &&
          colors.white};

          &:hover {
            border: 1px solid ${colors.white};
          }
        `}
        onClick={() => {
          setSelectedLeagueCategory('other')
        }}
      >
        <Text
          size={'tiny'}
          color={
            selectedLeagueCategory === 'other' ? colors.black : colors.grey100
          }
        >
          {t(`Other`)}
        </Text>
      </Flex>
    </Flex>
  )
}

export const QuizCollectionComponent: FC<{
  quizCollection: QuizCollection
}> = ({ quizCollection }) => {
  if (quizCollection.quizzes.length === 0) {
    return null
  }

  return (
    <Flex column>
      <Spacer height="large" />
      <PageContent>
        <Flex>
          <Flex column gap="tiny">
            <Text color="white" size="medium">
              {quizCollection.title}
            </Text>

            {quizCollection.description && (
              <Text color="white" size="small">
                {quizCollection.description}
              </Text>
            )}
          </Flex>
        </Flex>
      </PageContent>
      <LeaguesContainer>
        {quizCollection.quizzes.map((quiz) => (
          <PlayMoreQuizComponent key={quiz.id} quiz={quiz} />
        ))}
      </LeaguesContainer>
    </Flex>
  )
}

const Content = () => {
  const navigate = useNavigate()
  const leagueCategories = useLeagueCategoriesQuery()
  //const [filterQuery, setFilterQuery] = useState('')
  const [selectedLeagueCategory, setSelectedLeagueCategory] = useState<string>(
    leagueCategories.length > 0 ? leagueCategories[0].id : ''
  )
  const { data: quizCollections } = useLibraryContentQuery(
    selectedLeagueCategory
  )

  useEffect(() => {
    if (leagueCategories.length > 0) {
      setSelectedLeagueCategory(leagueCategories[0].id)
    }
  }, [leagueCategories])

  const updateLeagueCategory = (category: string) => {
    setSelectedLeagueCategory(category)
  }
  const t = useT()

  return (
    <Flex column grow>
      <PageContent>
        <Heading level={1} looksLikeLevel={3} color={colors.white}>
          {t(`Library`)}
        </Heading>
      </PageContent>

      <MyActiveSeriesComponent />

      <RecommendedLeagueQuizzesComponent />

      <Spacer height={'large'} />

      <PageContent>
        <Heading level={3} color={colors.white}>
          {t(`Discover`)}
        </Heading>
        <Spacer height={'medium'} />
        <Link to={'/library/search'}>
          <TextInput
            value={''}
            onValue={() => {}}
            placeholder={t(`Search...`)}
            css={css`
              border-radius: 5px;
              border: none;
              width: 100%;
              z-index: 2;
            `}
          />
        </Link>
        <Spacer height={'large'} />
        {selectedLeagueCategory !== '' && (
          <CategoryPicker
            leagueCategories={leagueCategories}
            setSelectedLeagueCategory={updateLeagueCategory}
            selectedLeagueCategory={selectedLeagueCategory}
          />
        )}
      </PageContent>

      {quizCollections &&
        quizCollections.map((quizCollection) => (
          <QuizCollectionComponent
            key={quizCollection.id}
            quizCollection={quizCollection}
          />
        ))}

      <Spacer height={'large'} />

      <PageContent>
        <LeaguesByCategory selectedLeagueCategory={selectedLeagueCategory} />
      </PageContent>

      <Spacer height={'large'} />

      {!isProduction && (
        <PageContent>
          <Flex
            column
            gap="large"
            css={css`
              background-color: ${colors.black};
              padding: 20px;
              border-radius: 16px;
            `}
          >
            <TextNew
              color={colors.white}
              condensed
              css={css`
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
                font-size: 24px;
                line-height: 1.2;
                letter-spacing: 0.2px;
              `}
              textAlign="center"
            >
              {t('Training description')}
            </TextNew>
            <PrimaryButton
              variant="white"
              onClick={() => {
                navigate('/training')
              }}
              css={css`
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
              `}
            >
              <TextNew
                color={colors.black}
                condensed
                css={css`
                  width: 100%;
                  max-width: 500px;
                  margin: 0 auto;
                  font-size: 24px;
                  line-height: 1.2;
                  letter-spacing: 0.2px;
                `}
                textAlign="center"
              >
                {t('Go to training')}
              </TextNew>
            </PrimaryButton>
          </Flex>
        </PageContent>
      )}
    </Flex>
  )
}

export const Library = () => {
  useTrackEvent(AnalyticsEventType.PageViewLibrary)
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      title={`fcQuiz | ${t('Library')}`}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      backgroundColor={colors.green600}
    >
      <Flex column grow>
        <Content />
      </Flex>
    </Layout>
  )
}
