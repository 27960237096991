/** @jsxImportSource @emotion/react */

import 'quill/dist/quill.snow.css'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { AdminNavbar } from './Navbar'
import {
  AdminPageDbQuestion,
  DbQuizCategoryWithCount,
  useAuthenticLeagueSyncMutation,
  useAuthenticPlayerSyncMutation,
  useAuthenticTeamSyncMutation,
  useCategoriesWithQuestionCountQuery,
  useDataSyncStatusQuery,
  useQuestionsInCategoryQuery,
  useSyncTimelessQuestionsToDatabase,
} from './queries'
import { useState } from 'react'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { css } from '@emotion/react'
import { Loader } from '../../components/Loader'
import { colors } from '../../styles/theme'
import { isDevelopment } from '../../config/config'
import { Edit } from '../../components/icons'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

export const LEAGUES = {
  premierLeague: 39,
  laLiga: 140,
  serieA: 135,
  bundesliga: 78,
  ligue1: 61,
  eliteserien: 103,
  eredivise: 88,
  mls: 253,
  saudiProLeague: 307,
  allSvenskan: 113,
  superLiga: 119,
  brazilSerieA: 71,
  championship: 40,
  primeiraLiga: 94,
}

export enum Status {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

const Content = () => {
  const [syncTimelessQuestionsToDatabase] = useSyncTimelessQuestionsToDatabase()
  const [numSynced, setNumSynced] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<
    DbQuizCategoryWithCount | undefined
  >(undefined)
  const [selectedLocale, setSelectedLocale] = useState<
    'no' | 'en' | 'es' | 'it' | 'ar' | 'fr' | 'de' | 'pt' | 'all'
  >('no')

  const { data: categoryData, loading: loadingCategories } =
    useCategoriesWithQuestionCountQuery({ questionLocale: selectedLocale })

  const {
    data: questionData,
    loading: questionsLoading,
    error: questionsQueryError,
  } = useQuestionsInCategoryQuery({
    categoryId: selectedCategory?.id,
    questionLocale: selectedLocale,
  })

  let categories = categoryData?.categoriesWithQuestionCount ?? []
  let questions = questionData?.questionsInCategory ?? []

  return (
    <div
      css={[
        margin.top('large'),
        css`
          width: 100%;
        `,
      ]}
    >
      <Flex column>
        <Flex
          column
          css={css`
            margin-top: 32px;
          `}
        >
          <AuthenticDataSyncComponent />

          <Spacer height={40} />

          <Flex horizontal="space-between">
            <Text strong>Categories with timeless questions: </Text>

            <select
              value={selectedLocale}
              onChange={(value) => {
                setSelectedLocale(value.target.value as any)
              }}
            >
              <option value="no">Norwegian</option>
              <option value="en">English</option>
              <option value="es">Spanish</option>
              <option value="it">Italian</option>
              <option value="ar">Arabic</option>
              <option value="fr">French</option>
              <option value="de">German</option>
              <option value="pt">Portugese</option>
              <option value="ja">Japanese</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="all">All</option>
            </select>
          </Flex>
          <Flex
            wrap
            vertical="center"
            css={css`
              margin-top: 16px;
            `}
          >
            {categories.length === 0 && loadingCategories === false && (
              <Text
                css={css`
                  font-style: italic;
                  opacity: 0.8;
                `}
              >
                {`Found no categories with timeless questions in: ${selectedLocale} 🤔`}
              </Text>
            )}
            {categories.map((category, index) => (
              <Flex
                key={`category-${category.id}_${index}`}
                css={css`
                  margin-right: 8px;
                  margin-bottom: 8px;
                  padding: 8px 12px;
                  background-color: ${selectedCategory === category
                    ? colors.yellow400
                    : colors.yellow200};
                  border-radius: 8px;
                  &:hover {
                    background-color: ${colors.yellow300};
                    cursor: pointer;
                  }
                `}
                onClick={() => {
                  if (category === selectedCategory) {
                    setSelectedCategory(undefined)
                  }
                  setSelectedCategory(category)
                }}
              >
                <Text>{`${category.content}`}</Text>
              </Flex>
            ))}
            {categories.length > 0 && !loadingCategories && (
              <Text
                css={css`
                  font-style: italic;
                  opacity: 0.8;
                  margin-bottom: 8px;
                `}
              >
                {`Categories with questions in: ${selectedLocale} = ${categories.length}`}
              </Text>
            )}
          </Flex>
          {questionsQueryError && (
            <Text>{`Could not fetch questions, error: ${questionsQueryError.message}`}</Text>
          )}

          {questionsLoading && (
            <Flex column horizontal="center">
              <Loader />
              <Text>{`Loading questions for "${selectedCategory?.content}" in "${selectedLocale}"...`}</Text>
            </Flex>
          )}

          {questions.length > 0 && (
            <Flex column>
              <Spacer height={16} />
              <Text>{`Questions for category "${selectedCategory?.content}" in language "${selectedLocale}": ${questions.length}`}</Text>
              <Spacer height={16} />
              {questions.map((question) => questionComponent(question))}
            </Flex>
          )}
        </Flex>
        <Spacer height={40} />
        {numSynced && !loading && (
          <Text>{`There are now ${numSynced} timeless questions in the database 🤌`}</Text>
        )}

        {!loading && (
          <PrimaryButton
            css={css`
              margin: 16px auto;
            `}
            onClick={async () => {
              setLoading(true)
              const res = await syncTimelessQuestionsToDatabase()
              setLoading(false)
              setNumSynced(res.data?.syncTimelessQuestionsToDatabase)
            }}
          >
            Sync timeless questions to database
          </PrimaryButton>
        )}

        {loading && (
          <div
            css={css`
              margin: 16px auto;
            `}
          >
            <Loader />
          </div>
        )}
        <Spacer height={80} />
      </Flex>
    </div>
  )
}

function questionComponent(question: AdminPageDbQuestion) {
  const secondaryTextColor = colors.grey400

  return (
    <Flex
      column
      key={`question-${question.id}_${question.locale}`}
      css={css`
        margin-top: 16px;
        padding: 8px 12px;
        border: 1px solid ${colors.grey300};
        border-radius: 8px;
      `}
    >
      <Flex horizontal="space-between" vertical="center">
        <Text
          size="small"
          css={css`
            padding: 4px 8px;
            background-color: ${colors.green200};
            border-radius: 8px;
          `}
        >{`${question.locale}`}</Text>
        {question.source === 'sanity' && (
          <SecondaryButton
            onClick={() => {
              if (question.source === 'sanity') {
                const sanityUrl = isDevelopment
                  ? `http://localhost:3333/desk/question;${question.id}`
                  : `https://fcquiz.sanity.studio/desk/question;${question.id}`
                window.open(sanityUrl, '_blank')
              }
            }}
            css={css`
              background-color: ${colors.white};
              &:hover {
                cursor: pointer;
                background-color: ${colors.green100};
                color: ${colors.green500};
              }
            `}
          >
            <Flex>
              <Edit color={secondaryTextColor} />
              <Spacer width={8} />
              <Text color={secondaryTextColor} size="tiny">{`Sanity`}</Text>
            </Flex>
          </SecondaryButton>
        )}
      </Flex>
      <Spacer height={8} />
      <Text>{`${question.content}`}</Text>
      <Spacer height={16} />
      <Flex horizontal="space-between">
        <Text
          color={secondaryTextColor}
          size="tiny"
        >{`answered times: ${question.answered_times}`}</Text>
        {question.difficulty && (
          <Text
            textAlign="center"
            color={secondaryTextColor}
            size="tiny"
          >{`incorrect answers: ${question.difficulty}%`}</Text>
        )}
        <Text
          textAlign="right"
          color={secondaryTextColor}
          size="tiny"
        >{`${question.id}`}</Text>
      </Flex>
    </Flex>
  )
}

function AuthenticDataSyncComponent() {
  const { data: dataSyncStatus } = useDataSyncStatusQuery()
  const [
    authenticLeagueSyncMutation,
    { loading: syncLeaguesLoading, data: leaguesData },
  ] = useAuthenticLeagueSyncMutation()

  const [
    authenticTeamSyncMutation,
    { loading: syncTeamsLoading, data: teamsData },
  ] = useAuthenticTeamSyncMutation()

  const [
    authenticPlayerSyncMutation,
    { loading: syncPlayersLoading, data: playersData },
  ] = useAuthenticPlayerSyncMutation()

  const [selectedLeagues, setSelectedLeagues] = useState<number[]>([])

  return (
    <Flex column gap={32}>
      <Text
        strong
        css={css`
          margin-top: 32px;
        `}
      >
        Authentic data sync
      </Text>

      <Flex column gap={8}>
        <Text italic>
          Authentic Leagues{' '}
          {dataSyncStatus?.getDataSyncStatus.leagues.status ===
            Status.IN_PROGRESS && 'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.leagues?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Leagues synced:{' '}
              {dataSyncStatus?.getDataSyncStatus.leagues.objectsAdded || 0} /{' '}
              {''}
              {dataSyncStatus?.getDataSyncStatus.leagues.totalObjects || 0}
            </Text>
          </Flex>

          {(syncLeaguesLoading ||
            dataSyncStatus?.getDataSyncStatus.leagues.status ===
              Status.IN_PROGRESS) && <Loader size={32} />}
          <Flex column>
            <PrimaryButton onClick={() => authenticLeagueSyncMutation()}>
              Sync authentic leagues
            </PrimaryButton>
            {leaguesData?.addLeagues && (
              <Text color={colors.grey400}>
                {leaguesData.addLeagues.message}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex column gap={8}>
        <Text italic>
          Authentic Clubs{' '}
          {(syncTeamsLoading ||
            dataSyncStatus?.getDataSyncStatus.teams.status ===
              Status.IN_PROGRESS) &&
            'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.teams?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.teams?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.teams?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Clubs synced:{' '}
              {dataSyncStatus?.getDataSyncStatus?.teams?.objectsAdded || 0} /{' '}
              {dataSyncStatus?.getDataSyncStatus?.teams?.totalObjects || 0}
            </Text>
          </Flex>

          <Flex column={false} vertical={'center'} gap={12}>
            {(syncTeamsLoading ||
              dataSyncStatus?.getDataSyncStatus.teams.status ===
                Status.IN_PROGRESS) && <Loader size={32} />}
            <Flex
              column
              css={css`
                max-width: 300px;
              `}
            >
              <PrimaryButton onClick={() => authenticTeamSyncMutation()}>
                Sync authentic clubs
              </PrimaryButton>
              {teamsData?.addTeams && (
                <Text color={colors.grey400}>{teamsData.addTeams.message}</Text>
              )}
              {(syncTeamsLoading ||
                dataSyncStatus?.getDataSyncStatus.teams.status ===
                  Status.IN_PROGRESS) && (
                <Text
                  css={css`
                    word-break: break-all;
                  `}
                >
                  This could take a while, go for a walk or something...
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex column gap={8}>
        <Text italic>
          Authentic Players{' '}
          {(syncPlayersLoading ||
            dataSyncStatus?.getDataSyncStatus.players.status ===
              Status.IN_PROGRESS) &&
            'Sync in progress...'}
        </Text>
        <Flex
          column={false}
          horizontal={'space-between'}
          vertical={'flex-start'}
        >
          <Flex column gap={8}>
            <Text>
              {dataSyncStatus?.getDataSyncStatus?.players?.endedAt
                ? 'Last sync: ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.players?.endedAt)
                  )
                    .toISOString()
                    .split('T')[0] +
                  ' ' +
                  new Date(
                    Number(dataSyncStatus?.getDataSyncStatus?.players?.endedAt)
                  )
                    .toISOString()
                    .split('T')[1]
                    .slice(0, 5)
                : 'Last sync: Never'}
            </Text>
            <Text>
              Players synced:{' '}
              {dataSyncStatus?.getDataSyncStatus?.players?.objectsAdded || 0} /{' '}
              {dataSyncStatus?.getDataSyncStatus?.players?.totalObjects || 0}
            </Text>
          </Flex>

          <Flex column={false} vertical={'center'} gap={12}>
            {(syncPlayersLoading ||
              dataSyncStatus?.getDataSyncStatus.players.status ===
                Status.IN_PROGRESS) && <Loader size={32} />}
            <Flex
              column
              css={css`
                max-width: 300px;
              `}
            >
              <PrimaryButton
                onClick={() =>
                  authenticPlayerSyncMutation({
                    variables: {
                      leagues: selectedLeagues,
                    },
                  })
                }
              >
                Sync authentic players
              </PrimaryButton>
              {playersData?.addPlayers && (
                <Text color={colors.grey400}>
                  {playersData.addPlayers.message}
                </Text>
              )}
              {(syncPlayersLoading ||
                dataSyncStatus?.getDataSyncStatus.players.status ===
                  Status.IN_PROGRESS) && (
                <Text
                  css={css`
                    word-break: break-all;
                  `}
                >
                  This could take a while, go for a walk or something...
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* List of all leagues with checkbox for league selection */}
      <Flex column gap={8}>
        <Text italic>Select leagues to sync players from</Text>
        <Flex wrap gap={8}>
          {Object.entries(LEAGUES).map(([league, id]) => (
            <Flex column={false} key={league} gap={8}>
              <input
                type="checkbox"
                id={league}
                name={league}
                value={league}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedLeagues([...selectedLeagues, id])
                  } else {
                    setSelectedLeagues(selectedLeagues.filter((l) => l !== id))
                  }
                }}
              />
              <Text>{league}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export const AdminQuestionsPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <PageContent grow>
          <AdminNavbar activePage="questions" />

          <Content />
        </PageContent>

        <Footer />
        <Header color="green" />
      </AdminAccessControlComponent>
    </Layout>
  )
}
