/** @jsxImportSource @emotion/react */

import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { useJoinChallengeGroupMutation } from '../Challenge/queries'
import { Invitation } from './queries'
import { useEffect } from 'react'
import { ModalTrigger } from '../../components/modal/Modal'
import { css } from '@emotion/react'
import { Text } from '../../components/Text'
import { LoginModalInner } from '../../components/modal/LoginModalInner'

export const GroupInvitationPage = ({
  invitation,
}: {
  invitation: Invitation
}) => {
  const t = useT()

  const [joinGroup] = useJoinChallengeGroupMutation()

  const { authUser } = useAuthContext()

  const navigate = useNavigate()

  const userIsMember = invitation.challengeGroup.members.some(
    (member) => member.id === authUser?.uid
  )

  useEffect(() => {
    if (userIsMember) {
      navigate(`/challenge-group/${invitation.challengeGroup.id}`)
    }
  }, [userIsMember])

  return (
    <Flex column grow>
      <Heading level={1} looksLikeLevel={2} extraCondensed strong>
        {`Group Invitation`}
      </Heading>
      {invitation.challengeGroup.title && (
        <Heading level={1} looksLikeLevel={3} extraCondensed>
          {`${invitation.challengeGroup.title}`}
        </Heading>
      )}
      <Heading level={2} looksLikeLevel={4} extraCondensed>
        {`You have been invited by: ${invitation.fromUser.displayName}`}
      </Heading>
      <Spacer height={32} />

      {!authUser && (
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              onClick={openModal}
              css={css`
                height: 50px;
              `}
            >
              <Text size="large" condensed color="white">
                {t('Sign up / Log in')}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      )}

      {authUser && !userIsMember && (
        <PrimaryButton
          onClick={async () => {
            const res = await joinGroup({
              variables: {
                groupId: invitation.challengeGroup.id,
              },
            })

            if (res.data?.joinChallengeGroup?.id) {
              navigate(`/challenge-group/${invitation.challengeGroup.id}`)
            }
          }}
        >
          {t('Join')}
        </PrimaryButton>
      )}
    </Flex>
  )
}
