/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../layout/Flex'
import { Text } from '../Text'
import { TextNew } from '../TextNew'
import { useT } from '../../lib/i18n/useT'

export const CareerPathQuizStatusComponent = ({
  currentQuestion,
  totalQuestions,
  timeSpent,
}: {
  currentQuestion: number
  totalQuestions: number
  timeSpent: number
}) => {
  const t = useT()
  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600)
    const mins = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    if (hrs > 0) {
      return `${hrs}h ${mins}m ${secs}s`
    } else if (mins > 0) {
      return `${mins}m ${secs}s`
    } else {
      return `${secs}s`
    }
  }

  return (
    <Flex
      vertical="center"
      css={css`
        width: 100%;
      `}
    >
      {totalQuestions > 1 && (
        <Flex
          gap={'tiny'}
          css={css`
            flex: 1;
            justify-content: flex-start;
            align-items: flex-end;
          `}
        >
          <TextNew condensed italic spacingEm={0} size={'massive'} strong>
            {currentQuestion}/{totalQuestions}
          </TextNew>
          <TextNew
            uppercase
            condensed
            italic
            spacingEm={0}
            size={'large'}
            strong
          >
            {t('Players')}
          </TextNew>
        </Flex>
      )}

      <Text></Text>
      <TextNew
        condensed
        italic
        spacingEm={0}
        size={'massive'}
        strong
        css={css`
          flex: 1;
          justify-content: flex-end;
          text-align: end;
          align-items: center;
        `}
      >
        {formatTime(timeSpent)}
      </TextNew>
    </Flex>
  )
}
