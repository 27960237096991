/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { useT } from '../lib/i18n/useT'
import { colors } from '../styles/theme'
import { Flex } from './layout/Flex'
import { Text } from './Text'
import { PrimaryButton } from './Button'
import { FaShareSquare } from 'react-icons/fa'

export const ShareLinkComponent = ({
  url,
  useShareDialog = false,
  shareMessage,
  shareCta,
}: {
  url: string
  useShareDialog?: boolean
  shareMessage?: string
  shareCta?: string
}) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const t = useT()

  const isMobileDevice = window.navigator.share !== undefined

  const showShareDialog = isMobileDevice && useShareDialog

  const shareLink = async () => {
    if (showShareDialog) {
      const shareText = shareMessage
        ? `⚽️ fcQuiz ⚽️\n\n${url}\n\n${shareMessage}`
        : `⚽️ fcQuiz ⚽️\n\n${url}`

      window.navigator
        .share({
          text: shareText,
        })
        .catch(() => {
          // ignore error
        })
    } else {
      navigator.clipboard.writeText(url)
      setLinkCopied(true)
      setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
    }
  }

  if (showShareDialog) {
    return (
      <PrimaryButton
        onClick={shareLink}
        css={css`
          height: 50px;
          opacity: ${linkCopied ? '0.5' : '1'};
        `}
      >
        <Flex gap="small" vertical="center" horizontal="center">
          <FaShareSquare color="white" size={20} />
          <Text color="white ">{shareCta || t('Share')}</Text>
        </Flex>
      </PrimaryButton>
    )
  }

  return (
    <Flex
      vertical="center"
      horizontal="center"
      onClick={shareLink}
      css={css`
        height: 50px;
        width: 100%;
        background-color: ${colors.grey200};
        border-radius: 8px;
        padding-left: 16px;
        cursor: pointer;
      `}
    >
      <Text
        strong
        condensed
        color={colors.black}
        css={css`
          width: 80%;
          overflow-x: scroll;
          white-space: nowrap;
          user-select: none;
          /* hide scrollbar */
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        {url}
      </Text>
      <Text
        strong
        css={css`
          padding: 0 16px;
        `}
        color={colors.black}
      >
        {linkCopied ? t('Copied') : t('Copy')}
      </Text>
    </Flex>
  )
}
