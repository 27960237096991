/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { random } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { GreenButton } from '../../../components/Button'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import { useNow } from '../../../utils/useNow'
import { titleFromQuiz } from '../../../utils/util'
import {
  calculateTimeRemaining,
  noTimeRemaining,
  timeRemainingToString,
} from '../../Admin/Utils'
import { useStartChallengeMatchQuizMutation } from '../../ChallengeMatch/queries'
import { frequencyToString, LeagueFrequency } from '../../League/queries'
import {
  LeagueQuiz,
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../../LeagueQuiz/queries'
import {
  challengeGroupQuery,
  ChallengeLeague,
  ChallengeUpcomingQuiz,
} from '../queries'

export const ActiveQuizComponent = ({
  league,
  activeQuiz,
  upcomingQuiz,
  leagueFrequency,
  leagueTitle,
}: {
  league: ChallengeLeague
  activeQuiz?: LeagueQuiz
  upcomingQuiz?: ChallengeUpcomingQuiz
  leagueFrequency?: LeagueFrequency
  leagueTitle?: string
}) => {
  const { authUser, signInAsAnonymous } = useAuthContext()
  const navigate = useNavigate()

  const [startLeagueQuizMutation] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const [startChallengeMatchMutation] = useStartChallengeMatchQuizMutation()

  const t = useT()

  const StartOrContinueQuiz = () => {
    if (!activeQuiz) {
      return null
    }

    return activeQuiz.viewerCanStartQuiz ? (
      <GreenButton
        onClick={async () => {
          if (!authUser) {
            await signInAsAnonymous()
          }

          const resultsLength = (activeQuiz.challengeGroupResults ?? []).length

          if (resultsLength === 0) {
            // start solo quiz

            if (activeQuiz.quizType === 'careerPath') {
              const { data } = await startCareerPathQuizMutation({
                variables: {
                  input: {
                    leagueQuizId: activeQuiz.id,
                  },
                },
              })

              if (!data || !data.startCareerPathQuiz) {
                return
              }

              trackEvent(AnalyticsEventType.StartQuiz, {
                quizId: activeQuiz.id,
                leagueId: activeQuiz.league.id,
                challengeGroupId: league.id,
                isEmbed: false,
              })

              const quizInstanceId = data.startCareerPathQuiz?.id
              navigate(
                `/play-quiz/${quizInstanceId}?redirectToLeague=${league.slug}&quizType=${activeQuiz.quizType}`
              )
              return
            }

            const { data } = await startLeagueQuizMutation({
              variables: {
                input: {
                  leagueQuizId: activeQuiz.id,
                },
              },
            })

            if (!data || !data.startLeagueQuiz) {
              return
            }

            trackEvent(AnalyticsEventType.StartQuiz, {
              quizId: activeQuiz.id,
              leagueId: activeQuiz.league.id,
              challengeGroupId: league.id,
              isEmbed: false,
            })

            const quizInstanceId = data.startLeagueQuiz?.id
            navigate(
              `/play-quiz/${quizInstanceId}?redirectToLeague=${league.slug}`
            )
          }

          const opponentId =
            activeQuiz.challengeGroupResults?.[random(0, resultsLength - 1)]
              ?.userId

          if (!opponentId) {
            return
          }

          const { data } = await startChallengeMatchMutation({
            variables: {
              input: {
                quizId: activeQuiz.id,
                challengeGroupId: league.id,
                opponentId,
              },
            },
          })

          if (!data || !data.startChallengeMatchQuiz) {
            return
          }

          const challengeMatchId = data.startChallengeMatchQuiz?.id
          trackEvent(AnalyticsEventType.StartQuiz, {
            quizId: activeQuiz.id,
            leagueId: activeQuiz.league.id,
            challengeGroupId: league.id,
            isEmbed: false,
          })
          navigate(`/challenge-match/${challengeMatchId}`)

          // start quiz
        }}
      >
        {t('Play quiz').toUpperCase()}
      </GreenButton>
    ) : activeQuiz.viewerData.activeQuizInstance?.id ? (
      <GreenButton
        onClick={async () => {
          const instanceId = activeQuiz.viewerData.activeQuizInstance?.id

          const resultsLength = (activeQuiz.challengeGroupResults ?? []).length

          if (resultsLength === 0) {
            // start solo quiz
            console.log('start solo quiz')
            navigate(`/play-quiz/${instanceId}?redirectToLeague=${league.slug}`)
            return
          }

          const opponentId =
            activeQuiz.challengeGroupResults?.[random(0, resultsLength - 1)]
              ?.userId

          if (!opponentId) {
            navigate(`/play-quiz/${instanceId}?redirectToLeague=${league.slug}`)
            return
          }

          const { data } = await startChallengeMatchMutation({
            variables: {
              input: {
                quizId: activeQuiz.id,
                challengeGroupId: league.id,
                opponentId,
              },
            },
          })
          // Continue quiz, so dont track start quiz

          if (!data || !data.startChallengeMatchQuiz) {
            navigate(`/play-quiz/${instanceId}?redirectToLeague=${league.slug}`)
            return
          }
          const challengeMatchId = data.startChallengeMatchQuiz?.id
          navigate(`/challenge-match/${challengeMatchId}`)
        }}
      >
        <TextNew
          extraStrong
          wide
          italic
          css={css`
            font-size: 17px;
            line-height: 20px;
          `}
        >
          {t('Continue quiz').toUpperCase()}
        </TextNew>
      </GreenButton>
    ) : null
  }

  const showActiveQuiz = activeQuiz
  const showUpcomingQuiz = upcomingQuiz && !activeQuiz
  const noQuizzesToShow = !showActiveQuiz && !showUpcomingQuiz

  return (
    <Flex
      css={css`
        background-color: ${colors.white};
        border-radius: 16px;
        position: relative;
        width: 100%;
        margin: 0 auto;
      `}
    >
      {(league.iconImageUrl ||
        activeQuiz?.league?.leagueCardImageUrl ||
        activeQuiz?.league?.imageUrl) && (
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <img
            src={
              activeQuiz?.league?.leagueCardImageUrl ||
              league.iconImageUrl ||
              activeQuiz?.league?.imageUrl
            }
            alt={league.title}
            css={css`
              padding: 12px;
              max-width: 100px;
              max-height: 100px;
              object-fit: contain;
            `}
          />
        </div>
      )}
      {leagueFrequency && (
        <div
          css={css`
            position: absolute;
            top: 12px;
            right: 12px;
            background-color: ${colors.lightBeige};
            padding: 2px 8px;
            border-radius: 24px;
            padding-bottom: 4px;
          `}
        >
          <TextNew
            color={colors.black}
            condensed
            italic
            css={css`
              font-size: 14px;
              line-height: 14px;
            `}
          >
            {t(frequencyToString(leagueFrequency))}
          </TextNew>
        </div>
      )}
      <Flex
        column
        vertical="center"
        css={css`
          z-index: 2;
          position: relative;
          width: 100%;
          margin-top: 4px;
          padding: 19px 9px;
        `}
      >
        <Flex
          css={css`
            margin-bottom: 8px;
            width: 100%;
          `}
        >
          {showActiveQuiz && (
            <Flex
              gap="small"
              horizontal="center"
              css={css`
                width: 100%;
                position: relative;
              `}
            >
              <Flex
                column
                horizontal="center"
                gap={12}
                css={css`
                  padding-bottom: 12px;
                `}
              >
                {leagueTitle && (
                  <TextNew
                    color={colors.black}
                    condensed
                    italic
                    css={css`
                      font-size: 18px;
                      line-height: 1;

                      @media (max-width: 599px) {
                        font-size: 16px;
                      }
                    `}
                  >
                    {leagueTitle}
                  </TextNew>
                )}
                <TextNew
                  color={colors.black}
                  strong
                  condensed
                  italic
                  textAlign="center"
                  css={css`
                    font-size: 31px;
                    line-height: 1;

                    max-width: 280px;

                    @media (max-width: 450px) {
                      font-size: 22px;
                      max-width: 130px;
                    }

                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {titleFromQuiz(activeQuiz, t)}
                </TextNew>
              </Flex>
            </Flex>
          )}
          {showUpcomingQuiz && (
            <Flex
              horizontal="center"
              css={css`
                width: 100%;
              `}
            >
              <UpcomingCountdown
                nextQuizDate={new Date(upcomingQuiz.availableFrom)}
              />
            </Flex>
          )}

          {noQuizzesToShow && (
            <Flex
              horizontal="center"
              css={css`
                width: 100%;
              `}
            >
              <TextNew
                color={colors.black}
                strong
                condensed
                italic
                css={css`
                  font-size: 31px;
                  line-height: 1;
                `}
              >
                {t('No active quizzes').toUpperCase()}
              </TextNew>
            </Flex>
          )}
        </Flex>
      </Flex>
      <div
        css={css`
          position: absolute;
          bottom: -20px;
          right: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 2;
        `}
      >
        {showActiveQuiz && <StartOrContinueQuiz />}
      </div>
    </Flex>
  )
}

const UpcomingCountdown = (props: { nextQuizDate: Date }) => {
  const t = useT()
  const now = useNow({
    updateFrequencyInMs: 1000,
  })

  const apolloClient = useApolloClient()

  const remaining = calculateTimeRemaining(now, props.nextQuizDate)

  if (noTimeRemaining(remaining)) {
    apolloClient.refetchQueries({
      include: [challengeGroupQuery],
    })
  }

  return (
    <Flex column horizontal="center">
      <TextNew
        condensed
        italic
        strong
        shadow
        color={colors.black}
        css={css`
          font-size: 24px;
          line-height: 1;
        `}
      >
        {t('Next quiz in').toUpperCase()}
      </TextNew>
      <TextNew
        condensed
        italic
        shadow
        color={colors.black}
        css={css`
          font-size: 26px;
          line-height: 1;
        `}
      >
        {timeRemainingToString(remaining, t)}
      </TextNew>
    </Flex>
  )
}
