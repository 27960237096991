/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { intervalToDuration } from 'date-fns'
import { padStart } from 'lodash'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { useFormatDate } from '../../utils/date'
import { useNow } from '../../utils/useNow'

export const RemainingTime = ({
  toDate,
  color,
}: {
  toDate: string
  color?: string
}) => {
  const t = useT()
  const formatDate = useFormatDate()
  const now = useNow({ updateFrequencyInMs: 1000 })

  const duration = intervalToDuration({
    start: now,
    end: new Date(toDate),
  })

  const formatted = duration.months
    ? `${t('Next quiz')} ${formatDate(toDate, 'full-date')}`
    : `${t('Next quiz in')}${
        duration.days
          ? `\n${t('{{count}} day', { count: duration.days })} `
          : ' '
      }${padZeros(duration.hours)}:${padZeros(duration.minutes)}:${padZeros(
        duration.seconds
      )}`

  return (
    <Text
      size="tiny"
      color={color}
      css={css`
        white-space: pre;
        line-height: 1.2;
      `}
    >
      {formatted}
    </Text>
  )
}

function padZeros(number?: number) {
  return padStart(String(number ?? 0), 2, '0')
}
