/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import {
  useAuthContext,
  useViewerQuery,
  viewerQuery,
} from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import { UserAvatar } from '../Avatar/UserAvatar'
import { LeagueQuizCard } from '../Challenge/components/LeagueQuizCard'
import { useJoinChallengeGroupMutation } from '../Challenge/queries'
import { validUsername } from '../EditProfile/EditProfilePage'
import { Invitation } from './queries'
import { useStartInviteChallengeQuizMutation } from '../ChallengeMatch/queries'
import { useApolloClient } from '@apollo/client'

export const ChallengeInvitationPage = ({
  invitation,
}: {
  invitation: Invitation
}) => {
  const { authUser, loading: authLoading, signInAsAnonymous } = useAuthContext()
  const { data: viewerData } = useViewerQuery()
  const apolloClient = useApolloClient()

  const [pageLoading, setPageLoading] = useState(false)

  const t = useT()

  const [anonUsername, setAnonUserName] = useState<string>(
    viewerData?.viewer?.displayName ?? ''
  )

  const [usernameError, setUsernameError] = useState<string | undefined>(
    undefined
  )

  const [joinChallengeGroup] = useJoinChallengeGroupMutation()

  const [startChallengeQuiz, { loading: startLoading }] =
    useStartInviteChallengeQuizMutation()

  const navigate = useNavigate()

  const canStartQuiz =
    invitation.challengeGroup.currentQuiz?.viewerCanStartQuiz ?? false

  useEffect(() => {
    const isValidUsername = validUsername.test(anonUsername)

    if (anonUsername.length === 0) {
      setUsernameError(undefined)
      return
    }

    if (!isValidUsername) {
      setUsernameError(
        t(`Between 5 and 16 characters, only letters and numbers`)
      )
      return
    }

    setUsernameError(undefined)
  }, [anonUsername])

  async function joinGroup() {
    setPageLoading(true)
    const res = await joinChallengeGroup({
      variables: {
        groupId: invitation.challengeGroup.id,
      },
    })

    const groupId = res?.data?.joinChallengeGroup?.id

    if (groupId) {
      navigate(`/challenge-group/${groupId}`, { replace: true })
    }
    setPageLoading(false)
  }

  useEffect(() => {
    if (authUser && authUser.isAnonymous) {
      return
    }

    if (canStartQuiz) {
      return
    }

    if (invitation.challengeGroup.viewerIsMember) {
      navigate(`/challenge-group/${invitation.challengeGroup.id}`, {
        replace: true,
      })
    }
  }, [authUser, canStartQuiz])

  if (authLoading || pageLoading) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Loader />
      </Flex>
    )
  }

  const isAnonymous = authUser === null || authUser?.isAnonymous

  const startQuiz = async () => {
    try {
      const res = await startChallengeQuiz({
        variables: {
          input: {
            invitationId: invitation.id,
            anonUsername: isAnonymous ? anonUsername : undefined,
          },
        },
      })

      await apolloClient.refetchQueries({
        include: [viewerQuery],
      })

      if (res?.data?.startInviteChallengeQuiz.challengeMatchId) {
        trackEvent(AnalyticsEventType.StartQuiz, {
          quizId: invitation.challengeGroup.currentQuiz?.id,
          leagueId: invitation.challengeGroup.currentQuiz?.league.id,
          challengeGroupId: invitation.challengeGroup.id,
          isEmbed: false,
        })
        const challengeMatchId =
          res.data?.startInviteChallengeQuiz.challengeMatchId
        navigate(`/challenge-match/${challengeMatchId}`)
      }
    } catch (error) {
      if (hasErrorCode(error, ErrorCode.USERNAME_NOT_AVAILABLE)) {
        setUsernameError(t('Player name is taken'))
      } else if (hasErrorCode(error, ErrorCode.USERNAME_INVALID)) {
        setUsernameError(t('Invalid player name'))
      }
    }
  }

  const currentQuiz = invitation.challengeGroup.currentQuiz

  const ActionButton = () => {
    if (startLoading) {
      return null
    }
    return (
      <PrimaryButton
        css={css`
          margin-top: 32px;
          height: 50px;
          opacity: ${usernameError || !anonUsername.length ? 0.5 : 1};
          background-color: ${colors.purple500};
        `}
        onClick={async () => {
          if (startLoading) {
            return
          }
          if (isAnonymous) {
            if (usernameError || !anonUsername.length) {
              return
            }

            await signInAsAnonymous()
          }

          trackEvent(AnalyticsEventType.ChallengeAccepted, {
            league: currentQuiz?.league?.slug ?? '',
          })

          await startQuiz()
        }}
      >
        <Text
          strong
          color="white"
          css={css`
            font-size: 20px;
            font-style: italic;
            letter-spacing: 0.2px;
          `}
        >
          {t('Accept Challenge')}
        </Text>
      </PrimaryButton>
    )
  }

  return (
    <Flex column>
      <Text
        extraCondensed
        strong
        textAlign="center"
        css={css`
          font-size: 40px;
          line-height: 40px;
          font-style: italic;
          padding-bottom: 8px;
          margin-top: -8px;
        `}
      >
        {t('You have been challenged by').toUpperCase()}
      </Text>
      {currentQuiz && (
        <>
          <Spacer height={16} />
          <Flex horizontal="center" vertical="center" gap="small">
            <UserAvatar
              avatarData={invitation.fromUser.avatarData}
              height={50}
            />
            <Text
              extraCondensed
              strong
              textAlign="center"
              css={css`
                font-size: 24px;
                line-height: 1px;
                font-style: italic;
              `}
            >
              {invitation.fromUser.displayName}
            </Text>
          </Flex>
          <Spacer height={24} />
          <Text
            condensed
            css={css`
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
            `}
          >
            {t('Can you beat me in this Quiz?')}
          </Text>
          <Spacer height={16} />
          <LeagueQuizCard quiz={currentQuiz} />
          <Spacer height={16} />
          {!currentQuiz.viewerCanStartQuiz && (
            <>
              <Text
                condensed
                css={css`
                  font-weight: 700;
                  font-size: 24px;
                  line-height: 24px;
                  margin-top: 16px;
                `}
              >
                {t('You have already completed this quiz')}
              </Text>
              <RedirectToChallengeComponent
                challengeGroupId={invitation.challengeGroup.id}
                onCompleted={joinGroup}
                playAnimation={true}
              />
            </>
          )}
        </>
      )}
      <Spacer height={16} />

      {isAnonymous && currentQuiz?.viewerCanStartQuiz && (
        <Flex column>
          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton
                onClick={openModal}
                css={css`
                  height: 50px;
                `}
              >
                <Text size="large" condensed color="white">
                  {t('Sign up / Log in')}
                </Text>
              </PrimaryButton>
            )}
            modal={({ closeModal }) => (
              <LoginModalInner closeModal={closeModal} />
            )}
          />
          <Spacer height={16} />
          <Flex vertical="center" gap="large">
            <div
              css={css`
                height: 1px;
                width: 100%;
                background-color: ${colors.black};
              `}
            />
            <Text
              extraCondensed
              strong
              textAlign="center"
              css={css`
                font-size: 40px;
                line-height: 40px;
                font-style: italic;
                padding-bottom: 8px;
              `}
            >
              {t('or').toUpperCase()}
            </Text>
            <div
              css={css`
                height: 1px;
                width: 100%;
                background-color: ${colors.black};
              `}
            />
          </Flex>
          <TextInput
            id="username"
            name="username"
            label={
              <Text
                condensed
                strong
                color={colors.black}
                size="small"
                css={css`
                  text-align: left;
                  font-size: 20px;
                  margin-bottom: 8px;
                `}
              >
                {t('Create a player name')}
              </Text>
            }
            placeholder={t('A unique name')}
            autoComplete="off"
            value={anonUsername}
            onValue={(value) => {
              setAnonUserName(value)
            }}
            error={usernameError}
          />

          <ActionButton />
          <Spacer height={32} />
        </Flex>
      )}
      {canStartQuiz && !isAnonymous && <ActionButton />}

      {startLoading && (
        <Flex horizontal="center" css={margin.top('large')}>
          <Loader />
        </Flex>
      )}
    </Flex>
  )
}

const RedirectToChallengeComponent = ({
  challengeGroupId,
  playAnimation = false,
  onCompleted,
}: {
  challengeGroupId: string
  playAnimation?: boolean
  onCompleted?: () => Promise<void>
}) => {
  const [redirectCancelled, setRedirectCancelled] = useState(!playAnimation)
  const redirectInSeconds = 6
  const [remaining, setRemaining] = useState(redirectInSeconds)

  const navigate = useNavigate()
  const t = useT()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1) // decrease by 1 every second
    },
    remaining === 0 || redirectCancelled ? null : 1000 // run every second
  )

  useEffect(() => {
    async function runOnCompleted() {
      if (onCompleted) {
        await onCompleted()
        navigate(`/challenge-group/${challengeGroupId}`, { replace: true })
      }
    }

    if (remaining <= 0) {
      runOnCompleted()
    }
  }, [remaining])

  if (!playAnimation || redirectCancelled) {
    return null
  }

  return (
    <Flex vertical="center" horizontal="space-between">
      <Text color={colors.grey400}>
        {t(`Redirecting to leaderboard in {{remaining}} seconds...`, {
          remaining: remaining.toString(),
        })}
      </Text>
      <SecondaryButton onClick={() => setRedirectCancelled(true)}>
        Cancel
      </SecondaryButton>
    </Flex>
  )
}
