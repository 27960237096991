/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { ComponentPropsWithRef, memo } from 'react'
import {
  textCondensedLegacy,
  textStyleLegacy,
  textUppercaseLegacy,
  textWideLegacy,
  textExtraCondensedLegacy,
} from '../styles/styles'

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export const sizeByLevel: { [key in HeadingLevel]: SerializedStyles } = {
  1: css`
    font-size: 64px;
  `,
  2: css`
    font-size: 48px;
  `,
  3: css`
    font-size: 32px;
  `,
  4: css`
    font-size: 26px;
  `,
  5: css`
    font-size: 18px;
  `,
  6: css`
    font-size: 15px;
  `,
}

export type HeadingProps = {
  level: HeadingLevel
  looksLikeLevel?: HeadingLevel
  color?: string
  strong?: boolean
  condensed?: boolean
  extraCondensed?: boolean
  wide?: boolean
  textAlign?: 'left' | 'center' | 'right'
  uppercase?: boolean
  italic?: boolean
} & ComponentPropsWithRef<'h1'>

export const Heading = memo(function Heading({
  level,
  looksLikeLevel,
  strong = true,
  condensed = false,
  extraCondensed = false,
  wide = false,
  textAlign,
  color,
  uppercase = false,
  italic = false,
  ...rest
}: HeadingProps) {
  const props = {
    css: [
      textStyleLegacy,
      condensed && textCondensedLegacy,
      extraCondensed && textExtraCondensedLegacy,
      wide && textWideLegacy,
      uppercase && textUppercaseLegacy,
      css`
        line-height: 1.1em;
        margin-top: 0.1em;
        margin-bottom: 0.2em;
        color: ${color ?? 'black'};
      `,
      sizeByLevel[looksLikeLevel ?? level],
      strong &&
        css`
          font-weight: 600;
        `,
      textAlign &&
        css`
          text-align: ${textAlign};
        `,
      italic &&
        css`
          font-style: italic;
        `,
    ],
    ...rest,
  }

  switch (level) {
    case 1:
      return <h1 {...props} />
    case 2:
      return <h2 {...props} />
    case 3:
      return <h3 {...props} />
    case 4:
      return <h4 {...props} />
    case 5:
      return <h5 {...props} />
    case 6:
      return <h6 {...props} />
  }
})
