import { gql, useMutation } from '@apollo/client'
import { User } from '../Profile/queries'

const buyStoreItem = gql`
  mutation buyStoreItem($input: StoreItemInput!) {
    buyStoreItem(input: $input) {
      id
      unlockedStyles
    }
  }
`

type StoreItemInput = {
  style: string
}

export const useBuyStoreItemMutation = () =>
  useMutation<{ buyStoreItem: User }, { input: StoreItemInput }>(buyStoreItem)
