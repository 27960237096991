/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { colors } from '../../../styles/theme'

export const HostModeHeader = () => {
  return (
    <Flex horizontal="center">
      <HeadingNew
        level={1}
        condensed
        color={colors.grey300}
        italic
        css={[
          css`
            font-size: 30px;
            font-weight: 500;
            line-height: 53px;
          `,
        ]}
      >
        Host Mode
      </HeadingNew>
    </Flex>
  )
}
