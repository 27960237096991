/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useT } from '../lib/i18n/useT'
import { useNativeApp } from '../lib/native/NativeAppContext'
import { margin, Spacer } from '../styles/margin'
import { Flex } from './layout/Flex'
import { PageContent } from './layout/PageContent'
import { Link } from './Link'
import { RoundedBox } from './RoundedBox'
import { Text } from './Text'
import { RenewCookiesButton } from './RenewCookiesButton'

export const Footer = ({ backgroundColor }: { backgroundColor?: string }) => {
  const t = useT()
  const { isNativeApp } = useNativeApp()

  return (
    <footer
      css={[
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `,
      ]}
    >
      <PageContent css={margin.vertical('large')} horizontal="center">
        <RoundedBox
          shadow
          css={css`
            padding: 16px 12px;
          `}
        >
          <Flex column>
            <Text size="small" color="white" textAlign="center">
              {t('fcQuiz is constantly trying to improve. Got feedback?')}{' '}
              <a
                href="https://discord.gg/x3hdFzxSvt"
                target="_blank"
                color="white"
                css={css`
                  text-decoration: none;
                `}
              >
                <Text
                  css={css`
                    text-decoration: none;
                    border-bottom: 1px solid white;
                    cursor: pointer;
                    color: white;
                    font-size: 15px;
                  `}
                >
                  {t(`Join our Discord 👾`)}
                </Text>
              </a>
            </Text>

            <Spacer height={'small'} />

            <Flex
              horizontal="center"
              css={margin.vertical('tiny')}
              gap={'small'}
              wrap
            >
              <a
                href="https://forms.gle/FCwL8uVBXVVtmoKZA"
                target="_blank"
                color="white"
                css={css`
                  text-decoration: none;
                `}
              >
                <Text
                  css={css`
                    text-decoration: none;
                    border-bottom: 1px solid white;
                    cursor: pointer;
                    color: white;
                    font-size: 15px;
                  `}
                >
                  {t('Feedback?')}
                </Text>
              </a>
              <Link
                to="/terms"
                target={isNativeApp ? undefined : '_blank'}
                size="small"
              >
                <Text
                  css={css`
                    text-decoration: none;
                    border-bottom: 1px solid white;
                    cursor: pointer;
                    color: white;
                    font-size: 15px;
                  `}
                >
                  {t('Terms')}
                </Text>
              </Link>
              <Link
                to="/privacy"
                target={isNativeApp ? undefined : '_blank'}
                size="small"
                color="white"
              >
                <Text
                  css={css`
                    text-decoration: none;
                    border-bottom: 1px solid white;
                    cursor: pointer;
                    color: white;
                    font-size: 15px;
                  `}
                >
                  {t('Privacy policy')}
                </Text>
              </Link>
              <RenewCookiesButton />
            </Flex>
          </Flex>
        </RoundedBox>
      </PageContent>
    </footer>
  )
}
