/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { BiFullscreen } from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import { useWakeLock } from 'react-screen-wake-lock'
import { DefaultError } from '../../components/DefaultError'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Loader as LoadingComponent } from '../../components/Loader'
import { FcQuizQrCode } from '../../components/QrCodeComponent'
import { TextNew } from '../../components/TextNew'
import { isDevelopment } from '../../config/config'
import { useT } from '../../lib/i18n/useT'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import {
  getLiveQuizJoinUrl,
  getTextColorForClub,
  ScreenSize,
} from '../Challenge/DisplayPage/util'
import { getLiveQuizStatus, LiveQuizStatus } from '../LiveQuiz/LiveQuizHostPage'
import {
  LiveQuizDisplayInstance,
  useGetLiveQuizDisplayInstanceByCodeQuery,
} from '../LiveQuiz/queries'
import { ActiveDisplayLiveQuiz } from './components/ActiveDisplayLiveQuiz'
import { FinishedDisplayLiveQuiz } from './components/FinishedDisplayLiveQuiz'
import { JoinableDisplayLiveQuiz } from './components/JoinableDisplayLiveQuiz'
import { LastCompletedQuestionDisplayLiveQuiz } from './components/LastCompletedQuestionDisplayLiveQuiz'
import { PendingDisplayLiveQuiz } from './components/PendingDisplayLiveQuiz'
import { ReadableDisplayLiveQuiz } from './components/ReadableDisplayLiveQuiz'

export const DisplayLiveQuizInner = ({
  instance,
  isLandscape,
  screenSize,
}: {
  instance: LiveQuizDisplayInstance
  isLandscape: boolean
  screenSize: ScreenSize
}) => {
  const status = getLiveQuizStatus({ ...instance })

  const club = instance.club

  const joinUrl = getLiveQuizJoinUrl(instance.invitationCode, club?.locale)

  const textColor = club ? getTextColorForClub(club) : colors.black

  const lastQuestionIsCompleted =
    (instance.completedQuestions &&
      instance.completedQuestions.length === instance.questionsCount) ??
    false

  const lastCompletedQuestion = instance.completedQuestions
    ? instance.completedQuestions[instance.completedQuestions.length - 1]
    : null

  const numCompletedQuestions = instance.completedQuestions
    ? instance.completedQuestions.length
    : 0

  const Component = () => {
    switch (status) {
      case LiveQuizStatus.PENDING:
        return (
          <PendingDisplayLiveQuiz
            instance={instance}
            isLandscape={isLandscape}
          />
        )
      case LiveQuizStatus.JOINABLE:
        return (
          <JoinableDisplayLiveQuiz
            instance={instance}
            screenSize={screenSize}
            isLandscape={isLandscape}
          />
        )
      case LiveQuizStatus.READABLEQUESTION:
        return (
          <ReadableDisplayLiveQuiz
            instance={instance}
            isLandscape={isLandscape}
          />
        )
      case LiveQuizStatus.ACTIVEQUESTION:
        return (
          <ActiveDisplayLiveQuiz
            instance={instance}
            isLandscape={isLandscape}
          />
        )
      case LiveQuizStatus.COMPLETEDQUESTION:
        return (
          <LastCompletedQuestionDisplayLiveQuiz
            key={lastCompletedQuestion?.id}
            isLastQuestion={lastQuestionIsCompleted}
            lastCompletedQuestion={lastCompletedQuestion}
            club={club}
            numCompletedQuestions={numCompletedQuestions}
            locale={instance.locale}
            roundResults={instance.leaderboardCurrentQuestion ?? []}
            totalResults={instance.leaderboard ?? []}
          />
        )
      case LiveQuizStatus.FINISHED:
        return (
          <FinishedDisplayLiveQuiz
            instance={instance}
            screenSize={screenSize}
          />
        )
    }
  }

  const showQrCode =
    status !== LiveQuizStatus.PENDING &&
    status !== LiveQuizStatus.JOINABLE &&
    status !== LiveQuizStatus.ACTIVEQUESTION &&
    status !== LiveQuizStatus.FINISHED

  return (
    <Flex
      column
      grow
      horizontal="center"
      css={[
        css`
          margin: auto auto;
          width: 100%;
          aspect-ratio: ${isLandscape ? 16 / 9 : 9 / 16};
          position: relative;
        `,
      ]}
    >
      <Component />

      {showQrCode && (
        <Flex
          column
          css={css`
            position: absolute;
            bottom: 4vh;
            left: 4vh;
            height: 25vh;
            justify-content: center;
            align-items: center;
          `}
        >
          <TextNew
            condensed
            shadow
            narrowShadow
            color={textColor}
            css={css`
              font-size: 3vw;
              font-weight: 600;
              letter-spacing: 0.1em;
            `}
          >
            {instance.invitationCode}
          </TextNew>
          <FcQuizQrCode
            url={joinUrl}
            backgroundColor={club?.backgroundColor ?? colors.white}
            foregroundColor={textColor}
            hideFcQuizLogo
            css={css`
              padding: 16px;
            `}
          />
        </Flex>
      )}
    </Flex>
  )
}

const Loader = ({ code }: { code: string }) => {
  const query = useGetLiveQuizDisplayInstanceByCodeQuery(code)
  const instance = query.data?.getLiveQuizDisplayInstanceByCode

  const windowAspectRatio = window.innerWidth / window.innerHeight

  const fullscreenHandle = useFullScreenHandle()

  const t = useT()

  const { isSupported, released, request, release } = useWakeLock()

  useEffect(() => {
    if (isSupported && !released) {
      console.log('Requesting wake lock')
      request()
    }

    return () => {
      if (isSupported && released) {
        console.log('Releasing wake lock')
        release()
      }
    }
  }, [isSupported, released, request, release])

  const [isLandscape, setIsLandscape] = useState(windowAspectRatio > 1)
  const [screenSize, setScreenSize] = useState<ScreenSize>(
    window.innerWidth < 1920
      ? 'small'
      : window.innerWidth < 2560
      ? '1080p'
      : window.innerWidth < 3840
      ? '1440p'
      : '4k'
  )

  const liveInstanceStatus = instance
    ? getLiveQuizStatus({ ...instance })
    : null

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth / window.innerHeight > 1)
      setScreenSize(
        window.innerWidth < 1920
          ? 'small'
          : window.innerWidth < 2560
          ? '1080p'
          : window.innerWidth < 3840
          ? '1440p'
          : '4k'
      )
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!liveInstanceStatus || liveInstanceStatus === LiveQuizStatus.FINISHED) {
      query.startPolling(10000)
      return
    }

    if (
      liveInstanceStatus === LiveQuizStatus.PENDING ||
      liveInstanceStatus === LiveQuizStatus.JOINABLE
    ) {
      query.startPolling(5000)
      return
    }

    query.startPolling(2000)
  }, [instance?.id, liveInstanceStatus])

  if (query.loading) {
    return <LoadingComponent center />
  }

  if (query.error || !query.data || !instance) {
    const reason = query.error ? query.error.message : 'No data'
    return (
      <DefaultError
        sentryErrorMessage={`DisplayLiveQuizPage | Loader | reason: ${reason}`}
      />
    )
  }

  return (
    <FullScreen handle={fullscreenHandle}>
      <Flex
        column
        grow
        css={css`
          background-color: ${colors.white};
          position: relative;
          height: ${isLandscape ? '100vh' : 'auto'};
          overflow: hidden;
        `}
      >
        {isDevelopment && (
          <TextNew
            css={css`
              position: absolute;
              top: 8px;
              left: 16px;
              background-color: ${colors.black};
              color: ${colors.white};
              padding: 4px;
              font-size: 14px;
            `}
          >
            {screenSize}
          </TextNew>
        )}

        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.05;
          `}
        >
          <Logo
            css={css`
              height: 40vw;
              width: 40vw;
            `}
          />
        </div>

        {!fullscreenHandle.active && (
          <button
            aria-label={t('Enter fullscreen')}
            onClick={fullscreenHandle.enter}
            css={[
              buttonReset,
              css`
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 16px;
                right: 16px;
                background-color: ${colors.black};
                height: 40px;
                width: 40px;
                border-radius: 50%;
                opacity: 0.8;
                transition: opacity 0.2s;
                z-index: 1000;

                &:hover {
                  opacity: 1;
                }
              `,
            ]}
          >
            <BiFullscreen
              size={24}
              css={css`
                color: ${colors.white};
                padding: 0;
                line-height: 1;
                margin: 0;
              `}
            />
          </button>
        )}

        <DisplayLiveQuizInner
          instance={instance}
          isLandscape={isLandscape}
          screenSize={screenSize}
        />
      </Flex>
    </FullScreen>
  )
}

export const DisplayLiveQuizPage = () => {
  const code = useParams().code

  if (!code) {
    return (
      <DefaultError
        sentryErrorMessage={`DisplayLiveQuizPage | No instance id`}
      />
    )
  }

  return (
    <Layout
      title={`fcQuiz | ${code}`}
      hideNavbar
      hideHeaderPadding
      css={css`
        height: 100vh;
      `}
    >
      <Loader code={code} />
    </Layout>
  )
}
