/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import splashImage from '../assets/splash.png'

type Props = {
  prefetch?: boolean
}

export const SplashImage: FC<Props> = ({ prefetch }) => {
  const image = (
    <img // TODO: fix
      alt="Splash"
      //layout="intrinsic"
      width={1100}
      height={824}
      //quality={100}
      src={splashImage}
      //priority
    />
  )
  return prefetch ? (
    <div
      css={css`
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      `}
    >
      {image}
    </div>
  ) : (
    image
  )
}
