/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Text } from './Text'
import { Flex } from './layout/Flex'
import { useT } from '../lib/i18n/useT'

export const EditLeagueToggle = ({
  editEnabled,
  setEditEnabled,
}: {
  editEnabled: boolean
  setEditEnabled: (value: boolean) => void
}) => {
  const t = useT()
  return (
    <Flex gap="small">
      <Text color="white">{t('Edit')}</Text>
      <input
        type="checkbox"
        checked={editEnabled}
        onChange={(event) => {
          setEditEnabled(event.target.checked)
        }}
        css={css`
          margin: 0;
          height: 30px;
          width: 30px;
        `}
      />
    </Flex>
  )
}
