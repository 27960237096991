import { css } from '@emotion/react'
import { uuidv4 } from '@firebase/util'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Loader } from '../../components/Loader'
import { ProfilePicture } from '../../components/Picture'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { TFunction, useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { lineClamp } from '../../styles/styles'
import { boxShadows, colors } from '../../styles/theme'
import { formatDate } from '../../utils/date'
import { leagueFrequencyToString } from '../League/LeaguePage'
import { RewardFrequency } from '../League/queries'
import { AdminNavbar } from './Navbar'
import {
  convertDate,
  getMonthsFromDate,
  getStartAndEndDateOfPeriod,
  getWeeksFromDate,
  getYearsFromDate,
} from './Utils'
import {
  AdminPageLeague,
  ParticipantItem,
  WinnerItem,
  useAddWinnerToLeagueReward,
  useLeagueParticipantsQuery,
  useLeagueQuery,
  useLeagueRewardWinnersQuery,
} from './queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

export function rewardFrequencyToString(
  t: TFunction,
  frequency: RewardFrequency
): string {
  switch (frequency) {
    case RewardFrequency.once:
      return t('Once')
    case RewardFrequency.weekly:
      return t('Weekly')
    case RewardFrequency.monthly:
      return t('Monthly')
    case RewardFrequency.yearly:
      return t('Yearly')
  }
}

export function dateToPeriod(
  date: Date,
  rewardFrequency: RewardFrequency,
  t: TFunction
): string {
  const newDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  )
  newDate.setUTCDate(newDate.getUTCDate() + 4 - (newDate.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(newDate.getUTCFullYear(), 0, 1))
  const weekNumber = Math.ceil(
    ((newDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  )
  switch (rewardFrequency) {
    case RewardFrequency.once:
      return date.toLocaleDateString()
    case RewardFrequency.weekly:
      return t('Week') + ' ' + weekNumber + ' ' + date.getFullYear()
    case RewardFrequency.monthly:
      return formatDate(date, 'MMMM-yy')
    case RewardFrequency.yearly:
      return formatDate(date, 'yyyy')
  }
}

function drawWinner(users: ParticipantItem[]): ParticipantItem {
  const randomIndex = Math.floor(Math.random() * users.length)
  return users[randomIndex]
}

function participantToWinner(
  participant: ParticipantItem,
  wonDate: Date = new Date()
): WinnerItem {
  return {
    id: uuidv4(),
    user: {
      id: participant.user.id,
      createdAt: new Date().toISOString(), // Assuming the user was just created. Adjust as needed.
      username: participant.user?.username ?? '',
      slug: participant.user.slug,
      email: participant.user.email, // We don't have an email in ParticipantItem, so you might want to fetch or handle it another way.
      isAdmin: false, // Assuming the user is not an admin by default. Adjust as needed.
      avatarData: participant.user.avatarData,
      // Add other properties as needed, or set default values.
    },
    wonDate: wonDate,
  }
}

interface DropdownProps {
  options: { label: string; value: Date }[]
  onSelect?: (value: Date) => void
  placeholder?: string
  selected?: Date | null
}

export const DateSelector: FC<DropdownProps> = ({
  options,
  onSelect,
  placeholder = 'Select a period',
  selected = null,
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = new Date(event.target.value)
    onSelect && onSelect(value)
  }

  return (
    <select
      value={selected ? selected.toISOString() : ''}
      onChange={handleChange}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value.toISOString()}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

const RewardDetails: FC<{
  reward: AdminPageLeague['reward']
  league: AdminPageLeague
}> = ({ reward, league }) => {
  const t = useT()
  const [eligibleUsers, setEligibleUsers] = useState<ParticipantItem[]>([])
  const [winner, setWinner] = useState<ParticipantItem | null>(null)
  const [previousWinners, setPreviousWinners] = useState<WinnerItem[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<Date | null>(null)
  const [dropdownOptions, setDropdownOptions] = useState<
    { label: string; value: Date }[]
  >([])

  const { data: previousWinnersData } = useLeagueRewardWinnersQuery(
    league.id,
    league.isRewarded
  )
  const { startDate, endDate } = selectedPeriod
    ? getStartAndEndDateOfPeriod(
        reward.rewardFrequency ?? RewardFrequency.yearly,
        selectedPeriod
      )
    : { startDate: undefined, endDate: undefined }
  const { data, loading } = useLeagueParticipantsQuery(
    league.id,
    league.reward?.rewardCriteria?.minScore ?? 0,
    startDate,
    endDate
  )

  const [addWinnerToLeagueReward] = useAddWinnerToLeagueReward(
    league.id,
    winner?.user?.id ?? '',
    selectedPeriod ?? new Date()
  )

  useEffect(() => {
    if (!loading && data != null && league.isRewarded) {
      setEligibleUsers(data.leagueUsersEligibleForPrize ?? [])
    }
    switch (league.reward?.rewardFrequency) {
      case RewardFrequency.once:
        setDropdownOptions([])
        break
      case RewardFrequency.weekly:
        setDropdownOptions(
          getWeeksFromDate(
            league.availableFrom,
            previousWinners.length > 0
              ? previousWinners
              : previousWinnersData?.leagueRewardWinners ?? []
          )
        )
        break
      case RewardFrequency.monthly:
        setDropdownOptions(
          getMonthsFromDate(
            league.availableFrom,
            previousWinners.length > 0
              ? previousWinners
              : previousWinnersData?.leagueRewardWinners ?? []
          )
        )
        break
      case RewardFrequency.yearly:
        setDropdownOptions(getYearsFromDate(league.availableFrom))
        break
      default:
        setDropdownOptions([])
    }
  }, [loading, data, selectedPeriod])

  useEffect(() => {
    if (previousWinners.length === 0)
      setPreviousWinners(previousWinnersData?.leagueRewardWinners ?? [])
  }, [loading])

  const handleConfirm = async () => {
    try {
      if (
        winner?.user?.id &&
        (selectedPeriod ||
          league.reward?.rewardFrequency === RewardFrequency.once)
      ) {
        await addWinnerToLeagueReward()
          .then(() => {
            console.log('Winner added to league reward')
            const newWinnerItem = participantToWinner(
              winner,
              selectedPeriod ?? new Date()
            )
            setPreviousWinners((prevWinners) => [...prevWinners, newWinnerItem])
            setWinner(null)
            setSelectedPeriod(null)
          })
          .catch((error) => {
            console.error('Error adding winner to league reward:', error)
          })
      }
    } catch (error) {
      console.error('Error adding winner:', error)
    }
  }

  return (
    <Flex
      horizontal="space-between"
      css={css`
        ${margin.top('small')}
        padding: 8px;
      `}
      gap="small"
    >
      <Text size={'large'} strong={true}>
        {t('Reward')}
      </Text>
      {reward && (
        <Flex
          css={css`
            ${margin.top('small')}
          `}
          column={false}
          vertical="center"
          gap="medium"
          horizontal="space-between"
        >
          <Flex column>
            <Flex column>
              <Text size={'small'}>{league.reward.rewardDescription}</Text>
              <Flex
                css={margin.top('medium')}
                column={false}
                vertical={'center'}
              >
                <Text size={'small'} strong={false}>
                  {league.reward.numberOfWinners > 1
                    ? t(
                        '{{numberOfWinners}} winners are selected every {{rewardWinnersInterval}}',
                        {
                          numberOfWinners: league.reward.numberOfWinners,
                          rewardWinnersInterval: rewardFrequencyToString(
                            t,
                            league.reward.rewardFrequency
                          ).toLowerCase(),
                        }
                      )
                    : t('Winner is selected') + ' '}
                </Text>
                <Text size={'small'} strong>
                  {rewardFrequencyToString(
                    t,
                    league.reward.rewardFrequency
                  ).toLowerCase()}
                </Text>
              </Flex>
              <Flex css={margin.top('small')} column={true}>
                <Flex>
                  <Text size={'medium'} strong={true}>
                    {t('Requirements')}
                  </Text>
                </Flex>
                <Flex>
                  {reward.rewardCriteria?.hasPlayedAllQuizzesInPeriod && (
                    <Text size={'small'} strong={false}>
                      {t('Has played all quizzes in period')}
                    </Text>
                  )}
                </Flex>
                <Flex>
                  <Text size={'small'} strong={false}>
                    {t('Minimum score') +
                      ': ' +
                      reward.rewardCriteria?.minScore}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              column
              css={css`
                ${margin.top('medium')}
                ${margin.bottom('huge')}
                              max-height: 300px;
              `}
            >
              <Flex>
                <Text size={'medium'} strong={true}>
                  {t('Previous winners')}
                </Text>
              </Flex>
              <Flex>
                <ul style={{ width: '100%' }}>
                  <li style={{ padding: '4px' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div style={{ width: '15%' }}>
                        <Text size={'small'} strong>
                          {t('Period')}
                        </Text>
                      </div>
                      <div style={{ width: '40%' }}>
                        <Text size={'small'} strong>
                          {t('Name')}
                        </Text>
                      </div>
                      <div style={{ width: '45%' }}>
                        <Text size={'small'} strong>
                          {t('Email')}
                        </Text>
                      </div>
                    </div>
                  </li>
                  {previousWinners.length > 0 ? (
                    previousWinners.map((winner) => (
                      <li
                        key={winner.id}
                        style={{
                          width: '100%',
                          borderTop: '1px solid ' + colors.green300,
                          padding: '8px 4px',
                        }}
                      >
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div style={{ width: '15%' }}>
                            <Text size={'small'}>
                              {dateToPeriod(
                                convertDate(winner.wonDate),
                                league.reward.rewardFrequency,
                                t
                              )}
                            </Text>
                          </div>
                          <div style={{ width: '40%' }}>
                            <Text size={'small'}>{winner.user.username}</Text>
                          </div>
                          <div style={{ width: '45%' }}>
                            <Text size={'small'}>{winner.user.email}</Text>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <Text
                      style={{ width: '100%', padding: '8px 4px' }}
                      css={margin.top('large')}
                    >
                      {t('No winners yet')}
                    </Text>
                  )}
                </ul>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            css={css`
              ${margin.top('medium')}
            `}
            column
          >
            <Flex>
              {eligibleUsers && (
                <Text strong={false} size={'small'}>
                  {t('Eligible winners') + ': ' + eligibleUsers.length}
                </Text>
              )}
            </Flex>
            <Flex
              column={false}
              vertical={'center'}
              horizontal={'space-between'}
            >
              <Flex column={false} gap={'medium'} vertical={'center'}>
                {dropdownOptions.length > 0 && (
                  <DateSelector
                    options={dropdownOptions}
                    onSelect={setSelectedPeriod}
                    selected={selectedPeriod}
                  />
                )}
                {(eligibleUsers.length > 0 ||
                  league?.reward?.rewardFrequency === RewardFrequency.once) && (
                  <PrimaryButton
                    css={css`
                      ${margin.right('large')} min-width: 150px;
                    `}
                    onClick={() => setWinner(drawWinner(eligibleUsers))}
                  >
                    {winner ? t('Draw again') : t('Draw winner')}
                  </PrimaryButton>
                )}
              </Flex>

              {winner && (
                <Flex vertical={'center'} gap={'medium'}>
                  <Text strong={false} size={'medium'}>
                    {t('Winner') + ': ' + winner.user.username}
                  </Text>
                  <SecondaryButton
                    size={'small'}
                    css={css`
                      ${margin.right('medium')}
                    `}
                    onClick={handleConfirm}
                  >
                    {t('Confirm')}
                  </SecondaryButton>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const LeagueDetails: FC<{ league: AdminPageLeague }> = ({ league }) => {
  const t = useT()

  return (
    <Flex
      horizontal="space-between"
      css={css`
        ${margin.top('small')}
        padding: 8px;
        border-radius: 8px;
      `}
      gap="small"
    >
      <Flex
        column={false}
        vertical="center"
        gap="medium"
        horizontal="space-between"
      >
        <Flex column vertical="center" gap="medium">
          <Flex gap="medium">
            {league.frequency && (
              <Chip
                label={leagueFrequencyToString(t, league.frequency)}
                backgroundColor="black"
                color="white"
              />
            )}
            {league.isRewarded && (
              <Chip
                label={t('Rewarded')}
                backgroundColor="black"
                color="white"
              />
            )}
          </Flex>

          {league.availableTo && (
            <Flex gap="small">
              <Text size={'small'}>
                {formatDate(new Date(league.availableFrom), 'dd. MMMM yyyy') ??
                  '-'}
              </Text>
              <Text size={'small'}>{'-'}</Text>
              <Text size={'small'}>
                {formatDate(new Date(league.availableTo), 'dd. MMMM yyyy') ??
                  '-'}
              </Text>
            </Flex>
          )}
          <Flex column gap="medium">
            <Text size={'small'}>{league.description}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const LeagueCard: FC<{ league: AdminPageLeague }> = ({ league }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Flex
      horizontal="space-between"
      css={css`
        ${margin.top('small')}
        padding: 8px;
        border-radius: 8px;
        background-color: ${colors.green100};
        box-shadow: ${boxShadows.default};
      `}
      gap="small"
    >
      <Flex
        column={false}
        vertical="center"
        gap="medium"
        horizontal="space-between"
      >
        <Flex vertical="center" gap="medium">
          <ProfilePicture url={league.imageUrl} size={40} />
          <Flex column gap="medium">
            <Text css={[lineClamp(1)]}>{league.title ?? '-'}</Text>
          </Flex>
        </Flex>

        <Flex>
          {isExpanded ? (
            <BiChevronUp size={24} onClick={() => setIsExpanded(!isExpanded)} />
          ) : (
            <BiChevronDown
              size={24}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          )}
        </Flex>
      </Flex>
      {isExpanded && (
        <Flex column>
          <LeagueDetails league={league} />
          {league.isRewarded && (
            <Flex
              css={css`
                border-top: 1px solid ${colors.green500};
              `}
            >
              <Spacer height="large" />
              <RewardDetails reward={league.reward} league={league} />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  )
}

const Content: FC<{}> = () => {
  const { loading, data } = useLeagueQuery()
  let leagues = data?.leagues ?? []

  return (
    <div css={margin.top('large')}>
      <Spacer height="huge" />
      <ul css={margin.top()}>
        {loading && <Loader />}
        {leagues.map((league) => (
          <LeagueCard key={league.id} league={league} />
        ))}
      </ul>
    </div>
  )
}

export const AdminLeaguePage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="leagues" />

          <Content />
        </PageContent>

        <Footer />
      </AdminAccessControlComponent>
    </Layout>
  )
}
