/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ClubElement } from '../../components/ClubElement'
import { Heading } from '../../components/Heading'
import { Link } from '../../components/Link'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Cross } from '../../components/icons/Cross'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { PlayerElement } from '../Clubs/PlayerElement'
import { LeagueItem } from '../Home/LeagueItem'
import { useLibrarySearchQuery } from './queries'
import { FeedLeagueQuizComponent } from '../Feed/FeedItemComponent'

enum ContentTypes {
  LEAGUES = 'Leagues',
  QUIZZES = 'Quizzes',
  CLUBS = 'Clubs',
  USERS = 'Users',
}

const ContentTypePicker = ({
  selectedContentType,
  setSelectedContentType,
  emptyContentTypes,
}: {
  selectedContentType: ContentTypes
  setSelectedContentType: (type: ContentTypes) => void
  emptyContentTypes: ContentTypes[]
}) => {
  const t = useT()
  return (
    <Flex horizontal={'space-between'} gap={'small'} column={false} css={css``}>
      {Object.values(ContentTypes).map((type) => {
        if (emptyContentTypes.includes(type)) {
          return null
        }

        return (
          <Flex
            key={type}
            css={css`
              cursor: pointer;
              border-radius: 5px;
              padding: 5px 8px;
              text-align: center;

              border: 1px solid ${colors.white};
              background-color: ${selectedContentType === type
                ? colors.white
                : 'transparent'};
              color: ${selectedContentType === type
                ? colors.green600
                : colors.white};
            `}
            onClick={() => setSelectedContentType(type)}
          >
            <Text
              textAlign={'center'}
              css={css`
                width: 100%;
              `}
              color={selectedContentType !== type ? colors.white : colors.black}
            >
              {t(type)}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}

const Content = () => {
  const t = useT()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedContentType, setSelectedContentType] = useState(
    ContentTypes.LEAGUES
  )
  const debouncedSearchQuery = useDebouncedString(searchQuery, 650, {
    minLength: 3,
  })
  const searchQueryResult = useLibrarySearchQuery(debouncedSearchQuery)
  const { authUser } = useAuthContext()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  // Set the search query from the URL
  useEffect(() => {
    if (searchParams.get('query')) {
      // Decode the search query from the URL
      const query = searchParams.get('query') as string
      const decodedSearchQuery = decodeURIComponent(query)
      setSearchQuery(decodedSearchQuery)
    }
    if (searchParams.get('type')) {
      setSelectedContentType(searchParams.get('type') as ContentTypes)
    }
  }, [])

  // Set the params to the search query
  useEffect(() => {
    if (searchQuery) {
      //Encode the search query to be URL friendly
      const encodedSearchQuery = encodeURIComponent(searchQuery)
      const encodedType = encodeURIComponent(selectedContentType)
      setSearchParams({ query: encodedSearchQuery, type: encodedType })
    }
  }, [debouncedSearchQuery, selectedContentType])

  // Set the content type based on what content is available
  useEffect(() => {
    const { librarySearch } = searchQueryResult.data || {}
    let type = ContentTypes.LEAGUES // Default to LEAGUES

    // Define an array of content types in the order of priority
    const contentTypes = [
      { key: 'leagues', type: ContentTypes.LEAGUES },
      { key: 'quizzes', type: ContentTypes.QUIZZES },
      { key: 'clubs', type: ContentTypes.CLUBS },
      { key: 'users', type: ContentTypes.USERS },
    ]

    // Explicitly define the type of librarySearch to work around the TypeScript error
    const safeLibrarySearch: { [key: string]: any[] } = librarySearch || {}

    // Find the first non-empty content type
    const nonEmptyType = contentTypes.find(
      ({ key }) => safeLibrarySearch[key] && safeLibrarySearch[key].length > 0
    )

    // If a non-empty type is found, update the selected content type
    if (nonEmptyType) {
      type = nonEmptyType.type
    }

    setSelectedContentType(type)
  }, [searchQueryResult.data])

  const searchResult = searchQueryResult.data?.librarySearch

  const emptyContentTypes = () => {
    const emptyTypes: ContentTypes[] = [
      ContentTypes.LEAGUES,
      ContentTypes.QUIZZES,
      ContentTypes.CLUBS,
      ContentTypes.USERS,
    ]
    if (!searchResult) {
      return emptyTypes
    }

    if (searchResult.leagues.length > 0) {
      emptyTypes.splice(emptyTypes.indexOf(ContentTypes.LEAGUES), 1)
    }

    if (searchResult.quizzes.length > 0) {
      emptyTypes.splice(emptyTypes.indexOf(ContentTypes.QUIZZES), 1)
    }

    if (searchResult.clubs.length > 0) {
      emptyTypes.splice(emptyTypes.indexOf(ContentTypes.CLUBS), 1)
    }

    if (searchResult.users.length > 0) {
      emptyTypes.splice(emptyTypes.indexOf(ContentTypes.USERS), 1)
    }

    return emptyTypes
  }

  const noResults =
    searchQueryResult.data &&
    !searchQueryResult.loading &&
    searchQuery.trim() !== '' &&
    emptyContentTypes().length === Object.values(ContentTypes).length

  return (
    <PageContent>
      <Flex column grow>
        <Flex vertical="center" horizontal="space-between">
          <Heading level={1} looksLikeLevel={3} color={colors.white}>
            {t(`Search`)}
          </Heading>
          <Link
            to={'/library'}
            css={[
              buttonReset,
              css`
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding-right: 2px;
                padding-bottom: 1px;

                &:hover {
                  background-color: ${colors.grey400};
                }
              `,
            ]}
          >
            <Cross size={32} />
          </Link>
        </Flex>
        <Spacer height={'large'} />
        <Flex
          css={css`
            position: relative;
          `}
        >
          <TextInput
            ref={inputRef}
            value={searchQuery}
            onValue={setSearchQuery}
            placeholder={t('Search...')}
            css={css`
              border-radius: 5px;
              border: none;
              width: 100%;
              z-index: 2;
            `}
          />
          {searchQuery.trim() !== '' && (
            <button
              onClick={() => {
                setSearchQuery('')
                setSearchParams()
              }}
              css={[
                buttonReset,
                css`
                  position: absolute;
                  z-index: 3;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  height: 100%;
                  width: 48px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  opacity: 0;
                  animation: ${fadeIn} 650ms;
                  animation-fill-mode: forwards;
                  animation-delay: 300ms;
                `,
              ]}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  aspect-ratio: 1;
                  background-color: ${colors.grey400};
                  border-radius: 50%;
                  padding: 4px;
                  padding-right: 5px;
                `}
              >
                <Cross size={16} color="white" />
              </div>
            </button>
          )}
        </Flex>
      </Flex>

      <Spacer height="medium" />

      <Flex grow>
        <ContentTypePicker
          selectedContentType={selectedContentType}
          setSelectedContentType={setSelectedContentType}
          emptyContentTypes={emptyContentTypes()}
        />
      </Flex>

      <Spacer height="large" />

      <Flex column grow>
        {searchResult && selectedContentType === ContentTypes.LEAGUES && (
          <Flex
            column
            grow
            gap={'medium'}
            css={css`
              display: grid;
              justify-items: center;
              width: 100%;
              grid-template-columns: 1fr 1fr 1fr;
              @media (max-width: 780px) {
                grid-template-columns: 1fr 1fr;
              }
              @media (max-width: 525px) {
                grid-template-columns: 1fr;
              }
            `}
          >
            {searchResult.leagues.map((league) => (
              <LeagueItem wide={true} key={league.id} league={league} />
            ))}
          </Flex>
        )}

        {searchQueryResult.loading && <Loader size={64} delay={0} center />}
        {searchResult && selectedContentType === ContentTypes.QUIZZES && (
          <Flex
            column
            grow
            gap={'medium'}
            css={css`
              display: flex;
              justify-items: center;
              width: 100%;
            `}
          >
            {searchResult.quizzes.map((quiz) => (
              <FeedLeagueQuizComponent
                key={quiz.id}
                quiz={quiz}
                showChips={false}
              />
            ))}
          </Flex>
        )}

        {searchResult &&
          selectedContentType === ContentTypes.CLUBS &&
          searchResult.clubs.map((club) => (
            <ClubElement key={club.id} club={club} />
          ))}

        {searchResult &&
          selectedContentType === ContentTypes.USERS &&
          searchResult.users.map((user) => (
            <PlayerElement
              key={user.id}
              username={user.username}
              slug={user.slug ?? user.id}
              role={'member'}
              avatarData={user.avatarData}
              backgroundColor={
                user.id === authUser?.uid ? colors.yellow100 : 'white'
              }
            />
          ))}

        {noResults && (
          <Text
            color={colors.grey300}
            size="medium"
            textAlign="center"
            css={[
              margin.top('large'),
              [
                css`
                  opacity: 0;
                  animation: ${fadeIn} 650ms;
                  animation-fill-mode: forwards;
                  animation-delay: 1000ms;
                `,
              ],
            ]}
          >
            {t(`Found no results for: {{query}}`, {
              query: searchQuery,
            })}
          </Text>
        )}

        {searchQuery.trim() === '' && (
          <Text
            color={colors.grey300}
            size="medium"
            textAlign="center"
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${fadeIn} 1000ms;
                animation-fill-mode: forwards;
                animation-delay: 650ms;
              `,
            ]}
          >
            {t(`Search for quizzes, clubs, users, etc..`)}
          </Text>
        )}
      </Flex>
    </PageContent>
  )
}

export const SearchPage = () => {
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  const t = useT()
  return (
    <Layout
      title={`fcQuiz | ${t('Search')}`}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      backgroundColor={colors.green600}
    >
      <Content />
    </Layout>
  )
}
