/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import { useCreateClubMutation } from './queries'

export const CreateClubModal = ({ closeModal }: { closeModal: () => void }) => {
  const t = useT()
  const [createClub] = useCreateClubMutation()

  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) => (value ? undefined : t('Club name cannot be empty')),
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        const result = await createClub({
          variables: { input: { name: values.name } },
        })
        closeModal()

        if (result.data) {
          navigate(`/clubs/${result.data.createClub.slug}`)
        }
      } catch (e) {
        console.error('Failed to create club', e)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  return (
    <Modal
      closeModal={closeModal}
      backgroundColor={colors.grey200}
      hideCross
      borderRadius={'19px'}
    >
      <Spacer height="medium" />
      <HeadingNew
        level={1}
        looksLikeLevel={4}
        textAlign="center"
        condensed
        italic
        size={30}
        css={css`
          text-transform: capitalize;
          line-height: 53px;
        `}
      >
        {t('Create new club')}
      </HeadingNew>
      <Spacer height="medium" />

      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <TextNew
          weight={700}
          size={30}
          italic
          condensed
          css={css`
            line-height: 53px;
          `}
        >
          {t(`Give your Club a name`)}
        </TextNew>
        <TextInput
          value={form.values.name}
          onValue={(val) => form.setValue('name', val)}
          placeholder={t('Club name')}
          textCss={css`
            font-size: 22px;
            line-height: 40px;
          `}
        />
        <Flex horizontal="center">
          <TextNew
            weight={700}
            size={20}
            italic
            color={colors._grey450}
            condensed
            css={css`
              line-height: 30px;
              margin-top: 4px;
            `}
          >
            {t(`Don't use the name of a professional Club or League`)}
          </TextNew>
        </Flex>
        <Flex
          horizontal="center"
          vertical="center"
          css={margin.top('large')}
          gap={'large'}
        >
          <PrimaryButton
            variant="white"
            disabled={form.submitting}
            onClick={closeModal}
            rounded
            shadow
            css={css`
              flex: 1;
              height: 50px;
            `}
          >
            {t('Cancel')}
          </PrimaryButton>

          <PrimaryButton
            rounded
            shadow
            disabled={form.submitting}
            type="submit"
            css={css`
              flex: 1;
              height: 50px;
            `}
          >
            {t('Create')}
          </PrimaryButton>
        </Flex>
      </form>

      <Spacer height="huge" />
    </Modal>
  )
}
