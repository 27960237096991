/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../components/layout/Flex'
import { useEffect, useRef, useState } from 'react'

export const ScrollBoxFade = ({
  maxHeight,
  children,
  fadeColorHex = '#f3f3f3',
}: {
  maxHeight: string
  children: any
  fadeColorHex?: string
}) => {
  const [fadeOpacity, setFadeOpacity] = useState(0)
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const me = scrollRef.current
    if (!me) return
    const style = window.getComputedStyle(me)
    setFadeOpacity(style.height === style.maxHeight ? 1 : 0)
  }, [scrollRef])
  return (
    <Flex
      ref={scrollRef}
      css={css`
        max-height: ${maxHeight};
        overflow-y: auto;
        position: relative;
      `}
      column
      onScrollCapture={(e: any) => {
        const me = e.target
        if (!me) return
        const scrollBottom = me.scrollHeight - (me.clientHeight + me.scrollTop)
        setFadeOpacity(Math.min(1, scrollBottom / 50))
      }}
    >
      {children}
      <Flex
        css={css`
          bottom: 0px;
          position: absolute;
          pointer-events: none;
          width: 100%;
          height: 50px;
          background: linear-gradient(
            0deg,
            ${fadeColorHex} 0%,
            ${fadeColorHex}00 100%
          );
          opacity: ${fadeOpacity};
        `}
      />
    </Flex>
  )
}
