/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { ErrorMessage, TextInput } from '../../../components/TextInput'
import { TextNew } from '../../../components/TextNew'
import { ErrorCode, hasErrorCode } from '../../../lib/apollo/apiError'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useForm } from '../../../utils/useForm'
import { User } from '../../Profile/queries'
import { useAddUserToLeagueWithRoleMutation } from '../queries'
import { MemberComponent } from './MembersSubpage'

export const AddOwnerModal = ({
  closeModal,
  owners,
  viewerId,
  leagueId,
}: {
  closeModal: () => void
  owners: User[]
  viewerId: string
  leagueId: string
}) => {
  const t = useT()

  const [addUserToLeagueWithRole] = useAddUserToLeagueWithRoleMutation()

  const form = useForm({
    initialValues: {
      role: 'owner',
      userId: '',
    },
    validate: {
      userId: (value) => {
        if (!value) {
          return `Please enter a user ID`
        }

        const id = value.trim()

        if (id.length === 0) {
          return `Please enter a user ID`
        }

        if (owners.some((owner) => owner.id === id || owner.username === id)) {
          return `User is already an owner`
        }
      },
    },

    onSubmit: async ({ values, setSubmitError }) => {
      setSubmitError(undefined)
      try {
        await addUserToLeagueWithRole({
          variables: {
            input: {
              groupId: leagueId,
              userIdOrSlug: values.userId,
              role: values.role,
            },
          },
        })
        closeModal()
      } catch (error) {
        if (hasErrorCode(error, ErrorCode.NOT_FOUND)) {
          setSubmitError('User not found')
        } else {
          setSubmitError('Something went wrong. Please try again later.')
        }
      }
    },
  })

  return (
    <Modal closeModal={closeModal} heading={t(`Add owners`)}>
      <Flex column>
        <TextNew>{t(`Current owners`)}</TextNew>
        {owners.map((owner) => (
          <MemberComponent
            key={owner.id}
            member={owner}
            memberIsOwner
            memberIsViewer={owner.id === viewerId}
          />
        ))}
        <Spacer height={16} />
        <form
          css={css`
            padding: 16px;
            background-color: ${colors.grey200};
            border-radius: 8px;
          `}
          onSubmit={(event) => {
            event.preventDefault()
            form.submitForm()
          }}
        >
          <TextInput
            id="userId"
            name="userId"
            label={`User ID or slug`}
            type="text"
            required
            onValue={(value) => {
              form.setValue('userId', value)
            }}
            error={form.submitAttempted && form.fieldErrors.userId}
          />
          <Spacer height={16} />
          <PrimaryButton type="submit">{t(`Add owner`)}</PrimaryButton>

          {form.submitError && (
            <div css={margin.top()}>
              <ErrorMessage>{form.submitError}</ErrorMessage>
            </div>
          )}
        </form>
      </Flex>
    </Modal>
  )
}
