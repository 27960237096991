/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { colors } from './theme'

export const prettyLetterSpacing = css`
  letter-spacing: -0.025em;
`

export const textStyle = css`
  font-family: acumin-variable, sans-serif;
  font-size: 18px;
  letter-spacing: ${prettyLetterSpacing};
  font-variation-settings: 'wght' 500;
`

export const textSemiCondensed = css`
  font-family: acumin-variable, sans-serif;
  letter-spacing: 0em;
  font-variation-settings: 'wght' 500, 'wdth' 70;
`

export const textCondensed = css`
  font-family: acumin-variable, sans-serif;
  letter-spacing: -0.015em;
  font-variation-settings: 'wght' 500, 'wdth' 50;
`

// Not finished. Please finish this if you are using it
export const textExtraCondensed = css`
  font-family: acumin-variable, sans-serif;
`

// Not finished. Please finish this if you are using it
export const textWide = css`
  font-family: acumin-variable, sans-serif;
`

export const textStyleLegacy = css`
  font-family: acumin-pro, sans-serif;
  font-size: 18px;
  letter-spacing: 0.01em;
`
export const textCondensedLegacy = css`
  font-family: acumin-pro-condensed, sans-serif;
  letter-spacing: -0.01em;
`

export const textExtraCondensedLegacy = css`
  font-family: acumin-pro-extra-condensed, sans-serif;
  letter-spacing: -0.01em;
`

export const textWideLegacy = css`
  font-family: acumin-pro-wide, sans-serif;
  letter-spacing: -0.01em;
`

export const textUppercaseLegacy = css`
  font-family: acumin-pro, sans-serif;
  letter-spacing: -0.01em;
  font-style: normal;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`

export const buttonReset = css`
  background: none;
  color: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  text-align: left;
  cursor: pointer;
  &:disabled {
    cursor: initial;
  }
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const lineClampMixin = (lines: number) => `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  line-clamp: ${lines};
  overflow-wrap: break-word;
`

export const lineClamp = (lines: number) => css`
  ${lineClampMixin(lines)}
`

export const absoluteFill = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const screenreaderOnly = css`
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
`

export const confettiColors = [
  colors.green200,
  colors.green300,
  colors.green400,
  colors.white,
  colors.yellow300,
  colors.yellow400,
  colors.yellow500,
]
