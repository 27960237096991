import { useEffect, useState } from 'react'
import { isDevelopment, isTest } from '../../../config/config'

enum UpdateStatus {
  checking = 'CHECKING',
  current = 'CURRENT',
}

export const PageRefreshComponent = () => {
  const [status, setStatus] = useState<UpdateStatus>(UpdateStatus.checking)

  const [currentVersion, setCurrentVersion] = useState<string | null>(null)

  const getCurrentVersion = async () => {
    const myCurrentVersion = await fetch('/version.txt')
      .then((response) => response.text())
      .then((text) => text.trim())

    setStatus(UpdateStatus.current)

    setCurrentVersion(myCurrentVersion)
  }

  const checkUpdate = async () => {
    // Don't check in development
    if (isDevelopment) {
      setStatus(UpdateStatus.current)
      return
    }

    setStatus(UpdateStatus.checking)

    // read version.txt from new version
    const versionUrl = isTest
      ? 'https://test.fcquiz.app/version.txt'
      : 'https://fcquiz.app/version.txt'

    const response = await fetch(versionUrl)
    const responseText = await response.text()
    const latestVersion = responseText.trim()

    // Compare versions
    if (latestVersion === currentVersion) {
      setStatus(UpdateStatus.current)
      return
    } else {
      // Reload page to force update
      window.location.reload()
    }
  }

  useEffect(() => {
    getCurrentVersion()
  }, [])

  useEffect(() => {
    // Don't check in development

    if (isDevelopment) {
      return
    }

    // Dont start new checks if already checking
    if (status !== UpdateStatus.current) {
      return
    }

    // Check every 15 minutes
    const timeoutId = window.setTimeout(() => checkUpdate(), 1000 * 60 * 15)

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [status, checkUpdate, isDevelopment])

  return null
}
