/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from '../../components/Button'
import { Text } from '../../components/Text'
import { GroupMembersIcon, Verified } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { Club } from '../Clubs/queries'

export const ProfileClubComponent = ({
  club,
  isEditingMainClub,
  onClick,
}: {
  club: Club
  isEditingMainClub: Boolean
  onClick: () => void
}) => {
  const t = useT()

  return (
    <Button resetStyling aria-label={club.name} onClick={onClick}>
      <Flex column horizontal="center" vertical="center">
        <Text
          size="tiny"
          strong
          css={css`
            color: ${colors.white};
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 82px;
            white-space: nowrap;
          `}
        >
          {club.name}
        </Text>
        <Spacer height={4} />
        <Flex
          horizontal="center"
          vertical="center"
          css={css`
            height: 82px;
            width: 82px;
            padding: 4px;
            position: relative;
            background-color: white;
            border-radius: 50%;
          `}
        >
          {club.logoUrl && (
            <img
              src={club.logoUrl}
              alt={club.name}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              `}
            />
          )}
          {/* we use the same shield as Verified as a fallback for clubs without logo*/}
          {!club.logoUrl && (
            <Verified
              color={colors.grey300}
              checkColor={colors.grey300}
              width={48}
              height={54}
            />
          )}

          {club.verified && (
            <Verified
              css={css`
                position: absolute;
                top: 0;
                right: 0;
              `}
              height={25}
              width={23}
            />
          )}
          {!isEditingMainClub && club.viewerMainClub && (
            <Flex
              horizontal="center"
              vertical="center"
              css={css`
                position: absolute;
                bottom: 0;
                width: 82px;
                height: 24px;
                background-color: ${colors.green300};
                border-radius: 7px;
              `}
            >
              <Text
                textAlign="center"
                strong
                css={css`
                  font-size: 14px;
                  line-height: 24px;
                `}
              >
                {t(`Main`).toUpperCase()}
              </Text>
            </Flex>
          )}
        </Flex>
        <Spacer height={8} />
        <GroupMembersIcon
          color={colors.white}
          css={css`
            opacity: 0.75;
          `}
        />

        <Text
          size="tiny"
          css={css`
            color: ${colors.white};
            font-size: 14px;
            opacity: 0.75;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 88px;
            white-space: nowrap;
          `}
        >
          {club.membersCount}
        </Text>
      </Flex>
    </Button>
  )
}
