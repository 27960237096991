/** @jsxImportSource @emotion/react */

export const Cross = ({ size = 24, color = 'white' }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 30 30`} fill="none">
      <path
        d={`M24 8L8 24`}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M8 8L24 24`}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
