/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { useT } from '../lib/i18n/useT'
import { getMaskCss, MaskShape } from '../lib/imageMask'
import { Group, Person, Verified } from './icons'
import { Flex } from './layout/Flex'

type CommonProps = {
  url?: string | null
  size: number
  shape?: MaskShape
  className?: string
  frameColor?: string
  horizontalCenter?: boolean
  verified?: boolean
}

type PictureProps = CommonProps & { fallback: () => ReactNode }

const Picture: FC<PictureProps> = ({
  url,
  size,
  shape = 'circle',
  className,
  fallback,
}) => {
  const t = useT()

  return (
    <Flex className={className}>
      {url ? (
        <img
          src={url}
          alt={t('Profile picture')}
          css={[
            css`
              width: ${size}px;
              height: ${size}px;
              object-fit: ${shape == 'uncropped' ? 'contain' : 'cover'};
            `,
            getMaskCss(shape),
          ]}
        />
      ) : (
        fallback()
      )}
    </Flex>
  )
}

export const ProfilePicture: FC<CommonProps> = ({ url, size, className }) => {
  return (
    <Picture
      url={url}
      size={size}
      className={className}
      fallback={() => (
        <Flex
          css={css`
            background-color: white;
            border-radius: 50%;
            padding: ${size * (1 / 6)}px;
          `}
        >
          <Person
            css={css`
              width: ${size * (2 / 3)}px;
              height: ${size * (2 / 3)}px;
            `}
          />
        </Flex>
      )}
    />
  )
}

export const ClubLogo: FC<CommonProps> = ({
  url,
  shape,
  size,
  className,
  verified,
}) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Picture
        url={url}
        shape={shape}
        size={size}
        className={className}
        fallback={() => (
          <Flex
            css={css`
              background-color: white;
              border-radius: 50%;
              padding: ${size * (1 / 6)}px;
            `}
          >
            <Group
              css={css`
                width: ${size * (2 / 3)}px;
                height: ${size * (2 / 3)}px;
              `}
            />
          </Flex>
        )}
      />
      {verified && (
        <Verified
          height={size * 0.3}
          width={size * 0.3}
          css={css`
            position: absolute;
            bottom: 0;
            right: 0;
          `}
        />
      )}
    </div>
  )
}

export const FramedClubLogo: FC<CommonProps> = ({
  url,
  shape,
  size,
  className,
  frameColor = '#f0f0f0',
  horizontalCenter = false,
}) => {
  return (
    <div
      css={css`
        width: ${size * 1.04}px;
        height: ${size * 1.04}px;
        position: relative;
        background-color: ${shape === 'uncropped' ? 'transparent' : frameColor};
        ${getMaskCss(shape)};
        margin: ${horizontalCenter ? '0 auto' : '0'};
      `}
    >
      <ClubLogo
        url={url}
        shape={shape}
        size={size}
        css={css`
          position: absolute;
          top: ${size * 0.02}px;
          left: ${size * 0.02}px;
        `}
        className={className}
      />
    </div>
  )
}
