/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FcQuizLogo } from '../../../components/icons'
import { Flex } from '../../../components/layout/Flex'
import { FramedClubLogo } from '../../../components/Picture'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'
import { viewWidthToNumber } from '../../Admin/Utils'
import {
  getIconImageForClub,
  getTextColorForClub,
} from '../../Challenge/DisplayPage/util'
import { Club } from '../../Clubs/queries'

export const QuestionHeader = ({
  club,
  locale,
  questionNumber,
}: {
  club?: Club
  locale?: string
  questionNumber?: number
}) => {
  const iconUrl = club ? getIconImageForClub(club) : undefined

  const textColor = club ? getTextColorForClub(club) : colors.black

  const t = useT(locale)
  return (
    <Flex
      horizontal="space-between"
      vertical="center"
      css={css`
        padding: 2vw;
      `}
    >
      {iconUrl && club && (
        <FramedClubLogo
          url={iconUrl}
          size={viewWidthToNumber('6vw')}
          shape={club.logoShape}
          frameColor={club.textColor}
        />
      )}

      {!iconUrl && (
        <FcQuizLogo
          color={textColor}
          css={css`
            width: 8vw;
            height: 3vw;
            max-height: 7vw;
            max-width: 30vw;
            object-fit: contain;
          `}
        />
      )}

      <TextNew
        italic
        condensed
        shadow
        color={textColor}
        css={css`
          font-size: 5vw;
          line-height: 1;
          font-weight: 600;
          margin-right: 1vw;
        `}
      >
        {t(`Question {{number}}`, {
          number: questionNumber,
        })}
      </TextNew>
    </Flex>
  )
}
