/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { useT } from '../lib/i18n/useT'
import { MaskShape } from '../lib/imageMask'
import { boxShadows, colors } from '../styles/theme'
import { Flex } from './layout/Flex'
import { Link } from './Link'
import { ClubLogo } from './Picture'
import { TextNew } from './TextNew'

export const CLUB_GRID_ELEMENT_SIZE = 125

export const ClubGridElement: FC<{
  club: {
    id: string
    name: string
    slug: string
    logoUrl?: string | null
    logoShape?: MaskShape
    verified?: boolean
    membersCount: number
    backgroundColor: string
    textColor: string
  }
  className?: string
}> = ({ club, className }) => {
  const t = useT()
  const textColor = colors.white
  const backgroundColor = colors.black

  return (
    <Link to={`/clubs/${club.slug}`} underline={false}>
      <Flex
        column
        vertical="flex-end"
        horizontal="center"
        gap={'small'}
        css={css`
          height: ${CLUB_GRID_ELEMENT_SIZE}px;
          width: ${CLUB_GRID_ELEMENT_SIZE}px;
          background-color: ${backgroundColor};
          border-radius: 17px;
          padding: 4px 4px 10px 4px;

          box-shadow: ${boxShadows.default};
        `}
        className={className}
      >
        <Flex
          vertical="center"
          css={css`
            flex: 1;
          `}
        >
          <TextNew
            size={10}
            textAlign="center"
            weight={800}
            color={textColor}
            autoLineHeight
            css={css`
              text-transform: uppercase;
            `}
          >
            {club.name}
          </TextNew>
        </Flex>
        <ClubLogo
          size={56}
          url={club.logoUrl}
          shape={club.logoShape}
          verified={club.verified}
        />
        <TextNew size={9} color={textColor}>
          {t('{{count}} member', { count: club.membersCount })}
        </TextNew>
      </Flex>
    </Link>
  )
}
