/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentProps } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '../../components/Text'

export const NavItem = ({
  to,
  title,
  active,
  color = 'black',
  className,
}: {
  to: ComponentProps<typeof Link>['to']
  title: string
  active: boolean
  color?: 'black' | 'white'
  className?: string
}) => {
  return (
    <div className={className}>
      <Link
        to={to}
        css={css`
          text-decoration: none;
          cursor: pointer;
        `}
      >
        <Text
          css={css`
            display: block;
            font-size: 26px;
            border-bottom: 2px solid ${active ? color : 'transparent'};
            padding-bottom: 8px;
            opacity: ${active ? 1 : 0.5};
          `}
          condensed
          strong
          color={color}
        >
          {title}
        </Text>
      </Link>
    </div>
  )
}
