/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from '../../components/Button'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'

export const EditClubComponent = (props: {
  setIsEditingMainClub: (value: boolean) => void
}) => {
  const t = useT()

  return (
    <Button
      resetStyling
      onClick={() => {
        props.setIsEditingMainClub(true)
      }}
      aria-label="Edit main club"
    >
      <Flex column horizontal="center" vertical="center">
        <Text
          size="tiny"
          strong
          css={css`
            color: ${colors.white};
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 82px;
            white-space: nowrap;
            opacity: 0;
            pointer-events: none;
          `}
        >
          {t(`Edit main club`)}
        </Text>
        <Spacer height={4} />
        <Flex
          horizontal="center"
          vertical="center"
          css={css`
            height: 82px;
            width: 82px;
            padding: 4px;
            position: relative;

            border-radius: 50%;
            background-color: #323232;
          `}
        >
          <img
            src={'/EditClub.svg'}
            alt={'Edit Club'}
            css={css`
              width: 48px;
              height: 54px;
              object-fit: cover;
            `}
          />
        </Flex>
        <Spacer height={8} />

        <Text
          size="tiny"
          css={css`
            color: ${colors.white};
            font-size: 14px;
            opacity: 0.75;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 88px;
            white-space: pre-wrap;
            font-weight: 100;
          `}
        >
          {t(`Edit main club`)}
        </Text>
      </Flex>
    </Button>
  )
}
