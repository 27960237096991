/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { colors } from '../../styles/theme'
import { FeedItemComponent } from './FeedItemComponent'
import { FeedItem, useFeedQuery } from './queries'

const Content = () => {
  const fetchAmount = 15

  const [initLoad, setInitLoad] = useState(true)
  const [items, setItems] = useState<FeedItem[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [index, setIndex] = useState(0)

  const { ref, inView } = useInView()

  const { data, fetchMore } = useFeedQuery(index, fetchAmount)

  useEffect(() => {
    if (initLoad) {
      if (data) {
        setItems([...items, ...data.feed])
        setIndex(items.length)
        setInitLoad(false)
      }
    }
  }, [initLoad, data])

  const fetchMoreData = async () => {
    const newData = await fetchMore({
      variables: {
        offset: items.length,
        limit: fetchAmount,
      },
    })

    if (newData.data.feed.length < fetchAmount) {
      setHasMore(false)
    }
    setItems([...items, ...newData.data.feed])
    setIndex(items.length)
  }

  useEffect(() => {
    if (inView && hasMore) {
      fetchMoreData()
    }
  }, [inView])

  return (
    <PageContent>
      <Flex column>
        {items.map((item, index) => {
          const isThirdLast = items.length - index === 3
          return (
            <div ref={isThirdLast && hasMore ? ref : undefined} key={index}>
              <FeedItemComponent item={item} />
            </div>
          )
        })}
      </Flex>
    </PageContent>
  )
}
export const FeedPage = () => {
  return (
    <Layout
      withHeader
      withFooter
      title="fcQuiz | Feed"
      backgroundColor={colors.black}
    >
      <Content />
    </Layout>
  )
}
