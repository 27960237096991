/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Flex } from '../../components/layout/Flex'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { LeaderboardItemComponent } from '../../views/League/Leaderboard/LeaderboardItem'
import { LeaderboardItem } from '../../views/League/queries'
import { TextNew } from '../TextNew'

export const TopPlayers = ({
  hideLink = false,
  quizId,
  leagueSlug,
  viewerHasFriends,
  entries,
}: {
  hideLink?: Boolean
  quizId: string
  leagueSlug?: string
  viewerHasFriends?: boolean
  entries: LeaderboardItem[]
}) => {
  const t = useT()

  const { authUser } = useAuthContext()
  return (
    <Flex column>
      <Flex horizontal="space-between" vertical="center">
        <TextNew
          size={16}
          italic
          condensed
          spacingEm={0.01}
          strong
          color={'#676767'}
        >
          {viewerHasFriends ? t(`Friends`) : t(`Top players`)}
        </TextNew>
        {!hideLink && leagueSlug && (
          <Link
            target="_parent"
            css={css`
              text-decoration: none;
              display: flex;
              gap: 8px;
              align-items: center;
            `}
            to={`/series/${leagueSlug}/${quizId}`}
          >
            <TextNew
              size={16}
              italic
              condensed
              spacingEm={0.01}
              strong
              color={'#676767'}
            >
              {t(`See the leaderboard`)}
            </TextNew>
            <img
              src="/chevron2.svg"
              css={css`
                width: 4px;
                height: 8px;
                margin-left: -2px;
              `}
            />
          </Link>
        )}
      </Flex>
      <Spacer height={8} />
      <Flex
        css={css`
          position: relative;
          margin-bottom: 4px;
        `}
        column
      >
        <Flex
          column
          css={css`
            gap: 4px;
            padding: 0px 20px;
          `}
        >
          {entries.map((it, index) => (
            <LeaderboardItemComponent
              key={index}
              item={it}
              isViewer={authUser?.uid === it.user.id}
              height={32}
              small
              clickable={false}
              css={[
                css`
                  flex: 1;
                `,
              ]}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
