/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import Hidden from '../../assets/hidden.svg'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'
import { colors } from '../../styles/theme'
import { GoArrowRight } from 'react-icons/go'
import { Modal, ModalTrigger } from '../modal/Modal'

const CLUB_LOGO_SIZE = 56
const CLUB_LOGO_SIZE_LARGE = 68

const growShrink = keyframes`
  0% {
    transform: scale(0.9);
      opacity: 0;
  }
  70% {
    transform: scale(1.1);
      opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`

export type CareerPathEntry = {
  team: {
    id: string
    name: string
    logoUrl: string
  }
  year: string
  type: string
}

export default function CareerPath({
  careerPath = [],
  showYear = false,
  hideClubsIndex = -1,
  lastCompleted = false,
}: {
  careerPath: CareerPathEntry[]
  showYear?: boolean
  hideClubsIndex?: number
  lastCompleted?: boolean
}) {
  const t = useT()
  return (
    <Flex column horizontal={'center'}>
      <Spacer height={'small'} />
      <Flex
        vertical={'flex-start'}
        horizontal={'center'}
        css={css`
          flex-wrap: wrap;
        `}
      >
        {careerPath.map((club, index) => {
          return (
            <Flex
              column={false}
              key={index}
              css={css`
                height: 96px;
              `}
            >
              <Flex
                column
                gap={'tiny'}
                vertical={'flex-end'}
                horizontal={'center'}
                css={css`
                  position: relative;
                `}
              >
                <ModalTrigger
                  button={({ openModal }) => (
                    <img
                      onClick={index <= hideClubsIndex ? openModal : undefined}
                      css={
                        lastCompleted
                          ? css`
                              animation: ${growShrink} 0.5s ease-out
                                ${index * 0.5}s both;
                              cursor: pointer;
                            `
                          : index == hideClubsIndex &&
                            css`
                              animation: ${growShrink} 0.7s ease-in-out;
                              cursor: pointer;
                            `
                      }
                      height={
                        index == hideClubsIndex
                          ? CLUB_LOGO_SIZE_LARGE
                          : CLUB_LOGO_SIZE
                      }
                      src={
                        club.team.logoUrl === 'Hidden'
                          ? Hidden
                          : club.team.logoUrl
                      }
                      alt={
                        club.team.logoUrl == 'Hidden'
                          ? t('Hidden')
                          : club.team.name
                      }
                      title={
                        club.team.logoUrl == 'Hidden'
                          ? t('Hidden')
                          : club.team.name
                      }
                    />
                  )}
                  modal={({ closeModal }) => (
                    <ClubInfoDialog entry={club} onClose={closeModal} />
                  )}
                />

                {showYear && (
                  <Flex
                    css={css`
                      height: 24px;
                    `}
                  >
                    <TextNew
                      condensed
                      italic
                      spacingEm={0}
                      size={'large'}
                      strong
                    >
                      {club.year}
                    </TextNew>
                  </Flex>
                )}

                {club.type.toLowerCase() == 'loan' &&
                  index != 0 &&
                  (index <= hideClubsIndex || hideClubsIndex == -1) && (
                    <Flex
                      css={css`
                        position: absolute;
                        top: -6px;
                        right: -8px;
                        background-color: ${colors.yellow400};
                        padding: 4px 8px;
                        border-radius: 40px;

                        box-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px 0;
                      `}
                    >
                      <TextNew
                        color={colors.grey500}
                        condensed
                        italic
                        spacingEm={0}
                        size={'small'}
                        strong
                      >
                        {club.type}
                      </TextNew>
                    </Flex>
                  )}
              </Flex>
              {index != careerPath.length - 1 && (
                <Flex
                  column
                  gap={'tiny'}
                  vertical={'flex-end'}
                  horizontal={'center'}
                  css={css`
                    position: relative;
                  `}
                >
                  <Flex
                    vertical={'center'}
                    css={css`
                      height: 24px;
                    `}
                  >
                    <GoArrowRight color={colors.grey400} size={18} />
                  </Flex>
                </Flex>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

function ClubInfoDialog({
  entry,
  onClose,
}: {
  entry: CareerPathEntry
  onClose: () => void
}) {
  return (
    <Modal
      heading={entry.team.name}
      closeModal={onClose}
      maxWidth={400}
      css={css`
        text-align: center;
      `}
    >
      <Flex
        column
        horizontal={'center'}
        css={css`
          padding: 16px;
        `}
      >
        <img
          src={entry.team.logoUrl === 'Hidden' ? Hidden : entry.team.logoUrl}
          alt={entry.team.name}
          css={css`
            max-width: 200px;
            max-height: 200px;
          `}
        />
      </Flex>
    </Modal>
  )
}
