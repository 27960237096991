/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { leagueQuizItemStyleV2 } from './LeaguePage'
import { League } from './queries'
import { TextNew } from '../../components/TextNew'
import { colors } from '../../styles/theme'
import { useT } from '../../lib/i18n/useT'
import { Divider } from '../../components/Divider'

export const TotalScoresItemV2 = ({ league }: { league: League }) => {
  const t = useT()

  return (
    <Flex
      column
      vertical={'space-between'}
      css={[
        leagueQuizItemStyleV2,
        css`
          background-color: ${colors.yellow100};
        `,
      ]}
    >
      <TextNew size={21} condensed>
        {league.title}
      </TextNew>
      <Flex
        column
        vertical={'center'}
        gap={'small'}
        css={css`
          height: 100%;
          width: 100%;
        `}
      >
        <TextNew
          condensed
          spacingEm={0}
          strong
          css={css`
            font-size: 31px;
          `}
        >
          {t(`Total score`)}
        </TextNew>

        <Divider color={colors.black} size={1} />
        <TextNew size={14}>
          {t('{{count}} player', {
            count: league.participantCount,
          })}
        </TextNew>
      </Flex>
      <Flex
        vertical={'flex-end'}
        css={css`
          width: 100%;
          margin-bottom: 8px;
          margin-left: 8px;
        `}
      >
        <TextNew
          textAlign="center"
          italic
          condensed
          spacingEm={0}
          strong
          css={css`
            font-size: 45px;
          `}
        >
          {league.viewerResult.totalScore}
        </TextNew>
        <TextNew
          size={'massive'}
          textAlign="center"
          italic
          condensed
          spacingEm={0}
          strong
        >
          p
        </TextNew>
      </Flex>
    </Flex>
  )
}
