import { keyframes } from '@emotion/react'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const expand = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`

export const grow = keyframes`
    from {
    width: 0%;
    }
    to {
    width: 100%;
    }
`

export const growAndFade = keyframes`
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
    `
export const growAndFadeBadge = keyframes`
    0% {
      transform: translate(-500%,500%) scale(0.1);
      opacity: 0;
    }
    10% {
      transform: translate(-500%,500%) scale(5.0);
      opacity: 1;
    }
    
    100% {
      transform: translate(0,0) scale(1);
      opacity: 1;
    }
`

export const coinFlip = keyframes`
  0% {
    transform: translateY(0) rotateX(0deg);
    filter: drop-shadow(0 2px 0 rgb(155, 155, 155));
  }
  10% {
    transform: translateY(-15px) rotateX(0deg);
    filter: drop-shadow(0 2px 0 rgb(155, 155, 155));
  }
  30% {
    transform: translateY(-17px) rotateX(110deg);
    filter: drop-shadow(0 10px 0 rgb(155, 155, 155));
  }
  50% {
    transform: translateY(-20px) rotateX(180deg);
    filter: drop-shadow(0 2px 0 rgb(155, 155, 155));
  }
  70% {
    transform: translateY(-17px) rotateX(250deg);
    filter: drop-shadow(0 10px 0 rgb(155, 155, 155));
  }
  90% {
    transform: translateY(-15px) rotateX(360deg);
    filter: drop-shadow(0 2px 0 rgb(155, 155, 155));
  }
  100% {
    transform: translateY(0) rotateX(360deg);
  }
`

export const horizontalFlip = keyframes`
  0% {
    transform: translateY(0) rotateY(0deg);
  }
  10% {
    transform: translateY(-15px) rotateY(0deg);
  }
  30% {
    transform: translateY(-17px) rotateY(110deg);
  }
  50% {
    transform: translateY(-20px) rotateY(180deg);
  }
  70% {
    transform: translateY(-17px) rotateY(250deg);
  }
  90% {
    transform: translateY(-15px) rotateY(360deg);
  }
  100% {
    transform: translateY(0) rotateY(360deg);
  }
`

export const moveTowards = (
  idMe: string,
  idTarget: string,
  alsoMoveScale: boolean = false
) => {
  const me = document.getElementById(idMe)
  const target = document.getElementById(idTarget)

  if (!me || !target) return
  const meRect = me.getBoundingClientRect()
  const targetRect = target.getBoundingClientRect()
  const x = (rect: any) => rect.left * 0.5 + rect.right * 0.5
  const y = (rect: any) => rect.top * 0.5 + rect.bottom * 0.5
  const deltaX = x(targetRect) - x(meRect)
  const deltaY = y(targetRect) - y(meRect)

  //I'll only consider width for simplicity
  const scaleEnd = targetRect.width / meRect.width
  const scaleEndCss = alsoMoveScale ? `scale(${scaleEnd})` : ''

  return keyframes`
  0% {
    transform: translateX(0px) translateY(0px);
    opacity: 1;
  }
  99% {
    transform: translateX(${deltaX}px) translateY(${deltaY}px) ${scaleEndCss}; 
    opacity: 1;
  }
  100% {
    transform: translateX(${deltaX}px) translateY(${deltaY}px) ${scaleEndCss}; 
    opacity: 0;
  }
`
}

export const fadeOutRight = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
`
export const fadeInLeft = keyframes`
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeInLeftfadeOutRight = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateX(50%);
  }
  51% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const moveLeftToRight = (widthContainer: number) => keyframes`
  0% {
    transform: translateX(50%) translateX(${widthContainer / 2}px);
  }
  100% {
    transform: translateX(-50%) translateX(${-widthContainer / 2}px);
  }
`
export const moveLeftToCenter = (widthContainer: number) => keyframes`
  0% {
    transform: translateX(50%) translateX(${widthContainer / 2}px);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(0%);
  }
`

export const fcCurve = 'cubic-bezier(0, 0, 0, 1)'
export const pulseCurve = 'cubic-bezier(0, 1, 1, 1)'

export const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.1);
  }
 
  100% {
    transform: scale(1);
  }
`
