/** @jsxImportSource @emotion/react */

import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { reverse, sortBy } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { FaCrown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { SelectInput } from '../../components/SelectInput'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { Locale, langShortToLang } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { lineClamp } from '../../styles/styles'
import { boxShadows, colors } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import { useDebouncedValue } from '../../utils/useDebouncedValue'
import { UserAvatar } from '../Avatar/UserAvatar'
import { ChallengeNavItem } from '../Challenge/ChallengeGroupPage'
import { AdminNavbar } from './Navbar'
import {
  AdminPageUser,
  useLocaleCountQuery,
  useUsersSearchPaginatedQuery,
} from './queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'

type Sorting = 'username' | 'email' | 'createdAt'
type SortDirection = 'asc' | 'desc'

const AdminUserSearch: FC<{}> = () => {
  const t = useT()
  const pageSize = 200

  const [page, setPage] = useState(1)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(search, 300)
  const { loading, data } = useUsersSearchPaginatedQuery(
    page,
    pageSize,
    debouncedSearch
  )
  const [adminOnly, setAdminOnly] = useState(false)
  const [sorting, setSorting] = useState<Sorting>('createdAt')
  const [direction, setDirection] = useState<SortDirection>('desc')

  const [filterLocale, setFilterlocale] = useState<
    'no' | 'en' | 'es' | 'it' | 'ar' | 'fr' | 'de' | 'pt' | 'all'
  >('all')

  let users = data?.usersSearchPaginated ?? []

  useEffect(() => {
    if (adminOnly) {
      setFilterlocale('all')
    }
  }, [adminOnly])

  if (adminOnly) {
    users = users.filter((user) => user.isAdmin)
  }

  if (filterLocale !== 'all') {
    users = users.filter((user) => user.locale === filterLocale)
  }

  if (search) {
    users = users.filter((user) => {
      const searchLower = search.toLocaleLowerCase()
      const username = (
        user.displayName ??
        user.username ??
        ''
      ).toLocaleLowerCase()
      const email = (user.email ?? '').toLocaleLowerCase()
      const uid = (user.id ?? '').toLocaleLowerCase()

      return (
        username.includes(searchLower) ||
        email.includes(searchLower) ||
        uid.includes(searchLower)
      )
    })
  }

  users = sortBy(users, (user) => (user[sorting] ?? '').toLocaleLowerCase())
  if (direction === 'desc') {
    users = reverse(users)
  }

  const filteredUserCount = users.length

  return (
    <Flex column css={margin.top()}>
      <div
        css={css`
          max-width: 400px;
        `}
      >
        <ModalTrigger
          button={({ openModal }) => (
            <SecondaryButton onClick={openModal} css={margin.bottom('large')}>
              Delete profile picture for user
            </SecondaryButton>
          )}
          modal={({ closeModal }) => (
            <RemoveProfilePictureModal closeModal={closeModal} />
          )}
        />

        <TextInput
          label={t('Search for ID, username or email')}
          placeholder={t('Search...')}
          value={search}
          onValue={setSearch}
        />

        <Flex css={margin.top('medium')} gap="medium">
          <SelectInput
            value={sorting}
            onValue={(val) => setSorting(val)}
            label={t('Sort by')}
            css={[
              css`
                flex: 3;
              `,
            ]}
          >
            <option value="createdAt">{t('Created at')}</option>
            <option value="username">{t('Username')}</option>
            <option value="email">{t('Email')}</option>
          </SelectInput>

          <SelectInput
            value={direction}
            onValue={(val) => setDirection(val)}
            label={t('Direction')}
            css={css`
              flex: 1;
            `}
          >
            <option value="asc">{t('Ascending')}</option>
            <option value="desc">{t('Descending')}</option>
          </SelectInput>
        </Flex>

        <Spacer height="medium" />
        <ToggleSwitch
          value={adminOnly}
          onValue={setAdminOnly}
          label={t('Only show admins')}
          css={margin.top('medium')}
        />

        <Spacer height="medium" />

        <SelectInput
          value={filterLocale}
          onValue={(val) => setFilterlocale(val)}
          label={t('Language')}
          css={css`
            flex: 1;
          `}
        >
          <option value="all">{t('All')}</option>
          <option value="no">{'no'}</option>
          <option value="en">{'en'}</option>
          <option value="es">{'es'}</option>
          <option value="it">{'it'}</option>
          <option value="ar">{'ar'}</option>
          <option value="fr">{'fr'}</option>
          <option value="de">{'de'}</option>
          <option value="pt">{'pt'}</option>
          <option value="ja">{'ja'}</option>
          <option value="sv">{'sv'}</option>
          <option value="da">{'da'}</option>
        </SelectInput>

        {(users.length === 0 || search.length < 3) && (
          <Flex column css={margin.top('large')}>
            <Heading level={3} looksLikeLevel={4}>
              {`Enter a search query to find users, min 3 characters`}
            </Heading>
          </Flex>
        )}

        {users.length === 0 && search.length >= 3 && (
          <Flex column css={margin.top('large')}>
            <Heading level={3} looksLikeLevel={4}>
              {`No users found for query: "${search}"`}
            </Heading>
          </Flex>
        )}

        {users.length > 0 && (
          <Flex column css={margin.top('large')}>
            <Heading level={3} looksLikeLevel={4}>
              {`Found ${filteredUserCount} users`}
            </Heading>
          </Flex>
        )}
      </div>

      <ul css={margin.top()}>
        {loading && <Loader />}
        {users
          .filter((u) => !adminOnly || u.isAdmin)
          .map((user) => (
            <UserElement key={user.id} user={user} />
          ))}
      </ul>

      {users.length === pageSize && (
        <Flex horizontal="center">
          <PrimaryButton
            onClick={() => setPage((page) => page + 1)}
            css={margin.top('large')}
          >
            {'Next page'}
          </PrimaryButton>
        </Flex>
      )}
    </Flex>
  )
}

export const UserElement: FC<{ user: AdminPageUser }> = ({ user }) => {
  const formatDate = useFormatDate()

  return (
    <Link
      to={`/profile/${user.slug}`}
      css={css`
        text-decoration: none;
      `}
    >
      <Flex
        horizontal="space-between"
        css={css`
          ${margin.top('small')}
          padding: 8px;
          border-radius: 8px;
          background-color: ${colors.green100};
          box-shadow: ${boxShadows.default};
        `}
        gap="small"
      >
        <Flex vertical="center" gap="medium">
          <UserAvatar avatarData={user.avatarData} height={40} />
          <Flex column gap="medium">
            <Flex vertical="center" gap="small" wrap>
              <Text css={[lineClamp(1)]}>{user.displayName ?? '-'}</Text>
              {user.displayName !== user.username && (
                <Text size="small" color={colors.grey400} css={[lineClamp(1)]}>
                  {user.username ?? '-'}
                </Text>
              )}
            </Flex>
            <Text size="small" css={[lineClamp(1)]}>
              {user.email ?? '-'}
            </Text>
            <Text dimmed size="tiny" css={[margin.top('tiny')]}>
              {user.id} (
              {formatDate(new Date(user.createdAt), 'short-datetime')})
            </Text>
          </Flex>
        </Flex>
        <Flex vertical="center" gap="large">
          {user.locale && <Text size="small">{user.locale}</Text>}
          {user.isAdmin && <FaCrown size={32} />}
        </Flex>
      </Flex>
    </Link>
  )
}

const RemoveProfilePictureModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  const [userId, setUserId] = useState('')

  const [mutation, { error }] = useMutation<{}, { userId: string }>(gql`
    mutation removeProfilePicture($userId: ID!) {
      removeProfilePicture(userId: $userId) {
        id
        profilePictureUrl
      }
    }
  `)

  const onSubmit = async () => {
    mutation({ variables: { userId } }).then(() => closeModal())
  }

  return (
    <Modal heading="Delete profile picture" closeModal={closeModal}>
      <Text>
        Enter the user id of the player you want to remove the profile picture.
      </Text>
      <Spacer height="medium" />
      <TextInput
        label="User id"
        value={userId}
        onValue={(value) => setUserId(value)}
      />
      <Spacer height="large" />
      <Flex horizontal="flex-end">
        <PrimaryButton disabled={!userId} onClick={onSubmit}>
          Remove
        </PrimaryButton>
      </Flex>
      {error && (
        <Flex horizontal="flex-end">
          <Text>Something went wrong</Text>
        </Flex>
      )}
    </Modal>
  )
}

export const AdminUsersStats = () => {
  const { data, loading } = useLocaleCountQuery()

  if (loading) {
    return <Loader center />
  }

  const localeCount = data?.localeCountQuery ?? []

  const totalUserCount = localeCount.reduce((acc, { count }) => acc + count, 0)

  return (
    <Flex column css={margin.top()} gap="large">
      <Heading level={3} looksLikeLevel={4}>
        {`Total users: ${totalUserCount}`}
      </Heading>

      <ul>
        {localeCount.map(({ locale, count }, index) => (
          <li key={locale}>
            <Flex
              horizontal="space-between"
              css={css`
                padding: 8px;
                background-color: ${index % 2 === 0
                  ? colors.green100
                  : colors.green200};

                border-radius: ${index === 0
                  ? '8px 8px 0 0'
                  : index === localeCount.length - 1
                  ? '0 0 8px 8px'
                  : '0'};
              `}
            >
              <Text>{langShortToLang[locale as Locale]}</Text>
              <Text>{count}</Text>
            </Flex>
          </li>
        ))}
      </ul>
    </Flex>
  )
}

export const AdminUsersPage = () => {
  const t = useT()

  const [selectedTab, setSelectedTab] = useState<'search' | 'stats'>('search')

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="index" />

          <Spacer height="large" />
          <Flex gap="medium">
            <ChallengeNavItem
              title="Search"
              selected={selectedTab === 'search'}
              onClick={() => setSelectedTab('search')}
            />
            <ChallengeNavItem
              title="Stats"
              selected={selectedTab === 'stats'}
              onClick={() => setSelectedTab('stats')}
            />
          </Flex>

          {selectedTab === 'search' && <AdminUserSearch />}
          {selectedTab === 'stats' && <AdminUsersStats />}
        </PageContent>
      </AdminAccessControlComponent>
      <Footer />
    </Layout>
  )
}
