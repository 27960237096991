/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link, useSearchParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { Heading } from '../../components/Heading'
import { Logo, SmallTrophy } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { ClubLogo } from '../../components/Picture'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { LeagueQuizStatus } from '../../lib/types'
import { margin } from '../../styles/margin'
import { boxShadows, colors } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import { getIconAndTitle } from '../../utils/helperFunctions'
import { leagueFrequencyToString } from '../League/LeaguePage'
import { HomePageLeague, LeagueDisplayType } from './queries'
import { RemainingTime } from './RemainingTime'

const NotificationCircle = ({ number }: { number: number }) => {
  return (
    <Flex
      vertical="center"
      horizontal="center"
      css={css`
        position: absolute;
        top: -12px;
        right: -3px;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        background-color: ${colors.red400};
      `}
    >
      <Text
        size="small"
        color="white"
        css={css`
          margin-bottom: 4px;
        `}
      >
        {number}
      </Text>
    </Flex>
  )
}

const showDeadline = (date: Date) => {
  const today = new Date()
  const todayPlus3Month = new Date(today.setMonth(today.getMonth() + 3))
  return date > today && date < todayPlus3Month
}

export const LeagueItem = ({
  league,
  className,
  display,
  wide,
}: {
  league: HomePageLeague
  className?: string
  display?: LeagueDisplayType
  wide?: boolean
}) => {
  const t = useT()
  const formatDate = useFormatDate()
  const [searchParams] = useSearchParams()
  const club = searchParams.get('club') as string
  const quizzesViewerCanStart = league.quizzes.filter((quiz) => {
    return (
      quiz.viewerCanStartQuiz || Boolean(quiz.viewerData.activeQuizInstance?.id)
    )
  })
  const viewerCanStartQuiz = quizzesViewerCanStart.length > 0
  const nextUpcomingQuizAvailableFrom = league.quizzes.filter(
    (quiz) => quiz.status === LeagueQuizStatus.upcoming
  )[0]?.availableFrom
  const { title } =
    display === 'latestQuiz'
      ? getIconAndTitle(quizzesViewerCanStart[0]?.title ?? league.title)
      : getIconAndTitle(league.title)

  const ownerClub = league.ownerClub

  return (
    <Link
      to={`/series/${league.slug + (club ? `?club=${club}` : '')}`}
      css={[
        css`
          min-width: 220px;
          max-width: 220px;
          min-height: 250px;
          text-decoration: none;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          ${wide &&
          css`
            min-width: 220px;
            max-width: 380px;
            width: 100%;
          `}
        `,
      ]}
      className={className}
    >
      <Flex
        column
        vertical="space-between"
        css={[
          css`
            flex: 1;
            border-radius: 2px;
            box-shadow: ${boxShadows.default2};
            position: relative;
          `,
          league.leagueCardImageUrl
            ? css`
                background: linear-gradient(
                    rgba(0, 0, 0, 0),
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.5),
                    black,
                    black
                  ),
                  url(${league.leagueCardImageUrl});
                background-size: cover;
                background-position: center center;
              `
            : css`
                background-color: white;
              `,
        ]}
      >
        {viewerCanStartQuiz && (
          <NotificationCircle number={quizzesViewerCanStart.length} />
        )}

        {league.leagueCardImageUrl ? (
          <div />
        ) : (
          <Flex
            column
            css={css`
              padding: 6px 15px;
            `}
          >
            <Flex
              css={css`
                justify-content: space-between;
                align-items: center;
                margin: 10px 0 20px 0;
              `}
            >
              {ownerClub && ownerClub.logoUrl ? (
                <ClubLogo
                  url={ownerClub.logoUrl}
                  size={40}
                  shape={ownerClub.logoShape}
                />
              ) : (
                <Logo size={40} />
              )}
              {league.frequency && (
                <Flex css={margin.top('small')}>
                  <Chip
                    label={leagueFrequencyToString(t, league.frequency)}
                    backgroundColor={colors.grey100}
                    style={css`
                      height: 30px;
                    `}
                    color="black"
                  />
                </Flex>
              )}
            </Flex>

            <Heading
              level={3}
              looksLikeLevel={5}
              css={css`
                font-size: 20px;
              `}
            >
              {title}
            </Heading>
          </Flex>
        )}

        <div>
          <Flex
            vertical="flex-end"
            horizontal="space-between"
            css={[
              css`
                padding: 0 15px;
              `,
              !league.leagueCardImageUrl &&
                !viewerCanStartQuiz &&
                css`
                  > * {
                    opacity: 0.5;
                  }
                `,
            ]}
          >
            <Flex column>
              {league.leagueCardImageUrl && (
                <Heading
                  level={3}
                  wide
                  color="white"
                  css={css`
                    font-size: 21px;
                    font-weight: 800;
                  `}
                >
                  {title}
                </Heading>
              )}
              {league.viewerLeaderboardEntry?.rank ? (
                <Flex vertical="center" gap="small">
                  <SmallTrophy color="black" />
                  <Text
                    size="small"
                    color={league.leagueCardImageUrl ? 'white' : 'black'}
                  >
                    {league.viewerLeaderboardEntry.rank}/
                    {league.participantCount}
                  </Text>
                </Flex>
              ) : (
                <Text
                  size="small"
                  color={league.leagueCardImageUrl ? 'white' : 'black'}
                >
                  {t('{{count}} player', {
                    count: league.participantCount,
                  })}
                </Text>
              )}

              {viewerCanStartQuiz ? (
                showDeadline(new Date(league.availableTo)) && (
                  <Text
                    size="small"
                    color={league.leagueCardImageUrl ? 'white' : 'black'}
                  >
                    {t('Deadline {{date}}', {
                      date: formatDate(
                        new Date(league.availableTo),
                        'short-datetime'
                      ),
                    })}
                  </Text>
                )
              ) : nextUpcomingQuizAvailableFrom ? (
                <RemainingTime
                  toDate={nextUpcomingQuizAvailableFrom}
                  color={league.leagueCardImageUrl ? 'white' : 'black'}
                />
              ) : null}
            </Flex>
          </Flex>

          <Flex
            horizontal="center"
            css={css`
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              padding: 15px 0;
            `}
          >
            <PrimaryButton
              css={css`
                width: calc(100% - 30px);
                height: 34px;
                span {
                  font-size: 18px;
                }
              `}
              variant={league.leagueCardImageUrl ? 'white' : 'black'}
              size="small"
              disabled={!viewerCanStartQuiz}
            >
              {viewerCanStartQuiz ? t('Play quiz') : t('No active quizzes')}
            </PrimaryButton>
          </Flex>
        </div>
      </Flex>
    </Link>
  )
}
