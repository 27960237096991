/** @jsxImportSource @emotion/react */

import { Flex } from './layout/Flex'
import { Fragment, useMemo } from 'react'
import { margin, Spacer } from '../styles/margin'
import { PageContent } from './layout/PageContent'
import { Text } from './Text'
import { LeaguesContainer } from '../views/Home/LeaguesContainer'
import { LeagueItem } from '../views/Home/LeagueItem'
import { filterDaily } from '../utils/util'
import { sortLeagues } from '../views/Home/HomePage'
import { useViewerSubscribedLeagues } from '../views/Home/queries'
import { useT } from '../lib/i18n/useT'
import { ShimmerComponent } from '../views/Home/ShimmerComponent'
import { css } from '@emotion/react'

export const MyActiveSeriesComponent = () => {
  const t = useT()
  const viewerSubscribedLeagues = useViewerSubscribedLeagues()
  const subscribedLeaguesLoading = viewerSubscribedLeagues.loading
  const subscribedLeagues = useMemo(() => {
    const data = viewerSubscribedLeagues.data
    if (!data) {
      return []
    }
    return sortLeagues(filterDaily(data.subscribedLeagues ?? []))
  }, [viewerSubscribedLeagues.data?.subscribedLeagues])
  return (
    <Flex column>
      {subscribedLeaguesLoading && (
        <>
          <Spacer height="large" />
          <LeaguesContainer>
            <ShimmerComponent
              css={css`
                min-width: 220px;
                max-width: 220px;
              `}
            />
            <ShimmerComponent
              css={css`
                min-width: 220px;
                max-width: 220px;
              `}
            />
            <ShimmerComponent
              css={css`
                min-width: 220px;
                max-width: 220px;
              `}
            />
            <ShimmerComponent
              css={css`
                min-width: 220px;
                max-width: 220px;
              `}
            />
            <ShimmerComponent
              css={css`
                min-width: 220px;
                max-width: 220px;
              `}
            />
          </LeaguesContainer>
        </>
      )}
      {subscribedLeagues.length !== 0 && (
        <Fragment>
          <Spacer height={'large'} />
          <PageContent>
            <Flex horizontal="space-between">
              <Text color="white" size="small">
                {t('My active series')}
              </Text>

              <Text color="white" size="small">
                ({subscribedLeagues.length})
              </Text>
            </Flex>
          </PageContent>
          <Spacer height="small" />
          {subscribedLeagues.length > 0 ? (
            <LeaguesContainer>
              {subscribedLeagues.map((league) => (
                <LeagueItem key={league.id} league={league} />
              ))}
            </LeaguesContainer>
          ) : (
            <PageContent css={margin.bottom()}>
              <Text color="white">{t('Nothing right now')}</Text>
            </PageContent>
          )}
        </Fragment>
      )}
    </Flex>
  )
}
