/** @jsxImportSource @emotion/react */

import { gql, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import { Spacer } from '../../../styles/margin'
import { LiveQuizHostInstance } from '../queries'
import { HostModeHeader } from './HostModeHeader'

const getLiveQuizHostInstanceQuery = gql`
  query getLiveQuizHostInstance($id: ID!) {
    getLiveQuizHostInstance(id: $id) {
      id
      participantCount
    }
  }
`

export const useGetLiveQuizHostInstanceParticipantsQuery = (id: string) => {
  return useQuery<{ getLiveQuizHostInstance: LiveQuizHostInstance }>(
    getLiveQuizHostInstanceQuery,
    { variables: { id }, fetchPolicy: 'cache-and-network' }
  )
}

export const JoinLiveQuiz = ({
  instance,
  nextQuestion,
}: {
  instance: LiveQuizHostInstance
  nextQuestion: () => void
}) => {
  const participantsQuery = useGetLiveQuizHostInstanceParticipantsQuery(
    instance.id
  )

  useEffect(() => {
    participantsQuery.startPolling(2000)

    return () => {
      participantsQuery.stopPolling()
    }
  }, [])

  const participantCount =
    participantsQuery.data?.getLiveQuizHostInstance.participantCount || 0

  return (
    <Flex column grow>
      <PageContent horizontal="center">
        <HostModeHeader />
        <TextNew
          italic
          condensed
          textAlign="center"
          css={[
            css`
              font-size: 45px;
              font-weight: 500;
              line-height: 53px;
            `,
          ]}
        >
          {' '}
          Wait for participants
        </TextNew>
        <TextNew> {participantCount} participants</TextNew>
        <Spacer height={100} />
        <TextNew
          italic
          condensed
          textAlign="center"
          css={[
            css`
              font-size: 30px;
              font-weight: 500;
              line-height: 1;
            `,
          ]}
        >
          When everyone is ready, start the quiz
        </TextNew>
        <Spacer height={'large'} />
        <PrimaryButton
          css={css`
            width: 100%;
          `}
          onClick={nextQuestion}
        >
          Start Quiz
        </PrimaryButton>
      </PageContent>
    </Flex>
  )
}
