import { useEffect } from 'react'
import { useRefValue } from './useRefValue'

export function useTimeout(callback: () => void, delayMs: number | null) {
  const savedCallback = useRefValue(callback)

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delayMs !== null && delayMs !== Infinity) {
      const id = setTimeout(tick, delayMs)
      return () => clearTimeout(id)
    }
  }, [delayMs, savedCallback])
}
