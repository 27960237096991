/**
 * Important!
 * Keep this file in sync with its counterpart in the fcquiz.api and fcquiz.web repos
 */

const hairMouthColors = [
  '#202020',
  '#7A3300',
  '#CF662A',
  '#D29956',
  '#E36D89',
  '#6DD5E3',
  '#F5F5EF',
]

const browsEyesColors = hairMouthColors.map((it) =>
  darkenSaturateHexColor(it, 30, 30)
)

const skinColors = [
  '#FEDCB3',
  '#F5BFA1',
  '#D8A66D',
  '#CD8F55',
  '#B97552',
  '#804220',
  '#63331F',
]

export const facePartToColorsLegacy = {
  Skin: skinColors,
  Hair: hairMouthColors,
  Brows: browsEyesColors,
  Eyes: browsEyesColors,
  Mouth: hairMouthColors,
}

export const shirtColorsLegacy = [
  '#ffffff', //White
  '#202020', //Black/Grey
  '#930A02', //Red
  '#C63C34', //Red
  '#F38077', //Red
  '#FFB8A9', //Red
  '#7A3300', //Orange
  '#EE7836', //Orange
  '#FF9B53', //Orange
  '#FFD4AC', //Orange
  '#E0A100', //Yellow
  '#FECB17', //Yellow
  '#FFE456', //Yellow
  '#FFF2B0', //Yellow
  '#034B13', //Green
  '#1EBE41', //Green
  '#85F49E', //Green
  '#C6FFC7', //Green
  '#003ACF', //Blue
  '#3A6DF2', //Blue
  '#7A9FFF', //Blue
  '#C7D6FF', //Blue
  '#991D79', //Pink
  '#B73E97', //Pink
  '#FFB7EC', //Pink
  '#FFDDF6', //Pink
  '#4C007A', //Purple
  '#8C45E7', //Purple
  '#B693E3', //Purple
  '#DDC1FF', //Purple
]

export const skinLegacy = ['SkinA']

export const hairsLegacy = [
  'Hair0',
  'HairA',
  'HairB',
  'HairC',
  'HairD',
  'HairE',
  'HairF',
  'HairG',
  'HairH',
  'HairI',
  'HairJ',
  'HairK',
  'HairL',
  'HairM',
  'HairN',
  'HairO',
  'HairP',
  'HairQ',
  'HairR',
  'HairS',
  'HairT',
  'HairU',
  'HairV',
  'HairW',
  'HairX',
  'HairY',
  'HairZ',
  'Hair1',
  'Hair2',
  'Hair3',
  'Hair4',
  'Hair5',
]

export const browsLegacy = [
  'BrowsB',
  'BrowsC',
  'BrowsD',
  'BrowsE',
  'BrowsF',
  'BrowsA',
  'BrowsG',
  'BrowsH',
  'BrowsI',
  'BrowsJ',
]

export const eyesLegacy = [
  'EyesA',
  'EyesB',
  'EyesC',
  'EyesF',
  'EyesD',
  'EyesE',
  'EyesG',
  'EyesH',
]

export const mouthLegacy = [
  'MouthA',
  'MouthB',
  'MouthC',
  'MouthD',
  'MouthE',
  'MouthF',
  'MouthG',
  'MouthH',
  'MouthI',
  'MouthJ',
  'MouthK',
  'MouthL',
  'MouthM',
  'MouthO',
  'MouthP',
  'MouthQ',
]

export const collarLegacy = [
  'Collar0',
  'CollarA',
  'CollarB',
  'CollarC',
  'CollarD',
  'CollarE',
  'CollarF',
  'CollarH',
]

export const armsLegacy = [
  'Arms0',
  'ArmsA',
  'ArmsB',
  'ArmsC',
  'ArmsD',
  'ArmsE',
  'ArmsF',
  'ArmsG',
  'ArmsH',
  'ArmsI',
  'ArmsJ',
  'ArmsK',
  'ArmsL',
]

export const chestLegacy = [
  'ChestA',
  'ChestB',
  'ChestC',
  'ChestD',
  'ChestE',
  'ChestF',
  'ChestG',
  'ChestH',
  'ChestI',
  'ChestJ',
  'ChestK',
  'ChestL',
  'ChestM',
  'ChestN',
  'ChestO',
]

export const shouldersLegacy = [
  'Shoulders0',
  'ShouldersA',
  'ShouldersC',
  'ShouldersD',
  'ShouldersE',
  'ShouldersF',
  'ShouldersG',
]

const sleevesLegacy = [
  'Sleeves0',
  'SleevesA',
  'SleevesC',
  'SleevesD',
  'SleevesE',
  'SleevesF',
]

export const partToStylesLegacy = {
  Skin: skinLegacy,
  Hair: hairsLegacy,
  Brows: browsLegacy,
  Eyes: eyesLegacy,
  Mouth: mouthLegacy,
  Arms: armsLegacy,
  Chest: chestLegacy,
  Collar: collarLegacy,
  Shoulders: shouldersLegacy,
  Sleeves: sleevesLegacy,
}

//-----
// SATURATE COLOR START
//-----
function hexToRgb(hex: string): [number, number, number] {
  let r = 0,
    g = 0,
    b = 0
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return [r, g, b]
}

function rgbToHsl(r: number, g: number, b: number): [number, number, number] {
  ;(r /= 255), (g /= 255), (b /= 255)
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h: number = (max + min) / 2
  let s: number = (max + min) / 2
  const l: number = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  return [h, s, l]
}

function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let r: number, g: number, b: number

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (x: number) => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export function saturateHexColor(hex: string, percentage: number): string {
  let [r, g, b] = hexToRgb(hex)
  const [h, _s, l] = rgbToHsl(r, g, b)
  let s = _s * (1 + percentage / 100)
  s = Math.min(1, Math.max(0, s)) // Ensure s is between 0 and 1
  ;[r, g, b] = hslToRgb(h, s, l)
  return rgbToHex(r, g, b)
}
//-----
// SATURATE COLOR END
//-----

export function darkenHexColor(hex: string, darkenPercent: number): string {
  // Ensure the darken percentage is between 0 and 100
  darkenPercent = Math.max(0, Math.min(100, darkenPercent))

  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)

  // Calculate the darkening
  r = Math.round((r * (100 - darkenPercent)) / 100)
  g = Math.round((g * (100 - darkenPercent)) / 100)
  b = Math.round((b * (100 - darkenPercent)) / 100)

  // Convert back to hex and return
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}

export function darkenSaturateHexColor(
  hex: string,
  darkenPercent: number,
  saturatePercent: number
): string {
  const saturated = saturateHexColor(hex, saturatePercent)
  return darkenHexColor(saturated, darkenPercent)
}
