/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FcQuizLogo } from '../icons'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'
import { Timer } from './Timer'

export const QuizStatusComponent = ({
  currentQuestion,
  totalQuestions,
  textColor = 'black',
  seconds,
  points,
}: {
  currentQuestion: number
  totalQuestions: number
  textColor?: string
  seconds?: number
  points?: number
}) => {
  const hasPoints = points !== undefined
  return (
    <Flex
      vertical="flex-end"
      css={css`
        width: 100%;
      `}
    >
      <TextNew
        color={textColor}
        size="small"
        strong
        css={css`
          flex: 1;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        {currentQuestion}/{totalQuestions}
      </TextNew>
      <FcQuizLogo
        color={textColor}
        css={css`
          margin-top: 6px;
          flex: 1;
          justify-content: center;
          align-items: center;
        `}
      />

      {!hasPoints && (
        <Timer
          css={css`
            flex: 1;
            align-items: center;
            justify-content: flex-end;
          `}
          seconds={seconds}
          color={textColor}
        />
      )}

      {hasPoints && (
        <TextNew
          textAlign="right"
          color={textColor}
          size="small"
          strong
          css={css`
            flex: 1;
            align-items: center;
            justify-content: flex-end;
          `}
        >
          {`${points}p`}
        </TextNew>
      )}
    </Flex>
  )
}
