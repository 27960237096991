// query checkClubMembershipBadges: [Badge!]!

import { gql, useQuery } from '@apollo/client'
import { badgeFragment } from '../../views/LeagueQuiz/queries'
import { Badge } from '../../views/League/queries'

export const checkClubMembershipBadges = gql`
  query checkClubMembershipBadges {
    checkClubMembershipBadges {
      ...BadgeFragment
    }
  }
  ${badgeFragment}
`

export const useCheckClubMembershipBadgesQuery = () => {
  return useQuery<{ checkClubMembershipBadges: Badge[] }>(
    checkClubMembershipBadges
  )
}
