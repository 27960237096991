import { gql, useQuery } from '@apollo/client'
import { User } from '../Profile/queries'
import { Club } from '../Clubs/queries'
import { HomePageLeague, homePageLeagueFragment } from '../Home/queries'
import { FeedLeagueQuiz } from '../Feed/queries'

export type SearchResult = {
  leagues: HomePageLeague[]
  quizzes: FeedLeagueQuiz[]
  clubs: Club[]
  users: User[]
}

export const LeagueQuizFragment = gql`
  fragment LeagueQuizFragment on LeagueQuiz {
    id
    title
    availableFrom
    availableTo
    status
    viewerCanStartQuiz
    viewerData {
      id
      canStartQuiz
      hasCompletedQuiz
      activeQuizInstance {
        id
      }
    }
    league {
      id
      slug
      title
      status
      availableFrom
      availableTo
    }
  }
`

export const librarySearchQuery = gql`
  query librarySearch($query: String!) {
    librarySearch(query: $query) {
      leagues {
        ...HomePageLeagueFragment
      }
      quizzes {
        id
        title
        availableFrom
        availableTo
        viewerCanStartQuiz
        questionsCount
        viewerCanQuickMatch
        participantCount
        quizType
        league {
          id
          slug
          title
          status
          availableFrom
          availableTo
        }
        viewerData {
          id
          canStartQuiz
          hasCompletedQuiz
          activeQuizInstance {
            id
          }
        }
      }
      clubs {
        id
        slug
        name
        description
      }
      users {
        id
        username
        displayName
        avatarData
        slug
        mainClubMembership {
          id
          club {
            id
            slug
            name
            logoUrl
            logoShape
          }
        }
        friendStatus
      }
    }
  }
  ${homePageLeagueFragment}
`

export const useLibrarySearchQuery = (query: string) => {
  return useQuery<{ librarySearch: SearchResult }>(librarySearchQuery, {
    variables: { query },
  })
}
