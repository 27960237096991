import { gql, useMutation } from '@apollo/client'
import { default as amplitude } from 'amplitude-js'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { isDevelopment } from '../config/config'
import { apolloClient } from './apollo/apollo'
import { Locale } from './i18n/locale'
import { hasReactNativeWebViewPostMessage } from './native/NativeAppContext'

export enum AnalyticsEventType {
  AdCampaignClicked = 'Ad campaign clicked',
  AdCampaignExpanded = 'Ad campaign expanded',
  AdCampaignShown = 'Ad campaign shown',
  AdCampaignFinishedShown = 'Ad campaign finished shown',
  AdCompletedVlyby = 'Ad completed | Vlyby',
  AppDownloadbuttonClick = 'App downloadbutton cick',
  BadgeEarned = 'Badge earned',
  ChallengeAccepted = 'Challenge | Challenge accepted',
  ChallengeGroupCreated = 'Challenge | Group created',
  ClubJoined = 'Club joined',
  DirectEmbedCodeCopied = 'Direct embed code copied',
  EmbedError = 'Embed error',
  EmbedFinishedQuiz = 'Embed Finished Quiz',
  EmbedLandingpage = 'Embed landingpage',
  EmbedPlayMoreInEmbedQuiz = 'Embed Play More in-embed quiz',
  FriendRequest = 'Friend request',
  LeagueJoined = 'League joined',
  LeaguePage = 'League page',
  LiveQuizJoined = 'Live quiz joined',
  JoinDateRangeChallenge = 'Join date range challenge',
  PageViewHome = 'Page view | Home',
  PageViewLibrary = 'Page view | Library',
  PlayMoreQuiz = 'Play More Quiz',
  QuickMatchStarted = 'Quick match started',
  QuizCompleted = 'Quiz completed',
  QRCode = 'QR code',
  QuizLandingPage = 'Quiz landing page',
  RandomAvatarGenerated = 'Generate Random Avatar',
  Refferer = 'Refferer',
  RevIqAdImpression = 'RevIq Ad impression',
  ShareLeagueClicked = 'Share league clicked',
  StartQuiz = 'Start quiz',
  UserAvatar = 'User Avatar',
  UserDeleteRequest = 'Delete request',
  UserRegistered = 'User registered',
}

// Some events need to be tracked in db
// This is because not all events can be tracked in amplitude, due to cookie concent
export const eventsToTrackInDb = [
  AnalyticsEventType.AdCampaignClicked,
  AnalyticsEventType.AdCampaignShown,
  AnalyticsEventType.AdCampaignFinishedShown,
  AnalyticsEventType.AdCampaignExpanded,
  AnalyticsEventType.ClubJoined,
  AnalyticsEventType.EmbedLandingpage,
  AnalyticsEventType.FriendRequest,
  AnalyticsEventType.QuizCompleted,
  AnalyticsEventType.RevIqAdImpression,
  AnalyticsEventType.StartQuiz,
  AnalyticsEventType.UserRegistered,
  AnalyticsEventType.JoinDateRangeChallenge,
]

let amplitudeInited = false
// Bold.dk are very strict with cookies, therefore we wont track events from them
const boldEuroSubscriptionId = '10002049'

const isNativeApp = hasReactNativeWebViewPostMessage()

export const trackFcEvent = async (eventType: string, eventData?: object) => {
  const client = apolloClient
  if (!client) {
    return
  }

  const eventDataString = eventData
    ? JSON.stringify({ ...eventData, isNativeApp })
    : '{}'

  await client.mutate({
    mutation: addFcAnalyticsMutation,
    variables: {
      input: {
        eventType,
        eventData: eventDataString,
      },
    },
  })
}

function getUserId() {
  try {
    let userId = window.localStorage.getItem('amplitudeId')
    if (!userId) {
      userId = uuidv4()
      window.localStorage.setItem('amplitudeId', userId)
    }
    return userId
  } catch (_) {
    return null
  }
}

function shouldTrack() {
  if (window.location.host !== 'fcquiz.app') {
    return false
  }

  const hasStatisticsConcent = window.Cookiebot?.consent?.statistics === true
  if (!hasStatisticsConcent) {
    return false
  }

  // don't track embeds for Bold.dk
  if (window.location.pathname.includes(boldEuroSubscriptionId)) {
    return false
  }

  return true
}

function init() {
  if (!shouldTrack()) {
    return
  }

  amplitude.getInstance().init('52f501cf9ea66410f8f16873ebce6c72')
  amplitude.getInstance().setUserId(getUserId())
  amplitude.getInstance().setUserProperties({ isNativeApp })

  amplitudeInited = true
}

export function trackEvent(name: AnalyticsEventType, data?: object) {
  try {
    if (eventsToTrackInDb.includes(name)) {
      trackFcEvent(name, data)
    }

    if (!shouldTrack()) {
      if (isDevelopment) {
        console.log('Not tracking event', name, data)
      }
      return
    }

    if (!amplitudeInited) {
      init()
    }

    amplitude.getInstance().logEvent(name, data)
  } catch (error) {
    console.error('trackEvent error', error)
  }
}

export function useTrackEvent(name: AnalyticsEventType, data?: object) {
  useEffect(() => {
    trackEvent(name, data)
  }, [name, JSON.stringify(data)]) // eslint-disable-line react-hooks/exhaustive-deps
}

export const analytics = {
  trackEvent,
  useTrackEvent,
  setIsAuthenticated: (isAuthenticated: boolean) => {
    if (!shouldTrack()) return

    if (!amplitudeInited) {
      init()
    }

    amplitude.getInstance().setUserProperties({ isAuthenticated })
  },
  setIsAnonymous: (isAnonymous: boolean) => {
    if (!shouldTrack()) return

    if (!amplitudeInited) {
      init()
    }

    amplitude.getInstance().setUserProperties({ isAnonymous })
  },
  setLocale: (locale: Locale) => {
    if (!shouldTrack()) return

    if (!amplitudeInited) {
      init()
    }

    amplitude.getInstance().setUserProperties({ locale })
  },
}

type AddEventInput = {
  eventType: string
  eventData: string
}

export const addFcAnalyticsMutation = gql`
  mutation addEvent($input: AddEventInput!) {
    addEvent(input: $input)
  }
`

export const useTrackFcEventMutation = () => {
  return useMutation<{ addEvent: Boolean }, { input: AddEventInput }>(
    addFcAnalyticsMutation
  )
}
