/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

export type MaskShape = 'circle' | 'shield' | 'uncropped'

export function getMaskCss(shape?: MaskShape) {
  switch (shape) {
    case 'shield':
      return css`
        mask-image: url('/shape-shield.svg');
        mask-size: 100%;
        mask-repeat: no-repeat;
      `
    case 'circle':
      return css`
        mask-image: url('/shape-circle.svg');
        mask-size: 100%;
        mask-repeat: no-repeat;
      `
  }
}
