/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

export type MarginSize = 'tiny' | 'small' | 'medium' | 'large' | 'huge'

export type Margin = number | MarginSize | 'auto'

const margins: Record<MarginSize, string> = {
  tiny: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  huge: '50px',
}

export const getMargin = (margin?: Margin) => {
  if (typeof margin === 'undefined') {
    return margins.medium
  } else if (typeof margin === 'number') {
    return `${margin}px`
  } else if (margin === 'auto') {
    return 'auto'
  } else {
    return margins[margin]
  }
}

export const gap = (gap?: Margin) => css`
  gap: ${getMargin(gap)};
`

export const margin = {
  top: (margin?: Margin) => css`
    margin-top: ${getMargin(margin)};
  `,
  bottom: (margin?: Margin) => css`
    margin-bottom: ${getMargin(margin)};
  `,
  left: (margin?: Margin) => css`
    margin-left: ${getMargin(margin)};
  `,
  right: (margin?: Margin) => css`
    margin-right: ${getMargin(margin)};
  `,
  horizontal: (margin?: Margin, marginRight?: Margin) => css`
    margin-left: ${getMargin(margin)};
    margin-right: ${getMargin(marginRight ?? margin)};
  `,
  vertical: (margin?: Margin, marginBottom?: Margin) => css`
    margin-top: ${getMargin(margin)};
    margin-bottom: ${getMargin(marginBottom ?? margin)};
  `,
  all: (
    margin?: Margin,
    marginRight?: Margin,
    marginBottom?: Margin,
    marginLeft?: Margin
  ) => css`
    margin-top: ${getMargin(margin)};
    margin-right: ${getMargin(marginRight ?? margin)};
    margin-bottom: ${getMargin(marginBottom ?? margin)};
    margin-left: ${getMargin(marginLeft ?? margin)};
  `,
}

export const Spacer = ({
  width,
  height,
  className,
}: {
  width?: number | MarginSize
  height?: number | MarginSize
  className?: string
}) => {
  return (
    <div
      css={[
        width &&
          css`
            width: ${getMargin(width)};
          `,
        height &&
          css`
            height: ${getMargin(height)};
          `,
      ]}
      className={className}
    />
  )
}
