/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { ComponentPropsWithRef, forwardRef } from 'react'
import {
  textCondensedLegacy,
  textExtraCondensedLegacy,
  textStyleLegacy,
  textUppercaseLegacy,
  textWideLegacy,
} from '../styles/styles'
import theme, { colors } from '../styles/theme'

export type TextSize =
  | 'massive'
  | 'huge'
  | 'large'
  | 'medium'
  | 'small'
  | 'tiny'

export const styleBySizeNumber: { [key in TextSize]: number } = {
  massive: 30,
  huge: 22,
  large: 20,
  medium: 18,
  small: 15,
  tiny: 13,
}

export const styleBySize: { [key in TextSize]: SerializedStyles } = {
  massive: css`
    font-size: 30px;
  `,
  huge: css`
    font-size: 22px;
  `,
  large: css`
    font-size: 20px;
  `,
  medium: css`
    font-size: 18px;
  `,
  small: css`
    font-size: 15px;
  `,
  tiny: css`
    font-size: 13px;
  `,
}

export type TextProps = {
  size?: TextSize
  color?: string
  strong?: boolean
  dimmed?: boolean
  textAlign?: 'left' | 'center' | 'right'
  condensed?: boolean
  extraCondensed?: boolean
  wide?: boolean
  underline?: boolean
  autoHeight?: boolean
  uppercase?: boolean
  italic?: boolean
  outline?: boolean
  outlineColor?: string
} & ComponentPropsWithRef<'span'>

export const Text = forwardRef<HTMLSpanElement, TextProps>(function Text(
  {
    size = 'medium',
    strong,
    dimmed,
    color,
    textAlign,
    condensed,
    extraCondensed,
    wide,
    underline,
    className,
    uppercase,
    italic,
    outline,
    outlineColor = colors.black,
    ...rest
  },
  ref
) {
  return (
    <span
      ref={ref}
      css={[
        textStyleLegacy,
        condensed && textCondensedLegacy,
        extraCondensed && textExtraCondensedLegacy,
        wide && textWideLegacy,
        uppercase && textUppercaseLegacy,
        styleBySize[size],
        css`
          line-height: 1.4;
          color: ${color ?? (dimmed ? theme.colors.grey300 : colors.black)};
        `,
        strong
          ? css`
              font-weight: 600;
            `
          : css`
              font-weight: 500;
            `,
        textAlign &&
          css`
            text-align: ${textAlign};
          `,
        underline &&
          css`
            text-decoration: underline;
          `,
        italic &&
          css`
            font-style: italic;
          `,
        // experimental, might not work in all browsers
        outline &&
          css`
            text-shadow: -1px -1px 0 ${outlineColor}, 1px -1px 0 ${outlineColor},
              -1px 1px 0 ${outlineColor}, 1px 1px 0 ${outlineColor};
          `,
      ]}
      className={className}
      {...rest}
    />
  )
})
