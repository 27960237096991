/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Fragment, useEffect } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { Text } from '../../../components/Text'
import { AddFriendsButtonText } from '../../../components/friends/AddFriends'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useLeagueQuizLeaderboard } from '../queries'
import { LeaderboardItemComponentV2 } from './LeaderBoardItemV2'

export const LeagueQuizLeaderBoardV2 = ({
  leagueQuizId,
  clubId,
  selectedTab = 'users',
}: {
  leagueQuizId: string
  clubId?: string
  selectedTab?: 'users' | 'clubs' | 'friends'
}) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const {
    leaderboard,
    viewerLeaderboardEntry,
    clubsLeaderboard,
    friendsLeaderboard,
    loading,
    hasMore,
    fetchMore,
    refetch,
  } = useLeagueQuizLeaderboard(leagueQuizId, clubId)

  useEffect(() => {
    refetch()
  }, [leagueQuizId])

  const userNotInTopResults =
    leaderboard.findIndex((item) => item.user.id === authUser?.uid) === -1

  return (
    <>
      {loading && leaderboard.length === 0 ? (
        <Flex horizontal="center">
          <Loader variant="yellow" size={40} />
        </Flex>
      ) : leaderboard.length === 0 && selectedTab === 'users' ? (
        <Text color="white">{t("There's no one here")}</Text>
      ) : null}

      {selectedTab === 'users' && (
        <>
          {userNotInTopResults && viewerLeaderboardEntry && (
            <>
              <LeaderboardItemComponentV2
                item={viewerLeaderboardEntry}
                isViewer={true}
                css={margin.bottom('tiny')}
                backgroundColor={colors.yellow200}
              />
              <div
                css={css`
                  background-color: ${colors.white};
                  height: 1px;
                  margin: 16px;
                `}
              />
            </>
          )}

          {leaderboard.map((item) => {
            return (
              <Fragment key={item.user.id}>
                <LeaderboardItemComponentV2
                  item={item}
                  isViewer={item.user.id === authUser?.uid}
                  css={margin.bottom('small')}
                />
              </Fragment>
            )
          })}

          {hasMore && (
            <Flex horizontal="center" css={margin.top()}>
              <SecondaryButton onClick={fetchMore} variant="white">
                {t('More')}
              </SecondaryButton>
            </Flex>
          )}
        </>
      )}

      {selectedTab === 'clubs' && (
        <>
          {clubsLeaderboard.map((entry) => {
            return (
              <Fragment key={entry.id}>
                <LeaderboardItemComponentV2
                  clubEntry={entry}
                  isViewer={false}
                  css={margin.bottom('tiny')}
                />
              </Fragment>
            )
          })}
        </>
      )}

      {selectedTab === 'friends' && (
        <>
          {friendsLeaderboard.length === 0 ? (
            <Text color="white">{t("There's no one here")}</Text>
          ) : (
            friendsLeaderboard.map((item) => {
              return (
                <Fragment key={item.user.id}>
                  <LeaderboardItemComponentV2
                    item={item}
                    isViewer={item.user.id === authUser?.uid}
                    css={margin.bottom('small')}
                  />
                </Fragment>
              )
            })
          )}
          <Spacer height={16} />
          <AddFriendsButtonText />
        </>
      )}
    </>
  )
}
