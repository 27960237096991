/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { MdCheck, MdSchedule } from 'react-icons/md'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { leagueQuizItemStyle } from './LeaguePage'
import { League } from './queries'

export const TotalScoresItem = ({ league }: { league: League }) => {
  const t = useT()

  return (
    <Flex column css={leagueQuizItemStyle}>
      <Heading level={5} css={margin.bottom()}>
        {t('Total')}
      </Heading>
      <Flex grow column vertical="center">
        <Text
          size="small"
          css={css`
            padding: 8px 0;
          `}
        >
          {t('{{count}} player', { count: league.participantCount })}
        </Text>
        <Flex
          vertical="center"
          css={[
            css`
              border-top: 1px solid black;
              padding: 8px 0;
            `,
          ]}
          gap="medium"
        >
          <Flex vertical="center" gap="small">
            <MdCheck size={16} />
            <Text size="small">
              {t('{{correctAnswersCount}} of {{questionsCount}}', {
                correctAnswersCount: league.viewerResult.correctAnswersCount,
                questionsCount: league.viewerResult.questionsCount,
              })}
            </Text>
          </Flex>

          <Flex vertical="center" gap="small">
            <MdSchedule size={16} />
            <Text size="small">{league.viewerResult.timeBonus}s</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex css={margin.vertical('small')}>
        <Text>={league.viewerResult.totalScore}p</Text>
      </Flex>
    </Flex>
  )
}
