/** @jsxImportSource @emotion/react */

import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import {
  League,
  useLeagueClubsLeaderboard,
  useLeagueLeaderboard,
} from '../queries'
import { LeagueStatus } from '../../../lib/types'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { Flex } from '../../../components/layout/Flex'
import { SecondaryButton } from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { Text } from '../../../components/Text'
import { Fragment } from 'react'
import { css } from '@emotion/react'
import { LeaderboardItemComponentV2 } from './LeaderBoardItemV2'
import { AddFriendsButtonText } from '../../../components/friends/AddFriends'

export const LeagueLeaderboardV2 = ({
  league,
  clubId,
  selectedTab = 'users',
}: {
  league: League
  clubId?: string
  selectedTab?: 'users' | 'clubs' | 'friends'
}) => {
  const t = useT()
  const { authUser } = useAuthContext()
  const {
    leaderboard,
    viewerLeaderboardEntry,
    friendsLeaderboard,
    loading,
    hasMore,
    fetchMore,
  } = useLeagueLeaderboard(league.slug, clubId)

  const { clubsLeaderboard } = useLeagueClubsLeaderboard(league.slug)

  const userNotInTopResults =
    leaderboard.findIndex((item) => item.user.id === authUser?.uid) === -1

  return (
    <>
      {selectedTab === 'users' && (
        <>
          {loading && leaderboard.length === 0 ? (
            <Flex horizontal="center">
              <Loader variant="yellow" size={40} />
            </Flex>
          ) : leaderboard.length === 0 ? (
            <Text color="white">{t("There's no one here")}</Text>
          ) : null}

          {userNotInTopResults && viewerLeaderboardEntry && (
            <Fragment>
              <Text color="white"> {t(`Your positon:`)} </Text>
              <Spacer height="small" />
              <LeaderboardItemComponentV2
                item={viewerLeaderboardEntry}
                isViewer={true}
                showTrophies={league.status === LeagueStatus.completed}
                css={margin.bottom('tiny')}
                backgroundColor={colors.yellow200}
              />

              <div
                css={css`
                  background-color: ${colors.white};
                  height: 1px;
                  margin: 16px;
                `}
              />
            </Fragment>
          )}

          {leaderboard.map((item) => {
            return (
              <Fragment key={item.user.id}>
                <LeaderboardItemComponentV2
                  item={item}
                  isViewer={item.user.id === authUser?.uid}
                  showTrophies={league.status === LeagueStatus.completed}
                  css={margin.bottom('small')}
                />
              </Fragment>
            )
          })}

          {hasMore && (
            <Flex horizontal="center" css={margin.top()}>
              <SecondaryButton onClick={fetchMore} variant="white">
                {t('More')}
              </SecondaryButton>
            </Flex>
          )}
        </>
      )}

      {selectedTab === 'clubs' && (
        <>
          {clubsLeaderboard.map((entry) => {
            return (
              <Fragment key={entry.id}>
                <LeaderboardItemComponentV2
                  clubEntry={entry}
                  isViewer={false}
                  showTrophies={league.status === LeagueStatus.completed}
                  css={margin.bottom('tiny')}
                />
              </Fragment>
            )
          })}
        </>
      )}

      {selectedTab === 'friends' && (
        <>
          {friendsLeaderboard.map((item) => {
            return (
              <Fragment key={item.user.id}>
                <LeaderboardItemComponentV2
                  item={item}
                  isViewer={item.user.id === authUser?.uid}
                  css={margin.bottom('small')}
                />
              </Fragment>
            )
          })}
          <Spacer height={16} />
          <AddFriendsButtonText />
        </>
      )}
    </>
  )
}
