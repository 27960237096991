import { gql, useQuery } from '@apollo/client'

export type SiteSettings = {
  maintenanceMessage?: string
}

export function useSiteSettings(): SiteSettings {
  const query = useQuery<{ siteSettings: SiteSettings }>(
    gql`
      query siteSettings {
        siteSettings {
          maintenanceMessage
        }
      }
    `
  )

  return {
    maintenanceMessage: query.data?.siteSettings.maintenanceMessage,
  }
}
