/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import 'quill/dist/quill.snow.css'
import { useEffect, useState } from 'react'
import { useQuill } from 'react-quilljs'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { ConfirmModal } from '../../components/ConfirmModal'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import {
  Locale,
  LocaleString,
  localeToFlag,
  locales,
} from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { ChallengeNavItem } from '../Challenge/ChallengeGroupPage'
import { useClubsQuery } from '../Clubs/queries'
import { useLibrarySearchQuery } from '../Search/queries'
import { useTranslateLocaleStringQuery } from '../Translate/queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'
import { TargetMode } from './PushPage'
import {
  useSendNewsletterMutation,
  useUsersWithMarketingEmailSettingsQuery,
} from './queries'

const Content = () => {
  const t = useT()
  const theme = 'snow'
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  }

  const [targetMode, setTargetMode] = useState<TargetMode>(TargetMode.all)
  const [clubs, setClubs] = useState<
    { id: string; name: string; verified: boolean }[]
  >([])
  const [verifiedClubsOnly, setVerifiedClubsOnly] = useState(true)

  const clubIds = clubs.map((c) => c.id)
  const [leagues, setLeagues] = useState<{ id: string; title: string }[]>([])

  const leagueIds = leagues.map((l) => l.id)

  const [clubQuery, setClubQuery] = useState('')
  const debouncedClubQuery = useDebouncedString(clubQuery, 300, {
    minLength: 3,
  })

  const clubsQuery = useClubsQuery({
    query: debouncedClubQuery,
    limit: debouncedClubQuery.length > 0 ? undefined : 50,
    skip: debouncedClubQuery.length < 3,
    verifiedOnly: verifiedClubsOnly,
    skipCacheRead: true,
  })

  const searchResultClubs = clubsQuery.clubs ?? []

  const [leagueQuery, setLeagueQuery] = useState('')
  const debouncedLeagueQuery = useDebouncedString(leagueQuery, 300, {
    minLength: 3,
  })

  const leaguesQuery = useLibrarySearchQuery(debouncedLeagueQuery)

  const searchResultLeagues = leaguesQuery.data?.librarySearch.leagues ?? []

  const resetTargeting = () => {
    setClubs([])
    setClubQuery('')
    setLeagues([])
    setLeagueQuery('')
    setVerifiedClubsOnly(true)
  }

  useEffect(() => {
    resetTargeting()
  }, [targetMode])
  const [subject, setSubject] = useState('')
  const [missingSubject, setMissingSubject] = useState(false)
  const { quillRef, quill } = useQuill({ theme, modules })
  const [sendNewsletter, { loading, data }] = useSendNewsletterMutation()
  const usersQuery = useUsersWithMarketingEmailSettingsQuery(
    [],
    clubIds,
    leagueIds
  )

  const [titles, setTitles] = useState<LocaleString>({
    en: '',
  })

  const [bodies, setBodies] = useState<LocaleString>({
    en: '',
  })
  const [showLocales, setShowLocales] = useState(false)
  const [oldLocale, setOldLocale] = useState<Locale>(Locale.en)
  const [locale, setLocale] = useState<Locale>(Locale.en)
  const [runQuery, translateLocaleString] = useTranslateLocaleStringQuery()
  const body = quill?.root.innerHTML
  const [bodyChanged, setBodyChanged] = useState(false)

  useEffect(() => {
    if (!quill) return
    quill.on('text-change', () => {
      setBodyChanged(true)
    })
  }, [quill])

  useEffect(() => {
    setBodyChanged(false)
    if (oldLocale !== locale) {
      setSubject(titles[locale] ?? '')
      if (quill) {
        quill.root.innerHTML = bodies[locale] ?? ''
      }
      setOldLocale(locale)
      return
    }
    if (titles[locale] !== subject) {
      setTitles({ ...titles, [locale]: subject })
    }
    const bodyEmpty =
      (body?.replace(/<(.|\n)*?>/g, '').trim().length ?? 0) === 0
    const _body = bodyEmpty ? '' : body //We want '' instead so that isEmpty == true
    if (bodies[locale] !== _body) {
      setBodies({ ...bodies, [locale]: _body })
    }
  }, [subject, bodyChanged, locale])

  const usersWithEmail = usersQuery.data?.usersWithMarketingEmailSettings ?? 0

  return (
    <ConfirmModal
      onConfirm={() => {
        const content = body

        if (!content || !quill) {
          alert('An error occurred while sending newsletter.')
          return
        }

        return sendNewsletter({
          variables: {
            input: { subject: titles, content: bodies, clubIds, leagueIds },
          },
        }).then((result) => {
          if (result.data?.sendNewsletter) {
            // reset form
            setSubject('')
            resetTargeting()
            try {
              quill.setText('\n')
            } catch (_) {}
          }
        })
      }}
      button={({ openModal }) => (
        <form
          css={margin.top('large')}
          onSubmit={(e) => {
            e.preventDefault()

            if (!subject) {
              setMissingSubject(true)
              return
            }

            openModal()
          }}
        >
          <Flex
            column
            gap="small"
            css={css`
              background-color: ${colors.grey200};
              border-radius: 10px;
              padding: 16px;
            `}
          >
            <TextNew> Targeting: {targetMode}</TextNew>
            <Spacer height={4} />
            <Flex wrap gap={'medium'}>
              <ChallengeNavItem
                title={TargetMode.all}
                onClick={() => setTargetMode(TargetMode.all)}
                selected={targetMode === TargetMode.all}
              />
              <ChallengeNavItem
                title={TargetMode.clubMembers}
                onClick={() => setTargetMode(TargetMode.clubMembers)}
                selected={targetMode === TargetMode.clubMembers}
              />
              <ChallengeNavItem
                title={TargetMode.seriesSubscribers}
                onClick={() => setTargetMode(TargetMode.seriesSubscribers)}
                selected={targetMode === TargetMode.seriesSubscribers}
              />
            </Flex>

            {targetMode === TargetMode.clubMembers && (
              <Flex column gap={'medium'} css={margin.bottom('medium')}>
                <Flex wrap gap={'small'}>
                  {clubs.map((club) => {
                    return (
                      <Chip
                        key={`selected_${club.id}`}
                        label={`${club.name}${club.verified ? ' ✔' : ''}`}
                        backgroundColor={colors.yellow400}
                        color={colors.black}
                        clickable
                        onClick={() => {
                          setClubs(clubs.filter((c) => c.id !== club.id))
                        }}
                      />
                    )
                  })}{' '}
                </Flex>

                <TextInput
                  label={'Search for club'}
                  onValue={setClubQuery}
                  css={css`
                    flex: 1;
                  `}
                />
                <ToggleSwitch
                  value={verifiedClubsOnly}
                  onValue={setVerifiedClubsOnly}
                  label={'Verified only'}
                />

                <Flex wrap gap={'small'}>
                  {searchResultClubs.map((club) => {
                    const selected = clubIds.includes(club.id)
                    return (
                      <Chip
                        clickable
                        key={club.id}
                        label={`${club.name}${club.verified ? ' ✔' : ''}`}
                        backgroundColor={
                          selected ? colors.yellow400 : colors.yellow200
                        }
                        color={colors.black}
                        onClick={() => {
                          if (selected) {
                            setClubs(clubs.filter((c) => c.id !== club.id))
                          } else {
                            setClubs([...clubs, club])
                          }
                        }}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            )}
            {targetMode === TargetMode.seriesSubscribers && (
              <Flex column gap={'medium'} css={margin.bottom('medium')}>
                <Flex wrap gap={'small'}>
                  {leagues.map((league) => {
                    return (
                      <Chip
                        key={`selected_${league.id}`}
                        label={league.title}
                        backgroundColor={colors.yellow400}
                        color={colors.black}
                        clickable
                        onClick={() => {
                          setLeagues(leagues.filter((l) => l.id !== league.id))
                        }}
                      />
                    )
                  })}
                </Flex>
                <TextInput
                  label={'Search for series'}
                  onValue={setLeagueQuery}
                />
                <Flex wrap gap={'small'}>
                  {searchResultLeagues.map((league) => {
                    const selected = leagueIds.includes(league.id)
                    return (
                      <Chip
                        clickable
                        key={league.id}
                        label={league.title}
                        backgroundColor={
                          selected ? colors.yellow400 : colors.yellow200
                        }
                        color={colors.black}
                        onClick={() => {
                          if (selected) {
                            setLeagues(
                              leagues.filter((c) => c.id !== league.id)
                            )
                          } else {
                            setLeagues([...leagues, league])
                          }
                        }}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            )}
            <Flex
              css={css`
                padding: 8px;
                background: ${colors.yellow100};
                border-radius: 2px;
                border: 1px solid gray;
              `}
            >
              <Text>
                {t('Send email to {{count}} users', {
                  count: usersWithEmail,
                })}
              </Text>
            </Flex>
          </Flex>
          <Spacer height={16} />
          {showLocales && (
            <Flex gap="tiny">
              {locales.map((it) => (
                <SecondaryButton
                  key={it}
                  css={[
                    css`
                      border: 1px solid ${colors.grey200};
                      border-bottom: none;
                      border-bottom-left-radius: 0px;
                      border-bottom-right-radius: 0px;
                      padding-bottom: 0px;
                    `,
                    it !== locale &&
                      css`
                        background-color: ${colors.grey200};
                      `,
                  ]}
                  onClick={() => setLocale(it)}
                >
                  {localeToFlag[it]}
                </SecondaryButton>
              ))}
            </Flex>
          )}
          <TextInput
            label={t('Subject')}
            value={subject}
            onValue={(val) => {
              if (val) {
                setMissingSubject(false)
              }
              setSubject(val)
            }}
            css={margin.top('large')}
            error={missingSubject ? t('Subject is required') : undefined}
          />
          <Flex column css={margin.top('large')}>
            <Text>{t('Content')}</Text>
            <div
              css={css`
                ${emailStyles}
              `}
            >
              <div ref={quillRef} />
            </div>
          </Flex>
          <Flex horizontal="space-between">
            <SecondaryButton
              onClick={async () => {
                const prompt =
                  'This will override the non-english titles and bodies, are you sure you want to re-translate?'
                if (showLocales && !confirm(prompt)) return
                if (!bodies.en) {
                  alert('English translation is missing')
                  return
                }
                const words = bodies.en.split(' ').length
                if (words > 100) {
                  alert(
                    `This can take up to 3 min. (Words to translate: ${words})`
                  )
                }
                const newTitles: { [key: string]: string } = {
                  en: titles.en!,
                }
                const newBodies: { [key: string]: string } = {
                  en: bodies.en!,
                }
                for (const locale of locales.filter((it) => it !== Locale.en)) {
                  const res = await runQuery({
                    variables: {
                      input: {
                        englishTexts: [titles.en, bodies.en],
                        toLocale: locale,
                      },
                    },
                  })
                  if (!res.data) {
                    alert(
                      'An error occurred while translating. Please check each locales before sending notification.'
                    )
                    return
                  }
                  const [_titles, _bodies] = res.data.translateLocaleStrings
                  newTitles[locale] = _titles[locale] ?? ''
                  newBodies[locale] = _bodies[locale] ?? ''
                }
                setTitles(newTitles)
                setBodies(newBodies)
                setShowLocales(true)
              }}
              css={margin.top('large')}
              disabled={!subject || !body || locale !== Locale.en}
            >
              {translateLocaleString.loading ? (
                <Loader size={15} delay={0} />
              ) : (
                'Translate'
              )}
            </SecondaryButton>
            <PrimaryButton
              disabled={loading}
              type="submit"
              css={margin.top('large')}
            >
              {t('Send')}
            </PrimaryButton>
          </Flex>
          {data && (
            <Flex css={margin.top()}>
              <Text strong>{t('Email sent')}</Text>
            </Flex>
          )}
        </form>
      )}
    />
  )
}

export const AdminEmailPage = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Administration')}`}>
      <AdminAccessControlComponent>
        <Header color="green" />

        <PageContent grow>
          <AdminNavbar activePage="email" />

          <Content />
        </PageContent>

        <Footer />
      </AdminAccessControlComponent>
    </Layout>
  )
}

// Try to replicate layout from https://account.postmarkapp.com/servers/6520287/templates/23874966/edit
const emailStyles = `
strong,
b,
strong *,
b * {
  font-weight: bold;
}
em,
i,
em *,
i * {
  font-style: italic;
}

a {
  color: #3869D4;
}

a img {
  border: none;
}

h1 {
  margin-top: 0;
  color: #333333;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}

h2 {
  margin-top: 0;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

h3 {
  margin-top: 0;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

p,
ul,
ol,
blockquote {
  margin: .4em 0 1.1875em;
  font-size: 16px;
  line-height: 1.625;
}
`
