/** @jsxImportSource @emotion/react */

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from '../components/layout/Flex'
import { Layout } from '../components/layout/Layout'
import { Page } from '../components/leagueQuiz/Page'
import { useAuthContext } from '../lib/AuthContext'
import { colors } from '../styles/theme'
import { useLeagueQuizInstanceWithTransferQuery } from './ChallengeMatch/queries'
import { WelcomeBanner } from './Home/WelcomeBanner'

const Content = ({ instanceId }: { instanceId: string | null }) => {
  const { authUser } = useAuthContext()

  // If a user has an account, but played the quiz in an anon session
  // Transfer the instance to the user's account
  const { data: instanceData, loading: instanceLoading } =
    useLeagueQuizInstanceWithTransferQuery({
      id: instanceId || '',
      skip: !instanceId || authUser?.isAnonymous,
    })

  const navigate = useNavigate()

  useEffect(() => {
    if (!instanceLoading && instanceData?.leagueQuizInstanceWithTransfer) {
      const quiz = instanceData.leagueQuizInstanceWithTransfer.leagueQuiz
      navigate(`/series/${quiz.league.slug}/${quiz.id}`)
    }

    if (authUser && !authUser.isAnonymous && !instanceId) {
      navigate('/')
    }
  }, [authUser, instanceLoading, instanceData])

  return (
    <Flex column grow>
      <WelcomeBanner />
    </Flex>
  )
}

export const SignupPage = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const instanceId = searchParams.get('instanceId')
  return (
    <Layout withHeader headerForegroundColor={colors.black} hideNavbar>
      <Page showFcQuizLogo logoColor={colors.grey300}>
        <Content instanceId={instanceId} />
      </Page>
    </Layout>
  )
}
