/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { MdSchedule } from 'react-icons/md'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'

export const Timer: FC<{
  seconds?: number
  color?: string
  condensed?: boolean
  className?: string
}> = ({ seconds, color = 'white', condensed = false, className }) => {
  return (
    <Flex vertical="center" gap="tiny" className={className}>
      {seconds && (
        <TextNew
          color={color}
          size="small"
          condensed={condensed}
          strong
          css={css`
            font-variant-numeric: tabular-nums;
            min-width: 2em;
            text-align: right;
            margin-bottom: 0;
          `}
        >
          {seconds}s
        </TextNew>
      )}
      <MdSchedule size={22} color={color} />
    </Flex>
  )
}
