/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { Cross } from '../../components/icons/Cross'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { ModalTrigger } from '../../components/modal/Modal'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import { HostLiveQuizModal } from '../LiveQuiz/components/HostLiveQuizModal'
import { useLibrarySearchQuery } from '../Search/queries'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'

const Content = () => {
  const t = useT()
  const [searchQuery, setSearchQuery] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })

  const searchQueryResult = useLibrarySearchQuery(debouncedSearchQuery)

  // Set the search query from the URL
  useEffect(() => {
    if (searchParams.get('query')) {
      // Decode the search query from the URL
      const query = searchParams.get('query') as string
      const decodedSearchQuery = decodeURIComponent(query)
      setSearchQuery(decodedSearchQuery)
    }
  }, [])

  // Set the params to the search query
  useEffect(() => {
    if (searchQuery) {
      //Encode the search query to be URL friendly
      const encodedSearchQuery = encodeURIComponent(searchQuery)

      setSearchParams({ query: encodedSearchQuery })
    }
  }, [debouncedSearchQuery])

  return (
    <PageContent css={[margin.top(16)]}>
      <HeadingNew level={1} looksLikeLevel={3}>
        Admin Live Quiz Page
      </HeadingNew>
      <Spacer height="large" />
      <TextNew>{'Search for quizzes to host:'}</TextNew>
      <Spacer height="small" />
      <Flex
        css={css`
          position: relative;
          background-color: ${colors.grey200};
          padding: 16px;
          border-radius: 5px;
        `}
      >
        <TextInput
          value={searchQuery}
          onValue={setSearchQuery}
          placeholder={t('Search...')}
          css={css`
            border-radius: 5px;
            border: none;
            width: 100%;
            z-index: 2;
          `}
        />
        {searchQuery.trim() !== '' && (
          <button
            onClick={() => {
              setSearchQuery('')
              setSearchParams()
            }}
            css={[
              buttonReset,
              css`
                position: absolute;
                z-index: 3;
                top: 0;
                right: 12px;
                bottom: 0;
                height: 100%;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                animation: ${fadeIn} 650ms;
                animation-fill-mode: forwards;
                animation-delay: 300ms;
              `,
            ]}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                aspect-ratio: 1;
                background-color: ${colors.grey400};
                border-radius: 50%;
                padding: 4px;
                padding-right: 5px;
              `}
            >
              <Cross size={16} color="white" />
            </div>
          </button>
        )}
      </Flex>

      <Spacer height="large" />

      <Flex column gap="medium">
        {searchQueryResult.data?.librarySearch.quizzes.map((quiz) => (
          <Flex
            key={quiz.id}
            vertical="center"
            horizontal="space-between"
            css={css`
              padding: 16px;
              border-radius: 5px;
              background-color: ${colors.grey200};
            `}
          >
            <Flex column gap={'tiny'}>
              {quiz.league && <TextNew>{quiz.league.title}</TextNew>}
              <TextNew strong>{quiz.title}</TextNew>
              <Spacer height="small" />
              <TextNew>{`${quiz.questionsCount} questions`}</TextNew>
            </Flex>
            <ModalTrigger
              button={({ openModal }) => (
                <PrimaryButton onClick={openModal}>
                  {t('Go live!')}
                </PrimaryButton>
              )}
              modal={({ closeModal }) => (
                <HostLiveQuizModal closeModal={closeModal} quiz={quiz} />
              )}
            />
          </Flex>
        ))}
      </Flex>
    </PageContent>
  )
}

export const AdminLiveQuizPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="liveQuiz" />
        </PageContent>
        <Content />
      </AdminAccessControlComponent>
    </Layout>
  )
}
