import { gql, useMutation, useQuery } from '@apollo/client'
import { isURL } from './util'
import { Club, clubFragment } from '../Clubs/queries'
import { LocaleString } from '../../lib/i18n/locale'
import { localeStringFragment } from '../Translate/queries'
import { CareerPathQuestion } from './GenerateQuestion'

export type GeneratedQuestion = {
  question: string
  options: string[]
  solutionIndex: number
  citation: string
}

type QuestionInfo = {
  id: string
  question: LocaleString
  alternatives: LocaleString[]
  solutionIndex: number
  categories: string[]
  timeless: boolean
  aiGenerated: boolean
  referenceUrl: string
  questionIndex: number
  questionLimit: number
  madeByUser: boolean
}

export type QuestionInput = {
  question: QuestionInfo
  language: string
  leagueSlug: string
}

export type CareerPathEntryInput = {
  team: {
    id: string
    name: string
    logoUrl: string
    league?: {
      id: string
      name: string
      logoUrl: string
      nation: string
    }
    nation?: string
  }
  type: string
  year: string
}

export type CareerPathQuestionInput = {
  careerPathPlayer: {
    playerId: string
    playerName: string
    firstName: string
    lastName: string
    photoUrl: string
    nationality: string
    position: string
    team: {
      id: string
      name: string
      logoUrl: string
      league?: string
      nation?: string
    }
    careerPath: CareerPathEntryInput[]
  }
  timeless: boolean
  leagueSlug: string
}

export type QuizInput = {
  id: string
  leagueSlug: string
  title: LocaleString
  promotionalText?: LocaleString
  randomizeOrder: boolean
  startDate: string
  endDate?: string
  questionIds: string[]
  language: string
  isLiveQuiz: boolean
}

type GeneratedQuestionMsg = {
  content: GeneratedQuestion
  status: string
}

export type CheckTitleMsg = {
  title: string
  checkmark: number
  checkmarkMax: number
}

type CheckAllTitleMsg = {
  checkTitleInfos: CheckTitleMsg[]
}

type Category = {
  _id: string
  name: LocaleString
}

type GetCategoriesMsg = {
  categories: Category[]
}

const generateQuestionsQuery = gql`
  query generateQuestions(
    $text: String!
    $languages: [String!]!
    $leagueSlug: String!
  ) {
    generateQuestions(
      text: $text
      languages: $languages
      leagueSlug: $leagueSlug
    ) {
      content {
        question
        options
        solutionIndex
      }
      status
    }
  }
`

const checkTitleQuery = gql`
  query checkTitle($url: String!) {
    checkTitle(url: $url) {
      checkmark
      checkmarkMax
    }
  }
`

const checkAllTitleQuery = gql`
  query checkAllTitle {
    checkAllTitle {
      checkTitleInfos {
        title
        checkmark
        checkmarkMax
      }
    }
  }
`

const getCategoriesQuery = gql`
  query getCategories {
    getCategories {
      categories {
        _id
        name {
          ...LocaleString
        }
      }
    }
  }
  ${localeStringFragment}
`

const addQuestionMutation = gql`
  mutation addQuestion($input: QuestionInput!) {
    addQuestion(input: $input)
  }
`

const addCareerPathQuestionMutation = gql`
  mutation addCareerPathQuestion($input: CareerPathQuestionInput!) {
    addCareerPathQuestion(input: $input)
  }
`

const addQuizMutation = gql`
  mutation addQuiz($input: QuizInput!) {
    addQuiz(input: $input)
  }
`

const scrapeUrlQuery = gql`
  query scrapeUrl($url: String!) {
    scrapeUrl(url: $url) {
      text
    }
  }
`

export const useStartGenerateQuestionsQuery = (
  text: string,
  languages: string[],
  leagueSlug: string
) => {
  return useQuery<{
    generateQuestions?: GeneratedQuestionMsg
  }>(generateQuestionsQuery, {
    variables: { text, languages, leagueSlug },
    fetchPolicy: 'cache-and-network',
    skip: text === '',
  })
}

export const useAddQuestionMutation = () => {
  return useMutation<unknown, { input: QuestionInput }>(addQuestionMutation)
}

export const useAddCareerPathQuestionMutation = () => {
  return useMutation<unknown, { input: CareerPathQuestionInput }>(
    addCareerPathQuestionMutation
  )
}

export const useAddQuizMutation = () => {
  return useMutation<{ addQuiz: string }, { input: QuizInput }>(addQuizMutation)
}

export const useCheckTitleQuery = (url: string) => {
  return useQuery<{
    checkTitle?: CheckTitleMsg
  }>(checkTitleQuery, {
    variables: { url },
    fetchPolicy: 'cache-and-network',
    skip: !isURL(url),
  })
}

export const useCheckAllTitleQuery = () => {
  return useQuery<{
    checkAllTitle?: CheckAllTitleMsg
  }>(checkAllTitleQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export const useGetCategoriesQuery = () => {
  return useQuery<{
    getCategories?: GetCategoriesMsg
  }>(getCategoriesQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export const useScrapeUrlQuery = (url: string) => {
  return useQuery<{
    scrapeUrl: { text: string }
  }>(scrapeUrlQuery, {
    variables: { url },
    fetchPolicy: 'cache-and-network',
    skip: !isURL(url),
  })
}

const checkSlugAccessQuery = gql`
  query checkSlugAccess($leagueSlug: String!) {
    checkSlugAccess(leagueSlug: $leagueSlug) {
      ...Club
    }
  }

  ${clubFragment}
`

export const useCheckSlugAccess = (leagueSlug: string) => {
  return useQuery<{ checkSlugAccess: Club[] }>(checkSlugAccessQuery, {
    variables: { leagueSlug },
    fetchPolicy: 'cache-and-network',
    skip: leagueSlug == '',
  })
}

export type CareerPathTeam = {
  id: string
  name: string
  logoUrl: string
  league?: CareerPathLeague
}

export type CareerPathLeague = {
  id: string
  name: string
  logoUrl: string
}

export type CareerPathTransfer = {
  fromTeam: CareerPathTeam
  toTeam: CareerPathTeam
  transferType: string
  transferDate: string
}

export type CareerPathPlayer = {
  id: string
  firstName: string
  lastName: string
  name: string
  photo: string
  team: CareerPathTeam
  position: string
  age: number
  nationality: string
  transfers?: CareerPathTransfer[]
}

export const careerPathTeamFragment = gql`
  fragment CareerPathTeamFragment on CareerPathTeam {
    id
    name
    logoUrl
    league {
      id
      name
      logoUrl
    }
  }
`

export const careerPathPlayerFragment = gql`
  fragment CareerPathPlayerFragment on CareerPathQuestion {
    id
    careerPathPlayer {
      playerId
      playerName
      photoUrl
      nationality
      team {
        id
        name
        logoUrl
        league {
          id
          name
          logoUrl
        }
      }
      careerPath {
        team {
          id
          name
          logoUrl
        }
        type
        year
      }
    }
  }
`

const careerPathPlayersByNameQuery = gql`
  query careerPathPlayersByName($name: String!) {
    careerPathPlayersByName(name: $name) {
      ...CareerPathPlayerFragment
    }
  }
  ${careerPathPlayerFragment}
`

export const useGetPlayerByNameQuery = (name: string) => {
  return useQuery<{ careerPathPlayersByName: CareerPathQuestion[] }>(
    careerPathPlayersByNameQuery,
    {
      variables: { name },
      fetchPolicy: 'cache-and-network',
      skip: name == '',
    }
  )
}

export const useGetPlayerByNameSimpleQuery = (name: string) => {
  return useQuery<{ careerPathPlayersByName: CareerPathQuestion[] }>(
    careerPathPlayersByNameSimpleQuery,
    {
      variables: { name },
      fetchPolicy: 'cache-and-network',
      skip: name == '',
    }
  )
}

export const careerPathQuestionFragment = gql`
  fragment CareerPathQuestionFragment on CareerPathQuestion {
    id
    careerPathPlayer {
      playerId
      playerName
      firstName
      lastName
      photoUrl
      nationality
      position
      team {
        id
        name
        logoUrl
        league {
          id
          name
          logoUrl
        }
      }
      careerPath {
        team {
          id
          name
          logoUrl
        }
        type
        year
      }
    }
  }
`

export const careerPathQuestionSimpleFragment = gql`
  fragment CareerPathQuestionSimpleFragment on CareerPathQuestion {
    id
    careerPathPlayer {
      playerId
      playerName
      photoUrl
    }
  }
`

const careerPathPlayersByNameSimpleQuery = gql`
  query careerPathPlayersByName($name: String!) {
    careerPathPlayersByName(name: $name) {
      ...CareerPathQuestionSimpleFragment
    }
  }
  ${careerPathQuestionSimpleFragment}
`

const careerPathQuestionQuery = gql`
  query careerPathQuestion($id: String!) {
    getCareerPathQuestion(id: $id) {
      ...CareerPathQuestionFragment
    }
  }
  ${careerPathQuestionFragment}
`

export const useGetCareerPathQuestionQuery = (id: string) => {
  return useQuery<{ getCareerPathQuestion: CareerPathQuestion }>(
    careerPathQuestionQuery,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
      skip: id == '',
    }
  )
}

const getTeamsByNameQuery = gql`
  query getTeamsByName($name: String!) {
    getTeamsByName(name: $name) {
      ...CareerPathTeamFragment
    }
  }
  ${careerPathTeamFragment}
`

export const useGetAuthenticTeamsByNameQuery = (name: string) => {
  return useQuery<{ getTeamsByName: CareerPathTeam[] }>(getTeamsByNameQuery, {
    variables: { name },
    fetchPolicy: 'cache-and-network',
    skip: name == '',
  })
}

const getTransferHistoryQuery = gql`
  query getTransferHistory($playerId: String!) {
    getTransferHistory(playerId: $playerId) {
      fromTeam {
        ...CareerPathTeamFragment
      }
      toTeam {
        ...CareerPathTeamFragment
      }
      transferType
      transferDate
    }
  }
  ${careerPathTeamFragment}
`

export const useGetTransferHistoryQuery = (playerId: string) => {
  return useQuery<{ getTransferHistory: CareerPathTransfer[] }>(
    getTransferHistoryQuery,
    {
      variables: { playerId },
      fetchPolicy: 'cache-and-network',
      skip: playerId == '',
    }
  )
}
