/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { analyticsLabelsQuery, useEditAnalyticsLabelMutation } from './queries'

export const EditAnalyticslabelModal = ({
  closeModal,
  eventId,
  label,
}: {
  closeModal: () => void
  eventId: string
  label: string
}) => {
  const hasNotBeenSet = label === eventId

  const [copied, setCopied] = useState(false)

  const [inputLabel, setInputLabel] = useState(hasNotBeenSet ? '' : label)

  const apolloClient = useApolloClient()

  const [editLabelMutation] = useEditAnalyticsLabelMutation()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <Modal closeModal={closeModal} heading={'Edit analytics Label'}>
      <Flex vertical="center" horizontal="space-between">
        <TextNew>{`ID: ${eventId}`}</TextNew>
        <SecondaryButton
          onClick={async () => {
            await navigator.clipboard.writeText(eventId)

            setCopied(true)
          }}
        >
          {copied ? 'Copied!' : 'Copy'}
        </SecondaryButton>
      </Flex>
      <Spacer height={10} />
      <TextInput
        css={css`
          border: 1px solid ${colors.grey500};
        `}
        value={inputLabel}
        onValue={(value) => setInputLabel(value)}
        placeholder="Enter new label"
      />
      <Spacer height={20} />
      <Flex horizontal="space-between">
        <PrimaryButton
          onClick={async () => {
            await editLabelMutation({
              variables: {
                input: {
                  id: eventId,
                  label: inputLabel,
                },
              },
            })

            await apolloClient.refetchQueries({
              include: [analyticsLabelsQuery],
            })
            closeModal()
          }}
        >
          Save
        </PrimaryButton>
        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
      </Flex>
    </Modal>
  )
}
