/** @jsxImportSource @emotion/react */

import { gql, useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { AnalyticsTimeRange, DatePicker } from '../../Admin/AdminAnalyticsPage'
import { LineChartV2 } from '../../Admin/LineChartComponentV2'
import { EventDataset } from '../../Admin/queries'
import { Club } from '../queries'
import { Loader } from '../../../components/Loader'

// getEventsForClub(input: ClubEventsInput): [EventDataSet!]!
const clubStatsQuery = gql`
  query getEventsForClub($input: ClubEventsInput!) {
    getEventsForClub(input: $input) {
      label
      data
      total
    }
  }
`

const useGetEventsForClubQuery = (input: {
  clubId: string
  startDate: string
  endDate: string
}) => {
  const query = useQuery<{ getEventsForClub: EventDataset[] }>(clubStatsQuery, {
    variables: {
      input,
    },
  })

  const data = useMemo(() => {
    if (!query.data?.getEventsForClub) {
      return []
    }

    const events = [...query.data.getEventsForClub].sort(
      (a, b) => b.total - a.total
    )

    return events
  }, [query.data?.getEventsForClub])

  return { ...query, data }
}

export const ClubStatsSubPage = ({ club }: { club: Club }) => {
  const date1MonthAgo = new Date()
  date1MonthAgo.setMonth(date1MonthAgo.getMonth() - 1)

  const intervalStartDate = new Date()

  intervalStartDate.setDate(intervalStartDate.getDate() - 7)

  const endParamEndOfDay = new Date()
  endParamEndOfDay.setHours(23, 59, 59, 999)

  const [selectedTimeRange, setSelectedTimeRange] =
    useState<AnalyticsTimeRange>({
      startDate: intervalStartDate,
      endDate: endParamEndOfDay,
    })
  const clubStatsQuery = useGetEventsForClubQuery({
    clubId: club.id,
    startDate: selectedTimeRange.startDate.toISOString(),
    endDate: selectedTimeRange.endDate.toISOString(),
  })

  const events = clubStatsQuery.data || []

  return (
    <Flex column horizontal="center" gap={'large'}>
      <Spacer height="large" />
      <DatePicker
        selectedTimeRange={selectedTimeRange}
        setSelectedTimeRange={setSelectedTimeRange}
        textColor={colors.white}
      />

      {clubStatsQuery.loading && (
        <Flex vertical="center" grow>
          <Loader center variant="yellow" />
        </Flex>
      )}

      {!clubStatsQuery.loading && (
        <LineChartV2
          datasets={events}
          timeRange={selectedTimeRange}
          groupBy={'none'}
          setGroupBy={() => {}}
          analyticsLabels={[]}
          filterBy={[]}
          editable={false}
        />
      )}
    </Flex>
  )
}
