import { getCountryCode } from 'countries-list'

export const getCountryCodeHelper = (name: string): string => {
  try {
    switch (name) {
      case 'England':
        return 'gb-eng'
      case 'Wales':
        return 'gb-wls'
      case 'Scotland':
        return 'gb-sct'
      case 'Northern Ireland':
        return 'gb-nir'
      case 'Republic of Ireland':
        return 'ie'
      case 'Czechia':
        return 'cz'
      case 'USA':
        return 'us'
      case 'Korea Republic':
        return 'kr'
      default:
        return getCountryCode(name).toString().toLowerCase()
    }
  } catch (error) {
    return ''
  }
}

export const getCountryFlag = (name: string): string => {
  try {
    return `https://hatscripts.github.io/circle-flags/flags/${getCountryCodeHelper(
      name
    )}.svg`
  } catch (error) {
    return ''
  }
}
