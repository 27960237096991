/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { ComponentPropsWithoutRef, FC, forwardRef, ReactNode } from 'react'
import { margin } from '../styles/margin'
import { textStyleLegacy } from '../styles/styles'
import theme, { colors } from '../styles/theme'
import { Flex } from './layout/Flex'
import { Text } from './Text'

export type TextInputProps = {
  onValue: (value: string) => void
  label?: string | ReactNode
  error?: string | false
  textColor?: string
  textCss?: SerializedStyles
  lock?: boolean
} & ComponentPropsWithoutRef<'input'>

export type TextAreaProps = {
  onValue: (value: string) => void
  label?: string | ReactNode
  error?: string | false
  textColor?: string
  textCss?: SerializedStyles
} & ComponentPropsWithoutRef<'textarea'>

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      value,
      onValue,
      label,
      textColor = 'black',
      type = 'text',
      placeholder,
      error,
      className,
      textCss,
      lock,
      ...rest
    },
    ref
  ) {
    return (
      <label
        css={css`
          display: block;
        `}
        className={className}
      >
        <Flex
          column
          css={css`
            position: relative;
          `}
        >
          {typeof label === 'string' ? (
            <Text css={margin.bottom('tiny')} color={textColor}>
              {label}
            </Text>
          ) : (
            label
          )}
          <input
            ref={ref}
            value={value}
            onChange={(event) => onValue(event.target.value)}
            type={type}
            placeholder={placeholder}
            css={[
              textStyleLegacy,
              css`
                padding: 6px 8px;
                border-radius: 4px;
                border: 1px solid white;
              `,
              error &&
                css`
                  border: 1px solid ${theme.colors.red300};
                `,
              textCss,
            ]}
            {...rest}
          />
          {lock && (
            <img
              css={css`
                top: 25%;
                right: 8px;
                height: 50%;
                position: absolute;
              `}
              src="/lock.svg"
            />
          )}
          {error && (
            <ErrorMessage css={margin.top('tiny')}>{error}</ErrorMessage>
          )}
        </Flex>
      </label>
    )
  }
)

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(
    {
      value,
      onValue,
      label,
      textColor = 'black',
      placeholder,
      error,
      className,
      textCss,
      ...rest
    },
    ref
  ) {
    return (
      <label
        css={css`
          display: block;
        `}
        className={className}
      >
        <Flex column>
          {typeof label === 'string' ? (
            <Text css={margin.bottom('tiny')} color={textColor}>
              {label}
            </Text>
          ) : (
            label
          )}
          <textarea
            ref={ref}
            value={value}
            onChange={(event) => onValue(event.target.value)}
            placeholder={placeholder}
            css={[
              textStyleLegacy,
              css`
                padding: 6px 8px;
                border-radius: 4px;
                border: 1px solid white;
                resize: none;
              `,
              error &&
                css`
                  border: 1px solid ${theme.colors.red300};
                `,
              textCss,
            ]}
            {...rest}
          />
          {error && (
            <ErrorMessage css={margin.top('tiny')}>{error}</ErrorMessage>
          )}
        </Flex>
      </label>
    )
  }
)

export const ErrorMessage: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Flex
      css={css`
        background-color: ${colors.red200};
        color: ${colors.red500};
        padding: 0 4px 2px;
        border-radius: 4px;
      `}
      className={className}
    >
      <span
        css={css`
          align-self: center;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background-color: ${colors.red500};
          margin-right: 4px;
        `}
      />
      <Text
        size="small"
        css={css`
          color: ${colors.red500};
          margin-top: 0 !important;
        `}
        className={className}
      >
        {children}
      </Text>
    </Flex>
  )
}
