class PartData {
  style: string = ''
  index?: number // Legacy
  colors: string[] = []
  colorIndexes?: number[] // Legacy
  get data() {
    return {
      style: this.style,
      colors: this.colors,
    }
  }
}

export class Part extends PartData {
  name: string = ''
  selectableStylesLegacy?: string[]
  selectableColors?: string[]
  selectableColorsLegacy?: string[] // Legacy
  borders?: number[]
  bgasfg?: boolean
  get usedColors() {
    return 1
  }

  constructor(init?: Partial<Part>) {
    super()
    Object.assign(this, init)

    // Old avatars used colorIndexes instead of color
    // Therefore, set this.colors equal to those legacy
    // colors if colorIndexes was used
    const legacyColors = this.colorIndexes !== undefined
    if (legacyColors && this.selectableColorsLegacy) {
      this.colors = this.colorIndexes!.map(
        (it) => this.selectableColorsLegacy![it]
      )
      this.colorIndexes = undefined
    }

    const legacyStyle = this.index !== undefined
    if (legacyStyle && this.selectableStylesLegacy) {
      this.style = this.selectableStylesLegacy[this.index!]
      this.index = undefined
    }
  }
}

export default Part
