import { createContext, useContext, useEffect, useState } from 'react'
import { SanityLeagueQuizCampaign } from '../../lib/sanity/types'

export enum QuizState {
  START_SCREEN = 'start-screen',
  COUNTDOWN = 'countdown',
  ACTIVE_QUIZ = 'active-quiz',
  CALCULATING = 'calculating',
  FINISHED = 'finished',
}

const LeagueQuizContext = createContext<{
  quizState: QuizState
  setQuizState: (state: QuizState) => void
  isExpandedAdActive: boolean
  setIsExpandedAdActive: (active: boolean) => void
  campaignHeaderHeight: string
  hasSanityCampaign?: boolean
  videoAdActive: boolean
  setVideoAdActive: (active: boolean) => void
  showMobileImages: boolean
  leagueId?: string
  setLeagueId: (leagueId: string) => void
  quizId?: string
  setQuizId: (quizId: string) => void
  instanceId?: string
  setInstanceId: (instanceId?: string) => void
  campaign?: SanityLeagueQuizCampaign
  setCampaign: (campaign?: SanityLeagueQuizCampaign) => void
  clubLeagueSubscriptionId?: string
  setClubLeagueSubscriptionId: (subscriptionId?: string) => void
  clubId?: string
  setClubId: (clubId?: string) => void
}>({
  quizState: QuizState.START_SCREEN,
  setQuizState: () => Promise.reject(),
  isExpandedAdActive: false,
  setIsExpandedAdActive: () => Promise.reject(),
  campaignHeaderHeight: '0px',
  hasSanityCampaign: false,
  videoAdActive: false,
  setVideoAdActive: () => Promise.reject(),
  showMobileImages: false,
  leagueId: undefined,
  setLeagueId: () => Promise.reject(),
  quizId: undefined,
  setQuizId: () => Promise.reject(),
  instanceId: undefined,
  setInstanceId: () => Promise.reject(),
  campaign: undefined,
  setCampaign: () => Promise.reject(),
  clubLeagueSubscriptionId: undefined,
  setClubLeagueSubscriptionId: () => Promise.reject(),
  clubId: undefined,
  setClubId: () => Promise.reject(),
})

const mobileBreakpoint = 600

// 33vh on mobile, 50vh on desktop screen sizes
export const calculateScoreHeaderHeight =
  window.innerWidth < mobileBreakpoint ? '33vh' : '50vh'

const visibleHeaderHeight = 110
export const expandedHeaderHeight = '33vh'
export const collapsedHeaderHeight = `${visibleHeaderHeight + 16}px` // 110 is the visible height of the header, with a 16px margin for the rounded lip within the header
export const collapsedHeaderHeightNoPadding = `${visibleHeaderHeight}px` //

export const headerNotVisibleHeight = '0px'

export const useLeagueQuizContext = () => {
  return useContext(LeagueQuizContext)
}

export const LeagueQuizContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [leagueId, setLeagueId] = useState<string | undefined>(undefined)
  const [quizId, setQuizId] = useState<string | undefined>(undefined)
  const [instanceId, setInstanceId] = useState<string | undefined>(undefined)

  const [quizState, setQuizState] = useState<QuizState>(QuizState.START_SCREEN)
  const [hasSanityCampaign, setHasSanityCampaign] = useState<boolean>(false)
  const [clubLeagueSubscriptionId, setClubLeagueSubscriptionId] = useState<
    string | undefined
  >(undefined)

  const [clubId, setClubId] = useState<string | undefined>(undefined)

  const [campaign, setCampaign] = useState<
    SanityLeagueQuizCampaign | undefined
  >(undefined)

  const [videoAdActive, setVideoAdActive] = useState<boolean>(false)

  const [isExpandedAdActive, setIsExpandedAdActive] = useState<boolean>(false)
  const [campaignHeaderHeight, setCampaignHeaderHeight] = useState<string>(
    headerNotVisibleHeight
  )

  const [showMobileImages, setShowMobileImages] = useState<boolean>(
    window.innerWidth < mobileBreakpoint
  )

  // set showMobileImages based on screen size, when resizing
  useEffect(() => {
    const handleResize = () => {
      setShowMobileImages(window.innerWidth < mobileBreakpoint)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setHasSanityCampaign(!!campaign)
  }, [campaign])

  useEffect(() => {
    switch (quizState) {
      case QuizState.START_SCREEN:
        setCampaignHeaderHeight(headerNotVisibleHeight)
        break
      case QuizState.COUNTDOWN:
        setCampaignHeaderHeight(expandedHeaderHeight)
        break
      case QuizState.ACTIVE_QUIZ:
        setCampaignHeaderHeight(collapsedHeaderHeight)
        break
      case QuizState.CALCULATING:
        setCampaignHeaderHeight(calculateScoreHeaderHeight)
        break
      case QuizState.FINISHED:
        setCampaignHeaderHeight(collapsedHeaderHeight)
        break

      default:
        setCampaignHeaderHeight(headerNotVisibleHeight)
    }
  }, [quizState, setCampaignHeaderHeight, hasSanityCampaign])

  const value = {
    quizState,
    setQuizState,
    isExpandedAdActive,
    setIsExpandedAdActive,
    campaignHeaderHeight,
    hasSanityCampaign,
    videoAdActive,
    setVideoAdActive,
    showMobileImages,
    leagueId,
    setLeagueId,
    quizId,
    setQuizId,
    instanceId,
    setInstanceId,
    campaign,
    setCampaign,
    clubLeagueSubscriptionId,
    setClubLeagueSubscriptionId,
    clubId,
    setClubId,
  }

  return (
    <LeagueQuizContext.Provider value={value}>
      {children}
    </LeagueQuizContext.Provider>
  )
}
