/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { QuizStartScreen } from '../../components/leagueQuiz/QuizStartScreen'
import {
  QuizState,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import { LeagueQuizInstanceStatus } from '../LeagueQuiz/queries'
import { LiveQuizInstance } from '../LiveQuiz/queries'
import {
  LiveActiveQuestion,
  LiveReadQuestionScreen,
} from './components/LiveActiveQuestion'
import { LiveFinished } from './components/LiveFinished'
import { LiveLastCompletedQuestion } from './components/LiveLastCompletedQuestion'
import { LiveWaitToStart } from './components/LiveWaitToStart'

export const PlayLiveQuiz = ({
  quizInstance,
  clubLeagueSubscriptionId,
  setShouldPoll,
}: {
  quizInstance: LiveQuizInstance
  clubLeagueSubscriptionId?: string
  setShouldPoll: (value: boolean) => void
}) => {
  const { quizState, setQuizState, setIsExpandedAdActive } =
    useLeagueQuizContext()
  const leagueQuiz = quizInstance.leagueQuiz

  const completedQuestions = quizInstance.completedQuestionsLive
  const activeQuestion = quizInstance.activeQuestionLive
  const readableQuestion = quizInstance.readableQuestionLive

  const lastCompletedQuestion =
    !activeQuestion && completedQuestions.length > 0
      ? completedQuestions[completedQuestions.length - 1]
      : null

  const [finished, setFinished] = useState<boolean>(
    quizInstance.status === LeagueQuizInstanceStatus.completed
  )

  // This determines if the play count animation should be played | default: false
  const [playCountAnimation, setPlayCountAnimation] = useState<boolean>(false)

  const quizFinished = quizInstance.liveHostInstanceStatus === 'finished'

  useEffect(() => {
    if (quizFinished) {
      setFinished(true)
      triggerCalculation()

      //timeOut 15 seconds then stop polling
      setTimeout(() => {
        setShouldPoll(false)
      }, 15000)
    }
  }, [quizFinished])

  useEffect(() => {
    if (!activeQuestion && !lastCompletedQuestion && !readableQuestion) {
      setIsExpandedAdActive(false)
      setQuizState(QuizState.START_SCREEN)
    } else {
      setQuizState(QuizState.ACTIVE_QUIZ)
    }
  }, [activeQuestion, lastCompletedQuestion, readableQuestion])

  const triggerCalculation = () => {
    setIsExpandedAdActive(true)
    setPlayCountAnimation(true)
    setFinished(true)
    setQuizState(QuizState.FINISHED)
  }

  if (quizState === QuizState.START_SCREEN) {
    return <QuizStartScreen leagueQuiz={quizInstance.leagueQuiz} />
  } else if (!activeQuestion && !lastCompletedQuestion && !readableQuestion) {
    return <LiveWaitToStart />
  } else if (finished) {
    return (
      <LiveFinished
        instance={quizInstance}
        playAnimation={playCountAnimation}
      />
    )
  } else if (readableQuestion) {
    return (
      <LiveReadQuestionScreen
        key={readableQuestion.id}
        leagueQuiz={leagueQuiz}
        numCompletedQuestions={
          completedQuestions.length + (activeQuestion ? 1 : 0)
        }
        activeQuestion={readableQuestion}
      />
    )
  } else if (activeQuestion) {
    return (
      <LiveActiveQuestion
        key={activeQuestion.id}
        leagueQuiz={leagueQuiz}
        numCompletedQuestions={
          completedQuestions.length + (activeQuestion ? 1 : 0)
        }
        activeQuestion={activeQuestion}
        quizInstanceId={quizInstance.id}
        isLastQuestion={
          completedQuestions.length ===
          quizInstance.leagueQuiz.questionsCount - 1
        }
      />
    )
  } else if (lastCompletedQuestion) {
    return (
      <LiveLastCompletedQuestion
        leagueQuiz={leagueQuiz}
        completedQuestions={completedQuestions}
        lastCompletedQuestion={lastCompletedQuestion}
        allQuestionsAnswered={quizFinished}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
      />
    )
  }
  return null
}
