/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, ReactNode } from 'react'
import { FaCrown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { prettyLetterSpacing } from '../../styles/styles'
import { boxShadows } from '../../styles/theme'
import { UserAvatar } from '../Avatar/UserAvatar'
import { formatClubRole } from './clubUtils'
import { ClubRole } from './queries'

type Props = {
  username: string
  slug: string
  role: ClubRole
  avatarData?: string
  backgroundColor?: string
  isAdmin?: boolean
  adminControls?: ReactNode
  className?: string
  displayName?: string
}

export const PlayerElement: FC<Props> = ({
  username,
  slug,
  role,
  avatarData,
  isAdmin,
  backgroundColor,
  adminControls,
  displayName,
  className,
}) => {
  const t = useT()

  return (
    <Link
      to={`/profile/${slug}`}
      css={css`
        text-decoration: none;
        width: 100%;
      `}
    >
      <Flex
        horizontal="space-between"
        css={css`
          ${margin.top('tiny')}
          padding: 12px 20px;
          border-radius: 25px;
          background-color: ${backgroundColor ?? 'white'};
          box-shadow: ${boxShadows.default};
          height: 50px;
        `}
        className={className}
        gap="small"
      >
        <Flex vertical="center" gap="medium">
          <UserAvatar avatarData={avatarData} height={50} />
          <Flex gap={'tiny'} vertical="center" wrap>
            <TextNew size={18} weight={700} italic css={prettyLetterSpacing}>
              {displayName ?? username ?? '-'}
            </TextNew>
            <TextNew
              size={14}
              italic
              css={[
                prettyLetterSpacing,
                css`
                  margin-top: 3px;
                `,
              ]}
            >
              {formatClubRole(t, role)}
            </TextNew>
          </Flex>
        </Flex>

        <Flex vertical="center" gap="small">
          {isAdmin && <FaCrown size={24} />}

          {adminControls && <div>{adminControls}</div>}
        </Flex>
      </Flex>
    </Link>
  )
}
