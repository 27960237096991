/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { BiPlus } from 'react-icons/bi'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { ModalTrigger } from '../../components/modal/Modal'
import { useViewerQuery } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { CreateChallengeLeagueModal } from './CreateChallengeLeagueModal'
import UserLeaguesList from './UserLeaguesList'
import { useEffect, useState } from 'react'
import { useChallengeLeaguesQuery } from './queries'
import { ChallengeLeagueListComponent } from './components/ChallengeLeagueListComponent'

const Content = () => {
  const t = useT()

  const { data: viewerData } = useViewerQuery()

  const [userIsAdmin, setUserIsAdmin] = useState(false)

  const { data: leaguesData } = useChallengeLeaguesQuery({
    hideFollowing: true,
  })

  useEffect(() => {
    if (viewerData?.viewer && viewerData.viewer.isAdmin !== undefined) {
      setUserIsAdmin(viewerData.viewer.isAdmin)
    }
  }, [viewerData])

  const notFollowedLeagues = leaguesData?.challengeLeagues || []

  const CreateLeagueButton = () => {
    return (
      <ModalTrigger
        button={({ openModal }) => (
          <button
            role="button"
            aria-label={t('Create league')}
            onClick={openModal}
            css={[
              buttonReset,
              css`
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;

                border-radius: 8px;
                &:hover {
                  background-color: ${colors.grey200};
                }
              `,
            ]}
          >
            <BiPlus size={40} />
          </button>
        )}
        modal={({ closeModal }) => (
          <CreateChallengeLeagueModal closeModal={closeModal} />
        )}
      />
    )
  }

  return (
    <Flex column grow>
      <Header color="grey" />
      <PageContent>
        <Flex horizontal="space-between" vertical="center">
          <Heading level={1} looksLikeLevel={3} extraCondensed>
            {t('Leagues')}
          </Heading>
          {userIsAdmin && <CreateLeagueButton />}
        </Flex>
      </PageContent>

      <UserLeaguesList />

      {notFollowedLeagues.length > 0 && (
        <PageContent>
          <Spacer height={40} />
          <Heading
            level={2}
            looksLikeLevel={4}
            extraCondensed
            textAlign="center"
          >
            {t('Active leagues')}
          </Heading>
          {notFollowedLeagues.map((league) => (
            <ChallengeLeagueListComponent league={league} key={league.id} />
          ))}
        </PageContent>
      )}

      <Spacer height={80} />
    </Flex>
  )
}

export const LeaguesPage = () => {
  const t = useT()
  return (
    <Layout backgroundColor={colors.grey100} title={`fcQuiz | ${t('Leagues')}`}>
      <Content />
    </Layout>
  )
}
