/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { colors, hexOpacity } from '../../styles/theme'

export const AvatarPromoBanner = () => {
  const t = useT()
  return (
    <Link to={'/profile/avatar'} aria-label={t(`Design your own avatar`)}>
      <Flex
        css={css`
          background-color: ${hexOpacity(colors.black, 0.6)};
          border: 2px solid #8ee35a;
          border-radius: 10px;
          width: 100%;
          margin-bottom: 30px;
          position: relative;
        `}
        horizontal="space-evenly"
        vertical="flex-end"
      >
        <Flex
          css={css`
            position: absolute;
            background-color: #8ee35a;
            z-index: 1;
            border-radius: 10px;
            padding: 5px 20px;
            margin-bottom: -20px;
          `}
        >
          <Heading
            level={1}
            looksLikeLevel={4}
            css={css`
              font-size: 15px;
              text-transform: uppercase;
            `}
          >
            {t(`Design your own avatar`)}
          </Heading>
        </Flex>
        <img
          css={css`
            @media (min-width: 599px) {
              scale: 2;
            }
            align-self: center;
          `}
          src="/AvatarArrow.svg"
        />
        <img
          css={css`
            padding-top: 10px;
            width: 75%;
          `}
          src="/AvatarBanner.svg"
        />
      </Flex>
    </Link>
  )
}
