/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useCallback } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import { Spacer } from '../../../styles/margin'
import { LiveQuizHostInstance, useStartLiveQuizMutation } from '../queries'
import { HostModeHeader } from './HostModeHeader'

export const PendingLiveQuiz = ({
  instance,
}: {
  instance: LiveQuizHostInstance
}) => {
  const [startQuizMutation] = useStartLiveQuizMutation()

  const startQuiz = useCallback(() => {
    startQuizMutation({
      variables: {
        input: {
          instanceId: instance.id,
        },
      },
    })
  }, [instance.id, startQuizMutation])

  return (
    <Flex column grow>
      <PageContent gap={'medium'}>
        <HostModeHeader />
        <TextNew
          italic
          condensed
          textAlign="center"
          css={[
            css`
              font-size: 45px;
              font-weight: 500;
              line-height: 53px;
            `,
          ]}
        >
          Open Quiz for participants
        </TextNew>
        <Spacer height={'large'} />
        <TextNew
          italic
          condensed
          textAlign="center"
          css={[
            css`
              font-size: 30px;
              font-weight: 500;
              line-height: 1;
            `,
          ]}
        >
          When you open the Quiz, participants can join via QR code or link
        </TextNew>
        <Spacer height={'large'} />
        <PrimaryButton onClick={startQuiz}>Start quiz</PrimaryButton>
      </PageContent>
    </Flex>
  )
}
