import { ref, uploadBytesResumable, UploadTask } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { storage } from './firebase'

function unslash(str: string) {
  return str.replace(/^\/+|\/+$/g, '')
}

function unspace(str: string) {
  return str.replace(/ /g, '_')
}

export function uploadImageToFirebase(path: string, file: File) {
  // filenames cannot contain spaces

  const fullPath = `${unslash(path)}/${uuidv4()}_${unspace(file.name)}`
  const fileRef = ref(storage, fullPath)

  const uploadTask = uploadBytesResumable(fileRef, file)

  async function getImageUri() {
    await uploadTask
    return fileRef.toString()
  }

  return {
    uploadTask,
    getImageUri,
  }
}

export function cancelUploadTask(uploadTask: UploadTask) {
  if (!uploadTask) {
    return
  }

  try {
    uploadTask.cancel()
  } catch (error) {
    console.log(error)
  }
}

export function getProgress(
  uploadTask: UploadTask,
  progressCallback: (progress: number) => void
) {
  uploadTask.on('state_changed', (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    progressCallback(progress)
  })
}
