/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { clamp } from 'lodash'
import { FC, useEffect } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { AlternativeButton } from '../../../components/leagueQuiz/AlternativeButton'
import { QuizStatusComponent } from '../../../components/leagueQuiz/QuizStatusComponent'
import { Text } from '../../../components/Text'
import { TextNew } from '../../../components/TextNew'
import { AnalyticsEventType, trackEvent } from '../../../lib/analytics'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useLeagueQuizContext } from '../../LeagueQuiz/LeagueQuizContext'
import {
  CompletedQuestion,
  LeagueQuiz as LeagueQuizType,
} from '../../LeagueQuiz/queries'

export const LiveLastCompletedQuestion: FC<{
  leagueQuiz: LeagueQuizType
  completedQuestions: CompletedQuestion[]
  lastCompletedQuestion: CompletedQuestion
  allQuestionsAnswered: boolean
  clubLeagueSubscriptionId?: string
}> = ({
  leagueQuiz,
  completedQuestions,
  lastCompletedQuestion,
  allQuestionsAnswered,
  clubLeagueSubscriptionId,
}) => {
  const { campaignHeaderHeight } = useLeagueQuizContext()

  const t = useT()

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId: leagueQuiz.league.id,
        leagueQuizId: leagueQuiz.id,
        isLive: true,
        clubLeagueSubscriptionId,
      })
    }
  }, [allQuestionsAnswered, leagueQuiz.id, leagueQuiz.league.id])

  const textColor = colors.black

  const userHasAnswered = lastCompletedQuestion.alternatives.some(
    (alternative) => alternative.selectedAlternative
  )

  // clamp to 0-10
  const timeBonus = clamp(lastCompletedQuestion.secondsLeft - 5, 0, 10)

  return (
    <PageContent
      css={css`
        padding-top: 24px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Spacer height={'medium'} />
        <QuizStatusComponent
          currentQuestion={completedQuestions.length}
          totalQuestions={leagueQuiz.questionsCount}
          points={lastCompletedQuestion.points}
        />
        <Spacer height={'small'} />
        <Text
          key={lastCompletedQuestion.text}
          color={textColor}
          css={margin.vertical('medium')}
          textAlign="center"
          size="medium"
        >
          {lastCompletedQuestion.text}
        </Text>

        {!userHasAnswered && (
          <Flex
            horizontal="center"
            css={css`
              padding: 16px;
            `}
          >
            <TextNew
              italic
              color={colors.red400}
              css={css`
                font-size: 16px;
                letter-spacing: 0.1em;
              `}
            >
              {t(`You didn't answer this question`)}
            </TextNew>
          </Flex>
        )}

        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <AlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.selectedAlternative}
              correct={alternative.isCorrectAlternative}
              showGlobalStats={false}
              isLive={true}
              confettiParticleCount={30 + 30 * timeBonus}
            >
              {alternative.text}
            </AlternativeButton>
          ))}
        </Flex>
      </Flex>
    </PageContent>
  )
}
