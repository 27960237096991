/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Link } from '../../components/Link'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext, useViewerQuery, Viewer } from '../../lib/AuthContext'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import { AnalyticsTimeRange, DatePicker } from '../Admin/AdminAnalyticsPage'
import { LineChartV2 } from '../Admin/LineChartComponentV2'
import { CampaignMinimal, useAdvertiserByIdQuery } from '../Admin/queries'
import { ShimmerComponent } from '../Home/ShimmerComponent'
import { useGetEventsForCampaignQuery } from './queries'

const SkeletonLoader = () => {
  return (
    <Flex
      column
      gap={'medium'}
      css={css`
        width: 100%;
      `}
    >
      <Flex
        column
        grow
        css={css`
          width: 100%;
        `}
      >
        <ShimmerComponent
          backgroundColor={colors.grey200}
          css={css`
            border-radius: 10px;
          `}
        />

        <Spacer height={'large'} />

        <ShimmerComponent
          backgroundColor={colors.grey200}
          css={css`
            border-radius: 10px;
          `}
        />
      </Flex>
      <Flex
        grow
        gap={'medium'}
        css={css`
          width: 100%;
        `}
      >
        <ShimmerComponent
          backgroundColor={colors.grey200}
          css={css`
            border-radius: 10px;
            width: 100%;
          `}
        />

        <ShimmerComponent
          backgroundColor={colors.grey200}
          css={css`
            border-radius: 10px;
            width: 100%;
          `}
        />
      </Flex>
    </Flex>
  )
}

export const CampaignMetaData = ({
  campaign,
}: {
  campaign: CampaignMinimal
}) => {
  const formatDate = useFormatDate()
  return (
    <Flex
      column
      gap={'medium'}
      css={css`
        background-color: ${colors.grey100};
        border-radius: 10px;
        padding: 8px;
        width: 100%;
      `}
    >
      {campaign.availableFrom && campaign.availableTo && (
        <Flex vertical={'center'} gap={'small'}>
          <Flex
            css={css`
              width: 20%;
              min-width: 100px;
              max-width: 150px;
            `}
          >
            <TextNew>Period:</TextNew>
          </Flex>

          <TextNew>
            {formatDate(new Date(campaign.availableFrom), 'full-date')} -{' '}
            {formatDate(new Date(campaign.availableTo), 'full-date')}
          </TextNew>

          {/* Active/inactive indicator */}
          <TextNew
            color={
              new Date(campaign.availableFrom) < new Date() &&
              new Date(campaign.availableTo) > new Date()
                ? 'green'
                : 'red'
            }
          >
            {new Date(campaign.availableFrom) < new Date() &&
            new Date(campaign.availableTo) > new Date()
              ? 'Active'
              : 'Inactive'}
          </TextNew>
        </Flex>
      )}

      <Flex vertical={'center'} gap={'small'}>
        <Flex
          css={css`
            width: 20%;
            min-width: 100px;
            max-width: 150px;
          `}
        >
          <TextNew>Available in:</TextNew>
        </Flex>

        {campaign.availableInRegions ? (
          <Flex
            gap={'small'}
            css={css`
              overflow-x: auto;
            `}
          >
            {campaign.availableInRegions.map((region) => {
              let code = region.toString()
              switch (region) {
                case 'en':
                  code = 'gb'
                  break
                case 'ar':
                  code = 'sa'
                  break
                case 'ja':
                  code = 'jp'
                  break
                case 'sv':
                  code = 'se'
                  break
                case 'da':
                  code = 'dk'
                  break
                default:
                  code = region.toString()
              }
              return (
                <Flex
                  key={region}
                  horizontal={'center'}
                  vertical={'center'}
                  css={css`
                    border-radius: 50%;
                    overflow: hidden;
                    min-width: 24px;
                  `}
                >
                  <img
                    src={`https://hatscripts.github.io/circle-flags/flags/${code}.svg`}
                    height={24}
                    width={24}
                    alt={region}
                  />
                </Flex>
              )
            })}
          </Flex>
        ) : (
          <TextNew>All languages </TextNew>
        )}
      </Flex>

      {campaign.campaignLink && (
        <Flex
          vertical={'center'}
          gap={'small'}
          css={css`
            max-width: 100%;
          `}
        >
          <Flex
            css={css`
              width: 20%;
              min-width: 100px;
              max-width: 150px;
            `}
          >
            <TextNew>Campaign link:</TextNew>
          </Flex>

          <Link
            to={campaign.campaignLink}
            css={css`
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {campaign.campaignLink}
          </Link>
        </Flex>
      )}
    </Flex>
  )
}

export const Campaigns = ({
  campaigns,
  advertiserId,
}: {
  campaigns: CampaignMinimal[]
  advertiserId: string
}) => {
  if (campaigns.length === 0) {
    return (
      <PageContent>
        <TextNew>No campaigns</TextNew>
      </PageContent>
    )
  }

  const [selectedCampaign, setSelectedCampaign] = useState<CampaignMinimal>(
    campaigns[0]
  )

  const dateSevenDaysAgo = new Date()
  dateSevenDaysAgo.setDate(dateSevenDaysAgo.getDate() - 7)

  const [selectedTimeRange, setSelectedTimeRange] =
    useState<AnalyticsTimeRange>({
      startDate: selectedCampaign.availableFrom
        ? new Date(selectedCampaign.availableFrom)
        : dateSevenDaysAgo,
      endDate: selectedCampaign.availableTo
        ? new Date(selectedCampaign.availableTo)
        : new Date(),
    })

  useEffect(() => {
    setSelectedTimeRange({
      startDate: selectedCampaign.availableFrom
        ? new Date(selectedCampaign.availableFrom)
        : dateSevenDaysAgo,
      endDate:
        selectedCampaign.availableTo &&
        new Date(selectedCampaign.availableTo) < new Date()
          ? new Date(selectedCampaign.availableTo)
          : new Date(),
    })
  }, [selectedCampaign])

  const { data: events, loading } = useGetEventsForCampaignQuery({
    campaignId: selectedCampaign.id,
    advertiserId,
    startDate: selectedTimeRange.startDate.toISOString(),
    endDate: selectedTimeRange.endDate.toISOString(),
    groupBy: ['eventType', 'leagueId', 'quizId'],
  })

  const leagueEvents = events
    ?.filter((e) => e.groupBy === 'leagueId' && e.total > 0)
    .slice(0, 20)
  const quizEvents = events
    ?.filter((e) => e.groupBy === 'quizId' && e.total > 0)
    .slice(0, 20)

  return (
    <PageContent
      css={css`
        position: relative;
      `}
    >
      <Flex
        column={false}
        horizontal={'space-between'}
        vertical={'flex-end'}
        css={css`
          width: 100%;
          overflow-x: auto;
          position: relative;
          &:first-child {
            margin: 0;
          }
          &::after {
            content: '';
            display: block;
            padding-left: 10px;
          }
          // Fade out the overflowed content

          &::-webkit-scrollbar {
            display: none;
          }

          // Hide scrollbar
          scrollbar-width: none;
        `}
      >
        {campaigns.map((campaign) => (
          <Flex
            key={campaign.id}
            css={css`
              min-width: fit-content;
              padding: 16px;
              border-radius: 4px 4px 0 0;
              border: 1px solid
                ${selectedCampaign.id === campaign.id
                  ? colors.grey300
                  : colors.grey200};
              border-bottom: ${selectedCampaign.id === campaign.id
                ? 0
                : '1px solid ' + colors.grey300};
              background-color: ${selectedCampaign.id === campaign.id
                ? selectedCampaign.backgroundColor
                : colors.white};
              cursor: pointer;

              &:hover {
                background-color: ${selectedCampaign.id !== campaign.id &&
                colors.grey100};
              }
            `}
            onClick={() => setSelectedCampaign(campaign)}
          >
            <TextNew color={'inherit'}>{campaign.name}</TextNew>
          </Flex>
        ))}

        <div
          css={css`
            border-bottom: 1px solid ${colors.grey300};
            width: 100%;
            height: 1px;
          `}
        />
      </Flex>

      {/* Campaign details and stats */}
      <Flex
        column
        horizontal={'center'}
        vertical={'flex-start'}
        gap={'medium'}
        css={css`
          width: 100%;
          padding: 16px;
          border-radius: 0 0 4px 4px;
          border: 1px solid ${colors.grey300};
          border-top: 0;
          background-color: ${selectedCampaign.backgroundColor};
        `}
      >
        <CampaignMetaData campaign={selectedCampaign} />
        <Spacer height={'medium'} />

        <DatePicker
          selectedTimeRange={selectedTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
        />

        {loading ? (
          <SkeletonLoader />
        ) : (
          <Flex
            column
            gap={'medium'}
            css={css`
              width: 100%;
            `}
          >
            <LineChartV2
              datasets={events.filter((e) => e.groupBy === 'eventType')}
              timeRange={selectedTimeRange}
              groupBy={'campaignId'}
              setGroupBy={() => {}}
              analyticsLabels={[]}
              editable={false}
              filterBy={[]}
            />
            <Flex
              gap={'medium'}
              css={css`
                width: 100%;
                @media (max-width: 768px) {
                  flex-direction: column;
                }
              `}
            >
              {/* List of leagues with most shows */}
              <Flex
                column
                gap={'small'}
                css={css`
                  background-color: ${colors.grey100};
                  border-radius: 10px;
                  padding: 8px;
                  width: 100%;
                `}
              >
                <Flex
                  horizontal="space-between"
                  css={css`
                    padding: 8px;
                  `}
                >
                  <TextNew size={'huge'}>Events by series:</TextNew>
                  <TextNew size={'huge'} strong>
                    {leagueEvents.reduce(
                      (acc, dataset) => acc + dataset.total,
                      0
                    )}
                  </TextNew>
                </Flex>
                <Flex
                  column
                  gap={'small'}
                  css={css`
                    overflow-y: auto;
                    max-height: 500px;
                  `}
                >
                  {leagueEvents.map((league) => (
                    <Flex
                      key={league.label}
                      horizontal={'space-between'}
                      css={css`
                        border: 1px solid ${colors.grey300};
                        border-radius: 4px;
                        padding: 8px 16px;
                      `}
                    >
                      <TextNew>{league.label}</TextNew>
                      <Spacer width={16} />
                      <TextNew>{league.total}</TextNew>
                    </Flex>
                  ))}
                </Flex>
              </Flex>

              {/* List of quizzes with most shows */}
              <Flex
                column
                gap={'small'}
                css={css`
                  background-color: ${colors.grey100};
                  border-radius: 10px;
                  padding: 8px;
                  width: 100%;
                `}
              >
                <Flex
                  horizontal="space-between"
                  css={css`
                    padding: 8px;
                  `}
                >
                  <TextNew size={'huge'}>Events by quizzes:</TextNew>
                  <TextNew size={'huge'} strong>
                    {quizEvents.reduce(
                      (acc, dataset) => acc + dataset.total,
                      0
                    )}
                  </TextNew>
                </Flex>
                <Flex
                  column
                  gap={'small'}
                  css={css`
                    overflow-y: auto;
                    max-height: 500px;
                  `}
                >
                  {quizEvents.map((quiz) => (
                    <Flex
                      key={quiz.label}
                      horizontal={'space-between'}
                      css={css`
                        border: 1px solid ${colors.grey300};
                        border-radius: 4px;
                        padding: 8px 16px;
                      `}
                    >
                      <TextNew>{quiz.label}</TextNew>
                      <Spacer width={16} />
                      <TextNew>{quiz.total}</TextNew>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Spacer height={32} />
    </PageContent>
  )
}

export const Content = ({ advertiserId }: { advertiserId: string }) => {
  const navigate = useNavigate()
  const { data: viewerData, loading: viewerLoading } = useViewerQuery()
  const [viewer, setViewer] = useState<Viewer | null>(null)
  const [campaigns, setCampaigns] = useState<CampaignMinimal[]>([])

  const { data, loading } = useAdvertiserByIdQuery({
    advertiserId,
  })

  useEffect(() => {
    if (viewerData?.viewer) {
      setViewer(viewerData.viewer)
    }
  }, [viewerData])

  {
    /* If the user is not a member or not admin, navigate them away */
  }
  useEffect(() => {
    if (loading || viewerLoading || !viewer || !data) return
    if (
      !data?.advertiser.members?.some((m) => m.id === viewer?.id) &&
      !viewer?.isAdmin
    ) {
      console.log('Not a member or admin')
      navigate('/')
    }
  }, [data, navigate, loading, viewer])

  useEffect(() => {
    if (data?.advertiser?.campaigns) {
      setCampaigns(data.advertiser.campaigns)
    }
  }, [data])

  if (!viewer) {
    return null
  }

  return (
    <Flex column>
      <PageContent>
        <Header color={'green'} />
        <HeadingNew level={1} looksLikeLevel={3}>
          Advertiser Dashboard: {data?.advertiser.name}
        </HeadingNew>
        <Spacer height={'small'} />
        <TextNew>
          This is the advertiser dashboard. Here you see the performance of your
          campaigns.
        </TextNew>

        <Spacer height={32} />
      </PageContent>

      <Campaigns campaigns={campaigns} advertiserId={advertiserId} />
    </Flex>
  )
}

export const AdvertiserDashboardPage = () => {
  const { advertiserId } = useParams()

  const { authUser } = useAuthContext()

  if (!advertiserId) {
    console.error('No advertiserId provided')
    return null
  }

  if (!authUser) {
    console.error('No viewer data')
    return null
  }

  return (
    <Layout>
      <Content advertiserId={advertiserId} />
      <Footer />
    </Layout>
  )
}
