/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'

export const AdminAccessControlComponent = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { loading, authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })

  if (loading || viewerQuery.loading) {
    return <Loader center />
  }

  if (
    !viewerQuery.data?.viewer ||
    authUser?.isAnonymous ||
    !viewerQuery.data.viewer.isAdmin
  ) {
    return (
      <Flex column grow vertical="center">
        <TextNew textAlign="center">Not authorized</TextNew>
      </Flex>
    )
  }

  return (
    <Flex column grow>
      {children}
    </Flex>
  )
}
