import Part from './Part'
import { height, width } from './Avatar'
import { armsStyles } from './options/arms/_index'
import { chestStyles } from './options/chest/_index'
import { collarStyles } from './options/collar/_index'
import { shouldersStyles } from './options/shoulders/_index'
import { sleevesStyles } from './options/sleeves/_index'
import { facePartToColors, shirtColors } from '../../common/avatarConfig'
import {
  facePartToColorsLegacy,
  partToStylesLegacy,
  shirtColorsLegacy,
} from '../../common/avatarConfigLegacy'
import { hairStyles } from './viewbox/hair/_index'
import { mouthStyles } from './viewbox/face/mouth/_index'
import { browsStyles } from './viewbox/face/brows/_index'
import { eyesStyles } from './viewbox/face/eyes/_index'
import { skinStyles } from './viewbox/skin/_index'
export function isValidHexaCode(str: string) {
  if (str[0] != '#') return false

  if (!(str.length == 4 || str.length == 7)) return false

  for (let i = 1; i < str.length; i++)
    if (
      !(
        (str[i].charCodeAt(0) <= '0'.charCodeAt(0) &&
          str[i].charCodeAt(0) <= 9) ||
        (str[i].charCodeAt(0) >= 'a'.charCodeAt(0) &&
          str[i].charCodeAt(0) <= 'f'.charCodeAt(0)) ||
        str[i].charCodeAt(0) >= 'A'.charCodeAt(0) ||
        str[i].charCodeAt(0) <= 'F'.charCodeAt(0)
      )
    )
      return false

  return true
}

export type AvatarParts = {
  Hair: Part
  Brows: Part
  Eyes: Part
  Mouth: Part
  Skin: Part
  Chest: Part
  Arms: Part
  Shoulders: Part
  Collar: Part
  Sleeves: Part
}

export function makeAvatarParts(avatarData: any) {
  const {
    Hair: HairData,
    Brows: BrowsData,
    Eyes: EyesData,
    Mouth: MouthData,
    Skin: SkinData,
    Chest: ChestData,
    Arms: ArmsData,
    Shoulders: ShouldersData,
    Collar: CollarData,
    Sleeves: SleevesData,
  } = avatarData

  const parts = {
    Hair: new Part({
      name: 'Hair',
      //Update model/user/avatarData to include new colors (excluding new premium ones)
      selectableStylesLegacy: partToStylesLegacy['Hair'],
      selectableColors: facePartToColors['Hair'],
      selectableColorsLegacy: facePartToColorsLegacy['Hair'],
      borders: [0, 30, width, height / 2],
      ...HairData,
    }),
    Brows: new Part({
      name: 'Brows',
      selectableStylesLegacy: partToStylesLegacy['Brows'],
      selectableColors: facePartToColors['Brows'],
      selectableColorsLegacy: facePartToColorsLegacy['Brows'],
      borders: [-30, 100, width / 6, 33],
      bgasfg: true,
      ...BrowsData,
    }),
    Eyes: new Part({
      name: 'Eyes',
      selectableStylesLegacy: partToStylesLegacy['Eyes'],
      selectableColors: facePartToColors['Eyes'],
      selectableColorsLegacy: facePartToColorsLegacy['Eyes'],
      borders: [-30, 120, width / 4, 50],
      bgasfg: true,
      ...EyesData,
    }),
    Mouth: new Part({
      name: 'Mouth',
      selectableStylesLegacy: partToStylesLegacy['Mouth'],
      selectableColors: facePartToColors['Mouth'],
      selectableColorsLegacy: facePartToColorsLegacy['Mouth'],
      ...MouthData,
      borders: [0, 165, width / 3, 65],
    }),
    Skin: new Part({
      name: 'Skin',
      selectableStylesLegacy: partToStylesLegacy['Skin'],
      selectableColors: facePartToColors['Skin'],
      selectableColorsLegacy: facePartToColorsLegacy['Skin'],
      ...SkinData,
    }),
    Chest: new Part({
      name: 'Chest',
      selectableStylesLegacy: partToStylesLegacy['Chest'],
      selectableColors: shirtColors,
      selectableColorsLegacy: shirtColorsLegacy,
      borders: [0, 110, width, height * 0.7],
      ...ChestData,
    }),
    Arms: new Part({
      name: 'Arms',
      selectableStylesLegacy: partToStylesLegacy['Arms'],
      selectableColors: shirtColors,
      selectableColorsLegacy: shirtColorsLegacy,
      //baseRotation: 15,
      ...ArmsData,
    }),
    Shoulders: new Part({
      name: 'Shoulders',
      selectableStylesLegacy: partToStylesLegacy['Shoulders'],
      selectableColors: shirtColors,
      selectableColorsLegacy: shirtColorsLegacy,
      //baseRotation: 60,
      ...ShouldersData,
    }),
    Collar: new Part({
      name: 'Collar',
      selectableStylesLegacy: partToStylesLegacy['Collar'],
      selectableColors: shirtColors,
      selectableColorsLegacy: shirtColorsLegacy,
      ...CollarData,
    }),
    Sleeves: new Part({
      name: 'Sleeves',
      selectableStylesLegacy: partToStylesLegacy['Sleeves'],
      selectableColors: shirtColors,
      selectableColorsLegacy: shirtColorsLegacy,
      //baseRotation: 15,
      ...SleevesData,
    }),
  }
  return parts
}

export function makeAvatarData(avatarParts: { [key: string]: Part }) {
  const parts = Object.keys(avatarParts)
  const partToData = Object.fromEntries(
    parts.map((it) => [it, avatarParts[it].data])
  )
  return JSON.stringify(partToData)
}

function rgbToHex(rgb: any) {
  return (
    '#' +
    ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1)
  )
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    throw new Error('Could not parse hex: ' + hex)
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }
}

export function mixColors(colorsHex: string[]) {
  let rgbs = []
  for (let color of colorsHex) {
    rgbs.push(hexToRgb(color))
  }
  let avgColor = { r: 0, g: 0, b: 0 }
  for (let color of rgbs) {
    avgColor.r += color.r
    avgColor.g += color.g
    avgColor.b += color.b
  }
  avgColor.r /= rgbs.length
  avgColor.g /= rgbs.length
  avgColor.b /= rgbs.length
  return rgbToHex(avgColor)
}

export type FacePart = 'Hair' | 'Mouth' | 'Brows' | 'Eyes' | 'Skin'
export const FaceParts = ['Hair', 'Mouth', 'Brows', 'Eyes', 'Skin']
export type ShirtPart = 'Chest' | 'Arms' | 'Shoulders' | 'Collar' | 'Sleeves'
export const ShirtParts = ['Chest', 'Arms', 'Shoulders', 'Collar', 'Sleeves']

export function getShirtStyles(part: Part) {
  const partName = part.name as ShirtPart
  const shirtStyles = {
    Chest: chestStyles,
    Arms: armsStyles,
    Shoulders: shouldersStyles,
    Collar: collarStyles,
    Sleeves: sleevesStyles,
  }
  const shirtStyle = shirtStyles[partName]
  if (!shirtStyle) {
    throw new Error('Unintended usage of getShirtStyle')
  }
  return Object.entries(shirtStyle).map(([key, value]) => ({
    id: key,
    icon: value.icon,
    premium: value.premium,

    // Color pattern, used for shirt styles
    usedColors: value.usedColors,
    rotation: value.rotation,
    width: value.width,
    centerIndex: value.centerIndex,
    offset: value.offset,
  }))
}

export function getStyle(part: Part) {
  return getSelectableStyles(part, true).find((it) => it.id == part.style)!
}

export function baseStyleIconShirt(part: Part) {
  const partToCategoryStyle: { [key: string]: string } = {
    Chest: 'ChestA',
    Collar: 'CollarA',
    Sleeves: 'SleevesA',
    Arms: 'ArmsA',
    Shoulders: 'ShouldersA',
  }
  const baseStyle = partToCategoryStyle[part.name]
  return getSelectableStyles(part, true).find((it) => it.id == baseStyle)!.icon
}

export const baseStyleIconFace = (parts: any, withHair: boolean = false) => ({
  ...parts,
  Hair: { ...parts['Hair'], style: withHair ? 'HairF' : 'Hair0' },
  Brows: { ...parts['Brows'], style: 'BrowsG' },
  Eyes: { ...parts['Eyes'], style: 'EyesC' },
  Mouth: { ...parts['Mouth'], style: 'MouthB' },
})
// Only returns selectable options, therefore no skin or collar
// (collar is a special case since it is selected in the shirt section)
function getFaceStyles(part: Part) {
  const partName = part.name as FacePart
  const faceStyles = {
    Hair: hairStyles,
    Mouth: mouthStyles,
    Brows: browsStyles,
    Eyes: eyesStyles,
    Skin: skinStyles,
  }
  const faceStyle = faceStyles[partName]
  if (!faceStyle) {
    throw new Error('Unintended usage of getFaceStyle')
  }
  return Object.entries(faceStyle).map(([key, value]) => ({
    id: key,
    icon: value.svg,
    premium: value.premium,

    // Color pattern, used for shirt styles
    usedColors: 1,
    rotation: 0,
    width: 15,
    centerIndex: undefined,
    offset: 0,
  }))
}

type Style = {
  id: string
  icon: React.SVGProps<SVGSVGElement>
  usedColors: number
  rotation: number
  width: number
  centerIndex?: number
  offset: number
  premium: boolean
}

export function getSelectableStyles(
  part: Part,
  includePremiums: Boolean
): Style[] {
  if (ShirtParts.includes(part.name)) {
    return getShirtStyles(part).filter((it) => includePremiums || !it.premium)
  } else {
    return getFaceStyles(part).filter((it) => includePremiums || !it.premium)
  }
}

export const hasDefinedAvatar = (avatarData?: string) => {
  if (!avatarData) {
    return false
  }
  const json = JSON.parse(avatarData)

  // if a user has no avatar, they get a placeholder by default
  // This placeholder is marked with IsBase=True
  const isBase = json.IsBase

  return !isBase
}
