/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { IconButton } from '../../../components/Button'
import { FcQuizLogo } from '../../../components/icons'
import { Cross } from '../../../components/icons/Cross'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { PageContent } from '../../../components/layout/PageContent'
import { Link } from '../../../components/Link'
import { Loader } from '../../../components/Loader'
import { TextNew } from '../../../components/TextNew'
import { useViewerQuery } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset, prettyLetterSpacing } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { useClubProfileQuery } from '../queries'
import { ClubStatsSubPage } from './ClubStatsSubPage'
import { LiveQuizSubPage } from './LiveQuizSubPage'
import { MembersSubPage } from './MembersSubPage'
import { ProfileSubPage } from './ProfileSubPage'
import { QuizzesSubPage } from './QuizzesSubPage'

export type EditClubPageType =
  | 'profile'
  | 'quizzes'
  | 'members'
  | 'live'
  | 'stats'

export const EditNavItem = ({
  title,
  onClick,
  selected,
  className,
  textColor,
}: {
  title: string
  onClick: () => void
  selected: boolean
  className?: string
  textColor?: string
}) => {
  return (
    <button
      onClick={onClick}
      css={[
        buttonReset,
        css`
          background-color: ${selected ? colors.black : colors._grey450};
          border-radius: 10px 10px 0 0;
          height: 48px;
          width: 124px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 700px) {
            width: 80px;
          }
          @media (max-width: 480px) {
            width: 60px;
          }
        `,
      ]}
      className={className}
    >
      <TextNew
        condensed
        strong
        color={textColor || 'black'}
        css={[
          prettyLetterSpacing,
          css`
            font-size: 30px;
            @media (max-width: 700px) {
              font-size: 22px;
            }
            @media (max-width: 480px) {
              font-size: 16px;
            }
          `,
        ]}
      >
        {title}
      </TextNew>
    </button>
  )
}

const Content = () => {
  const slug = useParams().slug!
  const query = useClubProfileQuery(slug)
  const club = query.data?.club

  const t = useT()

  const [currentPage, setCurrentPage] = useState<EditClubPageType>('profile')

  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const viewerQuery = useViewerQuery()
  const viewer = viewerQuery.data?.viewer

  const navigate = useNavigate()

  const viewerHasAccess = club?.viewerCanEdit
  useEffect(() => {
    if (page) {
      setCurrentPage(page as EditClubPageType)
    }
  }, [])

  useEffect(() => {
    //Unverified clubs only have access to profile

    setSearchParams({ page: currentPage })
  }, [currentPage])

  if (query.loading) {
    return <Loader center variant="yellow" />
  }

  if (!club) {
    return null
  }

  if (!viewerHasAccess) {
    return <Navigate to="/404" replace />
  }

  //Unverified clubs only have access to profile
  return (
    <PageContent
      column
      css={css`
        height: 100vh;
      `}
    >
      <Flex
        horizontal="space-between"
        vertical="center"
        css={css`
          padding: 16px 0;

          width: 100%;
          margin: 0 auto;
        `}
      >
        <Link to="/" underline={false}>
          <FcQuizLogo color="white" height={25} width={50} />
        </Link>
        <IconButton
          onClick={() => navigate(`/clubs/${club.slug}`, { replace: true })}
          text={t('Close')}
          icon={<Cross />}
          iconBottomMargin={0}
          rounded
          backgroundColor={colors.grey400}
        />
      </Flex>
      <Flex
        column
        css={[
          css`
            padding-bottom: 100px;
            width: 100%;

            margin: 0 auto;
          `,
        ]}
      >
        <Flex wrap gap="tiny">
          <EditNavItem
            title={t('Profile')}
            selected={currentPage === 'profile'}
            onClick={() => setCurrentPage('profile')}
            textColor="white"
          />
          <EditNavItem
            title={t('Members')}
            selected={currentPage === 'members'}
            onClick={() => setCurrentPage('members')}
            textColor="white"
          />

          <EditNavItem
            title={t('Quizzes')}
            selected={currentPage === 'quizzes'}
            onClick={() => setCurrentPage('quizzes')}
            textColor="white"
          />
          {(club.verified || viewer?.isAdmin) && (
            <>
              <EditNavItem
                title="Live"
                selected={currentPage === 'live'}
                onClick={() => setCurrentPage('live')}
                textColor="white"
              />

              <EditNavItem
                title={t('Stats')}
                selected={currentPage === 'stats'}
                onClick={() => setCurrentPage('stats')}
                textColor="white"
              />
            </>
          )}
        </Flex>

        <Flex
          column
          css={css`
            padding: 40px 16px;
            background-color: ${colors.black};
            border-radius: 0 10px 10px 10px;
            min-height: 800px;
          `}
        >
          {currentPage === 'profile' && <ProfileSubPage club={club} />}
          {currentPage === 'quizzes' && <QuizzesSubPage club={club} />}
          {currentPage === 'members' && <MembersSubPage club={club} />}
          {currentPage === 'live' && (
            <LiveQuizSubPage club={club} setCurrentPage={setCurrentPage} />
          )}
          {currentPage === 'stats' && <ClubStatsSubPage club={club} />}
        </Flex>
      </Flex>
    </PageContent>
  )
}

export const EditClubPageV2 = () => {
  const t = useT()

  return (
    <Layout title={`fcQuiz | ${t('Clubs')}`} hideNavbar hideHeaderPadding>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
          background: linear-gradient(
            180deg,
            ${colors._grey450} 0%,
            ${colors._grey480} 10%
          );

          z-index: -1;
        `}
      />
      <Content />
    </Layout>
  )
}
