/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { colors } from '../../../styles/theme'
import { getTextColorForClub } from '../../Challenge/DisplayPage/util'
import { LiveQuizDisplayInstance } from '../../LiveQuiz/queries'
import { QuestionHeader } from './QuestionHeader'

export const ActiveDisplayLiveQuiz = ({
  instance,
  isLandscape,
}: {
  instance: LiveQuizDisplayInstance
  isLandscape: boolean
}) => {
  const club = instance.club

  const textColor = club
    ? getTextColorForClub({ slug: club.slug, textColor: club.textColor })
    : colors.black

  const numCompletedQuestions = instance.completedQuestions?.length || 0

  const activeQuestion = instance.activeQuestion

  if (!activeQuestion) {
    return null
  }

  return (
    <Flex
      column
      vertical="space-between"
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <QuestionHeader
        club={club}
        locale={instance.locale}
        questionNumber={numCompletedQuestions + 1}
      />

      <Flex
        horizontal="center"
        column
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: ${isLandscape ? '10vw' : '5vw'};
        `}
      >
        <div
          css={css`
            height: 5vw;
          `}
        />
        <TextNew
          italic
          condensed
          shadow
          color={textColor}
          textAlign="center"
          css={css`
            font-size: 7vw;
            font-weight: 600;
          `}
        >
          {activeQuestion.text}
        </TextNew>

        <div
          css={css`
            height: 10vw;
          `}
        />

        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2vw;
            width: 100%;
          `}
        >
          {activeQuestion.alternatives.map((alternative) => {
            return (
              <Flex
                horizontal="center"
                key={alternative.id}
                css={css`
                  flex: 1;
                  border: 0.2vw solid ${textColor};
                  padding: 1vw 2vw;
                  border-radius: 2vw;
                `}
              >
                <TextNew
                  italic
                  condensed
                  shadow
                  color={textColor}
                  textAlign="center"
                  css={css`
                    font-size: 3vw;
                    font-weight: 600;
                  `}
                >
                  {alternative.text}
                </TextNew>
              </Flex>
            )
          })}
        </div>
      </Flex>
    </Flex>
  )
}
