/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { SecondaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Loader } from '../../../components/Loader'
import { ModalTrigger } from '../../../components/modal/Modal'
import { Text } from '../../../components/Text'
import { TextNew } from '../../../components/TextNew'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset, flexCenter } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { PlayerElement } from '../PlayerElement'
import { ClubRole, useClubWithMembersQuery } from '../queries'
import { EditMemberModal } from './EditMemberModal'

export const Members = ({
  clubId,
  clubSlug,
  viewerRole,
  viewerCanEdit,
  membersCount,
  maxWidth = '500px',
  memberBatchSize = 50,
  textColor = 'white',
}: {
  clubId: string
  clubSlug: string
  viewerRole: ClubRole | null
  viewerCanEdit?: boolean
  membersCount?: number
  maxWidth?: string
  memberBatchSize?: number
  textColor?: 'black' | 'white'
}) => {
  const t = useT()
  const { authUser } = useAuthContext()

  const [membersOffset, setMembersOffset] = useState(0)

  const { data: membersData, loading } = useClubWithMembersQuery({
    slug: clubSlug,
    limit: memberBatchSize,
    offset: membersOffset,
  })

  const members = membersData?.club.members ?? []

  const showDirectionButtons = membersCount && membersCount > memberBatchSize

  if (loading && !membersData) {
    return (
      <Flex horizontal="center">
        <Loader variant="blue" delay={0} />
      </Flex>
    )
  }

  if (members.length === 0) {
    return (
      <div>
        <Text>{t("There's no one here")}</Text>
      </div>
    )
  }

  return (
    <Flex
      css={css`
        max-width: ${maxWidth};
        width: 100%;
        margin: 0 auto;
      `}
      column
    >
      {showDirectionButtons && (
        <DirectionButtons
          membersOffset={membersOffset}
          membersCount={membersCount ?? 0}
          setMembersOffset={setMembersOffset}
          memberBatchSize={memberBatchSize}
          membersLength={members.length}
          textColor={textColor}
        />
      )}
      {members.map((member, index) => (
        <PlayerElement
          key={member.id}
          role={member.role}
          {...member.user}
          backgroundColor={
            member.id === authUser?.uid
              ? colors.yellow300
              : index % 2
              ? colors.yellow100
              : colors.white
          }
          adminControls={
            viewerCanEdit ? (
              <ModalTrigger
                button={({ openModal }) => (
                  <button
                    css={[
                      buttonReset,
                      css`
                        background-color: black;
                        border-radius: 10px;
                        height: 20px;
                        width: 20px;
                        ${flexCenter};
                        &:hover {
                          background-color: ${colors.grey400};
                        }
                      `,
                    ]}
                    onClick={(event) => {
                      event.preventDefault()
                      openModal()
                    }}
                  >
                    <MdEdit size={14} color="white" />
                  </button>
                )}
                modal={({ closeModal }) => (
                  <EditMemberModal
                    member={member}
                    clubId={clubId}
                    viewerRole={viewerCanEdit ? 'admin' : viewerRole!}
                    closeModal={closeModal}
                  />
                )}
              />
            ) : null
          }
        />
      ))}
      {showDirectionButtons && (
        <DirectionButtons
          membersOffset={membersOffset}
          membersCount={membersCount ?? 0}
          setMembersOffset={setMembersOffset}
          memberBatchSize={memberBatchSize}
          membersLength={members.length}
          textColor={textColor}
        />
      )}
    </Flex>
  )
}

const DirectionButtons = ({
  membersOffset,
  membersCount,
  setMembersOffset,
  memberBatchSize,
  membersLength,
  textColor = 'white',
}: {
  membersOffset: number
  membersCount: number
  setMembersOffset: React.Dispatch<React.SetStateAction<number>>
  memberBatchSize: number
  membersLength: number
  textColor?: 'black' | 'white'
}) => {
  const t = useT()
  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={css`
        position: relative;
        margin: 32px 0;
      `}
    >
      <SecondaryButton
        rounded
        variant={textColor}
        css={css`
          position: absolute;
          left: 0;
        `}
        disabled={membersOffset <= 0}
        onClick={() => {
          if (membersOffset >= memberBatchSize) {
            setMembersOffset((prev) => prev - memberBatchSize)
          } else {
            setMembersOffset(0)
          }
        }}
      >
        {t(`Previous`)}
      </SecondaryButton>

      <TextNew italic color={textColor} condensed>
        {`${membersOffset + membersLength} | ${membersCount}`}
      </TextNew>

      <SecondaryButton
        rounded
        variant={textColor}
        css={css`
          position: absolute;
          right: 0;
        `}
        disabled={membersLength < memberBatchSize}
        onClick={() => {
          setMembersOffset((prev) => prev + membersLength)
        }}
      >
        {t(`Next`)}
      </SecondaryButton>
    </Flex>
  )
}
