/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { BiChevronLeft, BiCog } from 'react-icons/bi'
import { FaShare } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import {
  GreenButton,
  PrimaryButton,
  SecondaryButton,
} from '../../components/Button'
import { DefaultError } from '../../components/DefaultError'
import { HeadingNew } from '../../components/HeadingNew'
import { Loader } from '../../components/Loader'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { GradientBackground } from '../../components/layout/GradientBackground'
import { Layout } from '../../components/layout/Layout'
import { PageBodyComponent } from '../../components/layout/PageBodyComponent'
import { ModalTrigger } from '../../components/modal/Modal'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors, hexOpacity } from '../../styles/theme'
import { LeagueQuiz } from '../LeagueQuiz/queries'
import tilDrakt from './assets/til-drakt.png'
import tromsoLogo from './assets/tromso.png'
import { ActiveQuizComponent } from './components/ActiveQuizComponent'
import { LeaveChallengeLeagueModal } from './components/LeaveChallengeLeagueModal'
import { ShareLeagueModal } from './components/ShareLeagueModal'
import {
  ChallengeLeague,
  useChallengeLeagueQuery,
  useJoinChallengeLeagueMutation,
} from './queries'

const Content = ({
  groupId,
  source,
}: {
  groupId: string
  source?: string | null
}) => {
  const { data: groupData, loading } = useChallengeLeagueQuery(groupId)
  // const prevRoundWinnersQuery = usePreviousRoundWinnersQuery(groupId)
  const t = useT()

  const [joinGroup] = useJoinChallengeLeagueMutation()

  const navigate = useNavigate()

  const [activeQuizzes, setActiveQuizzes] = useState<LeagueQuiz[]>([])

  useEffect(() => {
    if (groupData?.group && !groupData?.group?.isChallengeLeague) {
      navigate(`/challenge-group/${groupData?.group.id}`, { replace: true })
    }

    if (groupData?.group?.activeQuizzes) {
      const sortedActiveQuizzes = [...groupData?.group?.activeQuizzes].sort(
        (a, b) =>
          new Date(b.availableFrom).getTime() -
          new Date(a.availableFrom).getTime()
      )

      setActiveQuizzes(sortedActiveQuizzes)
    }

    // update url if needed | slugs are prettier than ids
    if (
      groupData?.group &&
      window.location.pathname !== `/league/${groupData?.group?.slug}`
    ) {
      //was challenge-league
      navigate(`/league/${groupData?.group?.slug}`, { replace: true })
    }

    if (groupData?.group?.title) {
      document.title = `fcQuiz | ${groupData?.group.title} `

      trackEvent(AnalyticsEventType.LeaguePage, {
        league: groupData?.group.title,
        slug: groupData?.group.slug,
        source,
      })
    }
  }, [groupData?.group])

  //Legacy: Keep once
  useEffect(() => {
    const notFound = !groupData || !groupData.group
    if (!loading && notFound) {
      navigate(`/series/${groupId}`)
    }
  }, [groupData?.group, loading])

  if (loading) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Loader />
      </Flex>
    )
  }

  if (!groupData || !groupData.group) {
    const reason = !groupData
      ? 'no group data'
      : !groupData.group
      ? 'no group'
      : 'unknown'
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <DefaultError
          sentryErrorMessage={`ChallengeLeaguePage | groupId: ${groupId}, reason: ${reason}`}
        />
      </Flex>
    )
  }

  const league = groupData.group

  const contentMaxWidth = 460

  // const prevRoundWinners =
  //   prevRoundWinnersQuery.data?.group?.previousRoundWinners ?? []

  return (
    <Flex
      horizontal="center"
      column
      grow
      css={css`
        width: 100%;
        padding: 0 8px;
      `}
    >
      <GradientBackground backgroundImagePath={league.backgroundImageUrl} />

      <PageBodyComponent backgroundColor={league.backgroundColor}>
        <Flex
          horizontal="space-between"
          vertical="center"
          css={css`
            width: 100%;
          `}
        >
          <div
            css={css`
              position: relative;
              flex: 2;
              height: 30px;
            `}
          >
            <button
              role="navigation"
              aria-label={t('Back')}
              onClick={() => {
                navigate('/')
              }}
              css={[
                buttonReset,
                css`
                  position: absolute;
                  height: 48px;
                  width: 48px;
                  top: -9px;
                  left: -10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `,
              ]}
            >
              <div
                css={css`
                  background-color: ${colors.black};
                  border-radius: 50%;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <BiChevronLeft color={colors.white} size={30} />
              </div>
            </button>
          </div>
          <Flex
            css={css`
              position: absolute;
              top: -20px;
              left: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              pointer-events: none;
            `}
          >
            {league.iconImageUrl && (
              <img
                src={league.iconImageUrl}
                alt={league.title}
                css={css`
                  max-height: 100px;
                  max-width: 100px;
                `}
              />
            )}
          </Flex>
          <div
            css={css`
              flex: 2;
              display: flex;
              justify-content: flex-end;
            `}
          >
            <TextNew
              wide
              italic
              shadow
              narrowShadow
              textAlign="right"
              color={league.textColor}
              css={css`
                font-size: 10px;
              `}
            >
              {t('{{count}} member', {
                count: league.memberCount,
              }).toUpperCase()}
            </TextNew>
          </div>
        </Flex>
        <Spacer height={40} />

        <HeadingNew
          level={1}
          color={league.textColor}
          textAlign="center"
          condensed
          italic
          shadow
          strong={false}
          css={css`
            font-size: 60px;
            line-height: 1;
          `}
        >
          {league.title}
        </HeadingNew>

        <Spacer height="small" />

        {!league.viewerIsMember && (
          <Flex
            column
            horizontal="center"
            css={css`
              width: 100%;
              max-width: ${contentMaxWidth}px;
              margin: 0 auto;
            `}
          >
            <Spacer height={40} />
            {league.slug === 'tromso' && (
              <>
                <Flex
                  horizontal="space-between"
                  css={css`
                    background-color: ${hexOpacity(colors.black, 0.5)};
                    border-radius: 16px;
                    padding: 16px 0px 16px 16px;
                    width: 100%;
                  `}
                >
                  <TextNew
                    color={colors.white}
                    italic
                    condensed
                    strong
                    css={css`
                      font-size: 24px;
                      line-height: 26px;
                      flex: 2;
                      letter-spacing: 0px;
                    `}
                  >
                    {
                      'Bli med i den ukentlige Quiz-Ligaen og vinn en signert drakt!'
                    }
                  </TextNew>
                  <div
                    css={css`
                      width: 124px;
                      flex: 1;
                      position: relative;
                    `}
                  >
                    <img
                      src={tilDrakt}
                      alt="TIL-drakt"
                      css={css`
                        position: absolute;
                        max-width: 124px;
                        max-height: 138px;
                        object-fit: contain;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      `}
                    />
                  </div>
                </Flex>
                <Spacer height={40} />
              </>
            )}

            <GreenButton
              signUpRequired
              css={css`
                width: 100%;
                max-width: 200px;
              `}
              onClick={async () => {
                trackEvent(AnalyticsEventType.LeagueJoined, {
                  leagueSlug: league.slug,
                  league: league.title,
                  source,
                })
                await joinGroup({
                  variables: {
                    groupId: league.id,
                  },
                })
              }}
            >
              {t('Join').toUpperCase()}
            </GreenButton>
            <Spacer height={40} />
          </Flex>
        )}

        {league.viewerIsMember && (
          <Flex
            column
            css={css`
              width: 100%;
              max-width: ${contentMaxWidth}px;
              margin: 0 auto;
            `}
          >
            <Flex horizontal="center" vertical="center" gap="small">
              <ModalTrigger
                button={({ openModal }) => (
                  <PrimaryButton
                    onClick={openModal}
                    css={css`
                      min-height: 40px;
                      border-radius: 40px;
                    `}
                  >
                    <TextNew color="white">{t('Following')}</TextNew>
                  </PrimaryButton>
                )}
                modal={({ closeModal }) => {
                  return (
                    <LeaveChallengeLeagueModal
                      league={league}
                      closeModal={closeModal}
                    />
                  )
                }}
              />
              <ModalTrigger
                button={({ openModal }) => (
                  <PrimaryButton
                    onClick={openModal}
                    css={css`
                      height: 40px;
                      width: 40px;
                      border-radius: 40px;
                      padding: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <FaShare size={20} color={colors.white} />
                  </PrimaryButton>
                )}
                modal={({ closeModal }) => {
                  return (
                    <ShareLeagueModal league={league} closeModal={closeModal} />
                  )
                }}
              />
            </Flex>
            <Spacer height={24} />
            {league.viewerCanEdit && (
              <Flex column horizontal="center">
                <SecondaryButton
                  css={css`
                    border: 1px solid ${league.textColor};
                    max-width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                  `}
                  onClick={() => {
                    //was challenge-league
                    navigate(`/league/${league.slug}/settings`)
                  }}
                >
                  <BiCog size={20} color={league.textColor} />
                  <TextNew color={league.textColor}>
                    {t(`Admin Settings`)}
                  </TextNew>
                </SecondaryButton>
                <Spacer height={40} />
              </Flex>
            )}

            {activeQuizzes.length === 0 && (
              <ActiveQuizComponent
                league={league}
                activeQuiz={undefined}
                leagueTitle={league.upcomingQuiz?.title ?? league.title}
                upcomingQuiz={league.upcomingQuiz}
              />
            )}

            {activeQuizzes.length > 0 && (
              <Flex column gap={'huge'}>
                {activeQuizzes.map((activeQuiz) => (
                  <ActiveQuizComponent
                    key={activeQuiz.id}
                    league={league}
                    activeQuiz={activeQuiz}
                    leagueTitle={activeQuiz.league.title}
                    leagueFrequency={activeQuiz.league.frequency}
                    upcomingQuiz={league.upcomingQuiz}
                  />
                ))}
              </Flex>
            )}

            <Spacer height={40} />
            {league.slug === 'tromso' && (
              <Flex
                column
                css={css`
                  width: 100%;
                  max-width: ${contentMaxWidth}px;
                  margin: 0 auto;
                `}
              >
                <PrizesComponent league={league} />
                <Spacer height={40} />
              </Flex>
            )}

            {/* {prevRoundWinners.length > 0 && !isProduction && (
              <>
                <PreviousRoundWinners
                  contentMaxWidth={contentMaxWidth}
                  league={league}
                  prevRoundWinners={prevRoundWinners}
                />
                <Spacer height={40} />
              </>
            )} */}
          </Flex>
        )}

        <Spacer height={40} />
      </PageBodyComponent>
    </Flex>
  )
}

export const ChallengeLeaguePage = () => {
  const groupId = useParams<{ challengeLeagueId: string }>().challengeLeagueId!

  const urlParams = new URLSearchParams(window.location.search)
  const source = urlParams.get('source')

  useEffect(() => {
    if (window.location.pathname.includes('challenge-league')) {
      window.history.replaceState(
        {},
        '',
        window.location.pathname.replace('challenge-league', 'league')
      )
    }
  }, [
    window.location.pathname.includes('challenge-league'),
    window.location.pathname,
  ])

  return (
    <Layout backgroundColor={colors.grey100} showDownLoadButtons>
      <Content groupId={groupId} source={source} />
    </Layout>
  )
}

const PrizesComponent = ({ league }: { league: ChallengeLeague }) => {
  const t = useT()

  const firstColumnWidth = 90

  return (
    <Flex
      column
      gap="small"
      css={css`
        flex: 1;

        @media (max-width: 850px) {
          width: 100%;
        }
      `}
    >
      <TextNew
        condensed
        strong
        color={league.textColor}
        css={css`
          font-size: 28px;
          line-height: 1;
          font-style: italic;
        `}
      >
        {t('Prizes')}
      </TextNew>
      <Flex
        horizontal="space-between"
        css={css`
          background-color: ${hexOpacity(colors.black, 0.5)};
          border-radius: 16px;
          padding: 16px;
        `}
      >
        <Flex column>
          <Flex>
            <TextNew
              color={colors.white}
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
                width: ${firstColumnWidth}px;
              `}
            >
              {t(`Round`)}
            </TextNew>
            <TextNew
              color={colors.white}
              strong
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
              `}
            >
              {'Tromsø badge'}
            </TextNew>
          </Flex>
          <Flex>
            <TextNew
              color={colors.white}
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
                width: ${firstColumnWidth}px;
              `}
            >
              {t(`Monthly`)}
            </TextNew>
            <TextNew
              color={colors.white}
              strong
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
              `}
            >
              {'TIL-gavekort'}
            </TextNew>
          </Flex>
          <Flex>
            <TextNew
              color={colors.white}
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
                width: ${firstColumnWidth}px;
              `}
            >
              {capitalize(t(`Season`))}
            </TextNew>
            <TextNew
              color={colors.white}
              strong
              css={css`
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
              `}
            >
              {'Signert TIL-drakt'}
            </TextNew>
          </Flex>
        </Flex>

        <Flex gap="small">
          <img
            src={tromsoLogo}
            alt="Tromsø IL logo"
            css={css`
              max-width: 45px;
              max-height: 65px;
              object-fit: contain;
            `}
          />
          <img
            src={tilDrakt}
            alt="TIL-drakt"
            css={css`
              max-width: 45px;
              max-height: 65px;
              object-fit: contain;
            `}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

// const PreviousRoundWinners = ({
//   contentMaxWidth,
//   league,
//   prevRoundWinners,
// }: {
//   contentMaxWidth: number
//   league: ChallengeLeague
//   prevRoundWinners: ChallengeGroupLeaderboardEntry[]
// }) => {
//   const t = useT()
//   return (
//     <Flex
//       column
//       css={css`
//         border-radius: 16px;
//         padding: 16px;
//         width: 100%;
//         max-width: ${contentMaxWidth}px;
//       `}
//       gap="medium"
//     >
//       <TextNew
//         condensed
//         italic
//         strong
//         shadow
//         color={league.textColor}
//         textAlign="center"
//         css={css`
//           font-size: 24px;
//           line-height: 1;
//         `}
//       >
//         {t(
//           prevRoundWinners.length > 1
//             ? "Previous round's winners"
//             : "Previous round's winner"
//         )}
//       </TextNew>
//       <Flex horizontal="center" gap="medium" wrap>
//         {prevRoundWinners.map((winner) => {
//           return (
//             <Flex
//               key={`prev_winner_${winner.user.id}`}
//               horizontal="center"
//               column
//               gap={'tiny'}
//               css={css`
//                 background-color: ${colors.gold};
//                 padding: 8px 12px;
//                 border-radius: 16px 0;
//                 min-width: 100px;
//                 min-height: 140px;
//                 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//               `}
//             >
//               <UserAvatar
//                 avatarData={winner.user.avatarData}
//                 height={80}
//                 clickable
//               />
//               <TextNew
//                 color={colors.black}
//                 strong
//                 italic
//                 condensed
//                 textAlign="center"
//                 css={css`
//                   font-size: 24px;
//                   line-height: 1;
//                   max-width: 120px;
//                   overflow: hidden;
//                   text-overflow: ellipsis;
//                 `}
//               >
//                 {winner.user.displayName || winner.user.username}
//               </TextNew>
//             </Flex>
//           )
//         })}
//       </Flex>
//     </Flex>
//   )
// }
