/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { ClubLogo } from '../../components/Picture'
import { styleBySizeNumber, TextSize } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { lineClamp } from '../../styles/styles'
import { Club } from '../Clubs/queries'
import { colors } from '../../styles/theme'
import { TextNew } from '../../components/TextNew'

export type ClubItemProps = {
  club: Club | undefined
  size?: TextSize
  variant?: 'black' | 'green'
  clickable?: boolean
} & ComponentPropsWithRef<'button'>

export const ClubItem = forwardRef<HTMLButtonElement, ClubItemProps>(
  function ClubItem(
    { club, size = 'small', variant = 'black', clickable = true, ...rest },
    ref
  ) {
    const navigate = useNavigate()
    if (!club) return <></>
    return (
      <Button
        resetStyling
        ref={ref}
        onClick={(e: any) => {
          navigate(`/clubs/${club.slug}`)
          e.stopPropagation()
          e.preventDefault()
        }}
        disabled={!clickable}
        css={css`
          text-decoration: none;
        `}
        {...rest}
      >
        <Flex gap="tiny">
          <Flex vertical="center">
            <ClubLogo
              css={css`
                border-radius: 99px;
                margin-bottom: -2px;
              `}
              url={club.logoUrl}
              size={styleBySizeNumber[size] * 1.3}
              shape={club.logoShape}
            />
          </Flex>
          {variant == 'green' && (
            <TextNew
              condensed
              css={css`
                font-size: 21px;
                line-height: 24px;
                font-style: italic;
                padding-bottom: 2px;
              `}
              color="#8EE35A"
              size={size}
              strong
              italic
            >
              {club.name}
            </TextNew>
          )}
          {variant == 'black' && (
            <TextNew
              condensed
              size={size}
              css={
                (lineClamp(1),
                css`
                  margin-left: -3px;
                `,
                css`
                  width: 100%;
                `)
              }
              color={colors.grey400}
              italic
              strong
            >
              {club.name}
            </TextNew>
          )}
        </Flex>
      </Button>
    )
  }
)
