import { Selector } from '../../Selector'
import { ReactComponent as MouthA } from './Mouth A.svg'
import { ReactComponent as MouthB } from './Mouth B.svg'
import { ReactComponent as MouthC } from './Mouth C.svg'
import { ReactComponent as MouthD } from './Mouth D.svg'
import { ReactComponent as MouthE } from './Mouth E.svg'
import { ReactComponent as MouthF } from './Mouth F.svg'
import { ReactComponent as MouthG } from './Mouth G.svg'
import { ReactComponent as MouthH } from './Mouth H.svg'
import { ReactComponent as MouthI } from './Mouth I.svg'
import { ReactComponent as MouthJ } from './Mouth J.svg'
import { ReactComponent as MouthK } from './Mouth K.svg'
import { ReactComponent as MouthL } from './Mouth L.svg'
import { ReactComponent as MouthM } from './Mouth M.svg'
import { ReactComponent as MouthO } from './Mouth O.svg'
import { ReactComponent as MouthP } from './Mouth P.svg'
import { ReactComponent as MouthQ } from './Mouth Q.svg'

export const mouthStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    dy: number
    premium: boolean
  }
} = {
  MouthA: { svg: <MouthA />, dy: 0, premium: false },
  MouthB: { svg: <MouthB />, dy: 0, premium: false },
  MouthC: { svg: <MouthC />, dy: 0, premium: false },
  MouthD: { svg: <MouthD />, dy: 0, premium: false },
  MouthE: { svg: <MouthE />, dy: 0, premium: false },
  MouthF: { svg: <MouthF />, dy: 0, premium: false },
  MouthG: { svg: <MouthG />, dy: 0, premium: false },
  MouthH: { svg: <MouthH />, dy: 0, premium: false },
  MouthI: { svg: <MouthI />, dy: 0, premium: false },
  MouthJ: { svg: <MouthJ />, dy: 0, premium: false },
  MouthK: { svg: <MouthK />, dy: 0, premium: false },
  MouthL: { svg: <MouthL />, dy: 0, premium: false },
  MouthM: { svg: <MouthM />, dy: 0, premium: false },
  MouthO: { svg: <MouthO />, dy: -7, premium: false },
  MouthP: { svg: <MouthP />, dy: -8, premium: false },
  MouthQ: { svg: <MouthQ />, dy: 0, premium: false },
}

export const Mouth = () => {
  return <Selector selectedOptionKey={'mouth'} y={113} styles={mouthStyles} />
}
