/** @jsxImportSource @emotion/react */
import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { Badge } from '../../views/League/queries'
import { BadgeIcon } from '../../views/Profile/UserBadges'
import { userProfileQuery } from '../../views/Profile/queries'
import { Button } from '../Button'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import { checkClubMembershipBadges } from './queries'

export const BadgeModalContent = ({
  badges,
  onClose,
  textColor,
}: {
  badges: Badge[]
  onClose: () => void
  textColor?: string
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const t = useT()

  const apolloClient = useApolloClient()

  useEffect(() => {
    for (const badge of badges) {
      trackEvent(AnalyticsEventType.BadgeEarned, {
        badgeId: badge.id,
      })
    }
  }, [badges])

  const handleNext = () => {
    if (currentIndex < badges.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      apolloClient.refetchQueries({
        include: [userProfileQuery, checkClubMembershipBadges],
      })
      onClose()
    }
  }

  const currentBadge = badges[currentIndex]

  return (
    <Flex
      column
      vertical={'center'}
      css={css`
        text-align: center;
        width: 100%;
      `}
    >
      {/* Display the current badge. Adjust this part based on how you render badges. */}
      <Text color={textColor ?? colors.grey500}>
        {currentBadge.earnedMessage}
      </Text>
      <Spacer height={'medium'} />
      <Flex
        column={false}
        css={css`
          width: 100%;
          justify-content: space-between;
        `}
      >
        <Flex
          column
          vertical={'flex-start'}
          horizontal="center"
          gap={'medium'}
          css={css`
            width: 100%;
          `}
        >
          <BadgeIcon
            id={currentBadge.id}
            imageUrl={currentBadge.imageUrl}
            title={currentBadge.title}
            size={200}
            disableAnimation
          />
          <Text
            color={textColor ?? 'black'}
            size="huge"
            strong
            extraCondensed
            italic
          >
            {currentBadge.title}
          </Text>
        </Flex>
      </Flex>

      <Spacer height={'medium'} />

      <Flex horizontal={'center'}>
        <Button
          css={css`
            background-color: ${colors.green400};
            color: ${colors.white};
            border: none;
            width: 100%;
            margin: 0 32px;
          `}
          onClick={handleNext}
        >
          {t('Claim badge')}
        </Button>
      </Flex>
    </Flex>
  )
}
