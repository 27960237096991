/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useCallback, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import { PrimaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Text } from '../../components/Text'
import { useAuthContext, UserFlag, useViewerFlags } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import {
  useNativeApp,
  webviewToNativePostMessage,
} from '../../lib/native/NativeAppContext'
import { Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { boxShadows, colors } from '../../styles/theme'
import {
  useNotificationSettings,
  UserNotificationSetting,
  useSetNotificationSettingMutation,
} from '../EditProfile/queries'

function useShowEnablePushNotificationsBanner() {
  const { authUser } = useAuthContext()
  const { isNativeApp, pushNotificationsPermissionStatus } = useNativeApp()
  const notificationSettingsQuery = useNotificationSettings()
  const viewerFlags = useViewerFlags()

  useEffect(() => {
    const timeout = setTimeout(() => {
      webviewToNativePostMessage({
        type: 'request-push-notification-permission-status',
      })
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])

  if (
    !isNativeApp ||
    !authUser ||
    authUser.isAnonymous ||
    viewerFlags.loading ||
    notificationSettingsQuery.loading ||
    viewerFlags.flags.includes(UserFlag.hidePushNotificationBanner)
  ) {
    return false
  }

  if (pushNotificationsPermissionStatus === 'undetermined') {
    return true
  }

  if (pushNotificationsPermissionStatus === 'granted') {
    return !notificationSettingsQuery.data?.viewer?.notificationSettings
      .pushNotifications
  }

  return false
}

export function EnablePushNotificationsBanner({
  className,
}: {
  className?: string
}) {
  const t = useT()
  const { addFlag } = useViewerFlags()
  const show = useShowEnablePushNotificationsBanner()
  const [setNotificationSettingMutation] = useSetNotificationSettingMutation()
  const { askForPushNotificationPermission } = useNativeApp()

  const onYesClick = useCallback(() => {
    askForPushNotificationPermission()

    setNotificationSettingMutation({
      variables: {
        input: {
          setting: UserNotificationSetting.pushNotifications,
          value: true,
        },
      },
    })
  }, [askForPushNotificationPermission, setNotificationSettingMutation])

  const onNoClick = useCallback(() => {
    addFlag(UserFlag.hidePushNotificationBanner)
  }, [addFlag])

  return (
    // @ts-ignore TODO: why is the type wrong??
    <AnimateHeight
      height={show ? 'auto' : 0}
      css={css`
        display: ${show ? 'flex' : 'none'};
      `}
    >
      <Flex
        column
        css={css`
          padding: 16px;
          background-color: ${colors.blue100};
          border-radius: 8px;
          box-shadow: ${boxShadows.default2};
        `}
        className={className}
        horizontal="center"
      >
        <Text size="huge" textAlign="center">
          {t('Get notified')}
        </Text>

        <Spacer height="small" />

        <Text
          textAlign="center"
          css={css`
            max-width: 360px;
          `}
        >
          {t(
            'Do you want to receive push notifications when new quizzes are available?'
          )}
        </Text>

        <Spacer height="medium" />

        <Flex vertical="center">
          <button css={buttonReset} onClick={onNoClick}>
            <Text underline>{t('Not now')}</Text>
          </button>
          <Spacer width="medium" />
          <PrimaryButton onClick={onYesClick}>
            {t('Yes, please!')}
          </PrimaryButton>
        </Flex>
      </Flex>
    </AnimateHeight>
  )
}
