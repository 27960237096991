/** @jsxImportSource @emotion/react */

import { FetchResult } from '@apollo/client'
import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import { Flex } from '../../../components/layout/Flex'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { useInterval } from '../../../utils/useInterval'
import { LiveCompletedQuestion } from '../queries'
import { HostModeHeader } from './HostModeHeader'

export const CompletedQuestionLiveQuiz = ({
  nextQuestion,
  lastCompletedQuestion,
  questionsCount,
  numCompletedQuestions,
}: {
  nextQuestion: () => Promise<FetchResult>
  lastCompletedQuestion: LiveCompletedQuestion
  questionsCount: number
  numCompletedQuestions: number
}) => {
  const timeUntilButtonIsEnabled = 3000

  const isLastQuestion = numCompletedQuestions === questionsCount
  const t = useT()

  const [remainingTime, setRemainingTime] = useState(timeUntilButtonIsEnabled)
  const [loading, setLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const correctAlternatives = lastCompletedQuestion.alternatives.filter(
    (alternative) => alternative.isCorrectAlternative
  )
  useInterval(() => {
    setRemainingTime(remainingTime - 1000)
    if (remainingTime <= 0) {
      setButtonDisabled(false)
    }
  }, 1000)

  // Double check to make sure the button is enabled
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (buttonDisabled) {
        setButtonDisabled(false)
      }
    }, timeUntilButtonIsEnabled + 3000)
    return () => {
      clearTimeout(timeOut)
    }
  }, [])

  return (
    <Flex column grow>
      <PageContent gap={'medium'}>
        <HostModeHeader />
        <Heading level={1}>{`Questions is closed`}</Heading>
        <TextNew>{lastCompletedQuestion.text}</TextNew>
        {correctAlternatives.length && (
          <>
            {correctAlternatives.map((alternative) => (
              <TextNew italic key={alternative.id}>
                - {alternative.text}
              </TextNew>
            ))}
          </>
        )}
        <TextNew>{`Question: ${numCompletedQuestions} / ${questionsCount}`}</TextNew>
        <PrimaryButton
          disabled={loading || buttonDisabled}
          onClick={async () => {
            if (buttonDisabled) {
              return
            }

            setLoading(true)
            if (loading) {
              return
            }

            await nextQuestion()
            setLoading(false)
          }}
        >
          {isLastQuestion ? t('Final results') : t(`Next question`)}
        </PrimaryButton>

        <Spacer height={16} />

        {buttonDisabled && (
          <TextNew italic textAlign="center">
            {`Time left before button activates: ${Math.floor(
              remainingTime / 1000
            )}s`}
          </TextNew>
        )}

        {!buttonDisabled && (
          <TextNew italic textAlign="center">
            {'You may now proceed when ready.'}
          </TextNew>
        )}
      </PageContent>
    </Flex>
  )
}
