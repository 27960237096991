/** @jsxImportSource @emotion/react */

import { useParams } from 'react-router-dom'
import { GeneratePage } from '../../Generate/GeneratePage'

export const CreateQuizPage = () => {
  const leagueSlug = useParams<{ slug: string }>().slug!
  const searchParams = new URLSearchParams(location.search)
  const isLive = searchParams.get('isLive') ?? 'false'

  return <GeneratePage leagueSlug={leagueSlug} isLive={isLive === 'true'} />
}
