/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useUserDataDeletionRequestMutation } from '../Profile/queries'

const NotSignedIn = () => {
  const t = useT()

  return (
    <Flex column grow horizontal="center" gap="medium">
      <Text> {t('You need to sign in to view this page')}</Text>
      <ModalTrigger
        button={({ openModal }) => (
          <PrimaryButton
            onClick={openModal}
            css={css`
              height: 50px;
              min-width: 200px;
            `}
          >
            <Text size="large" condensed color="white">
              {t('Log in')}
            </Text>
          </PrimaryButton>
        )}
        modal={({ closeModal }) => <LoginModalInner closeModal={closeModal} />}
      />
    </Flex>
  )
}

const Content = () => {
  const { data, loading } = useViewerQuery()

  const [deletePressed, setDeletePressed] = useState(false)
  const [onlyDeleteData, setOnlyDeleteData] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)
  const [deleteInputValue, setDeleteInputValue] = useState('')

  const t = useT()
  const navigate = useNavigate()

  const deleteConfirmationValue = t('Delete').toUpperCase()

  const [userDataDeletionRequest] = useUserDataDeletionRequestMutation()

  if (loading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  if (!data?.viewer) {
    return <NotSignedIn />
  }

  const viewer = data.viewer

  return (
    <Flex column grow gap="large">
      <SecondaryButton
        onClick={() => {
          navigate('/profile/edit')
        }}
      >
        {t('Go back')}
      </SecondaryButton>
      <Heading level={1} looksLikeLevel={3} strong extraCondensed>
        {t('Account management')}
      </Heading>
      <Flex gap="small" wrap>
        <Text size="large" strong>
          {viewer.displayName}
        </Text>
        <Text size="large" color={colors.grey400} strong>
          {viewer.email}
        </Text>
      </Flex>

      <Flex vertical="center" gap="medium">
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${colors.grey400};
          `}
        />
        <Heading level={2} looksLikeLevel={5}>
          {t('Delete')}
        </Heading>
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${colors.grey400};
          `}
        />
      </Flex>

      {requestSent && (
        <Flex column gap="small" horizontal="center">
          <Text textAlign="center">
            {t(
              'A deletion request has been sent, and your data will be deleted soon.'
            )}
          </Text>
        </Flex>
      )}

      {!requestSent && (
        <Flex column gap="small">
          <ToggleSwitch
            value={onlyDeleteData}
            onValue={setOnlyDeleteData}
            label={t('Delete data only and keep account')}
          />
          <Spacer height="tiny" />

          <PrimaryButton
            variant="dangerRed"
            css={css`
              height: 50px;
            `}
            onClick={() => {
              setDeletePressed(true)
            }}
          >
            {onlyDeleteData ? t('Delete data only') : t('Delete account')}
          </PrimaryButton>

          {deletePressed && !requestSent && (
            <Flex column>
              <label htmlFor="deleteInput">
                <Text>
                  {t('Type {{delete}} to confirm', {
                    delete: deleteConfirmationValue,
                  })}
                </Text>
              </label>
              <Flex gap="medium">
                <input
                  id="deleteInput"
                  type="text"
                  aria-label={t('Type {{delete}} to confirm', {
                    delete: deleteConfirmationValue,
                  })}
                  placeholder={deleteConfirmationValue}
                  value={deleteInputValue}
                  onChange={(e) => setDeleteInputValue(e.target.value)}
                  css={css`
                    height: 50px;
                    width: 100%;
                    padding-left: 4px;
                  `}
                />
                <SecondaryButton
                  variant="dangerRed"
                  disabled={deleteInputValue !== deleteConfirmationValue}
                  onClick={async () => {
                    if (deleteInputValue !== deleteConfirmationValue) {
                      return
                    }

                    if (requestFailed) {
                      setRequestFailed(false)
                    }

                    const res = await userDataDeletionRequest({
                      variables: { input: { onlyDeleteData } },
                    })

                    const success = res.data?.requestDeleteUserData

                    if (!success) {
                      setRequestFailed(true)
                      return
                    }

                    trackEvent(AnalyticsEventType.UserDeleteRequest, {
                      onlyDeleteData,
                    })
                    setRequestSent(true)
                  }}
                >
                  {t('Confirm')}
                </SecondaryButton>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}

      {requestFailed && (
        <Flex column gap="small" horizontal="center">
          <Text textAlign="center">{t('Something went wrong, try again')}</Text>
        </Flex>
      )}
    </Flex>
  )
}

export const AccountHandlingPage = () => {
  return (
    <Layout hideNavbar>
      <Header color="grey" />
      <Page>
        <Content />
      </Page>
    </Layout>
  )
}
