import { Selector } from '../Selector'

import { ReactComponent as Hair0 } from './Hair 0.svg'
import { ReactComponent as HairA } from './Hair A.svg'
import { ReactComponent as HairB } from './Hair B.svg'
import { ReactComponent as HairC } from './Hair C.svg'
import { ReactComponent as HairD } from './Hair D.svg'
import { ReactComponent as HairE } from './Hair E.svg'
import { ReactComponent as HairF } from './Hair F.svg'
import { ReactComponent as HairG } from './Hair G.svg'
import { ReactComponent as HairH } from './Hair H.svg'
import { ReactComponent as HairI } from './Hair I.svg'
import { ReactComponent as HairJ } from './Hair J.svg'
import { ReactComponent as HairK } from './Hair K.svg'
import { ReactComponent as HairL } from './Hair L.svg'
import { ReactComponent as HairM } from './Hair M.svg'
import { ReactComponent as HairN } from './Hair N.svg'
import { ReactComponent as HairO } from './Hair O.svg'
import { ReactComponent as HairP } from './Hair P.svg'
import { ReactComponent as HairQ } from './Hair Q.svg'
import { ReactComponent as HairR } from './Hair R.svg'
import { ReactComponent as HairS } from './Hair S.svg'
import { ReactComponent as HairT } from './Hair T.svg'
// Premiums
import { ReactComponent as HairU } from './Hair U.svg'
import { ReactComponent as HairV } from './Hair V.svg'
import { ReactComponent as HairW } from './Hair W.svg'
import { ReactComponent as HairX } from './Hair X.svg'
import { ReactComponent as HairY } from './Hair Y.svg'
import { ReactComponent as HairZ } from './Hair Z.svg'
import { ReactComponent as Hair1 } from './Hair 1.svg'
import { ReactComponent as Hair2 } from './Hair 2.svg'
import { ReactComponent as Hair3 } from './Hair 3.svg'
import { ReactComponent as Hair4 } from './Hair 4.svg'
import { ReactComponent as Hair5 } from './Hair 5.svg'

export const hairStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    dx: number
    dy: number
    zIndex: number
    premium: boolean
  }
} = {
  Hair0: { svg: <Hair0 />, dx: 0, dy: -1, zIndex: 0, premium: false },
  HairA: { svg: <HairA />, dx: 0, dy: -11.5, zIndex: 0, premium: false },
  HairB: { svg: <HairB />, dx: 0, dy: -12.5, zIndex: 0, premium: false },
  HairC: { svg: <HairC />, dx: 0, dy: -11.5, zIndex: 0, premium: false },
  HairD: { svg: <HairD />, dx: 3, dy: -11, zIndex: 0, premium: false },
  HairE: { svg: <HairE />, dx: 2.5, dy: -12, zIndex: 0, premium: false },
  HairF: { svg: <HairF />, dx: 1, dy: -28, zIndex: 0, premium: false },
  HairG: { svg: <HairG />, dx: 0, dy: -14, zIndex: 0, premium: false },
  HairH: { svg: <HairH />, dx: -0.5, dy: -20, zIndex: 0, premium: false },
  HairI: { svg: <HairI />, dx: -0.5, dy: -17, zIndex: 0, premium: false },
  HairJ: { svg: <HairJ />, dx: -0.5, dy: -4, zIndex: 0, premium: false },
  HairK: { svg: <HairK />, dx: -8, dy: -32, zIndex: 0, premium: false },
  HairL: { svg: <HairL />, dx: -0.5, dy: -21, zIndex: 0, premium: false },
  HairM: { svg: <HairM />, dx: -1, dy: -19, zIndex: 0, premium: false },
  HairN: { svg: <HairN />, dx: -0.5, dy: -32, zIndex: 0, premium: false },
  HairO: { svg: <HairO />, dx: 0, dy: -42, zIndex: 0, premium: false },
  HairP: { svg: <HairP />, dx: -0.5, dy: -36, zIndex: 1, premium: false },
  HairQ: { svg: <HairQ />, dx: 0, dy: -70.5, zIndex: 0, premium: false },
  HairR: { svg: <HairR />, dx: 0, dy: -19, zIndex: 0, premium: false },
  HairS: { svg: <HairS />, dx: 0, dy: -34.5, zIndex: 0, premium: false },
  HairT: { svg: <HairT />, dx: 0.5, dy: -1, zIndex: 0, premium: false },
  //Premium
  HairU: { svg: <HairU />, dx: 0.5, dy: -17, zIndex: 1, premium: true },
  HairV: { svg: <HairV />, dx: 0, dy: -12, zIndex: 1, premium: true },
  HairW: { svg: <HairW />, dx: -10, dy: -33, zIndex: 0, premium: true },
  HairX: { svg: <HairX />, dx: 0.5, dy: -10, zIndex: 0, premium: true },
  HairY: { svg: <HairY />, dx: 0, dy: -12, zIndex: 0, premium: true },
  HairZ: { svg: <HairZ />, dx: -0.5, dy: -1, zIndex: 0, premium: true },
  Hair1: { svg: <Hair1 />, dx: 0, dy: -33, zIndex: 0, premium: true },
  Hair2: { svg: <Hair2 />, dx: 0, dy: -12, zIndex: 0, premium: true },
  Hair3: { svg: <Hair3 />, dx: 0, dy: 0, zIndex: 0, premium: true },
  Hair4: { svg: <Hair4 />, dx: 0, dy: -9, zIndex: 0, premium: true },
  Hair5: { svg: <Hair5 />, dx: 0, dy: -22, zIndex: 0, premium: true },
}

export const Hair = () => {
  return <Selector selectedOptionKey={'hair'} styles={hairStyles} />
}
