/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react'
import { Flex } from './Flex'
import { css } from '@emotion/react'

export const PageBodyComponent = ({
  backgroundColor,
  children,
}: {
  backgroundColor?: string
  children: ReactNode
}) => (
  <Flex
    column
    grow
    css={css`
      background-color: ${backgroundColor};
      border-radius: 16px 16px 0 0;
      padding: 16px;
      width: 100%;
      max-width: 720px;
      margin-top: 200px;
      position: relative;
      height: 100%;
    `}
  >
    {children}
  </Flex>
)
