/** @jsxImportSource @emotion/react */

import { Selector } from '../../Selector'
import { ReactComponent as BrowsA } from './Brows A.svg'
import { ReactComponent as BrowsB } from './Brows B.svg'
import { ReactComponent as BrowsC } from './Brows C.svg'
import { ReactComponent as BrowsD } from './Brows D.svg'
import { ReactComponent as BrowsE } from './Brows E.svg'
import { ReactComponent as BrowsF } from './Brows F.svg'
import { ReactComponent as BrowsG } from './Brows G.svg'
import { ReactComponent as BrowsH } from './Brows H.svg'
import { ReactComponent as BrowsI } from './Brows I.svg'
import { ReactComponent as BrowsJ } from './Brows J.svg'

export const browsStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    dy: number
    premium: boolean
  }
} = {
  BrowsB: { svg: <BrowsB />, dy: 8, premium: false },
  BrowsC: { svg: <BrowsC />, dy: 10, premium: false },
  BrowsD: { svg: <BrowsD />, dy: 15, premium: false },
  BrowsE: { svg: <BrowsE />, dy: 10, premium: false },
  BrowsF: { svg: <BrowsF />, dy: 9, premium: false },
  BrowsA: { svg: <BrowsA />, dy: 7, premium: false },
  BrowsG: { svg: <BrowsG />, dy: 4, premium: false },
  BrowsH: { svg: <BrowsH />, dy: 6, premium: false },
  BrowsI: { svg: <BrowsI />, dy: 5, premium: false },
  BrowsJ: { svg: <BrowsJ />, dy: 6, premium: false },
}

export const Brows = () => {
  return <Selector selectedOptionKey={'brows'} y={31} styles={browsStyles} />
}
