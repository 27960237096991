import { gql, useMutation, useQuery } from '@apollo/client'

const updateUsernameMutation = gql`
  mutation updateUsername($username: String!) {
    updateUsername(username: $username) {
      id
      username
    }
  }
`

export const useUpdateUsernameMutation = () =>
  useMutation<any, { username: string }>(updateUsernameMutation)

export const useUpdateProfilePictureUriMutation = () => {
  return useMutation<any, { input: { profilePictureUri: string } }>(gql`
    mutation updateProfilePictureUri($input: UpdateUserInput!) {
      updateUser(input: $input) {
        id
        profilePictureUrl
      }
    }
  `)
}

// Notification settings

export enum UserNotificationSetting {
  emailNewLeagueQuiz = 'emailNewLeagueQuiz',
  emailLeagueQuizSummary = 'emailLeagueQuizSummary',
  emailLeagueQuizReminder = 'emailLeagueQuizReminder',
  pushNotifications = 'pushNotifications',
}

export type NotificationSettings = {
  emailNewLeagueQuiz: boolean
  emailLeagueQuizSummary: boolean
  emailLeagueQuizReminder: boolean
  pushNotifications: boolean
}

const notificationSettingsQuery = gql`
  query notificationSettings {
    viewer {
      id
      notificationSettings {
        id
        emailNewLeagueQuiz
        emailLeagueQuizSummary
        emailLeagueQuizReminder
        pushNotifications
      }
    }
  }
`
export const useNotificationSettings = () => {
  return useQuery<{ viewer?: { notificationSettings: NotificationSettings } }>(
    notificationSettingsQuery
  )
}

const setNotificationSettingMutation = gql`
  mutation setNotificationSetting($input: NotificationSettingInput!) {
    setNotificationSetting(input: $input) {
      id
      notificationSettings {
        id
        emailNewLeagueQuiz
        emailLeagueQuizSummary
        emailLeagueQuizReminder
        pushNotifications
      }
    }
  }
`

export const useSetNotificationSettingMutation = () =>
  useMutation<
    unknown,
    { input: { setting: UserNotificationSetting; value: boolean } }
  >(setNotificationSettingMutation)
