import { gql, useQuery } from '@apollo/client'

export type FeedLeague = {
  id: string
  title: string
  viewerIsSubscribed: boolean
}

export type FeedLeagueQuiz = {
  id: string
  title: string
  slug: string
  league?: FeedLeague
  viewerCanStartQuiz: boolean
  questionsCount: number
  viewerCanQuickMatch: boolean
  participantCount: number
  quizType: string
}

export type FeedItem = {
  league?: FeedLeague
  leagueQuiz?: FeedLeagueQuiz
  specialLeagueSlug?: string
  videoAd?: boolean
}

const feedQuery = gql`
  query feed($offset: Int!, $limit: Int!) {
    feed(offset: $offset, limit: $limit) {
      league {
        id
        title
        viewerIsSubscribed
      }
      leagueQuiz {
        id
        title
        league {
          id
          title
          viewerIsSubscribed
        }
        viewerCanStartQuiz
        slug
        questionsCount
        viewerCanQuickMatch
        participantCount
        quizType
      }
      specialLeagueSlug
      videoAd
    }
  }
`

export const useFeedQuery = (offset: number, limit: number) => {
  return useQuery<{ feed: FeedItem[] }>(feedQuery, {
    variables: { offset, limit },
    fetchPolicy: 'cache-and-network',
  })
}
