/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { prettyLetterSpacing } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { User } from '../../Profile/queries'
import { ChallengeLeague, useChallengeGroupMembersQuery } from '../queries'

export const MembersSubpage = ({
  league,
  viewerId,
}: {
  league: ChallengeLeague
  viewerId: string
}) => {
  const memberBatchSize = 75

  const [membersOffset, setMembersOffset] = useState(0)

  const membersQuery = useChallengeGroupMembersQuery(
    league.id,
    memberBatchSize,
    membersOffset
  )

  const leagueMembers = membersQuery.data?.group?.members ?? []

  const t = useT()
  return (
    <>
      <Flex column gap="small">
        <Spacer height={32} />

        <Flex horizontal="space-between">
          <TextNew strong size="large" extraCondensed>
            {t('Members')}
          </TextNew>
          <TextNew strong size="large" extraCondensed italic>
            {`(${league.memberCount})`}
          </TextNew>
        </Flex>

        {(leagueMembers ?? []).map((member) => {
          const memberIsOwner = league.owners.some(
            (owner) => owner.id === member.id
          )
          const memberIsViewer = viewerId === member.id
          return (
            <MemberComponent
              key={member.id}
              member={member}
              memberIsOwner={memberIsOwner}
              memberIsViewer={memberIsViewer}
            />
          )
        })}

        <Spacer height={32} />

        <Flex
          horizontal="center"
          vertical="center"
          css={css`
            position: relative;
          `}
        >
          <SecondaryButton
            css={css`
              position: absolute;
              left: 0;
            `}
            disabled={membersOffset <= 0}
            onClick={() => {
              if (membersOffset >= memberBatchSize) {
                setMembersOffset((prev) => prev - memberBatchSize)
              } else {
                setMembersOffset(0)
              }
            }}
          >
            {t(`Previous`)}
          </SecondaryButton>

          <TextNew italic color={colors.black} condensed>
            {`${membersOffset + leagueMembers.length} | ${league.memberCount}`}
          </TextNew>

          <SecondaryButton
            css={css`
              position: absolute;
              right: 0;
            `}
            disabled={leagueMembers.length < memberBatchSize}
            onClick={() => {
              setMembersOffset((prev) => prev + leagueMembers.length)
            }}
          >
            {t(`Next`)}
          </SecondaryButton>
        </Flex>
      </Flex>
    </>
  )
}

export const MemberComponent = ({
  member,
  memberIsOwner,
  memberIsViewer,
}: {
  member: User
  memberIsOwner?: boolean
  memberIsViewer?: boolean
}) => {
  const t = useT()
  return (
    <Flex gap="small" vertical="center" wrap>
      <UserAvatar avatarData={member.avatarData} height={50} />
      <TextNew color={colors.black} css={prettyLetterSpacing}>
        {member.displayName}
      </TextNew>
      {memberIsOwner && (
        <TextNew italic color={colors.black} condensed>
          {t('Owner')}
        </TextNew>
      )}
      {memberIsViewer && (
        <TextNew italic color={colors.black} condensed>
          {t('You')}
        </TextNew>
      )}
    </Flex>
  )
}
