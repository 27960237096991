/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { ComponentPropsWithRef, memo } from 'react'
import {
  textCondensed,
  textExtraCondensed,
  textStyle,
  textWide,
} from '../styles/styles'
import { colors, hexOpacity } from '../styles/theme'
import { colorIsLight } from '../views/Admin/Utils'

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6

export const sizeByLevel: { [key in HeadingLevel]: SerializedStyles } = {
  1: css`
    font-size: 64px;
  `,
  2: css`
    font-size: 48px;
  `,
  3: css`
    font-size: 32px;
  `,
  4: css`
    font-size: 26px;
  `,
  5: css`
    font-size: 18px;
  `,
  6: css`
    font-size: 15px;
  `,
}

export type HeadingProps = {
  level: HeadingLevel
  looksLikeLevel?: HeadingLevel
  size?: number
  color?: string
  strong?: boolean
  extraStrong?: boolean
  weight?: number
  condensed?: boolean
  extraCondensed?: boolean
  wide?: boolean
  textAlign?: 'left' | 'center' | 'right'
  uppercase?: boolean
  italic?: boolean
  shadow?: boolean
} & ComponentPropsWithRef<'h1'>

export const HeadingNew = memo(function Heading({
  level,
  looksLikeLevel,
  size,
  strong = true,
  extraStrong = false,
  weight,
  condensed = false,
  extraCondensed = false,
  wide = false,
  textAlign,
  color,
  uppercase = false,
  italic = false,
  shadow = false,
  ...rest
}: HeadingProps) {
  // Simply setting font-weight: x will not match the style in a variable font
  // Therefore, the weight of the variable settings are replaced here
  const bold = (style: false | SerializedStyles | undefined) => {
    if (!style) return false
    if (weight) return style.styles.replace("'wght' 500", `'wght' ${weight}`)
    if (extraStrong) return style.styles.replace("'wght' 500", "'wght' 900")
    if (strong) return style.styles.replace("'wght' 500", "'wght' 700")
    return style
  }
  const props = {
    css: [
      textStyle,
      condensed && textCondensed,
      extraCondensed && textExtraCondensed,
      wide && textWide,
      uppercase &&
        css`
          text-transform: uppercase;
        `,
      css`
        line-height: 1.1em;
        margin-top: 0.1em;
        margin-bottom: 0.2em;
        color: ${color ?? 'black'};
      `,
      size
        ? css`
            font-size: ${size}px;
          `
        : sizeByLevel[looksLikeLevel ?? level],
      textAlign &&
        css`
          text-align: ${textAlign};
        `,
      italic &&
        css`
          font-style: italic;
        `,
      shadow &&
        css`
          text-shadow: 1px 3px 0px
            ${hexOpacity(
              colors.black,
              colorIsLight(color ?? colors.black) ? 0.5 : 0.1
            )};
        `,
    ].map((it) => bold(it)),
    ...rest,
  }

  switch (level) {
    case 1:
      return <h1 {...props} />
    case 2:
      return <h2 {...props} />
    case 3:
      return <h3 {...props} />
    case 4:
      return <h4 {...props} />
    case 5:
      return <h5 {...props} />
    case 6:
      return <h6 {...props} />
  }
})
