// Campaign constants
export const AD_CAMPAIGN_DISPLAY_TIME_MS = 12350
export const AD_CAMPAIGN_DISPLAY_HALFTIME = 6350
export const AD_CAMPAIGN_HEADER_EXPAND_TIME_MS = 350
export const AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS = 2000
export const AD_CAMPAIGN_DISPLAY_TIME_SHORT_MS = 5125
export const AD_CAMPAIGN_DISPLAY_HALFTIME_SHORT = 2125
export const AD_CAMPAIGN_HEADER_EXPAND_TIME_SHORT_MS = 125
export const AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_SHORT_MS = 1000

// Client side quiz constants
export const AVERAGE_MS_PER_WORD = 375
export const MAX_TIME_TO_ANSWER_IN_SECONDS = 20
export const MAX_TIME_BONUS_IN_SECONDS = 10

// Misc
export const CHALLENGE_MODE_MAX_W = 560
export const UNVERIFIED_CLUB_SUBSCRIPTION_LIMIT = 4
