/** @jsxImportSource @emotion/react */

import { Keyframes, css } from '@emotion/react'
import { TextNew, TextProps } from './TextNew'
import { useEffect, useState } from 'react'
import { fadeInLeft, fadeInLeftfadeOutRight } from '../styles/animations'
import { Flex } from './layout/Flex'

//---
// Fades out the text left-right. Good for short text
//---

// Similiar to TextShifting but happens only x times
export const TextShiftsFade = ({
  children,
  shiftsAtMs,
  disabled = false,

  className,
  ...rest
}: {
  children: any
  shiftsAtMs: number[]
  disabled?: boolean
} & TextProps) => {
  const [animation, setAnimation] = useState<Keyframes | undefined>(
    disabled ? undefined : fadeInLeft
  )
  const [childUsed, setChildUsed] = useState(children[disabled ? 1 : 0])

  useEffect(() => {
    if (disabled) return
    let n = 0
    for (const child of children.slice(1)) {
      setTimeout(() => {
        setAnimation(fadeInLeftfadeOutRight)
        setTimeout(() => {
          setChildUsed(child)
        }, 250)
        setTimeout(() => {
          setAnimation(undefined)
        }, 500)
      }, shiftsAtMs[n])
      n++
    }
  }, [])

  return (
    <Flex
      css={css`
        overflow: hidden;
        margin: -20px;
        padding: 20px;
      `}
    >
      <TextNew
        css={css`
          animation: ${animation} 0.5s forwards;
        `}
        className={className}
        {...rest}
      >
        {childUsed}
      </TextNew>
    </Flex>
  )
}
