import { gql, useMutation } from '@apollo/client'
import { Locale } from '../../lib/i18n/locale'

type RegisterUserInput = {
  username: string
  email: string
  password: string
  locale?: Locale
  invitationCode?: string
  emailNewLeagueQuiz?: boolean
  emailLeagueQuizSummary?: boolean
  emailLeagueQuizReminder?: boolean
}

const registerUserMutation = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      signInToken
    }
  }
`
export const useRegisterUserMutation = () => {
  return useMutation<
    { registerUser: { signInToken: string } },
    { input: RegisterUserInput }
  >(registerUserMutation)
}

const transferDataMutation = gql`
  mutation transferAnonymousUserData($userId: ID!) {
    transferAnonymousUserData(userId: $userId) {
      id
    }
  }
`

export const useTransferDataMutation = () => {
  return useMutation<
    { transferAnonymousUserData: { id: string } },
    { userId: string }
  >(transferDataMutation)
}
