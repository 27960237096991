/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Fragment } from 'react'
import { MdCheck } from 'react-icons/md'
import { PrimaryButton } from '../../components/Button'
import { Chip } from '../../components/Chip'
import { DefaultError } from '../../components/DefaultError'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { RoundedBox } from '../../components/RoundedBox'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext } from '../../lib/AuthContext'
import { TFunction, useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { LeaguesContainer } from '../Home/LeaguesContainer'
import { useStartTrainingQuizMutation } from '../TrainingQuiz/queries'
import {
  TrainingCategory,
  TrainingQuiz,
  TrainingQuizDifficulty,
  useTrainingPageQuery,
  useTrainingQuizzes,
} from './queries'
import { useNavigate } from 'react-router-dom'

const getTrainingQuizColors = (color: string | undefined) => {
  switch (color) {
    case 'purple':
      return {
        light: colors.purple200,
        dark: colors.purple300,
      }
    case 'red':
      return {
        light: colors.red200,
        dark: colors.red300,
      }
    case 'blue':
      return {
        light: colors.blue200,
        dark: colors.blue300,
      }
    case 'orange':
      return {
        light: colors.orange200,
        dark: colors.orange300,
      }
    case 'green':
    default:
      return {
        light: colors.green100,
        dark: colors.green300,
      }
  }
}

const translateDifficulty = (
  t: TFunction,
  difficulty: TrainingQuizDifficulty
) => {
  switch (difficulty) {
    case 'easy':
      return t('Easy')
    case 'medium':
      return t('Medium')
    case 'hard':
      return t('Hard')
  }
}

export const TrainingQuizItem = ({
  trainingQuiz,
}: {
  trainingQuiz: TrainingQuiz
}) => {
  const t = useT()
  const [startTrainingQuizMutation, { loading }] =
    useStartTrainingQuizMutation()
  const { authUser, signInAsAnonymous } = useAuthContext()

  let navigate = useNavigate()

  const { light, dark } = getTrainingQuizColors(trainingQuiz.color)

  const percentageCompleted = trainingQuiz.viewerResult
    ? Math.round(
        (trainingQuiz.viewerResult.mostCorrectAnswers /
          trainingQuiz.questionCount) *
          100
      )
    : 0

  return (
    <RoundedBox
      backgroundColor={light}
      css={css`
        min-width: 180px;
        max-width: 180px;
        min-height: 180px;
        position: relative;
        opacity: ${percentageCompleted === 100 ? 0.6 : 1};
      `}
    >
      {percentageCompleted === 100 ? (
        <Flex
          vertical="center"
          horizontal="center"
          css={css`
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: ${colors.green300};
            width: 32px;
            height: 32px;
            border-radius: 32px;
            box-shadow: ${theme.boxShadows.default};
          `}
        >
          <MdCheck size={20} color="white" strokeWidth="2px" />
        </Flex>
      ) : trainingQuiz.viewerResult &&
        trainingQuiz.viewerResult.attempts > 0 ? (
        <Flex
          vertical="center"
          horizontal="center"
          css={css`
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: ${colors.blue400};
            border-radius: 1000px;
            padding: 2px 6px 3px 8px;
            box-shadow: ${theme.boxShadows.default2};
          `}
        >
          <Text
            color="white"
            size="tiny"
            strong
            css={css`
              z-index: 1;
            `}
          >{`${percentageCompleted}%`}</Text>
        </Flex>
      ) : null}

      <Flex
        column
        grow
        vertical="space-between"
        css={css`
          height: 100%;
        `}
      >
        <Flex column>
          <Heading level={3} looksLikeLevel={4} condensed>
            {trainingQuiz.title}
          </Heading>

          <Flex css={margin.top('small')}>
            <Chip
              label={translateDifficulty(t, trainingQuiz.difficulty)}
              backgroundColor={dark}
              color={colors.green600}
            />
          </Flex>
        </Flex>

        <PrimaryButton
          onClick={async () => {
            if (!authUser) {
              await signInAsAnonymous()
            }

            startTrainingQuizMutation({
              variables: {
                input: {
                  trainingCategoryId: trainingQuiz.trainingCategory.id,
                  trainingQuizId: trainingQuiz.id,
                },
              },
            }).then((result) => {
              const quizInstanceId = result.data?.startTrainingQuiz.id
              if (quizInstanceId) {
                navigate(`/training-quiz/${quizInstanceId}`)
              }
            })
          }}
          loading={loading}
        >
          {t('Start')}
        </PrimaryButton>
      </Flex>
    </RoundedBox>
  )
}

const TrainingCategoryList = ({ category }: { category: TrainingCategory }) => {
  return (
    <Flex column>
      <PageContent>
        <Flex horizontal="space-between">
          <Text color="white" size="small">
            {category.title}
          </Text>
          <Text color="white" size="small">
            ({category.quizzes.length})
          </Text>
        </Flex>
      </PageContent>
      <LeaguesContainer>
        {category.quizzes.map((trainingQuiz) => {
          return (
            <TrainingQuizItem
              key={trainingQuiz.id}
              trainingQuiz={trainingQuiz}
            />
          )
        })}
      </LeaguesContainer>
    </Flex>
  )
}

const Content = ({
  trainingCategories,
}: {
  trainingCategories: TrainingCategory[]
}) => {
  return (
    <Flex column>
      {trainingCategories.map((category) => {
        return (
          <Fragment key={category.id}>
            <TrainingCategoryList category={category} />
            <Spacer height="huge" />
          </Fragment>
        )
      })}
    </Flex>
  )
}

const LoadContent = () => {
  const query = useTrainingPageQuery()
  const trainingQuizzesQuery = useTrainingQuizzes()

  if ((query.loading || trainingQuizzesQuery.loading) && !query.data) {
    return (
      <Flex horizontal="center">
        <Loader variant="green" />
      </Flex>
    )
  }

  if (query.error) {
    const reason = query.error?.message
    return (
      <DefaultError
        css={margin.top('huge')}
        sentryErrorMessage={`TrainingPage | reason: ${reason}`}
      />
    )
  }

  return <Content trainingCategories={query.data!.trainingCategories} />
}

export const TrainingPage = () => {
  const t = useT()
  const { authUser } = useAuthContext()
  const authenticated = Boolean(authUser)
  return (
    <Layout
      backgroundColor={colors.green600}
      withHeader={!(authenticated && !authUser?.isAnonymous)}
      profileBanner
      withFooter
      showCookiebotButton
      title={`fcQuiz | ${t('Training')}`}
    >
      <LoadContent />
    </Layout>
  )
}
