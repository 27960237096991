/** @jsxImportSource @emotion/react */
import { gql, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { PrimaryButton } from '../../../components/Button'
import { Modal } from '../../../components/modal/Modal'
import { TextArea, TextInput } from '../../../components/TextInput'
import { TextNew } from '../../../components/TextNew'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useForm } from '../../../utils/useForm'

type EditLeagueInput = {
  leagueId: string
  title: string
  description?: string
  hideOnHomePage: boolean
}

const updateLeagueInfoMutation = gql`
  mutation UpdateLeagueInfo($input: UpdateLeagueInput!) {
    updateLeagueInfo(input: $input) {
      id
      slug
      title
      description
      hideOnHomePage
    }
  }
`

const useUpdateLeagueInfoMutation = () => {
  return useMutation<
    {
      updateLeagueInfo: {
        id: string
        slug: string
        title: string
        description?: string
        hideOnHomePage: boolean
      }
    },
    { input: EditLeagueInput }
  >(updateLeagueInfoMutation)
}

export const EditLeagueModal = ({
  closeModal,
  league,
}: {
  closeModal: () => void
  league: {
    id: string
    slug: string
    title: string
    description?: string
    hideOnHomePage?: boolean
  }
}) => {
  const t = useT()

  const [updateMutation, { loading }] = useUpdateLeagueInfoMutation()

  const form = useForm({
    initialValues: {
      title: league.title,
      description: league.description,
      hideOnHomePage: league.hideOnHomePage ?? true,
    },

    onSubmit: async ({ values, setSubmitError }) => {
      try {
        await updateMutation({
          variables: {
            input: {
              leagueId: league.id,
              title: values.title,
              description:
                values.description === '' ? undefined : values.description,
              hideOnHomePage: values.hideOnHomePage,
            },
          },
        })

        closeModal()
      } catch (error) {
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })

  return (
    <Modal closeModal={closeModal} heading={t('Edit series information')}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <TextInput
          id="title"
          name="title"
          label={
            <TextNew
              color={colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Title')}
            </TextNew>
          }
          placeholder={t('Title')}
          autoComplete="off"
          value={form.values.title}
          onValue={(value) => {
            form.setValue('title', value)
          }}
          error={form.submitAttempted && form.fieldErrors.title}
          css={margin.top('medium')}
          maxLength={40}
        />
        <TextArea
          id="description"
          name="description"
          label={
            <TextNew
              color={colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Description')}
            </TextNew>
          }
          placeholder={t('Description')}
          autoComplete="off"
          maxLength={140}
          value={form.values.description || ''}
          onValue={(value) => {
            if (value === '') {
              form.setValue('description', undefined)
            } else {
              form.setValue('description', value)
            }
          }}
          error={form.submitAttempted && form.fieldErrors.description}
          css={margin.top('medium')}
        />
        <ToggleSwitch
          label={'Only show on club page'}
          value={form.values.hideOnHomePage}
          onValue={(value) => {
            form.setValue('hideOnHomePage', value)
          }}
        />
        <TextNew color={colors.grey400} size="small">
          {league.hideOnHomePage
            ? 'Series is currently only visible on the club page'
            : 'Series is visible throughout fcQuiz'}
        </TextNew>
        <Spacer height={'large'} />
        <PrimaryButton
          variant="black"
          type="submit"
          disabled={form.submitting || loading}
          loading={form.submitting}
          css={css`
            height: 48px;
            width: 100%;
          `}
        >
          {t('Save')}
        </PrimaryButton>
      </form>
    </Modal>
  )
}
