import { useT } from '../lib/i18n/useT'

interface SplitTitle {
  icon: string | null
  title: string
}

export const getIconAndTitle = (title?: string): SplitTitle => {
  if (!title) return { icon: null, title: '' }

  const titlearray = title.split(/ (.*)/s)

  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi

  const hasMainEmoji = regexExp.test(titlearray[0])

  return {
    icon: hasMainEmoji ? titlearray[0] : null,
    title: hasMainEmoji ? titlearray[1] : title,
  }
}

export const hashString = (str: string): number => {
  let hash = 0
  let i
  let chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

export const getMotivationalText = (correct: number, total: number) => {
  const t = useT()
  if (correct === 1) return `${t('You got one right..')} 🥶`

  const correctPercentage = (correct / total) * 100
  if (correctPercentage < 21) return `${t('Ouch! Better luck next time')} 💀`
  if (correctPercentage < 32)
    return `${t('Well, there’s room for improvement')} 😬`
  if (correctPercentage < 43) return `${t('You survived!')} 😅`
  if (correctPercentage < 54) return `${t('Not too shabby!')} 😊`
  if (correctPercentage < 65) return `${t('You know your football!')} ⚽️`
  if (correctPercentage < 76) return `${t('Great work!')} 💪`
  if (correctPercentage < 87) return `${t('Very impressive!')} 🔥`

  return `${t("Wow, you're the champion!")} 👑`
}
