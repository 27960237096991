/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import theme from '../../styles/theme'
import { Flex } from '../layout/Flex'
import { EmbedWrapper } from './EmbedWrapper'

export const Page: FC<{
  colorTheme?: 'white' | 'red'
  className?: string
}> = ({ colorTheme = 'white', children }) => {
  const backgroundColor =
    colorTheme === 'red' ? theme.colors.red500 : theme.colors.white
  return (
    <EmbedWrapper colorTheme={colorTheme}>
      <Flex
        column
        vertical="space-between"
        css={css`
          min-width: 280px;
          background-color: ${backgroundColor};
          border-radius: 4px;
        `}
      >
        <Flex
          column
          css={css`
            padding: 16px;
          `}
        >
          {children}
        </Flex>
      </Flex>
    </EmbedWrapper>
  )
}
