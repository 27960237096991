/** @jsxImportSource @emotion/react */

import { Keyframes, css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { ReactComponent as CoinBackground } from './CoinBackground.svg'
import { ReactComponent as CoinCheckmark } from './CoinCheckmark.svg'
import { ReactComponent as Coin } from './Coin.svg'
import { useEffect, useState } from 'react'
import { coinFlip, moveTowards } from '../../../styles/animations'
import ConfettiExplosion from 'react-confetti-explosion'
import { colors } from '../../../styles/theme'

function makePositionFixed(id: string) {
  const element = document.getElementById(id)
  if (!element) {
    console.log('Element not found')
    return
  }

  // Get the current position relative to the viewport
  const rect = element.getBoundingClientRect()

  // Change the position to fixed
  element.style.position = 'fixed'

  // Set the top and left properties to match the current position
  // Note: This assumes the element's margin is negligible for its positioning.
  // If the element has a margin, you might need to adjust these values accordingly.
  element.style.top = `${rect.top}px`
  element.style.left = `${rect.left}px`
}

export const CoinAnimated = ({
  index,
  length,
  className,
}: {
  index: number
  length: number
  className?: string
}) => {
  const [moveTowardsKeyframe, setMoveTowardsKeyframe] = useState<
    Keyframes | undefined
  >(undefined)

  const [moveTowardsKeyframeConfetti, setMoveTowardsKeyframeConfetti] =
    useState<Keyframes | undefined>(undefined)

  const [coinCheckmarkOpacity, setCoinCheckmarkOpacity] = useState(0)
  const [showConfetti, setShowConfetti] = useState(false)

  const delayMs = 1000

  useEffect(() => {
    setTimeout(() => {
      makePositionFixed(`coin${index}`)
      setMoveTowardsKeyframe(moveTowards(`coin${index}`, 'userCoinsPos', true))
      setCoinCheckmarkOpacity(1)
    }, delayMs + 500 + length * 250)
    setTimeout(() => {
      setMoveTowardsKeyframeConfetti(
        moveTowards(`confetti${index}`, 'userCoinsPos', true)
      )
      setShowConfetti(true)
    }, delayMs + 1500 + length * 250)
  }, [])

  return (
    <>
      <Flex className={className} vertical="center" horizontal="center">
        <Coin
          css={css`
            z-index: 1;
            position: absolute;
            animation: ${coinFlip} 0.5s forwards;
            animation-delay: ${delayMs / 1000 + 0.25 * index}s;
          `}
        />
        <Coin
          id={`coin${index}`}
          css={css`
            z-index: 10;
            position: absolute;
            opacity: 0;

            animation: ${moveTowardsKeyframe} 1s cubic-bezier(0.4, 0, 0.15, 1)
              forwards;
          `}
        />
        <CoinCheckmark
          css={css`
            z-index: 1;
            position: absolute;
            opacity: ${coinCheckmarkOpacity};

            transition-property: opacity;
            transition-duration: 1s;
          `}
        />
        <Flex
          css={css`
            position: absolute;
            width: 500px;
            animation: ${moveTowardsKeyframeConfetti} 0s forwards;
          `} //Fun hacky way to position it at the coins
          id={`confetti${index}`}
        >
          {showConfetti && (
            <ConfettiExplosion
              particleCount={10}
              force={0.2}
              width={500}
              duration={2500}
              zIndex={10}
              colors={[colors.white, colors.yellow300, colors.yellow400]}
            />
          )}
        </Flex>
        <CoinBackground
          css={css`
            position: absolute;
          `}
        />
        <CoinBackground />
      </Flex>
    </>
  )
}
