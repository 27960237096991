/** @jsxImportSource @emotion/react */

import { Layout } from '../../components/layout/Layout'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { PageContent } from '../../components/layout/PageContent'
import { Header } from '../../components/Header'
import { AdminNavbar } from './Navbar'
import { HeadingNew } from '../../components/HeadingNew'
import { Spacer } from '../../styles/margin'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { TextNew } from '../../components/TextNew'
import { colors } from '../../styles/theme'
import { useState } from 'react'
import { Flex } from '../../components/layout/Flex'
import { css } from '@emotion/react'
import { TextInput } from '../../components/TextInput'
import { useForm } from '../../utils/useForm'
import { useT } from '../../lib/i18n/useT'
import {
  Advertiser,
  useAddAdvertiserMemberMutation,
  useAdvertisersQuery,
  useCreateAdvertiserMutation,
} from './queries'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalTrigger } from '../../components/modal/Modal'
import { FaArrowRight, FaUserPlus } from 'react-icons/fa'
import { BiSolidDashboard } from 'react-icons/bi'
import { IoIosArrowBack } from 'react-icons/io'
import { Divider } from '../../components/Divider'

const validName = /^.{2,32}$/

const AddMemberModal = ({
  closeModal,
  advertiserId,
}: {
  closeModal: () => void
  advertiserId: string
}) => {
  const t = useT()
  const [email, setEmail] = useState('')
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [addMember] = useAddAdvertiserMemberMutation()

  return (
    <Modal closeModal={closeModal} heading={'Add member'}>
      <Flex column gap={'medium'}>
        <TextInput
          value={email}
          placeholder={'Email'}
          onValue={setEmail}
          type={'email'}
          css={css`
            width: 100%;
          `}
        />

        <Flex column gap={'small'}>
          {submitError && (
            <TextNew color={colors.red200}>{submitError}</TextNew>
          )}
          <Spacer height="medium" />
        </Flex>

        <Flex horizontal={'space-between'}>
          <SecondaryButton onClick={closeModal}>
            <TextNew color={colors.black}>{t('Cancel')}</TextNew>
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              addMember({ variables: { advertiserId, email } })
                .then(() => {
                  closeModal()
                })
                .catch((error) => {
                  console.error('Error while adding member', error)
                  setSubmitError(t('Something went wrong, try again'))
                })
            }}
          >
            <TextNew color={colors.white}>{t('Confirm')}</TextNew>
          </PrimaryButton>
        </Flex>
      </Flex>
    </Modal>
  )
}

const CreateAdvertiserForm = ({
  setShowCreateAdvertiser,
}: {
  setShowCreateAdvertiser: (show: boolean) => void
}) => {
  const t = useT()
  const [createAdvertiser] = useCreateAdvertiserMutation()

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      logo: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
    },
    validate: {
      name: (val) =>
        validName.test(val)
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
      email: (val) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)
          ? undefined
          : 'Invalid email address',
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        await createAdvertiser({
          variables: {
            input: { name: values.name, email: values.email },
          },
        })
          .then(() => {
            setShowCreateAdvertiser(false)
          })
          .catch((error) => {
            console.error('Error while creating advertiser', error)
            setSubmitError(t('Something went wrong, try again'))
          })
      } catch (error) {
        console.error('Error while creating advertiser', error)
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })
  return (
    <Flex column gap={'large'}>
      <TextNew strong size={24}>
        Create advertiser form
      </TextNew>

      {/* Form fields go here */}
      <Flex column>
        <TextNew>Name</TextNew>
        <Spacer height="small" />

        <TextInput
          value={form.values.name}
          placeholder={'Advertiser name'}
          onValue={(val) => form.setValue('name', val)}
          css={css`
            width: 100%;
          `}
        />
      </Flex>

      <Flex column>
        <TextNew>Email</TextNew>
        <Spacer height="small" />

        <TextInput
          value={form.values.email}
          placeholder={'Advertiser email'}
          onValue={(val) => form.setValue('email', val)}
          css={css`
            width: 100%;
          `}
        />
      </Flex>

      {/* image */}
      <Flex column></Flex>

      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <Flex column gap={'small'}>
          {form.fieldErrors && (
            <TextNew color={colors.red200}>
              {Object.values(form.fieldErrors).join(', ')}
            </TextNew>
          )}
          <Spacer height="medium" />
        </Flex>
        <Flex horizontal={'space-between'}>
          <SecondaryButton onClick={() => setShowCreateAdvertiser(false)}>
            <TextNew color={colors.black}>{t('Cancel')}</TextNew>
          </SecondaryButton>
          <PrimaryButton disabled={form.submitting} type={'submit'}>
            <TextNew color={colors.white}>{t('Create')}</TextNew>
          </PrimaryButton>
        </Flex>
      </form>
    </Flex>
  )
}

const AdvertiserDetails = ({
  advertiser,
  goBack,
}: {
  advertiser: Advertiser
  goBack: () => void
}) => {
  const navigate = useNavigate()

  return (
    <Flex
      column
      gap={'medium'}
      css={css`
        animation: slide-in 0.2s forwards;
        @keyframes slide-in {
          from {
            opacity: 0;
            transform: translateX(100%);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        `}
    >
      <TextNew>Name: {advertiser.name}</TextNew>
      <TextNew>Email: {advertiser.email}</TextNew>

      {/* Members */}
      <TextNew strong>Members:</TextNew>

      <Flex column gap={'small'}>
        {advertiser.members?.map((member) => (
          <TextNew key={member.id}>{member.username}</TextNew>
        ))}
      </Flex>

      <Spacer height="small" />

      <ModalTrigger
        button={({ openModal }) => (
          <PrimaryButton
            onClick={openModal}
            css={css`
              background-color: ${colors.green500};
              width: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
            `}
          >
            <FaUserPlus color={colors.white} />
            <TextNew color={colors.white}>Add member</TextNew>
          </PrimaryButton>
        )}
        modal={({ closeModal }) => (
          <AddMemberModal
            closeModal={closeModal}
            advertiserId={advertiser.id}
          />
        )}
      />

      <PrimaryButton
        css={css`
          width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        `}
        onClick={() => {
          navigate(`/advertiser/${advertiser.id}`)
        }}
      >
        <BiSolidDashboard color={colors.white} />
        <TextNew color={colors.white}> Go to dashboard</TextNew>
      </PrimaryButton>

      <SecondaryButton
        css={css`
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        onClick={goBack}
      >
        <IoIosArrowBack />
        <TextNew>Back</TextNew>
      </SecondaryButton>
    </Flex>
  )
}

const Content = () => {
  const [showCreateAdvertiser, setShowCreateAdvertiser] = useState(false)
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser | null>(null)
  const {
    data: advertisersData,
    loading: advertisersLoading,
    error: advertisersError,
  } = useAdvertisersQuery()

  return (
    <PageContent grow gap={'medium'}>
      <Spacer height="large" />

      <HeadingNew level={1} looksLikeLevel={3}>
        Advertisers
      </HeadingNew>

      {showCreateAdvertiser && (
        <CreateAdvertiserForm
          setShowCreateAdvertiser={setShowCreateAdvertiser}
        />
      )}

      <Flex
        gap={'medium'}
        css={css`
          width: 100%;
          overflow: hidden;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
          }
        `}
      >
        {advertisersLoading && <TextNew>Loading...</TextNew>}
        {advertisersError && <TextNew>{advertisersError.message}</TextNew>}
        {advertisersData && (
          <Flex
            column
            gap={'medium'}
            css={css`
              transition: all 0.2s ease-in-out;
            `}
          >
            {advertisersData.advertisers.map((advertiser) => (
              <Flex
                key={advertiser.id}
                horizontal={'space-between'}
                css={css`
                  padding: 10px 16px;
                  border: 1px solid ${colors.grey500};
                  border-radius: 10px;
                  background-color: ${selectedAdvertiser === advertiser
                    ? colors.green100
                    : colors.grey100};
                  cursor: pointer;
                  width: 100%;
                `}
                gap={'small'}
                onClick={() => setSelectedAdvertiser(advertiser)}
              >
                <Flex
                  gap={'small'}
                  css={css`
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    @media (max-width: 768px) {
                      flex-direction: column;
                    }
                  `}
                >
                  <TextNew>{advertiser.name}</TextNew>
                  <TextNew>{advertiser.email}</TextNew>
                </Flex>
                <FaArrowRight width={24} />
              </Flex>
            ))}
            {!showCreateAdvertiser && (
              <PrimaryButton onClick={() => setShowCreateAdvertiser(true)}>
                <TextNew color={colors.white}>Create new advertiser</TextNew>
              </PrimaryButton>
            )}
          </Flex>
        )}
        {selectedAdvertiser && (
          <Divider
            vertical
            css={css`
              width: 1px;
              max-width: 1px;
            `}
          />
        )}
        {selectedAdvertiser && (
          <div
            css={css`
              animation: slide-in 0.2s forwards;
              @keyframes slide-in {
                from {
                  opacity: 0;
                  transform: translateX(100%);
                }
                to {
                  opacity: 1;
                  transform: translateX(0);
                }
              }
              @media (max-width: 768px) {
                border-bottom: 1px solid ${colors.grey500};
                padding-bottom: 16px;
              }
            `}
          >
            <AdvertiserDetails
              advertiser={selectedAdvertiser}
              goBack={() => setSelectedAdvertiser(null)}
            />
          </div>
        )}
      </Flex>
      <Spacer height="large" />
    </PageContent>
  )
}

export const AdminAdvertisersPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="advertisers" />
        </PageContent>
        <Content />
      </AdminAccessControlComponent>
    </Layout>
  )
}
