export type UnsubscribeFunc = () => void

export const createObserver = <TCallback extends Function, TArg>() => {
  let observers: TCallback[] = []

  const remove = (callback: TCallback) => {
    observers = observers.filter((observer) => observer !== callback)
  }

  const add = (callback: TCallback): UnsubscribeFunc => {
    observers.push(callback)
    return () => remove(callback)
  }

  const notifyAll = (arg: TArg) => {
    observers.forEach((observer) => observer(arg))
  }

  return {
    add,
    remove,
    notifyAll,
  }
}
