/** @jsxImportSource @emotion/react */

import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../../components/Button'
import { CroppedImageForm } from '../../../components/CroppedImageForm'
import { Flex } from '../../../components/layout/Flex'
import { Loader } from '../../../components/Loader'
import { TextInput } from '../../../components/TextInput'
import { TextNew } from '../../../components/TextNew'
import { PROFILE_PICTURE_SIZE } from '../../../config/config'
import { Locale } from '../../../lib/i18n/locale'
import { useT } from '../../../lib/i18n/useT'
import { uploadImageToFirebase } from '../../../lib/uploadToStorage'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useForm } from '../../../utils/useForm'
import { hexColorRegex } from '../../Admin/Utils'
import { LanguagePicker } from '../../Clubs/ClubProfilePage/EmbedCodeModal'
import { ChallengeLeaguePreviewComponent } from '../components/ChallengeLeaguePreviewComponent'
import {
  challengeGroupQuery,
  ChallengeLeague,
  useUpdateChallengeGroupBackgroundImageMutation,
  useUpdateChallengeGroupImageMutation,
  useUpdateChallengeGroupMutation,
} from '../queries'

export const UpdateInfoForm = ({ league }: { league: ChallengeLeague }) => {
  const t = useT()
  const [updateGroup] = useUpdateChallengeGroupMutation()
  const [updateImage] = useUpdateChallengeGroupImageMutation()
  const [updateBackgroundImage] =
    useUpdateChallengeGroupBackgroundImageMutation()

  const [loading, setLoading] = useState(false)

  const [updateSuccess, setUpdateSuccess] = useState(false)

  const apolloClient = useApolloClient()

  useEffect(() => {
    if (updateSuccess) {
      const timeout = setTimeout(() => {
        setUpdateSuccess(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [updateSuccess])

  const form = useForm({
    initialValues: {
      title: league.title ?? '',
      locale: league.locale ?? Locale.en,
      description: league.description ?? '',
      iconImage: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      backgroundImage: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      backgroundColor: league.backgroundColor ?? colors.grey100,
      textColor: league.textColor ?? colors.black,
    },
    validate: {
      // at least 3 characters required
      title: (val) =>
        val.trim().length >= 3
          ? undefined
          : t(`At least 3 characters required`),

      description: (val) =>
        val.trim().length <= 100
          ? undefined
          : t(`At least 3 characters required and max 100 characters allowed`),
      backgroundColor: (val) =>
        val &&
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
      textColor: (val) =>
        val &&
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
    },
    onSubmit: async ({ values }) => {
      const promises: Promise<any>[] = []
      setLoading(true)

      if (values.iconImage?.file) {
        const uri = await uploadImageToFirebase(
          `challenge-league/${league.id}`,
          values.iconImage.file
        ).getImageUri()

        promises.push(
          updateImage({
            variables: {
              input: {
                groupId: league.id,
                imageUrl: uri,
              },
            },
          })
        )
      }

      if (values.backgroundImage?.file) {
        const uri = await uploadImageToFirebase(
          `challenge-league/${league.id}`,
          values.backgroundImage.file
        ).getImageUri()

        promises.push(
          updateBackgroundImage({
            variables: {
              input: {
                groupId: league.id,
                imageUrl: uri,
              },
            },
          })
        )
      }

      const description =
        values.description.trim() === '' ? undefined : values.description.trim()

      const res = await updateGroup({
        variables: {
          input: {
            groupId: league.id,
            title: values.title.trim(),
            description,
            backgroundColor: values.backgroundColor,
            textColor: values.textColor,
            locale: values.locale,
          },
        },
      })

      await Promise.all(promises)

      await apolloClient.refetchQueries({
        include: [challengeGroupQuery],
      })

      if (res.data?.updateChallengeGroup.id) {
        setUpdateSuccess(true)
      }
      setLoading(false)
    },
  })

  return (
    <form
      css={css`
        border-radius: 8px;
        padding: 16px;
        background: ${colors.grey200};
        opacity: ${loading ? 0.5 : 1};
      `}
      onSubmit={(event) => {
        event.preventDefault()
        form.submitForm()
      }}
    >
      {loading && <Loader />}
      <TextInput
        id="title"
        name="title"
        label={
          <TextNew
            color={colors.green600}
            size="small"
            css={
              (margin.bottom('tiny'),
              css`
                text-align: left;
              `)
            }
          >
            {t('Title')}
          </TextNew>
        }
        placeholder={t('League title')}
        autoComplete="off"
        value={form.values.title}
        onValue={(value) => {
          form.setValue('title', value)
        }}
        error={form.submitAttempted && form.fieldErrors.title}
        css={margin.top('medium')}
      />
      <Spacer height={40} />
      <TextNew>{t('Language')}</TextNew>
      <Spacer height={4} />
      <LanguagePicker
        locale={form.values.locale}
        onLocale={(value) => {
          form.setValue('locale', value)
        }}
      />
      {/* <TextInput
          id="description"
          name="description"
          label={
            <TextNew
              color={colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Description')}
            </TextNew>
          }
          placeholder={t('League description')}
          autoComplete="off"
          value={form.values.description}
          onValue={(value) => {
            form.setValue('description', value)
          }}
          error={form.submitAttempted && form.fieldErrors.description}
          css={margin.top('medium')}
        />
        <Spacer height={40} /> */}
      <Spacer height={40} />
      <CroppedImageForm
        label={t(`League image`)}
        minWidth={PROFILE_PICTURE_SIZE.width}
        minHeight={PROFILE_PICTURE_SIZE.height}
        currentImage={league.iconImageUrl ? league.iconImageUrl : undefined}
        setCropped={(croppedOptions) =>
          form.setValue('iconImage', croppedOptions)
        }
        textColor="black"
      />
      <Spacer height={40} />
      <CroppedImageForm
        label={t(`Background image`)}
        minWidth={PROFILE_PICTURE_SIZE.width}
        minHeight={PROFILE_PICTURE_SIZE.height}
        currentImage={
          league.backgroundImageUrl ? league.backgroundImageUrl : undefined
        }
        setCropped={(croppedOptions) =>
          form.setValue('backgroundImage', croppedOptions)
        }
        textColor="black"
      />
      <Flex vertical="flex-end" horizontal="space-between">
        <TextInput
          id="backgroundColor"
          name="backgroundColor"
          label={
            <TextNew
              size="small"
              css={css`
                text-align: left;
              `}
            >
              {t(`Background color`)}
            </TextNew>
          }
          placeholder={t('Background color')}
          autoComplete="off"
          value={form.values.backgroundColor}
          maxLength={7}
          onValue={(value) => {
            form.setValue('backgroundColor', value.trim().toUpperCase())
          }}
          error={form.submitAttempted && form.fieldErrors.backgroundColor}
          css={margin.top('medium')}
        />
        <input
          aria-label={t('Background color')}
          css={css`
            height: 48px;
            width: 48px;
          `}
          type="color"
          value={form.values.backgroundColor ?? colors.grey100}
          onChange={(e) => {
            form.setValue('backgroundColor', e.target.value)
          }}
        />
      </Flex>
      <Spacer height={16} />

      <Flex vertical="center" horizontal="space-between">
        <TextNew
          color={colors.green600}
          size="small"
          css={css`
            text-align: left;
          `}
        >
          {t('Text color')}
        </TextNew>
        <Flex vertical="center">
          <label htmlFor="black">
            <TextNew>{t('Black')}</TextNew>
          </label>

          <input
            id="black"
            type="radio"
            name="textColor"
            value="black"
            checked={
              form.values.textColor.toLowerCase() === colors.black.toLowerCase()
            }
            onChange={() => form.setValue('textColor', colors.black)}
            css={css`
              height: 40px;
              width: 40px;
            `}
          />
          <Spacer width={16} />
          <label htmlFor="white">
            <TextNew>{t('White')}</TextNew>
          </label>
          <input
            id="white"
            type="radio"
            name="textColor"
            value="black"
            checked={
              form.values.textColor.toLowerCase() === colors.white.toLowerCase()
            }
            onChange={() => form.setValue('textColor', colors.white)}
            css={css`
              height: 40px;
              width: 40px;
            `}
          />
        </Flex>
      </Flex>
      <Spacer height={32} />

      <ChallengeLeaguePreviewComponent
        title={form.values.title}
        backgroundColor={form.values.backgroundColor}
        textColor={form.values.textColor}
      />

      <Spacer height={32} />
      <Flex css={margin.top('large')} column horizontal="center">
        <PrimaryButton
          variant="black"
          type="submit"
          disabled={form.submitting || loading || updateSuccess}
          loading={form.submitting}
          css={css`
            height: 48px;
            width: 100%;
          `}
        >
          {updateSuccess ? `✅ ${t(`Update was successfull`)}` : t('Save')}
        </PrimaryButton>
      </Flex>
    </form>
  )
}
