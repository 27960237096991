/** @jsxImportSource @emotion/react */

import { useCallback, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { ConfirmModal } from '../../../components/ConfirmModal'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { SelectInput } from '../../../components/SelectInput'
import { Text } from '../../../components/Text'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { buttonReset } from '../../../styles/styles'
import { formatClubRole } from '../clubUtils'
import {
  ClubRole,
  ClubWithMembers,
  useRemoveFromClubMutation,
  useTransferClubAdmin,
  useUpdateClubMember,
} from '../queries'

export const EditMemberModal = ({
  member,
  clubId,
  viewerRole,
  closeModal,
}: {
  member: ClubWithMembers['members'][0]
  clubId: string
  viewerRole: ClubRole
  closeModal: () => void
}) => {
  const t = useT()

  const [role, setRole] = useState(member.role)

  const [updateClubMemberMutation, { loading }] = useUpdateClubMember()
  const [removeFromClub, { loading: removeFromClubLoading }] =
    useRemoveFromClubMutation()
  const [transferClubAdmin, { loading: transferClubAdminLoading }] =
    useTransferClubAdmin()

  const handleSubmit = useCallback(async () => {
    await updateClubMemberMutation({
      variables: {
        input: {
          clubId,
          userId: member.user.id,
          role,
        },
      },
    })

    closeModal()
  }, [closeModal, clubId, member.user.id, role, updateClubMemberMutation])

  return (
    <Modal heading={t('Edit member')} closeModal={closeModal}>
      <Text>
        {t('Player name')}: {member.user.displayName}
      </Text>
      <Spacer height="medium" />

      <SelectInput
        label={t('Role')}
        value={role}
        onValue={(role) => setRole(role)}
        disabled={member.role === 'admin'}
      >
        <option value="admin" disabled>
          {formatClubRole(t, 'admin')}
        </option>
        <option value="moderator" disabled={viewerRole !== 'admin'}>
          {formatClubRole(t, 'moderator')}
        </option>
        <option value="senior">{formatClubRole(t, 'senior')}</option>
        <option value="member">{formatClubRole(t, 'member')}</option>
      </SelectInput>

      {viewerRole === 'admin' && member.role !== 'admin' && (
        <Flex column horizontal="flex-start">
          <Spacer height="medium" />
          <Text>{t('Remove player from club?')}</Text>
          <Spacer height="small" />

          <ConfirmModal
            button={({ openModal }) => (
              <SecondaryButton
                onClick={openModal}
                loading={removeFromClubLoading}
                variant="dangerRed"
              >
                {t('Remove from club')}
              </SecondaryButton>
            )}
            onConfirm={() => {
              removeFromClub({
                variables: {
                  input: { clubId, userId: member.user.id },
                },
              }).then(() => closeModal())
            }}
            loading={removeFromClubLoading}
          />

          <Spacer height="medium" />
          <Text>
            {t('Transfer ownership of the club to {{name}}?', {
              name: member.user.displayName,
            })}
          </Text>
          <Spacer height="small" />
          <ConfirmModal
            button={({ openModal }) => (
              <SecondaryButton
                onClick={openModal}
                loading={transferClubAdminLoading}
                variant="dangerRed"
              >
                {t('Transfer ownership')}
              </SecondaryButton>
            )}
            onConfirm={() => {
              transferClubAdmin({
                variables: {
                  clubId,
                  toUserId: member.user.id,
                },
              }).then(() => closeModal())
            }}
            loading={transferClubAdminLoading}
            content={t('You will no longer be owner this club.')}
          />
        </Flex>
      )}

      <Flex
        css={margin.top('large')}
        horizontal="flex-end"
        vertical="center"
        gap="medium"
      >
        <button css={[buttonReset]} onClick={closeModal}>
          <Text>{t('Cancel')}</Text>
        </button>
        <PrimaryButton onClick={handleSubmit} loading={loading}>
          {t('Save')}
        </PrimaryButton>
      </Flex>
    </Modal>
  )
}
