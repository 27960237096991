/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useSiteSettings } from '../lib/useSiteSettings'
import { margin } from '../styles/margin'
import { buttonReset } from '../styles/styles'
import { colors } from '../styles/theme'
import { CrossSmall } from './icons'
import { Flex } from './layout/Flex'
import { PageContent } from './layout/PageContent'
import { RoundedBox } from './RoundedBox'
import { Text } from './Text'

export const MaintenanceMessage = () => {
  const { maintenanceMessage } = useSiteSettings()

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    const hideMaintenanceMessage = sessionStorage.getItem(
      'hideMaintenanceMessage'
    )

    if (!hideMaintenanceMessage) {
      setShowMessage(true)
    }
  }, [])

  if (!showMessage || !maintenanceMessage) {
    return null
  }

  return (
    <PageContent css={margin.top('large')}>
      <RoundedBox backgroundColor={colors.blue100}>
        <Flex horizontal="space-between" vertical="flex-start">
          <Text>
            <span
              dangerouslySetInnerHTML={{ __html: maintenanceMessage }}
              css={css`
                a {
                  color: ${colors.blue400};
                }
              `}
            />
          </Text>

          <button
            css={buttonReset}
            onClick={() => {
              setShowMessage(false)
              sessionStorage.setItem('hideMaintenanceMessage', 'true')
            }}
          >
            <CrossSmall />
          </button>
        </Flex>
      </RoundedBox>
    </PageContent>
  )
}
