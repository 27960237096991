/** @jsxImportSource @emotion/react */

import { useT } from '../lib/i18n/useT'
import { MaybePromise } from '../lib/types'
import { margin, Spacer } from '../styles/margin'
import { buttonReset } from '../styles/styles'
import { PrimaryButton } from './Button'
import { Flex } from './layout/Flex'
import { Modal, ModalTrigger, ModalTriggerProps } from './modal/Modal'
import { Text } from './Text'

type ConfirmModalProps = {
  button: ModalTriggerProps['button']
  headingText?: string
  content?: React.ReactNode
  confirmText?: string
  cancelText?: string
  onConfirm: () => MaybePromise<void>
  onCancel?: () => MaybePromise<void>
  loading?: boolean
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const t = useT()

  const headingText = props.headingText ?? t('Are you sure?')
  const confirmText = props.confirmText ?? t('Yes')
  const cancelText = props.cancelText ?? t('Cancel')

  return (
    <ModalTrigger
      button={props.button}
      modal={({ closeModal }) => (
        <Modal heading={headingText} closeModal={closeModal} maxWidth={400}>
          {typeof props.content === 'string' ? (
            <Text>{props.content}</Text>
          ) : (
            props.content
          )}
          <Flex horizontal="flex-end" vertical="center" css={margin.top()}>
            <button
              css={buttonReset}
              onClick={() => {
                closeModal()
                props.onCancel?.()
              }}
              disabled={props.loading}
            >
              {cancelText}
            </button>
            <Spacer width="medium" />
            <PrimaryButton
              loading={props.loading}
              onClick={async () => {
                await props.onConfirm()
                closeModal()
              }}
            >
              {confirmText}
            </PrimaryButton>
          </Flex>
        </Modal>
      )}
    />
  )
}
