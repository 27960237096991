/** @jsxImportSource @emotion/react */

import { FC, useState } from 'react'
import { useTimeout } from '../utils/useTimeout'

export const HideAfterDelay: FC<{ delayMs: number; onHidden?: () => void }> = ({
  delayMs,
  onHidden,
  children,
}) => {
  const [show, setShow] = useState(true)
  useTimeout(() => {
    setShow(false)
    onHidden?.()
  }, delayMs)

  return show ? <>{children}</> : null
}
