import { Selector } from '../Selector'

import { ReactComponent as SkinA } from './Skin A.svg'

export const skinStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    premium: boolean
  }
} = {
  SkinA: { svg: <SkinA />, premium: false },
}

export const Skin = () => {
  return <Selector selectedOptionKey={'skin'} styles={skinStyles} />
}
