/** @jsxImportSource @emotion/react */

import { Flex } from './layout/Flex'
import { Fragment } from 'react'
import { Spacer } from '../styles/margin'
import { PageContent } from './layout/PageContent'
import { Text } from './Text'
import { LeaguesContainer } from '../views/Home/LeaguesContainer'
import { useRecommendedLeagueQuizzes } from '../views/Home/queries'
import { useT } from '../lib/i18n/useT'
import { PlayMoreQuizComponent } from './leagueQuiz/PlayMoreQuizComponent'

export const RecommendedLeagueQuizzesComponent = () => {
  const t = useT()
  const recommendedQuizzes = useRecommendedLeagueQuizzes()

  return (
    <Flex column>
      {recommendedQuizzes.length > 0 && (
        <Fragment>
          <Spacer height="large" />
          <PageContent>
            <Flex horizontal="space-between">
              <Text color="white" size="small">
                {t('Popular quizzes')}
              </Text>
            </Flex>
          </PageContent>

          <Spacer height="medium" />
          <LeaguesContainer>
            {recommendedQuizzes.map((quiz) => (
              <PlayMoreQuizComponent key={quiz.id} quiz={quiz} />
            ))}
          </LeaguesContainer>
        </Fragment>
      )}
    </Flex>
  )
}
