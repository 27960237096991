/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Loader } from '../../components/Loader'
import { ModalTrigger } from '../../components/modal/Modal'
import { TextNew } from '../../components/TextNew'
import { useT } from '../../lib/i18n/useT'
import { useChallengeLeaguesQuery } from '../Challenge/queries'
import { AddLiveQuizToLeagueModal } from './AddLiveQuizToLeagueModal'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'

const Content = () => {
  const query = useChallengeLeaguesQuery({ hideFollowing: false })
  const t = useT()

  if (query.loading) {
    return <Loader center />
  }

  const leagues = query.data?.challengeLeagues || []

  return (
    <Flex
      column
      css={css`
        margin-top: 24px;
      `}
    >
      <TextNew>Challenge leagues</TextNew>
      <Flex
        column
        gap={'small'}
        css={css`
          margin-top: 24px;
        `}
      >
        {leagues.map((league) => (
          <Flex
            horizontal="space-between"
            vertical="center"
            gap={'medium'}
            key={league.id}
            css={css`
              padding: 16px;
              border-radius: 4px;
              background-color: ${league.backgroundColor};

              flex-direction: row;
              justify-content: space-between;

              @media (max-width: 600px) {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              }
            `}
          >
            <Flex
              gap={'small'}
              css={css`
                width: 100%;
                justify-content: flex-start;

                @media (max-width: 600px) {
                  width: 100%;
                  justify-content: space-between;
                }
              `}
            >
              <TextNew strong italic color={league.textColor}>
                {league.title}
              </TextNew>
              <a
                href={`/league/${league.slug}/settings`}
                css={css`
                  text-decoration-color: ${league.textColor};
                `}
              >
                <TextNew italic color={league.textColor}>
                  View
                </TextNew>
              </a>
            </Flex>
            <ModalTrigger
              button={({ openModal }) => (
                <PrimaryButton
                  onClick={openModal}
                  css={css`
                    background-color: ${league.textColor};
                  `}
                >
                  <TextNew color={league.backgroundColor}>
                    {t('Add live quiz')}
                  </TextNew>
                </PrimaryButton>
              )}
              modal={({ closeModal }) => (
                <AddLiveQuizToLeagueModal
                  closeModal={closeModal}
                  league={league}
                />
              )}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export const AdminChallengeLeaguePage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="challenge-leagues" />
          <Content />
        </PageContent>
      </AdminAccessControlComponent>
    </Layout>
  )
}
