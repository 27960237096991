/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useEffect } from 'react'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { SanityQuestion } from '../../lib/sanity/types'
import { fadeIn } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import { PrimaryButton } from '../Button'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import { AlternativeButton } from '../leagueQuiz/AlternativeButton'
import { QuizStatusComponent } from '../leagueQuiz/QuizStatusComponent'
import { QuestionAnswer } from './ClientSideQuiz'
import { DefaultError } from '../DefaultError'
import { QuizStatistics } from './queries'

export const LastCompletedQuestionScreen: FC<{
  lastCompletedQuestion: SanityQuestion
  allQuestionsAnswered: boolean
  triggerCalculation: () => void
  nextQuestion: () => void
  isEmbed: boolean
  clubLeagueSubscriptionId?: string
  quizId: string
  leagueId?: string
  questionsCount: number
  currentQuestionIndex: number
  answer?: QuestionAnswer
  quizStatistics?: QuizStatistics
}> = ({
  lastCompletedQuestion,
  allQuestionsAnswered,
  triggerCalculation,
  nextQuestion,
  isEmbed,
  clubLeagueSubscriptionId,
  quizId,
  leagueId,
  questionsCount,
  currentQuestionIndex,
  answer,
  quizStatistics,
}) => {
  const t = useT()

  const { campaignHeaderHeight, clubId } = useLeagueQuizContext()

  const statsForQuestion = quizStatistics?.questions.find(
    (it) => it.id === lastCompletedQuestion.id
  )

  useEffect(() => {
    if (allQuestionsAnswered) {
      trackEvent(AnalyticsEventType.QuizCompleted, {
        type: 'League quiz',
        leagueId,
        leagueQuizId: quizId,
        isEmbed,
        clubLeagueSubscriptionId,
        clubId,
      })
    }
  }, [allQuestionsAnswered, quizId, leagueId])

  const textColor = theme.colors.black

  if (!answer) {
    return (
      <DefaultError
        sentryErrorMessage={'ClientSideQuiz: answer is undefined'}
      />
    )
  }

  return (
    <PageContent
      css={css`
        padding-top: ${isEmbed ? 0 : 24}px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        css={css`
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        `}
      >
        <Spacer height={14} />
        <QuizStatusComponent
          currentQuestion={currentQuestionIndex}
          totalQuestions={questionsCount}
          points={answer.score + answer.timeBonus}
        />
        <Spacer height={isEmbed ? 0 : 'small'} />
        <Text
          key={lastCompletedQuestion.text}
          color={textColor}
          css={margin.vertical(isEmbed ? 'small' : 'medium')}
          textAlign="center"
          size="medium"
        >
          {lastCompletedQuestion.text}
        </Text>

        {!lastCompletedQuestion.alternatives.some(
          (alternative) => alternative.id === answer.alternativeId
        ) && (
          <Text color={colors.red300} css={margin.bottom()} textAlign="center">
            {t('Unfortunately, the time ran out!')}
          </Text>
        )}
        <Flex column>
          {lastCompletedQuestion.alternatives.map((alternative) => (
            <AlternativeButton
              key={alternative.id}
              onClick={() => {}}
              selected={alternative.id === answer.alternativeId}
              correct={alternative.correct}
              showGlobalStats
              percentageAnswered={
                statsForQuestion?.alternatives.find(
                  (it) => it.id === alternative.id
                )?.percentage || 0
              }
            >
              {alternative.text}
            </AlternativeButton>
          ))}

          <Flex
            column
            css={[
              margin.top(isEmbed ? 'small' : 'large'),
              css`
                opacity: 0;
                animation: ${fadeIn} 600ms ease-out;
                animation-delay: 2000ms;
                animation-fill-mode: forwards;
              `,
            ]}
          >
            {allQuestionsAnswered ? (
              <PrimaryButton
                onClick={() => triggerCalculation()}
                css={css`
                  background-color: 'black';
                  height: 50px;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Finish')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={nextQuestion}
                css={css`
                  background-color: black;
                  color: white;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                {t('Next')}
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Flex>
    </PageContent>
  )
}
