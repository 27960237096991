import { gql, useMutation, useQuery } from '@apollo/client'
import { ActiveQuestion, CompletedQuestion } from '../LeagueQuiz/queries'

export enum TrainingQuizInstanceStatus {
  active = 'active',
  completed = 'completed',
}

export type TrainingQuizInstance = {
  id: string
  status: TrainingQuizInstanceStatus
  trainingQuiz: {
    id: string
    title: string
    questionCount: number
    trainingCategory: {
      id: string
      title: string
    }
  }
  completedQuestions: CompletedQuestion[]
  activeQuestion?: ActiveQuestion
}

export const useTrainingQuizInstanceQuery = (id: string) => {
  return useQuery<{ trainingQuizInstance?: TrainingQuizInstance }>(
    gql`
      query trainingQuizInstance($id: ID!) {
        trainingQuizInstance(id: $id) {
          id
          status
          trainingQuiz {
            id
            title
            questionCount
            trainingCategory {
              id
              title
            }
          }
          completedQuestions {
            id
            text
            imageUrl
            secondsLeft
            alternatives {
              id
              isCorrectAlternative
              selectedAlternative
              text
            }
          }
          activeQuestion {
            id
            text
            imageUrl
            alternatives {
              id
              text
            }
            secondsLeft
            maxTimeToAnswerInSeconds
          }
        }
      }
    `,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    }
  )
}

export const useStartTrainingQuizMutation = () => {
  return useMutation<
    { startTrainingQuiz: { id: string } },
    {
      input: {
        trainingCategoryId: string
        trainingQuizId: string
      }
    }
  >(
    gql`
      mutation startTrainingQuiz($input: StartTrainingQuizInput!) {
        startTrainingQuiz(input: $input) {
          id
        }
      }
    `,
    { fetchPolicy: 'no-cache' }
  )
}

export const useNextTrainingQuestionMutation = () => {
  return useMutation<unknown, { input: { trainingQuizInstanceId: string } }>(
    gql`
      mutation nextTrainingQuestion($input: NextTrainingQuestionInput!) {
        nextTrainingQuestion(input: $input) {
          id
          completedQuestions {
            id
            text
            alternatives {
              id
              isCorrectAlternative
              selectedAlternative
              text
            }
          }
          activeQuestion {
            id
            text
            imageUrl
            alternatives {
              id
              text
            }
            secondsLeft
            maxTimeToAnswerInSeconds
          }
        }
      }
    `
  )
}

export const useAnswerTrainingQuestionMutation = () => {
  return useMutation<
    { answerTrainingQuestion: TrainingQuizInstance },
    {
      input: {
        trainingQuizInstanceId: string
        questionId: string
        alternativeId: string | null
      }
    }
  >(
    gql`
      mutation answerTrainingQuestion($input: AnswerTrainingQuestionInput!) {
        answerTrainingQuestion(input: $input) {
          id
          completedQuestions {
            id
            text
            alternatives {
              id
              isCorrectAlternative
              selectedAlternative
              text
            }
          }
          activeQuestion {
            id
            text
            alternatives {
              id
              text
            }
            secondsLeft
            maxTimeToAnswerInSeconds
          }
        }
      }
    `
  )
}
