/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Text } from '../../components/Text'
import { TextInput } from '../../components/TextInput'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { useT } from '../../lib/i18n/useT'
import { Spacer, margin } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import {
  useCreateChallengeGroupMutation,
  useUpdateChallengeGroupImageMutation,
} from './queries'

import { useState } from 'react'
import { LeagueItemSubscribe } from '../Home/LeagueItemSubscribe'
import { useLibraryLeagueQuery } from '../Library/queries'

import { CroppedImageForm } from '../../components/CroppedImageForm'
import { TextNew } from '../../components/TextNew'
import { PROFILE_PICTURE_SIZE } from '../../config/config'
import { uploadImageToFirebase } from '../../lib/uploadToStorage'
import { hexColorRegex } from '../Admin/Utils'
import { HomePageLeague } from '../Home/queries'
import { ChallengeLeaguePreviewComponent } from './components/ChallengeLeaguePreviewComponent'
import { useViewerQuery } from '../../lib/AuthContext'
import { Locale } from '../../lib/i18n/locale'
import { LanguagePicker } from '../Clubs/ClubProfilePage/EmbedCodeModal'

export const CreateChallengeLeagueModal = ({
  closeModal,
  groupId,
  currentLeague,
}: {
  closeModal: () => void
  groupId?: string
  currentLeague?: HomePageLeague
}) => {
  const [createGroupMutation] = useCreateChallengeGroupMutation()
  const [updateImage] = useUpdateChallengeGroupImageMutation()

  const viewerQuery = useViewerQuery()

  const navigate = useNavigate()
  const t = useT()

  const { data: activeLeagues } = useLibraryLeagueQuery()

  const [search, setSearch] = useState('')
  const [selectedLeague, setSelectedLeague] = useState<HomePageLeague | null>(
    currentLeague || null
  )

  const form = useForm({
    initialValues: {
      title: '',
      description: '',
      locale: viewerQuery.data?.viewer?.locale || Locale.en,
      iconImage: null as {
        left: number
        top: number
        width: number
        height: number
        file: File
      } | null,
      backgroundColor: colors.grey100,
      textColor: colors.black,
    },
    validate: {
      title: (val) =>
        val.trim()
          ? undefined
          : t(`Between 5 and 16 characters, only letters and numbers`),
      backgroundColor: (val) =>
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
      textColor: (val) =>
        val.trim() &&
        val.length === 7 &&
        val.startsWith('#') &&
        hexColorRegex.test(val)
          ? undefined
          : t(`Invalid color`),
    },
    onSubmit: async ({ values }) => {
      if (!selectedLeague) {
        return
      }

      const promises: Promise<any>[] = []

      const description =
        values.description.trim() === '' ? undefined : values.description.trim()

      const res = await createGroupMutation({
        variables: {
          input: {
            title: values.title.trim(),
            description,
            groupId,
            subcriptionLeagueId: selectedLeague.id,
            backgroundColor: values.backgroundColor,
            textColor: values.textColor,
            locale: values.locale,
          },
        },
      })
      if (res.data?.createChallengeGroup?.id) {
        const league = res.data.createChallengeGroup
        if (values.iconImage?.file) {
          const uri = await uploadImageToFirebase(
            `challenge-league/${league.id}`,
            values.iconImage.file
          ).getImageUri()

          promises.push(
            updateImage({
              variables: {
                input: {
                  groupId: league.id,
                  imageUrl: uri,
                },
              },
            })
          )
        }
        await Promise.all(promises)
        closeModal()
        //was challenge-league
        navigate(`/league/${res.data.createChallengeGroup.id}`)
      }
    },
  })
  return (
    <Modal closeModal={closeModal} heading={t('Create league')}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
      >
        <Flex
          column
          gap="small"
          css={css`
            width: 100%;
          `}
        >
          <Text color={colors.grey400}>
            {t(
              'A league is a place where you can compete regularly with your friends. Subscribe the league to a quiz, and start competing!'
            )}
          </Text>

          <div
            css={css`
              height: 1px;
              background-color: ${theme.colors.grey200};
            `}
          />
        </Flex>
        <TextInput
          id="title"
          name="title"
          label={
            <Text
              color={theme.colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Title')}
            </Text>
          }
          placeholder={t('League title')}
          autoComplete="off"
          value={form.values.title}
          onValue={(value) => {
            form.setValue('title', value)
          }}
          error={form.submitAttempted && form.fieldErrors.title}
          css={margin.top('medium')}
        />
        <Spacer height={40} />
        <TextNew>{t('Language')}</TextNew>
        <Spacer height={4} />
        <LanguagePicker
          locale={form.values.locale}
          onLocale={(value) => {
            form.setValue('locale', value)
          }}
        />
        {/* <TextInput
          id="description"
          name="description"
          label={
            <Text
              color={theme.colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Description')}
            </Text>
          }
          placeholder={t('League description')}
          autoComplete="off"
          value={form.values.description}
          onValue={(value) => {
            form.setValue('description', value)
          }}
          error={form.submitAttempted && form.fieldErrors.description}
          css={margin.top('medium')}
        /> */}
        <CroppedImageForm
          label={t(`League image`)}
          minWidth={PROFILE_PICTURE_SIZE.width}
          minHeight={PROFILE_PICTURE_SIZE.height}
          currentImage={undefined}
          setCropped={(croppedOptions) =>
            form.setValue('iconImage', croppedOptions)
          }
          textColor="black"
        />
        <Flex vertical="flex-end" horizontal="space-between">
          <TextInput
            id="backgroundColor"
            name="backgroundColor"
            label={
              <Text
                size="small"
                css={
                  (margin.bottom('tiny'),
                  css`
                    text-align: left;
                  `)
                }
              >
                {t(`Background color`)}
              </Text>
            }
            placeholder={t('Background color')}
            autoComplete="off"
            value={form.values.backgroundColor}
            maxLength={7}
            onValue={(value) => {
              form.setValue('backgroundColor', value.trim().toUpperCase())
            }}
            error={form.submitAttempted && form.fieldErrors.backgroundColor}
            css={margin.top('medium')}
          />
          <input
            aria-label={t('Background color')}
            css={css`
              height: 48px;
              width: 48px;
              margin-bottom: 8px;
            `}
            type="color"
            value={form.values.backgroundColor}
            onChange={(e) => form.setValue('backgroundColor', e.target.value)}
          />
        </Flex>

        <Flex vertical="center" horizontal="space-between">
          <TextNew
            color={colors.green600}
            size="small"
            css={css`
              text-align: left;
            `}
          >
            {t('Text color')}
          </TextNew>
          <Flex vertical="center">
            <label htmlFor="black">
              <TextNew>{t('Black')}</TextNew>
            </label>

            <input
              id="black"
              type="radio"
              name="textColor"
              value="black"
              checked={
                form.values.textColor.toLowerCase() ===
                colors.black.toLowerCase()
              }
              onChange={() => form.setValue('textColor', colors.black)}
              css={css`
                height: 40px;
                width: 40px;
              `}
            />
            <Spacer width={16} />
            <label htmlFor="white">
              <TextNew>{t('White')}</TextNew>
            </label>
            <input
              id="white"
              type="radio"
              name="textColor"
              value="black"
              checked={
                form.values.textColor.toLowerCase() ===
                colors.white.toLowerCase()
              }
              onChange={() => form.setValue('textColor', colors.white)}
              css={css`
                height: 40px;
                width: 40px;
              `}
            />
          </Flex>
        </Flex>
        <Spacer height={32} />

        <ChallengeLeaguePreviewComponent
          title={form.values.title}
          backgroundColor={form.values.backgroundColor}
          textColor={form.values.textColor}
        />

        <Spacer height={32} />

        {selectedLeague && (
          <Flex
            horizontal="center"
            css={css`
              background-color: ${colors.grey100};
              padding: 16px;
              border-radius: 8px;
            `}
          >
            <LeagueItemSubscribe
              league={selectedLeague}
              unsubscribe={(e: any) => {
                e.stopPropagation()
                setSelectedLeague(null)
              }}
            />
          </Flex>
        )}

        {!selectedLeague && (
          <Flex
            column
            gap="medium"
            css={css`
              background-color: ${colors.grey100};
              padding: 16px;
              border-radius: 8px;
            `}
          >
            <Text>{t(`Add quiz subscription`)}</Text>
            <TextInput
              value={search}
              onValue={setSearch}
              css={css`
                border: 1px solid ${colors.grey200};
                border-radius: 2px;
              `}
              placeholder="Search"
            />
            <Flex
              gap="large"
              css={css`
                overflow-x: scroll;
              `}
            >
              {activeLeagues
                .filter((league) =>
                  league.title.toLowerCase().includes(search.toLowerCase())
                )
                .map((league) => (
                  <LeagueItemSubscribe
                    key={league.id}
                    league={league}
                    subscribe={(e: any) => {
                      e.stopPropagation()
                      setSelectedLeague(league)
                    }}
                  />
                ))}
            </Flex>
          </Flex>
        )}
        <Flex css={margin.top('large')} column horizontal="center">
          <PrimaryButton
            variant="black"
            type="submit"
            loading={form.submitting}
            disabled={!form.isValid || !selectedLeague}
            css={css`
              height: 48px;
              width: 100%;
              max-width: 300px;
            `}
          >
            {t('Create')}
          </PrimaryButton>
        </Flex>
      </form>
    </Modal>
  )
}
