/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import copyToClipboard from 'copy-to-clipboard'
import { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Modal } from '../../../components/modal/Modal'
import { Text } from '../../../components/Text'
import { TextInputForm } from '../../../components/TextInputForm'
import { useT } from '../../../lib/i18n/useT'
import { margin } from '../../../styles/margin'
import theme from '../../../styles/theme'
import { useTimeout } from '../../../utils/useTimeout'
import { useFriendsForUserQuery } from '../../Profile/queries'
import {
  Club,
  useClubWithMembersQuery,
  useInviteToClubMutation,
} from '../queries'
import { TextNew } from '../../../components/TextNew'
import { UserAvatar } from '../../Avatar/UserAvatar'

export const InviteMemberModal = ({
  club,
  closeModal,
}: {
  club: Club
  closeModal: () => void
}) => {
  const t = useT()
  const [inviteToClub] = useInviteToClubMutation()
  const [hasCopied, setHasCopied] = useState(false)
  useTimeout(() => setHasCopied(false), hasCopied ? 3000 : null)

  const [invitedFriends, setInvitedFriends] = useState<string[]>([])

  const friendsForUserQuery = useFriendsForUserQuery()

  const clubMembersQuery = useClubWithMembersQuery({
    slug: club.slug,
  })

  const members = clubMembersQuery.data?.club.members || []

  const friends = friendsForUserQuery.data?.friendsForUser || []

  // Sorted with non member friends first
  const friendsSorted = [...friends].sort((a, b) => {
    const aIsMember = members.some((member) => member.id === a.id)
    const bIsMember = members.some((member) => member.id === b.id)
    return aIsMember === bIsMember ? 0 : aIsMember ? 1 : -1
  })

  return (
    <Modal
      heading={t('Invite')}
      closeModal={closeModal}
      styling={css`
        background-color: ${theme.colors.green200};
        text-align: center;
      `}
      textColor={theme.colors.green500}
    >
      <Flex
        column
        css={css`
          text-align: center;
          max-width: 390px;
          margin: 0 auto;
        `}
      >
        <Text
          css={css`
            text-align: center;
            color: ${theme.colors.green500};
          `}
          size="large"
        >
          {t('Invite your friends to join {{clubName}}.', {
            clubName: club.name,
          })}
        </Text>

        {friends.length > 0 && (
          <Flex column gap={'small'} css={margin.top('large')}>
            {friendsSorted.map((friend) => {
              const friendIsMember = members.some(
                (member) => member.id === friend.id
              )

              const friendInvited = invitedFriends.includes(friend.id)
              return (
                <Flex
                  key={friend.id}
                  horizontal="space-between"
                  vertical="center"
                  css={css`
                    background-color: ${theme.colors.green500};
                    border-radius: 100px;
                    padding: 4px 8px;
                    padding-left: 24px;
                  `}
                >
                  <Flex vertical="center" wrap gap={'small'}>
                    <UserAvatar avatarData={friend.avatarData} height={50} />
                    <TextNew condensed size={22} weight={700} color="white">
                      {friend.displayName}
                    </TextNew>
                  </Flex>
                  {friendIsMember ? null : (
                    <PrimaryButton
                      variant="white"
                      rounded
                      disabled={friendInvited}
                      onClick={async () => {
                        setInvitedFriends([...invitedFriends, friend.id])
                        await inviteToClub({
                          variables: {
                            input: {
                              toUserId: friend.id,
                              clubId: club.id,
                            },
                          },
                        })
                      }}
                    >
                      <TextNew
                        condensed
                        size={22}
                        weight={700}
                        italic
                        css={css`
                          text-transform: capitalize;
                        `}
                        color={
                          friendInvited
                            ? theme.colors.white
                            : theme.colors.green500
                        }
                      >
                        {friendInvited ? t('Invited') : t('Invite')}
                      </TextNew>
                    </PrimaryButton>
                  )}
                </Flex>
              )
            })}
          </Flex>
        )}

        <Text
          css={[
            css`
              text-align: center;
              color: ${theme.colors.green500};
            `,
            margin.vertical('large'),
          ]}
          size="small"
        >
          {t(
            'You can also send an invite by sending an email or copy the invite link and send it yourself.'
          )}
        </Text>

        <TextInputForm
          label=""
          placeholder={t('Email')}
          formParameters={{
            initialValues: {
              value: '',
            },
            onSubmit: async ({ values, setSubmitError }) => {
              try {
                await inviteToClub({
                  variables: {
                    input: {
                      email: values.value,
                      clubId: club.id,
                    },
                  },
                })
              } catch (e) {
                console.error('Failed to invite user to club', e)
                setSubmitError(t('Something went wrong, try again'))
              }
            },
            validate: {
              value: (value) =>
                !value ? t('Email cannot be empty') : undefined,
            },
          }}
          type="email"
          submitText={t('Send')}
          successText={t('Invite sent')}
        />

        <Flex column horizontal="center" css={margin.top()}>
          <SecondaryButton
            onClick={() => {
              const inviteUrl = `${
                new URL(window.location.href).origin
              }/clubs?invitation=${club.invitationCode}`

              copyToClipboard(inviteUrl)

              setHasCopied(true)
            }}
            css={css`
              height: 38px;
              border-color: ${theme.colors.green500};
              color: ${theme.colors.green500};
              white-space: nowrap;
            `}
          >
            {t('Copy invite link')}
          </SecondaryButton>
          {hasCopied && (
            <Text css={margin.top('tiny')} strong>
              {t('Copied!')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}
