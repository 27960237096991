/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { Text } from '../../components/Text'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin, Spacer } from '../../styles/margin'
import { boxShadows } from '../../styles/theme'

export function WelcomeBanner({ className }: { className?: string }) {
  const { authUser } = useAuthContext()
  const t = useT()
  // const { isNativeApp, openNativeLoginModal } = useNativeApp()

  // const showNativeLogin = isNativeApp && !isProduction

  if (authUser && !authUser.isAnonymous) {
    return null
  }

  return (
    <Flex column horizontal="center">
      <Flex
        column
        css={css`
          padding: 20px;
          background-color: black;
          border-radius: 8px;
          max-width: 500px;
          box-shadow: ${boxShadows.default2};
        `}
        className={className}
      >
        <Flex column horizontal="center">
          <Text
            wide
            textAlign="center"
            color="white"
            css={css`
              font-size: 28px;
              font-weight: 800;
            `}
          >
            {t('WELCOME!')}
          </Text>

          <Logo color="white" size={100} css={margin.vertical('large')} />

          <Text textAlign="center" color="white">
            {t(
              'From legendary players to iconic moments, do you know all the facts that have shaped the game we all love?'
            )}
          </Text>
          <Spacer height="medium" />
          <Text textAlign="center" color="white">
            {t(
              "Get your team ready and let's find out if you have what it takes to become a true football champion!"
            )}
          </Text>
        </Flex>

        <Spacer height="large" />

        <Flex column>
          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton variant="white" onClick={openModal}>
                {t('Sign up / Log in')}
              </PrimaryButton>
            )}
            modal={({ closeModal }) => (
              <LoginModalInner closeModal={closeModal} />
            )}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
