/** @jsxImportSource @emotion/react */

import { Keyframes, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { moveLeftToCenter, moveLeftToRight } from '../styles/animations'
import { TextNew, TextProps } from './TextNew'
import { Flex } from './layout/Flex'

//---
// Moves out the text left-right. Good for long text
//---

// Similiar to TextShifting but happens only x times
export const TextShiftsMove = ({
  children,
  shiftsAtMs,
  disabled = false,

  className,
  ...rest
}: {
  children: any
  shiftsAtMs: number[]
  disabled?: boolean
} & TextProps) => {
  const [animation, setAnimation] = useState<Keyframes | undefined>(undefined)
  const [stopAnimation, setStopAnimation] = useState(false)
  const [childUsed, setChildUsed] = useState(children[disabled ? 1 : 0])

  useEffect(() => {
    if (disabled) return
    const width = document.getElementById('textShiftMove')?.clientWidth
    if (!width) return
    setAnimation(moveLeftToRight(width + 40))
    let n = 0
    for (const child of children.slice(1)) {
      setTimeout(() => {
        setChildUsed(child)
        // Last item
        if (child == children[children.length - 1]) {
          console.log('End')
          setAnimation(moveLeftToCenter(width))
          setStopAnimation(true)
        }
      }, shiftsAtMs[n])
      n++
    }
  }, [])

  return (
    <Flex
      id="textShiftMove"
      css={css`
        overflow: hidden;
        margin: -20px;
        padding: 20px;
        width: 100%;
      `}
      horizontal="center"
    >
      <TextNew
        css={css`
          animation: ${animation} 3s
            ${stopAnimation ? '' : 'cubic-bezier(0.12, 0.57, 0.63, 0.21)'}
            ${stopAnimation ? 'forwards' : 'infinite'};
        `}
        className={className}
        {...rest}
      >
        {childUsed}
      </TextNew>
    </Flex>
  )
}
