/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading } from '../../../components/Heading'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { PageContent } from '../../../components/layout/PageContent'
import { TextNew } from '../../../components/TextNew'
import { useViewerQuery } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { buttonReset } from '../../../styles/styles'
import { colors, hexOpacity } from '../../../styles/theme'
import { LeaguesContainer } from '../../Home/LeaguesContainer'
import { LEAGUE_QUIZ_ITEM_WIDTH } from '../../League/LeaguePage'
import { LeagueQuizItemV2 } from '../../League/LeagueQuizItemV2'
import { LeagueQuizSelector } from '../../League/LeagueQuizSelector'
import { ClubAdminPanel } from '../ClubAdminPanel'
import { AddQuizToClubComponent } from '../EditClub/QuizzesSubPage'
import {
  ClubPageLeague,
  ClubProfileQueryResult,
  useClubProfileQuery,
} from '../queries'
import { ClubLeaderboard } from './ClubLeaderboard'
import { ClubPageLeagueItem } from './ClubPageLeagueItem'
import { ClubTopContentV2 } from './ClubTopContentV2'
import { Members } from './Members'

type Props = { club: ClubProfileQueryResult['club'] }

const Content: FC<Props> = ({ club }) => {
  const t = useT()

  const navigate = useNavigate()

  useEffect(() => {
    if (club) {
      document.title = `fcQuiz | ${club.name} `
      // update url if needed | slugs are prettier than ids
      if (window.location.pathname !== `/clubs/${club.slug}`) {
        navigate(`/clubs/${club.slug}`, { replace: true })
      }
    }
  }, [club])

  const [selectedLeague, setSelectedLeague] = useState<
    ClubPageLeague | undefined
  >(undefined)
  const leagueQuizzes = selectedLeague?.quizzes ?? []

  const [selectedQuiz, setSelectedQuiz] = useState<
    { id: string; title: string } | undefined
  >(undefined)

  const [quizSelectorIndex, setQuizSelectorIndex] = useState(
    leagueQuizzes.length - 1
  )

  const viewerQuery = useViewerQuery()

  const isAdmin = viewerQuery.data?.viewer?.isAdmin

  const viewerCanEdit = club.viewerCanEdit
  const clubHasSubscribedLeagues = club.subscribedLeagues.length > 0

  useEffect(() => {
    setSelectedQuiz(undefined)
    setQuizSelectorIndex(leagueQuizzes.length)
  }, [selectedLeague])

  useEffect(() => {
    if (club.subscribedLeagues.length === 1 && club.viewerIsMember) {
      setSelectedLeague(club.subscribedLeagues[0])
    }
  }, [club.subscribedLeagues])

  const selectedColor = hexOpacity(colors.black, 0.7)

  return (
    <>
      {isAdmin && (
        <ClubAdminPanel
          clubId={club.id}
          clubSlug={club.slug}
          clubIsVerified={club.verified}
          css={css`
            margin-bottom: 20px;
          `}
        />
      )}

      <PageContent>
        <ClubTopContentV2 club={club} />
      </PageContent>

      {club.subscribedLeagues.length > 0 && (
        <>
          <Spacer height="huge" />
          <PageContent>
            <Heading
              level={3}
              looksLikeLevel={6}
              color={club.textColor}
              css={css`
                text-align: center;
                text-transform: uppercase;
              `}
            >
              {t('Series_plural')}
            </Heading>
          </PageContent>

          <LeaguesContainer>
            {club.subscribedLeagues.map((league) => {
              const selected = selectedLeague?.id === league.id
              return (
                <div
                  key={league.id}
                  css={css`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  <ClubPageLeagueItem
                    key={league.id}
                    league={league}
                    onClick={() => {
                      if (!club.viewerIsMember) {
                        return
                      }
                      if (selected) {
                        setSelectedLeague(undefined)
                        return
                      }
                      setSelectedLeague(league)
                    }}
                    selected={selected}
                    selectedColor={selectedColor}
                    viewerCanEdit={club.viewerCanEdit}
                  />
                </div>
              )
            })}
            {club.subscribedLeagues.length > 0 && club.viewerIsMember && (
              <button
                aria-label={t('Total score')}
                onClick={() => {
                  setSelectedLeague(undefined)
                }}
                css={[buttonReset]}
              >
                <Flex
                  column
                  vertical="center"
                  css={css`
                    min-width: 220px;
                    min-height: 180px;
                    background-color: ${colors.yellow100};
                    border-radius: 16px;
                    padding: 16px;

                    border: ${selectedLeague
                      ? 'none'
                      : `5px solid ${selectedColor}`};
                  `}
                >
                  <TextNew size={21} weight={500} condensed>
                    {club.name}
                  </TextNew>
                  <Flex
                    column
                    vertical="center"
                    css={css`
                      flex: 1;
                    `}
                  >
                    <TextNew
                      size={27}
                      weight={800}
                      condensed
                      css={css`
                        text-transform: capitalize;
                      `}
                    >
                      {t('Total score')}
                    </TextNew>
                    <div
                      css={css`
                        width: 100%;
                        height: 1px;
                        background-color: ${colors.black};
                        margin: 8px 0;
                      `}
                    />
                    <TextNew weight={400} size={14}>
                      {t('{{count}} player', { count: club.membersCount })}
                    </TextNew>
                  </Flex>
                </Flex>
              </button>
            )}
          </LeaguesContainer>
        </>
      )}
      {leagueQuizzes.length > 0 && (
        <>
          <Spacer height="large" />
          <LeagueQuizSelector
            selectorColor={club.textColor}
            items={[
              ...leagueQuizzes.map((quiz) => {
                return (
                  <LeagueQuizItemV2
                    key={quiz.id}
                    clubId={club.id}
                    quiz={{
                      ...quiz,
                      questionsCount: quiz.questionsCount || 0,
                    }}
                    css={css`
                      min-height: ${LEAGUE_QUIZ_ITEM_WIDTH}px;
                    `}
                    selected={selectedQuiz?.id === quiz.id}
                    selectedColor={selectedColor}
                  />
                )
              }),

              <button aria-label={t('Total score')} css={[buttonReset]}>
                <Flex
                  column
                  vertical="center"
                  css={css`
                    padding: 12px;
                    border-radius: 15px;
                    min-width: 220px;
                    max-width: 220px;
                    min-height: 220px;

                    background-color: ${colors.yellow100};
                    border-radius: 16px;
                    padding: 16px;

                    border: ${selectedQuiz
                      ? 'none'
                      : `5px solid ${selectedColor}`};
                  `}
                >
                  <TextNew
                    size={21}
                    weight={500}
                    condensed
                    css={css`
                      text-transform: uppercase;
                    `}
                  >
                    {selectedLeague?.title}
                  </TextNew>
                  <Flex
                    column
                    vertical="center"
                    css={css`
                      flex: 1;
                    `}
                  >
                    <TextNew
                      size={27}
                      weight={800}
                      condensed
                      css={css`
                        text-transform: capitalize;
                      `}
                    >
                      {t('Total score')}
                    </TextNew>
                    <div
                      css={css`
                        width: 100%;
                        height: 1px;
                        background-color: ${colors.black};
                        margin: 8px 0;
                      `}
                    />
                    <TextNew weight={400} size={14}>
                      {t('{{count}} player', { count: club.membersCount })}
                    </TextNew>
                  </Flex>
                </Flex>
              </button>,
            ]}
            selectedIndex={quizSelectorIndex}
            onSelectIndex={(index) => {
              // if (quizSelectorIndex === index && selectedQuiz) {
              //   setSelectedQuiz(undefined)
              //   return
              // }

              setQuizSelectorIndex(index)
              setSelectedQuiz(leagueQuizzes[index])
            }}
            itemWidth={220}
            itemSpacing={0}
          />
        </>
      )}
      {club.viewerIsMember && clubHasSubscribedLeagues && (
        <>
          <Spacer height="large" />
          <PageContent>
            <ClubLeaderboard
              {...club}
              limit={25}
              selectedLeague={selectedLeague && { ...selectedLeague }}
              selectedQuiz={selectedQuiz && { ...selectedQuiz }}
              textColor={club.textColor}
              backgroundColor={club.backgroundColor}
            />
          </PageContent>
        </>
      )}

      {!clubHasSubscribedLeagues && (
        <>
          <PageContent width="narrow">
            {viewerCanEdit && club.viewerIsMember && (
              <Flex horizontal="center" column>
                <Spacer height="large" />
                <AddQuizToClubComponent club={club} subscribeOnly />
              </Flex>
            )}

            <Spacer height="large" />
            <Flex
              column
              css={css`
                background-color: ${club.backgroundColor};
                border-radius: 20px;
                padding: 32px;

                /* smaller margin on mobile devices */
                @media (max-width: 768px) {
                  padding: 32px 16px;
                }
              `}
            >
              <HeadingNew
                level={2}
                looksLikeLevel={4}
                color={club.textColor}
                size={24}
                condensed
                italic
              >
                {t('Members')}
              </HeadingNew>
              <Members
                clubSlug={club.slug}
                clubId={club.id}
                viewerRole={club.viewerRole}
                membersCount={club.membersCount}
                maxWidth={'800px'}
                memberBatchSize={15}
                textColor={club.textColor === colors.white ? 'white' : 'black'}
              />
            </Flex>
          </PageContent>
        </>
      )}
    </>
  )
}

export function DataLoader({
  setBackgroundColor,
}: {
  setBackgroundColor?: (color: string) => void
}) {
  const slug = useParams<'slug'>().slug!
  const query = useClubProfileQuery(slug)
  const club = query.data?.club
  const backgroundImagePath = club?.backgroundImageUrl

  useEffect(() => {
    if (setBackgroundColor) {
      setBackgroundColor(club?.backgroundColor || '#000000')
    }
  }, [club, setBackgroundColor])

  return (
    <>
      <Background
        backgroundImagePath={backgroundImagePath}
        backgroundColor={club?.backgroundColor}
      />

      <Flex column grow>
        {club ? (
          <Content club={club} />
        ) : query.loading ? null /* Spinner */ : null}
      </Flex>
    </>
  )
}

export const ClubProfilePageV2 = () => {
  const t = useT()

  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(
    '#000000'
  )

  return (
    <Layout title={`fcQuiz | ${t('Clubs')}`} backgroundColor={backgroundColor}>
      <DataLoader setBackgroundColor={setBackgroundColor} />
    </Layout>
  )
}

const Background = ({
  backgroundImagePath,
  backgroundColor = '#000000',
}: {
  backgroundImagePath?: string | null
  backgroundColor?: string
}) => {
  if (!backgroundImagePath) {
    return (
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
          z-index: -1;
          background: linear-gradient(
            ${colors._grey450} 0%,
            ${backgroundColor} 40%
          );
        `}
      />
    )
  }

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50vh;
        max-height: 500px;
        z-index: -1;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 1px;
          background-image: url(${backgroundImagePath});
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: ${backgroundColor};
          z-index: -1;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background: linear-gradient(
            ${hexOpacity('#000000', 0.5)},
            ${hexOpacity('#000000', 0.2)},
            ${hexOpacity('#000000', 0.2)},
            ${hexOpacity(backgroundColor, 0.8)},
            ${backgroundColor}
          );
        `}
      />
    </div>
  )
}
