/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { AvatarContextProvider, useAvatarContext } from './AvatarPage'
import { SVGPatterns } from './SVGPatterns'
import Face from './viewbox/face'
import { Hair, hairStyles } from './viewbox/hair/_index'
import { Skin } from './viewbox/skin/_index'
import { Chest } from './viewbox/chest/_index'
import { useRef } from 'react'
import { exportSVGAsPNG } from '../../utils/exportSVG'
import { PrimaryButton } from '../../components/Button'
import { PiDownloadSimpleBold } from 'react-icons/pi'
import { colors } from '../../styles/theme'
import { isProduction } from '../../config/config'

const paddingTop = 72
const paddingBottom = 0
const heightIcon = 199 + paddingTop + paddingBottom // Less to exclude shirt
export const heightInner = 309
export const height = heightInner + paddingTop + paddingBottom
export const width = 248

//Icon: Black/White, shows the mask of the avatar
//Base: Gradient green, used for anonymous users
export const Avatar = (props: {
  width?: number
  height?: number
  className?: string
  userId?: string

  iconPart?: string
  greyScale?: boolean
  hideOthers?: boolean
  FGColor?: string
  BGColor?: string
  bgasfg?: boolean
  partsOverride?: any
  border?: number[]
  showExport?: boolean
}) => {
  const FGColor = props.bgasfg ? props.BGColor : props.FGColor
  const mask = (
    iconPart: string | undefined,
    part: string,
    invert: boolean,
    FGColorOverride?: string
  ) => ((iconPart == part) !== invert ? '#808080' : FGColorOverride ?? FGColor)

  const { iconPart: iconPartOriginal } = props

  const invert = iconPartOriginal === 'Skin'
  const iconPart = invert ? 'Hair' : iconPartOriginal
  const pupilColors = iconPart == 'Eyes' ? props.FGColor : FGColor

  if (props.width && props.height) {
    throw new Error(
      'Only use one of these parameters. Aspect ratio is important'
    )
  }

  const { parts: partsContext } = useAvatarContext()
  const parts = props.partsOverride ?? partsContext

  const _height = iconPart ? heightIcon : height

  const [x, y, dx, dy] = props.border ?? [0, 0, width, _height]

  const elementScaleX = props.width && props.width / dx
  const elementScaleY = props.height && props.height / dy
  const elementScale = elementScaleX || elementScaleY || 1

  const patternId = props.userId // Used to differentiate between multiple users.

  const fill = [
    ...Object.keys(parts).map((part) =>
      [false, true].map((mirrored) =>
        [false, true].map(
          (dark) => `
        .${part}${mirrored ? 'Mirrored' : ''}${dark ? 'Dark' : ''} {
          fill: url(#${part}${mirrored ? 'Mirrored' : ''}${
            dark ? 'Dark' : ''
          }${patternId});
        }
      `
        )
      )
    ),
  ]

  const hideOtherParts = [
    ...Object.keys(parts)
      .filter((it) => [iconPart, 'Skin'].includes(it))
      .map((part) =>
        [false, true].map((mirrored) =>
          [false, true].map(
            (dark) => `
        .${part}${mirrored ? 'Mirrored' : ''}${dark ? 'Dark' : ''} {
          fill: url(#${part}${mirrored ? 'Mirrored' : ''}${dark ? 'Dark' : ''}${
              props.userId
            });
        }
      `
          )
        )
      ),
    ...[...Object.keys(parts), 'Eyes', 'Mouth']
      .filter((it) => ![iconPart, 'Skin'].includes(it))
      .map(
        (part) => `
            .${part}Group path {
              fill-opacity: 0;
            }
          `
      ),
    ,
  ]

  const greyScaleFill = [
    //Make these parts either black or white
    //Need to hide brows since they go over hair
    ...Object.keys(parts).map(
      (part) => css`
        .${part}Group path {
          fill: ${mask(iconPart, part, invert)};
          ${iconPart === 'Hair' && part == 'Brows' ? 'fill-opacity: 0' : ''};
        }
      `
    ),
    //Pupil should be different from eyes
    css`
      .Eyes {
        fill: ${mask(iconPart, '', invert, pupilColors)} !important;
      }
    `,
    //Hide these parts
    ...['Shoulders', 'Arms', 'Chest', 'Collar', 'Sleeves'].map((part) =>
      ['', 'Mirrored'].map(
        (mirrored) => css`
          .${part + mirrored} {
            fill: ${props.BGColor} !important;
          }
          .hideIconPart {
            opacity: 0 !important;
          }
        `
      )
    ),
    css`
      g {
        mix-blend-mode: normal !important;
      }
    `,
  ]

  const hairStyle = parts['Hair'].style
  const zIndexHair = hairStyles[hairStyle].zIndex

  const svgRef = useRef(null)

  const handleExportClick = async (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    await exportSVGAsPNG(svgRef.current)
  }

  return (
    <AvatarContextProvider
      value={{
        parts: parts,
        setParts: () => {},
      }}
    >
      <svg
        ref={svgRef}
        className={props.className}
        css={[
          !props.hideOthers && !props.greyScale && fill,
          props.greyScale && greyScaleFill,
          props.hideOthers && hideOtherParts,
          'pointer-events: none',
          css`
            min-width: ${dx * elementScale}px;
            min-height: ${dy * elementScale}px;
          `,
        ]}
        width={`${dx * elementScale}px`}
        height={`${dy * elementScale}px`}
        viewBox={`0 -${paddingTop} ${dx} ${dy}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <SVGPatterns parts={parts} patternId={patternId} />
        <g transform={`translate(${x}, -${y})`}>
          <g transform={`translate(${dx / 2}, 0)`}>
            <Skin />
            <Chest />
            {zIndexHair == 0 && <Hair />}
            <Face />
            {zIndexHair == 1 && <Hair />}
          </g>
        </g>
      </svg>
      {props.showExport && !isProduction && (
        <PrimaryButton
          variant="grey"
          onClick={handleExportClick}
          css={css`
            padding: 10px 10px;
            display: flex;
            position: absolute;
            right: 30px;
            top: 60px;
          `}
        >
          <PiDownloadSimpleBold size={26} color={colors.white} />
        </PrimaryButton>
      )}
    </AvatarContextProvider>
  )
}
