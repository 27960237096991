/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Select from 'react-select'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { Category } from '../../lib/types'
import { textStyleLegacy } from '../../styles/styles'
import { useGetCategoriesQuery } from './queries'
import { Locale } from '../../lib/i18n/locale'

export const SelectCategories = (props: {
  selectedCategories: Category[]
  setSelectedCategories: (arg: Category[]) => void
  languageOverride?: Locale
  disabled?: boolean
}) => {
  const t = useT(props.languageOverride)
  const language: Locale = props.languageOverride ?? Locale.en
  const getCategoriesQuery = useGetCategoriesQuery()
  const categories = getCategoriesQuery.data?.getCategories?.categories

  const getNameFromCategory = (category: Category) => {
    return (
      category.name[language] ??
      category.name.en ??
      category.name.no ??
      `MissingName|${category._id}`
    )
  }

  return (
    <Flex>
      <Select
        css={css`
          width: 100%;
          ${textStyleLegacy}

          * {
            border-color: black !important;
          }
        `}
        placeholder={t(`Select tags...`)}
        value={props.selectedCategories.map((it) => ({
          value: it,
          label: getNameFromCategory(it),
        }))}
        onChange={(value) => {
          const newCategories = [...value.values()].map((it) => it.value)
          props.setSelectedCategories(newCategories)
        }}
        options={categories?.map((it) => ({
          value: it,
          label: getNameFromCategory(it),
        }))}
        isMulti
        menuPlacement="top"
        isDisabled={props.disabled}
      />
    </Flex>
  )
}
