import { Selector } from '../../Selector'

import { ReactComponent as CollarA } from './Collar A.svg'
import { ReactComponent as CollarB } from './Collar B.svg'
import { ReactComponent as CollarC } from './Collar C.svg'

const collarStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
  }
} = {
  Collar0: { svg: <CollarA /> },
  CollarA: { svg: <CollarA /> },
  CollarB: { svg: <CollarA /> },
  CollarC: { svg: <CollarB /> },
  CollarD: { svg: <CollarB /> },
  CollarE: { svg: <CollarC /> },
  CollarF: { svg: <CollarC /> },
  CollarH: { svg: <CollarC /> },
}

export const Collar = () => {
  return <Selector selectedOptionKey={'collar'} styles={collarStyles} />
}
