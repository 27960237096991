/** @jsxImportSource @emotion/react */

import { css, Keyframes } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { ReactComponent as AvatarCheckmark } from './AvatarCheckmark.svg'
import MessiAvatar from './MessiAvatar.svg'
import RonaldoAvatar from './RonaldoAvatar.svg'
import GriezmannAvatar from './GriezmannAvatar.svg'
import SalahAvatar from './SalahAvatar.svg'
import HaalandAvatar from './HaalandAvatar.svg'
import KanteAvatar from './KanteAvatar.svg'
import SimonsAvatar from './SimonsAvatar.svg'
import { useEffect, useState } from 'react'
import { horizontalFlip, moveTowards } from '../../../styles/animations'
import ConfettiExplosion from 'react-confetti-explosion'
import { colors } from '../../../styles/theme'

const avatars = [
  SimonsAvatar,
  KanteAvatar,
  GriezmannAvatar,
  SalahAvatar,
  HaalandAvatar,
  RonaldoAvatar,
  MessiAvatar,
]

function makePositionFixed(id: string) {
  const element = document.getElementById(id)
  if (!element) {
    console.log('Element not found')
    return
  }

  // Get the current position relative to the viewport
  const rect = element.getBoundingClientRect()

  // Change the position to fixed
  element.style.position = 'fixed'

  // Set the top and left properties to match the current position
  // Note: This assumes the element's margin is negligible for its positioning.
  // If the element has a margin, you might need to adjust these values accordingly.
  element.style.top = `${rect.top}px`
  element.style.left = `${rect.left}px`
}

export const AvatarAnimated = ({
  index,
  length,
  className,
}: {
  index: number
  length: number
  className?: string
}) => {
  const [moveTowardsKeyframe, setMoveTowardsKeyframe] = useState<
    Keyframes | undefined
  >(undefined)

  const [moveTowardsKeyframeConfetti, setMoveTowardsKeyframeConfetti] =
    useState<Keyframes | undefined>(undefined)

  const [coinCheckmarkOpacity, setCoinCheckmarkOpacity] = useState(0)
  const [showConfetti, setShowConfetti] = useState(false)

  const AvatarComponent = avatars[index - 1]

  const delayMs = 1000

  useEffect(() => {
    setTimeout(() => {
      makePositionFixed(`coin${index}`)
      setMoveTowardsKeyframe(moveTowards(`coin${index}`, 'userCoinsPos', true))
      setCoinCheckmarkOpacity(1)
    }, delayMs + 500 + length * 250)
    setTimeout(() => {
      setMoveTowardsKeyframeConfetti(
        moveTowards(`confetti${index}`, 'userCoinsPos', true)
      )
      setShowConfetti(true)
    }, delayMs + 1500 + length * 250)
  }, [])

  return (
    <Flex
      column
      css={css`
        position: relative;
      `}
      className={className}
      vertical="center"
      horizontal="center"
    >
      <img
        css={css`
          z-index: 1;
          width: 40px;
          height: 40px;
          animation: ${horizontalFlip} 0.5s forwards;
          animation-delay: ${delayMs / 1000 + 0.25 * index}s;
          @media (max-width: 399px) {
            height: 11vw;
            width: 11vw;
          }
        `}
        src={AvatarComponent}
      />
      <img
        id={`coin${index}`}
        css={css`
          width: 40px;
          height: 40px;
          z-index: 10;
          position: fixed;
          opacity: 0;
          animation: ${moveTowardsKeyframe} 1s cubic-bezier(0.4, 0, 0.15, 1)
            forwards;
          @media (max-width: 399px) {
            height: 11vw;
            width: 11vw;
          }
        `}
        src={AvatarComponent}
      />
      <AvatarCheckmark
        css={css`
          z-index: 10;
          position: absolute;
          height: 17px;
          right: -2px;
          bottom: 6px;
          transition-duration: 1s;
          opacity: ${coinCheckmarkOpacity};
        `}
      />
      <Flex
        css={css`
          position: absolute;
          width: 500px;
          animation: ${moveTowardsKeyframeConfetti} 0s forwards;
        `} //Fun hacky way to position it at the coins
        id={`confetti${index}`}
      >
        {showConfetti && (
          <ConfettiExplosion
            particleCount={10}
            force={0.2}
            width={500}
            duration={2500}
            zIndex={10}
            colors={[colors.white, colors.yellow300, colors.yellow400]}
          />
        )}
      </Flex>
    </Flex>
  )
}
