/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn } from '../../../styles/animations'
import { Spacer } from '../../../styles/margin'
import { colors, hexOpacity } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { LeaderboardItem } from '../../League/queries'

export const LiveQuizLeaderboard = ({
  results,
}: {
  results: LeaderboardItem[]
}) => {
  const paddingFromIndex = (index: number) => {
    switch (index) {
      case 0:
        return '0'
      case 1:
        return '1vw'
      case 2:
        return '2vw'
      default:
        return '3vw'
    }
  }

  const itemHeightFromIndex = (index: number) => {
    switch (index) {
      case 0:
        return '7vh'
      case 1:
        return '6vh'
      case 2:
        return '5vh'
      default:
        return '4vh'
    }
  }

  const fontSizeFromIndex = (index: number) => {
    switch (index) {
      case 0:
        return '1.9vw'
      case 1:
        return '1.7vw'
      default:
        return '1.6vw'
    }
  }

  return (
    <Flex
      column
      css={css`
        overflow: hidden;
      `}
    >
      {results.map((result, index) => {
        const isFirst = index === 0
        const horizontalPadding = paddingFromIndex(index)
        return (
          <div
            key={`${result.user.id}-${index}`}
            css={css`
              padding: 0 ${horizontalPadding};
            `}
          >
            <LeaderboardItemComponent
              item={result}
              isAuthUser={isFirst} //TODO: Add custom color
              index={index}
              height={itemHeightFromIndex(index)}
              fontSize={fontSizeFromIndex(index)}
            />
          </div>
        )
      })}
    </Flex>
  )
}

export const LeaderboardItemComponent = ({
  item,
  isAuthUser,
  index,
  height,
  fontSize,
}: {
  item: LeaderboardItem
  isAuthUser: boolean
  index: number
  height: string
  fontSize: string
}) => {
  const t = useT()

  const screenHeight = window.innerHeight

  const isLiveDisplayPage = window.location.pathname.includes('live-display')

  const avatarHeight = viewHeightToNumber(height, screenHeight)
  return (
    <Flex
      key={`${item.user.id}-${item.rank}-${index}`}
      vertical="center"
      horizontal="space-between"
      css={css`
        width: 100%;
        background-color: ${isAuthUser
          ? colors.yellow200
          : index % 2 === 0
          ? isLiveDisplayPage
            ? colors.grey100
            : colors.white
          : hexOpacity(
              isLiveDisplayPage ? colors.green100 : colors.white,
              0.9
            )};
        border-radius: 50px;
        padding-right: 2vw;
        padding-left: 1vw;
        height: ${height}px;
        margin-bottom: 4px;
        animation: ${fadeIn} 650ms forwards;
        animation-delay: ${index * 0.1}s;
      `}
    >
      <Flex
        vertical="center"
        css={css`
          @media (max-width: 400px) {
            max-width: 85%;
          }
        `}
      >
        <Flex
          horizontal="center"
          css={css`
            min-width: 24px;
          `}
        >
          <TextNew
            weight={800}
            condensed
            strong
            css={css`
              font-size: ${fontSize};
              line-height: 1.2;
              font-style: italic;
            `}
          >
            {item.rank}
          </TextNew>
        </Flex>

        <Spacer width={item.rank > 99 ? 12 : 8} />
        <UserAvatar
          avatarData={item.user.avatarData}
          height={avatarHeight}
          clickable
        />
        <Spacer width={12} />
        <TextNew
          strong
          italic
          textAlign="left"
          css={css`
            width: 100%;
            padding: 0 8px;
            font-size: ${fontSize};
            line-height: 1.2;
            font-style: italic;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: 0.08%;
            white-space: nowrap;
          `}
        >
          {isAuthUser
            ? item.user.displayName ?? t('You')
            : item.user.displayName}
        </TextNew>
      </Flex>

      <TextNew
        condensed
        strong
        italic
        textAlign="center"
        css={css`
          font-size: ${fontSize};
          line-height: 1.2;
          font-style: italic;
          padding-bottom: 2px;
        `}
      >
        {item.score}
      </TextNew>
    </Flex>
  )
}

const viewHeightToNumber = (vh: string, screenHeight: number) => {
  const num = parseInt(vh.split('vh')[0])
  return (screenHeight * num) / 100
}
