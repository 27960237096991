/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { ErrorMessage, TextInput } from '../../components/TextInput'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import {
  ChallengeLeagueMinimal,
  useAddQuizToChallengeGroupMutation,
} from '../Challenge/queries'

export const AddLiveQuizToLeagueModal = ({
  closeModal,
  league,
}: {
  closeModal: () => void
  league: ChallengeLeagueMinimal
}) => {
  const [addQuizMutation] = useAddQuizToChallengeGroupMutation()
  const form = useForm({
    initialValues: {
      quizId: '',
    },
    validate: {
      quizId: (value) => {
        if (!value) {
          return 'Please enter a quiz id'
        }
        //must be the length of a uuid
        if (value.length !== 36) {
          return 'Invalid quiz id'
        }
      },
    },
    onSubmit: async ({ values, setSubmitError }) => {
      try {
        setSubmitError(undefined)

        await addQuizMutation({
          variables: {
            input: {
              groupId: league.id,
              quizId: values.quizId,
              playMode: 'live',
            },
          },
        })

        closeModal()
      } catch (error) {
        if (hasErrorCode(error, ErrorCode.QUIZ_ALREADY_ADDED)) {
          setSubmitError('Quiz already added')
        } else if (hasErrorCode(error, ErrorCode.QUIZ_NOT_FOUND)) {
          setSubmitError('Quiz not found')
        } else {
          setSubmitError('Something went wrong, try again')
        }
      }
    },
  })

  return (
    <Modal closeModal={closeModal} heading={'Add live quiz'}>
      <Flex
        column
        css={css`
          padding: 16px;
          background-color: ${colors.grey200};
          border-radius: 4px;
        `}
      >
        <HeadingNew level={1} looksLikeLevel={4}>
          {league?.title}
        </HeadingNew>

        <Spacer height={'medium'} />
        <form
          onSubmit={(event) => {
            event.preventDefault()
            form.submitForm()
          }}
        >
          <Flex column gap={'small'}>
            <TextInput
              id="quizId"
              name="quizId"
              placeholder="Quiz ID"
              autoComplete="off"
              value={form.values.quizId}
              onValue={(val) => {
                form.setValue('quizId', val)
              }}
              error={form.submitAttempted && form.fieldErrors.quizId}
            />
            <PrimaryButton type="submit" loading={form.submitting}>
              Add
            </PrimaryButton>

            {form.submitError && (
              <div css={margin.top()}>
                <ErrorMessage>{form.submitError}</ErrorMessage>
              </div>
            )}
          </Flex>
        </form>
      </Flex>
    </Modal>
  )
}
