/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { DefaultError } from '../../../components/DefaultError'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { Page } from '../../../components/leagueQuiz/Page'
import { Loader } from '../../../components/Loader'
import { ModalTrigger } from '../../../components/modal/Modal'
import { TextNew } from '../../../components/TextNew'
import { useAuthContext, useViewerQuery } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { AddLiveQuizToLeagueModal } from '../../Admin/AddLiveQuizToLeagueModal'
import { ManageLeagueSubscriptionsModal } from '../../Admin/ManageLeagueSubscriptionsModal'
import { ChallengeNavItem } from '../ChallengeGroupPage'
import { useChallengeLeagueOwnerQuery } from '../queries'
import { AddOwnerModal } from './AddOwnerModal'
import { MembersSubpage } from './MembersSubpage'
import { UpdateInfoForm } from './UpdateInfoForm'

const Content = ({ slug }: { slug: string }) => {
  const [subPage, setSubPage] = useState<
    'settings' | 'subscriptions' | 'members' | 'prevLiveQuizzes' | 'fcQuizAdmin'
  >('settings')
  const leagueQuery = useChallengeLeagueOwnerQuery(slug)

  const { authUser, loading: authUserLoading } = useAuthContext()
  const viewerQuery = useViewerQuery()

  const t = useT()

  const navigate = useNavigate()

  if (leagueQuery.loading || authUserLoading || viewerQuery.loading) {
    return <Loader center css={margin.top('large')} />
  }

  if (leagueQuery.error || !leagueQuery.data?.group || !authUser) {
    const reason = leagueQuery.error
      ? leagueQuery.error.message
      : !leagueQuery.data?.group
      ? 'No league found'
      : 'No user found'
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeLeagueSettingsPage | ${reason}`}
      />
    )
  }

  const league = leagueQuery.data.group

  const viewerIsAdmin = viewerQuery.data?.viewer?.isAdmin

  const subscriptions = league.subscribedLeagues || []

  const showPrevLiveQuizzes =
    !!league.previousLiveQuizzes && league.previousLiveQuizzes.length > 0

  return (
    <Flex column gap="medium">
      <Spacer height={16} />

      <SecondaryButton
        onClick={() => {
          navigate(`/league/${league.slug}`)
        }}
      >
        {t('To the league page')}
      </SecondaryButton>
      <Spacer height={16} />
      <Flex gap={'large'} wrap>
        <ChallengeNavItem
          title={t('Settings')}
          onClick={() => setSubPage('settings')}
          selected={subPage === 'settings'}
        />
        <ChallengeNavItem
          title={t('Subscriptions')}
          onClick={() => setSubPage('subscriptions')}
          selected={subPage === 'subscriptions'}
        />
        <ChallengeNavItem
          title={t('Members')}
          onClick={() => setSubPage('members')}
          selected={subPage === 'members'}
        />
        {showPrevLiveQuizzes && (
          <ChallengeNavItem
            title={t('Previous live quizzes')}
            onClick={() => setSubPage('prevLiveQuizzes')}
            selected={subPage === 'prevLiveQuizzes'}
          />
        )}
        {viewerIsAdmin && (
          <ChallengeNavItem
            title="FCQuiz Admin"
            onClick={() => setSubPage('fcQuizAdmin')}
            selected={subPage === 'fcQuizAdmin'}
          />
        )}
      </Flex>
      <Spacer height={16} />

      {subPage === 'settings' && (
        <>
          <Spacer height={16} />
          <UpdateInfoForm league={league} />
        </>
      )}

      {subPage === 'members' && (
        <MembersSubpage league={league} viewerId={authUser.uid} />
      )}

      {subPage === 'subscriptions' && (
        <>
          <Spacer height={16} />

          <Flex column gap="small">
            <TextNew strong>{t(`Subscriptions`)}</TextNew>
            <TextNew italic>
              {t(
                `New quizzes from these sources will be added to the league upon release.`
              )}
            </TextNew>

            {subscriptions.map((sub) => (
              <Link
                to={`/series/${sub.id}`}
                key={`subscribed-${sub.id}`}
                css={css`
                  text-decoration: none;

                  padding: 16px;
                  border-radius: 8px;
                  background-color: ${colors.grey200};

                  &:hover {
                    background-color: ${colors.grey300};
                    text-decoration: underline;
                  }
                `}
              >
                <Flex
                  gap="small"
                  horizontal="space-between"
                  vertical="center"
                  wrap
                >
                  <TextNew>{sub.title}</TextNew>
                  <PrimaryButton size="small">{'View'}</PrimaryButton>
                </Flex>
              </Link>
            ))}
          </Flex>
        </>
      )}

      {subPage === 'prevLiveQuizzes' && showPrevLiveQuizzes && (
        <Flex column gap={'medium'}>
          <Spacer height={16} />

          {(league.previousLiveQuizzes ?? []).map((instance) => {
            const startTimeString = new Date(instance.startTime).toLocaleString(
              league.locale,
              {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }
            )
            return (
              <Link
                to={`/live-display/${instance.invitationCode}`}
                key={instance.id}
                css={css`
                  text-decoration: none;
                `}
              >
                <Flex
                  column
                  gap={'medium'}
                  css={css`
                    background-color: ${colors.green300};
                    border-radius: 8px;
                    padding: 16px;
                  `}
                >
                  <Flex
                    horizontal="space-between"
                    wrap
                    gap={'medium'}
                    css={css`
                      width: 100%;
                    `}
                  >
                    <TextNew>{instance.quiz?.title ?? t('Quiz')}</TextNew>
                    <TextNew italic size={14}>
                      {t('{{count}} player_other', {
                        count: instance.participantCount,
                      })}
                    </TextNew>
                  </Flex>
                  <Flex vertical="flex-end" horizontal="space-between">
                    <Flex
                      css={css`
                        border: 2px solid ${colors.black};
                        border-radius: 8px;
                        padding: 16px;
                      `}
                    >
                      <TextNew>{t('Results')}</TextNew>
                    </Flex>
                    <TextNew italic size={14}>
                      {startTimeString}
                    </TextNew>
                  </Flex>
                </Flex>
              </Link>
            )
          })}
        </Flex>
      )}

      {viewerIsAdmin && subPage === 'fcQuizAdmin' && (
        <>
          <Spacer height={16} />

          <TextNew textAlign="center" color={colors.grey400} italic>
            Owners can edit league settings, view stats and host live-quizzes.
          </TextNew>

          <Flex
            column
            css={css`
              border: 2px solid ${colors.black};
              border-radius: 8px;
              padding: 24px;
            `}
          >
            <TextNew size={'large'} textAlign="center">
              {'FcQuiz admin settings'}
            </TextNew>
            <Spacer height={8} />
            <TextNew size={'small'} textAlign="center">
              {'This section is not visible for partners.'}
            </TextNew>
            <Spacer height={16} />
            <ModalTrigger
              button={({ openModal }) => (
                <PrimaryButton
                  onClick={openModal}
                  css={css`
                    height: 48px;
                    background-color: ${colors.green300};
                  `}
                >
                  <TextNew color={colors.black}>{t('Add live quiz')}</TextNew>
                </PrimaryButton>
              )}
              modal={({ closeModal }) => (
                <AddLiveQuizToLeagueModal
                  closeModal={closeModal}
                  league={league}
                />
              )}
            />
            <Spacer height={16} />
            <ModalTrigger
              button={({ openModal }) => (
                <PrimaryButton
                  onClick={openModal}
                  css={css`
                    height: 48px;
                    background-color: ${colors.black};
                  `}
                >
                  <TextNew color={colors.white}>
                    {'Manage subscriptions'}
                  </TextNew>
                </PrimaryButton>
              )}
              modal={({ closeModal }) => (
                <ManageLeagueSubscriptionsModal
                  closeModal={() => {
                    closeModal()
                    leagueQuery.refetch()
                  }}
                  slug={league.slug}
                />
              )}
            />
            <Spacer height={16} />
            <ModalTrigger
              button={({ openModal }) => (
                <PrimaryButton onClick={openModal}>
                  {t(`Add owners`)}
                </PrimaryButton>
              )}
              modal={({ closeModal }) => (
                <AddOwnerModal
                  closeModal={closeModal}
                  owners={league.owners}
                  viewerId={authUser.uid}
                  leagueId={league.id}
                />
              )}
            />
          </Flex>
        </>
      )}
      <Spacer height={80} />
    </Flex>
  )
}

export const ChallengeLeagueSettingsPage = () => {
  const { slug } = useParams()
  const t = useT()

  if (!slug) {
    return (
      <DefaultError sentryErrorMessage="ChallengeLeagueSettingsPage | No league id provided" />
    )
  }

  return (
    <Layout title={`fcQuiz | ${t('Settings')}`}>
      <Page dense>
        <Content slug={slug} />
      </Page>
    </Layout>
  )
}
