/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import { LeaguesContainer } from '../Home/LeaguesContainer'
import { League } from './queries'

export const FollowTheTeams = ({
  league,
  className,
}: {
  league: League
  className?: string
}) => {
  const t = useT()
  const navigate = useNavigate()

  if (!league.match?.homeTeam.club?.backgroundImageUrl) return null
  if (!league.match?.awayTeam.club?.backgroundImageUrl) return null

  return (
    <Flex column className={className}>
      <PageContent>
        <Text
          color="white"
          size="small"
          css={css`
            text-transform: uppercase;
          `}
        >
          {t('Follow the clubs')}
        </Text>
      </PageContent>
      <LeaguesContainer>
        {[league.match.homeTeam, league.match.awayTeam].map((team) => {
          return (
            <Flex
              key={team.name}
              column
              vertical="flex-end"
              css={css`
                background: linear-gradient(
                    rgba(0, 0, 0, 0),
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.5),
                    black,
                    black
                  ),
                  url(${team.club?.backgroundImageUrl});
                background-position: center;
                background-size: cover;
                min-height: 289px;
                min-width: 240px;
                max-width: 240px;
                padding: 16px;
                border-radius: 4px;
              `}
            >
              <Text
                color="white"
                wide
                css={css`
                  text-transform: uppercase;
                  font-size: 21px;
                  font-weight: 800;
                  ${margin.bottom('small')};
                `}
              >
                {team.name}
              </Text>

              <Flex>
                <PrimaryButton
                  variant="white"
                  size="small"
                  css={css`
                    flex: 1;
                  `}
                  onClick={() => {
                    navigate(`/clubs/${team.club?.id}`)
                  }}
                >
                  {t('View club')}
                </PrimaryButton>
              </Flex>
            </Flex>
          )
        })}
      </LeaguesContainer>
    </Flex>
  )
}
