/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link as RouterLink } from 'react-router-dom'
import { Heading } from '../../components/Heading'
import { Logo } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { Link } from '../../components/Link'
import { Text } from '../../components/Text'
import { useT } from '../../lib/i18n/useT'
import { useNativeApp } from '../../lib/native/NativeAppContext'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'

export const TermsHeader = () => {
  const t = useT()
  const { isNativeApp } = useNativeApp()

  return (
    <header
      css={css`
        padding-top: 50px;
        padding-bottom: 50px;
      `}
    >
      <PageContent>
        {isNativeApp && (
          <Flex>
            <button
              onClick={() => window.history.back()}
              css={[buttonReset, margin.bottom()]}
            >
              <Text>ᐊ {t('Back')}</Text>
            </button>
          </Flex>
        )}
        <Flex vertical="center" horizontal="space-between">
          <RouterLink
            to="/"
            css={css`
              text-decoration: none;
              cursor: pointer;
            `}
          >
            <Flex vertical="center" gap="medium">
              <Logo color={colors.green500} />
              <Heading level={1} looksLikeLevel={3} color={colors.green500}>
                fcQuiz
              </Heading>
            </Flex>
          </RouterLink>

          <Flex>
            <Link to="/terms">Terms</Link>
            <Spacer width="small" />
            <Link to="/privacy">Privacy</Link>
          </Flex>
        </Flex>
      </PageContent>
    </header>
  )
}
