/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { DependencyList, ReactNode, useEffect, useRef, useState } from 'react'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import theme from '../../styles/theme'
import { useWindowSize } from '../../utils/useWindowSize'
import { FcQuizLogo, Logo } from '../icons'
import { Flex } from '../layout/Flex'
import {
  PageContent,
  SMALL_SCREEN_PADDING,
  SUPER_SMALL_SCREEN_PADDING,
} from '../layout/PageContent'

export const EmbedWrapper = ({
  children,
  tabDeps,
  colorTheme = 'red',
}: {
  children: ReactNode
  tabDeps?: DependencyList
  colorTheme?: 'red' | 'white'
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [leftScroll, setLeftScroll] = useState(0)

  useEffect(() => {
    const scroll = scrollRef.current
    if (scroll) {
      const onScroll = () => setLeftScroll(scroll.scrollLeft)
      scroll.addEventListener('scroll', onScroll)
      return () => scroll.removeEventListener('scroll', onScroll)
    }
  }, [])

  const { width, loaded } = useWindowSize(tabDeps)

  const showTabButtons =
    scrollRef.current && loaded ? width < scrollRef.current.scrollWidth : false
  const firstTabActive =
    leftScroll < ((scrollRef.current?.scrollWidth ?? 0) - width) / 2
  const backgroundColor =
    colorTheme === 'red' ? theme.colors.red500 : theme.colors.white

  return (
    <Flex
      column
      css={css`
        height: 100vh;
      `}
    >
      <Flex
        column
        css={css`
          border-radius: 8px;
          background-color: ${backgroundColor};
          height: 100%;
          padding-top: 24px;
        `}
      >
        <PageContent width="narrow">
          <Flex vertical="center" horizontal="space-between">
            <Flex vertical="center" gap="small">
              <Logo size={20} color="white" />
              <FcQuizLogo
                width={46}
                height={10}
                css={css`
                  position: relative;
                  top: 2px;
                `}
              />
            </Flex>

            {showTabButtons && (
              <Flex vertical="center">
                <button
                  css={[
                    buttonReset,
                    css`
                      padding: 4px;
                      margin: -4px;
                    `,
                  ]}
                  onClick={() =>
                    scrollRef.current?.scrollTo({ left: 0, behavior: 'smooth' })
                  }
                >
                  <div
                    css={css`
                      width: 12px;
                      height: 12px;
                      border-radius: 12px;
                      border: 1px solid white;
                      background-color: ${firstTabActive
                        ? 'white'
                        : 'transparent'};
                    `}
                  />
                </button>

                <Spacer width={4} />

                <button
                  css={[
                    buttonReset,
                    css`
                      padding: 4px;
                      margin: -4px;
                    `,
                  ]}
                  onClick={() =>
                    scrollRef.current?.scrollTo({
                      left: scrollRef.current.scrollWidth ?? 1000,
                      behavior: 'smooth',
                    })
                  }
                >
                  <div
                    css={css`
                      width: 12px;
                      height: 12px;
                      border-radius: 12px;
                      border: 1px solid white;
                      background-color: ${!firstTabActive
                        ? 'white'
                        : 'transparent'};
                    `}
                  />
                </button>
              </Flex>
            )}
          </Flex>
        </PageContent>

        <Flex
          ref={scrollRef}
          css={[
            margin.top(24),
            css`
              flex: 1;
              overflow-x: scroll;
              padding-bottom: 24px;
              padding-left: ${SMALL_SCREEN_PADDING}px;
              padding-right: ${SMALL_SCREEN_PADDING}px;

              @media (max-width: 350px) {
                padding-left: ${SUPER_SMALL_SCREEN_PADDING}px;
                padding-right: ${SUPER_SMALL_SCREEN_PADDING}px;
              }

              @media (min-width: 660px) {
                justify-content: center;
              }
            `,
          ]}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
