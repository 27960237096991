/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { ErrorMessage, TextArea } from '../../components/TextInput'
import { IncorrectAnswer } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { colors } from '../../styles/theme'
import { smartSplit, trim } from './util'

const TextAreaPartition = (props: { texts: string[]; setTexts: any }) => {
  const [nextFocus, setNextFocus] = useState(-1)
  return (
    <Flex
      css={css`
        width: 550px;
      `}
      gap="small"
      column
    >
      {props.texts.map((text: string, index: number) => (
        <Flex
          key={index}
          css={css`
            width: 100%;
            position: relative;
          `}
          column
          vertical="center"
        >
          <Flex
            css={css`
              position: absolute;
              left: -15px;
              transform: translate(-100%, 0);
            `}
          >
            <Text color={colors.grey300}>Fact {index + 1}</Text>
          </Flex>
          {props.texts.length > 1 && (
            <Flex
              css={css`
                position: absolute;
                right: -15px;
                transform: translate(100%, 0);
              `}
              onClick={() => {
                const texts = [...props.texts]
                texts.splice(index, 1)
                props.setTexts(texts)
              }}
            >
              <IncorrectAnswer
                css={css`
                  &: hover {
                    cursor: pointer;
                  }
                `}
                color={colors.red400}
              />
            </Flex>
          )}
          <TextArea
            ref={(re: HTMLTextAreaElement) => {
              const scrollHeight = re?.scrollHeight ?? 60
              const clientHeight = re?.clientHeight ?? 60

              if (re) {
                if (scrollHeight !== clientHeight) {
                  re.style.height = scrollHeight - 1 + 'px'
                } else {
                  re.style.height = '30px' // Minimize, and let it resize itself
                }
              }
              if (nextFocus == index) {
                re?.focus()
                setNextFocus(-1)
              }
            }}
            value={text}
            onValue={(value) => {
              const textsPre = [...props.texts.slice(0, index)]
              const textsPost = [...props.texts.slice(index + 1)]

              const textMiddle = value.split('\n\n')
              props.setTexts([...textsPre, ...textMiddle, ...textsPost])
              setNextFocus(index + textMiddle.length - 1)
            }}
            placeholder="Write text"
          />
        </Flex>
      ))}
    </Flex>
  )
}

export const AIPrompt = (props: {
  setAIPrompt: any
  setFacts: any
  goBack: any
}) => {
  const [textOrUrl, setTextOrUrl] = useState<string>('')
  const [texts, setTexts] = useState<string[]>([])
  const [manualPartition, setManualPartition] = useState(false)
  const [submitError, setSubmitError] = useState('')

  useEffect(() => {
    if (texts.length > 0) {
      setTextOrUrl(texts.join('\n\n'))
    }
  }, [texts])

  return (
    <Flex horizontal="center">
      <Flex
        column
        gap="medium"
        horizontal="center"
        css={css`
          width: 550px;
        `}
      >
        <PrimaryButton
          variant="white"
          size="small"
          css={css`
            align-self: flex-start;
            background-color: ${colors.grey200};
            border-color: ${colors.grey200};
          `}
          onClick={() => props.goBack()}
        >
          Back
        </PrimaryButton>
        {manualPartition && (
          <TextAreaPartition texts={texts} setTexts={setTexts} />
        )}
        {!manualPartition && (
          <ReactQuill
            value={textOrUrl}
            onChange={(value) => {
              console.log(value)
              setTextOrUrl(value)
              setSubmitError('')
            }}
            modules={{
              toolbar: false,
            }}
            formats={['bold', 'italic', 'underline']}
            css={css`
              background-color: white;
              height: 550px;
              width: 550px;
              strong {
                font-weight: bold;
              }
              border-radius: 15px;
              .ql-snow {
                border: none;
              }

              p {
                font-size: 18px;
              }

              .ql-blank::before {
                font-size: 18px;
                font-style: normal;
              }
            `}
            placeholder="Paste text or url"
          />
        )}
        {manualPartition && (
          <Flex>
            <Text size="small" color={colors.grey300}>
              Press enter twice to partition
            </Text>
          </Flex>
        )}
        {/* Consider enabling this feature */}
        {false && textOrUrl.split('\n').length > 1 && (
          <Flex
            css={css`
              flex: 1;
              opacity: 0; /* Set initial opacity to 0 */
              animation: fadeIn 0.25s ease-in-out forwards; /* Apply the fadeIn animation */

              @keyframes fadeIn {
                from {
                  opacity: 0; /* Start with opacity 0 */
                }
                to {
                  opacity: 1; /* End with opacity 1 */
                }
              }
            `}
            horizontal="center"
            gap="small"
          >
            <Heading
              level={6}
              color="white"
              strong={false}
              css={css`
                margin: auto 0;
              `}
            >
              Manual partition:
            </Heading>
            <input
              type="checkbox"
              checked={manualPartition}
              onChange={(event) => {
                const manualPartition = event.target.checked
                setManualPartition(manualPartition)
                if (manualPartition) {
                  setTexts(smartSplit(textOrUrl))
                } else {
                  setTexts([])
                }
              }}
              css={css`
                margin: 0;
                height: 30px;
                width: 30px;
              `}
            />
          </Flex>
        )}
        {submitError && (
          <ErrorMessage
            css={css`
              width: 550px;
            `}
          >
            {submitError}
          </ErrorMessage>
        )}
        <Flex
          css={css`
            background-color: black;
            padding: 20px;
            border-radius: 5px;
            width: 150px;
            &:hover {
              cursor: pointer;
            }
          `}
          horizontal="center"
          vertical="center"
          onClick={() => {
            if (texts.length > 0) {
              props.setFacts(texts)
              return
            }
            if (!textOrUrl) {
              setSubmitError("Field can't be empty")
              return
            }
            let textOrUrlNoTags = textOrUrl.replaceAll(/<p>(.*?)<\/p>/g, '$1\n') //Remove <p>, use /n
            textOrUrlNoTags = textOrUrlNoTags.replaceAll(/<.*>[ ]*<.*>/g, '') //Replace empty
            textOrUrlNoTags = textOrUrlNoTags.replaceAll(/<br>/g, '') //Replace empty
            props.setAIPrompt(trim(textOrUrlNoTags))
          }}
        >
          <Text color="white">Continue</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
