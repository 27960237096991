/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import theme, { colors } from '../styles/theme'

export const RoundedBox: FC<{
  backgroundColor?: string
  shadow?: boolean
  className?: string
}> = ({ backgroundColor = colors.green500, shadow, children, className }) => {
  return (
    <div
      css={[
        css`
          background-color: ${backgroundColor};
          padding: 8px 16px;
          padding-top: 6px;
          border-radius: 8px;
        `,
        shadow &&
          css`
            box-shadow: ${theme.boxShadows.default};
          `,
      ]}
      className={className}
    >
      {children}
    </div>
  )
}
