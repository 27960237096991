/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../components/Button'
import { HeadingNew } from '../../../components/HeadingNew'
import { Link } from '../../../components/Link'
import { TextNew } from '../../../components/TextNew'
import { Flex } from '../../../components/layout/Flex'
import { useAuthContext } from '../../../lib/AuthContext'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn } from '../../../styles/animations'
import { Spacer } from '../../../styles/margin'
import { colors, hexOpacity } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { HomePageLeagueQuiz } from '../../Home/queries'
import { FrequencyButton } from '../../Leaderboards/LeaderboardsPage'
import { GlobalLeaderboardType } from '../../Leaderboards/queries'
import { LeaderboardItem } from '../../League/queries'
import { useClubLeaderboardQuery } from '../queries'

export const ClubLeaderboard = ({
  id: clubId,
  limit,
  className,
  selectedQuiz,
  selectedLeague,
  textColor = 'white',
  backgroundColor = 'black',
}: {
  id: string
  limit?: number
  quizzes?: HomePageLeagueQuiz[]
  className?: string
  textColor?: string
  backgroundColor?: string
  selectedQuiz?: {
    id: string
    title: string
  }
  selectedLeague?: {
    id: string
    title: string
  }
}) => {
  const queryLimit = limit ?? 100
  const [canFetchMore, setCanFetchMore] = useState(true)
  const { authUser } = useAuthContext()

  const [offset, setOffset] = useState(0)

  const [results, setResults] = useState<LeaderboardItem[]>([])

  const [leaderboardType, setLeaderboardType] = useState<GlobalLeaderboardType>(
    GlobalLeaderboardType.THIS_WEEK
  )

  const [prevLeaderboardType, setPrevLeaderboardType] = useState<
    GlobalLeaderboardType | undefined
  >(undefined)

  // Type is always ALL_TIME when a quiz is selected
  const hasSelectedQuiz = selectedQuiz !== undefined

  useEffect(() => {
    if (hasSelectedQuiz) {
      setPrevLeaderboardType(leaderboardType)
      setLeaderboardType(GlobalLeaderboardType.ALL_TIME)
    } else {
      if (prevLeaderboardType) {
        setLeaderboardType(prevLeaderboardType)
      }
    }
  }, [hasSelectedQuiz])

  const t = useT()

  const { data: leaderboardData, loading } = useClubLeaderboardQuery({
    clubId,
    variant: leaderboardType,
    limit: queryLimit,
    offset,
    quizIds: selectedQuiz ? [selectedQuiz.id] : undefined,
    leagueIds: selectedQuiz
      ? undefined
      : selectedLeague
      ? [selectedLeague.id]
      : undefined,
  })

  useEffect(() => {
    setCanFetchMore(true)
    setResults([])
    setOffset(0)
  }, [leaderboardType, selectedQuiz, selectedLeague, clubId])

  useEffect(() => {
    if (!leaderboardData) return

    setResults((prevResults) => [
      ...prevResults,
      ...leaderboardData.clubLeaderboard,
    ])

    if (leaderboardData.clubLeaderboard.length < queryLimit) {
      setCanFetchMore(false)
    }
  }, [leaderboardData])

  const viewerLeaderboardEntry = undefined

  const leaderboardTitle = () => {
    if (selectedLeague && selectedQuiz) {
      return `${selectedLeague.title} - ${selectedQuiz.title}`
    }

    if (selectedLeague) {
      return `${selectedLeague.title}`
    }

    return t('Leaderboard')
  }

  return (
    <Flex
      className={className}
      column
      css={css`
        background-color: ${backgroundColor};
        border-radius: 20px;
        padding: 32px;

        /* smaller margin on mobile devices */
        @media (max-width: 768px) {
          padding: 32px 16px;
        }
      `}
    >
      <HeadingNew
        level={3}
        looksLikeLevel={3}
        condensed
        strong
        textAlign="center"
        color={textColor}
        italic
      >
        {leaderboardTitle()}
      </HeadingNew>

      <Spacer height={16} />

      {!selectedQuiz && (
        <Flex wrap gap={'medium'} horizontal="center">
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.TODAY}
            leaderboardType={GlobalLeaderboardType.TODAY}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_WEEK}
            leaderboardType={GlobalLeaderboardType.THIS_WEEK}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_MONTH}
            leaderboardType={GlobalLeaderboardType.THIS_MONTH}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_YEAR}
            leaderboardType={GlobalLeaderboardType.THIS_YEAR}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.ALL_TIME}
            leaderboardType={GlobalLeaderboardType.ALL_TIME}
            setLeaderboardType={setLeaderboardType}
          />
        </Flex>
      )}
      <Spacer height={16} />
      {viewerLeaderboardEntry && (
        <LeaderboardItemComponent
          item={viewerLeaderboardEntry}
          isAuthUser={true}
          index={-1}
        />
      )}
      <Spacer height={16} />
      {viewerLeaderboardEntry && (
        <>
          <TextNew
            condensed
            strong
            color={textColor}
            css={css`
              font-size: 20px;
              line-height: 24px;
              font-style: italic;
            `}
          >
            {t(`Top players`)}
          </TextNew>
          <Spacer height={8} />
        </>
      )}
      {loading && (
        <>
          <LoadingPlaceholderComponent />
          <LoadingPlaceholderComponent />
          <LoadingPlaceholderComponent />
          <LoadingPlaceholderComponent />
          <LoadingPlaceholderComponent />
        </>
      )}

      {results.map((result, index) => {
        const isAuthUser = authUser?.uid === result.user.id

        return (
          <LeaderboardItemComponent
            key={result.user.id}
            item={result}
            isAuthUser={isAuthUser}
            index={index}
          />
        )
      })}

      {canFetchMore && (
        <SecondaryButton
          loading={loading}
          onClick={() => {
            setOffset(offset + queryLimit)
          }}
          css={css`
            margin-top: 8px;
            border-color: ${textColor};
            border-radius: 50px;

            opacity: 0;
            animation: ${fadeIn} 1s forwards;
            animation-delay: ${650}ms;
          `}
        >
          <TextNew italic size={'small'} color={textColor}>
            {t(`Show more`)}
          </TextNew>
        </SecondaryButton>
      )}
      <Spacer height={200} />
    </Flex>
  )
}

const LoadingPlaceholderComponent = () => {
  return (
    <div
      css={css`
        width: 100%;
        background-color: ${hexOpacity(colors.white, 0.9)};
        border-radius: 50px;
        padding-right: 16px;
        padding-left: 16px;
        height: 32px;
        margin-bottom: 3px;
        opacity: 0;
        animation: ${fadeIn} 1s forwards;
        animation-delay: ${650}ms;
      `}
    />
  )
}

export const LeaderboardItemComponent = ({
  item,
  isAuthUser,
  index,
  height = 32,
}: {
  item: LeaderboardItem
  isAuthUser: boolean
  index: number
  height?: number
}) => {
  const t = useT()
  return (
    <Link to={`/profile/${item.user.slug}`} underline={false}>
      <Flex
        key={`${item.user.id}-${item.rank}-${index}`}
        vertical="center"
        horizontal="space-between"
        css={css`
          width: 100%;
          background-color: ${isAuthUser
            ? colors.yellow200
            : index % 2 === 0
            ? colors.white
            : hexOpacity(colors.white, 0.9)};
          border-radius: 50px;
          padding-right: 16px;
          padding-left: 12px;
          height: ${height}px;
          margin-bottom: 3px;
          opacity: 0;
          animation: ${fadeIn} 650ms forwards;
        `}
      >
        <Flex
          vertical="center"
          css={css`
            @media (max-width: 400px) {
              max-width: 85%;
            }
          `}
        >
          <Flex
            horizontal="center"
            css={css`
              min-width: 24px;
            `}
          >
            <TextNew
              condensed
              italic
              weight={800}
              css={css`
                font-size: 24px;
                line-height: 24px;
              `}
            >
              {item.rank}
            </TextNew>
          </Flex>

          <Spacer width={item.rank > 99 ? 12 : 8} />

          <UserAvatar avatarData={item.user.avatarData} height={height} />

          <Spacer width={12} />
          <TextNew
            strong
            italic
            textAlign="left"
            css={css`
              width: 100%;
              padding: 0 8px;
              font-size: 18px;
              line-height: 21px;
              font-style: italic;
              overflow: hidden;
              text-overflow: ellipsis;
              letter-spacing: 0.08%;
              white-space: nowrap;
            `}
          >
            {isAuthUser
              ? item.user.displayName ?? t('You')
              : item.user.displayName}
          </TextNew>
        </Flex>

        <TextNew
          condensed
          strong
          italic
          textAlign="center"
          css={css`
            font-size: 21px;
            line-height: 24px;
            font-style: italic;
            padding-bottom: 2px;
          `}
        >
          {item.score}
        </TextNew>
      </Flex>
    </Link>
  )
}
