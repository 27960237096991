import { gql, useQuery } from '@apollo/client'

type TrainingQuizResult = {
  id: string
  mostCorrectAnswers: number
  attempts: number
}

export type TrainingQuizDifficulty = 'easy' | 'medium' | 'hard'

export type TrainingQuiz = {
  id: string
  title: string
  difficulty: TrainingQuizDifficulty
  questionCount: number
  color?: string
  viewerCanStartQuiz: boolean
  viewerResult?: TrainingQuizResult
  trainingCategory: {
    id: string
  }
}

export type TrainingCategory = {
  id: string
  title: string
  quizzes: TrainingQuiz[]
  viewerResult?: {
    score: number
  }
}

export const useTrainingPageQuery = () => {
  return useQuery<{
    trainingCategories: TrainingCategory[]
  }>(
    gql`
      query trainingPage {
        trainingCategories {
          id
          title
          quizzes {
            id
            title
            difficulty
            questionCount
            color
            viewerResult {
              id
              mostCorrectAnswers
              attempts
            }
            trainingCategory {
              id
            }
          }
          viewerResult {
            id
            score
          }
        }
      }
    `,
    { fetchPolicy: 'cache-and-network' }
  )
}

export const useTrainingQuizzes = () => {
  return useQuery<{
    trainingQuizzes: TrainingQuiz[]
  }>(
    gql`
      query trainingQuizzes {
        trainingQuizzes {
          id
          title
          difficulty
          questionCount
          color
          viewerResult {
            id
            mostCorrectAnswers
            attempts
          }
          trainingCategory {
            id
          }
        }
      }
    `,
    { fetchPolicy: 'cache-and-network' }
  )
}
