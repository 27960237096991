/** @jsxImportSource @emotion/react */

import { isAndroid } from '../utils/browser'
import { AppStoreButton, PlayStoreButton } from './DownloadButtons'

export const AndroidOrIosButton = () => {
  const buttonPlacement = 'quiz-landingpage'
  return isAndroid() ? (
    <PlayStoreButton buttonPlacement={buttonPlacement} />
  ) : (
    <AppStoreButton buttonPlacement={buttonPlacement} />
  )
}
