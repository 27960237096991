/** @jsxImportSource @emotion/react */
import { Navigate, useParams } from 'react-router-dom'
import { Layout } from '../../components/layout/Layout'
import { Flex } from '../../components/layout/Flex'
import { Text } from '../../components/Text'
import { colors } from '../../styles/theme'
import { Page } from '../../components/leagueQuiz/Page'
import { Header } from '../../components/Header'
import { useInvitationQuery } from './queries'
import { Loader } from '../../components/Loader'
import { margin } from '../../styles/margin'

import { useT } from '../../lib/i18n/useT'

import { ChallengeInvitationPage } from './ChallengeInvitationPage'
import { GroupInvitationPage } from './GroupInvitationPage'

const Content = ({ invitationId }: { invitationId: string }) => {
  const { data: invitationData, loading } = useInvitationQuery(invitationId)

  const t = useT()

  if (loading) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Loader />
      </Flex>
    )
  }

  if (
    !invitationData ||
    !invitationData.invitation ||
    !invitationData.invitation.challengeGroup
  ) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Text>{t('Invitation not found')}</Text>
      </Flex>
    )
  }

  if (invitationData.invitation.challengeGroup.currentQuiz) {
    return <ChallengeInvitationPage invitation={invitationData.invitation} />
  }

  return <GroupInvitationPage invitation={invitationData.invitation} />
}

export const InvitationPage = () => {
  const invitationId = useParams<{ invitationId: string }>().invitationId

  const t = useT()

  if (!invitationId) {
    return <Navigate to="/" replace />
  }

  return (
    <Layout
      backgroundColor={colors.grey100}
      title={`fcQuiz | ${t(`Invitation`)}`}
    >
      <Header color="grey" hideSignupButton />
      <Page dense>
        <Content invitationId={invitationId} />
      </Page>
    </Layout>
  )
}
