/** @jsxImportSource @emotion/react */

import { SerializedStyles, css } from '@emotion/react'
import { margin } from '../styles/margin'
import { textStyleLegacy } from '../styles/styles'
import { Flex } from './layout/Flex'
import { Text } from './Text'
import { useT } from '../lib/i18n/useT'

type Props<T extends string> = {
  children: React.ReactNode
  label?: string
  value?: T
  onValue: (value: T) => void
  disabled?: boolean
  className?: string
  textColor?: string
  selectCss?: SerializedStyles
  ariaLabel?: string
}
export function SelectInput<T extends string>({
  label,
  value,
  onValue,
  disabled,
  children,
  className,
  textColor = 'black',
  selectCss = css``,
  ariaLabel,
}: Props<T>) {
  const t = useT()

  const chevronColor = () => {
    if (textColor === 'black') {
      return '000'
    }
    if (textColor === 'white') {
      return 'fff'
    }
    if (textColor.includes('#')) {
      return textColor.replace('#', '')
    }

    return '000'
  }

  const backgroundImagePath = `data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${
    chevronColor() as string
  }%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E`

  const content = (
    <select
      value={value}
      onChange={(event) => onValue(event.target.value as T)}
      disabled={disabled}
      aria-label={ariaLabel ?? t('Language')}
      css={[
        textStyleLegacy,
        css`
          padding: 4px 32px 6px 12px;
          border-radius: 1000px;
          border: 1px solid black;
          appearance: none;
          display: block;
          width: 100%;
          max-width: 100%;
          border-radius: 100px;
          background-color: ${textColor === 'white' ? 'transparent' : 'white'};
          background-image: url(${backgroundImagePath});
          background-repeat: no-repeat;
          background-position: right 0.7em top 50%;
          background-size: 0.65em auto;
          border-color: ${textColor};
          color: ${textColor};
          font-size: 15px;
        `,
        selectCss,
      ]}
    >
      {children}
    </select>
  )

  return label ? (
    <label
      css={css`
        display: block;
      `}
      className={className}
    >
      <Flex column>
        <Text css={margin.bottom('tiny')} color={textColor}>
          {label}
        </Text>
        {content}
      </Flex>
    </label>
  ) : (
    <div className={className}>{content}</div>
  )
}
