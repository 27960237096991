import { gql, useMutation, useQuery } from '@apollo/client'
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createObserver } from '../../utils/observer'
import { NotificationSettings } from '../../views/EditProfile/queries'
import { useRegisterUserMutation } from '../../views/auth/queries'
import { useAuthContext } from '../AuthContext'
import { ErrorCode, hasErrorCode } from '../apollo/apiError'
import { useCurrentLocale } from '../i18n/locale'
import {
  NATIVE_TO_WEBVIEW_CONSTANTS_VARIABLE_NAME,
  NativeToWebviewConstants,
  NativeToWebviewMessage,
  PermissionStatus,
  WEBVIEW_ON_MESSAGE_FUNCTION_NAME,
  WebviewToNativeMessage,
  parseNativeToWebviewMessage,
  serializeWebviewToNativeMessage,
} from './webNativeApi'

export const hasReactNativeWebViewPostMessage = () => {
  if (
    (window as any).ReactNativeWebView &&
    (window as any).ReactNativeWebView.postMessage &&
    typeof (window as any).ReactNativeWebView.postMessage === 'function'
  ) {
    return true
  } else {
    return false
  }
}
const dataList: string[] = []

export const getNativeAppConstants = () => {
  return (window as any)[NATIVE_TO_WEBVIEW_CONSTANTS_VARIABLE_NAME] as
    | NativeToWebviewConstants
    | undefined
}

const webviewOnMessageObserver = createObserver<
  (message: NativeToWebviewMessage) => void,
  NativeToWebviewMessage
>()

;(window as any)[WEBVIEW_ON_MESSAGE_FUNCTION_NAME] = (data: string) => {
  dataList.push(data)
  setTimeout(() => {
    console.log('received message from webview', dataList)
  }, 3000)

  const message = parseNativeToWebviewMessage(data)
  if (message) {
    webviewOnMessageObserver.notifyAll(message)
  }
}

export const webviewToNativePostMessage = (message: WebviewToNativeMessage) => {
  if (hasReactNativeWebViewPostMessage()) {
    ;(window as any).ReactNativeWebView.postMessage(
      serializeWebviewToNativeMessage(message)
    )
  }
}

type ContextType = {
  isNativeApp: boolean
  pushNotificationsPermissionStatus?: PermissionStatus
  askForPushNotificationPermission: () => void
  trackingPermissionStatus?: PermissionStatus
  requestTrackingPermission: () => void
  openNativeLoginModal: () => void
}
const Context = createContext<ContextType>({
  isNativeApp: false,
  pushNotificationsPermissionStatus: undefined,
  askForPushNotificationPermission: () => {},
  trackingPermissionStatus: undefined,
  requestTrackingPermission: () => {},
  openNativeLoginModal: () => {},
})

export const useNativeApp = () => useContext(Context)

export const NativeAppProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const isLoggedIn = Boolean(useAuthContext().authUser)
  const isNativeApp = hasReactNativeWebViewPostMessage()
  const { customTokenSignin } = useAuthContext()
  const [registerUserMutation] = useRegisterUserMutation()
  const locale = useCurrentLocale()

  const [authToken, setAuthToken] = useState<string | undefined>(undefined)

  const [
    pushNotificationsPermissionStatus,
    setPushNotificationsPermissionStatus,
  ] = useState<ContextType['pushNotificationsPermissionStatus']>(undefined)

  const [trackingPermissionStatus, setTrackingPermissionStatus] =
    useState<ContextType['pushNotificationsPermissionStatus']>(undefined)

  const [setPushNotificationTokenMutation] =
    useSetPushNotificationTokenMutation()

  const { data, refetch } = useGetSignInTokenQuery(authToken)

  useEffect(() => {
    if (data?.getSignInToken?.signInToken) {
      const token = data.getSignInToken.signInToken
      try {
        customTokenSignin(token).then(() => {
          window.location.reload()
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [data, customTokenSignin])

  useEffect(() => {
    if (authToken) {
      refetch()
    }
  }, [authToken, refetch])

  const onMessage = useCallback(
    async (message: NativeToWebviewMessage): Promise<'handled'> => {
      switch (message.type) {
        case 'set-push-notification-token': {
          if (isLoggedIn) {
            setPushNotificationTokenMutation({
              variables: { token: message.token },
            })
          }
          return 'handled'
        }

        case 'set-push-notifications-permission-status': {
          setPushNotificationsPermissionStatus(message.status)
          return 'handled'
        }

        case 'request-tracking-permissions': {
          setTrackingPermissionStatus(message.status)
          return 'handled'
        }

        case 'set-auth-user': {
          setAuthToken(message.token)
          return 'handled'
        }

        case 'sign-up':
          {
            console.log('Sign up')
            try {
              const result = await registerUserMutation({
                variables: {
                  input: {
                    username: message.details.playerName,
                    email: message.details.email,
                    password: message.details.password,
                    locale,
                    emailNewLeagueQuiz: false,
                  },
                },
              })
              if (result.data?.registerUser?.signInToken) {
                const token = result.data.registerUser.signInToken
                await customTokenSignin(token).then(() => {
                  window.location.reload()
                })
              }
            } catch (e) {
              if (hasErrorCode(e, ErrorCode.USERNAME_NOT_AVAILABLE)) {
                signUpError('Username not available')
              } else if (hasErrorCode(e, ErrorCode.USERNAME_INVALID)) {
                signUpError('Username invalid')
              } else if (hasErrorCode(e, ErrorCode.EMAIL_ALREADY_EXISTS)) {
                signUpError('Email already exists')
              } else {
                signUpError('Unknown error')
              }
            }
          }
          return 'handled'
      }
    },
    [isLoggedIn, setPushNotificationTokenMutation, setAuthToken]
  )

  useEffect(() => {
    return webviewOnMessageObserver.add(onMessage)
  }, [onMessage])

  useEffect(() => {
    if (isLoggedIn) {
      webviewToNativePostMessage({
        type: 'set-is-logged-in',
        isLoggedIn,
      })
    }

    webviewToNativePostMessage({
      type: 'request-push-notification-permission-status',
    })
  }, [isLoggedIn])

  const askForPushNotificationPermission = useCallback(() => {
    webviewToNativePostMessage({
      type: 'ask-for-push-notification',
    })
  }, [])

  const requestTrackingPermission = useCallback(() => {
    webviewToNativePostMessage({
      type: 'request-tracking-permissions',
    })
  }, [])

  const openNativeLoginModal = useCallback(() => {
    webviewToNativePostMessage({
      type: 'open-login-modal',
    })
  }, [])

  const signUpError = useCallback((message: string) => {
    console.log('Sign up error', message)
    webviewToNativePostMessage({
      type: 'auth-error',
      message,
    })
  }, [])

  const value = useMemo(
    () => ({
      isNativeApp,
      pushNotificationsPermissionStatus,
      askForPushNotificationPermission,
      trackingPermissionStatus,
      requestTrackingPermission,
      openNativeLoginModal,
    }),
    [
      isNativeApp,
      pushNotificationsPermissionStatus,
      askForPushNotificationPermission,
      trackingPermissionStatus,
      requestTrackingPermission,
      openNativeLoginModal,
    ]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useSetPushNotificationTokenMutation = () =>
  useMutation<
    {
      setPushNotificationToken: {
        id: string
        notificationSettings: NotificationSettings
      }
    },
    { token: string }
  >(
    gql`
      mutation setPushNotificationToken($token: String!) {
        setPushNotificationToken(token: $token) {
          id
          pushNotificationToken
        }
      }
    `
  )

const getSignInTokenQuery = gql`
  query getSignInToken($token: String!) {
    getSignInToken(token: $token) {
      signInToken
    }
  }
`

export const useGetSignInTokenQuery = (token?: string) => {
  if (!token) {
    return { data: undefined, loading: false, refetch: () => {} }
  }

  return useQuery<{ getSignInToken: { signInToken: string } }>(
    getSignInTokenQuery,
    {
      variables: { token },
    }
  )
}
