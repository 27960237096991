import { gql, useMutation, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { LeagueQuizStatus, LeagueStatus } from '../../lib/types'
import { useQueryUnpack } from '../../utils/useQueryUnpack'
import {
  Badge,
  DailyCareerPathLeague,
  DailyLeague,
  LeaderboardItem,
  LeagueFrequency,
  SpecialQuiz,
} from '../League/queries'
import { MaskShape } from './../../lib/imageMask'
import { Club } from '../Clubs/queries'

export const homePageLeagueFragment = gql`
  fragment HomePageLeagueFragment on League {
    id
    slug
    title
    leagueCardImageUrl
    frequency
    status
    availableFrom
    availableTo
    subscriberCount
    participantCount
    viewerIsSubscribed
    quizzesPlayedLastTwoWeeks
    quizzes(status: [active], limit: 1, onlyStartable: true) {
      id
      title
      availableFrom
      availableTo
      status
      viewerCanStartQuiz
      quizType
      viewerData {
        id
        canStartQuiz
        hasCompletedQuiz
        activeQuizInstance {
          id
        }
      }
    }
    ownerClub {
      id
      slug
      name
      logoUrl
      logoShape
      backgroundImageUrl
      verified
      public
    }
  }
`

export const homePageSpecialQuizFragment = gql`
  fragment HomePageSpecialQuizFragment on League {
    id
    slug
    title
    imageUrl
    color
    showClubsLeaderboard
    latestStartableQuiz {
      id
      title
      slug
      questionsCount
      availableFrom
      availableTo
      status
      viewerCanStartQuiz
      participantCount
      viewerData {
        id
        canStartQuiz
        hasCompletedQuiz
        activeQuizInstance {
          id
        }
        result {
          leagueQuizInstanceId
          correctAnswersCount
          correctAnswerScore
          timeBonus
          totalScore
        }
      }
    }
    upcommingQuiz {
      id
      title
      slug
      questionsCount
      availableFrom
      availableTo
      status
      viewerCanStartQuiz
      participantCount
      viewerData {
        id
        canStartQuiz
        hasCompletedQuiz
        activeQuizInstance {
          id
        }
        result {
          leagueQuizInstanceId
          correctAnswersCount
          correctAnswerScore
          timeBonus
          totalScore
        }
      }
    }
    badges {
      id
      dbId
      title
      imageUrl
      criteria {
        quizzesCompleted
      }
      requirementMessage
      earnedMessage
      league {
        id
      }
      progressBackgroundColor
      progressForegroundColor
      seenByUser
      earnedAt
      userId
    }
    clubsLeaderboard(limit: 3) {
      id
      club {
        id
        name
        logoUrl
        logoShape
        public
        membersCount
        viewerIsMember
      }
      rank
      score
    }
    quizzesPlayed
    participantCount
    live
  }
`

export const homePageLeagueQuizFragment = gql`
  fragment HomepageLeagueQuizFragment on LeagueQuiz {
    id
    title
    slug
    availableFrom
    availableTo
    status
    viewerCanStartQuiz
    quizType
    participantCount
    viewerCanQuickMatch
    imageUrl
    viewerData {
      id
      canStartQuiz
      hasCompletedQuiz
      activeQuizInstance {
        id
      }
      result {
        leagueQuizInstanceId
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
    }
    league {
      id
      slug
      title
      status
      availableFrom
      availableTo
      leagueCardImageUrl
      imageUrl
      color
      live
      participantCount
      viewerIsSubscribed
    }
  }
`

export type HomePageLeagueQuiz = {
  id: string
  title: string
  slug: string
  availableFrom: string
  availableTo: string
  status: LeagueQuizStatus
  viewerCanStartQuiz: boolean
  userRelevance?: number
  quizType: string
  viewerCanQuickMatch: boolean
  participantCount: number
  imageUrl?: string
  questionsCount?: number
  viewerData: {
    canStartQuiz: boolean
    hasCompletedQuiz: boolean
    activeQuizInstance?: {
      id: string
    }
  }
  league: {
    id: string
    slug: string
    title: string
    status: LeagueStatus
    availableFrom: string
    availableTo: string
    leagueCardImageUrl?: string
    imageUrl?: string
    color?: string
    live?: number
    participantCount: number
    viewerIsSubscribed: boolean
  }
}

export type ViewerLeaderboardEntry = {
  rank: number
  trophies: Array<{
    name: string
  }>
}

export type HomePageLeague = {
  id: string
  clubLeagueSubscriptionId?: string
  slug: string
  title: string
  leagueCardImageUrl?: string
  status: LeagueStatus
  frequency?: LeagueFrequency
  availableFrom: string
  availableTo: string
  quizzes: HomePageLeagueQuiz[]
  quizzesPlayedLastTwoWeeks: number
  subscriberCount: number
  participantCount: number
  viewerLeaderboardEntry?: ViewerLeaderboardEntry
  viewerIsSubscribed: boolean
  relevance?: number
  ownerClub?: Club
}

export type LeagueDisplayType = 'league' | 'player' | 'latestQuiz'
export type HomePageClub = {
  id: string
  name: string
  slug: string
  logoUrl?: string
  logoShape: MaskShape
  membersCount: number
  backgroundColor: string
  textColor: string
}

export type HomePageLeagueCollection = {
  id: string
  title: string
  leagues: HomePageLeague[]
  display?: LeagueDisplayType
}

export type HomePageFriendActivity = {
  friend: {
    id: string
    username: string
    displayName: string
    avatarData: string
  }
  quiz: HomePageLeagueQuiz
  score: number
}

export type DateRangeChallengeViewerEntry = {
  challengeId: string
  userId: string
  quizzesCompleted: number
}

export type TicketTier = {
  id: string
  multiplier: number
  quizzesPlayed: number
  progress: number
}

export type DateRangeChallenge = {
  id: string
  title: string
  description: string | null
  availableFrom: string
  availableTo: string
  backgroundColor: string | null
  backgroundImageUrl?: string | null
  prizeImageUrl: string | null
  partnerLogoUrl: string | null
  participantCount: number
  viewerEntry: DateRangeChallengeViewerEntry | null
  ticketTiers: TicketTier[]
  ticketCount: number
}

export type HomePageQueryResult = {
  homePage: {
    promotedLeagueCollection?: HomePageLeagueCollection
    specialQuiz?: SpecialQuiz
    promotedClubs?: HomePageClub[]
    leagueCollections?: HomePageLeagueCollection[]
    popularQuizzes: HomePageLeagueQuiz[]
    friendActivity?: HomePageFriendActivity
    dateRangeChallenge?: DateRangeChallenge
  }
  getJoinableLiveQuizExists: boolean
  earnedBadges: Badge[]
  usersGlobalLeaderboard: LeaderboardItem[]
  dailyQuiz?: DailyLeague
  dailyCareerPath?: DailyCareerPathLeague
  ticketTiers: TicketTier[]
}

export const homePageQuery = gql`
  query homePage {
    homePage {
      id
      promotedLeagueCollection {
        id
        title
        leagues {
          ...HomePageLeagueFragment
        }
      }
      promotedClubs {
        id
        name
        slug
        logoUrl
        logoShape
        membersCount
        backgroundColor
        textColor
        verified
      }
      specialQuiz {
        ...HomePageSpecialQuizFragment
      }
      leagueCollections {
        id
        title
        leagues {
          ...HomePageLeagueFragment
        }
      }
      popularQuizzes {
        ...HomepageLeagueQuizFragment
      }
      friendActivity {
        friend {
          id
          username
          displayName
          avatarData
        }
        quiz {
          ...HomepageLeagueQuizFragment
        }
        score
      }
      dateRangeChallenge {
        id
        title
        description
        availableFrom
        availableTo
        backgroundColor
        backgroundImageUrl
        prizeImageUrl
        partnerLogoUrl
        participantCount
        viewerEntry {
          challengeId
          userId
          quizzesCompleted
        }
        ticketTiers {
          id
          multiplier
          quizzesPlayed
          progress
        }
        ticketCount
      }
    }

    getJoinableLiveQuizExists

    earnedBadges: checkClubMembershipBadges {
      id
      dbId
      title
      imageUrl
      criteria {
        quizzesCompleted
      }
      requirementMessage
      earnedMessage
      league {
        id
      }
      progressBackgroundColor
      progressForegroundColor
      seenByUser
      earnedAt
      userId
    }

    usersGlobalLeaderboard(input: { type: TODAY, limit: 3 }) {
      user {
        id
        username
        slug
        avatarData
        displayName
        mainClubMembership {
          id
          role
          club {
            id
            slug
            name
            logoUrl
            logoShape
          }
        }
      }
      rank
      score
    }

    dailyQuiz: league(slug: "the-daily") {
      id
      currentQuiz {
        id
        title
        slug
        questionsCount
        availableFrom
        availableTo
        status
        viewerCanStartQuiz
        participantCount
        viewerData {
          id
          canStartQuiz
          hasCompletedQuiz
          activeQuizInstance {
            id
            quizType
          }
          result {
            leagueQuizInstanceId
            correctAnswersCount
            correctAnswerScore
            timeBonus
            totalScore
          }
        }
        quizType
      }
      streak
      hasSeenStreak
      live
      participantCount
      viewerIsOwner
    }

    dailyCareerPath: league(slug: "the-daily-career-path") {
      id
      latestStartableQuiz {
        id
        title
        slug
        questionsCount
        availableFrom
        availableTo
        status
        viewerCanStartQuiz
        participantCount
        quizType
        viewerData {
          id
          canStartQuiz
          hasCompletedQuiz
          activeQuizInstance {
            id
          }
          result {
            leagueQuizInstanceId
            correctAnswersCount
            correctAnswerScore
            timeBonus
            totalScore
          }
        }
      }
      streak
      hasSeenStreak
      viewerHasPlayedToday
      viewerIsOwner
      participantCount
      live
    }
  }

  ${homePageSpecialQuizFragment}
  ${homePageLeagueFragment}
  ${homePageLeagueQuizFragment}
`

export const viewerSubscribedLeaguesQuery = gql`
  query subscribedLeagues {
    subscribedLeagues {
      ...HomePageLeagueFragment
    }
  }

  ${homePageLeagueFragment}
`

export const useViewerSubscribedLeagues = () => {
  return useQuery<{ subscribedLeagues: HomePageLeague[] }>(
    viewerSubscribedLeaguesQuery
  )
}

export const homepageSpecialQuizQuery = gql`
  query homepageSpecialQuiz {
    homePage {
      specialQuiz {
        ...HomePageSpecialQuizFragment
      }
    }
  }

  ${homePageSpecialQuizFragment}
`

export const useHomepageSpecialQuizQuery = () => {
  return useQuery<{ homePage: { specialQuiz?: SpecialQuiz } }>(
    homepageSpecialQuizQuery
  )
}

export const useLeagueCollectionsQuery = () => {
  return useQuery<{
    homePage: { leagueCollections: HomePageLeagueCollection[] }
  }>(
    gql`
      query leagueCollections {
        homePage {
          id
          leagueCollections {
            id
            title
            display
            leagues {
              ...HomePageLeagueFragment
            }
          }
        }
      }

      ${homePageLeagueFragment}
    `
  )
}

export const usePromotedClubs = () => {
  return useQuery<{
    homePage: {
      promotedClubs: HomePageClub[]
    }
  }>(
    gql`
      query promotedClubs {
        homePage {
          id
          promotedClubs {
            id
            name
            slug
            logoUrl
            logoShape
            membersCount
          }
        }
      }
    `
  )
}

export const useHomePageQuery = () => {
  return useQuery<HomePageQueryResult>(homePageQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export type CompletedLeague = {
  id: string
  slug: string
  title: string
  status: LeagueStatus.completed
  availableTo: string
  participantCount: number
  viewerLeaderboardEntry?: {
    rank: number
  }
}
export const useCompletedLeagues = (options: { skip?: boolean } = {}) => {
  return useQuery<{ leagues: CompletedLeague[] }>(
    gql`
      query completedLeagues {
        leagues(input: { status: completed }) {
          id
          slug
          title
          status
          availableTo
          participantCount
        }
      }
    `,
    options
  )
}

export type RecommendedLeagueQuiz = {
  id: string
  title: string
  slug: string
  availableFrom: string
  availableTo: string
  status: LeagueQuizStatus
  viewerCanStartQuiz: boolean
  quizType: string
  league: {
    id: string
    slug: string
    title: string
    status: LeagueStatus
    availableFrom: string
    availableTo: string
  }
  viewerData: {
    canStartQuiz: boolean
    hasCompletedQuiz: boolean
    activeQuizInstance?: {
      id: string
    }
    result?: {
      leagueQuizInstanceId: string
      correctAnswersCount: number
      correctAnswerScore: number
      timeBonus: number
      totalScore: number
    }
  }
}

export const useRecommendedLeagueQuizzes = () => {
  const query = useQuery<{
    recommendedLeagueQuizzes: RecommendedLeagueQuiz[]
  }>(
    gql`
      query recommendedLeagueQuizzes {
        recommendedLeagueQuizzes(limit: 10) {
          id
          title
          slug
          availableFrom
          availableTo
          status
          viewerCanStartQuiz
          quizType
          league {
            id
            slug
            title
            status
            availableFrom
            availableTo
          }
          viewerData {
            canStartQuiz
            hasCompletedQuiz
            activeQuizInstance {
              id
            }
            result {
              leagueQuizInstanceId
              correctAnswersCount
              correctAnswerScore
              timeBonus
              totalScore
            }
          }
        }
      }
    `
  )
  return query.data?.recommendedLeagueQuizzes ?? []
}

export type LeagueCategory = {
  id: string
  title: string
}

export const useLeagueCategoriesQuery = (skip?: boolean) => {
  const query = useQuery<{
    leagueCategories: LeagueCategory[]
  }>(
    gql`
      query leagueCategories {
        leagueCategories {
          id
          title
        }
      }
    `,
    {
      skip,
    }
  )
  return query.data?.leagueCategories ?? []
}

const ADD_USER_FAVORITE_CATEGORY = gql`
  mutation addUserFavoriteCategory($categoryIds: [ID!]!) {
    addUserFavoriteCategories(categoryIds: $categoryIds) {
      id
    }
  }
`

export const useAddUserFavoriteCategoryMutation = () => {
  return useMutation<
    { addUserFavoriteCategory: { id: string } },
    { categoryIds: string[] }
  >(ADD_USER_FAVORITE_CATEGORY)
}

const SET_USER_ONBOARDING_COMPLETED = gql`
  mutation setUserOnboardingCompleted {
    setOnboardingCompleted {
      id
    }
  }
`

export const useSetUserOnboardingCompletedMutation = () => {
  return useMutation(SET_USER_ONBOARDING_COMPLETED)
}

const leaguesByCategoryQuery = gql`
  query leaguesByCategory($category: String!) {
    leaguesByCategory(category: $category) {
      categories {
        id
        title
      }
      relevance
      ...HomePageLeagueFragment
    }
  }

  ${homePageLeagueFragment}
`

export const useLeaguesByCategoryQuery = (category: string) => {
  const query = useQuery<{ leaguesByCategory: HomePageLeague[] }>(
    leaguesByCategoryQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { category },
      skip: category.trim() === '',
    }
  )

  const data = useMemo(() => {
    if (!query.data?.leaguesByCategory) {
      return []
    }

    const leagues = [...query.data.leaguesByCategory]

    // sort by relevance
    leagues.sort((a, b) => {
      if (a.relevance && b.relevance) {
        return b.relevance - a.relevance
      }
      return 0
    })

    // if a league has no startable quizzes, move it to the end
    leagues.sort((a, b) => {
      const aHasStartableQuizzes =
        (a.quizzes ?? []).filter((quiz) => quiz.viewerCanStartQuiz).length > 0
      const bHasStartableQuizzes =
        (b.quizzes ?? []).filter((quiz) => quiz.viewerCanStartQuiz).length > 0

      if (aHasStartableQuizzes && !bHasStartableQuizzes) {
        return -1
      }
      if (!aHasStartableQuizzes && bHasStartableQuizzes) {
        return 1
      }
      return 0
    }) ?? []
    return leagues
  }, [query.data?.leaguesByCategory])

  return { ...query, data }
}

const LEAGUES_BY_CATEGORIES = gql`
  query leaguesByCategories($categories: [String!]!) {
    leaguesByCategories(categories: $categories) {
      categories {
        id
        title
      }
      ...HomePageLeagueFragment
    }
  }

  ${homePageLeagueFragment}
`

export const useUserRecommendedLeaguesQuery = (categories: string[]) => {
  return useQueryUnpack<HomePageLeague[]>(LEAGUES_BY_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    variables: { categories },
  })
}

const SHOW_ONBOARDING = gql`
  query showOnboarding {
    showOnboarding
  }
`

export const useShowOnboardingQuery = () => {
  return useQuery<{ showOnboarding: boolean }>(SHOW_ONBOARDING)
}

export const useGetJoinableLiveQuizExistsQuery = () => {
  return useQuery<{ getJoinableLiveQuizExists: boolean }>(
    gql`
      query getJoinableLiveQuizExists {
        getJoinableLiveQuizExists
      }
    `
  )
}

// mutation joinDateRangeChallenge(input: JoinDateRangeChallengeInput): DateRangeChallenge!

const joinDateRangeChallengeMutation = gql`
  mutation joinDateRangeChallenge($input: JoinDateRangeChallengeInput!) {
    joinDateRangeChallenge(input: $input) {
      id
      viewerEntry {
        challengeId
        userId
        quizzesCompleted
      }
    }
  }
`

export const useJoinDateRangeChallengeMutation = () => {
  return useMutation<{ id: string }, { input: { challengeId: string } }>(
    joinDateRangeChallengeMutation
  )
}
