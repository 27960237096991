import { Selector } from '../../Selector'
import { ReactComponent as EyesA } from './Eyes A.svg'
import { ReactComponent as EyesB } from './Eyes B.svg'
import { ReactComponent as EyesC } from './Eyes C.svg'
import { ReactComponent as EyesD } from './Eyes D.svg'
import { ReactComponent as EyesE } from './Eyes E.svg'
import { ReactComponent as EyesF } from './Eyes F.svg'
import { ReactComponent as EyesG } from './Eyes G.svg'
import { ReactComponent as EyesH } from './Eyes H.svg'

export const eyesStyles: {
  [key: string]: {
    svg: React.SVGProps<SVGSVGElement>
    dy: number
    premium: boolean
  }
} = {
  EyesA: { svg: <EyesA />, dy: 0, premium: false },
  EyesB: { svg: <EyesB />, dy: -1, premium: false },
  EyesC: { svg: <EyesC />, dy: -0.87, premium: false },
  EyesF: { svg: <EyesF />, dy: 0, premium: false },
  EyesD: { svg: <EyesD />, dy: 9, premium: false },
  EyesE: { svg: <EyesE />, dy: 12, premium: false },
  EyesG: { svg: <EyesG />, dy: 15, premium: false },
  EyesH: { svg: <EyesH />, dy: 15, premium: false },
}

export const Eyes = () => {
  return <Selector selectedOptionKey={'eyes'} y={56} styles={eyesStyles} />
}
