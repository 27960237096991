/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useT } from '../lib/i18n/useT'
import { FcQuizQrCode } from './QrCodeComponent'
import { Flex } from './layout/Flex'
import { Modal } from './modal/Modal'
import { useState } from 'react'
import { Spacer } from '../styles/margin'
import { PrimaryButton } from './Button'
import { FaShareSquare } from 'react-icons/fa'
import { Text } from './Text'
import { colors } from '../styles/theme'

export const GroupInvitationModal = ({
  invitationId,
  closeModal,
}: {
  invitationId: string
  closeModal: () => void
}) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const t = useT()

  const invitationLink = `${window.location.origin}/invitation/${invitationId}`
  const isMobileDevice = window.navigator.share !== undefined

  const shareLink = async () => {
    if (!invitationLink) {
      return
    }

    const message = t('Can you beat me in this Quiz?')

    if (isMobileDevice) {
      const shareText = `⚽️ fcQuiz - ⚽️\n\n${invitationLink}\n\n${message}`
      window.navigator
        .share({
          text: shareText,
        })
        .catch(() => {
          // ignore error
        })
    } else {
      navigator.clipboard.writeText(invitationLink)
      setLinkCopied(true)
      setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
    }
  }
  return (
    <Modal heading={t('Invite')} closeModal={closeModal}>
      <Flex horizontal="center">
        <Flex
          column
          css={css`
            width: 100%;
            max-width: 220px;
          `}
        >
          <FcQuizQrCode url={invitationLink} />
          <Spacer height={16} />
          {isMobileDevice && (
            <PrimaryButton
              onClick={shareLink}
              css={css`
                height: 50px;
                opacity: ${linkCopied ? '0.5' : '1'};
              `}
            >
              <Flex gap="small" vertical="center" horizontal="center">
                <FaShareSquare color="white" size={20} />
                <Text color="white ">
                  {linkCopied ? t('Copied') : t('Share')}
                </Text>
              </Flex>
            </PrimaryButton>
          )}

          {!isMobileDevice && (
            <Flex
              vertical="center"
              horizontal="center"
              onClick={shareLink}
              css={css`
                height: 50px;
                width: 100%;
                background-color: ${colors.grey200};
                border-radius: 8px;
                padding-left: 16px;
                cursor: pointer;
                &:hover {
                }
              `}
            >
              <Text
                strong
                condensed
                color={colors.black}
                css={css`
                  width: 80%;
                  overflow-x: scroll;
                  white-space: nowrap;
                  user-select: none;
                  /* hide scrollbar */
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                `}
              >
                {invitationLink}
              </Text>
              <Text
                strong
                css={css`
                  padding: 0 16px;
                `}
                color={colors.black}
              >
                {linkCopied ? t('Copied') : t('Copy')}
              </Text>
            </Flex>
          )}
          <Spacer height={16} />
        </Flex>
      </Flex>
    </Modal>
  )
}
