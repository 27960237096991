/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn } from '../../../styles/animations'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { getTextColorForClub } from '../../Challenge/DisplayPage/util'
import { Club } from '../../Clubs/queries'
import { LeaderboardItem } from '../../League/queries'
import { LiveCompletedQuestion } from '../../LiveQuiz/queries'
import { LiveQuizLeaderboard } from './LiveQuizLeaderboard'
import { QuestionHeader } from './QuestionHeader'

export const LastCompletedQuestionDisplayLiveQuiz = ({
  isLastQuestion,
  lastCompletedQuestion,
  club,
  numCompletedQuestions,
  locale,
  roundResults,
  totalResults,
}: {
  isLastQuestion: boolean
  lastCompletedQuestion: LiveCompletedQuestion | null
  club: Club | undefined
  numCompletedQuestions: number
  locale?: string
  roundResults: LeaderboardItem[]
  totalResults: LeaderboardItem[]
}) => {
  const [pageState, setPageState] = useState<
    'showText' | 'showRoundLeaderboard' | 'showTotalLeaderboard'
  >('showText')

  const t = useT()

  const textColor = club ? getTextColorForClub(club) : colors.black

  const intervalTime = 7000

  useInterval(() => {
    if (pageState === 'showText') {
      setPageState('showRoundLeaderboard')
      return
    }

    // if we are on the last question, we don't want to change page
    // as it would spoil the final leaderboard
    // state remains on showRoundLeaderboard
    if (isLastQuestion) {
      return
    }

    if (pageState === 'showRoundLeaderboard') {
      setPageState('showTotalLeaderboard')
      return
    }

    if (pageState === 'showTotalLeaderboard') {
      setPageState('showRoundLeaderboard')
      return
    }
  }, intervalTime)

  if (!lastCompletedQuestion) {
    return null
  }

  const correctAlternatives = lastCompletedQuestion.alternatives.filter(
    (alternative) => alternative.isCorrectAlternative
  )

  // const spinnerValue = ((progress % intervalTime) / intervalTime) * 100
  // const pathColor = hexOpacity(league?.textColor || colors.black, 0.8)
  // const trailColor = hexOpacity(league?.textColor || colors.black, 0.3)

  // const hideSpinner = isLastQuestion && pageState === 'showRoundLeaderboard'

  return (
    <Flex
      column
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <QuestionHeader
        club={club}
        locale={locale}
        questionNumber={numCompletedQuestions}
      />

      {/* {!hideSpinner && (
        <CircularProgressbar
          key={`spinner-${progress}`}
          value={spinnerValue}
          css={css`
            position: absolute;
            bottom: 1vw;
            right: 1vw;
            height: 3vw;
            width: 3vw;
          `}
          strokeWidth={10}
          styles={buildStyles({
            pathColor,
            trailColor,
            strokeLinecap: 'butt',
          })}
        /> */}

      {pageState === 'showText' && (
        <Flex
          column
          vertical="center"
          horizontal="center"
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 20vw;
            opacity: 0;
            animation: ${fadeIn} 650ms forwards;
          `}
        >
          <TextNew
            color={textColor}
            shadow
            italic
            textAlign="center"
            condensed
            css={[
              css`
                font-weight: 600;
                font-size: 5vw;
              `,
            ]}
          >
            {lastCompletedQuestion.text}
          </TextNew>
          <Spacer
            css={css`
              height: 10vh;
            `}
          />

          {correctAlternatives.length && (
            <>
              {correctAlternatives.map((alternative) => (
                <TextNew
                  key={alternative.id}
                  color={textColor}
                  shadow
                  italic
                  textAlign="center"
                  condensed
                  css={[
                    css`
                      font-weight: 600;
                      font-size: 5.5vw;
                    `,
                  ]}
                >
                  {alternative.text}
                </TextNew>
              ))}
            </>
          )}
        </Flex>
      )}

      {pageState === 'showRoundLeaderboard' && (
        <Flex
          column
          css={css`
            padding: 0 20vw;
            opacity: 0;
            animation: ${fadeIn} 650ms forwards;
            gap: 2vh;
          `}
        >
          <TextNew
            color={textColor}
            shadow
            italic
            textAlign="center"
            condensed
            css={[
              css`
                font-weight: 600;
                font-size: 3vw;
              `,
            ]}
          >
            {t('Top players this round')}
          </TextNew>
          <LiveQuizLeaderboard results={roundResults ?? []} />
        </Flex>
      )}

      {pageState === 'showTotalLeaderboard' && (
        <Flex
          column
          css={css`
            padding: 0 20vw;
            opacity: 0;
            animation: ${fadeIn} 650ms forwards;
            gap: 2vh;
          `}
        >
          <TextNew
            color={textColor}
            shadow
            italic
            textAlign="center"
            condensed
            css={[
              css`
                font-weight: 600;
                font-size: 3vw;
              `,
            ]}
          >
            {t('Top players')}
          </TextNew>
          <LiveQuizLeaderboard results={totalResults ?? []} />
        </Flex>
      )}
    </Flex>
  )
}
