/** @jsxImportSource @emotion/react */

import { gql, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { FramedClubLogo } from '../../../components/Picture'
import { FcQuizQrCode } from '../../../components/QrCodeComponent'
import { TextNew } from '../../../components/TextNew'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { viewWidthToNumber } from '../../Admin/Utils'
import { UserAvatar } from '../../Avatar/UserAvatar'
import {
  getIconImageForClub,
  getTextColorForClub,
  itemFontSizeFromScreenSize,
  ScreenSize,
} from '../../Challenge/DisplayPage/util'
import { LiveQuizDisplayInstance } from '../../LiveQuiz/queries'

const displayInstanceParticipantsQuery = gql`
  query getLiveQuizDisplayInstance($id: ID!) {
    getLiveQuizDisplayInstance(id: $id) {
      id
      participants {
        id
        username
        displayName
        avatarData
      }
      participantCount
    }
  }
`

export const useDisplayInstanceParticipantQuery = (id: string) => {
  return useQuery<{
    getLiveQuizDisplayInstance: {
      id: string
      participants: {
        id: string
        username: string
        displayName: string
        avatarData: string
      }[]
      participantCount: number
    }
  }>(displayInstanceParticipantsQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
}

export const JoinableDisplayLiveQuiz = ({
  instance,
  screenSize,
  isLandscape,
}: {
  instance: LiveQuizDisplayInstance
  screenSize: ScreenSize
  isLandscape: boolean
}) => {
  const club = instance.club
  const joinUrl = `${window.location.protocol}//${window.location.host}/join/${instance.invitationCode}`

  const textColor = club ? getTextColorForClub(club) : colors.black

  // fetch participant data in its own query to avoid flickering
  const participantQuery = useDisplayInstanceParticipantQuery(instance.id)

  useEffect(() => {
    participantQuery.startPolling(2000)

    return () => participantQuery.stopPolling()
  }, [])

  const participantData = participantQuery.data?.getLiveQuizDisplayInstance

  const participants =
    participantData?.participants || instance.participants || []

  const participantCount = participantData?.participantCount || 0

  const mostRecent = participants.slice(-21)

  const iconUrl = club && getIconImageForClub(club)

  const avatarSize = (numParticipants: number) => {
    let vw
    switch (numParticipants) {
      case 1:
        vw = '25vw'
        break
      case 2:
        vw = '20vw'
        break
      case 3:
        vw = '18vw'
        break
      case 4:
        vw = '16vw'
        break
      case 5:
        vw = '14vw'
        break
      case 6:
        vw = '12vw'
        break
      case 7:
        vw = '10vw'
        break
      default:
        vw = '10vw'
        break
    }

    return viewWidthToNumber(vw)
  }

  return (
    <Flex
      column
      vertical="center"
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <TextNew
        italic
        condensed
        shadow
        color={textColor}
        css={css`
          position: absolute;
          top: 5vh;
          right: 1vw;
          font-size: 3vw;
          line-height: 0;
          font-weight: 600;
          margin-right: 1vw;
        `}
      >
        {t('{{count}} player', {
          count: participantCount,
        })}
      </TextNew>
      {iconUrl && (
        <FramedClubLogo
          url={iconUrl}
          size={viewWidthToNumber('10vw')}
          shape={club.logoShape}
          css={css`
            margin: 0 auto;
          `}
          frameColor={textColor}
          horizontalCenter
        />
      )}

      <Spacer
        css={css`
          height: 5vh;
        `}
      />

      <Flex
        column={!isLandscape}
        vertical={isLandscape ? 'center' : 'flex-start'}
        horizontal="center"
        css={css`
          width: 100%;
        `}
      >
        <Flex
          horizontal="center"
          column
          css={[
            css`
              flex: 1;
              height: 100%;
              aspect-ratio: 1;
              max-height: 40vh;
            `,
            !isLandscape &&
              css`
                flex: auto;
                aspect-ratio: 1;
              `,
          ]}
        >
          <TextNew
            condensed
            shadow
            narrowShadow
            color={textColor}
            css={css`
              font-size: 3vw;
              font-weight: 600;
            `}
          >
            {t(`Live kode:`)}
          </TextNew>
          <TextNew
            condensed
            shadow
            narrowShadow
            color={textColor}
            css={css`
              font-size: 3vw;
              font-weight: 600;
              letter-spacing: 0.1em;
            `}
          >
            {instance.invitationCode}
          </TextNew>
          <FcQuizQrCode
            url={joinUrl}
            backgroundColor={club?.backgroundColor ?? colors.white}
            foregroundColor={textColor}
            logoScale={3}
            hideFcQuizLogo
            hideBoxBackground
            css={css`
              padding: 16px;
            `}
          />
        </Flex>
        <Flex
          wrap
          horizontal="center"
          vertical="center"
          gap={'small'}
          css={[
            css`
              flex: 1;
              padding: 40px;
              overflow: hidden;
              height: 100%;
              max-height: 100%;
            `,

            !isLandscape &&
              css`
                flex: auto;
              `,
          ]}
        >
          {mostRecent.map((participant, index) => (
            <Flex column horizontal="center" key={`${participant.id}-${index}`}>
              <UserAvatar
                avatarData={participant.avatarData}
                height={avatarSize(mostRecent.length)}
              />
              <TextNew
                italic
                condensed
                color={textColor}
                css={css`
                  font-size: ${itemFontSizeFromScreenSize(screenSize)}px;
                  font-weight: 600;
                `}
              >
                {participant.displayName}
              </TextNew>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
