/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'

import { createContext, useContext, useEffect, useState } from 'react'
import { Avatar } from './Avatar'

import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Loader } from '../../components/Loader'
import { PageContent } from '../../components/layout/PageContent'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import {
  useRandomAvatarDataQuery,
  useUserProfileQuery,
} from '../Profile/queries'
import { AvatarSelectUI } from './AvatarSelectUI'
import Part from './Part'
import { useSetAvatarMutation } from './queries'
import { makeAvatarData, makeAvatarParts } from './util'

import { FaRandom } from 'react-icons/fa'
import { PleaseLogIn } from '../../components/PleaseLogIn'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'

const AvatarContext = createContext<{
  parts: { [key: string]: Part }
  setParts: any
}>({
  parts: {},
  setParts: () => {},
})

export const useAvatarContext = () => {
  return useContext(AvatarContext)
}

export const AvatarContextProvider = (props: {
  children: any
  value?: any
}) => {
  const { children, value } = props
  const [parts, setParts] = useState<{ [key: string]: Part } | undefined>(
    undefined
  )
  const baseValue = { parts, setParts }

  return (
    <AvatarContext.Provider value={value ?? baseValue}>
      {children}
    </AvatarContext.Provider>
  )
}

const Content = () => {
  const [selectedPart, setSelectedPart] = useState<string>('Skin')

  const navigate = useNavigate()

  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })
  const authenticated = Boolean(authUser)

  const { parts, setParts } = useAvatarContext()

  const userSlug = viewerQuery.data?.viewer?.slug

  const query = useUserProfileQuery(userSlug ?? '', userSlug === undefined)
  const user = query?.data?.user

  const [setAvatarMutation, { loading }] = useSetAvatarMutation()

  const randomAvatarQuery = useRandomAvatarDataQuery()

  const [randomLoading, setRandomLoading] = useState(false)

  const isLoading = viewerQuery.loading || !parts || query.loading

  const t = useT()
  const [isBase, setIsBase] = useState(false)

  useEffect(() => {
    if (parts || !user || !user.avatarData) return
    // Summary:
    // Get savedData, but not if dbData is updated
    const dbDataText = user.avatarData
    const dbData = dbDataText ? JSON.parse(dbDataText) : dbDataText

    // if isBase, the user has not set any avatar data yet
    setIsBase(dbData.IsBase)
    const prevDataText = window.localStorage.getItem('prevData')

    if (dbDataText !== prevDataText) {
      window.localStorage.setItem('prevData', dbDataText)
      window.localStorage.removeItem('avatarData')
    } else {
    }
    const savedDataText = window.localStorage.getItem('avatarData')
    const savedData = savedDataText ? JSON.parse(savedDataText) : undefined

    if (
      savedDataText &&
      savedDataText !== dbDataText &&
      window.confirm(t(`Continue where you left of?`))
    ) {
      setParts(makeAvatarParts(savedData))
    } else {
      setParts(makeAvatarParts(dbData))
    }
    window.localStorage.removeItem('avatarData')
  }, [user])

  useEffect(() => {
    const selectColors = document.querySelector('.SelectColors')
    if (!selectColors) return
    const partSVG = document.querySelector(`.Avatar .${selectedPart}`)
    if (partSVG) {
      selectColors.classList.remove('hide')
    } else {
      selectColors.classList.add('hide')
    }
  }, [parts, selectedPart])

  if (query.error) {
    return <></>
  }
  if (viewerQuery.loading) {
    return <Loader center />
  }

  if (!(authenticated && !authUser?.isAnonymous)) {
    return <PleaseLogIn />
  }

  if (isLoading) {
    return <Loader center />
  }

  return (
    <>
      <Flex
        column
        horizontal="center"
        gap="small"
        css={css`
          *::-webkit-scrollbar {
            display: none;
          }
          width: 100%;
          position: relative;
          .SelectColors.hide {
            display: none;
          }
        `}
      >
        <Flex
          css={css`
            width: 100%;
            position: absolute;
          `}
        >
          <Flex
            css={css`
              background-color: white;
              border-radius: 999px;
              margin-top: 20px;
              margin-left: 10px;
            `}
          >
            <SecondaryButton
              css={css`
                display: flex;
                align-self: flex-start;
                border: none;
                padding: 10px;
              `}
              variant="white"
              onClick={() => {
                const savedDataText = window.localStorage.getItem('avatarData')
                if (
                  savedDataText &&
                  !confirm(t('Are you sure you want to discard your changes?'))
                ) {
                  return
                }
                window.localStorage.removeItem('avatarData')
                navigate(`/profile/${userSlug}`)
              }}
            >
              <img
                css={css`
                  width: 20px;
                  height: 20px;
                  filter: invert(1);
                  z-index: 2;
                `}
                src="/Back.svg"
              />
            </SecondaryButton>
          </Flex>
          <Flex
            css={css`
              position: absolute;
              right: 10px;
            `}
            horizontal="center"
          >
            <PrimaryButton
              css={css`
                margin-top: 20px;
                height: 40px;
                width: 80px;
                border-radius: 999px;
                z-index: 2;
              `}
              variant="white"
              onClick={async () => {
                trackEvent(AnalyticsEventType.UserAvatar, {
                  create: isBase,
                  edit: !isBase,
                })

                const avatarData = makeAvatarData(parts)
                setAvatarMutation({
                  variables: {
                    input: avatarData,
                  },
                }).then(() => {
                  window.localStorage.removeItem('avatarData')
                  navigate(`/profile/${userSlug}`)
                })
              }}
            >
              {loading ? <Loader delay={0} size={15} center /> : t(`Save`)}
            </PrimaryButton>
          </Flex>
        </Flex>

        <Flex
          className="Avatar"
          column
          horizontal="center"
          css={css`
            position: relative;
            width: 100%;
            z-index: 1;
          `}
        >
          <Avatar
            userId={user?.id ?? ''}
            height={250}
            css={css`
              @media (min-width: 599px) {
                transform: scale(1.3);
                margin-top: 40px;
                margin-bottom: 40px;
              }
            `}
          />
          <Flex
            css={css`
              position: absolute;
              right: 10px;
              bottom: 0;
            `}
            horizontal="center"
          >
            <button
              css={[
                buttonReset,
                css`
                  height: 48px;
                  width: 48px;
                  background-color: ${colors.white};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 48px;
                  z-index: 2;
                `,
              ]}
              onClick={async () => {
                setRandomLoading(true)
                const randomData = await randomAvatarQuery.refetch()
                const randomParts = makeAvatarParts(
                  JSON.parse(randomData.data?.randomAvatarData ?? '')
                )
                setParts(randomParts)
                setRandomLoading(false)
                trackEvent(AnalyticsEventType.RandomAvatarGenerated)
              }}
            >
              {randomLoading ? (
                <Loader delay={0} size={15} center />
              ) : (
                <FaRandom size={24} />
              )}
            </button>
          </Flex>
        </Flex>
        <AvatarSelectUI
          selectedPart={selectedPart}
          setSelectedPart={setSelectedPart}
        />
      </Flex>
    </>
  )
}

export const AvatarPage = () => {
  const t = useT()
  return (
    <Layout
      title={`fcQuiz | ${t('Profile')}`}
      withHeader
      backgroundColor="black"
      css={css`
        @media (max-width: 599px) {
          header {
            display: none;
          }
          .navbar {
            display: none;
          }
        }
      `}
      hideAvatar
    >
      <PageContent
        css={css`
          @media (max-width: 599px) {
            padding: 0px !important;
          }
        `}
      >
        <AvatarContextProvider>
          <Content />
        </AvatarContextProvider>
      </PageContent>
    </Layout>
  )
}
