/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { useT } from '../../lib/i18n/useT'
import { gql, useMutation } from '@apollo/client'
import { useSetClubIsVerifiedMutation } from '../Admin/AdminClubsPage'
import { useNavigate } from 'react-router-dom'
import { PageContent } from '../../components/layout/PageContent'
import { Flex } from '../../components/layout/Flex'
import { css } from '@emotion/react'
import { colors, hexOpacity } from '../../styles/theme'
import { TextNew } from '../../components/TextNew'
import { Button, PrimaryButton } from '../../components/Button'
import { BiChevronDown, BiChevronRight } from 'react-icons/bi'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { TextInput } from '../../components/TextInput'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'

const useSetClubSlugMutation = () => {
  return useMutation(gql`
    mutation setClubSlug($input: SetClubSlugInput!) {
      setClubSlug(input: $input) {
        id
        slug
      }
    }
  `)
}

export const ClubAdminPanel = ({
  clubId,
  clubSlug,
  clubIsVerified,
  className,
}: {
  clubId: string
  clubSlug: string
  clubIsVerified: boolean
  className?: string
}) => {
  const t = useT()
  const [expanded, setExpanded] = useState(false)
  const [setClubSlugMutation] = useSetClubSlugMutation()
  const [setClubIsVerifiedMutation] = useSetClubIsVerifiedMutation()

  const [slugError, setSlugError] = useState<string | null>(null)

  const navigate = useNavigate()

  const [clubSlugTemp, setClubSlugTemp] = useState(
    clubSlug === clubId ? '' : clubSlug
  )

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (slugError) {
        setSlugError(null)
      }
    }, 3000)

    return () => clearTimeout(timeOut)
  }, [slugError])

  return (
    <PageContent className={className}>
      <Flex
        gap="medium"
        column
        css={[
          css`
            background-color: ${hexOpacity(colors.black, 0.5)};
            backdrop-filter: blur(5px);
            border: 1px solid ${colors.white};
            border-radius: 8px;
            padding: 20px;
            overflow: hidden;
            max-height: ${expanded ? 'auto' : '56px'};
          `,
        ]}
      >
        <Flex
          vertical="center"
          horizontal="space-between"
          css={css`
            height: 16px;
          `}
        >
          <TextNew color={colors.white}>{t('Admin Settings')}</TextNew>
          <Button
            onClick={() => setExpanded(!expanded)}
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-right: -18px;
              background-color: transparent;
              border: none;
            `}
          >
            {expanded ? (
              <BiChevronDown color="white" size={32} />
            ) : (
              <BiChevronRight color="white" size={32} />
            )}
          </Button>
        </Flex>
        <ToggleSwitch
          value={clubIsVerified}
          onValue={(isVerified) => {
            if (!expanded) {
              return
            }
            setClubIsVerifiedMutation({
              variables: {
                input: { clubIdOrSlug: clubId, isVerified },
              },
            })
          }}
          label={'Verified'}
          textColor={colors.white}
        />

        {clubIsVerified && (
          <Flex horizontal="space-between" vertical="flex-end" gap={'medium'}>
            <TextInput
              value={clubSlugTemp}
              onValue={(slug) => {
                setClubSlugTemp(slug)
              }}
              label={'Set slug'}
              placeholder={'Slug'}
              textColor={colors.white}
              css={css`
                flex-grow: 1;
              `}
            />
            <PrimaryButton
              variant="white"
              disabled={clubSlugTemp.trim() === '' || clubSlugTemp === clubSlug}
              onClick={async () => {
                if (clubSlugTemp.trim() === '') {
                  return
                }

                try {
                  const res = await setClubSlugMutation({
                    variables: {
                      input: { clubId, slug: clubSlugTemp },
                    },
                  })

                  const newSlug = res.data?.setClubSlug?.slug

                  console.log('newSlug', newSlug)

                  if (newSlug) {
                    setSlugError(null)
                    navigate(`/clubs/${newSlug}`, { replace: true })
                  }
                } catch (error) {
                  if (hasErrorCode(error, ErrorCode.SLUG_ALREADY_EXISTS)) {
                    setSlugError('Slug already exists')
                  } else {
                    setSlugError('Unknown error')
                  }
                }
              }}
            >
              Submit
            </PrimaryButton>
          </Flex>
        )}
        {slugError && <TextNew color={colors.red300}>{slugError}</TextNew>}
      </Flex>
    </PageContent>
  )
}
