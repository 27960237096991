import { ChallengeGroup } from '../Challenge/queries'
import { User } from '../Profile/queries'
import { gql, useQuery } from '@apollo/client'

export type Invitation = {
  id: string
  fromUser: User
  challengeGroup: ChallengeGroup
}

export const useInvitationQuery = (invitationId: string) => {
  const query = useQuery<{ invitation: Invitation }>(
    gql`
      query invitation($invitationId: ID!) {
        invitation(invitationId: $invitationId) {
          id
          fromUser {
            id
            username
            slug
            avatarData
            displayName
          }
          challengeGroup {
            id
            title
            description
            quizzes {
              id
              title
              viewerCanStartQuiz
              status
              slug
              questionsCount
              participantCount
              league {
                id
                slug
                title
                frequency
              }
              challengeGroupResults {
                userId
                leagueQuizInstanceId
                correctAnswersCount
                correctAnswerScore
                timeBonus
                totalScore
              }
              challengeGroupId
            }
            members {
              id
              username
              slug
              avatarData
              displayName
            }
            viewerIsOwner
            currentQuiz {
              id
              title
              viewerCanStartQuiz
              status
              questionsCount
              participantCount
              challengeGroupId
              league {
                id
                slug
                title
                frequency
              }
            }
          }
        }
      }
    `,
    { variables: { invitationId } }
  )

  return query
}
