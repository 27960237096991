/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { RefObject, useEffect, useRef, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { Avatar } from './Avatar'
import { useAvatarContext } from './AvatarPage'
import Part from './Part'
import {
  baseStyleIconFace,
  baseStyleIconShirt,
  FaceParts,
  getSelectableStyles,
  getStyle,
  makeAvatarData,
  ShirtParts,
} from './util'
import { useUserProfileQuery } from '../Profile/queries'
import { useAuthContext } from '../../lib/AuthContext'

// Used to ensure that the last SelectableButton is cut
// So that the user knows they can scroll
const bigger = '{ min-width: 80px; max-width: 80px; padding: 15px;}'
const smaller = '{ min-width: 70px; max-width: 70px; padding: 10px;}'

export const SelectableButton = (props: {
  isSelected: boolean
  onClick: any
  children: any
  premium?: boolean
}) => (
  <PrimaryButton
    css={[
      css`
        border-radius: 4px;
        min-height: 62px;
        max-height: 62px;
        background-color: ${props.isSelected ? 'white' : colors._grey470};
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${bigger};
        @media (max-width: 500px) ${smaller};
        @media (max-width: 450px) ${bigger};
        @media (max-width: 400px) ${smaller};
        @media (max-width: 360px) ${bigger};
        @media (max-width: 310px) ${smaller};
      `,
      props.premium &&
        css`
          border: 1px solid #ffd700;
        `,
    ]}
    onClick={props.onClick}
  >
    {props.children}
  </PrimaryButton>
)

export const PartButton = (props: {
  part: string
  section: string
  selectedPart: string
  setSelectedPart: any
}) => {
  const isSelected = props.selectedPart == props.part
  const { parts } = useAvatarContext()
  return (
    <SelectableButton
      isSelected={isSelected}
      onClick={() => {
        if (props.selectedPart == props.part) return

        const selectColors = document.querySelector('.SelectColors')
        selectColors && selectColors.classList.add('hide') // Hide it immediately, then show it on useEffect

        props.setSelectedPart(props.part)
      }}
    >
      {props.section == 'face' ? (
        <Avatar
          height={50}
          iconPart={props.part}
          greyScale
          partsOverride={baseStyleIconFace(parts, props.part == 'Hair')}
          FGColor={'#191919'}
          BGColor={isSelected ? 'white' : colors._grey470}
        />
      ) : (
        <Flex
          //This flex is necessary to prevent overflow-y
          css={css`
            * {
              max-height: 50px;
              max-width: 50px;
            }
          `}
        >
          {baseStyleIconShirt(parts[props.part])}
        </Flex>
      )}
    </SelectableButton>
  )
}

const SelectColors = (props: {
  index: number
  multipleColors: boolean
  part: any
  selectedPart: string
  update: any
}) => {
  const t = useT()
  const { part, update } = props
  return (
    <Flex
      column
      className="SelectColors"
      gap="tiny"
      css={css`
        width: 100%;
      `}
      horizontal="center"
    >
      <SelectableButtons
        title={
          !props.multipleColors
            ? t(`Choose color`)
            : props.index == 0
            ? t(`Choose primary color`)
            : t(`Choose secondary color`)
        }
        selectedPart={props.selectedPart}
      >
        {part.selectableColors!.map((color: string, index: number) => (
          <SelectableButton
            key={index}
            isSelected={part.colors[props.index] == color}
            onClick={() => {
              const newColors: string[] = part.colors
              newColors.splice(props.index, 1, color)
              update('colors', newColors) // Just to refresh
            }}
          >
            <Flex
              css={css`
                width: 50px;
                height: 40px;
                background-color: ${color};
                border: 1.5px solid ${colors.black};
                border-radius: 5px;
              `}
            />
          </SelectableButton>
        ))}
      </SelectableButtons>
    </Flex>
  )
}

const StylePicker = (props: { selectedPart: string }) => {
  const { selectedPart } = props

  const { authUser } = useAuthContext()
  const userSlug = authUser?.uid
  const profileQuery = useUserProfileQuery(userSlug ?? '', userSlug === null)
  const user = profileQuery.data?.user

  if (selectedPart === '') return <></>

  const { parts, setParts } = useAvatarContext()

  const update = (field: string, value: any) => {
    const _parts: any = parts
    _parts[selectedPart][field] = value
    // Why is this done differently from setPickedColors?
    // Since I want this function to refresh the component
    // While I dont want setPickedColors to do so
    const part = _parts[selectedPart]
    const newParts = {
      ..._parts,
      [selectedPart]: new part.constructor({
        ...part,
        [field]: value,
      }),
    }
    setParts(newParts)
    window.localStorage.setItem('avatarData', makeAvatarData(newParts))
  }

  const part: Part = parts[selectedPart]

  const selectableStylesPremium = getSelectableStyles(part, true)
  const selectableStylesFree = getSelectableStyles(part, false)

  const selectableStyles = () => [
    ...selectableStylesPremium.filter((it) =>
      user?.unlockedStyles.includes(it.id)
    ),
    ...selectableStylesFree,
  ]

  const selectedStyle = selectableStylesPremium.find(
    (it) => it.id == part.style
  )

  if (!selectedStyle) {
    throw new Error('Selected style was not found')
  }

  const t = useT()

  return (
    <>
      <Flex
        column
        gap="small"
        css={css`
          width: 100vw;
          max-width: 100%;
        `} //80 = 100(save and finish) + 20
      >
        {selectableStylesPremium.length > 1 && (
          <SelectableButtons
            title={t('Choose style')}
            selectedPart={props.selectedPart}
          >
            {selectableStyles().map(({ id, icon, premium }) => (
              <SelectableButton
                key={id}
                isSelected={part.style == id}
                onClick={() => {
                  update('style', id)
                }}
                premium={premium}
              >
                {FaceParts.includes(part.name) && (
                  <Avatar
                    hideOthers
                    iconPart={selectedPart}
                    height={50}
                    //width={200}
                    FGColor={'#191919'}
                    BGColor={part.style == id ? 'white' : colors._grey470}
                    partsOverride={{
                      ...baseStyleIconFace(parts),
                      [selectedPart]: { ...parts[selectedPart], style: id },
                    }}
                    border={part.borders}
                    bgasfg={part.bgasfg}
                  />
                )}
                {ShirtParts.includes(part.name) && (
                  <Flex
                    //This flex is necessary to prevent overflow-y
                    css={css`
                      * {
                        max-height: 50px;
                        max-width: 50px;
                      }
                    `}
                  >
                    {icon}
                  </Flex>
                )}
              </SelectableButton>
            ))}
          </SelectableButtons>
        )}
        {selectedStyle?.usedColors > 0 && (
          <SelectColors
            index={0}
            multipleColors={selectedStyle!.usedColors! > 1}
            part={part}
            selectedPart={selectedPart}
            update={update}
          />
        )}
        {selectedStyle?.usedColors > 1 && (
          <SelectColors
            index={1}
            multipleColors={true}
            part={part}
            selectedPart={selectedPart}
            update={update}
          />
        )}
      </Flex>
    </>
  )
}

// Lets you scroll by dragging cards. Essential for mouse users and windows touchpad
export const useHorizontalScroll = (): RefObject<HTMLDivElement> => {
  const elRef = useRef<HTMLDivElement>(null)
  let isDown = false
  let startX: number
  let startScrollLeft: number
  let scrollLeft: number

  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const handleClick = (e: any) => {
      if (isDragging) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (isDragging) {
      // Add global click event listener
      document.addEventListener('click', handleClick, true)
    }

    return () => {
      // Remove the event listener when the component unmounts or dragging stops
      document.removeEventListener('click', handleClick, true)
    }
  }, [isDragging])

  useEffect(() => {
    const el = elRef.current
    if (!el) {
      return
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(false)
      isDown = true
      el.style.cursor = 'grabbing'
      startX = e.pageX - el.offsetLeft
      scrollLeft = el.scrollLeft
      startScrollLeft = el.scrollLeft
    }

    const handleMouseLeave = () => {
      setIsDragging(false)
      isDown = false
      el.style.cursor = 'grab'
    }

    const handleMouseUp = () => {
      setIsDragging(Math.abs(startScrollLeft - el.scrollLeft) > 20)
      setTimeout(() => {
        setIsDragging(false)
      }, 10)
      isDown = false
      el.style.cursor = 'grab'
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return
      setIsDragging(false)
      e.preventDefault()
      const x = e.pageX - el.offsetLeft
      const walk = (x - startX) * 1 // scroll-fast
      el.scrollLeft = scrollLeft - walk
    }

    el.addEventListener('mousedown', handleMouseDown)
    el.addEventListener('mouseleave', handleMouseLeave)
    el.addEventListener('mouseup', handleMouseUp)
    el.addEventListener('mousemove', handleMouseMove)

    return () => {
      el.removeEventListener('mousedown', handleMouseDown)
      el.removeEventListener('mouseleave', handleMouseLeave)
      el.removeEventListener('mouseup', handleMouseUp)
      el.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return elRef
}

export const SelectableButtons = (props: {
  title: string
  titleColor?: string
  selectedPart?: string
  children: any
}) => {
  const scrollRef = useHorizontalScroll()
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
    }
  }, [props.selectedPart])
  return (
    <Flex
      css={css`
        width: 100vw;
        max-width: 100%;
      `}
      column
      gap="tiny"
    >
      <Heading
        level={1}
        looksLikeLevel={5}
        color={props.titleColor ?? colors.white}
        css={css`
          padding: 0px 30px;
          text-transform: uppercase;
        `}
        wide
        italic
      >
        {props.title}
      </Heading>
      <Flex
        ref={scrollRef}
        css={css`
          overflow-x: auto;
          overflow-y: hidden;
          max-width: 100%;
          padding-top: 0px;
          padding-left: 30px;
          padding-right: 30px;
          gap: 12px;
          position: relative;
        `}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}

export const AvatarSelectUI = (props: {
  selectedPart: string
  setSelectedPart: any
}) => {
  const t = useT()
  const { selectedPart, setSelectedPart } = props

  const PartButtonProps = {
    selectedPart,
    setSelectedPart,
  }

  const { parts } = useAvatarContext()

  const chest = parts['Chest']
  //Disable other shirt parts if chest is premium
  const hideShirtButtons = getStyle(chest).premium

  return (
    <Flex
      column
      css={css`
        max-width: 100%;
        overflow-y: auto;
      `}
      horizontal="center"
      gap="small"
    >
      <SelectableButtons title={t(`Choose category`)}>
        <PartButton part="Skin" section="face" {...PartButtonProps} />
        <PartButton part="Hair" section="face" {...PartButtonProps} />
        <PartButton part="Brows" section="face" {...PartButtonProps} />
        <PartButton part="Eyes" section="face" {...PartButtonProps} />
        <PartButton part="Mouth" section="face" {...PartButtonProps} />
        <PartButton part="Chest" section="shirt" {...PartButtonProps} />
        {!hideShirtButtons && (
          <>
            <PartButton part="Arms" section="shirt" {...PartButtonProps} />
            <PartButton part="Shoulders" section="shirt" {...PartButtonProps} />
            <PartButton part="Collar" section="shirt" {...PartButtonProps} />
            <PartButton part="Sleeves" section="shirt" {...PartButtonProps} />
          </>
        )}
      </SelectableButtons>
      <StylePicker selectedPart={selectedPart} />
    </Flex>
  )
}
