/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import React, { FC, Fragment } from 'react'
import { Flex } from '../../components/layout/Flex'
import {
  LARGE_SCREEN_PADDING,
  SMALL_SCREEN_PADDING,
} from '../../components/layout/PageContent'
import { Spacer } from '../../styles/margin'
import { useHorizontalScroll } from '../Avatar/AvatarSelectUI'

interface Props {
  center?: boolean
  children?: React.ReactNode
}

export const LeaguesContainer: FC<Props> = (props) => {
  const children = React.Children.toArray(props.children)
  const scrollRef = useHorizontalScroll()

  if (children.length === 0) {
    return null
  }

  return (
    <Flex
      ref={scrollRef}
      css={css`
        overflow-x: auto;
        overflow-y: visible;
        padding-left: max(calc(50vw - 360px), ${LARGE_SCREEN_PADDING}px);
        padding-right: max(calc(50vw - 360px), ${LARGE_SCREEN_PADDING}px);
        padding-top: 12px; /* space for red circle */
        padding-bottom: 8px; /* space for box-shadow */
        justify-content: ${props.center ? 'center' : 'flex-start'};

        @media (max-width: 599px) {
          padding-left: ${SMALL_SCREEN_PADDING}px;
          padding-right: ${SMALL_SCREEN_PADDING}px;
          justify-content: ${children.length === 1 && props.center
            ? 'center'
            : 'flex-start'};
        }

        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      `}
    >
      {children.map((child, index, array) => (
        <Fragment key={index}>
          {child}
          {index < array.length - 1 && (
            <Spacer
              css={css`
                min-width: 8px;
                max-width: 8px;
              `}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  )
}
