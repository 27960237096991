/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FaShareSquare } from 'react-icons/fa'
import { MdCheck, MdSchedule } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import {
  PrimaryButton,
  SecondaryButton,
  VariableButton,
} from '../../components/Button'
import { Heading } from '../../components/Heading'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { ShareQuizModal } from '../../components/leagueQuiz/ShareQuizModal'
import { ModalTrigger } from '../../components/modal/Modal'
import { isProduction } from '../../config/config'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { Locale, locales, localeToFlag } from '../../lib/i18n/locale'
import { useT } from '../../lib/i18n/useT'
import { LeagueQuizStatus } from '../../lib/types'
import { margin } from '../../styles/margin'
import { lineClamp } from '../../styles/styles'
import { boxShadows, colors, hexOpacity } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { useGetQuizQuery } from '../Translate/queries'
import { leagueQuizItemStyle, ViewerLeagueRole } from './LeaguePage'
import { LeagueQuizMinimal } from './queries'

export const ShareQuizButton = ({
  slug,
  quizId,
  leagueId,
  userFinishedInstanceId,
  quizType = 'text',
}: {
  slug: string
  quizId: string
  leagueId: string
  userFinishedInstanceId?: string
  quizType?: string
}) => {
  return (
    <ModalTrigger
      button={({ openModal }) => (
        <SecondaryButton
          variant="white"
          onClick={openModal}
          css={css`
            border-radius: 8px;
            border: 1px ${hexOpacity(colors.black, 0.2)} solid;
            height: 32px;
            width: 32px;
            min-width: 32px;
            min-height: 32px;
            padding: 0px;
            padding-top: 4px;
            &:hover {
              border: 1px ${colors.black} solid;
            }
          `}
        >
          <FaShareSquare color={colors.black} />
        </SecondaryButton>
      )}
      modal={({ closeModal }) => (
        <ShareQuizModal
          slug={slug}
          quizId={quizId}
          leagueId={leagueId}
          closeModal={closeModal}
          userFinishedInstanceId={userFinishedInstanceId}
          quizType={quizType}
        />
      )}
    />
  )
}

const SanityButton = ({
  quizId,
  viewerLeagueRole,
}: {
  quizId: string
  viewerLeagueRole: ViewerLeagueRole
}) => {
  if (viewerLeagueRole !== 'fcQuizAdmin') return null

  const url = isProduction
    ? `https://fcquiz.sanity.studio/desk/leagueQuiz;${quizId}`
    : `http://localhost:3333/desk/leagueQuiz;${quizId}`
  // open in new tab
  return (
    <Link to={url} target={'_blank'}>
      <Text size="medium" condensed>
        View in Sanity
      </Text>
    </Link>
  )
}

export const TranslateButton = ({
  quiz,
  primary,
  leagueId,
}: {
  quiz: LeagueQuizMinimal
  primary?: boolean
  leagueId: string | undefined
}) => {
  const navigate = useNavigate()
  const quiz_with_questions = useGetQuizQuery({
    leagueSlug: leagueId ?? '',
    quizid: quiz.id,
  })
  const t = useT()

  const completeLocales = locales.filter((locale: Locale) => {
    if (!quiz_with_questions.data) return false
    const questions =
      'getQuiz' in quiz_with_questions.data
        ? quiz_with_questions.data?.getQuiz?.questions
        : undefined
    if (!questions || questions.length === 0) return false

    // If every question is translated
    return questions?.every((question) => {
      //Duplicate in translatePage
      const isFilled =
        question.question[locale] &&
        question.alternatives.map((it) => it[locale]).every(Boolean)

      function isNumeric(text: string) {
        return /^-?\d+$/.test(text)
      }

      const isNumbers = question.alternatives
        .map((it) => Object.values(it))
        .every((it) => it.every(isNumeric))
      return isFilled || isNumbers
    })
  })
  return (
    <Flex column>
      <Flex>
        {completeLocales.map((locale: Locale) => (
          <Text key={locale}>{localeToFlag[locale]}</Text>
        ))}
      </Flex>
      <Flex
        horizontal="flex-end"
        gap="small"
        css={css`
          width: 100%;
        `}
      >
        {completeLocales.length !== 0 && (
          <VariableButton
            css={css`
              flex: 1;
            `}
            buttonType={primary ? 'primary' : 'secondary'}
            onClick={() => {
              navigate(`/translate/${leagueId}/${quiz.id}`)
            }}
          >
            Translate
          </VariableButton>
        )}

        {completeLocales.length === 0 && quiz.quizType === 'text' && (
          <Text>{t(`Not ready to translate`)}</Text>
        )}

        {leagueId && (
          <VariableButton
            buttonType={primary ? 'primary' : 'secondary'}
            onClick={() => {
              navigate(`/edit/${leagueId}/${quiz.id}?quizType=${quiz.quizType}`)
            }}
          >
            Edit
          </VariableButton>
        )}
      </Flex>
    </Flex>
  )
}

const ActiveQuizItem = ({
  quiz,
  leagueId,
  editModeEnabled,
  viewerLeagueRole,
}: {
  quiz: LeagueQuizMinimal
  leagueId?: string
  editModeEnabled?: boolean
  viewerLeagueRole: ViewerLeagueRole
}) => {
  const { authUser, signInAsAnonymous } = useAuthContext()
  const t = useT()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const [startLeagueQuizMutation, { loading }] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const availableToCurrentYear = quiz.availableTo
    ? new Date(quiz.availableTo).getFullYear() === new Date().getFullYear()
    : false

  const StartOrContinueQuiz = () => {
    return quiz.viewerCanStartQuiz ? (
      <PrimaryButton
        disabled={loading}
        onClick={async () => {
          if (!authUser) {
            await signInAsAnonymous()
          }

          // If quiz is a career path quiz, start career path quiz
          if (quiz.quizType === 'careerPath') {
            startCareerPathQuizMutation({
              variables: { input: { leagueQuizId: quiz.id } },
            }).then((result) => {
              const quizInstanceId = result.data?.startCareerPathQuiz.id
              if (quizInstanceId) {
                trackEvent(AnalyticsEventType.StartQuiz, {
                  quizId: quiz.id,
                  leagueId,
                  isCareerPath: true,
                  isEmbed: false,
                })
                navigate(
                  `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`
                )
              }
            })
            return
          }

          startLeagueQuizMutation({
            variables: { input: { leagueQuizId: quiz.id } },
          }).then((result) => {
            const quizInstanceId = result.data?.startLeagueQuiz.id
            if (quizInstanceId) {
              trackEvent(AnalyticsEventType.StartQuiz, {
                quizId: quiz.id,
                leagueId,
                isEmbed: false,
              })
              navigate(`/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}`)
            }
          })
        }}
      >
        {t('Start')}
      </PrimaryButton>
    ) : quiz.viewerData.activeQuizInstance?.id ? (
      <PrimaryButton
        onClick={() =>
          // Continue quiz, so dont track start quiz
          navigate(
            `/play-quiz/${quiz.viewerData.activeQuizInstance?.id}?quizType=${quiz.quizType}`
          )
        }
      >
        {t('Continue quiz')}
      </PrimaryButton>
    ) : null
  }

  return (
    <Flex
      column
      grow
      css={css`
        justify-content: space-between;
      `}
    >
      <Flex vertical="flex-start">
        <Flex
          column
          horizontal="space-between"
          vertical="flex-start"
          css={css`
            width: 100%;
          `}
        >
          <Heading level={5} css={[lineClamp(3), { lineHeight: 1.2 }]}>
            {quiz.title}
          </Heading>
          <Flex
            column
            grow
            css={css`
              justify-content: space-between;
            `}
          >
            <Text
              size="small"
              css={[
                css`
                  padding: 4px 0;
                `,
              ]}
            >
              {t('{{count}} question', { count: quiz.questionsCount })}
            </Text>
          </Flex>
        </Flex>
        {leagueId && (
          <ShareQuizButton
            slug={quiz.slug}
            quizId={quiz.id}
            leagueId={leagueId}
            userFinishedInstanceId={
              quiz.viewerData.result?.leagueQuizInstanceId
            }
            quizType={quiz.quizType}
          />
        )}
      </Flex>
      <Text
        size="small"
        css={[
          css`
            padding: 4px 0;
          `,
        ]}
      >
        {quiz.availableTo &&
          t('Deadline {{date}}', {
            date: formatDate(
              new Date(quiz.availableTo),
              availableToCurrentYear ? 'short-datetime' : 'full-date'
            ),
          })}
        <br />
        {quiz.participantCount === 1
          ? t('{{count}} player', {
              count: quiz.participantCount,
            })
          : t('{{count}} player_other', {
              count: quiz.participantCount,
            })}
      </Text>
      <Flex column css={margin.top()}>
        {editModeEnabled ? (
          <>
            <SanityButton
              quizId={quiz.id}
              viewerLeagueRole={viewerLeagueRole}
            />

            <TranslateButton quiz={quiz} leagueId={leagueId} />
          </>
        ) : (
          <StartOrContinueQuiz />
        )}
      </Flex>
    </Flex>
  )
}

export const LeagueQuizItem = ({
  quiz,
  leagueId,
  editModeEnabled = false,
  viewerLeagueRole = 'viewer',
}: {
  quiz: LeagueQuizMinimal
  leagueId?: string
  editModeEnabled?: boolean
  viewerLeagueRole?: ViewerLeagueRole
}) => {
  const t = useT()
  const formatDate = useFormatDate()
  const navigate = useNavigate()

  const viewerResult = quiz.viewerData.result

  const availableToCurrentYear = quiz.availableTo
    ? new Date(quiz.availableTo).getFullYear() === new Date().getFullYear()
    : false

  return (
    <Flex
      column
      css={[
        leagueQuizItemStyle,
        (quiz.viewerCanStartQuiz ||
          Boolean(quiz.viewerData.activeQuizInstance?.id)) &&
          css`
            background-color: ${colors.yellow100};
            box-shadow: ${boxShadows.default};
          `,
      ]}
    >
      {quiz.viewerCanStartQuiz || quiz.viewerData.activeQuizInstance?.id ? (
        <ActiveQuizItem
          quiz={quiz}
          leagueId={leagueId}
          editModeEnabled={editModeEnabled}
          viewerLeagueRole={viewerLeagueRole}
        />
      ) : (
        <Flex column vertical="space-between" grow>
          <Flex horizontal="space-between">
            <Heading level={5} css={[lineClamp(3), { lineHeight: 1.2 }]}>
              {quiz.title}
            </Heading>

            {leagueId && quiz.status !== LeagueQuizStatus.completed && (
              // show embed button only for incomplete quizzes
              <ShareQuizButton
                slug={quiz.slug}
                quizId={quiz.id}
                leagueId={leagueId}
                userFinishedInstanceId={
                  quiz.viewerData.result?.leagueQuizInstanceId
                }
                quizType={quiz.quizType}
              />
            )}
          </Flex>
          <Flex
            column
            css={css`
              justify-content: space-between;
            `}
          >
            <Text
              size="small"
              css={[
                css`
                  padding: 4px 0;
                `,
              ]}
            >
              {t('{{count}} player', { count: quiz.participantCount })}
            </Text>
          </Flex>
          <Flex
            column
            grow
            css={css`
              justify-content: center;
            `}
          >
            <Text
              size="small"
              css={[
                css`
                  padding: 4px 0;
                `,
              ]}
            >
              {quiz.status === LeagueQuizStatus.active ? (
                <>
                  {quiz.availableTo
                    ? t('Deadline {{date}}', {
                        date: formatDate(
                          new Date(quiz.availableTo),
                          availableToCurrentYear
                            ? 'short-datetime'
                            : 'full-date'
                        ),
                      })
                    : t('No deadline')}
                </>
              ) : quiz.status === LeagueQuizStatus.completed ? (
                <>
                  {quiz.availableTo
                    ? t('Finished {{date}}', {
                        date: formatDate(
                          new Date(quiz.availableTo),
                          availableToCurrentYear
                            ? 'short-datetime'
                            : 'full-date'
                        ),
                      })
                    : t('No deadline')}
                </>
              ) : quiz.status === LeagueQuizStatus.upcoming ? (
                <>
                  Starts{' '}
                  {formatDate(new Date(quiz.availableFrom), 'full-datetime')}
                </>
              ) : null}
            </Text>
            {quiz.status === LeagueQuizStatus.upcoming ? null : quiz.viewerData
                .hasCompletedQuiz && viewerResult ? (
              <>
                <Flex
                  vertical="center"
                  css={css`
                    border-top: 1px solid black;
                    padding: 4px 0;
                  `}
                  gap="medium"
                >
                  <Flex vertical="center" gap="small">
                    <MdCheck size={16} />
                    <Text size="small">
                      {t('{{correctAnswersCount}} of {{questionsCount}}', {
                        correctAnswersCount: viewerResult.correctAnswersCount,
                        questionsCount: quiz.questionsCount,
                      })}
                    </Text>
                  </Flex>

                  {quiz.quizType == 'text' && (
                    <Flex vertical="center" gap="small">
                      <MdSchedule size={16} />
                      <Text size="small">{viewerResult.timeBonus}s</Text>
                    </Flex>
                  )}
                </Flex>
              </>
            ) : (
              <Text
                size="small"
                css={css`
                  border-top: 1px solid black;
                  padding: 4px 0;
                `}
              >
                {t('Not completed')}
              </Text>
            )}
          </Flex>

          {quiz.status !== LeagueQuizStatus.upcoming &&
            (editModeEnabled ? (
              <>
                <SanityButton
                  quizId={quiz.id}
                  viewerLeagueRole={viewerLeagueRole}
                />
                <TranslateButton quiz={quiz} leagueId={leagueId} />
              </>
            ) : (
              <Flex horizontal="space-between" vertical="center">
                <Text>+{quiz.viewerData.result?.totalScore ?? 0}p</Text>
                {quiz.viewerData.result?.leagueQuizInstanceId && (
                  <SecondaryButton
                    onClick={() => {
                      navigate(
                        `/play-quiz/${quiz.viewerData.result?.leagueQuizInstanceId}?quizType=${quiz.quizType}`
                      )
                    }}
                  >
                    {t('Result')}
                  </SecondaryButton>
                )}
              </Flex>
            ))}
          {quiz.status === LeagueQuizStatus.upcoming &&
            (editModeEnabled ? (
              <>
                <SanityButton
                  quizId={quiz.id}
                  viewerLeagueRole={viewerLeagueRole}
                />
                <TranslateButton quiz={quiz} primary leagueId={leagueId} />
              </>
            ) : (
              <Text size="tiny" color={colors.grey400}>
                You can see this item since you are an owner of this league
              </Text>
            ))}
        </Flex>
      )}
    </Flex>
  )
}
