/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { FieldInput } from './FieldInput'

export const QuestionForm = (props: {
  question: string
  setQuestion: (value: string) => void
  alternatives: string[]
  setAlternatives: (value: string[]) => void
  hideCorrect?: boolean
  questionLabel?: string
  alternativesLabel?: string[]
  showDiscard?: boolean
  languageOverride?: string
}) => {
  const setAlternative = (index: number) => (text: string) => {
    const oldAlternatives = [...props.alternatives]
    oldAlternatives[index] = text
    props.setAlternatives(oldAlternatives)
  }

  return (
    <Flex
      column
      gap="small"
      css={css`
        height: 100%;
      `}
    >
      <FieldInput
        field={props.question}
        setField={props.setQuestion}
        type="Question"
        isCorrect
        label={props.questionLabel}
        css={css`
          margin-bottom: 24px;
        `}
        autoFocus
        languageOverride={props.languageOverride}
      />
      {[0, 1, 2, 3].slice(0, props.alternatives.length).map((it) => (
        <FieldInput
          key={it}
          field={props.alternatives[it]}
          setField={setAlternative(it)}
          type="Alternative"
          isCorrect={!it}
          index={it}
          hideCorrect={props.hideCorrect}
          label={props.alternativesLabel && props.alternativesLabel[it]}
          discardField={props.alternativesLabel && props.alternativesLabel[it]}
          languageOverride={props.languageOverride}
        />
      ))}
    </Flex>
  )
}
