/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC } from 'react'
import { useWindowSize } from '../utils/useWindowSize'

type Props = {
  imageSrc?: string
}

export const QuestionImage: FC<Props> = ({ imageSrc }) => {
  const { width, height } = useWindowSize()
  if (!imageSrc) return null
  const imageWidth = width > 800 ? 720 : Math.floor(width * 0.9)
  const imageHeight = width > 800 ? 335 : Math.floor(height * 0.3)

  return (
    <div
      css={css`
        margin-bottom: 10px;
      `}
    >
      <img
        alt="Image for question"
        width={imageWidth}
        height={imageHeight}
        //quality={100} // TODO fix
        src={imageSrc}
        //objectFit="contain"
        //priority
      />
    </div>
  )
}
