/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { colors, hexOpacity } from '../../styles/theme'

export const ShimmerComponent = ({
  className,
  height = 250,
  backgroundColor = colors.grey200,
}: {
  className?: string
  height?: number
  backgroundColor?: string
}) => {
  return (
    <Flex
      className={className}
      vertical="center"
      css={css`
        height: ${height}px;
        position: relative;
        padding: 20px 15px;

        background-color: ${backgroundColor};
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          background: linear-gradient(
            135deg,
            transparent 15%,
            ${hexOpacity(colors.white, 0.5)} 50%,
            transparent 85%
          );

          background-size: 200% 400%;
          animation: ${shimmer} 1.2s infinite ease-in-out;
        }
      `}
    />
  )
}

export const shimmer = keyframes`
  0% {
      background-position: 100% 100%;   
  }
  50% {
      background-position: 0 0;
  }
  100% {
      background-position: 100% 100%;
  }
`
