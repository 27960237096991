/** @jsxImportSource @emotion/react */
import { useApolloClient } from '@apollo/client'
import { css } from '@emotion/react'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { Badge } from '../../views/League/queries'
import { userProfileQuery } from '../../views/Profile/queries'
import { Modal, ModalTrigger } from '../modal/Modal'
import { BadgeModalContent } from './BadgeModalContent'
import { checkClubMembershipBadges } from './queries'

export const CheckClubMembershipBadges = ({
  earnedBadges,
}: {
  earnedBadges?: Badge[]
}) => {
  const t = useT()

  const apolloCient = useApolloClient()

  if (!earnedBadges || earnedBadges.length === 0) {
    return null
  }

  if (earnedBadges.length > 0) {
    return (
      <ModalTrigger
        forceIsOpen
        button={() => (
          <div
            css={css`
              position: absolute;
              width: 0;
              height: 0;
            `}
          />
        )}
        modal={({ closeModal }) => (
          <Modal
            heading={
              earnedBadges.length > 1
                ? t('New badges unlocked!')
                : t('New badge unlocked!')
            }
            closeModal={closeModal}
            maxWidth={500}
            textColor={colors.white}
            css={css`
              text-align: center;
            `}
            transparent
          >
            <BadgeModalContent
              badges={earnedBadges}
              onClose={() => {
                closeModal()
                apolloCient.refetchQueries({
                  include: [checkClubMembershipBadges, userProfileQuery],
                })
              }}
              textColor={colors.white}
            />
          </Modal>
        )}
      />
    )
  }

  return null
}
