/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import QuizAd from '../../components/campaign/QuizAdHeader'
import { DefaultError } from '../../components/DefaultError'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { Loader } from '../../components/Loader'
import { useActiveCampaignForLeague } from '../../lib/sanity/sanity'
import { margin } from '../../styles/margin'
import theme from '../../styles/theme'
import { useChallengeMatchInstanceQuery } from '../ChallengeMatch/queries'
import {
  LeagueQuizContextProvider,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'
import { ChallengeQuiz } from './ChallengeQuiz'
import { ChallengeQuizHeader } from './ChallengeQuizHeader'

const Content = ({ challengeMatchId }: { challengeMatchId: string }) => {
  const { data: instanceData, loading: instanceLoading } =
    useChallengeMatchInstanceQuery(challengeMatchId)

  const { setCampaign, setLeagueId, setQuizId, setInstanceId } =
    useLeagueQuizContext()

  const { data: campaign } = useActiveCampaignForLeague(
    instanceData?.instanceFromChallengeMatch?.leagueQuiz.league.id
  )

  useEffect(() => {
    if (instanceData?.instanceFromChallengeMatch) {
      setLeagueId(instanceData.instanceFromChallengeMatch.leagueQuiz.league.id)
      setQuizId(instanceData.instanceFromChallengeMatch.leagueQuiz.id)
      setInstanceId(instanceData.instanceFromChallengeMatch.id)
    }
  }, [instanceData?.instanceFromChallengeMatch, setLeagueId, setQuizId])

  useEffect(() => {
    if (campaign) {
      setCampaign(campaign)
    }
  }, [campaign, setCampaign])

  if (instanceLoading) {
    return (
      <Page>
        <Flex horizontal="center" css={margin.top('huge')}>
          <Loader variant="green" />
        </Flex>
      </Page>
    )
  }

  const instance = instanceData?.instanceFromChallengeMatch

  if (
    !instance ||
    !instance.challengeQuizInstanceId ||
    !instance.challengeUser
  ) {
    const reason = !instance
      ? 'no instance'
      : !instance.challengeQuizInstanceId
      ? 'no challengeQuizInstanceId'
      : !instance.challengeUser
      ? 'no challengeUser'
      : 'unknown'

    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`ChallengeMatchPage | challengeMatchId: ${challengeMatchId}, reason: ${reason}`}
        />
      </Page>
    )
  }

  return (
    <Flex
      column
      grow
      css={css`
        overflow-x: hidden;
      `}
    >
      <QuizAd
        isChallenge
        isEmbed={false}
        leagueId={instance.leagueQuiz.league.id}
        quizId={instance.leagueQuiz.id}
        campaign={campaign}
      />

      <ChallengeQuizHeader instance={instance} />
      <ChallengeQuiz instance={instance} />
    </Flex>
  )
}

const ChallengeMatchPage = () => {
  const challengeMatchId = useParams<{ challengeMatchId: string }>()
    .challengeMatchId!

  return (
    <LeagueQuizContextProvider>
      <Layout
        hideHeaderPadding
        hideNavbar
        backgroundColor={theme.colors.grey100}
        css={css`
          height: 100%;
        `}
      >
        <Content challengeMatchId={challengeMatchId} />
      </Layout>
    </LeagueQuizContextProvider>
  )
}

export default ChallengeMatchPage
