/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from 'react'

import { css, keyframes } from '@emotion/react'
import { LeagueQuiz } from '../LeagueQuiz/queries'

import theme from '../../styles/theme'
import {
  QuizState,
  useLeagueQuizContext,
} from '../LeagueQuiz/LeagueQuizContext'

import { Text } from '../../components/Text'
import { PoweredByFcQuiz } from '../../components/icons'
import { Flex } from '../../components/layout/Flex'
import { useInterval } from '../../utils/useInterval'
import { CHALLENGE_HEADER_HEIGHT } from './ChallengeQuizHeader'
import { titleFromQuiz } from '../../utils/util'
import { useT } from '../../lib/i18n/useT'

export const ChallengeQuizStartScreen: FC<{
  leagueQuiz: LeagueQuiz
  colorTheme: 'red' | 'white' | 'green'
}> = ({ leagueQuiz, colorTheme }) => {
  const { setQuizState } = useLeagueQuizContext()

  const [remainingTime, setRemainingTime] = useState<number>(4)

  useInterval(
    () => {
      setRemainingTime(remainingTime - 1)
    },
    remainingTime > 0 ? 1000 : null
  )

  useEffect(() => {
    if (remainingTime === 0) {
      startQuiz()
    }
  }, [remainingTime])

  const fadeInAndOut = keyframes`
        0%{
          opacity: 0;
        }
        100%{
          opacity: 1;
        }
    `
  const textColor =
    colorTheme === 'red' || colorTheme === 'green'
      ? theme.colors.white
      : theme.colors.black

  const startQuiz = () => {
    setQuizState(QuizState.COUNTDOWN)
  }

  const t = useT()

  return (
    <Flex
      grow
      column
      vertical={'space-between'}
      css={css`
        padding: 24px 36px;
        max-width: 560px;
        margin: 0 auto;
        width: 100%;
        height: calc(100vh - ${CHALLENGE_HEADER_HEIGHT});
      `}
    >
      <Flex
        column
        grow
        horizontal="center"
        vertical="center"
        css={css`
          animation: ${fadeInAndOut} 2s ease-in-out;
        `}
      >
        <Text
          extraCondensed
          strong
          css={css`
            font-size: 54px;
            line-height: 54px;
            color: ${textColor};
            text-align: center;
            margin-bottom: 86px;
            font-style: italic;
          `}
        >
          {titleFromQuiz(leagueQuiz, t)}
        </Text>
      </Flex>

      <Flex
        horizontal="center"
        css={css`
          margin-top: 48px;
        `}
      >
        <div
          css={css`
            position: absolute;
            bottom: 0;
            padding-bottom: 16px;
          `}
        >
          <PoweredByFcQuiz />
        </div>
      </Flex>
    </Flex>
  )
}
