/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../lib/AuthContext'
import { useNotificationContext } from '../lib/NotificationContext'
import { useT } from '../lib/i18n/useT'
import { buttonReset } from '../styles/styles'
import { colors } from '../styles/theme'
import { containsJapanese } from '../utils/util'
import { Text } from './Text'
import { Home, ProfilePageIcon } from './icons'
import { Flex } from './layout/Flex'
import { PageContent } from './layout/PageContent'
import { LoginModalInner } from './modal/LoginModalInner'
import { ModalTrigger } from './modal/Modal'

const NavButton = ({
  label,
  onClick,
  active,
  icon,
  notifications,
}: {
  label: string
  onClick: () => void
  active?: boolean
  icon?: React.ReactNode
  notifications?: number
}) => {
  return (
    <button
      aria-label={label}
      role="navigation"
      css={[
        buttonReset,
        css`
          min-height: 40px;
          padding: 4px 12px;
          background-color: ${active ? colors.white : colors.grey500};
          border-radius: 4px;
          position: relative;
        `,
      ]}
      onClick={onClick}
    >
      {notifications && notifications > 0 ? (
        <Flex
          vertical="center"
          horizontal="center"
          css={css`
            position: absolute;
            top: -3px;
            right: -3px;
            background-color: ${colors.signalRed};
            border-radius: 16px;
            min-width: 16px;
            min-height: 16px;
            font-size: 8px;
            color: ${colors.white};
            animation: pulse 10s infinite ease-in-out;

            @keyframes pulse {
              0% {
                transform: scale(1);
              }

              45% {
                transform: scale(1);
              }

              50% {
                transform: scale(1.5);
              }

              55% {
                transform: scale(1);
              }

              100% {
                transform: scale(1);
              }
            }
          `}
        >
          {notifications}
        </Flex>
      ) : null}
      {icon && (
        <Flex
          vertical="center"
          horizontal="center"
          css={css`
            width: fit-content;
          `}
        >
          {icon}
        </Flex>
      )}

      {!icon && (
        <Text
          condensed
          size={containsJapanese(label) ? 'small' : 'medium'}
          color={active ? colors.black : colors.white}
        >
          {label}
        </Text>
      )}
    </button>
  )
}

export const Navbar = () => {
  const t = useT()
  const navigate = useNavigate()
  const location = useLocation()
  const { authUser } = useAuthContext()

  const isHome = location.pathname === '/'
  const isProfile = location.pathname.startsWith('/profile')
  const isLibrary = location.pathname.startsWith('/library')
  const isLeagues = location.pathname.startsWith('/leagues')

  // const { isNativeApp, openNativeLoginModal } = useNativeApp()

  // const showNativeLogin = isNativeApp && !isProduction

  const { unseenNotifications } = useNotificationContext()

  return (
    <>
      <Flex
        className="navbar"
        vertical="center"
        css={css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          height: 64px;
          background-color: ${colors.black};
          z-index: 2;
        `}
      >
        <PageContent horizontal="center">
          <Flex
            horizontal="space-between"
            vertical="center"
            css={css`
              width: 100%;
              max-width: 400px;
            `}
          >
            <NavButton
              label={t('Home')}
              icon={<Home color={isHome ? 'black' : 'white'} />}
              onClick={() => navigate('/')}
              active={isHome}
            />
            <NavButton
              label={t('Library')}
              onClick={() => navigate('/library')}
              active={isLibrary}
            />
            <NavButton
              label={t('Clubs')}
              onClick={() => navigate('/clubs')}
              active={location.pathname === '/clubs'}
            />

            <NavButton
              label={t('Leagues')}
              onClick={() => navigate('/leagues')}
              active={isLeagues}
            />

            {/*isProduction && (
              <NavButton
                label={t('Training')}
                onClick={() => navigate('/training')}
                active={location.pathname === '/training'}
              />
            )*/}

            {!authUser || authUser?.isAnonymous ? (
              <ModalTrigger
                button={({ openModal }) => (
                  <NavButton
                    label={t('Profile')}
                    icon={<ProfilePageIcon color={'white'} />}
                    onClick={() =>
                      //showNativeLogin ? openNativeLoginModal() : openModal()
                      openModal()
                    }
                  />
                )}
                modal={({ closeModal }) => (
                  <LoginModalInner
                    closeModal={closeModal}
                    destinationUrl={'/profile'}
                  />
                )}
              />
            ) : (
              <NavButton
                label={t('Profile')}
                icon={<ProfilePageIcon color={isProfile ? 'black' : 'white'} />}
                onClick={() => navigate('/profile')}
                active={isProfile}
                notifications={unseenNotifications}
              />
            )}
          </Flex>
        </PageContent>
      </Flex>
    </>
  )
}
