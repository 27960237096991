/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { FaShareSquare } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { growAndFade } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { LeagueQuizCard } from '../../views/Challenge/components/LeagueQuizCard'
import { ChallengeGroup } from '../../views/Challenge/queries'
import { PrimaryButton } from '../Button'
import { DefaultError } from '../DefaultError'
import { Header } from '../Header'
import { Loader } from '../Loader'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import { Layout } from '../layout/Layout'
import { Page } from '../leagueQuiz/Page'
import { LoginModalInner } from '../modal/LoginModalInner'
import { ModalTrigger } from '../modal/Modal'
import { FcQuizQrCode } from '../QrCodeComponent'
import { titleFromQuiz } from '../../utils/util'
import {
  useCreateChallengeGroupWithInvitationMutation,
  useLeagueQuizInstanceWithTransferQuery,
} from '../../views/ChallengeMatch/queries'

const Content = ({ quizInstanceId }: { quizInstanceId: string }) => {
  const { authUser, loading: authLoading } = useAuthContext()
  const navigate = useNavigate()
  const t = useT()

  const { data: instanceData, loading: instanceLoading } =
    useLeagueQuizInstanceWithTransferQuery({ id: quizInstanceId })

  const [createChallengeGroupWithInvitation, { loading: createLoading }] =
    useCreateChallengeGroupWithInvitationMutation()

  const [invitationLink, setInvitationLink] = useState<string | undefined>()
  const [linkCopied, setLinkCopied] = useState(false)

  const [challengeGroup, setChallengeGroup] = useState<
    ChallengeGroup | undefined
  >()

  if (instanceLoading || authLoading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  const instance = instanceData?.leagueQuizInstanceWithTransfer

  if (!instance || !authUser) {
    const reason = !instance ? 'no instance' : 'no authUser'
    return (
      <Page>
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`Challenge page | ${reason}`}
        />
      </Page>
    )
  }

  const isMobileDevice = window.navigator.share !== undefined

  const shareLink = async () => {
    if (!invitationLink) {
      return
    }

    const quizTitle = titleFromQuiz(instance.leagueQuiz, t)

    const message = t('Can you beat me in this Quiz?')

    if (isMobileDevice) {
      const shareText = `⚽️ fcQuiz – ${quizTitle} ⚽️\n\n${invitationLink}\n\n${message}`
      window.navigator
        .share({
          text: shareText,
        })
        .catch(() => {
          // ignore error
        })
    } else {
      navigator.clipboard.writeText(invitationLink)
      setLinkCopied(true)
      setTimeout(() => {
        setLinkCopied(false)
      }, 3000)
    }
  }

  const qrCode = () => {
    if (!invitationLink) {
      const reason = !invitationLink ? 'no invitationLink' : 'unknown'
      return (
        <DefaultError
          css={margin.top('huge')}
          sentryErrorMessage={`Challenge page | ${reason}`}
        />
      )
    }

    return (
      <Flex
        column
        css={css`
          width: 100%;
          max-width: 220px;
        `}
      >
        <FcQuizQrCode url={invitationLink} />
        <Spacer height={16} />
        {isMobileDevice && (
          <PrimaryButton
            onClick={shareLink}
            css={css`
              height: 50px;
              opacity: ${linkCopied ? '0.5' : '1'};
            `}
          >
            <Flex gap="small" vertical="center" horizontal="center">
              <FaShareSquare color="white" size={20} />
              <Text color="white ">
                {linkCopied ? t('Copied') : t('Share')}
              </Text>
            </Flex>
          </PrimaryButton>
        )}

        {!isMobileDevice && (
          <Flex
            vertical="center"
            horizontal="center"
            onClick={shareLink}
            css={css`
              height: 50px;
              width: 100%;
              background-color: ${colors.grey200};
              border-radius: 8px;
              padding-left: 16px;
              cursor: pointer;
              &:hover {
              }
            `}
          >
            <Text
              strong
              condensed
              color={colors.black}
              css={css`
                width: 80%;
                overflow-x: scroll;
                white-space: nowrap;
                user-select: none;
                /* hide scrollbar */
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              {invitationLink}
            </Text>
            <Text
              strong
              css={css`
                padding: 0 16px;
              `}
              color={colors.black}
            >
              {linkCopied ? t('Copied') : t('Copy')}
            </Text>
          </Flex>
        )}
        <Spacer height={16} />
      </Flex>
    )
  }

  if (authUser.isAnonymous) {
    return (
      <Flex
        column
        grow
        css={css`
          width: 100%;
          opacity: 0%;
          animation: ${growAndFade} 350ms cubic-bezier(0.4, 0, 0.15, 1) forwards;
          animation-delay: 150ms;
        `}
      >
        <Flex column>
          <Text
            extraCondensed
            strong
            size="massive"
            textAlign="center"
            css={css`
              font-style: italic;
              font-size: 40px;
              line-height: 40px;
            `}
          >
            {t('Woah!').toUpperCase()}
          </Text>
          <Text
            extraCondensed
            strong
            size="massive"
            textAlign="center"
            css={css`
              font-style: italic;
              font-size: 36px;
              line-height: 32px;
            `}
          >
            {t('Challenge signup prompt pt1')}
          </Text>
          <Spacer height={32} />
          <LeagueQuizCard quiz={instance.leagueQuiz} />
          <Spacer height={32} />

          <Text
            condensed
            strong
            textAlign="center"
            css={css`
              font-size: 20px;
              line-height: 24px;
              padding: 0 24px;
            `}
          >
            {t('Challenge signup prompt pt2')}
          </Text>
          <Text
            condensed
            strong
            textAlign="center"
            css={css`
              font-size: 20px;
              line-height: 24px;
              padding: 0 24px;
            `}
          >
            {t('Challenge signup prompt pt3')}
          </Text>
        </Flex>
        <Spacer height={32} />
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              onClick={openModal}
              css={css`
                height: 50px;
              `}
            >
              <Text size="huge" condensed color="white">
                {t('Sign up / Log in')}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      </Flex>
    )
  }

  return (
    <Flex
      column
      grow
      css={css`
        width: 100%;
      `}
    >
      <Flex column vertical="flex-start">
        <Text
          extraCondensed
          strong
          textAlign="center"
          css={css`
            font-size: 44px;
            font-style: italic;
          `}
        >
          {t('Challenge a friend').toUpperCase()}
        </Text>
        <Spacer height={16} />
        <LeagueQuizCard quiz={instance.leagueQuiz} />
      </Flex>
      <Spacer height={32} />

      {/* {groupsWithQuiz.length > 0 && (
        <Flex column>
          <Text extraCondensed textAlign="center" size="huge">
            {t('Challenges with this quiz')}
          </Text>
          <Spacer height={16} />
          {groupsWithQuiz.map((group) => {
            const selected = challengeGroup?.id === group.id
            return (
              <div key={group.id}>
                <ChallengeGroupListComponent
                  css={css`
                    margin-bottom: 16px;
                    width: 100%;
                  `}
                  group={group}
                  backgroundColor={selected ? colors.green200 : colors.grey100}
                  userId={authUser.uid}
                  buttonRole="button"
                  onClick={() => {
                    if (selected) {
                      setChallengeGroup(undefined)
                      setInvitationLink(undefined)
                      setExistingGroupSelected(false)
                      return
                    }

                    const invitation = group.versusInvitation

                    if (!invitation) {
                      return
                    }

                    const invLink = `${window.location.origin}/invitation/${invitation}`
                    setInvitationLink(invLink)
                    setChallengeGroup(group)
                    setExistingGroupSelected(true)
                  }}
                />
                {selected && <Flex horizontal="center">{qrCode()}</Flex>}
              </div>
            )
          })}
        </Flex>
      )} */}

      {!invitationLink && !createLoading && (
        <PrimaryButton
          onClick={() => {
            createChallengeGroupWithInvitation({
              variables: {
                input: {
                  quizInstanceId,
                },
              },
            }).then((result) => {
              const group = result.data?.createChallengeGroupWithInvitation
              if (!group) {
                return
              }

              setChallengeGroup(group)
              const invitation = group.versusInvitation

              if (!invitation) {
                return
              }

              trackEvent(AnalyticsEventType.ChallengeGroupCreated, {
                league: instance.leagueQuiz.league.slug,
              })

              const invLink = `${window.location.origin}/invitation/${invitation}`

              setInvitationLink(invLink)
            })
          }}
          css={css`
            height: 50px;
          `}
        >
          <Text size="huge" condensed color="white">
            {t('Create new challenge')}
          </Text>
        </PrimaryButton>
      )}

      {invitationLink && (
        <Flex
          column
          grow
          vertical="center"
          horizontal="center"
          css={css`
            width: 100%;
            opacity: 0%;
            animation: ${growAndFade} 350ms cubic-bezier(0.4, 0, 0.15, 1)
              forwards;
          `}
        >
          {qrCode()}
        </Flex>
      )}

      {createLoading && (
        <Flex grow horizontal="center">
          <Loader />
        </Flex>
      )}

      {challengeGroup && (
        <PrimaryButton
          onClick={() => {
            navigate(`/challenge-group/${challengeGroup.id}`)
          }}
          css={css`
            width: 100%;
            opacity: 0%;
            animation: ${growAndFade} 350ms cubic-bezier(0.4, 0, 0.15, 1)
              forwards;
            height: 50px;
          `}
        >
          {t('View challenge page')}
        </PrimaryButton>
      )}
    </Flex>
  )
}

export const ChallengePage = () => {
  const quizInstanceId = useParams<{ quizInstanceId: string }>().quizInstanceId

  return (
    <Layout>
      <Header color="grey" hideSignupButton />
      <Page>
        {quizInstanceId ? (
          <Content quizInstanceId={quizInstanceId} />
        ) : (
          <DefaultError
            css={margin.top('huge')}
            sentryErrorMessage="Challenge page | useParams could not find quizInstanceId"
          />
        )}
      </Page>
    </Layout>
  )
}
