import {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'

export function useQueryUnpack<T>(
  query: DocumentNode | TypedDocumentNode<T>,
  options?: QueryHookOptions<T>
) {
  const _query = useQuery(query, options)
  if (!_query.data) {
    return { ..._query }
  }
  return {
    ..._query,
    data: Object.values(_query.data)[0] as T,
  }
}

// Dont make useLazyQueryUnpack, since the runQuery method cant be modified

export function clearTypeName(data: any) {
  return Object.fromEntries(
    Object.entries(data).filter((it) => it[0] !== '__typename')
  )
}
