/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UnknownPlayer from '../../assets/UnknownPlayer.svg'
import UnknownPlayerEyesOpen from '../../assets/UnknownPlayerEyesOpen.svg'
import { useT } from '../../lib/i18n/useT'
import { fadeIn } from '../../styles/animations'
import { Spacer } from '../../styles/margin'
import { colors, hexOpacity } from '../../styles/theme'
import { getCountryFlag } from '../../utils/nation'
import { useDebouncedString } from '../../utils/useDebouncedValue'
import {
  CareerPathTeam,
  useGetPlayerByNameSimpleQuery,
} from '../../views/Generate/queries'
import { shimmer } from '../../views/Home/ShimmerComponent'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  ActiveCareerPathQuestion as ActiveCareerPathQuestionType,
  LeagueQuiz,
  useAnswerCareerPathQuizQuestionMutation,
} from '../../views/LeagueQuiz/queries'
import { Button, PrimaryButton } from '../Button'
import { Text } from '../Text'
import { TextInput } from '../TextInput'
import { TextNew } from '../TextNew'
import { Flex } from '../layout/Flex'
import { PageContent } from '../layout/PageContent'
import CareerPath from './CareerPath'
import { CareerPathQuizStatusComponent } from './CareerPathQuizStatusComponent'

export const ActiveCareerPathQuestion: FC<{
  leagueQuiz: LeagueQuiz
  numCompletedQuestions: number
  activeQuestion: ActiveCareerPathQuestionType
  quizInstanceId: string
  isEmbed?: boolean
}> = ({
  leagueQuiz,
  numCompletedQuestions,
  activeQuestion,
  quizInstanceId,
  isEmbed,
}) => {
  const t = useT()
  const { campaignHeaderHeight } = useLeagueQuizContext()
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedString(searchQuery, 300, {
    minLength: 3,
  })
  const queryResult = useGetPlayerByNameSimpleQuery(debouncedSearchQuery)

  const [answerQueryLoading, setAnswerQueryLoading] = useState(false)

  const [answerCareerPathQuizQuestionMutation] =
    useAnswerCareerPathQuizQuestionMutation()

  const [timeSpent, setTimeSpent] = useState(0)

  const [showEyesOpen, setShowEyesOpen] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const startedAt = new Date(
        parseInt(activeQuestion.answers[0].startedAt, 10)
      ) // Ensure startedAt is parsed as a Date
      const now = new Date()
      const diff = now.getTime() - startedAt.getTime()
      setTimeSpent(Math.floor(diff / 1000))
    }, 1000)

    return () => clearInterval(interval)
  }, [activeQuestion.answers])

  useEffect(() => {
    // Open the eyes every 5 seconds, then close them after 0.75 seconds
    const interval = setInterval(() => {
      setShowEyesOpen(true)
      setTimeout(() => {
        setShowEyesOpen(false)
      }, 150)
    }, 7000)

    return () => clearInterval(interval)
  }, [])

  const handleAnswerQuestion = async (playerId: string, skip?: boolean) => {
    if (answerQueryLoading) {
      return
    }
    setSearchQuery('')
    setAnswerQueryLoading(true)

    try {
      await answerCareerPathQuizQuestionMutation({
        variables: {
          input: {
            leagueQuizInstanceId: quizInstanceId,
            questionId: activeQuestion.id,
            playerId,
            skip: skip ?? false,
          },
        },
      })
    } catch (error) {
      console.error('Error answering question', error)
    }

    setAnswerQueryLoading(false)
  }

  return (
    <PageContent
      css={css`
        padding-top: ${isEmbed ? 0 : 24}px;
        height: calc(100vh - ${campaignHeaderHeight});
      `}
    >
      <Flex
        column
        vertical={'space-between'}
        css={css`
          height: 100%;
          max-width: 560px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Flex column>
          <Spacer height={isEmbed ? 8 : 'medium'} />
          <CareerPathQuizStatusComponent
            currentQuestion={numCompletedQuestions}
            totalQuestions={leagueQuiz.questionsCount}
            timeSpent={timeSpent}
          />

          <Flex
            horizontal={'center'}
            css={css`
              width: 100%;
              position: relative;
              height: 80px;
              overflow-y: visible;
              top: -20px;
            `}
          >
            <img
              src={showEyesOpen ? UnknownPlayer : UnknownPlayerEyesOpen}
              width={72}
              height={90}
              alt="Unknown Player"
            />
          </Flex>

          <Spacer height={'small'} />

          <CareerPath
            careerPath={activeQuestion.careerPath}
            showYear
            hideClubsIndex={
              activeQuestion.answers.filter((a) => a.playerId).length
            }
          />

          <Spacer height={'medium'} />

          <Flex
            horizontal={'center'}
            column={false}
            gap={'medium'}
            vertical={'center'}
            css={css`
              width: 100%;
              padding: 0;
            `}
          >
            <TextInput
              value={searchQuery}
              placeholder={t(`Search for player`)}
              onValue={setSearchQuery}
              css={css`
                width: 100%;
              `}
            />
          </Flex>

          <Spacer height={'medium'} />

          {queryResult.loading && <PlayerQuerySkeletonLoader />}
          {queryResult.error && <div>Error: {queryResult.error.message}</div>}
          {queryResult.data && searchQuery.length > 2 && (
            <Flex column gap={'small'}>
              {queryResult.data.careerPathPlayersByName.length <= 0 ? (
                <Flex
                  gap={'small'}
                  vertical={'center'}
                  horizontal={'center'}
                  css={css`
                    padding: 6px 12px;
                  `}
                >
                  <Text color={colors.grey500}>
                    {t(`No players found, try again...`)}
                  </Text>
                </Flex>
              ) : (
                queryResult.data.careerPathPlayersByName.map((player) => (
                  <Button
                    key={player.id}
                    css={css`
                      display: flex;
                      flex-direction: row;
                      gap: 12px;
                      align-items: center;
                      padding: 6px 12px;
                      border: 1px solid ${colors.grey400};
                      border-radius: 5px;
                      background-color: ${colors.white};

                      &:hover {
                        background-color: ${colors.grey200};
                      }
                    `}
                    onClick={() => handleAnswerQuestion(player.id)}
                  >
                    <img
                      width={32}
                      height={32}
                      src={player.careerPathPlayer.photoUrl}
                      css={css`
                        border-radius: 50%;
                        overflow: hidden;
                      `}
                    />

                    <Text>{player.careerPathPlayer.playerName}</Text>
                  </Button>
                ))
              )}
            </Flex>
          )}

          <Spacer height={'medium'} />

          <GuessIndicator
            answers={activeQuestion.answers}
            activeQuestion={activeQuestion}
          />

          <Spacer height={isEmbed ? 8 : 'medium'} />

          <Flex
            column
            gap={'small'}
            horizontal={'center'}
            css={css`
              width: 100%;
            `}
          >
            {activeQuestion.answers
              .filter((a) => a.playerId && a.playerId !== 'skipped')
              .sort(
                // Latest answers first
                (a, b) => parseInt(b.startedAt, 10) - parseInt(a.startedAt, 10)
              )
              .map((answer) => (
                <CareerPathQuestionAnswer
                  key={answer.playerId}
                  playerId={answer.playerId}
                  playerName={answer.playerName}
                  photoUrl={answer.photoUrl}
                  nationality={answer.nationality}
                  position={answer.position}
                  team={answer.team}
                  correct={answer.correct}
                  correctNationality={activeQuestion.nationality}
                  correctTeamId={activeQuestion.team.id}
                  correctLeagueId={activeQuestion.team.league?.id}
                  correctPosition={activeQuestion.position}
                />
              ))}
          </Flex>

          <Spacer height={isEmbed ? 8 : 'medium'} />

          <Flex horizontal={'center'}>
            <PrimaryButton
              onClick={() => handleAnswerQuestion('No player', true)}
              disabled={answerQueryLoading}
              css={css`
                height: 39px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: fit-content;

                border-radius: 38px;
                padding: 0 24px;
                min-width: 100px;
              `}
            >
              <TextNew
                color={'white'}
                condensed
                size={'large'}
                italic
                spacingEm={0}
                css={css`
                  margin-bottom: 4px; // Not ideal, but the font has a weird centering
                `}
              >
                {t(`Skip`)}
              </TextNew>
            </PrimaryButton>
          </Flex>

          <Spacer height={'large'} />
        </Flex>
      </Flex>
    </PageContent>
  )
}

export const CareerPathQuestionAnswer: FC<{
  playerId: string | undefined
  playerName: string
  photoUrl: string
  nationality: string
  position: string
  team: CareerPathTeam
  correct: boolean
  correctTeamId?: string
  correctNationality?: string
  correctLeagueId?: string
  correctPosition?: string
}> = ({
  playerId,
  playerName,
  photoUrl,
  nationality,
  position,
  team,
  correct,
  correctNationality,
  correctTeamId,
  correctLeagueId,
  correctPosition,
}) => {
  if (!playerId) {
    return null
  }

  if (playerId === 'skipped') {
    return <SkippedAnswer />
  }

  return (
    <Flex
      vertical={'center'}
      horizontal={'space-between'}
      css={css`
        background-color: ${correct ? colors.green300 : colors.grey200};
        padding: 6px 6px;
        height: 54px;
        border-radius: 5px;
        width: 100%;
        max-width: 100%;
        animation: ${fadeIn} 0.5s ease-out 0.5s both;
      `}
    >
      <Flex
        gap={'tiny'}
        vertical={'center'}
        css={css`
          width: 50%;
        `}
      >
        <img
          width={42}
          height={42}
          src={photoUrl}
          css={css`
            border-radius: 50%;
            border: 1px solid ${colors.grey400};
          `}
        />

        <TextNew
          condensed
          size={'large'}
          italic
          spacingEm={0}
          strong
          css={css`
            text-overflow: ellipsis;
            min-width: 100%;
            max-width: 100%;
          `}
        >
          {playerName}
        </TextNew>
      </Flex>

      <Flex
        gap={'tiny'}
        horizontal={'flex-end'}
        vertical={'center'}
        css={css`
          width: 100%;
        `}
      >
        <AnswerImage
          imageUrl={getCountryFlag(nationality)}
          correct={nationality == correctNationality}
          animationDelay={0.5}
        />
        {team.league?.logoUrl && (
          <AnswerImage
            imageUrl={team.league?.logoUrl}
            correct={team.league?.id == correctLeagueId}
            animationDelay={1}
          />
        )}
        <AnswerImage
          imageUrl={team.logoUrl}
          correct={team.id == correctTeamId}
          animationDelay={1.5}
        />
        <Flex
          horizontal={'center'}
          vertical={'center'}
          css={css`
            border-radius: 50%;
            background-color: ${position === correctPosition
              ? colors.green300
              : colors.red300};
            padding: 4px;
            width: 40px;
            height: 40px;
            animation: ${fadeIn} 0.5s ease-out ${2}s both;
          `}
        >
          <TextNew condensed size={'large'} italic spacingEm={0} strong>
            {position
              ? getPositionFromPositionString(position.toLowerCase())
              : 'N/A'}
          </TextNew>
        </Flex>
      </Flex>
    </Flex>
  )
}

const AnswerImage: FC<{
  imageUrl: string
  size?: number
  correct: boolean
  animationDelay?: number
}> = ({ imageUrl, size = 28, correct, animationDelay = 0 }) => {
  return (
    <Flex
      horizontal={'center'}
      vertical={'center'}
      css={css`
        border-radius: 50%;
        background-color: ${correct ? colors.green300 : colors.red300};
        padding: 6px;
        animation: ${fadeIn} 0.5s ease-out ${animationDelay}s both;
        width: ${size + 12}px;
        overflow: hidden;
      `}
    >
      <img src={imageUrl} height={size} />
    </Flex>
  )
}

const SkippedAnswer: FC = () => {
  const { t } = useTranslation('common')
  return (
    <Flex
      vertical={'center'}
      horizontal={'center'}
      gap={'medium'}
      css={css`
        background-color: ${colors.grey200};
        padding: 6px 12px;
        border-radius: 5px;
        width: 100%;
        animation: ${fadeIn} 0.5s ease-out 0.5s both;
        border: 1px solid ${colors.grey300};
      `}
    >
      <TextNew uppercase condensed size={'large'} italic spacingEm={0} strong>
        {t(`Skipped`)}
      </TextNew>
    </Flex>
  )
}

const PlayerQuerySkeletonLoader = () => (
  <Flex column gap={'small'}>
    {/* Create multiple skeleton loader placeholders */}
    {[...Array(5)].map(
      (
        _,
        index // Adjust the number of placeholders as needed
      ) => (
        <Flex
          key={index}
          gap={'small'}
          vertical={'center'}
          horizontal={'space-between'}
          css={css`
            padding: 6px 12px;
            border: 1px solid ${colors.grey400};
            border-radius: 5px;
            background-color: ${colors.white};
          `}
        >
          {/* Placeholder for the image */}
          <div
            css={css`
              width: 32px;
              min-width: 32px;
              height: 32px;
              border-radius: 50%;
              background-color: ${colors.grey300};

              position: relative;
              overflow: hidden;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background: linear-gradient(
                  135deg,
                  transparent 15%,
                  ${hexOpacity(colors.white, 0.5)} 50%,
                  transparent 85%
                );

                background-size: 200% 400%;
                animation: ${shimmer} 1.5s infinite ease-in-out;
              }
              }
            `}
          ></div>

          {/* Placeholder for the text */}
          <div
            css={css`
              width: 100%; /* Adjust the width as needed */
              height: 16px;
              border-radius: 5px;
              background-color: ${colors.grey300};

              position: relative;
              overflow: hidden;

              &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background: linear-gradient(
                  135deg,
                  transparent 15%,
                  ${hexOpacity(colors.white, 0.5)} 50%,
                  transparent 85%
                );

                background-size: 200% 400%;
                animation: ${shimmer} 1.5s infinite ease-in-out reverse;
              }
            `}
          ></div>
        </Flex>
      )
    )}
  </Flex>
)

export const GuessIndicator = ({
  answers,
  activeQuestion,
}: {
  answers: ActiveCareerPathQuestionType['answers']
  activeQuestion: ActiveCareerPathQuestionType
}) => {
  const t = useT()
  return (
    <Flex
      column={false}
      gap={'small'}
      vertical={'center'}
      horizontal={'center'}
    >
      <TextNew strong italic size={'small'}>
        {t(`GUESSES`)}
      </TextNew>

      {answers
        .filter((answer) => answer.playerId)
        .map((answer, index) =>
          answer.playerId === 'skipped' ? (
            <Flex
              key={index}
              css={css`
                width: 12px;
                height: 12px;
                margin-top: 2px; // Not ideal
                border-radius: 50%;
                background-color: ${colors.red300};
              `}
            />
          ) : (
            <Flex
              key={index}
              css={css`
                width: 12px;
                height: 12px;
                margin-top: 2px; // Not ideal
                border-radius: 50%;
                background-color: ${answer.correct
                  ? colors.green400
                  : answer.team?.id === activeQuestion.team?.id ||
                    answer.team.league?.id === activeQuestion.team.league?.id ||
                    answer.nationality === activeQuestion.nationality
                  ? colors.yellow400
                  : colors.red300};
              `}
            />
          )
        )}

      {/* Add a grey indicator for all remaining guesses */}

      {[
        ...Array(
          activeQuestion.careerPath.length -
            answers.filter((answer) => answer.playerId).length
        ),
      ].map((_, index) => (
        <Flex
          key={index}
          css={css`
            width: 12px;
            height: 12px;
            margin-top: 2px; // Not ideal
            border-radius: 50%;
            background-color: ${colors.grey200};
          `}
        />
      ))}
    </Flex>
  )
}

export function getPositionFromPositionString(position: string) {
  switch (position) {
    case 'goalkeeper':
      return 'GK'
    case 'defender':
      return 'DEF'
    case 'midfielder':
      return 'MID'
    case 'forward':
      return 'FWD'
    case 'attacker':
      return 'FWD'
    default:
      return 'N/A'
  }
}
