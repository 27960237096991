/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import adcat from '../../assets/ad-cat.gif'
import { isTest } from '../../config/config'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, fadeOut } from '../../styles/animations'
import { Flex } from '../layout/Flex'
import { TextNew } from '../TextNew'

export enum AdSize {
  BANNER = '468x60',
  LARGE_BANNER = '720x90',
  MEDIUM_RECTANGLE = '300x250',
  LARGE_RECTANGLE = '336x280',
  SQUARE = '250x250',
}

export enum AdId {
  rightRail1 = 'right-rail-1',
  rightRail2 = 'right-rail-2',
  rightRail3 = 'right-rail-3',
  leftRail1 = 'left-rail-1',
  leftRail2 = 'left-rail-2',
  leftRail3 = 'left-rail-3',
  videoTop = 'video-top',
  anchor = 'anchor',
}

const adsizeToCss = (adSize: AdSize) => {
  switch (adSize) {
    case AdSize.BANNER:
      return css`
        width: 468px;
        height: 60px;
      `
    case AdSize.LARGE_BANNER:
      return css`
        width: 720px;
        height: 90px;
      `
    case AdSize.MEDIUM_RECTANGLE:
      return css`
        width: 300px;
        height: 250px;
      `
    case AdSize.LARGE_RECTANGLE:
      return css`
        width: 336px;
        height: 280px;
      `
    case AdSize.SQUARE:
      return css`
        width: 250px;
        height: 250px;
      `
  }
}

enum ComponentState {
  canShow,
  adblock,
  cantShow,
}

export const RevIqAdComponent = ({
  adSize,
  adId = AdId.leftRail1,
  visible = true,
  trackEvent,
  adblockDetected,
}: {
  adSize: AdSize
  adId?: AdId
  visible?: boolean
  trackEvent?: () => void
  adblockDetected?: boolean
}) => {
  const t = useT()
  const adElement = document.getElementById(adId)

  const numChildren = adElement?.children?.length || 0
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!visible || !trackEvent || numChildren === 0) {
        return
      }

      trackEvent()
    }, 1000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [visible, trackEvent, numChildren])

  const backgroundColor = visible ? 'black' : 'transparent'

  const getComponentState = () => {
    if (isTest) {
      return ComponentState.cantShow
    }

    if (adblockDetected) {
      return ComponentState.adblock
    }

    return ComponentState.canShow
  }

  const componentState = getComponentState()

  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={[
        css`
          opacity: 0;
        `,
        visible &&
          css`
            animation: ${fadeIn} 600ms ease-out;
            animation-delay: 600ms;
            animation-fill-mode: forwards;
          `,

        !visible &&
          css`
            animation: ${fadeOut} 350ms ease-out forwards;
            animation-delay: ${0}ms;
          `,
      ]}
    >
      <div data-ad={adId} data-ad-size={adSize} />

      <Flex
        column
        horizontal="center"
        vertical="center"
        gap={'medium'}
        css={[
          css`
            background-color: ${backgroundColor};
            border-radius: 4px;
            padding: 16px;
            display: ${componentState === ComponentState.adblock
              ? 'flex'
              : 'none'};
          `,
          adsizeToCss(adSize),
        ]}
      >
        <img
          src={adcat}
          alt="cat-gif"
          style={{
            height: '82px',
            borderRadius: '100%',
            aspectRatio: '1/1',
            margin: '0',
            marginTop: '-11px',
            padding: '6px',
          }}
        />
        <TextNew condensed size="large" color="white" textAlign="center">
          {t('We see you dont like ads, you can watch this cat instead')}
        </TextNew>
      </Flex>

      <div
        css={[
          css`
            display: ${componentState === ComponentState.cantShow
              ? 'flex'
              : 'none'};
            background-color: ${backgroundColor};
            border-radius: 4px;
          `,
          adsizeToCss(adSize),
        ]}
      />
    </Flex>
  )
}
