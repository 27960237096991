/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Badge } from '../views/League/queries'

export const BadgeComponent = (props: {
  badge: Badge
  size?: number
  className?: string
}) => {
  const { badge, size, className } = props

  return (
    <object
      className={className}
      data={badge.imageUrl}
      css={css`
        height: ${size}px;
        object-fit: contain;
      `}
    />
  )
}
