import { createClient, type ClientConfig } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
import { useEffect, useState } from 'react'
import { shuffle } from 'shuffle-seed'
import { isProduction } from '../../config/config'
import { stringOrUndefined } from '../../views/Admin/Utils'
import { QuizType } from '../../views/Generate/GeneratePage'
import { useAuthContext } from '../AuthContext'

import {
  getStringFromLocaleString,
  Locale,
  useCurrentLocale,
} from '../i18n/locale'
import {
  CampaignImageType,
  SanityLeagueQuiz,
  SanityLeagueQuizCampaign,
  SanityLeagueQuizRaw,
  SanityQuestion,
} from './types'
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder'

const config: ClientConfig = {
  projectId: 'ryhob3bg',
  dataset: isProduction ? 'production' : 'development',
  apiVersion: '2024-07-30',
  useCdn: true,
}

const client = createClient(config)
const imageBuilder: ImageUrlBuilder = imageUrlBuilder(client)

export const findCurrentQuizForLeague = async (
  leagueId?: string
): Promise<SanityLeagueQuizRaw | undefined> => {
  if (!leagueId) {
    console.error('no league id provided')
    return undefined
  }

  const data = await client.fetch<SanityLeagueQuizRaw>(
    `*[_type == "leagueQuiz" && !(_id in path('drafts.**')) && league._ref == $leagueId
      && (!defined(availableTo) || availableTo > now())
      && availableFrom < now()
    ]{
      "id": _id,
      availableFrom,
      availableTo,
      title,
      "questions" : questions[] -> {
        "id": _id,
        "text": question,
       alternatives[] {
         "id": _key,
         correct,
         "text": content
       }
      },
      "questionsCount": count(questions),
      randomizeQuestionOrder,
      "embedPromotionalText": promotionalText,
      "slug": slug.current,
      "imageUrl": image.asset -> url,
      "league": league -> { 
        "id": _id,
        title,
        "slug": slug.current,
        "imageUrl": image.asset->url,
        frequency
      }
    } | order(availableFrom desc) [0]`,
    { leagueId }
  )

  return data
}

export const useCurrentQuizForLeague = (locale: Locale, leagueId?: string) => {
  const [currentQuiz, setCurrentQuiz] = useState<SanityLeagueQuiz | undefined>()
  const [loading, setLoading] = useState(false)

  const { authUser } = useAuthContext()

  useEffect(() => {
    if (!leagueId) {
      console.error('leagueId is required')
      return
    }

    setLoading(true)

    findCurrentQuizForLeague(leagueId).then((data) => {
      if (!data) {
        setCurrentQuiz(undefined)
        setLoading(false)
        return
      }

      const quiz = rawQuizToLocalized(data, locale, authUser?.uid)

      setLoading(false)
      setCurrentQuiz(quiz)
    })
  }, [leagueId])

  return { data: currentQuiz, loading }
}

export const findCampaigns = async (): Promise<SanityLeagueQuizCampaign[]> => {
  const query = `
    *[_type == "leagueQuizCampaign" 
      && !(_id in path('drafts.**')) 
      && availableTo > now() 
      && availableFrom < now()] 
    {
      "id": _id,
      name,
      campaignLink,
      "exclusiveLeagues": leagues[] -> {
        "id": _id
        },
      "excludedLeagues": excludedLeagues[]->{
        "id": _id
      },
      "alwaysIncludedLeagues": includedLeagues[]->{
        "id": _id
      },
      "languageRestrictions": languageRestrictedCampaign,
      authenticatedOnly,
      backgroundColor,
      titleScreenBackgroundColor,
      "backgroundImageUrl": backgroundImage.asset->url,
      "titleScreenImageUrl": titleScreenImage.asset->url,
      "titleScreenImageMobileUrl": titleScreenImageMobile.asset->url,
      "countdownImageUrl": countdownImage.asset->url,
      "countdownImageMobileUrl": countdownImageMobile.asset->url,
      "partnerLogoUrl": partnerLogo.asset->url,
      "bannerImageUrl": bannerImage.asset->url,
      "bannerImageMobileUrl": bannerImageMobile.asset->url,
      "expandedImageUrl": expandedImage.asset->url,
      "expandedImageMobileUrl": expandedImageMobile.asset->url,
      "finishedBannerImageUrl": finishedBannerImage.asset->url,
      "finishedBannerImageMobileUrl": finishedBannerImageMobile.asset->url
    }
  `

  const data = await client.fetch<SanityLeagueQuizCampaign[]>(query)

  return data
}

export const useActiveCampaignForLeague = (leagueId?: string) => {
  const [campaign, setCampaign] = useState<
    SanityLeagueQuizCampaign | undefined
  >()

  const locale = useCurrentLocale()

  const { authUser } = useAuthContext()
  const isAuthenticatedUser = stringOrUndefined(authUser?.email) !== undefined

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!leagueId) {
      return
    }

    setLoading(true)
    findCampaigns().then((data) => {
      setLoading(false)
      let relatedCampaigns: SanityLeagueQuizCampaign[] = []

      for (const campaign of data) {
        if (campaign.authenticatedOnly && !isAuthenticatedUser) {
          continue
        }

        const campaignHasNoLeagueRestrictions =
          !campaign.exclusiveLeagues || campaign.exclusiveLeagues?.length === 0

        const campaignHasNoRegionRestrictions =
          !campaign.languageRestrictions ||
          campaign.languageRestrictions?.length === 0

        const leagueInIncludedLeagues = campaign.alwaysIncludedLeagues?.some(
          (l) => l.id === leagueId
        )

        const leagueInExcludedLeagues =
          campaign.excludedLeagues?.some((l) => l.id === leagueId) || false

        // If the league is in the excluded leagues, skip the campaign
        if (leagueInExcludedLeagues) {
          continue
        }

        // If the league is in the always included leagues, add the campaign to the list
        if (leagueInIncludedLeagues) {
          relatedCampaigns.push(campaign)
          continue
        }

        // If the campaign has no restrictions, add it to the list
        if (
          campaignHasNoLeagueRestrictions &&
          campaignHasNoRegionRestrictions
        ) {
          relatedCampaigns.push(campaign)
          continue
        }

        const campaignIsRelatedToLeague = campaign.exclusiveLeagues?.some(
          (l) => l.id === leagueId
        )

        // if the campaign has no region restrictions and is related to the league, add it to the list
        if (campaignHasNoRegionRestrictions && campaignIsRelatedToLeague) {
          relatedCampaigns.push(campaign)
          continue
        }
        const restrictionsContainsRegion =
          campaign.languageRestrictions?.includes(locale)

        // if the campaign is related to the league and the campaign is supported by the region, add it to the list
        if (restrictionsContainsRegion && campaignIsRelatedToLeague) {
          relatedCampaigns.push(campaign)
          continue
        }

        // if the campaign is supported by the region and has no league restrictions, add it to the list
        if (restrictionsContainsRegion && campaignHasNoLeagueRestrictions) {
          relatedCampaigns.push(campaign)
        }
      }

      if (relatedCampaigns.length === 0) {
        return undefined
      }

      // Check if there are both authenticated and unauthenticated campaigns
      if (isAuthenticatedUser && relatedCampaigns.length > 1) {
        const hasAuthenticatedOnlyCampaigns = relatedCampaigns.some(
          (c) => c.authenticatedOnly
        )

        // filter out unauthenticated campaigns
        if (hasAuthenticatedOnlyCampaigns) {
          relatedCampaigns = relatedCampaigns.filter((c) => c.authenticatedOnly)
        }
      }

      const campaign =
        relatedCampaigns[Math.floor(Math.random() * relatedCampaigns.length)]

      if (!campaign) {
        return undefined
      }

      setCampaign(campaign)
    })
  }, [leagueId, locale, isAuthenticatedUser])

  return { data: campaign, loading }
}

const rawQuizToLocalized = (
  quiz: SanityLeagueQuizRaw,
  locale: Locale,
  userId?: string
): SanityLeagueQuiz => {
  const missingText = 'TEXT_MISSING'

  const title = getStringFromLocaleString(quiz.title, locale) || missingText

  const getQuizType = (): QuizType => {
    if (quiz.questions.some((q) => !q.alternatives)) {
      return QuizType.CAREERPATH
    }

    return QuizType.TEXT
  }

  const quizType = getQuizType()

  if (quizType !== 'text') {
    return {
      ...quiz,
      title,
      questions: quiz.questions.map((question) => ({
        ...question,
        text: '',
        alternatives: [],
      })),
      league: {
        ...quiz.league,
        title:
          getStringFromLocaleString(quiz.league.title, locale) || missingText,
      },
      embedPromotionalText: quiz.embedPromotionalText
        ? getStringFromLocaleString(quiz.embedPromotionalText, locale)
        : undefined,
      quizType,
    }
  }

  const questions: SanityQuestion[] = quiz.questions.map((question) => {
    return {
      id: question.id,
      text: getStringFromLocaleString(question.text, locale) || missingText,
      alternatives: shuffle(
        question.alternatives?.map((alternative) => ({
          id: alternative.id,
          text:
            getStringFromLocaleString(alternative.text, locale) || missingText,
          correct: alternative.correct,
        })) || [],
        userId
      ),
    }
  })

  const league = {
    ...quiz.league,
    title: getStringFromLocaleString(quiz.league.title, locale) || missingText,
  }

  const embedPromotionalText = quiz.embedPromotionalText
    ? getStringFromLocaleString(quiz.embedPromotionalText, locale)
    : undefined

  return {
    ...quiz,
    title,
    questions,
    league,
    embedPromotionalText,
    quizType,
    randomizeQuestionOrder: quiz.randomizeQuestionOrder ?? false,
  }
}

const imageDimensions = (
  type: CampaignImageType
): { width: number; height: number } => {
  switch (type) {
    case CampaignImageType.titleImage:
      return { width: 600, height: 120 }
    case CampaignImageType.titleImageMobile:
      return { width: 600, height: 173 }
    case CampaignImageType.countdownImage:
      return { width: 1000, height: 300 }
    case CampaignImageType.countdownImageMobile:
      return { width: 600, height: 300 }
    case CampaignImageType.bannerImage:
      return { width: 1000, height: 140 }
    case CampaignImageType.bannerImageMobile:
      return { width: 600, height: 140 }
    case CampaignImageType.expandedImage:
      return { width: 1400, height: 450 }
    case CampaignImageType.expandedImageMobile:
      return { width: 600, height: 450 }
  }
}

export const makeSanityImageUrl = ({
  uri,
  type,
}: {
  uri: string
  type?: CampaignImageType
}) => {
  const imageIsSvg = uri.endsWith('.svg')

  if (imageIsSvg) {
    return uri
  }

  if (!type) {
    return uri
  }

  const { width, height } = imageDimensions(type)
  return imageBuilder.image(uri).width(width).height(height).fit('max').url()
}
