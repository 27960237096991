/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { colors } from '../styles/theme'

export type DividerProps = {
  size?: number
  vertical?: boolean
  indent?: number
} & ComponentPropsWithRef<'div'>

export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  function Divider({ size = 1, vertical, indent = 0, color, ...rest }, ref) {
    return (
      // Vertical likely doesnt work, test when you're using it
      <div
        ref={ref}
        {...rest}
        css={css`
          background-color: ${color ?? colors.grey400};
          flex: 1;
          min-height: ${vertical ? 'auto' : size + 'px'};
          height: ${vertical ? 'auto' : size + 'px'};
          min-width: ${!vertical ? 'auto' : size + 'px'};
          width: ${!vertical ? 'auto' : size + 'px'};
          margin: ${vertical ? 0 : indent}px ${!vertical ? 0 : indent}px;
        `}
      />
    )
  }
)
