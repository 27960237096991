/** @jsxImportSource @emotion/react */

import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import UserChallengeList from './UserChallengesList'

export const MyChallengesPage = () => {
  const t = useT()
  return (
    <Layout
      title={`fcQuiz | ${t('Challenges')}`}
      backgroundColor={colors.grey100}
    >
      <Flex column grow>
        <Header color="grey" />
        <PageContent>
          <Flex horizontal="space-between" vertical="center">
            <Heading level={1} looksLikeLevel={3} extraCondensed>
              {t('Challenges')}
            </Heading>
          </Flex>
        </PageContent>
        <Spacer height={32} />
        <UserChallengeList daysLimit={30} />
        <Spacer height={32} />
      </Flex>
    </Layout>
  )
}
