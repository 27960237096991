/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { clamp, findLast } from 'lodash'
import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { TextNew } from '../../components/TextNew'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { colors, hexOpacity, hexToRgba } from '../../styles/theme'
import { colorIsLight } from '../Admin/Utils'
import { DeadlineComponent } from '../Challenge/components/ChallengeLeagueListComponent'
import {
  DateRangeChallenge,
  useJoinDateRangeChallengeMutation,
} from './queries'
import { ShimmerComponent } from './ShimmerComponent'
import { CloseOpen } from './SpecialLeaderboard'
import { GradientOverlay } from './SpecialQuiz'

export const DateRangeChallengeItem = ({
  dateRangeChallenge,
  loading,
  className,
}: {
  dateRangeChallenge?: DateRangeChallenge
  loading: boolean
  className?: string
}) => {
  const t = useT()
  const [hideDrawer, setHideDrawer] = useState(true)

  const drawerKey = `hideDrawer:${dateRangeChallenge?.id}`

  const [joinChallenge] = useJoinDateRangeChallengeMutation()

  useEffect(() => {
    setHideDrawer(localStorage.getItem(drawerKey) === 'true')
  }, [dateRangeChallenge?.id])

  if (loading) {
    return (
      <ShimmerComponent
        height={200}
        css={css`
          border-radius: 10px;
        `}
        className={className}
      />
    )
  }

  if (!dateRangeChallenge) {
    return null
  }

  const {
    backgroundImageUrl,
    ticketTiers,
    participantCount,
    prizeImageUrl,
    description,
    partnerLogoUrl,
    ticketCount,
  } = dateRangeChallenge
  const backgroundColor = dateRangeChallenge.backgroundColor || colors.black

  const userCompletedQuizzes =
    dateRangeChallenge.viewerEntry?.quizzesCompleted || 0

  const highestCompletedTier = findLast(ticketTiers, (tier) => {
    return tier.quizzesPlayed <= userCompletedQuizzes
  })

  const lowestTier = ticketTiers[0]
  const chanceToWin = highestCompletedTier?.multiplier || 0

  return (
    <Flex column className={className}>
      <Flex
        css={css`
          height: 250px;
          position: relative;
          padding: 20px 15px;
          border-radius: 15px;
          filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.4));
          background-image: ${backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : 'none'};

          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 2;
        `}
        column
        horizontal="center"
        gap="medium"
      >
        <Flex
          css={css`
            position: absolute;
            top: -8px;
            left: -8px;
            padding: 4px 16px;
            background-color: ${colors.yellow400};
            border-radius: 16px;
            box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.25)};
          `}
        >
          <TextNew
            strong
            italic
            condensed
            css={css`
              font-size: 16px;
              text-transform: capitalize;
            `}
          >
            {t(`New challenge`)}
          </TextNew>
        </Flex>

        <GradientOverlay
          gradient={`background: linear-gradient(171deg, ${hexToRgba(
            backgroundColor,
            0.8
          )} 26.92%, ${hexToRgba(backgroundColor, 0)} 115.01%);`}
        />

        <DeadlineComponent
          availableTo={dateRangeChallenge.availableTo}
          textColor={hexOpacity(colors.white, 0.9)}
          css={css`
            position: absolute;
            bottom: 12px;
            left: 12px;
          `}
        />

        <Flex
          column
          horizontal="flex-end"
          css={css`
            position: absolute;
            bottom: 12px;
            right: 12px;
          `}
        >
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >
            {`${Intl.NumberFormat('de-DE', {
              style: 'decimal',
            })
              .format(participantCount)
              .toString()
              .replaceAll('.', ' ')} ${t('players')}`}
          </TextNew>
          <TextNew
            color={hexOpacity(colors.white, 0.9)}
            weight={700}
            italic
            shadow
            size={12}
            css={css`
              text-transform: uppercase;
            `}
          >{`${Intl.NumberFormat('de-DE', {
            style: 'decimal',
          })
            .format(ticketCount)
            .toString()
            .replaceAll('.', ' ')} 🎟️`}</TextNew>
        </Flex>

        <Flex
          vertical="center"
          horizontal="center"
          column
          grow
          gap={'small'}
          css={css`
            margin-top: ${highestCompletedTier ? 0 : -12}px;
            width: 100%;
          `}
        >
          {!dateRangeChallenge.viewerEntry && (
            <TextNew
              color={colors.yellow400}
              weight={700}
              semiCondensed
              italic
              shadow
              css={css`
                font-size: 15px;
                margin-bottom: 8px;
                @media (max-width: 599px) {
                  font-size: 12px;
                }
              `}
            >
              {t(`Complete {{count}} quizzes to qualify`, {
                count: lowestTier.quizzesPlayed - userCompletedQuizzes,
              })}
            </TextNew>
          )}
          <TicketTiersItem dateRangeChallenge={dateRangeChallenge} />
          <HeadingNew
            css={css`
              margin-top: 10px;
              max-width: 400px;
              display: flex;
              justify-content: center;
              text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
              font-size: 55px;
              white-space: nowrap;
              @media (max-width: 650px) {
                font-size: 44px;
              }
            `}
            italic
            weight={600}
            condensed
            level={2}
            looksLikeLevel={4}
            color={colors.white}
            textAlign="center"
          >
            {dateRangeChallenge.title}
          </HeadingNew>

          {!dateRangeChallenge.viewerEntry ? (
            <PrimaryButton
              signUpRequired
              onClick={async () => {
                await joinChallenge({
                  variables: {
                    input: {
                      challengeId: dateRangeChallenge.id,
                    },
                  },
                })
                trackEvent(AnalyticsEventType.JoinDateRangeChallenge, {
                  id: dateRangeChallenge.id,
                })
              }}
              css={css`
                background-color: ${colors.yellow400};
                min-height: 48px;
                border-radius: 48px;
              `}
            >
              <TextNew
                italic
                strong
                condensed
                color={colors.black}
                css={css`
                  white-space: nowrap;
                  text-transform: capitalize;

                  font-size: 22px;

                  @media (max-width: 599px) {
                    font-size: 18px;
                  }
                `}
              >
                {t('Join challenge')}
              </TextNew>
            </PrimaryButton>
          ) : (
            <TextNew
              color={colors.yellow400}
              weight={700}
              semiCondensed
              italic
              shadow
              css={css`
                font-size: 18px;
              `}
            >
              {highestCompletedTier
                ? t(`You have a {{count}}X chance to win!`, {
                    count: chanceToWin,
                  })
                : t(`Complete {{count}} quizzes to qualify`, {
                    count: lowestTier.quizzesPlayed - userCompletedQuizzes,
                  })}
            </TextNew>
          )}
        </Flex>
      </Flex>
      <Flex
        css={css`
          margin-top: ${hideDrawer ? '-155' : '-15'}px;
          max-width: 720px;
          flex-grow: 1;
          position: relative;

          padding: 30px 10px;

          border-radius: 10px;

          background-color: ${backgroundColor};
          border: 1px solid ${hexOpacity(colors.white, 0.2)};

          transition: margin-top 0.35s ease-in-out;
          min-height: ${hideDrawer ? 190 : 180}px;
          overflow: hidden;
        `}
        horizontal="center"
        gap="medium"
      >
        <Flex
          css={css`
            max-width: 400px;
            position: relative;
          `}
        >
          <Flex>
            {prizeImageUrl && (
              <Flex
                css={css`
                  flex: 1;
                `}
              >
                <img
                  src={prizeImageUrl}
                  css={css`
                    object-fit: contain;
                    position: absolute;
                    bottom: -40px;
                    left: -30px;
                    opacity: ${hideDrawer ? 0 : 1};
                    transition: opacity 0.35s ease-in-out;
                    scale: 1.4;
                    max-height: 150px;
                    @media (max-width: 650px) {
                      scale: 1.2;
                    }

                    @media (max-width: 550px) {
                      scale: 1;
                    }

                    @media (max-width: 400px) {
                      scale: 0.8;
                    }
                  `}
                />
              </Flex>
            )}
            <Flex
              column
              vertical="center"
              horizontal="flex-start"
              gap={'medium'}
              css={css`
                flex: 2;
                scale: ${hideDrawer ? '0.2' : '1'};
                transition: scale 0.35s ease-in-out;
              `}
            >
              <TextNew
                size={12}
                color={
                  colorIsLight(backgroundColor) ? colors.black : colors.white
                }
                css={css`
                  line-height: 15px;
                  background-color: ${backgroundColor};
                  border-radius: 10px;
                  letter-spacing: 0.025em;
                `}
              >
                {description}
              </TextNew>
              {partnerLogoUrl && (
                <Flex column horizontal="center" vertical="center">
                  <TextNew
                    color={colors.white}
                    size={10}
                    css={css`
                      text-transform: uppercase;
                      margin-bottom: -6px;
                      background-color: ${backgroundColor};
                      border-radius: 10px;
                    `}
                  >
                    {t('In partnership with')}
                  </TextNew>
                  <img
                    src={partnerLogoUrl}
                    css={css`
                      object-fit: contain;
                    `}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <CloseOpen
          itemKey={drawerKey}
          hidden={hideDrawer}
          setHidden={setHideDrawer}
        />
      </Flex>
    </Flex>
  )
}

const TicketTiersItem = ({
  dateRangeChallenge,
  className,
}: {
  dateRangeChallenge: DateRangeChallenge
  className?: string
}) => {
  const tiers = dateRangeChallenge.ticketTiers

  const tierColors = ['#49C7B8', '#FFCB7C', '#FF7CC4', '#FF8C3C', '#FF69B4']

  if (tiers.length === 0) {
    return null
  }

  return (
    <Flex
      css={css`
        width: 100%;

        max-width: 400px;

        @media (max-width: 650px) {
          max-width: 300px;
        }
      `}
      className={className}
    >
      {tiers.map((tier, index) => {
        const isFirst = index === 0
        const isLast = index === tiers.length - 1

        const isMiddle = !isFirst && !isLast

        const tierColor = tierColors[index]
        const darkTierColor = darkenColor(tierColor, 100)

        return (
          <Flex
            column
            horizontal={
              isMiddle ? 'center' : isLast ? 'flex-end' : 'flex-start'
            }
            gap={'tiny'}
            key={tier.id}
            css={css`
              flex: 1;
            `}
          >
            <TextNew
              size={10}
              color="white"
              italic
              semiCondensed
            >{`${tier.quizzesPlayed} quizzes: 🎟️ x ${tier.multiplier}`}</TextNew>
            <div
              css={css`
                background-color: ${darkTierColor};
                width: 103%;
                height: 6px;
                border: 1px solid ${colors.black};
                position: relative;
                border-radius: 10px;
                z-index: ${3 - index};
              `}
            >
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  background-color: ${tierColor};
                  width: ${clamp(tier.progress, 0, 100)}%;
                  height: 100%;
                  border-radius: 10px;
                `}
              />
            </div>
          </Flex>
        )
      })}
    </Flex>
  )
}

const darkenColor = (color: string, amount: number) => {
  const { r, g, b } = hexToRgb(color)

  return rgbToHex(
    Math.max(0, r - amount),
    Math.max(0, g - amount),
    Math.max(0, b - amount)
  )
}

const hexToRgb = (hex: string) => {
  const sanitizedHex = hex.replace('#', '')
  const r = parseInt(sanitizedHex.substring(0, 2), 16)
  const g = parseInt(sanitizedHex.substring(2, 4), 16)
  const b = parseInt(sanitizedHex.substring(4, 6), 16)
  return { r, g, b }
}

const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
