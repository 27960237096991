/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isDevelopment } from '../config/config'
import { useAuthContext } from '../lib/AuthContext'
import { useT } from '../lib/i18n/useT'
import { margin } from '../styles/margin'
import { SecondaryButton } from './Button'
import { RoundedBox } from './RoundedBox'
import { Text } from './Text'
import { Flex } from './layout/Flex'

export const DefaultError = ({
  title,
  message,
  className,
  sentryErrorMessage,
}: {
  title?: string
  message?: string
  className?: string
  sentryErrorMessage: string // format as "Page | identifier | reason"
}) => {
  const t = useT()
  const navigate = useNavigate()
  const { authUser, signout } = useAuthContext()
  const isLoggedIn = Boolean(authUser)

  title = title ?? t('Something went wrong')
  message = message ?? t('Try again, or go back')

  // This component should ideally never be rendered, but if it is, we want to
  // capture the error in Sentry
  useEffect(() => {
    Sentry.captureException(sentryErrorMessage, {
      tags: {
        sentryError: 'DefaultComponentError',
      },
    })

    if (isDevelopment) {
      console.error('DefaultError sentryErrorMessage:', sentryErrorMessage)
    }
  }, [sentryErrorMessage])

  return (
    <Flex horizontal="center" column className={className}>
      <RoundedBox
        css={css`
          padding: 32px;
        `}
        shadow
      >
        <Flex column horizontal="center">
          <Text color="white" size="large">
            {title}
          </Text>
          <Text color="white" css={margin.top('tiny')}>
            {message}
          </Text>
          <SecondaryButton
            variant="white"
            onClick={() => navigate('/')}
            css={margin.top('large')}
          >
            {t('Go to the home page')}
          </SecondaryButton>
          {isLoggedIn && (
            <>
              <Text color="white" css={margin.vertical('small')}>
                {t('or')}
              </Text>
              <SecondaryButton
                variant="white"
                onClick={() =>
                  signout().then(() => {
                    navigate('/')
                  })
                }
              >
                {t('Log out')}
              </SecondaryButton>
            </>
          )}
        </Flex>
      </RoundedBox>
    </Flex>
  )
}
