/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useT } from '../../lib/i18n/useT'
import { buttonReset, prettyLetterSpacing } from '../../styles/styles'
import {
  GlobalLeaderboardType,
  useFriendsLeaderboardQuery,
  useUsersGlobalLeaderboardQuery,
} from './queries'

import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Divider } from '../../components/Divider'
import { TextNew } from '../../components/TextNew'
import { useAuthContext } from '../../lib/AuthContext'
import { Spacer } from '../../styles/margin'
import { colors, hexOpacity } from '../../styles/theme'
import { UserAvatar } from '../Avatar/UserAvatar'
import { ShimmerComponent } from '../Home/ShimmerComponent'
import { LeaderboardItem } from '../League/queries'

const prettifySearchParam = (type: GlobalLeaderboardType) => {
  switch (type) {
    case GlobalLeaderboardType.ALL_TIME:
      return 'all-time'
    case GlobalLeaderboardType.THIS_YEAR:
      return 'this-year'
    case GlobalLeaderboardType.THIS_MONTH:
      return 'this-month'
    case GlobalLeaderboardType.THIS_WEEK:
      return 'this-week'
    case GlobalLeaderboardType.TODAY:
      return 'today'
    default:
      return 'today'
  }
}

const fromPrettifiedSearchParam = (type?: string | null) => {
  switch (type) {
    case 'all-time':
      return GlobalLeaderboardType.ALL_TIME
    case 'this-year':
      return GlobalLeaderboardType.THIS_YEAR
    case 'this-month':
      return GlobalLeaderboardType.THIS_MONTH
    case 'this-week':
      return GlobalLeaderboardType.THIS_WEEK
    case 'today':
      return GlobalLeaderboardType.TODAY
    default:
      return GlobalLeaderboardType.TODAY
  }
}

const Content = () => {
  const t = useT()

  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get('type')

  const [variant, setVariant] = useState<'players' | 'friends'>(
    (searchParams.get('variant') as 'players' | 'friends') ?? 'players'
  )

  const [entries, setEntries] = useState<LeaderboardItem[]>([])

  const [leaderboardType, setLeaderboardType] = useState<GlobalLeaderboardType>(
    fromPrettifiedSearchParam(type)
  )

  useEffect(() => {
    setSearchParams({ variant, type: prettifySearchParam(leaderboardType) })
  }, [leaderboardType, variant, setSearchParams])

  const { authUser } = useAuthContext()

  const query = useUsersGlobalLeaderboardQuery(
    {
      type: leaderboardType,
      limit: 100,
    },
    variant !== 'players'
  )

  const friendsQuery = useFriendsLeaderboardQuery(
    {
      type: leaderboardType,
    },
    variant !== 'friends'
  )

  useEffect(() => {
    setEntries([])
    if (variant === 'players' && query.data) {
      setEntries(query.data.usersGlobalLeaderboard)
      return
    }

    if (variant === 'friends' && friendsQuery.data) {
      setEntries(friendsQuery.data.friendsLeaderboard)
      return
    }
  }, [query.data, friendsQuery.data, variant])

  const textColor = colors.white

  const topEntries = entries.filter((entry) => entry.rank <= 3)
  const otherEntries = entries.filter((entry) => entry.rank > 3)

  const loading = query.loading || friendsQuery.loading

  return (
    <Flex column>
      <PageContent>
        <HeadingNew
          level={1}
          looksLikeLevel={1}
          italic
          strong
          condensed
          textAlign="center"
          color={textColor}
          css={css``}
        >
          {t('Leaderboard')}
        </HeadingNew>
        <Spacer height={32} />

        <Flex wrap gap={'medium'} horizontal="center">
          <button
            css={[
              buttonReset,
              css`
                display: flex;
              `,
            ]}
            onClick={() => setVariant('players')}
          >
            <TextNew
              color={hexOpacity(textColor, variant === 'players' ? 1 : 0.8)}
              italic
              strong
              condensed
              css={css`
                font-size: 28px;
                text-decoration: ${variant === 'players'
                  ? 'underline'
                  : 'none'};
              `}
            >
              {t('All players')}
            </TextNew>
          </button>

          <button
            css={[
              buttonReset,
              css`
                display: flex;
              `,
            ]}
            onClick={() => setVariant('friends')}
          >
            <TextNew
              color={hexOpacity(textColor, variant === 'friends' ? 1 : 0.8)}
              italic
              strong
              condensed
              css={css`
                font-size: 28px;
                text-decoration: ${variant === 'friends'
                  ? 'underline'
                  : 'none'};
              `}
            >
              {t('Friends')}
            </TextNew>
          </button>
        </Flex>

        <Divider
          color={textColor}
          css={css`
            margin: 24px 0;
          `}
        />

        <Flex wrap gap={'medium'} horizontal="center">
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.TODAY}
            leaderboardType={GlobalLeaderboardType.TODAY}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_WEEK}
            leaderboardType={GlobalLeaderboardType.THIS_WEEK}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_MONTH}
            leaderboardType={GlobalLeaderboardType.THIS_MONTH}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.THIS_YEAR}
            leaderboardType={GlobalLeaderboardType.THIS_YEAR}
            setLeaderboardType={setLeaderboardType}
          />
          <FrequencyButton
            textColor={textColor}
            selected={leaderboardType === GlobalLeaderboardType.ALL_TIME}
            leaderboardType={GlobalLeaderboardType.ALL_TIME}
            setLeaderboardType={setLeaderboardType}
          />
        </Flex>

        <Spacer height={24} />

        {loading && (
          <Flex column horizontal="center" gap={'tiny'}>
            <ShimmerComponent
              height={58}
              css={css`
                border-radius: 64px;
                width: 100%;
              `}
            />
            <ShimmerComponent
              height={50}
              css={css`
                border-radius: 64px;
                width: 95%;
              `}
            />
            <ShimmerComponent
              height={44}
              css={css`
                border-radius: 64px;
                width: 93%;
              `}
            />

            <Spacer height={12} />

            {Array.from({ length: 20 }, (_, i) => i).map(
              (_, index) => (
                <ShimmerComponent
                  key={index}
                  height={40}
                  css={css`
                    border-radius: 64px;
                    width: 90%;
                  `}
                />
              ),
              []
            )}
            <Spacer height={12} />
          </Flex>
        )}

        {!loading && (
          <Flex column horizontal="center" gap={'tiny'}>
            {topEntries.map((entry, index) => {
              const isAuthUser = authUser?.uid === entry.user.id

              return (
                <GlobalLeaderboardItemComponent
                  key={`${index}_${entry.user.id}`}
                  item={entry}
                  index={index}
                  isAuthUser={isAuthUser}
                />
              )
            })}

            <Spacer height={12} />

            {otherEntries.map((entry, index) => {
              const isAuthUser = authUser?.uid === entry.user.id

              return (
                <GlobalLeaderboardItemComponent
                  key={`${index}_${entry.user.id}`}
                  item={entry}
                  index={index}
                  isAuthUser={isAuthUser}
                />
              )
            })}
          </Flex>
        )}

        {!loading && entries.length === 0 && (
          <TextNew
            italic
            color={hexOpacity(textColor, 0.8)}
            textAlign="center"
            css={css`
              letter-spacing: ${prettyLetterSpacing};
            `}
          >
            {variant === 'friends'
              ? t(
                  `If you don't have any friends yet, add some to see them here!`
                )
              : t(`No data available.`)}
          </TextNew>
        )}
        {entries.length > 0 && variant === 'players' && (
          <>
            <Spacer height={24} />
            <TextNew
              italic
              color={hexOpacity(textColor, 0.8)}
              textAlign="center"
              css={css`
                letter-spacing: ${prettyLetterSpacing};
              `}
            >
              {t(`The leaderboards are updated every hour.`)}
            </TextNew>
          </>
        )}
      </PageContent>
      <Spacer height={80} />
    </Flex>
  )
}

export const LeaderboardsPage = () => {
  const t = useT()

  return (
    <Layout
      title={`fcQuiz | ${t('Leaderboard')}`}
      backgroundColor={colors.black}
    >
      <Flex column grow>
        <Content />
      </Flex>
    </Layout>
  )
}

export const FrequencyButton = ({
  textColor,
  selected,
  leaderboardType,
  setLeaderboardType,
}: {
  textColor: string
  selected: boolean
  leaderboardType: GlobalLeaderboardType
  setLeaderboardType: (type: GlobalLeaderboardType) => void
}) => {
  const t = useT()

  const label = () => {
    switch (leaderboardType) {
      case GlobalLeaderboardType.ALL_TIME:
        return t('All time')
      case GlobalLeaderboardType.THIS_YEAR:
        return t('This year')
      case GlobalLeaderboardType.THIS_MONTH:
        return t('This month')
      case GlobalLeaderboardType.THIS_WEEK:
        return t('This week')
      case GlobalLeaderboardType.TODAY:
        return t('Today')
    }
  }
  return (
    <button
      css={[
        buttonReset,
        css`
          display: flex;
        `,
      ]}
      onClick={() => {
        setLeaderboardType(leaderboardType)
      }}
    >
      <TextNew
        color={hexOpacity(textColor, selected ? 1 : 0.7)}
        italic
        strong
        condensed
        css={css`
          font-size: 24px;
          text-decoration: ${selected ? 'underline' : 'none'};
        `}
      >
        {label()}
      </TextNew>
    </button>
  )
}

export const GlobalLeaderboardItemComponent = ({
  item,
  isAuthUser,
  index,
  className,
  clickable = true,
}: {
  item: LeaderboardItem
  isAuthUser: boolean
  index: number
  className?: string
  clickable?: boolean
}) => {
  const t = useT()
  const itemHeight = () => {
    if (item.rank === 1) {
      return 58
    }

    if (item.rank === 2) {
      return 50
    }

    if (item.rank === 3) {
      return 44
    }

    return 40
  }

  const itemWidth = () => {
    if (item.rank === 1) {
      return '100%'
    }

    if (item.rank === 2) {
      return '95%'
    }

    if (item.rank === 3) {
      return '93%'
    }

    return '90%'
  }

  const getBackgroundColor = () => {
    if (isAuthUser) {
      return colors.green200
    }

    if (item.rank === 1) {
      return colors.gold
    }

    if (item.rank === 2) {
      return colors.silver
    }

    if (item.rank === 3) {
      return colors.bronze
    }

    return index % 2 === 0 ? colors.white : hexOpacity(colors.blue100, 0.95)
  }

  const getTextStyle = () => {
    if (item.rank === 1) {
      return css`
        font-size: 28px;
        line-height: 28px;

        @media (max-width: 599px) {
          font-size: 18px;
          line-height: 20px;
        }
      `
    }

    if (item.rank === 2) {
      return css`
        font-size: 24px;
        line-height: 24px;

        @media (max-width: 599px) {
          font-size: 16px;
          line-height: 16px;
        }
      `
    }

    if (item.rank === 3) {
      return css`
        font-size: 22px;
        line-height: 22px;

        @media (max-width: 599px) {
          font-size: 16px;
          line-height: 16px;
        }
      `
    }

    return css`
      font-size: 20px;
      line-height: 20px;

      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 16px;
      }
    `
  }

  const textStyle = getTextStyle()

  const height = itemHeight()
  const username = item.user.displayName ?? item.user.username

  const Content = () => {
    return (
      <Flex
        vertical="center"
        className={className}
        css={css`
          background-color: ${getBackgroundColor()};
          border-radius: 64px;
          padding-right: 16px;
          padding-left: 12px;
          height: ${height}px;
        `}
      >
        <Flex
          horizontal="center"
          css={css`
            min-width: 24px;
          `}
        >
          <TextNew
            weight={800}
            condensed
            strong
            css={[
              css`
                font-style: italic;
              `,
              textStyle,
            ]}
          >
            {item.rank}
          </TextNew>
        </Flex>

        <Spacer width={item.rank > 99 ? 12 : 8} />
        <UserAvatar
          avatarData={item.user.avatarData}
          height={height}
          clickable={clickable}
        />
        <Spacer width={12} />

        <TextNew
          strong
          italic
          textAlign="left"
          css={[
            css`
              padding: 0 8px;
              font-size: 18px;
              line-height: 1.2;
              font-style: italic;
              overflow: hidden;
              text-overflow: ellipsis;
              letter-spacing: 0.08%;
              white-space: nowrap;
              height: 100%;
              display: flex;
              align-items: center;
            `,
            textStyle,
          ]}
        >
          {username}
        </TextNew>

        <TextNew
          condensed
          strong
          italic
          textAlign="center"
          css={[
            css`
              margin-left: auto;
              font-style: italic;
              padding-bottom: 2px;
              //no newline
              white-space: nowrap;
            `,
            textStyle,
          ]}
        >
          {Intl.NumberFormat('de-DE', {
            style: 'decimal',
          })
            .format(item.score)
            .toString()
            .replaceAll('.', ' ')}
        </TextNew>
      </Flex>
    )
  }

  if (!clickable) {
    return (
      <div
        key={`${item.user.id}-${item.rank}-${index}`}
        css={css`
          width: ${itemWidth()};
          margin: 0 auto;
        `}
      >
        <Content />
      </div>
    )
  }

  return (
    <Link
      to={`/profile/${item.user.slug}`}
      aria-label={`${t('Go to profile')}: ${username}`}
      key={`${item.user.id}-${item.rank}-${index}`}
      css={css`
        width: ${itemWidth()};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      `}
    >
      <Content />
    </Link>
  )
}
