/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { Modal } from '../../components/modal/Modal'
import { useT } from '../../lib/i18n/useT'
import { useCompleteLiveQuizMutation } from '../LiveQuiz/queries'

export const CompleteLiveQuizModal = ({
  closeModal,
  participantCount,
  instanceId,
  startTime,
}: {
  closeModal: () => void
  participantCount: number
  instanceId: string
  startTime: string
}) => {
  const t = useT()

  const [userhasBeenWarned, setUserHasBeenWarned] = useState(false)

  const [completeQuizMutation] = useCompleteLiveQuizMutation()

  useEffect(() => {
    const timer = setTimeout(() => {
      setUserHasBeenWarned(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [userhasBeenWarned, closeModal])

  return (
    <Modal closeModal={closeModal} heading={t(`Close active live quiz`)}>
      <Flex column gap={'medium'}>
        <TextNew>
          {t(`The start time of this quiz is: {{startTime}}`, {
            startTime: new Date(startTime).toLocaleTimeString(),
          })}
        </TextNew>

        <TextNew>
          {t(`There are currently  {{count}} participants in this live quiz`, {
            count: participantCount,
          })}
        </TextNew>

        <PrimaryButton
          variant={userhasBeenWarned ? 'dangerRed' : 'black'}
          onClick={() => {
            if (!userhasBeenWarned) {
              setUserHasBeenWarned(true)
              return
            }

            completeQuizMutation({ variables: { input: { instanceId } } }).then(
              () => closeModal()
            )
          }}
        >
          {userhasBeenWarned ? t('Are you sure?') : t('Close quiz')}
        </PrimaryButton>
      </Flex>
    </Modal>
  )
}
