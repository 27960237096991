/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { FC, ReactNode, useCallback, useState } from 'react'
import { margin } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { Heading } from '../Heading'
import { CrossMobileFriendy } from '../icons'
import { Flex } from '../layout/Flex'

type ModalProps = {
  heading?: string
  closeModal: () => void
  maxWidth?: number
  styling?: SerializedStyles
  textColor?: string
  className?: string
  transparent?: boolean
  fullWidth?: boolean
  hideHeading?: boolean
  backgroundColor?: string
  hideCross?: boolean
  borderRadius?: string
}
export const Modal: FC<ModalProps> = ({
  heading,
  closeModal,
  maxWidth = 600,
  styling,
  children,
  textColor,
  className,
  transparent,
  fullWidth,
  hideHeading,
  backgroundColor = 'white',
  hideCross = false,
  borderRadius = '8px',
}) => {
  return (
    <DialogOverlay
      style={{ zIndex: 1000 }}
      isOpen={true}
      onDismiss={closeModal}
      className={className}
      css={css`
        backdrop-filter: blur(${transparent ? 50 : 5}px);
      `}
    >
      <DialogContent
        id="modalcontent"
        aria-label={heading}
        css={[
          css`
            &[data-reach-dialog-content] {
              width: ${maxWidth}px;
              padding: 16px;
              padding-bottom: 20px;
              border-radius: ${borderRadius};
              background-color: ${transparent
                ? 'transparent'
                : backgroundColor};
            }
            ${!fullWidth &&
            css`
              @media (max-width: ${maxWidth + 32}px) {
                &[data-reach-dialog-content] {
                  width: calc(100% - 32px);
                  margin-left: 16px;
                  margin-right: 16px;
                }
              }
            `}
          `,
          styling,
        ]}
      >
        {!hideCross && (
          <Flex
            horizontal="flex-end"
            vertical="flex-start"
            css={margin.bottom()}
          >
            <button
              css={[
                buttonReset,
                css`
                  color: ${textColor};
                `,
              ]}
              // Fixes the annoying blue border on load
              ref={(it) =>
                setTimeout(() => {
                  it?.blur()
                }, 0)
              }
              onClick={closeModal}
            >
              <CrossMobileFriendy />
            </button>
          </Flex>
        )}
        {heading && !hideHeading && (
          <Heading
            level={2}
            looksLikeLevel={3}
            css={css`
              padding-bottom: 20px;
              color: ${textColor};
            `}
          >
            {heading}
          </Heading>
        )}
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export type ModalTriggerProps = {
  button: (params: { openModal: () => void }) => ReactNode
  modal: (params: { closeModal: () => void }) => ReactNode
  forceIsOpen?: boolean
}
export const ModalTrigger = ({
  button,
  modal,
  forceIsOpen = false,
}: ModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(forceIsOpen)

  const openModal = useCallback(() => {
    setIsOpen(true)
  }, [])
  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      {button({ openModal })}
      {isOpen && modal({ closeModal })}
    </>
  )
}
