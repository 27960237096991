export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('android') > -1
}

export function isIos() {
  const ua = navigator.userAgent.toLowerCase()
  return /iPad|iPhone|iPod/.test(ua)
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase()
  return !!ua.match(/version\/[\d\.]+.*safari/)
}

export function isChrome() {
  //@ts-ignore
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  //@ts-ignore
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1

  if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true
  }
  return false
}
