import { gql, useQuery } from '@apollo/client'
import {
  HomePageLeague,
  homePageLeagueFragment,
  RecommendedLeagueQuiz,
} from '../Home/queries'
import { sortLeagues } from '../Home/HomePage'
import { useMemo } from 'react'

export type LibraryQueryResult = {
  leagues: HomePageLeague[]
}

export type QuizCollection = {
  id: string
  title: string
  description?: string
  quizzes: RecommendedLeagueQuiz[]
}

const libraryLeagueQuery = gql`
  query libraryLeague {
    leagues(input: { status: active }) {
      ...HomePageLeagueFragment
    }
  }
  ${homePageLeagueFragment}
`

export const useLibraryLeagueQuery = () => {
  const query = useQuery<LibraryQueryResult>(libraryLeagueQuery, {
    fetchPolicy: 'cache-and-network',
  })

  return useMemo(() => {
    return {
      data: query.data ? sortLeagues(query.data!.leagues) : [],
    }
  }, [query.data])
}

const libraryContentQuery = gql`
  query library($leagueCategory: String!) {
    library(leagueCategory: $leagueCategory) {
      quizCollections {
        id
        title
        description
        quizzes {
          id
          title
          slug
          availableFrom
          availableTo
          viewerCanStartQuiz
          league {
            id
            slug
            title
            status
            availableFrom
            availableTo
          }
          viewerData {
            canStartQuiz
            hasCompletedQuiz
            activeQuizInstance {
              id
            }
            result {
              leagueQuizInstanceId
              correctAnswersCount
              correctAnswerScore
              timeBonus
              totalScore
            }
          }
        }
      }
    }
  }
`

export type LibraryContent = {
  quizCollections: QuizCollection[]
}

export const useLibraryContentQuery = (leagueCategory: string) => {
  const query = useQuery<{
    library: LibraryContent
  }>(libraryContentQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { leagueCategory },
    skip: leagueCategory === '',
  })

  return useMemo(() => {
    if (!query.data) {
      return { data: [] }
    }

    const quizCollections = [...query.data.library.quizCollections]

    const quizCollectionsSorted: QuizCollection[] = []

    for (const quizCollection of quizCollections) {
      quizCollectionsSorted.push({
        id: quizCollection.id,
        title: quizCollection.title,
        description: quizCollection.description,
        quizzes: [...quizCollection.quizzes].sort((a, b) => {
          const viewerCanStartQuizA = a.viewerCanStartQuiz
          const viewerCanStartQuizB = b.viewerCanStartQuiz

          if (viewerCanStartQuizA && !viewerCanStartQuizB) {
            return -1
          }

          if (!viewerCanStartQuizA && viewerCanStartQuizB) {
            return 1
          }

          return 0
        }),
      })
    }

    return {
      data: quizCollectionsSorted,
    }
  }, [query.data])
}
