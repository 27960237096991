/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '../../../components/Text'
import { Flex } from '../../../components/layout/Flex'
import { useT } from '../../../lib/i18n/useT'
import { colors } from '../../../styles/theme'

import { MdLockOutline } from 'react-icons/md'
import { ClubLogo } from '../../../components/Picture'
import { Score } from '../../../components/Score'
import { TextNew } from '../../../components/TextNew'
import { Verified } from '../../../components/icons'
import { Spacer } from '../../../styles/margin'
import { boxShadows } from '../../../styles/theme'
import { UserAvatar } from '../../Avatar/UserAvatar'
import { Club } from '../../Clubs/queries'
import { ClubItem } from '../../Home/ClubItem'
import { ClubLeaderboardEntry, LeaderboardItem } from '../queries'

type LeaderboardItemComponentProps = {
  item?: LeaderboardItem
  clubEntry?: ClubLeaderboardEntry
  isViewer: boolean
  showTrophies?: boolean
  className?: string
  height?: number
  backgroundColor?: string
  clickable?: boolean
  small?: boolean
}
export const LeaderboardItemComponent = memo(function LeaderboardItemComponent({
  item,
  clubEntry,
  isViewer,
  showTrophies,
  className,
  height = 86,
  backgroundColor,
  clickable = true,
  small,
}: LeaderboardItemComponentProps) {
  const t = useT()

  const linkTo = () => {
    if (item) {
      return `/profile/${item.user.id}`
    }
    if (clubEntry) {
      return `/clubs/${clubEntry.club.id}`
    }
    return ''
  }

  const Content = () => (
    <Flex
      vertical="center"
      horizontal="flex-start"
      css={css`
        border-radius: ${small ? '99px' : '2px'};
        padding: 12px 7px;
        background-color: ${backgroundColor
          ? backgroundColor
          : isViewer || (clubEntry && clubEntry.club.viewerIsMember)
          ? colors.yellow200
          : 'white'};
        box-shadow: ${small
          ? `0px 2px 4px 0px rgba(0, 0, 0, 0.25);`
          : boxShadows.default};
        height: ${height}px;

        /* This took forever to figure out */
        * {
          flex-shrink: 1;
          min-width: 0;
        }
        span {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}
    >
      <Flex
        css={css`
          flex: 1;
        `}
        vertical="center"
      >
        <Text
          css={css`
            width: 30px;
            text-align: center;
            flex-shrink: 0;
          `}
          italic
          size="medium"
          strong
        >
          {item?.rank ?? clubEntry?.rank}
        </Text>
        {item && (
          <Flex vertical="center">
            <UserAvatar
              css={css`
                z-index: 1;
                width: ${height}px;
                margin-top: -10px;
              `}
              avatarData={item.user.avatarData}
              height={height + 10}
              clickable={clickable}
            />
            <Spacer width={8} />
            <Flex
              column
              horizontal="flex-start"
              css={css`
                flex: 1;
                position: relative;
              `}
              vertical="center"
            >
              <TextNew condensed size={'medium'} italic strong spacingEm={0}>
                {
                  item.user.displayName ?? t('Anonymous player') // text-transform: uppercase makes the text-overflow not work
                }
              </TextNew>
              {!small && item.user.mainClubMembership && (
                <ClubItem
                  clickable={clickable}
                  club={item.user.mainClubMembership.club as Club}
                  size={small ? 'tiny' : 'small'}
                  css={css`
                    width: 100%;
                  `}
                />
              )}
            </Flex>
          </Flex>
        )}

        {clubEntry && (
          <>
            <Spacer width={16} />
            <Flex vertical="center" gap="medium">
              <Flex vertical="center" gap="medium">
                <ClubLogo
                  size={40}
                  url={clubEntry.club.logoUrl}
                  shape={clubEntry.club.logoShape}
                />
                <Flex column>
                  <Flex vertical="center" gap="small">
                    <Text>{clubEntry.club.name}</Text>
                    <Flex vertical="center" gap="tiny">
                      {clubEntry.club.verified && (
                        <Verified height={15} width={12} />
                      )}
                      {!clubEntry.club.public && (
                        <MdLockOutline height={15} width={12} />
                      )}
                    </Flex>
                  </Flex>
                  <Text size="small">
                    {t('{{count}} member', {
                      count: clubEntry.club.membersCount,
                    })}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      <Score
        score={item?.score ?? clubEntry?.score ?? 0}
        trophy={item && showTrophies && item.rank <= 3}
      />
    </Flex>
  )

  if (clickable) {
    return (
      <div className={className}>
        <Link
          to={linkTo()}
          css={css`
            width: 100%;
            cursor: pointer;
            text-decoration: none;
          `}
        >
          <Content />
        </Link>
      </div>
    )
  }
  return (
    <div className={className}>
      <Content />
    </div>
  )
})
