import { useEffect } from 'react'
import { isProduction } from '../config/config'

declare global {
  interface Window {
    Cookiebot?: {
      renew?: () => void
      withdraw: () => void
      // Add other methods here if needed with appropriate signatures
      consent?: {
        necessary: boolean
        preferences: boolean
        statistics: boolean
        marketing: boolean
      }
      reviq?: any[]
    }
  }
  var reviq: any[]
}

//add reviq spesification to globalThis, not on window

export const setRevIqAdsEnabled = (value: boolean) => {
  console.log('setRevIqAdsEnabled', value)

  try {
    const reviq = globalThis.reviq || []
    reviq.push((reviq: any) => {
      reviq.setAdsEnabled(value)
    })
  } catch (error) {
    console.error(error)
  }
}

export const ThirdPartyScrips = () => {
  useEffect(() => {
    // Don't load Third party scripts on embed pages
    if (window.location.pathname.includes('embed')) {
      return
    }

    // Cookiebot
    const cookiescript = document.createElement('script')
    cookiescript.id = 'Cookiebot'
    cookiescript.src = 'https://consent.cookiebot.com/uc.js'
    cookiescript.dataset.cbid = 'a741317c-9f6b-4d0c-b59d-00bdf7264786'
    cookiescript.type = 'text/javascript'
    cookiescript.async = true

    document.head.appendChild(cookiescript)
    console.log('Cookiebot loaded')

    // RevIq
    if (!isProduction) {
      console.log('RevIq not loaded')
      return
    }

    const adScript = document.createElement('script')
    adScript.type = 'module'
    adScript.src = '//js.rev.iq'
    adScript.dataset.domain = 'fcquiz.app'
    document.head.appendChild(adScript)
    console.log('RevIq loaded')
  }, [])

  return null
}
