/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { QRCodeSVG } from 'qrcode.react'
import { colors } from '../styles/theme'
import { FcQuizLogo } from './icons'
import { Flex } from './layout/Flex'

export const FcQuizQrCode = ({
  url,
  backgroundColor = colors.black,
  foregroundColor = colors.white,
  logoScale = 1.5,
  hideFcQuizLogo = false,
  hideBoxBackground = false,
  className,
}: {
  url: string
  backgroundColor?: string
  foregroundColor?: string
  logoScale?: number
  hideFcQuizLogo?: boolean
  hideBoxBackground?: boolean
  className?: string
}) => {
  return (
    <Flex
      column
      horizontal="center"
      css={css`
        padding: 32px 32px 16px 32px;
        background-color: ${hideBoxBackground
          ? 'transparent'
          : backgroundColor};
        border-radius: 16px;
        width: 100%;
        height: 100%;
      `}
      className={className}
    >
      <QRCodeSVG
        value={url}
        fgColor={foregroundColor}
        bgColor={backgroundColor}
        css={css`
          width: 100%;
          height: 100%;
        `}
      />

      {!hideFcQuizLogo && (
        <FcQuizLogo
          color={foregroundColor}
          width={46 * logoScale}
          height={10 * logoScale}
          css={css`
            margin-top: 16px;
          `}
        />
      )}
    </Flex>
  )
}
