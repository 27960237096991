/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'
import { UserWithResult } from './components/ChallengeScoreboard'
import { ChallengeGroup } from './queries'

import {
  ChallengeMatchesComponent,
  MatchComponent,
} from '../ChallengeMatch/ChallengeMatchComponent'

import { buttonReset } from '../../styles/styles'

/** @jsxImportSource @emotion/react */
export const ChallengeGroupListComponent = ({
  group,
  userId,
  className,
  backgroundColor = colors.yellow100,
  onClick,
  buttonRole = 'navigation',
}: {
  group: ChallengeGroup
  userId: string
  className?: string
  backgroundColor?: string
  onClick?: () => void
  buttonRole?: string
}) => {
  const navigate = useNavigate()
  const t = useT()

  const isDuel = group.members.length === 2

  const duelTitle = isDuel
    ? `${group.members[0].displayName} vs  ${group.members[1].displayName}`
    : undefined

  const firstQuiz = group.quizzes[0]
  const firstQuizTitle = firstQuiz?.title

  const title = group.title ?? firstQuizTitle ?? duelTitle ?? t('Friend group')

  const firstQuizResults = firstQuiz?.challengeGroupResults ?? []
  const mapped: UserWithResult[] = firstQuizResults
    .map((result) => {
      const member = group.members.find((member) => member.id === result.userId)
      if (!member) {
        return undefined
      }
      return {
        userId: result.userId,
        displayName: member.displayName,
        avatarData: member.avatarData,
        result: {
          correctAnswersCount: result.correctAnswersCount ?? 0,
          correctAnswerScore: result.correctAnswerScore ?? 0,
          timeBonus: result.timeBonus ?? 0,
          totalScore: result.totalScore ?? 0,
        },
      } as UserWithResult
    })
    .filter((result) => result !== undefined) as UserWithResult[]

  const viewer = group.members.find((member) => member.id === userId)
  const viewerIsOwner = group.viewerIsOwner

  return (
    <button
      aria-label={title}
      role={buttonRole}
      css={[
        buttonReset,
        css`
          cursor: pointer;
        `,
      ]}
      onClick={() => {
        if (onClick) {
          onClick()
        } else {
          navigate(`/challenge-group/${group.id}`)
        }
      }}
      className={className}
    >
      {group.matches.length === 0 && (
        <MatchComponent
          title={title}
          firstuserResults={
            mapped.find((res) => res.userId === userId) ?? {
              userId,
              displayName: viewer?.displayName ?? '',
              avatarData: viewer?.avatarData,
              result: {
                correctAnswersCount: 0,
                correctAnswerScore: 0,
                timeBonus: 0,
                totalScore: 0,
              },
            }
          }
        />
      )}
      {group.matches.length === 1 && (
        <Flex
          css={css`
            width: 100%;
          `}
        >
          <ChallengeMatchesComponent
            results={mapped}
            matches={group.matches}
            title={firstQuizTitle}
            backgroundColor={backgroundColor}
          />
        </Flex>
      )}
      {group.matches.length > 1 && (
        <Flex
          column
          gap="medium"
          css={css`
            width: 100%;
            background-color: ${colors.purple500};
            padding: 16px;
            border-radius: 16px;
          `}
        >
          <Text strong condensed color="white">
            {t('Friend group')}
          </Text>

          <ChallengeMatchesComponent
            results={mapped}
            matches={group.matches}
            title={firstQuizTitle}
            backgroundColor={backgroundColor}
            limit={3}
            viewerIsOwner={viewerIsOwner}
            viewerId={userId}
          />
        </Flex>
      )}
    </button>
  )
}
