/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../../components/layout/Flex'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { useMyChallengeGroups } from './queries'
import { useAuthContext } from '../../lib/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useT } from '../../lib/i18n/useT'
import { PageContent } from '../../components/layout/PageContent'
import { SecondaryButton } from '../../components/Button'
import { ChallengeGroupListComponent } from './ChallengeGroupListComponent'

const UserChallengeList = ({
  limit,
  daysLimit,
  isOnHomePage = false,
}: {
  limit?: number
  daysLimit?: number
  isOnHomePage?: boolean
}) => {
  const { authUser, loading: authLoading } = useAuthContext()
  const { data: groups, loading } = useMyChallengeGroups({
    limit,
    daysLimit,
  })

  const t = useT()
  const navigate = useNavigate()

  if (loading || authLoading) {
    return (
      <Flex horizontal="center">
        <Loader />
      </Flex>
    )
  }

  const displayGroups = limit ? groups?.slice(0, limit) : groups

  if (!authUser || !displayGroups?.length) {
    return null
  }

  return (
    <Flex
      grow
      wrap
      gap="medium"
      horizontal="space-between"
      column
      css={css`
        width: 100%;
      `}
    >
      {isOnHomePage && (
        <PageContent>
          <Flex horizontal="space-between">
            <Text color="white" size="small">
              {t(`Recent challenges`)}
            </Text>

            <SecondaryButton
              variant="white"
              onClick={() => {
                navigate('/my-challenges')
              }}
            >
              <Text color="white" size="small">
                {t('View all')}
              </Text>
            </SecondaryButton>
          </Flex>
        </PageContent>
      )}
      <PageContent>
        {displayGroups.map((group) => (
          <ChallengeGroupListComponent
            group={group}
            userId={authUser.uid}
            key={group.id}
            css={css`
              margin-bottom: 16px;
            `}
          />
        ))}
      </PageContent>
    </Flex>
  )
}

export default UserChallengeList
