/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useInterval } from '../../utils/useInterval'
import { useRefValue } from '../../utils/useRefValue'
import { Text } from '../Text'
import {
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'

type Props = {
  seconds: number
  onFinished: () => void
  color?: string
}
export const SecondsCountdown = ({
  seconds,
  onFinished,
  color = 'white',
}: Props) => {
  const [remaining, setRemaining] = useState(seconds)
  const { setQuizState } = useLeagueQuizContext()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 1)
    },
    remaining === 0 ? null : 1000
  )

  const savedOnFinished = useRefValue(onFinished)
  useEffect(() => {
    if (remaining <= 0) {
      setQuizState(QuizState.ACTIVE_QUIZ)
      savedOnFinished.current()
    }
  }, [remaining, savedOnFinished])

  return (
    <Text
      strong
      css={css`
        font-size: 144px;
        color: ${color};
        text-align: center;
        margin-bottom: 86px;
      `}
    >
      {remaining}
    </Text>
  )
}
