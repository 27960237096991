/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PrimaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { PageContent } from '../../components/layout/PageContent'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { ModalTrigger } from '../../components/modal/Modal'
import { Text } from '../../components/Text'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'

export const JoinTheTournament = () => {
  const { authUser } = useAuthContext()
  const t = useT()

  return (
    <PageContent>
      <Text
        color="white"
        size="small"
        css={css`
          text-transform: uppercase;
        `}
      >
        {t('Join the tournament')}
      </Text>

      <Flex
        column
        css={[
          margin.vertical('medium', 'huge'),
          css`
            padding: 20px;
            background: url('/images/braden-hopkins.webp');
            background-position: center;
            position: relative;
            background-size: cover;
            border-radius: 4px;
          `,
        ]}
      >
        <div
          css={css`
            position: absolute;
            inset: 0;
            background: linear-gradient(rgba(0, 0, 0, 0.1), black);
            z-index: 1;
          `}
        />
        <Flex
          column
          horizontal="flex-start"
          css={css`
            z-index: 2;
            margin-top: 100px;
          `}
        >
          <Text
            color="white"
            condensed
            wide
            css={css`
              font-size: 21px;
              font-weight: 800;
            `}
          >
            {t('WIN YOUR FAVOURITE TEAM JERSEY')}
          </Text>

          <Text
            color="white"
            size="small"
            css={css`
              max-width: 540px;
            `}
          >
            {t(
              '2 lucky winners will be get a favourite team jersey with printing of your choice. All you have to do is sign up, and register your e-mail with LaLiga. '
            )}
          </Text>

          <ModalTrigger
            button={({ openModal }) => (
              <PrimaryButton
                variant="white"
                onClick={() => {
                  if (Boolean(authUser && !authUser.isAnonymous)) {
                    window.open(
                      'https://play.laliga.es/widget/promos/fcquiz',
                      '_blank'
                    )
                  } else {
                    openModal()
                  }
                }}
                css={margin.top()}
              >
                {Boolean(authUser && !authUser.isAnonymous)
                  ? t('Participate')
                  : t('Sign up to participate')}
              </PrimaryButton>
            )}
            modal={({ closeModal }) => (
              <LoginModalInner closeModal={closeModal} />
            )}
          />
        </Flex>
      </Flex>
    </PageContent>
  )
}
