/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { Flex } from '../../components/layout/Flex'
import { Loader } from '../../components/Loader'
import { Modal } from '../../components/modal/Modal'
import { ErrorMessage, TextInput } from '../../components/TextInput'
import { TextNew } from '../../components/TextNew'
import { sanityRootUrl } from '../../config/config'
import { ErrorCode, hasErrorCode } from '../../lib/apollo/apiError'
import { useViewerQuery } from '../../lib/AuthContext'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useForm } from '../../utils/useForm'
import {
  useAddSubscriptionToChallengeGroupMutation,
  useChallengeLeagueOwnerQuery,
} from '../Challenge/queries'

export const ManageLeagueSubscriptionsModal = ({
  closeModal,
  slug,
}: {
  closeModal: () => void
  slug: string
}) => {
  const viewerQuery = useViewerQuery()
  const leagueQuery = useChallengeLeagueOwnerQuery(slug)
  const league = leagueQuery.data?.group

  const [addSubscription] = useAddSubscriptionToChallengeGroupMutation()

  const form = useForm({
    initialValues: {
      sanityLeagueId: '',
    },
    validate: {
      sanityLeagueId: (value) => {
        if (!value) {
          return 'Please enter a id'
        }
        //must be the length of a uuid
        if (value.length !== 36) {
          return 'Invalid quiz id'
        }
      },
    },
    onSubmit: async ({ values, setSubmitError }) => {
      if (!league) {
        return
      }

      setSubmitError(undefined)

      try {
        await addSubscription({
          variables: {
            input: {
              groupId: league.id,
              leagueId: values.sanityLeagueId,
            },
          },
        })
        closeModal()
      } catch (error) {
        if (hasErrorCode(error, ErrorCode.ALREADY_SUBSCRIBED)) {
          setSubmitError('League already subscribed')
        } else if (hasErrorCode(error, ErrorCode.NOT_FOUND)) {
          setSubmitError('League not found')
        } else {
          setSubmitError('Something went wrong, try again')
        }
      }
    },
  })

  if (!league) {
    return (
      <Modal closeModal={closeModal} heading={'Loading'}>
        <Loader />
      </Modal>
    )
  }

  const viewerIsAdmin = viewerQuery.data?.viewer?.isAdmin

  const subscriptions = league.subscribedLeagues || []

  return (
    <Modal closeModal={closeModal} heading={'Manage subscriptions'}>
      <Flex column>
        <Flex column gap={'small'}>
          <TextNew>{'Current subscriptions'}</TextNew>

          {subscriptions.map((subscribedLeague) => (
            <Flex
              key={subscribedLeague.id}
              gap={'small'}
              horizontal="space-between"
              vertical="center"
              css={css`
                padding: 16px;
                border-radius: 4px;
                border: 1px solid ${colors.grey300};
              `}
            >
              <Flex column gap={'small'}>
                <TextNew strong>{subscribedLeague.title}</TextNew>
                <TextNew condensed>{subscribedLeague.id}</TextNew>
              </Flex>
              {viewerIsAdmin && (
                <Link
                  to={sanityRootUrl + 'league;' + subscribedLeague.id}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  <TextNew>Sanity</TextNew>
                </Link>
              )}
            </Flex>
          ))}
        </Flex>
        <Spacer height={'medium'} />
        <Flex
          column
          css={css`
            padding: 16px;
            background-color: ${colors.grey200};
            border-radius: 4px;
          `}
        >
          <TextNew>{'Add new subscription to league'}</TextNew>

          <Spacer height={'medium'} />
          <form
            onSubmit={(event) => {
              event.preventDefault()
              form.submitForm()
            }}
          >
            <Flex column gap={'small'}>
              <TextInput
                id="sanityLeagueId"
                name="sanityLeagueId"
                placeholder="League ID from Sanity"
                autoComplete="off"
                value={form.values.sanityLeagueId}
                onValue={(val) => {
                  form.setValue('sanityLeagueId', val)
                }}
                error={form.submitAttempted && form.fieldErrors.sanityLeagueId}
              />
              <PrimaryButton type="submit" loading={form.submitting}>
                Add
              </PrimaryButton>

              {form.submitError && (
                <div css={margin.top()}>
                  <ErrorMessage>{form.submitError}</ErrorMessage>
                </div>
              )}
            </Flex>
          </form>
        </Flex>
      </Flex>
    </Modal>
  )
}
