/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { shuffle } from 'shuffle-seed'
import { useAuthContext } from '../../lib/AuthContext'
import {
  SanityLeagueQuiz,
  SanityLeagueQuizCampaign,
  SanityQuestion,
} from '../../lib/sanity/types'
import {
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { LeagueQuizInstanceResult } from '../../views/LeagueQuiz/queries'
import { ActiveQuestionScreen } from './ActiveQuestionScreen'
import FinishedQuiz from './FinishedQuiz'
import { LastCompletedQuestionScreen } from './LastCompletedQuestionScreen'
import { QuizStatistics, useSubmitQuizAnswersMutation } from './queries'
import { QuizSplash } from './QuizSplash'
import { QuizStartScreen } from './QuizStartScreen'

export type QuestionAnswer = {
  questionId: string
  alternativeId: string | null
  startedAt: Date
  answeredAt: Date
  correct: boolean
  score: number
  timeBonus: number
}

const calculateResults = (
  answers: QuestionAnswer[]
): LeagueQuizInstanceResult => {
  let correctAnswerScore = 0
  let correctAnswersCount = 0
  let timeBonus = 0
  let totalScore = 0

  for (const answer of answers) {
    correctAnswerScore += answer.score
    timeBonus += answer.timeBonus
    totalScore += answer.score + answer.timeBonus

    if (answer.correct) {
      correctAnswersCount++
    }
  }

  return {
    correctAnswerScore,
    correctAnswersCount,
    timeBonus,
    totalScore,
  }
}

const existingSessionKey = (quizId: string) => `quiz-session-${quizId}`

type ExistingQuizSession = {
  quizId: string
  answers: QuestionAnswer[]
}

const getExistingQuizSession = (
  quizId: string
): ExistingQuizSession | undefined => {
  const existingSession = localStorage.getItem(existingSessionKey(quizId))
  if (!existingSession) {
    return undefined
  }

  return JSON.parse(existingSession)
}

const setExistingQuizSession = (quizId: string, answers: QuestionAnswer[]) => {
  localStorage.setItem(
    existingSessionKey(quizId),
    JSON.stringify({ quizId, answers })
  )
}

const clearExistingQuizSession = (quizId: string) => {
  localStorage.removeItem(existingSessionKey(quizId))
}

export const useExistingQuizSession = (quizId?: string) => {
  const [existingSession, setExistingSession] = useState<
    ExistingQuizSession | undefined
  >(undefined)

  useEffect(() => {
    if (!quizId) {
      return
    }

    const existing = getExistingQuizSession(quizId)

    if (!existing) {
      return
    }

    setExistingSession(existing)
  }, [quizId])

  return existingSession
}

const getCompletedQuestionsFromSession = (
  questons: SanityQuestion[],
  session?: ExistingQuizSession
): SanityQuestion[] => {
  if (!session) {
    return []
  }

  const completedQuestions = questons.filter((q) =>
    session.answers.some((a) => a.questionId === q.id)
  )

  return completedQuestions
}

export const ClientSideQuiz = ({
  quiz,
  campaign,
  isEmbed = false,
  clubLeagueSubscriptionId,
  quizStatistics,
}: {
  quiz: SanityLeagueQuiz
  campaign?: SanityLeagueQuizCampaign
  isEmbed?: boolean
  clubLeagueSubscriptionId?: string
  quizStatistics?: QuizStatistics
}) => {
  const { authUser } = useAuthContext()
  const session = useExistingQuizSession(quiz.id)
  const questions = quiz.randomizeQuestionOrder
    ? shuffle(quiz.questions, authUser?.uid)
    : quiz.questions

  const { quizState, setQuizState, setIsExpandedAdActive, setInstanceId } =
    useLeagueQuizContext()

  const [submitQuizAnwersMutation] = useSubmitQuizAnswersMutation()

  const [activeQuestion, setActiveQuestion] = useState<
    SanityQuestion | undefined
  >()

  const [completedQuestions, setCompletedQuestions] = useState<
    SanityQuestion[]
  >([])

  useEffect(() => {
    if (session) {
      setQuizState(QuizState.ACTIVE_QUIZ)
      setCompletedQuestions(
        getCompletedQuestionsFromSession(questions, session)
      )

      setAnswers(session.answers)

      if (session.answers.length === questions.length) {
        setFinished(true)
      }
    }
  }, [session])

  const [answers, setAnswers] = useState<QuestionAnswer[]>(
    session?.answers || []
  )

  const unansweredQuestions: SanityQuestion[] = questions.filter(
    (question) => !completedQuestions.map((q) => q.id).includes(question.id)
  )

  const [finished, setFinished] = useState<boolean>(false)

  useEffect(() => {
    if (!finished) {
      return
    }
    setQuizState(QuizState.FINISHED)

    async function submitAnswers() {
      if (!authUser) {
        return
      }

      const res = await submitQuizAnwersMutation({
        variables: {
          input: {
            quizId: quiz.id,
            leagueId: quiz.league.id,
            answers,
            quizType: quiz.quizType,
          },
        },
      })

      const instanceId = res.data?.submitQuizAnswers.id

      // If the insertion is successful, clear the existing quiz session from local storage
      if (instanceId) {
        setInstanceId(instanceId)
        clearExistingQuizSession(quiz.id)
      }
    }

    submitAnswers()
    //clearExistingQuizSession(quiz.id)
  }, [finished])

  // This determines if the play count animation should be played | default: false
  const [playCountAnimation, setPlayCountAnimation] = useState<boolean>(false)

  const lastCompletedQuestion =
    completedQuestions[completedQuestions.length - 1]

  const allQuestionsAnswered = completedQuestions.length === quiz.questionsCount

  const answerQuestion = async (answer: QuestionAnswer) => {
    if (!activeQuestion) {
      return
    }

    //350ms delay for animation
    await new Promise((resolve) => setTimeout(resolve, 350))

    setAnswers([...answers, answer])

    // set active question as completed
    setCompletedQuestions([...completedQuestions, activeQuestion!])

    // reset active question
    setActiveQuestion(undefined)

    setExistingQuizSession(quiz.id, [...answers, answer])
  }

  const nextQuestion = () => {
    if (allQuestionsAnswered || unansweredQuestions.length === 0) {
      return
    }

    setActiveQuestion(unansweredQuestions[0])
  }

  const triggerCalculation = () => {
    setIsExpandedAdActive(true)
    setPlayCountAnimation(true)
    setFinished(true)
  }

  if (quizState === QuizState.START_SCREEN) {
    return (
      <QuizStartScreen
        quizTitle={quiz.title}
        embedPromotionalText={quiz.embedPromotionalText}
        campaign={campaign}
        isEmbed={isEmbed}
      />
    )
  }

  if (finished) {
    return (
      <FinishedQuiz
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        playAnimation={playCountAnimation}
        quizId={quiz.id}
        result={calculateResults(answers)}
        questionsCount={quiz.questionsCount}
        leagueSlug={quiz.league.slug || quiz.league.id}
        hasCampaign={!!campaign}
      />
    )
  }

  if (!activeQuestion && !lastCompletedQuestion) {
    return (
      <QuizSplash nextQuestion={nextQuestion} nextQuestionLoading={false} />
    )
  }

  if (activeQuestion) {
    return (
      <ActiveQuestionScreen
        activeQuestion={activeQuestion}
        numCompletedQuestions={completedQuestions.length}
        questionsCount={quiz.questionsCount}
        isEmbed={isEmbed}
        answerQuestion={answerQuestion}
      />
    )
  }

  if (lastCompletedQuestion) {
    return (
      <LastCompletedQuestionScreen
        lastCompletedQuestion={lastCompletedQuestion}
        allQuestionsAnswered={allQuestionsAnswered}
        triggerCalculation={triggerCalculation}
        nextQuestion={nextQuestion}
        isEmbed={isEmbed}
        clubLeagueSubscriptionId={clubLeagueSubscriptionId}
        quizId={quiz.id}
        leagueId={quiz.league.id}
        questionsCount={quiz.questionsCount}
        currentQuestionIndex={completedQuestions.length}
        answer={answers.find((a) => a.questionId === lastCompletedQuestion.id)}
        quizStatistics={quizStatistics}
      />
    )
  }

  return null
}
