/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import Lottie from 'lottie-react'
import { FC, useEffect, useState } from 'react'
import { useCountUp } from 'use-count-up'
import { AD_CAMPAIGN_HEADER_EXPAND_TIME_MS } from '../../constants'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, fadeOut } from '../../styles/animations'
import { Spacer } from '../../styles/margin'
import theme, { colors } from '../../styles/theme'
import {
  CareerPathQuizInstanceResult,
  LeagueQuizInstanceResult,
} from '../../views/LeagueQuiz/queries'
import ScoreIndicator from '../ScoreIndicator'
import { Text } from '../Text'
import { Flex } from '../layout/Flex'
import chargeScoreAnimation from './assets/lottie/chargeScore.json'
import scoreExplosion from './assets/lottie/scoreExplosion.json'
import scoreExplosion_high from './assets/lottie/scoreExplosion_high.json'
import zeroPoints from './assets/lottie/zeroPoints.json'

const scaleAndMoveUp = keyframes`
  0% {
    transform: translateY(0) scale(0.75);
  }
  100% {
    transform: translateY(-24px) scale(1);
  }
`

const scaleBack = keyframes`
  0% {
    transform: translateY(-24px) scale(1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
`

const shake = keyframes`
  0%, 100% { transform: scale(1.15) rotate(0deg); }
  7% { transform: scale(1.15) rotate(-3deg); }
  14% { transform: scale(1.15) rotate(3deg); }
  21% { transform: scale(1.15) rotate(-3deg); }
  28% { transform: scale(1.15) rotate(3deg); }
  35% { transform: scale(1.15) rotate(-3deg); }
  42% { transform: scale(1.15) rotate(3deg); }
  49% { transform: scale(1.15) rotate(-3deg); }
  56% { transform: scale(1.15) rotate(3deg); }
  63% { transform: scale(1.15) rotate(-3deg); }
  70% { transform: scale(1.15) rotate(3deg); }
  77% { transform: scale(1.15) rotate(-3deg); }
  84% { transform: scale(1.15) rotate(3deg); }
  91% { transform: scale(1.15) rotate(-3deg); }
  98% { transform: scale(1.15) rotate(3deg); }
`

//slide out to the left
const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 100%;
  }

  50% {
    opacity: 100%;
  }
  100% {
    transform: translateX(-1000%);
    opacity: 0%;
  }
`
//slide in from the right
const slideIn = keyframes`
  0% {
    transform: translateX(500%);
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    transform: translateX(0);
    opacity: 100%;
  }
`

export const QuizScoreComponent: FC<{
  remaining: number
  totalCalculationTime: number
  playAnimation?: boolean
  className?: string
  result: LeagueQuizInstanceResult | CareerPathQuizInstanceResult
  questionsCount: number
}> = ({
  remaining,
  totalCalculationTime,
  playAnimation = false,
  className,
  result,

  questionsCount,
}) => {
  const t = useT()

  const totalScoreQuestions =
    'timeBonus' in result
      ? result.correctAnswerScore
      : result.correctAnswersCount
  const totalScore =
    'timeBonus' in result ? result.totalScore : result.totalScore
  const totalAvailableTimeBonus = questionsCount * 10

  const [explosionData, setExplosionData] = useState<Object | null>(null)

  const [componentState, setComponentState] = useState({
    playCorrectScoreAnimation: false,
    playTimeBonusAnimation: false,
    startCountingTimeBonus: false,
    startCountingTotalScore: false,
    chargeComplete: false,
  })

  const timings = (): {
    scaleAndMoveUpDuration: number
    scaleBackDuration: number
    shakeDuration: number
    halfCalculationTime: number
    countingDuration: number
    timeCountingDuration: number
  } => {
    // skip animation if playAnimation is false
    if (!playAnimation) {
      return {
        scaleAndMoveUpDuration: 0,
        scaleBackDuration: 0,
        shakeDuration: 0,
        halfCalculationTime: 0,
        countingDuration: 0,
        timeCountingDuration: 0,
      }
    }

    const countingDuration =
      (totalCalculationTime - AD_CAMPAIGN_HEADER_EXPAND_TIME_MS) / 4000

    return {
      scaleAndMoveUpDuration: 800, // ms
      scaleBackDuration: 300, // ms
      shakeDuration: 2000, // ms
      halfCalculationTime: totalCalculationTime / 2,
      countingDuration,
      timeCountingDuration: countingDuration - 0.35,
    }
  }

  const {
    scaleAndMoveUpDuration,
    scaleBackDuration,
    shakeDuration,
    halfCalculationTime,
    countingDuration,
    timeCountingDuration,
  } = timings()

  const { value: totalScoreValue } = useCountUp({
    isCounting: componentState.startCountingTotalScore,
    start: 0,
    end: 'timeBonus' in result ? result.totalScore : result.totalScore,
    duration: countingDuration,
    easing: 'easeOutCubic',
  })

  const { value: correctAnswersValue } = useCountUp({
    isCounting: true,
    start: 0,
    end: result.correctAnswersCount,
    duration: countingDuration,
    easing: 'easeInCubic',
    decimalPlaces: 0,
    onComplete: () => {
      setComponentState((prevState) => ({
        ...prevState,
        startCountingTimeBonus: true,
      }))
    },
  })

  const { value: timeBonusValue } = useCountUp({
    isCounting: componentState.startCountingTimeBonus,
    start: 0,
    end:
      'timeBonus' in result
        ? result.timeBonus
        : result.totalScore - result.correctAnswersCount * 10,
    duration: timeCountingDuration,
    easing: 'easeInCubic',
  })

  const correctScoreAnimationTime =
    totalCalculationTime * 0.79 + AD_CAMPAIGN_HEADER_EXPAND_TIME_MS
  const timeBonusAnimationTime =
    totalCalculationTime * 0.52 + AD_CAMPAIGN_HEADER_EXPAND_TIME_MS

  const countingTotalScoreTime =
    totalCalculationTime * 0.42 + AD_CAMPAIGN_HEADER_EXPAND_TIME_MS

  useEffect(() => {
    if (
      remaining <= correctScoreAnimationTime &&
      !componentState.playCorrectScoreAnimation
    ) {
      setComponentState((prevState) => ({
        ...prevState,
        playCorrectScoreAnimation: true,
      }))
    }
    if (
      totalCalculationTime - remaining <= timeBonusAnimationTime &&
      !componentState.playTimeBonusAnimation
    ) {
      setComponentState((prevState) => ({
        ...prevState,
        playTimeBonusAnimation: true,
      }))
    }
    if (
      remaining <= countingTotalScoreTime &&
      !componentState.startCountingTotalScore
    ) {
      setComponentState((prevState) => ({
        ...prevState,
        startCountingTotalScore: true,
      }))
    }
    if (componentState.chargeComplete) {
      const correctPercentage =
        (totalScoreQuestions / 10 / questionsCount) * 100
      setExplosionData(
        correctPercentage >= 80 ? scoreExplosion_high : scoreExplosion
      )
    }
  }, [
    remaining,
    componentState.chargeComplete,
    correctAnswersValue,
    questionsCount,
  ])

  return (
    <Flex
      className={className}
      horizontal={totalScore > 0 ? 'space-between' : 'center'}
      vertical="flex-end"
      css={css`
        height: 100%;
        width: 100%;
        max-width: 350px;
        max-height: 120px;
      `}
    >
      {/* Question score */}
      {totalScore > 0 && (
        <Flex
          column
          vertical="flex-start"
          horizontal="center"
          css={css`
            position: relative;
            flex: 1;
          `}
        >
          <ScoreIndicator
            percentage={
              'timeBonus' in result
                ? totalScoreQuestions / 10 / questionsCount
                : result.correctAnswersCount / questionsCount
            }
            size={48}
            color={colors.green500}
            innerBackgroundColor={colors.green300}
            iconPath={'/icons/Check.svg'}
            duration={countingDuration}
            playAnimation={playAnimation}
          />

          <Text
            extraCondensed
            strong
            css={[
              css`
                font-size: 30px;
                line-height: 30px;
              `,
            ]}
          >
            {correctAnswersValue}/{questionsCount}
          </Text>
          <Spacer height={4} />
          <Text color={theme.colors.grey400} size={'tiny'}>
            {t('correct')}
          </Text>
        </Flex>
      )}

      {/* Total score when score > 0 */}
      {totalScore > 0 && (
        <Flex
          column
          horizontal="center"
          vertical="space-between"
          css={css`
            position: relative;
            flex: 1;
          `}
        >
          <Flex
            column
            horizontal="center"
            css={[
              css`
                position: relative;
              `,
              remaining <= halfCalculationTime &&
                css`
                  animation: ${scaleAndMoveUp} ${scaleAndMoveUpDuration}ms
                      cubic-bezier(0.17, 0, 0, 1.01) forwards,
                    ${scaleBack} ${scaleBackDuration}ms
                      ${countingDuration * 1000}ms
                      cubic-bezier(0.96, 0, 0.77, 1) forwards;
                `,
            ]}
          >
            {/* Lottie charge animation */}
            {playAnimation && componentState.startCountingTotalScore && (
              <Lottie
                animationData={chargeScoreAnimation}
                loop={false}
                onComplete={() =>
                  setComponentState((prevState) => ({
                    ...prevState,
                    chargeComplete: true,
                  }))
                } // set playChargeScore to false when animation completes
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '145px',
                  height: '104px',
                  zIndex: 2,
                }}
              />
            )}
            {/* Lottie explosion animation */}
            {playAnimation && explosionData && (
              <Lottie
                animationData={explosionData}
                loop={false}
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '145px',
                  height: '104px',
                  zIndex: 2,
                }}
              />
            )}

            <Text
              strong
              extraCondensed
              css={[
                css`
                  font-size: 96px;
                  font-weight: 800;
                  line-height: 104px;
                  opacity: 100%;
                `,
                remaining <= halfCalculationTime &&
                  css`
                    animation: ${shake} ${shakeDuration}ms
                      ${scaleAndMoveUpDuration + scaleBackDuration}ms forwards;
                  `,
                playAnimation &&
                  remaining >= halfCalculationTime &&
                  css`
                    opacity: 50%;
                  `,
              ]}
            >
              {totalScoreValue}
            </Text>
          </Flex>

          <Text color={theme.colors.grey400} strong size={'tiny'}>
            {t('points')}
          </Text>
        </Flex>
      )}

      {/* Total score when score = 0 */}
      {totalScore === 0 && (
        <Flex
          column
          horizontal="center"
          css={css`
            width: 100%;
            position: relative;
            height: 120px;
            flex: 1;
          `}
        >
          <Flex
            column
            horizontal="center"
            css={[
              css`
                position: absolute;
                top: 0;
                width: 145px;
                height: 104px;
              `,
              playAnimation &&
                css`
                  opacity: 0%;
                  animation: ${slideIn} 250ms ease-in-out forwards;
                  animation-delay: ${2650}ms;
                `,
            ]}
          >
            {playAnimation && remaining <= totalCalculationTime - 3350 ? (
              <Lottie
                animationData={zeroPoints}
                loop={false}
                autoplay={true}
                css={css``}
              />
            ) : (
              <Text
                strong
                extraCondensed
                css={css`
                  position: absolute;
                  top: 0;
                  font-size: 104px;
                  font-weight: 800;
                  line-height: 104px;
                `}
              >
                {'0'}
              </Text>
            )}
          </Flex>
          {playAnimation && (
            <Text
              strong
              extraCondensed
              css={css`
                position: absolute;
                top: 0;
                font-size: 96px;
                font-weight: 800;
                line-height: 104px;
                animation: ${slideOut} 350ms ease-out forwards;
                animation-delay: ${2650}ms;
              `}
            >
              {'0'}
            </Text>
          )}

          <Text
            css={css`
              position: absolute;
              bottom: 0;
              opacity: 0;
              animation: ${fadeIn} 500ms ease-out forwards;
              animation-delay: ${playAnimation ? 2500 : 0}ms;
            `}
            color={theme.colors.grey400}
            strong
            size={'tiny'}
          >
            {t('points')}
          </Text>
          {playAnimation && (
            <Text
              css={css`
                position: absolute;
                bottom: 0;
                animation: ${fadeOut} 350ms ease-out forwards;
                animation-delay: ${2500}ms;
              `}
              color={theme.colors.grey400}
              strong
              size={'tiny'}
            >
              {t('correct')}
            </Text>
          )}
        </Flex>
      )}
      {/* Time bonus */}
      {totalScore > 0 && (
        <Flex
          column
          vertical="flex-start"
          horizontal="center"
          css={css`
            position: relative;
            flex: 1;
          `}
        >
          <Flex
            column
            horizontal="center"
            css={css`
              position: relative;
            `}
          >
            <ScoreIndicator
              percentage={
                'timeBonus' in result
                  ? result.timeBonus / totalAvailableTimeBonus
                  : (result.totalScore - result.correctAnswersCount * 10) /
                    (questionsCount * 10)
              }
              size={48}
              color={colors.green500}
              innerBackgroundColor={colors.green300}
              iconPath={'/icons/Lightning.svg'}
              duration={timeCountingDuration}
              delay={countingDuration}
              playAnimation={playAnimation}
            />
          </Flex>

          <Text
            extraCondensed
            strong
            css={[
              css`
                font-size: 30px;
                line-height: 30px;
              `,
            ]}
          >
            {timeBonusValue}
          </Text>
          <Spacer height={4} />
          <Text color={theme.colors.grey400} size={'tiny'}>
            {'timeBonus' in result ? t('time bonus') : t(`bonus`)}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
