/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { DefaultError } from '../../components/DefaultError'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { useT } from '../../lib/i18n/useT'
import { colors } from '../../styles/theme'

const NotFoundPage = () => {
  const t = useT()

  const route = window.location.pathname

  return (
    <Layout
      backgroundColor={colors.green600}
      withFooter
      withHeader
      showDownLoadButtons
      showMaintenanceMessage
      css={css`
        position: relative;
      `}
    >
      <PageContent
        grow
        horizontal="center"
        css={css`
          padding-top: 100px;
          padding-bottom: 40px;
        `}
      >
        <DefaultError
          title={t("The page you're looking for doesn't exist")}
          message=""
          sentryErrorMessage={`NotFoundPage | route: ${route}`}
        />
      </PageContent>
    </Layout>
  )
}

export default NotFoundPage
