import { useEffect } from 'react'
import { useRefValue } from './useRefValue'

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRefValue(callback)

  useEffect(() => {
    if (delay === null) {
      return
    }

    const id = setInterval(() => {
      savedCallback.current()
    }, delay)

    return () => {
      clearInterval(id)
    }
  }, [delay, savedCallback])
}
