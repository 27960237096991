/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useAuthContext } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { LeaderboardItemComponent } from '../../views/League/Leaderboard/LeaderboardItem'
import { LeaderboardItem } from '../../views/League/queries'
import { TextNew } from '../TextNew'
import { Flex } from '../layout/Flex'

export const MyPlacement = ({ item }: { item?: LeaderboardItem }) => {
  const t = useT()
  const { authUser } = useAuthContext()

  if (!item || !authUser || authUser.isAnonymous) {
    return null
  }
  return (
    <Flex column>
      <Flex horizontal="space-between" vertical="center">
        <TextNew
          size={16}
          italic
          condensed
          spacingEm={0.01}
          strong
          color={'#676767'}
        >
          {t(`Your placement`)}
        </TextNew>
      </Flex>
      <Spacer height={8} />
      <Flex
        css={css`
          position: relative;
          margin-bottom: 4px;
        `}
        column
      >
        <Flex
          column
          css={css`
            gap: 1px;

            padding: 0px 20px;
          `}
        >
          <LeaderboardItemComponent
            item={item}
            isViewer
            height={32}
            small
            clickable={false}
            css={[
              css`
                flex: 1;
              `,
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
