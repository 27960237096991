/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Heading } from '../../components/Heading'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Text } from '../../components/Text'
import { TermsHeader } from './TermsHeader'

const Content = () => {
  return (
    <div
      css={css`
        section {
          margin-bottom: 32px;
        }

        h2 {
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 12px;
        }

        ul {
          list-style: inside;
          margin-bottom: 12px;
        }

        li {
          margin-bottom: 8px;
        }
      `}
    >
      <section>
        <Heading level={1}>Privacy policy</Heading>
        <Text>
          <p>Updated 02/02/2022</p>
          <p>
            Fc Quiz (org. No. [927 208 407]) (the “Provider” or “we”/”us”) cares
            about individuals{"'"} privacy and is committed to process personal
            data in compliance with the locally applicable laws and regulations.
          </p>
          <p>
            By using Fc Quiz (the “Service”), you give the Provider access to
            personal data. This Privacy Policy provides you with the information
            about the Provider{"'"}s processing of personal data, including
            which personal data we collect, why we process your personal data
            and your rights connected to the processing of the personal data.
          </p>
          <p>
            If you have any questions regarding our processing of personal data
            or about this privacy policy, you may contact our legal
            representative at legal@fcquiz.app.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Who is the data Controller?
        </Heading>
        <Text>
          <p>
            For the purpose of this privacy policy, the Provider is the data
            controller.
          </p>
          <p>
            When the Service is used by Customers to collect data from their
            End-Users, the Customer is the data controller and we are a data
            processor, and the data processing is governed by a Data Processing
            Agreement and not this Privacy Policy.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Personal data, purpose and legal basis for processing
        </Heading>
        <Text>
          <p>
            The Provider processes among others personal data relating to
            contact persons at its customers, service providers and business
            partners, users, potential candidates and others who contact the
            Provider or visit our webpage.
          </p>
          <p>
            The personal data may be provided by the data subject itself, from
            publicly accessible sources or third parties such as service
            providers, partners etc.
          </p>
          <p>
            We may process your personal data in connection with the following
            processing activities:
          </p>
          <ul>
            <li>
              <Text strong>Contractual relationship:</Text> In a contractual
              relationship between the Provider and individuals, personal data
              may be necessary to the execution or the performance of the
              contract (GDPR Article 6 (1) (b)), such as contact information and
              other personal data necessary to fulfill the contract.
            </li>
            <li>
              <Text strong>Contract management and business relationship:</Text>{' '}
              In connection with the administration and management of contracts
              with the Provider{"'"}s customers, business partners etc. and for
              business and partner relationship purposes, we may process
              personal data such as contact information of contact persons at
              such customers, business partners, in addition to personal data
              contained in contracts, ongoing commercial correspondence,
              invoices, minutes of meeting etc. The legal basis is the Provider
              {"'"}s legitimate interest in entering into business
              relationships, fulfilling contracts and administering such
              relationships (GDPR Article 6 (1) (f)).
            </li>
            <li>
              <Text strong>
                Use of the Provider{"'"}s website, application, Service:
              </Text>{' '}
              We may process personal data about visitors to/users of our
              website, application and Service. This may include data that is
              submitted by the visitors/users, or data that is collected using
              cookies and other information gathering methods to enhance the
              user experience and optimize the methods, where personal data as
              IP address may be collected. The legal basis for such processing
              is the Provider{"'"}s legitimate interest in providing and
              maintaining the webpage, application, Service, and collect
              statistics (GDPR Article 6 (1) (f)).
            </li>
            <li>
              <Text strong>Maintaining and improving the Service.</Text> We may
              process data in order to maintain or improve the Service. This may
              include accessing or migrating data in order to find and fix
              errors, or develop new features. The legal basis for such
              processing is the Provider{"'"}s legitimate interest in providing
              and maintaining the Service (GDPR Article 6 (1) (f)).
            </li>
            <li>
              <Text strong>Responding to inquiries:</Text> We may process
              personal data in order to follow-up inquiries from potential
              candidates, website users, and others who contact us. We may
              process personal data such as contact information and other
              personal data provided in connection with the inquiry. The legal
              basis for such processing is the Provider{"'"}s legitimate
              interest in providing support to answer and manage inquiries and
              other communication (GDPR Article 6 (1) (f)).
            </li>
            <li>
              <Text strong>Marketing, newsletters etc.;</Text> If you have
              signed up for marketing or our newsletter, we may process your
              name, contact information etc. in order to submit such materials
              to you. Such processing is based on your consent (GDPR Article 6
              (1) (a)).
            </li>
            <li>
              <Text strong>Recruitment:</Text> If you apply to a position at the
              Provider, we may process your personal data in order to assess
              your application such as CV, application, certificates, references
              and other information you may provide or which we need to verify
              to assess your application. The legal basis for such processing is
              normally that it is necessary in order to take steps at the
              request of the candidate prior to (potentially) entering into an
              employment contract. The processing may also be based on our
              legitimate interest in finding the best candidate for the position
              (GDPR Article 6 (1) (f)), or your consent (GDPR Article 6 (1)
              (a)).
            </li>
            <li>
              <Text strong>Ensuring compliance and legitimate interests:</Text>{' '}
              The Provider may also process personal data in order to comply
              with statutory obligations to which the Provider is subject, and
              to safeguard our or third parties{"'"} legitimate interests, e.g.
              in relation to establishing a legal claim or preventing
              unauthorized access to or disclosure of personal data GDPR Article
              6 (1) (f)).
            </li>
            <li>
              <Text strong>Legal obligations.</Text> We may process personal
              data due to legal obligations applicable to the operation of the
              Service (GDPR Article 6 (1) (c)), such as accounting obligations.
            </li>
          </ul>
          <p>
            We may, on a case-by-case basis, process personal data for other
            purposes and rely on different legal grounds, such as consent or
            legitimate interests or in accordance with applicable data
            protection law, as set forth in an applicable privacy notice.{' '}
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Data sharing
        </Heading>
        <Text>
          <p>
            We will only disclose your personal data to third parties to the
            extent we have a legal basis for such disclosure.
          </p>
          <p>
            We may share data with third parties in the following situations:
          </p>
          <ul>
            <li>
              Data that is submitted with an intent to share, collaborate or
              communicate with other users, may be shared with those users.
            </li>
            <li>
              Data that is submitted in a research dialog, may be shared with
              the Customer who created that Dialog.
            </li>
            <li>
              Data is shared with Sub-Processors when needed in order to perform
              their sub-processing functions. (See the Sub-Processor section
              below for more info.)
            </li>
            <li>
              Data may be shared with Public Authorities, when it is necessary
              to comply with law, prevent or investigate potentially unlawful
              behavior, or to resolve disputes.
            </li>
            <li>
              If the Provider engages in a merger, acquisition, bankruptcy,
              dissolution, reorganization, sale of some or all of Provider{"'"}s
              assets or stock, financing, public offering of securities,
              acquisition of all or a portion of our business, a similar
              transaction or proceeding, or steps in contemplation of such
              activities (e.g. due diligence), some or all data may be shared or
              transferred, subject to standard confidentiality arrangements.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Processing location
        </Heading>
        <Text>
          <p>
            We may process and store your personal data outside the EU/EEA. All
            processing in a third country will be subject to mechanisms ensuring
            sufficient security for your personal data, such as EU Standard
            Contractual Clauses or an adequacy decision from the EU Commission,
            in addition to all necessary required steps under applicable law in
            order for such transfer of your personal data to be compliant with
            applicable law.
          </p>
          <p>
            To see the data processing locations of our sub-processors, view the
            Sub-Processor section below.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Data retention and deletion
        </Heading>
        <Text>
          <p>
            We store data for as long as it is necessary to fulfill the purposes
            for processing as defined above. Please note that continued storage
            may be necessary due to statutory obligations to which Staffers are
            subject to e.g. statutory rules related to storage for accounting
            purposes, or due to Staffers{"'"} legitimate interests, e.g. for the
            purpose to establish, exercise or defend a legal claim.{' '}
          </p>
          <p>
            When the Agreement is terminated, or we receive a deletion request:
          </p>
          <ul>
            <li>We delete data within 30 days.</li>
            <li>
              Within 30 days of deletion, our database backups will also be
              deleted, and the data will become unrecoverable.
            </li>
            <li>
              To see the data retention policies of our sub-processors, view the
              Sub-Processor section below.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Sub-Processors
        </Heading>
        <Text>
          <p>
            To support delivery of our Service, we may engage and use data
            processors with access to certain data (each, a {'"'}Sub-Processor
            {'"'}).
          </p>
          <ul>
            <li>
              Our Sub-Processors are listed here, including their processing
              function, location, and data retention policies.
            </li>
            <li>
              Customers receive a minimum 7 days advance notice of changes to
              our use of Sub-Processors.
            </li>
            <li>
              Customers may reasonably object to changes in our use of
              Sub-Processors, by emailing legal@fcquiz.app with the subject line
              “Object”.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Security
        </Heading>
        <Text>
          <p>
            Fc Quiz values your privacy rights and has taken reasonable steps to
            protect the users{"'"} privacy, including by implementation of
            physical, technical and organizational measures, to prevent loss,
            alterations, theft and unauthorized access to information stored and
            otherwise processed.
          </p>
          <p>To ensure data availability:</p>
          <ul>
            <li>Our systems are run on multiple instances and servers.</li>
            <li>Database backups are made automatically on a daily basis.</li>
          </ul>
          <p>To ensure data confidentiality and integrity:</p>
          <ul>
            <li>Only relevant personnel can access the data.</li>
            <li>
              Our personnel are bound by Non-Disclosure-Agreements in their work
              contracts.
            </li>
            <li>
              Data is encrypted with Transport Layer Security (TLS) in transit
              between research dialogs and our systems.
            </li>
            <li>Research data is encrypted at rest in our databases.</li>
          </ul>
          <p>To ensure physical security:</p>
          <ul>
            <li>We require two-factor authentication to our systems</li>
            <li>
              We require password, code or biometric identification to access
              our devices{' '}
            </li>
            <li>We require a keycard to access our headquarters</li>
          </ul>
          <p>To ensure accountability:</p>
          <ul>
            <li>
              We keep an audit log for deployments of changes to source code.{' '}
            </li>
            <li>We keep a 30-day audit log for events happening in service</li>
            <li>None of these logs contain personal data.</li>
          </ul>
          <p>To ensure transparency and intervenability:</p>
          <ul>
            <li>
              The Customer controls the content and publication of their
              research dialogs{' '}
            </li>
            <li>
              The Customer is notified when new data is submitted by their
              end-users
            </li>
            <li>
              The Customer may unpublish their research dialogs at any time, in
              order to stop the collection of new data.
            </li>
            <li>
              To access, rectify, delete, block or object to processing of data,
              the Customer may submit a request by emailing legal@fcquiz.app. We
              will respond to such requests within 30 days.{' '}
            </li>
          </ul>
          <p>To ensure data portability:</p>
          <ul>
            <li>
              We may provide data exports in commonly portable formats such as
              CSV. To request exports, email legal@fcquiz.app. We will respond
              to such requests within 30 days.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Your data rights
        </Heading>
        <Text>
          <p>
            As a data subject, you are entitled to request access to the
            personal data we process about you, request that we update or
            correct your personal data, and/or withdraw any consent to
            processing. In some circumstances, you are also entitled to request
            that your personal data be erased, request restriction of processing
            or object to processing, and/or request data portability.
          </p>
          <p>
            To make a request to exercise any of these rights, email us at
            legal@fcquiz.app. We will respond to the request directly within 30
            days.
          </p>
          <p>
            You are also entitled to lodge a complaint with a supervisory
            authority if you believe that our processing of your personal data
            is contrary to relevant data protection laws.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Changes to the Privacy Policy
        </Heading>
        <Text>
          <p>
            If we make changes in the processing of personal data, this policy
            will be updated, and the change will be notified on the webpage, in
            the Service, or by use of your contact information.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Contact
        </Heading>
        <Text>
          <p>To contact us about data processing, email legal@fcquiz.app.</p>
        </Text>
      </section>
    </div>
  )
}

export const PrivacyPage = () => {
  return (
    <Layout hideNavbar>
      <TermsHeader />

      <PageContent>
        <Content />
      </PageContent>
    </Layout>
  )
}
