import { gql, useMutation } from '@apollo/client'

const setAvatarMutation = gql`
  mutation setAvatar($input: String!) {
    setAvatar(input: $input) {
      id
      avatarData
    }
  }
`

export const useSetAvatarMutation = () =>
  useMutation<unknown, { input: string }>(setAvatarMutation)
