import { TFunction } from '../../lib/i18n/useT'
import { ClubRole } from './queries'

export function formatClubRole(t: TFunction, role: ClubRole): string {
  switch (role) {
    case 'admin':
      return t('Owner')
    case 'moderator':
      return t('Assistant trainer')
    case 'senior':
      return t('First team player')
    case 'member':
      return t('Player')
  }
}

export function hasOneOfClubRoles(
  viewerRole?: ClubRole | null,
  ...requiredRoles: ClubRole[]
): boolean {
  return requiredRoles.some((role) => role === viewerRole)
}
