/** @jsxImportSource @emotion/react */

import { gql, useApolloClient, useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PiPlusBold } from 'react-icons/pi'
import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Flex } from '../../../components/layout/Flex'
import { Modal, ModalTrigger } from '../../../components/modal/Modal'
import { TextArea, TextInput } from '../../../components/TextInput'
import { TextNew } from '../../../components/TextNew'
import { ToggleSwitch } from '../../../components/ToggleSwitch'
import { UNVERIFIED_CLUB_SUBSCRIPTION_LIMIT } from '../../../constants'
import { ErrorCode, hasErrorCode } from '../../../lib/apollo/apiError'
import { useT } from '../../../lib/i18n/useT'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useDebouncedString } from '../../../utils/useDebouncedValue'
import { useForm } from '../../../utils/useForm'
import {
  useSubscribeClubtoLeagueMutation,
  useUnsubscribeClubFromLeagueMutation,
} from '../../Admin/queries'
import { ChallengeNavItem } from '../../Challenge/ChallengeGroupPage'
import { LeagueItem } from '../../Home/LeagueItem'
import { HomePageLeague, homePageLeagueFragment } from '../../Home/queries'
import { useLibrarySearchQuery } from '../../Search/queries'
import { EmbedCodeModal } from '../ClubProfilePage/EmbedCodeModal'
import {
  Club,
  clubProfileQuery,
  useClubOwnerQuery,
  useCreateQuizLeagueForClubMutation,
} from '../queries'

const polularLeaguesQuery = gql`
  query popularLeagues($limit: Int!) {
    popularLeagues(limit: $limit) {
      ...HomePageLeagueFragment
    }
  }

  ${homePageLeagueFragment}
`

const usePopularLeaguesQuery = (limit: number) => {
  return useQuery<{ popularLeagues: HomePageLeague[] }>(polularLeaguesQuery, {
    variables: { limit },
    fetchPolicy: 'cache-and-network',
  })
}

export const QuizzesSubPage = ({ club }: { club: Club }) => {
  const t = useT()
  const profilePageQuery = useClubOwnerQuery(club.slug)

  const ownedLeagues = profilePageQuery.data?.club?.ownedLeagues || []

  // Filter out owned leagues
  const subscribedLeagues = (
    profilePageQuery.data?.club?.subscribedLeagues || []
  ).filter(
    (league) =>
      ownedLeagues.findIndex((ownedLeague) => ownedLeague.id === league.id) ===
      -1
  )

  const textColor = colors.white

  const showCreateQuiz = club.verified
  return (
    <Flex column horizontal="center" gap={'large'} css={margin.vertical(32)}>
      {club.verified && (
        <>
          <Flex column gap={'large'}>
            <TextNew
              color={textColor}
              textAlign="center"
              size={32}
              italic
              weight={700}
              condensed
            >
              {t(`Series owned by your club`)}
            </TextNew>
            <Flex gap={'small'} wrap>
              {ownedLeagues.map((league) => (
                <div
                  key={league.id}
                  css={css`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                  `}
                >
                  <LeagueItem key={league.id} league={league} wide />

                  <ModalTrigger
                    button={({ openModal }) => (
                      <SecondaryButton
                        variant="white"
                        css={margin.top()}
                        onClick={openModal}
                      >
                        {t('Generate embed')}
                      </SecondaryButton>
                    )}
                    modal={({ closeModal }) => (
                      <EmbedCodeModal
                        clubLeagueSubscriptionId={
                          league.clubLeagueSubscriptionId
                        }
                        closeModal={closeModal}
                        clubLocale={club.locale}
                      />
                    )}
                  />
                </div>
              ))}
              {showCreateQuiz && (
                <Flex
                  column
                  css={css`
                    height: 205px;
                    width: 217px;
                    border-radius: 15px;
                    background-color: ${colors.green200};
                    padding: 16px;
                  `}
                >
                  <Flex
                    vertical="center"
                    horizontal="center"
                    css={css`
                      flex: 1;
                    `}
                  >
                    <Flex column gap={'tiny'}>
                      <TextNew
                        weight={700}
                        size={24}
                        condensed
                        textAlign="center"
                        color={colors.black}
                      >
                        {t(`Create a quiz series for your club`)}
                      </TextNew>
                      <Divider color={colors.black} size={1} />
                    </Flex>
                  </Flex>

                  <ModalTrigger
                    button={({ openModal }) => (
                      <IconButton
                        backgroundColor={'black'}
                        iconBottomMargin={-2}
                        onClick={openModal}
                        rounded
                        text={
                          <TextNew
                            weight={700}
                            size={24}
                            condensed
                            italic
                            color={'white'}
                          >
                            {t('Create series')}
                          </TextNew>
                        }
                        icon={<TextNew size={24}>💥</TextNew>}
                      />
                    )}
                    modal={({ closeModal }) => (
                      <CreateLeagueForClubModal
                        closeModal={closeModal}
                        clubId={club.id}
                      />
                    )}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </>
      )}

      {showCreateQuiz && subscribedLeagues.length === 0 && (
        <Flex
          column
          gap={'small'}
          css={css`
            width: 100px;
          `}
        >
          <Divider color={colors.white} />
          <TextNew
            color={colors.white}
            italic
            size={32}
            textAlign="center"
            weight={700}
            condensed
            css={css`
              text-transform: uppercase;
              margin-bottom: 2px;
            `}
          >
            {t('or')}
          </TextNew>

          <Divider color={colors.white} />
        </Flex>
      )}
      <Spacer height="large" />
      <TextNew
        color={textColor}
        textAlign="center"
        size={32}
        italic
        weight={700}
        condensed
      >
        {t('Subscriptions')}
      </TextNew>
      <Flex gap={'small'} wrap>
        {subscribedLeagues.map((league) => (
          <div
            key={league.id}
            css={css`
              display: flex;
              justify-content: center;
              flex-direction: column;
            `}
          >
            <LeagueItem key={league.id} league={league} />

            {club.verified && (
              <ModalTrigger
                button={({ openModal }) => (
                  <SecondaryButton
                    variant="white"
                    css={margin.top()}
                    onClick={openModal}
                  >
                    {t('Generate embed')}
                  </SecondaryButton>
                )}
                modal={({ closeModal }) => (
                  <EmbedCodeModal
                    clubLeagueSubscriptionId={league.clubLeagueSubscriptionId}
                    closeModal={closeModal}
                    clubLocale={club.locale}
                  />
                )}
              />
            )}
          </div>
        ))}
      </Flex>

      {subscribedLeagues.length === 0 && (
        <AddQuizToClubComponent club={club} subscribeOnly />
      )}

      {subscribedLeagues.length > 0 && (
        <ModalTrigger
          button={({ openModal }) => (
            <IconButton
              css={margin.top()}
              onClick={openModal}
              noWrap
              rounded
              backgroundColor={colors.white}
              textColor={colors.black}
              text={
                <TextNew
                  weight={600}
                  size={24}
                  italic
                  condensed
                  textAlign="center"
                  color={colors.black}
                >
                  {subscribedLeagues.length
                    ? t('Manage subscriptions')
                    : t('Subscribe')}
                </TextNew>
              }
              icon={<TextNew size={24}>✏️</TextNew>}
            />
          )}
          modal={({ closeModal }) => (
            <ClubSeriesSubscriptionModal
              closeModal={closeModal}
              subscribedLeagues={subscribedLeagues}
              club={club}
            />
          )}
        />
      )}
    </Flex>
  )
}

const CreateLeagueForClubModal = ({
  closeModal,
  clubId,
}: {
  closeModal: () => void
  clubId: string
}) => {
  const t = useT()
  const [createQuizLeagueMutation, { loading }] =
    useCreateQuizLeagueForClubMutation()

  const form = useForm({
    initialValues: {
      title: '',
      description: '',
      hideOnHomePage: true,
    },

    validate: {
      title: (value) => {
        if (!value || value.trim() === '' || value.length < 3) {
          return 'Title must be at least 3 characters'
        }
      },
    },

    onSubmit: async ({ values, setSubmitError }) => {
      try {
        await createQuizLeagueMutation({
          variables: {
            input: {
              clubId,
              leagueTitle: values.title,
              leagueDescription: values.description,
              hideOnHomePage: values.hideOnHomePage,
            },
          },
        })
        closeModal()
      } catch (error) {
        setSubmitError(t('Something went wrong, try again'))
      }
    },
  })
  return (
    <Modal
      closeModal={closeModal}
      heading={t('Create series')}
      backgroundColor={colors.grey200}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          form.submitForm()
        }}
        css={css`
          display: flex;
          gap: 8px;
          flex-direction: column;
        `}
      >
        <TextInput
          id="title"
          name="title"
          label={
            <TextNew
              color={colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Title')}
            </TextNew>
          }
          placeholder={t('Title')}
          autoComplete="off"
          value={form.values.title}
          onValue={(value) => {
            form.setValue('title', value)
          }}
          error={form.submitAttempted && form.fieldErrors.title}
          css={margin.top('medium')}
          maxLength={40}
        />

        <TextArea
          id="description"
          name="description"
          label={
            <TextNew
              color={colors.green600}
              size="small"
              css={
                (margin.bottom('tiny'),
                css`
                  text-align: left;
                `)
              }
            >
              {t('Description')}
            </TextNew>
          }
          placeholder={t('Description')}
          autoComplete="off"
          maxLength={140}
          value={form.values.description || ''}
          onValue={(value) => {
            if (value === '') {
              form.setValue('description', '')
            } else {
              form.setValue('description', value)
            }
          }}
          error={form.submitAttempted && form.fieldErrors.description}
          css={margin.top('medium')}
        />
        <ToggleSwitch
          label={t(`Only show on club page`)}
          value={form.values.hideOnHomePage}
          onValue={(value) => {
            form.setValue('hideOnHomePage', value)
          }}
        />
        <TextNew color={colors.grey400} size="small">
          {form.values.hideOnHomePage
            ? t(`The series will only be visible on your club page 🔒`)
            : t('The series will be visible throughout fcQuiz 🎉')}
        </TextNew>
        <Spacer height={'large'} />
        <PrimaryButton
          variant="black"
          type="submit"
          disabled={form.submitting || loading}
          loading={form.submitting}
          css={css`
            height: 48px;
            width: 100%;
          `}
        >
          {t('Save')}
        </PrimaryButton>
      </form>
    </Modal>
  )
}

export const ClubSeriesSubscriptionModal = ({
  closeModal,
  subscribedLeagues,
  club,
  subscribeOnly = false,
}: {
  closeModal: () => void
  subscribedLeagues: HomePageLeague[]
  club: {
    slug: string
    verified: boolean
  }
  subscribeOnly?: boolean
}) => {
  const t = useT()
  const [selectedTab, setSelectedTab] = useState<'subscribe' | 'unsubscribe'>(
    'subscribe'
  )
  const [userWarnedAboutUnsubId, setUserWarnedAboutUnsubId] = useState<
    string | undefined
  >(undefined)

  const [unsubscribeMutation] = useUnsubscribeClubFromLeagueMutation()

  useEffect(() => {
    const timer = setTimeout(() => {
      setUserWarnedAboutUnsubId(undefined)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [userWarnedAboutUnsubId])

  return (
    <Modal closeModal={closeModal}>
      <Flex column gap={'medium'}>
        {!subscribeOnly && (
          <Flex gap={'medium'}>
            <ChallengeNavItem
              title="Subscribe"
              onClick={() => setSelectedTab('subscribe')}
              selected={selectedTab === 'subscribe'}
            />

            <ChallengeNavItem
              title="Unsubscribe"
              onClick={() => setSelectedTab('unsubscribe')}
              selected={selectedTab === 'unsubscribe'}
            />
          </Flex>
        )}

        {selectedTab === 'unsubscribe' && (
          <>
            {subscribedLeagues.length > 0 && (
              <>
                <TextNew color={colors.black}>
                  {t(`Quiz series_plural: {{count}}`, {
                    count: subscribedLeagues.length,
                  })}
                </TextNew>
                {club.verified && (
                  <TextNew
                    textAlign="center"
                    size="small"
                    color={colors.danger}
                  >
                    NB! Unsubscribing from series may break any active Embeds.
                  </TextNew>
                )}
                <Flex column gap={'medium'}>
                  {subscribedLeagues.map((league) => {
                    const warnedAboutUnsubscription =
                      userWarnedAboutUnsubId === league.id
                    return (
                      <Flex
                        horizontal="space-between"
                        vertical="center"
                        gap={'medium'}
                        css={css`
                          padding: 10px;
                          border-radius: 5px;
                          border: 1px solid ${colors.black};
                        `}
                      >
                        <TextNew>{league.title}</TextNew>
                        <PrimaryButton
                          variant="dangerRed"
                          onClick={() => {
                            if (!warnedAboutUnsubscription) {
                              setUserWarnedAboutUnsubId(league.id)
                              return
                            }

                            unsubscribeMutation({
                              variables: {
                                clubIdOrSlug: club.slug,
                                leagueId: league.id,
                              },
                            }).then(() => {
                              closeModal()
                            })
                          }}
                        >
                          {warnedAboutUnsubscription
                            ? 'Are you sure?'
                            : 'Unsubscribe'}
                        </PrimaryButton>
                      </Flex>
                    )
                  })}
                </Flex>
              </>
            )}
          </>
        )}

        {selectedTab === 'subscribe' && (
          <>
            {subscribeOnly && (
              <TextNew
                size={24}
                condensed
                italic
                weight={700}
                textAlign="center"
              >
                {t('Add quiz subscription')}
              </TextNew>
            )}
            <TextNew italic textAlign="center">
              {t(
                `New quizzes from subscribed series will be added to your club automatically.`
              )}
            </TextNew>

            <LeagueSearchComponent
              clubSlug={club.slug}
              subScribedLeagueIds={subscribedLeagues.map((league) => league.id)}
              verified={club.verified}
            />
          </>
        )}
      </Flex>
    </Modal>
  )
}

export const AddQuizToClubComponent = ({
  club,
  subscribeOnly = false,
}: {
  club: Club
  subscribeOnly?: boolean
}) => {
  const t = useT()

  return (
    <Flex
      column
      css={css`
        height: 205px;
        width: 217px;
        border-radius: 15px;
        background-color: ${colors.yellow100};
        padding: 16px;
      `}
    >
      <Flex
        vertical="center"
        horizontal="center"
        css={css`
          flex: 1;
        `}
      >
        <Flex column gap={'tiny'}>
          <TextNew weight={700} size={24} condensed textAlign="center">
            {t(`Add a Quiz to your Club`)}
          </TextNew>
          <Divider color={colors.black} size={1} />
        </Flex>
      </Flex>

      <ModalTrigger
        button={({ openModal }) => (
          <IconButton
            iconBottomMargin={-2}
            onClick={openModal}
            rounded
            text={
              <TextNew weight={700} size={24} condensed italic color="white">
                {t('Add quiz')}
              </TextNew>
            }
            icon={<PiPlusBold color="white" size={24} />}
          />
        )}
        modal={({ closeModal }) => (
          <ClubSeriesSubscriptionModal
            closeModal={closeModal}
            subscribedLeagues={[]}
            club={club}
            subscribeOnly={subscribeOnly}
          />
        )}
      />
    </Flex>
  )
}

const LeagueSearchComponent = ({
  clubSlug,
  subScribedLeagueIds,
  verified = false,
}: {
  clubSlug: string
  subScribedLeagueIds: string[]
  verified?: boolean
}) => {
  const t = useT()
  const [searchQuery, setSearchQuery] = useState('')
  const [errorText, setErrorText] = useState<string | undefined>('hey')
  const [subscribeMutation] = useSubscribeClubtoLeagueMutation()
  const debounced = useDebouncedString(searchQuery, 500, { minLength: 3 })

  const popularLeaguesQuery = usePopularLeaguesQuery(10)
  const popularLeagues = popularLeaguesQuery.data?.popularLeagues || []

  useEffect(() => {
    setErrorText(undefined)
  }, [searchQuery])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (errorText) {
        setErrorText(undefined)
      }
    }, 3000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [errorText])

  const query = useLibrarySearchQuery(debounced)

  const apolloClient = useApolloClient()

  const queryLeagues =
    query.data?.librarySearch.leagues &&
    query.data.librarySearch.leagues.length > 0
      ? (query.data?.librarySearch.leagues).filter(
          (league) => !subScribedLeagueIds.includes(league.id)
        )
      : popularLeagues

  if (
    subScribedLeagueIds.length === UNVERIFIED_CLUB_SUBSCRIPTION_LIMIT &&
    !verified
  ) {
    return (
      <TextNew size="small" color={colors.danger}>
        {t(`Unverified clubs cannot subscribe to more than {{count}} series.`, {
          count: UNVERIFIED_CLUB_SUBSCRIPTION_LIMIT,
        })}
      </TextNew>
    )
  }

  if (errorText) {
    return <TextNew color={colors.danger}>{errorText}</TextNew>
  }

  return (
    <Flex column gap={'medium'}>
      <TextInput
        value={searchQuery}
        onValue={(value) => setSearchQuery(value)}
        placeholder="Search for series"
        css={css`
          border: 1px solid ${colors.black};
          border-radius: 5px;
          width: 100%;
        `}
      />

      {queryLeagues.map((league) => (
        <Flex
          key={`search_result_${league.id}`}
          horizontal="space-between"
          vertical="center"
          css={css`
            padding: 10px;
            border-radius: 5px;
            border: 1px solid ${colors.black};
          `}
        >
          <TextNew>{league.title}</TextNew>
          <PrimaryButton
            onClick={async () => {
              try {
                await subscribeMutation({
                  variables: {
                    clubIdOrSlug: clubSlug,
                    leagueId: league.id,
                    isOwner: false,
                  },
                }).then(() => {
                  apolloClient.refetchQueries({ include: [clubProfileQuery] })
                  setSearchQuery('')
                })
              } catch (error) {
                if (hasErrorCode(error, ErrorCode.MAX_CAPACITY_REACHED)) {
                  setErrorText(
                    t(
                      `Unverified clubs cannot subscribe to more than {{count}} series.`,
                      {
                        count: UNVERIFIED_CLUB_SUBSCRIPTION_LIMIT,
                      }
                    )
                  )
                } else {
                  setErrorText(t('Something went wrong, try again'))
                }
              }
            }}
          >
            {t('Subscribe')}
          </PrimaryButton>
        </Flex>
      ))}
    </Flex>
  )
}
