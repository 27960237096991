/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { RiShareForwardLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { TextNew } from '../../components/TextNew'
import { Flex } from '../../components/layout/Flex'
import { ShareQuizModal } from '../../components/leagueQuiz/ShareQuizModal'
import { ModalTrigger } from '../../components/modal/Modal'
import { useAuthContext } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { useT } from '../../lib/i18n/useT'
import { LeagueQuizStatus } from '../../lib/types'
import { colors } from '../../styles/theme'
import { useFormatDate } from '../../utils/date'
import {
  useStartCareerPathQuizMutation,
  useStartLeagueQuizMutation,
} from '../LeagueQuiz/queries'
import { leagueQuizItemStyleV2 } from './LeaguePage'
import { LeagueQuizMinimal } from './queries'

export const ShareQuizButton = ({
  slug,
  quizId,
  leagueId,
  userFinishedInstanceId,
}: {
  slug: string
  quizId: string
  leagueId: string
  userFinishedInstanceId?: string
}) => {
  return (
    <ModalTrigger
      button={({ openModal }) => (
        <SecondaryButton
          variant="white"
          onClick={openModal}
          css={css`
            padding: 0;
          `}
        >
          <RiShareForwardLine size={24} color={colors.black} />
        </SecondaryButton>
      )}
      modal={({ closeModal }) => (
        <ShareQuizModal
          slug={slug}
          quizId={quizId}
          leagueId={leagueId}
          closeModal={closeModal}
          userFinishedInstanceId={userFinishedInstanceId}
        />
      )}
    />
  )
}

const ActiveQuizItemV2 = ({
  quiz,
  leagueId,
  leagueTitle,
  clubId,
}: {
  quiz: LeagueQuizMinimal
  leagueId?: string
  leagueTitle?: string
  clubId?: string
}) => {
  const { authUser, signInAsAnonymous } = useAuthContext()
  const t = useT()

  const navigate = useNavigate()
  const [startLeagueQuizMutation, { loading }] = useStartLeagueQuizMutation()
  const [startCareerPathQuizMutation] = useStartCareerPathQuizMutation()

  const StartOrContinueQuiz = () => {
    return quiz.viewerCanStartQuiz ? (
      <PrimaryButton
        disabled={loading}
        css={css`
          border-radius: 50px;
        `}
        onClick={async () => {
          if (!authUser) {
            await signInAsAnonymous()
          }

          // If quiz is a career path quiz, start career path quiz
          if (quiz.quizType === 'careerPath') {
            startCareerPathQuizMutation({
              variables: { input: { leagueQuizId: quiz.id } },
            }).then((result) => {
              const quizInstanceId = result.data?.startCareerPathQuiz.id
              if (quizInstanceId) {
                trackEvent(AnalyticsEventType.StartQuiz, {
                  quizId: quiz.id,
                  leagueId,
                  clubId,
                  isEmbed: false,
                })
                navigate(
                  `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}${
                    clubId ? `&clubId=${clubId}` : ''
                  }`
                )
              }
            })
            return
          }

          startLeagueQuizMutation({
            variables: { input: { leagueQuizId: quiz.id } },
          }).then((result) => {
            const quizInstanceId = result.data?.startLeagueQuiz.id
            if (quizInstanceId) {
              trackEvent(AnalyticsEventType.StartQuiz, {
                quizId: quiz.id,
                leagueId,
                clubId,
                isEmbed: false,
              })
              navigate(
                `/play-quiz/${quizInstanceId}?quizType=${quiz.quizType}${
                  clubId ? `&clubId=${clubId}` : ''
                }`
              )
            }
          })
        }}
      >
        <TextNew
          color={'white'}
          textAlign="center"
          italic
          condensed
          spacingEm={0}
          strong
          css={css`
            font-size: 24px;
            text-transform: capitalize;
          `}
        >
          {t('Play quiz')}
        </TextNew>
      </PrimaryButton>
    ) : quiz.viewerData.activeQuizInstance?.id ? (
      <PrimaryButton
        css={css`
          border-radius: 50px;
        `}
        onClick={() =>
          // Continue quiz, so dont track start quiz
          navigate(
            `/play-quiz/${quiz.viewerData.activeQuizInstance?.id}?quizType=${
              quiz.quizType
            }${clubId ? `&clubId=${clubId}` : ''}`
          )
        }
      >
        <TextNew
          textAlign="center"
          italic
          condensed
          color={colors.white}
          spacingEm={0}
          strong
          css={css`
            font-size: 24px;
          `}
        >
          {t('Continue quiz')}
        </TextNew>
      </PrimaryButton>
    ) : null
  }

  return (
    <Flex
      column
      grow
      css={css`
        justify-content: space-between;
      `}
      gap={'tiny'}
    >
      <Flex horizontal="space-between">
        <TextNew size={21} condensed>
          {leagueTitle}
        </TextNew>
        {leagueId && (
          <ShareQuizButton
            slug={quiz.slug}
            quizId={quiz.id}
            leagueId={leagueId}
            userFinishedInstanceId={
              quiz.viewerData.result?.leagueQuizInstanceId
            }
          />
        )}
      </Flex>
      <V2InfoComponent
        title={quiz.title}
        quizNumber={quiz.quizNumber}
        availableTo={quiz.availableTo}
        playerCount={quiz.participantCount}
      />
      <StartOrContinueQuiz />
    </Flex>
  )
}

const V2InfoComponent = ({
  title,
  quizNumber,
  availableTo,
  playerCount = 0,
}: {
  title: string
  quizNumber?: number
  availableTo?: string
  playerCount?: number
}) => {
  const t = useT()
  const formatDate = useFormatDate()

  const longTitle = title.length > 30

  const availableToCurrentYear = availableTo
    ? new Date(availableTo).getFullYear() === new Date().getFullYear()
    : false
  return (
    <Flex column gap={'tiny'}>
      {quizNumber && (
        <TextNew size={24} condensed>
          {t('Quiz') + ' ' + quizNumber}
        </TextNew>
      )}
      <TextNew
        size={longTitle ? 22 : 27}
        condensed
        strong
        css={css`
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;
          line-height: 1.2;
        `}
      >
        {title}
      </TextNew>
      <Divider size={1} color={colors.black} />
      <TextNew size={14}>
        {availableTo &&
          t('Deadline {{date}}', {
            date: formatDate(
              new Date(availableTo),
              availableToCurrentYear ? 'short-datetime' : 'full-date'
            ),
          })}
      </TextNew>
      <TextNew size={14}>
        {t('{{count}} player', {
          count: playerCount,
        })}
      </TextNew>
    </Flex>
  )
}

export const LeagueQuizItemV2 = ({
  quiz,
  leagueId,
  leagueTitle,
  className,
  clubId,
  selected = false,
  selectedColor = colors.gold,
}: {
  quiz: LeagueQuizMinimal
  leagueId?: string
  leagueTitle?: string
  className?: string
  clubId?: string
  selected?: boolean
  selectedColor?: string
}) => {
  const t = useT()
  const navigate = useNavigate()

  return (
    <Flex
      column
      css={[
        leagueQuizItemStyleV2,
        (quiz.viewerCanStartQuiz ||
          Boolean(quiz.viewerData.activeQuizInstance?.id)) &&
          css`
            height: 100%;
          `,
        selected &&
          css`
            height: 100%;
            border: 5px solid ${selectedColor};
          `,
      ]}
      className={className}
    >
      {quiz.viewerCanStartQuiz || quiz.viewerData.activeQuizInstance?.id ? (
        <ActiveQuizItemV2
          quiz={quiz}
          leagueId={leagueId}
          leagueTitle={leagueTitle}
          clubId={clubId}
        />
      ) : (
        <Flex column vertical="space-between" grow>
          <Flex horizontal="space-between">
            <TextNew size={21} condensed>
              {leagueTitle}
            </TextNew>
            {leagueId && (
              <ShareQuizButton
                slug={quiz.slug}
                quizId={quiz.id}
                leagueId={leagueId}
                userFinishedInstanceId={
                  quiz.viewerData.result?.leagueQuizInstanceId
                }
              />
            )}
          </Flex>
          <V2InfoComponent
            title={quiz.title}
            quizNumber={quiz.quizNumber}
            availableTo={quiz.availableTo}
            playerCount={quiz.participantCount}
          />

          {quiz.status !== LeagueQuizStatus.upcoming && (
            <Flex
              horizontal="space-between"
              vertical="center"
              css={css`
                width: 100%;
              `}
            >
              {quiz.viewerData.result?.leagueQuizInstanceId ? (
                <SecondaryButton
                  css={css`
                    border-radius: 50px;
                    width: 100%;
                  `}
                  onClick={() => {
                    navigate(
                      `/play-quiz/${quiz.viewerData.result?.leagueQuizInstanceId}?quizType=${quiz.quizType}`
                    )
                  }}
                >
                  <TextNew
                    textAlign="center"
                    italic
                    condensed
                    spacingEm={0}
                    strong
                    css={css`
                      font-size: 24px;
                    `}
                  >
                    {t('Result')}
                  </TextNew>
                </SecondaryButton>
              ) : (
                <div
                  css={css`
                    height: 40px;
                  `}
                />
              )}
            </Flex>
          )}
          {quiz.status === LeagueQuizStatus.upcoming && (
            <Text size="tiny" color={colors.grey400}>
              You can see this item since you are an owner of this league
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  )
}
