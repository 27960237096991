/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { useState } from 'react'
import { colors } from '../styles/theme'
import { useTimeout } from '../utils/useTimeout'
import { Flex } from './layout/Flex'

const animation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)

  }
`

export type LoaderVariant = 'normal' | 'yellow' | 'green' | 'blue' | 'red'

const colorsByVariant = (variant: LoaderVariant) => {
  switch (variant) {
    case 'normal':
      return {
        backgroundColor: 'lightgray',
        foregroundColor: 'black',
      }
    case 'yellow':
      return {
        backgroundColor: colors.yellow300,
        foregroundColor: colors.yellow400,
      }
    case 'green':
      return {
        backgroundColor: colors.green300,
        foregroundColor: colors.green400,
      }
    case 'blue':
      return {
        backgroundColor: colors.blue300,
        foregroundColor: colors.blue400,
      }
    case 'red':
      return {
        backgroundColor: colors.red300,
        foregroundColor: colors.red400,
      }
  }
}

export const Loader = ({
  variant = 'normal',
  size = 64,
  delay = 500,
  className,
  center,
}: {
  size?: number
  delay?: number
  variant?: LoaderVariant
  className?: string
  center?: boolean
}) => {
  const [show, setShow] = useState(!delay)
  useTimeout(() => setShow(true), delay ?? null)

  const { backgroundColor, foregroundColor } = colorsByVariant(variant)
  const borderWidth = size / 5

  const Loader = () => (
    <div
      css={css`
        position: relative;
        width: ${size}px;
        height: ${size}px;
        border-radius: ${size}px;
        opacity: ${show ? 1 : 0};
        border: ${borderWidth}px solid ${backgroundColor};
        border-top: ${borderWidth}px solid ${foregroundColor};
        animation: ${animation} 600ms linear infinite;
        transition: opacity 200ms ease-in-out;
      `}
      className={className}
    />
  )

  if (center) {
    return (
      <Flex
        css={css`
          width: 100%;
          height: 100%;
          max-height: 200px;
        `}
        horizontal="center"
        vertical="center"
      >
        <Loader />
      </Flex>
    )
  }

  return <Loader />
}
