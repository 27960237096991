/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { DownloadButtons } from '../../components/DownloadButtons'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { LARGE_WIDTH_WITH_PADDING } from '../../components/layout/PageContent'
import { useAuthContext, useViewerQuery } from '../../lib/AuthContext'
import { useT } from '../../lib/i18n/useT'
import { Spacer } from '../../styles/margin'
import { UserAvatarMe } from '../Avatar/UserAvatar'
import { BadgeIcon } from '../Profile/UserBadges'
import { useUserProfileQuery } from '../Profile/queries'
import { ClubItem } from './ClubItem'
import { colors, hexOpacity } from '../../styles/theme'
import { TextNew } from '../../components/TextNew'

export const ProfileBanner = (props: { className?: string }) => {
  const { authUser } = useAuthContext()
  const viewerQuery = useViewerQuery({ skip: !authUser })
  const viewer = viewerQuery.data?.viewer
  const isAdmin = viewerQuery.data?.viewer?.isAdmin
  const query = useUserProfileQuery(viewer?.slug ?? '', viewer === undefined)
  const t = useT()
  const navigate = useNavigate()
  const user = query?.data?.user
  const [showButtons, setShowButtons] = useState(false)

  if (!authUser || authUser.isAnonymous) {
    return null
  }

  if (!viewer || !user) {
    return null
  }

  const badges = [...user.badges].slice(0, 3).reverse()

  return (
    <>
      <Spacer height={(showButtons ? 130 : 0) + 120} />
      <Flex
        css={css`
          position: fixed;
          width: 100%;
          z-index: 10;
        `}
        column
      >
        <DownloadButtons insideProfileBanner setShowButtons={setShowButtons} />
        <Flex
          className={props.className}
          css={css`
            width: 100%;
            height: 120px;
            background-color: ${hexOpacity(colors.black, 0.6)};
            border-bottom: 2px solid #8ee35a;
            box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.4)};
            position: relative;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
          `}
          vertical="flex-end"
          horizontal="center"
        >
          {isAdmin && (
            <Button
              resetStyling
              onClick={(e: any) => {
                navigate('/admin')
                e.stopPropagation()
                e.preventDefault()
              }}
              css={css`
                position: absolute;
                left: 50%;
                top: 20px;
                transform: translateX(-50%);
                z-index: 1;
              `}
            >
              <Text
                color="white"
                css={css`
                  text-decoration: underline;
                `}
              >
                {t('Admin')}
              </Text>
            </Button>
          )}
          <Link
            to={`/profile/${user.slug}`}
            css={css`
              text-decoration: none;
              top: -30px;
              width: 100%;
              display: flex;
              justify-content: center;
            `}
          >
            <Flex
              css={css`
                width: 100%;
                max-width: ${LARGE_WIDTH_WITH_PADDING}px;
                padding: 0px 30px;
                @media (min-width: 599px) {
                  padding: 0px 50px;
                }
              `}
              horizontal="space-between"
            >
              <Flex gap="small">
                <Flex
                  css={css`
                    height: 100%;

                    position: relative;
                    border-radius: 2px;
                  `}
                  vertical="flex-end"
                  horizontal="center"
                >
                  <UserAvatarMe
                    css={css`
                      z-index: 1;
                    `}
                    height={88}
                  />
                </Flex>
                <Flex
                  css={css`
                    padding: 10px 10px;
                  `}
                  column
                  vertical="center"
                >
                  <Text color="white" italic wide>
                    {user.displayName}
                  </Text>
                  <ClubItem
                    club={user.mainClubMembership?.club}
                    variant="green"
                    clickable={false}
                  />
                </Flex>
              </Flex>
              <Flex gap="medium">
                <Flex vertical="center">
                  <Flex
                    css={css`
                      width: 50px;
                      position: relative;
                    `}
                  >
                    {badges.map((badge, index) => {
                      const reverseIndex = badges.length - index - 1
                      return (
                        <Flex
                          vertical="center"
                          horizontal="center"
                          key={`profile_banner_${badge.id}_${index}`}
                          css={css`
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: ${reverseIndex * 10}px;

                            width: 33px;
                            opacity: ${1 - reverseIndex * 0.2};
                          `}
                        >
                          <BadgeIcon
                            id={badge.id}
                            imageUrl={badge.imageUrl}
                            title={badge.title}
                            disableAnimation
                            firstShowing={badge.seenByUser === false}
                          />
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>
                <Flex vertical="center">
                  <Flex
                    css={css`
                      margin-bottom: 30px;
                    `}
                    vertical="flex-end"
                    gap={1}
                  >
                    <TextNew id="viewerCoins" color={'white'}>
                      {viewer.coins ?? 0}
                    </TextNew>
                    <object
                      id="userCoinsPos"
                      css={css`
                        width: 15px;
                      `}
                      data={'/Coin.svg'}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <div
              //Used to prevent ugly flickering at the top while scrolling
              css={css`
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 60%;
                background: linear-gradient(
                  ${hexOpacity(colors.black, 0.5)},
                  ${hexOpacity(colors.black, 0)}
                );
                z-index: -1;
              `}
            />
            {/*<img
          src={`/ProfileBannerRight.svg`}
          css={css`
            margin-right: -2px;
          `}
        />
        <Flex
          css={css`
            height: 100%;
            width: 60px;
            padding-right: 5px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: #515151;
          `}
          horizontal="center"
          vertical="center"
        >
          <Tooltip title={t(`Your badges`)}>
            <img src="/Mask group.svg"></img>
          </Tooltip>
        </Flex>*/}
          </Link>
        </Flex>
      </Flex>
    </>
  )
}
