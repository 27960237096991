const encodeSVGToBase64 = (svgString: string) => {
  const utf8Encoder = new TextEncoder()
  const utf8Array = utf8Encoder.encode(svgString)
  const base64String = window.btoa(String.fromCharCode(...utf8Array))
  return `data:image/svg+xml;base64,${base64String}`
}

const svgToCanvas = (svgElement: SVGSVGElement): Promise<HTMLCanvasElement> => {
  const svgString = new XMLSerializer().serializeToString(svgElement)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const img = new Image()
  img.src = encodeSVGToBase64(svgString)

  return new Promise<HTMLCanvasElement>((resolve) => {
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx?.drawImage(img, 0, 0)
      resolve(canvas)
    }
  })
}

export const exportSVGAsPNG = async (
  svgElement: SVGSVGElement | null,
  filename: string = 'avatar.png'
) => {
  if (!svgElement) {
    console.error('No SVG element found')
    return
  }

  const svgCopy = inlineStylesAndPatterns(svgElement)

  try {
    const canvas = await svgToCanvas(svgCopy)
    const pngUrl = canvas.toDataURL('image/png')

    const link = document.createElement('a')
    link.href = pngUrl
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error exporting SVG as PNG:', error)
  }
}

const inlineStylesAndPatterns = (svgElement: SVGSVGElement) => {
  const styles = `
    <style>
      ${Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join('')
          } catch (e) {
            console.error(e)
            return ''
          }
        })
        .join('')}
    </style>
  `

  // Create a copy of the SVG element to modify
  const svgCopy = svgElement.cloneNode(true) as SVGSVGElement

  // Inline the styles
  svgCopy.insertAdjacentHTML('afterbegin', styles)

  // Ensure any patterns are inlined (assuming patterns are children of defs element)
  const defs = svgCopy.querySelector('defs')
  if (defs) {
    defs.innerHTML = svgElement.querySelector('defs')!.innerHTML
  }

  // Set background to transparent if any rect or similar elements are used as backgrounds
  const backgroundElements = svgCopy.querySelectorAll(
    'rect, path, circle, polygon, ellipse'
  )
  backgroundElements.forEach((el) => {
    if (el.hasAttribute('fill') && el.getAttribute('fill') === 'none') {
      el.setAttribute('fill', 'transparent')
    }
  })

  return svgCopy
}
