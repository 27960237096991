import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

export type GetEventsForCampaignInput = {
  campaignId: string
  advertiserId: string
  startDate: string
  endDate: string
  groupBy: string[]
}

export type EventDataSetExpanded = {
  label: string
  data: number[]
  total: number
  groupBy: string
}

export const getEventsForCampaignQuery = gql`
  query getEventsForCampaign($input: GetEventsForCampaignInput!) {
    getEventsForCampaign(input: $input) {
      label
      data
      total
      groupBy
    }
  }
`

export const useGetEventsForCampaignQuery = (
  input: GetEventsForCampaignInput
) => {
  const query = useQuery<{ getEventsForCampaign: EventDataSetExpanded[] }>(
    getEventsForCampaignQuery,
    {
      variables: {
        input,
      },
    }
  )

  const data = useMemo(() => {
    if (!query.data?.getEventsForCampaign) {
      return []
    }

    const events = [...query.data.getEventsForCampaign].sort(
      (a, b) => b.total - a.total
    )

    return events
  }, [query.data?.getEventsForCampaign])

  return { ...query, data }
}
