/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex } from '../components/layout/Flex'
import { Text } from './Text'
import { PrimaryButton } from './Button'
import { ModalTrigger } from './modal/Modal'
import { useNavigate } from 'react-router-dom'
import { Heading } from './Heading'
import { LoginModalInner } from './modal/LoginModalInner'
import { useT } from '../lib/i18n/useT'

export const PleaseLogIn = () => {
  const navigate = useNavigate()
  const t = useT()
  return (
    <Flex
      css={css`
        width: 100%;
      `}
      column
      horizontal="center"
      gap="medium"
    >
      <Heading level={1} looksLikeLevel={4} color="white">
        {t(`You must be logged in to access this area`)}
      </Heading>
      <Flex gap="medium">
        <PrimaryButton
          onClick={() => {
            navigate(-1)
          }}
          variant="white"
        >
          <Text condensed color="black">
            {t(`Go back`)}
          </Text>
        </PrimaryButton>
        <ModalTrigger
          button={({ openModal }) => (
            <PrimaryButton
              onClick={openModal}
              css={css`
                height: 50px;
              `}
              variant="white"
            >
              <Text size="large" condensed color="black">
                {t('Sign up / Log in')}
              </Text>
            </PrimaryButton>
          )}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      </Flex>
    </Flex>
  )
}
