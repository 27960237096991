/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Part from './Part'
import { heightInner as heightAvatar, width as widthAvatar } from './Avatar'
import { darkenSaturateHexColor } from '../../common/avatarConfig'
import { getSelectableStyles, getStyle } from './util'

const getColorIndexes = (usedColors: number | undefined) => {
  let colorIndexes: number[] = []
  switch (usedColors) {
    case 1:
      colorIndexes = [0]
      break
    case 2:
      colorIndexes = [0, 1]
      break
    case 3:
      colorIndexes = [0, 0, 0, 0, 1] // Just one line of secondary color
      // If changed then a lot of styles must be edited
      break
  }
  return colorIndexes
}

export const SVGPatterns = (props: { parts: any; patternId?: string }) => {
  // Used to trigger this rerender, without rerendering the slider/input

  const patternId = props.patternId //Used to seperate svg patterns between users

  const SVGPattern = (props: {
    name: string
    part: Part
    mirrored: boolean
    dark: boolean //Used at some hairs
  }) => {
    const { part, mirrored, name, dark } = props

    const style = getStyle(part)

    if (!style) {
      const styles = getSelectableStyles(part, true)
        .map((it) => it.id)
        .join(', ')
      throw new Error(
        `Could not find style for ${part.style}. All available styles: ${styles}`
      )
    }

    const {
      rotation: _rotation,
      width: _width,
      centerIndex,
      usedColors,
      offset, //Optional
    } = style
    const colorIndexes = getColorIndexes(usedColors)

    const center = centerIndex !== undefined
    const centerDistance = center
      ? 0.5 + colorIndexes.indexOf(centerIndex ?? 0)
      : 0

    const colors = colorIndexes.map((it) => part.colors[it])

    const width = _width
    const rotation = _rotation
    const sign = mirrored ? '-' : ''
    const translateXStart = widthAvatar / 2
    const translateYStart = heightAvatar - 30 // Found by testing, used to offset transform center
    const translateXEnd = -centerDistance * width + offset
    const translateYEnd = 0
    const id =
      name + (mirrored ? 'Mirrored' : '') + (dark ? 'Dark' : '') + patternId

    return (
      <pattern
        key={id}
        id={id}
        width={colors.length}
        height={colors.length}
        patternTransform={`translate(${translateXStart}, ${translateYStart}) scale(${sign}1,1) rotate(${rotation} 0 0) translate(${translateXEnd}, ${translateYEnd}) scale(${width},${width}) `}
        patternUnits="userSpaceOnUse"
      >
        {colors.map((color: string, index: number) => (
          <line
            key={index}
            x1={`${index}.5`}
            y1="0"
            x2={`${index}.5`}
            y2="10"
            css={css`
              stroke: ${darkenSaturateHexColor(
                color,
                dark ? 30 : 0,
                dark ? 30 : 0
              )};
              stroke-width: 1.02;
            `} //1.02 since it can sometimes be 1 pixel off etc
          />
        ))}
      </pattern>
    )
  }

  return (
    <svg height="0px" width="0px">
      {Object.keys(props.parts).map((name: string) =>
        [false, true].map((mirrored) =>
          [false, true].map((dark) => (
            <SVGPattern
              key={name + mirrored + dark}
              name={name}
              part={props.parts[name]}
              mirrored={mirrored}
              dark={dark}
            />
          ))
        )
      )}
    </svg>
  )
}
