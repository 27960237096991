/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Header } from '../../components/Header'
import { Heading } from '../../components/Heading'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { Page } from '../../components/leagueQuiz/Page'
import { useAuthContext } from '../../lib/AuthContext'
import { margin, Spacer } from '../../styles/margin'
import { buttonReset } from '../../styles/styles'
import { colors } from '../../styles/theme'
import { ChallengeGroupQuizResults } from './ChallengeGroupQuizResultPage'
import { useChallengeGroupQuery } from './queries'

export const ChallengeNavItem = ({
  title,
  onClick,
  selected,
  className,
  textColor,
  textStyle,
}: {
  title: string
  onClick: () => void
  selected: boolean
  className?: string
  textColor?: string
  textStyle?: SerializedStyles
}) => {
  return (
    <button
      onClick={onClick}
      css={[
        buttonReset,
        css`
          opacity: ${selected ? 1 : 0.5};
          border-bottom: 2px solid ${selected ? textColor : 'transparent'};
        `,
      ]}
      className={className}
    >
      <Text
        extraCondensed
        strong
        color={textColor || 'black'}
        css={[
          css`
            font-style: italic;
            font-size: 24px;
            line-height: 24px;
          `,
          textStyle,
        ]}
      >
        {title}
      </Text>
    </button>
  )
}

const Content = ({ groupId }: { groupId: string }) => {
  const { data: groupData, loading } = useChallengeGroupQuery(groupId)

  const { authUser } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (groupData?.group.isChallengeLeague) {
      //was challenge-league
      navigate(`/league/${group.id}`, {
        replace: true,
      })
    }
  }, [groupData?.group.isChallengeLeague])

  if (loading) {
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <Loader />
      </Flex>
    )
  }

  if (!groupData || !groupData.group) {
    const reason = !groupData
      ? 'no group data'
      : !groupData.group
      ? 'no group'
      : 'unknown'
    return (
      <Flex horizontal="center" css={[margin.top('large')]}>
        <DefaultError
          sentryErrorMessage={`ChallengeGroupPage | groupId: ${groupId}, reason: ${reason}`}
        />
      </Flex>
    )
  }

  const group = groupData.group
  const quiz = group.quizzes[0]
  const userIsMember = group.members.some(
    (member) => member.id === authUser?.uid
  )

  if (!authUser || !userIsMember) {
    const reason = !authUser
      ? 'no authUser'
      : !userIsMember
      ? 'user is not a member of the group'
      : 'unknown'
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeGroupPage | groupId: ${groupId}, reason: ${reason}`}
      />
    )
  }

  if (!quiz) {
    return (
      <DefaultError
        sentryErrorMessage={`ChallengeGroupPage | groupId: ${groupId}, reason: no quiz`}
      />
    )
  }

  const grouphasTitle = group.title && group.title.length > 0

  return (
    <Flex column grow gap="medium">
      {grouphasTitle && (
        <>
          <Flex horizontal="space-between" vertical="center">
            <Flex column>
              <Heading level={1} looksLikeLevel={3} extraCondensed>
                {group.title}
              </Heading>
              <Heading level={2} looksLikeLevel={5} extraCondensed>
                {group.description}
              </Heading>
            </Flex>
          </Flex>
          <Spacer height={16} />
        </>
      )}
      <ChallengeGroupQuizResults group={group} quiz={quiz} />
    </Flex>
  )
}

export const ChallengeGroupPage = () => {
  const groupId = useParams<{ groupId: string }>().groupId!

  return (
    <Layout backgroundColor={colors.grey100}>
      <Header color="grey" />
      <Page dense>
        <Content groupId={groupId} />
      </Page>
    </Layout>
  )
}
