/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { t } from 'i18next'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultError } from '../../components/DefaultError'
import { Loader } from '../../components/Loader'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { Club } from '../Clubs/queries'
import { EditClubComponent } from './EditClubComponent'
import { ProfileClubComponent } from './ProfileClubComponent'
import { useSetMainClubMutation, useUserClubMembershipsQuery } from './queries'
import { useHorizontalScroll } from '../Avatar/AvatarSelectUI'

export const UserClubMemberships: FC<{
  userSlug: string
  isViewer: boolean
}> = ({ userSlug, isViewer }) => {
  const query = useUserClubMembershipsQuery(userSlug)
  const navigate = useNavigate()
  const [setMainClubMutation] = useSetMainClubMutation()

  const [isEditingMainClub, setIsEditingMainClub] = useState(false)

  const scrollRef = useHorizontalScroll()

  const setMainClub = (club: Club) => {
    setMainClubMutation({ variables: { clubId: club.id } })
    setIsEditingMainClub(false)
  }

  if (query.loading && !query.data) {
    return (
      <Flex horizontal="center" css={margin.top('huge')}>
        <Loader />
      </Flex>
    )
  }

  if (query.error) {
    return (
      <DefaultError
        css={margin.top('huge')}
        sentryErrorMessage={`UserClubMemberships | userSlug: ${userSlug}, ${query.error.message}`}
      />
    )
  }

  const clubMemberships = query.data
  return (
    <div>
      <Text size={'medium'} color={colors.white} strong italic>
        {t('My clubs').toUpperCase()}
      </Text>
      <Spacer height={16} />
      {clubMemberships.length > 0 ? (
        <Flex
          ref={scrollRef}
          css={css`
            overflow-x: auto;
          `}
          gap="medium"
        >
          {clubMemberships.map(({ club }) => (
            <ProfileClubComponent
              key={club.id}
              club={club}
              isEditingMainClub={isEditingMainClub}
              onClick={() => {
                isEditingMainClub
                  ? setMainClub(club)
                  : navigate(`/clubs/${club.slug}`)
              }}
            />
          ))}
          {isViewer && clubMemberships.length > 1 && !isEditingMainClub && (
            <EditClubComponent setIsEditingMainClub={setIsEditingMainClub} />
          )}
        </Flex>
      ) : (
        <Text color="white">{t('No clubs yet')}</Text>
      )}
    </div>
  )
}
