/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { BiFullscreen } from 'react-icons/bi'
import { Navigate, useParams } from 'react-router-dom'
import { useWakeLock } from 'react-screen-wake-lock'
import { DefaultError } from '../../../components/DefaultError'
import { HeadingNew } from '../../../components/HeadingNew'
import { Flex } from '../../../components/layout/Flex'
import { Layout } from '../../../components/layout/Layout'
import { Loader as LoadingComponent } from '../../../components/Loader'
import { FcQuizQrCode } from '../../../components/QrCodeComponent'
import { TextNew } from '../../../components/TextNew'
import { isDevelopment } from '../../../config/config'
import { useT } from '../../../lib/i18n/useT'
import { buttonReset } from '../../../styles/styles'
import { colors } from '../../../styles/theme'
import { DisplayLiveQuizInner } from '../../DisplayLiveQuiz/DisplayLiveQuizPage'
import {
  getLiveQuizStatus,
  LiveQuizStatus,
} from '../../LiveQuiz/LiveQuizHostPage'

import { FramedClubLogo } from '../../../components/Picture'
import { viewWidthToNumber } from '../../Admin/Utils'
import { Club, useClubProfileQuery } from '../../Clubs/queries'
import { useGetLiveQuizDisplayInstanceForClubQuery } from '../../LiveQuiz/queries'
import { DisplayPageLeaderboard } from './DisplayPageLeaderboard'
import { PageRefreshComponent } from './PageRefreshComponent'
import {
  getCTATextForClub,
  getIconImageForClub,
  getSpecialClubLanguage,
  getTextColorForClub,
  ScreenSize,
} from './util'

const Content = ({
  club,
  isLandscape,
  screenSize,
}: {
  club: Club
  isLandscape: boolean
  screenSize: ScreenSize
}) => {
  const textColor = getTextColorForClub({
    slug: club.slug,
    textColor: club.textColor,
  })
  const leagueUrl = `https://fcquiz.app/${club.locale}/clubs/${club.slug}`

  const leagueLanguage = getSpecialClubLanguage({
    slug: club.slug,
    locale: club.locale,
  })

  const iconUrl = getIconImageForClub(club)

  return (
    <Flex
      column={!isLandscape}
      grow
      css={[
        css`
          margin: auto auto;
          width: 100%;
          aspect-ratio: ${isLandscape ? 16 / 9 : 9 / 16};
        `,
        !isLandscape &&
          css`
            padding-top: 10vh;
          `,
      ]}
    >
      <PageRefreshComponent />
      <Flex
        column
        vertical="center"
        horizontal="center"
        css={[
          css`
            padding: 0 40px;
            gap: 3vh;
          `,
          isLandscape &&
            css`
              flex: 3;
            `,
          !isLandscape &&
            css`
              gap: 24px;
            `,
        ]}
      >
        {iconUrl && (
          <FramedClubLogo
            url={iconUrl}
            size={viewWidthToNumber('10vw')}
            shape={club.logoShape}
            css={css`
              margin: 0 auto;
            `}
            frameColor={textColor}
          />
        )}

        <HeadingNew
          italic
          condensed
          level={1}
          color={textColor}
          textAlign="center"
          css={[
            css`
              font-size: 9vh;
              line-height: 1;
              letter-spacing: -0.025em;
              text-transform: capitalize;
            `,
            !isLandscape &&
              css`
                font-size: 6vh;
              `,
          ]}
        >
          {getCTATextForClub(club)}
        </HeadingNew>

        <Flex
          css={[
            css`
              height: 40%;
              aspect-ratio: 1;
            `,
            !isLandscape &&
              css`
                min-height: 300px;
                aspect-ratio: 1;
              `,
          ]}
        >
          <FcQuizQrCode
            url={leagueUrl}
            backgroundColor={club.backgroundColor}
            foregroundColor={textColor}
            logoScale={3}
          />
        </Flex>
      </Flex>
      <Flex
        column
        vertical="center"
        css={[
          css`
            padding: 40px;
          `,
          isLandscape &&
            css`
              padding-top: 0px;
              padding-right: 100px;
              flex: 2;
            `,

          !isLandscape &&
            css`
              padding: 0 24px;
            `,
        ]}
      >
        <DisplayPageLeaderboard
          club={club}
          screenSize={screenSize}
          isLandscape={isLandscape}
          language={leagueLanguage}
        />
      </Flex>
    </Flex>
  )
}

const PageLayout = ({ club }: { club: Club }) => {
  const liveInstanceQuery = useGetLiveQuizDisplayInstanceForClubQuery(club.id)
  const liveInstance = liveInstanceQuery.data?.getLiveQuizDisplayInstanceForClub

  const liveInstanceStatus = liveInstance
    ? getLiveQuizStatus({ ...liveInstance })
    : null

  useEffect(() => {
    if (!liveInstanceStatus || liveInstanceStatus === LiveQuizStatus.FINISHED) {
      liveInstanceQuery.startPolling(10000)
      return
    }

    liveInstanceQuery.startPolling(2000)
  }, [club.id, liveInstanceStatus])

  const { isSupported, released, request, release } = useWakeLock()

  useEffect(() => {
    if (isSupported && !released) {
      request()
    }

    return () => {
      if (isSupported && released) {
        release()
      }
    }
  }, [isSupported, released, request, release])

  const clubLanguage = getSpecialClubLanguage({
    slug: club.slug,
    locale: club.locale,
  })

  const t = useT(clubLanguage)
  const fullscreenHandle = useFullScreenHandle()

  const windowAspectRatio = window.innerWidth / window.innerHeight

  const [isLandscape, setIsLandscape] = useState(windowAspectRatio > 1)
  const [screenSize, setScreenSize] = useState<ScreenSize>(
    window.innerWidth < 1920
      ? 'small'
      : window.innerWidth < 2560
      ? '1080p'
      : window.innerWidth < 3840
      ? '1440p'
      : '4k'
  )

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth / window.innerHeight > 1)
      setScreenSize(
        window.innerWidth < 1920
          ? 'small'
          : window.innerWidth < 2560
          ? '1080p'
          : window.innerWidth < 3840
          ? '1440p'
          : '4k'
      )
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <FullScreen handle={fullscreenHandle}>
      <Flex
        column
        grow
        css={css`
          background-color: ${club.backgroundColor};
          position: relative;
          height: ${isLandscape ? '100vh' : 'auto'};
          overflow: hidden;
        `}
      >
        {isDevelopment && (
          <TextNew
            css={css`
              position: absolute;
              top: 8px;
              left: 16px;
              background-color: ${colors.black};
              color: ${colors.white};
              padding: 4px;
              font-size: 14px;
            `}
          >
            {screenSize}
          </TextNew>
        )}

        {!fullscreenHandle.active && (
          <button
            aria-label={t('Enter fullscreen')}
            onClick={fullscreenHandle.enter}
            css={[
              buttonReset,
              css`
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 16px;
                right: 16px;
                background-color: ${club.textColor};
                height: 40px;
                width: 40px;
                border-radius: 50%;
                opacity: 0.8;
                transition: opacity 0.2s;
                z-index: 1000;

                &:hover {
                  opacity: 1;
                }
              `,
            ]}
          >
            <BiFullscreen
              size={24}
              css={css`
                color: ${club.backgroundColor};
                padding: 0;
                line-height: 1;
                margin: 0;
              `}
            />
          </button>
        )}

        {liveInstance && (
          <DisplayLiveQuizInner
            instance={liveInstance}
            isLandscape={isLandscape}
            screenSize={screenSize}
          />
        )}

        {!liveInstance && (
          <Content
            club={club}
            isLandscape={isLandscape}
            screenSize={screenSize}
          />
        )}
      </Flex>
    </FullScreen>
  )
}

const Loader = ({ slug }: { slug: string }) => {
  const clubQuery = useClubProfileQuery(slug)

  if (clubQuery.loading) {
    return <LoadingComponent center variant="yellow" />
  }

  if (clubQuery.error || !clubQuery.data?.club) {
    const reason = clubQuery.error?.message ?? 'League not found'
    return <DefaultError sentryErrorMessage={`LeagueDisplayPage | ${reason}`} />
  }

  const club = clubQuery.data.club

  return <PageLayout club={club} />
}

export const ClubDisplayPage = () => {
  const slug = useParams().slug

  if (!slug) {
    return <Navigate to="/" />
  }

  return (
    <Layout
      hideNavbar
      hideHeaderPadding
      backgroundColor={colors.green600}
      css={css`
        height: 100vh;
      `}
    >
      <Loader slug={slug} />
    </Layout>
  )
}
