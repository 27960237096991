/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import useFitText from 'use-fit-text'
import { Text } from './Text'

type Props = ComponentProps<typeof Text>

export const FitText: FC<Props> = ({ children, autoHeight, ...rest }) => {
  const [show, setShow] = useState(false)
  const onFinish = useCallback(() => setShow(true), [])

  useEffect(() => {
    // make sure onFinish is called when the component is mounted (with some delay)
    setTimeout(onFinish, 100)
  }, [onFinish])

  const { fontSize, ref } = useFitText({
    minFontSize: 100,
    maxFontSize: 200,
    onFinish,
  })

  return (
    <Text
      ref={ref}
      css={[
        css`
          font-size: ${fontSize};
          height: ${!autoHeight && parseInt(fontSize) > 100 ? '10vh' : 'auto'};
          max-height: ${parseInt(fontSize) > 100 ? '10vh' : 'auto'};
          width: 100%;
          ${!show &&
          css`
            visibility: hidden;
          `}
        `,
      ]}
      {...rest}
    >
      {children}
    </Text>
  )
}
