import { useCallback, useMemo, useState } from 'react'
import { useRefValue } from './useRefValue'
import { useIsMounted } from './useIsMounted'

type Values = Record<string, any>
type InitialValues<T> = T | (() => T)
export type Errors<T> = Record<keyof T, string | undefined>
export type Validate<T> = Partial<
  { [K in keyof T]: (value: T[K], values: T) => string | undefined }
>
export type SubmitFunction<T> = (params: {
  values: T
  setSubmitError: (message: string | undefined) => void
}) => Promise<void>

export function useFormFields<T extends Values>(
  initialValues: InitialValues<T>,
  validate?: Validate<T>
) {
  const savedValidate = useRefValue(validate)

  const [values, setValues] = useState(initialValues)
  const setValue = useCallback(
    <TField extends keyof T>(field: TField, value: T[TField]) => {
      setValues((values) => ({ ...values, [field]: value }))
    },
    []
  )

  const { isValid, fieldErrors } = useMemo(() => {
    const validate = savedValidate.current
    const fieldErrors: Record<string, string | undefined> = {}

    Object.keys(values).forEach((field) => {
      if (validate?.hasOwnProperty(field)) {
        fieldErrors[field] = validate[field]!(values[field], values)
      } else {
        fieldErrors[field] = undefined
      }
    })

    return {
      isValid: Object.values(fieldErrors).filter(Boolean).length === 0,
      fieldErrors: fieldErrors as Errors<T>,
    }
  }, [values, savedValidate])

  return {
    values,
    setValue,
    fieldErrors,
    isValid,
  }
}

export type FormParameters<T extends Values> = {
  initialValues: InitialValues<T>
  validate?: Validate<T>
  onSubmit: SubmitFunction<T>
}

export function useForm<T extends Values>({
  initialValues,
  validate,
  onSubmit,
}: FormParameters<T>) {
  const { values, setValue, fieldErrors, isValid } = useFormFields(
    initialValues,
    validate
  )
  const savedOnSubmit = useRefValue(onSubmit)
  const isMounted = useIsMounted()

  const [submitting, setSubmitting] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState<string | undefined>(undefined)

  const submitForm = useCallback(async () => {
    if (submitting) {
      return
    }

    setSubmitAttempted(true)

    if (isValid) {
      setSubmitError(undefined)
      setSubmitting(true)
      return savedOnSubmit
        .current({ values, setSubmitError })
        .then(() => setSubmitSuccess(true))
        .finally(() => {
          if (isMounted()) {
            setSubmitting(false)
          }
        })
    }
  }, [values, isValid, savedOnSubmit, submitting, isMounted, setSubmitError])

  const clearSubmitAttempt = useCallback(() => {
    setSubmitAttempted(false)
    setSubmitSuccess(false)
    setSubmitError(undefined)
  }, [])

  return {
    values,
    setValue,
    isValid,
    fieldErrors,
    submitForm,
    submitting,
    submitAttempted,
    submitSuccess,
    submitError,
    clearSubmitAttempt,
  }
}
