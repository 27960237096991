/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const LeagueBackground = ({
  imageUrl,
  v2Design,
}: {
  imageUrl?: string
  v2Design?: boolean
}) => {
  return (
    <>
      {imageUrl ? (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: ${v2Design ? 60 : 50}vh;
            z-index: -1;
          `}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 1px;
              background-image: url(${imageUrl});
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              z-index: -1;
            `}
          />
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: -1;
              background: ${v2Design
                ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7),  black)`
                : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8), black, black)`};
            `}
          />
        </div>
      ) : (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 20px;
            right: 20px;
            height: 50vh;
            background-image: url('/logo-green.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: -1;
            opacity: 0.4;
          `}
        />
      )}
    </>
  )
}
