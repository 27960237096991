/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import { Header } from '../../components/Header'
import { HeadingNew } from '../../components/HeadingNew'
import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Page } from '../../components/leagueQuiz/Page'
import { SelectInput } from '../../components/SelectInput'
import { TextNew } from '../../components/TextNew'
import { isDevelopment } from '../../config/config'
import { useViewerQuery } from '../../lib/AuthContext'
import { Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useNow } from '../../utils/useNow'
import { AdminAccessControlComponent } from './AdminAccessControlComponent'
import { AdminNavbar } from './Navbar'
import { LoadTestType, useLoadTestMutation } from './queries'

const Content = () => {
  const viewerQuery = useViewerQuery()

  const [userWarned, setUserWarned] = useState(false)

  const [testStartedAt, setTestStartedAt] = useState<number | undefined>(
    undefined
  )

  const now = useNow({ updateFrequencyInMs: 1000 })

  const elapsedTimeSeconds = testStartedAt
    ? Math.floor((now.getTime() - testStartedAt) / 1000)
    : 0

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (userWarned) {
        setUserWarned(false)
      }
    }, 5000)
    return () => {
      clearTimeout(timeOut)
    }
  }, [userWarned])

  const [loadTestType, setLoadTestType] = useState<LoadTestType>('livequiz')

  const [targetEnvironment, setTargetEnvironment] = useState<
    'local' | 'test' | 'production'
  >('local')

  const [loading, setLoading] = useState(false)

  const [loadtestMutation] = useLoadTestMutation()

  const viewer = viewerQuery.data?.viewer
  const viewerIsAdmin = viewer?.isAdmin

  const handleLoadTest = async () => {
    if (loading) {
      return
    }

    // Warn user before starting the test
    if (!userWarned) {
      setUserWarned(true)
      return
    }

    if (!testStartedAt) {
      setTestStartedAt(now.getTime())
    }

    setLoading(true)
    try {
      loadtestMutation({
        variables: { input: { type: loadTestType, targetEnvironment } },
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (!viewerIsAdmin || !viewer) {
    return (
      <div>
        <HeadingNew level={1} looksLikeLevel={3}>
          Access Denied
        </HeadingNew>

        <TextNew>You do not have permission to access this page.</TextNew>
      </div>
    )
  }

  if (!isDevelopment) {
    return (
      <div>
        <HeadingNew level={1} looksLikeLevel={3}>
          Access Denied
        </HeadingNew>

        <TextNew>Load test is only available in development</TextNew>
      </div>
    )
  }

  const infoText = () => {
    switch (loadTestType) {
      case 'homepage':
        return 'This test simulates 1000 users visiting the homepage at the same time.'
      case 'leaguepage':
        return 'This test simulates 1000 users visiting the league page at the same time. The league with the most members will be used.'
      case 'livequiz':
        return 'This test simulates 1000 users playing a live-quiz'
      default:
        return 'This will load test the homepage. This will simulate 1000 users visiting the homepage at the same time.'
    }
  }

  return (
    <Flex column grow>
      <Spacer height={40} />
      <Flex horizontal="space-between" gap={'medium'} wrap>
        <HeadingNew level={1} looksLikeLevel={3}>
          Load test
        </HeadingNew>

        <Flex column gap={'medium'}>
          <SelectInput
            label="Test type"
            onValue={(value) => setLoadTestType(value as LoadTestType)}
          >
            <option value={'livequiz'}>LiveQuiz</option>
            <option value={'homepage'}>HomePage</option>
            <option value={'leaguepage'}>LeaguePage</option>
          </SelectInput>

          <SelectInput
            label="Target environment"
            onValue={(value) =>
              setTargetEnvironment(value as 'local' | 'test' | 'production')
            }
          >
            <option value={'local'}>Local</option>
            <option value={'test'}>Test</option>
            <option value={'production'}>Production</option>
          </SelectInput>
        </Flex>
      </Flex>

      <Spacer height={40} />
      <TextNew textAlign="center">{infoText()}</TextNew>
      <Spacer height={40} />

      {!loading && (
        <>
          <TextNew textAlign="center" italic>
            When started, the test will run for check your api-console for
            state.
          </TextNew>
        </>
      )}
      {loading && (
        <TextNew textAlign="center" italic>
          Time elapsed: {elapsedTimeSeconds} seconds
        </TextNew>
      )}
      <Spacer height={20} />

      {!loading && (
        <PrimaryButton
          onClick={handleLoadTest}
          disabled={loading}
          loading={loading}
          variant={userWarned ? 'dangerRed' : 'black'}
        >
          {userWarned ? 'Are you sure?' : 'Start load simulation'}
        </PrimaryButton>
      )}

      {loading && (
        <PrimaryButton
          onClick={() => {
            setTestStartedAt(undefined)
            setUserWarned(false)
            setLoading(false)
          }}
          variant="black"
        >
          Reset timer (does not stop test)
        </PrimaryButton>
      )}

      <Spacer height={20} />

      {!loading && (
        <TextNew textAlign="center" italic color={colors.grey400}>
          Be aware that this test will put a lot of load on the server, and may
          lead to increased costs. Use with caution.
        </TextNew>
      )}
      <Spacer height={200} />
    </Flex>
  )
}

export const AdminLoadTestPage = () => {
  return (
    <Layout>
      <AdminAccessControlComponent>
        <PageContent>
          <Header color="green" />
          <AdminNavbar activePage="loadtest" />
        </PageContent>
        <Page>
          <Content />
        </Page>
      </AdminAccessControlComponent>
    </Layout>
  )
}
