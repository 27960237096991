/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { capitalize } from 'lodash'
import { useAvatarContext } from '../AvatarPage'
import { getStyle } from '../util'

// Centers horizontally
const Centerer = (props: { children: any }) => {
  return (
    <g
      css={css`
        * {
          overflow: visible !important;
        }
        path {
          transform: translate(-50%, 0%) !important;
        }
      `}
    >
      {props.children}
    </g>
  )
}

export const Selector = (props: {
  styles: any
  selectedOptionKey: any
  x?: any
  y?: any // Use 'option' inside figma to find this number. Red line from rectangle to the top of base
}) => {
  const { parts } = useAvatarContext()

  const selectedPart = capitalize(props.selectedOptionKey)

  const part = parts[selectedPart]

  const style = part.style

  const x = props.x ?? 0 // Mostly unnecessary since it is centered
  const y = props.y ?? 0
  const dx = props.styles[style].dx ?? 0
  const dy = props.styles[style].dy ?? 0

  const child = props.styles[style].svg

  const chest = parts['Chest']
  //Disable other shirt parts if chest is premium
  if (
    getStyle(chest).premium &&
    ['Sleeves', 'Shoulders', 'Arms', 'Collar'].includes(selectedPart)
  ) {
    return <></>
  }

  return (
    <g
      className={capitalize(props.selectedOptionKey) + 'Group'}
      transform={`translate(${x + dx},${y + dy})`}
    >
      <Centerer>{child}</Centerer>
    </g>
  )
}
