import { ReactComponent as Collar0 } from './Collar 0.svg'
import { ReactComponent as CollarA } from './Collar A.svg'
import { ReactComponent as CollarB } from './Collar B.svg'
import { ReactComponent as CollarC } from './Collar C.svg'
import { ReactComponent as CollarD } from './Collar D.svg'
import { ReactComponent as CollarE } from './Collar E.svg'
import { ReactComponent as CollarF } from './Collar F.svg'
import { ReactComponent as CollarH } from './Collar H.svg'

//If adding new indexes, add the class + fill at avatar/index.tsx
export const collarStyles = {
  Collar0: {
    icon: <Collar0 />,
    usedColors: 0,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  CollarA: {
    icon: <CollarA />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  CollarB: {
    icon: <CollarB />,
    usedColors: 2,
    width: 100,
    centerIndex: undefined,
    rotation: 180,
    offset: 0,
    premium: false,
  },
  CollarC: {
    icon: <CollarC />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  CollarD: {
    icon: <CollarD />,
    usedColors: 2,
    width: 60,
    centerIndex: undefined,
    rotation: -45,
    offset: -18,
    premium: false,
  },
  CollarE: {
    icon: <CollarE />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  CollarF: {
    icon: <CollarF />,
    usedColors: 3,
    width: 15,
    centerIndex: 0,
    rotation: 45,
    offset: 3,
    premium: false,
  },
  CollarH: {
    icon: <CollarH />,
    usedColors: 2,
    width: 30,
    centerIndex: 0,
    rotation: -30,
    offset: 0,
    premium: false,
  },
}
