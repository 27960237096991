/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { Text } from '../../components/Text'
import { Flex } from '../../components/layout/Flex'
import { growAndFadeBadge } from '../../styles/animations'
import { Spacer, margin } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { Badge } from '../League/queries'
import { useBadgeSetSeenByUserMutation } from './queries'
import { useHorizontalScroll } from '../Avatar/AvatarSelectUI'

type BadgeProps = Badge & { className?: string }

/** Add styles to your component, including the animation */
const shimmerAnimation = keyframes`
  from {
    left: -300px;
    background: rgba(255, 255, 255, 0.3);
  }
  to {
    left: 250px;
    background: rgba(255, 255, 255, 0.2);
  }
`

export const BadgeIcon: FC<{
  id: string
  imageUrl: string
  title: string
  disableAnimation?: boolean
  firstShowing?: boolean
  size?: number
}> = ({
  imageUrl,
  title,
  disableAnimation: disableAnimation = false,
  firstShowing = false,
  id,
  size = 64,
}) => {
  const [showAnimation, setShowAnimation] = useState(false)

  const [showConfetti, setShowConfetti] = useState(false)

  const [setBadgeSeenByUser] = useBadgeSetSeenByUserMutation()

  const badgeIsSvg = imageUrl.includes('.svg')

  // Show the confetti on first showing
  const firstShowingAnimationTime = 2500
  useEffect(() => {
    if (firstShowing) {
      const timer = setTimeout(async () => {
        setShowConfetti(true)
        await setBadgeSeenByUser({ variables: { badgeId: id } })
      }, firstShowingAnimationTime)
      return () => clearTimeout(timer)
    }
  }, [firstShowing])

  // Run the animation randomly, infinitely
  useEffect(() => {
    // random number between 3000 and 8000
    const intervalMs = Math.floor(Math.random() * 5000) + 3000
    const interval = setInterval(() => {
      if (!disableAnimation) {
        setShowAnimation(Math.random() > 0.5)
      }
    }, intervalMs)

    return () => clearInterval(interval) //This is important
  }, [disableAnimation])

  return (
    <Flex
      vertical="center"
      horizontal="center"
      css={[
        css`
          position: relative; /* Added to support absolute positioning of pseudo-elements */
          width: ${size}px;
          height: ${size}px;
          overflow: hidden;
          border-radius: 5px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -300px;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            transform: skewX(-30deg);
            transition: left 1s, background 1s; /* Transition for left and background */
          }
        `,
        showAnimation &&
          css`
            &:before {
              animation: ${shimmerAnimation} 3s forwards;
            }
          `,
        firstShowing &&
          css`
            animation: ${growAndFadeBadge} ${firstShowingAnimationTime}ms
              forwards;
          `,
      ]}
    >
      {!badgeIsSvg && (
        <img
          src={imageUrl}
          alt={title}
          css={css`
            object-fit: contain;
            width: 100%;
            height: 100%;
          `}
        />
      )}

      {badgeIsSvg && (
        <object
          data={imageUrl}
          css={css`
            object-fit: contain;
            width: 100%;
            height: 100%;
          `}
        />
      )}

      {showConfetti && (
        <ConfettiExplosion
          css={css`
            position: absolute;
            left: 13px;
          `}
          particleCount={100}
          force={0.2}
          duration={firstShowingAnimationTime}
          zIndex={10}
          colors={[
            colors.green300,
            colors.green400,
            colors.yellow300,
            colors.yellow400,
            colors.orange400,
            colors.red400,
          ]}
        />
      )}
    </Flex>
  )
}

export const UserBadgeComponent: FC<BadgeProps> = ({
  title,
  imageUrl,
  id,
  earnedAt,
}) => {
  return (
    <Flex
      column
      horizontal={'center'}
      vertical={'center'}
      css={css`
        height: 140px;
        width: 82px;
        z-index: 0;
        display: flex;
        text-align: center;
        justify-content: flex-start;
        align-items: center;
      `}
    >
      <Text
        size="tiny"
        strong
        css={css`
          color: ${colors.white};
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 82px;
          white-space: nowrap;
        `}
      >
        {title}
      </Text>
      <Spacer height={4} />
      <BadgeIcon id={id} imageUrl={imageUrl} title={title} disableAnimation />
      {earnedAt && (
        <Text
          size="tiny"
          css={css`
            color: ${colors.white};
            font-size: 14px;
            line-height: 24px;
            opacity: 0.75;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 82px;
            white-space: nowrap;
          `}
        >
          {earnedAt}
        </Text>
      )}
    </Flex>
  )
}

type UserBadgesProps = { badges: Badge[] }

export const UserBadges: FC<UserBadgesProps> = ({ badges }) => {
  const scrollRef = useHorizontalScroll()
  return (
    <Flex column>
      <Text size={'medium'} color={colors.white} strong italic>
        {t(`My Badges`).toUpperCase()}
      </Text>
      <Spacer height="small" />
      <Flex
        ref={scrollRef}
        gap={'medium'}
        css={css`
          overflow-x: auto;
        `}
      >
        {badges.length > 0 ? (
          badges.map((badge) => (
            <UserBadgeComponent
              key={`${badge.id}`}
              {...badge}
              css={margin.bottom(4)}
            />
          ))
        ) : (
          <Text color="white"> {t(`No badges yet`)} </Text>
        )}
      </Flex>
    </Flex>
  )
}
