import { gql, useMutation, useQuery } from '@apollo/client'
import { CareerPathEntry } from '../../components/careerPath/CareerPath'
import { LeagueQuizStatus } from '../../lib/types'
import { ChallengeGroupResult } from '../Challenge/queries'
import { QuizType } from '../Generate/GeneratePage'
import { CareerPathTeam } from '../Generate/queries'
import {
  Badge,
  LeaderboardItem,
  leaderboardItemFragment,
  LeagueFrequency,
  LeagueQuizMinimal,
} from '../League/queries'

export const leagueQuizShortFragment = gql`
  fragment LeagueQuizShortFragment on LeagueQuiz {
    id
    title
    league {
      id
      title
      leagueCardImageUrl
      imageUrl
    }
  }
`

export type LeagueQuiz = {
  id: string
  title: string
  viewerCanStartQuiz: boolean
  slug: string
  imageUrl?: string
  questionsCount: number
  availableFrom: string
  availableTo?: string
  status: LeagueQuizStatus
  participantCount: number
  embedPromotionalText?: string
  league: {
    id: string
    slug: string
    title: string
    imageUrl?: string
    leagueCardImageUrl?: string
    frequency?: LeagueFrequency
    viewerIsSubscribed?: boolean
    recommendedQuiz?: LeagueQuiz
  }
  viewerData: {
    canStartQuiz: boolean
    hasCompletedQuiz: boolean
    activeQuizInstance?: { id: string }
    result?: {
      leagueQuizInstanceId: string
      correctAnswersCount: number
      correctAnswerScore: number
      timeBonus: number
      totalScore: number
    }
    leaderboardEntry: {
      rank: number
    }
  }
  challengeGroupResults?: ChallengeGroupResult[]
  challengeGroupId?: string
  leaderboard?: LeaderboardItem[]
  quizType: QuizType
}

export type CompletedQuestionAlternative = {
  id: string
  isCorrectAlternative: boolean
  selectedAlternative: boolean
  text: string
  numberOfAnswers: number
}

export type CompletedQuestion = {
  id: string
  text: string
  imageUrl: string
  alternatives: CompletedQuestionAlternative[]
  secondsLeft: number
  otherUserAnswer?: ChallengeOtherUserAnswer
  points?: number
}

export type ChallengeOtherUserAnswer = {
  alternativeId?: string
  userId: string
  username: string
  avatarData?: string
  score: number
  timeBonus: number
  correct: boolean
  secondsLeft: number
}

type ActiveQuestionAlternative = {
  id: string
  text: string
}

export type ActiveQuestion = {
  id: string
  text: string
  imageUrl?: string
  alternatives: ActiveQuestionAlternative[]
  secondsLeft: number
  maxTimeToAnswerInSeconds: number
  timeToReadQuestionInMs: number
}

export enum LeagueQuizInstanceStatus {
  active = 'active',
  completed = 'completed',
}

export type LeagueQuizInstanceResult = {
  correctAnswersCount: number
  correctAnswerScore: number
  timeBonus: number
  totalScore: number
}

export type LeagueQuizInstance = {
  id: string
  status: LeagueQuizInstanceStatus
  leagueQuiz: LeagueQuiz
  liveHostInstanceId?: string
  completedQuestions: CompletedQuestion[]
  completedQuestionsLive: CompletedQuestion[]
  activeQuestion: ActiveQuestion | null
  activeQuestionLive: ActiveQuestion | null

  readableQuestionLive: ActiveQuestion | null
  result: LeagueQuizInstanceResult
  leaderboardParticipantCount: number | null
  quizType: QuizType
}

export const activeQuestionFragment = gql`
  fragment ActiveQuestionFragment on ActiveQuestion {
    id
    text
    imageUrl
    alternatives {
      id
      text
    }
    secondsLeft
    maxTimeToAnswerInSeconds
    timeToReadQuestionInMs
  }
`

export const completedQuestionFragment = gql`
  fragment CompletedQuestionFragment on CompletedQuestion {
    id
    text
    imageUrl
    secondsLeft
    points
    alternatives {
      id
      isCorrectAlternative
      selectedAlternative
      text
      numberOfAnswers
    }
  }
`

const leagueQuizInstanceFragment = gql`
  fragment LeagueQuizInstanceFragment on LeagueQuizInstance {
    id
    status
    leagueQuiz {
      id
      title
      slug
      viewerCanStartQuiz
      questionsCount
      participantCount
      embedPromotionalText
      league {
        id
        slug
        title
        frequency
      }
    }
    completedQuestions {
      ...CompletedQuestionFragment
    }
    activeQuestion {
      ...ActiveQuestionFragment
    }
    result {
      correctAnswersCount
      correctAnswerScore
      timeBonus
      totalScore
    }
    leaderboardParticipantCount
    quizType
  }

  ${activeQuestionFragment}
  ${completedQuestionFragment}
`

const leagueQuizInstanceQuery = gql`
  query leagueQuizInstance($id: ID!) {
    leagueQuizInstance(id: $id) {
      id
      status
      leagueQuiz {
        id
        title
        slug
        viewerCanStartQuiz
        questionsCount
        participantCount
        embedPromotionalText
        league {
          id
          slug
          title
          frequency
        }
      }
      completedQuestions {
        ...CompletedQuestionFragment
      }
      activeQuestion {
        ...ActiveQuestionFragment
      }
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
      leaderboardParticipantCount
      quizType
    }
  }

  ${activeQuestionFragment}
  ${completedQuestionFragment}
`
export const useLeagueQuizInstanceQuery = (id: string, quizType?: string) => {
  if (quizType === 'careerPath') {
    const instance = useQuery<{
      careerPathQuizInstance?: CareerPathQuizInstance
    }>(careerPathQuizInstanceQuery, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    })
    return {
      ...instance,
      data: {
        leagueQuizInstance: instance.data?.careerPathQuizInstance,
      },
    }
  }

  return useQuery<{
    leagueQuizInstance?: LeagueQuizInstance
  }>(leagueQuizInstanceQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
}

type LeagueFinishedType = LeagueQuizMinimal & {
  leaderboard: LeaderboardItem[]
  viewerLeaderboardEntry?: LeaderboardItem
  friendsLeaderboard: LeaderboardItem[]
  league: {
    id: string
    slug: string
    recommendedQuiz: LeagueQuiz
  }
}

export const useLeagueQuizFinished = ({
  quizId,
  limit = 3,
}: {
  quizId: string
  limit?: number
}) => {
  return useQuery<{
    leagueQuiz?: LeagueFinishedType
  }>(
    gql`
      query leagueQuiz($quizId: ID!) {
        leagueQuiz(id: $quizId) {
          league {
            id
            slug
            title
            frequency
            recommendedQuiz {
              ...LeagueQuizShortFragment
            }
          }
          leaderboard(limit: ${limit}) {
            ...LeaderboardItem
          }
          viewerLeaderboardEntry {
            ...LeaderboardItem
          }
          friendsLeaderboard {
            ...LeaderboardItem
          }
        }
      }
      ${leaderboardItemFragment}
      ${leagueQuizShortFragment}
    `,
    {
      variables: { quizId, limit },
      fetchPolicy: 'cache-and-network',
    }
  )
}

// Start league
type StartLeagueQuizInput = {
  leagueQuizId: string
}
const startLeagueQuizMutation = gql`
  mutation startLeagueQuiz($input: StartLeagueQuizInput!) {
    startLeagueQuiz(input: $input) {
      id
    }
  }
`
export const useStartLeagueQuizMutation = () => {
  return useMutation<
    { startLeagueQuiz: { id: string } },
    { input: StartLeagueQuizInput }
  >(startLeagueQuizMutation)
}

// Next question
type NextQuestionInput = {
  leagueQuizInstanceId: string
  challengeQuizInstanceId?: string
  challengeMatchId?: string
}

const nextQuestionMutation = gql`
  mutation nextQuestion($input: NextQuestionInput!) {
    nextQuestion(input: $input) {
      ...LeagueQuizInstanceFragment
    }
  }

  ${leagueQuizInstanceFragment}
`
export const useNextQuestionMutation = () => {
  return useMutation<unknown, { input: NextQuestionInput }>(
    nextQuestionMutation
  )
}

// Answer question
export type AnswerQuestionInput = {
  leagueQuizInstanceId: string
  questionId: string
  alternativeId: string | null
  isLastQuestion?: boolean
}

const answerQuestionMutation = gql`
  mutation answerQuestion($input: AnswerQuestionInput!) {
    answerQuestion(input: $input) {
      ...LeagueQuizInstanceFragment
    }
  }

  ${leagueQuizInstanceFragment}
`
export const useAnswerQuestionMutation = () => {
  return useMutation<unknown, { input: AnswerQuestionInput }>(
    answerQuestionMutation
  )
}

export type ViewerDailyLeagueStats = {
  quizzesPlayed: number
  correctPercentage: number
  currentPlayStreak: number
  maxPlayStreak: number
  medals: {
    gold: number
    silver: number
    bronze: number
  }
}

export type ViewerDailyLeagueStatsResponse = {
  leagueQuizInstance?: {
    leagueQuiz?: {
      league?: {
        viewerDailyStats?: ViewerDailyLeagueStats
      }
    }
  }
}
export const useViewerDailyLeagueStats = (leagueQuizInstance: string) =>
  useQuery<ViewerDailyLeagueStatsResponse>(
    gql`
      query viewerDailyLeagueStats($leagueQuizInstance: ID!) {
        leagueQuizInstance(id: $leagueQuizInstance) {
          id
          leagueQuiz {
            id
            league {
              id
              viewerDailyStats {
                id
                quizzesPlayed
                correctPercentage
                currentPlayStreak
                maxPlayStreak
                medals {
                  gold
                  silver
                  bronze
                }
              }
            }
          }
        }
      }
    `,
    { variables: { leagueQuizInstance } }
  )

export const useViewerDailyStats = ({ enabled }: { enabled: boolean }) =>
  useQuery<{
    viewer: {
      dailyStats?: ViewerDailyLeagueStats
    }
  }>(
    gql`
      query viewerDailyStats {
        viewer {
          id
          dailyStats {
            id
            quizzesPlayed
            correctPercentage
            currentPlayStreak
            maxPlayStreak
            medals {
              gold
              silver
              bronze
            }
          }
        }
      }
    `,
    {
      skip: !enabled,
    }
  )

// Skip reading question
type SkipReadQuestionInput = {
  leagueQuizInstanceId: string
  questionId: string
}

const skipReadQuestionMutation = gql`
  mutation skipReadQuestion($input: SkipReadQuestionInput!) {
    skipReadQuestion(input: $input) {
      id
    }
  }
`

export const useSkipReadQuestionMutation = () => {
  return useMutation<
    { skipReadQuestion: { id: string | undefined } },
    { input: SkipReadQuestionInput }
  >(skipReadQuestionMutation)
}

type startChallengeQuizInput = {
  challengeInstanceId: string
}

const startChallengeQuizMutation = gql`
  mutation startChallengeQuiz($input: StartChallengeQuizInput!) {
    startChallengeQuiz(input: $input) {
      id
    }
  }
`
export const useStartChallengeQuizMutation = () => {
  return useMutation<
    { startChallengeQuiz: { id: string } },
    { input: startChallengeQuizInput }
  >(startChallengeQuizMutation)
}

type CheckBadgesAchievedInput = {
  leagueQuizInstanceId: string
}

export const badgeFragment = gql`
  fragment BadgeFragment on Badge {
    id
    dbId
    title
    imageUrl
    criteria {
      quizzesCompleted
    }
    requirementMessage
    earnedMessage
    league {
      id
    }
    progressBackgroundColor
    progressForegroundColor
    seenByUser
    earnedAt
    userId
  }
`

const checkAchievedBadgesMutation = gql`
  mutation checkBadgeAchieved($input: CheckBadgeAchievedInput!) {
    checkBadgeAchieved(input: $input) {
      ...BadgeFragment
    }
  }
  ${badgeFragment}
`
export const useCheckAchievedBadgesMutation = () => {
  return useMutation<
    { checkBadgeAchieved: Badge[] },
    { input: CheckBadgesAchievedInput }
  >(checkAchievedBadgesMutation)
}

const quizBySlugOrIdQuery = gql`
  query quizBySlugOrId($slugOrId: String!) {
    quizBySlugOrId(slugOrId: $slugOrId) {
      id
      title
      slug
      imageUrl
      viewerCanStartQuiz
      questionsCount
      availableFrom
      availableTo
      status
      participantCount

      league {
        id
        slug
        title
        imageUrl
        leagueCardImageUrl
        frequency
        viewerIsSubscribed
      }
      viewerData {
        canStartQuiz
        hasCompletedQuiz
        activeQuizInstance {
          id
        }
        result {
          leagueQuizInstanceId
          correctAnswersCount
          correctAnswerScore
          timeBonus
          totalScore
        }
        leaderboardEntry {
          rank
        }
      }
    }
  }
`

export const useQuizBySlugOrIdQuery = (slugOrId: string) => {
  return useQuery<{ quizBySlugOrId?: LeagueQuiz }>(quizBySlugOrIdQuery, {
    variables: { slugOrId },
    fetchPolicy: 'cache-and-network',
  })
}

export const useLeagueInstanceViewerRankingQuery = (
  leagueQuizInstanceId: string
) => {
  return useQuery<{
    leagueQuizInstance?: {
      leaderboardParticipantCount: number
      viewerLeaderboardEntry?: {
        rank: number
      }
    }
  }>(
    gql`
      query leagueQuizInstance($leagueQuizInstanceId: ID!) {
        leagueQuizInstance(id: $leagueQuizInstanceId) {
          id
          leaderboardParticipantCount
          viewerLeaderboardEntry {
            rank
          }
        }
      }
    `,
    {
      variables: { leagueQuizInstanceId },
      fetchPolicy: 'cache-and-network',
    }
  )
}

// CareerPath

export type CareerPathQuestionAnswer = {
  id: string
  playerId: string | undefined
  playerName: string
  photoUrl: string
  nationality: string
  position: string
  team: CareerPathTeam
  correct: boolean
  startedAt: string
  answeredAt: string
}

export type CompletedCareerPathQuestion = {
  id: string
  name: string
  team: CareerPathTeam
  photo: string
  nationality: string
  position: string
  careerPath: CareerPathEntry[]
  answers: CareerPathQuestionAnswer[]
}

export type ActiveCareerPathQuestion = {
  id: string
  name: string
  team: CareerPathTeam
  photo: string
  nationality: string
  position: string
  careerPath: CareerPathEntry[]
  answers: CareerPathQuestionAnswer[]
}

export type CareerPathQuizInstanceResult = {
  correctAnswersCount: number
  totalScore: number
}

export type CareerPathQuizInstance = {
  id: string
  status: LeagueQuizInstanceStatus
  leagueQuiz: LeagueQuiz
  activeQuestion: ActiveCareerPathQuestion | null
  completedQuestions: CompletedCareerPathQuestion[]
  result: CareerPathQuizInstanceResult
  leaderboardParticipantCount: number | null
  quizType: QuizType
}

export const careerPathEntryFragment = gql`
  fragment CareerPathEntryFragment on CareerPathEntry {
    team {
      id
      name
      logoUrl
    }
    type
    year
  }
`

export const activeCareerPathQuestionFragment = gql`
  fragment ActiveCareerPathQuestionFragment on ActiveCareerPathQuestion {
    id
    name
    team {
      id
      name
      logoUrl
      league {
        id
        name
        logoUrl
      }
    }
    photo
    nationality
    position
    careerPath {
      ...CareerPathEntryFragment
    }
    answers {
      id
      playerId
      playerName
      photoUrl
      nationality
      position
      team {
        id
        name
        logoUrl
        league {
          id
          name
          logoUrl
        }
      }
      correct
      startedAt
      answeredAt
    }
  }

  ${careerPathEntryFragment}
`

export const completedCareerPathQuestionFragment = gql`
  fragment CompletedCareerPathQuestionFragment on CompletedCareerPathQuestion {
    id
    name
    team {
      id
      name
      logoUrl
      league {
        id
        name
        logoUrl
      }
    }
    nationality
    position
    careerPath {
      ...CareerPathEntryFragment
    }
    answers {
      id
      playerId
      playerName
      photoUrl
      nationality
      position
      team {
        id
        name
        logoUrl
        league {
          id
          name
          logoUrl
        }
      }
      correct
      startedAt
      answeredAt
    }

    photo
  }
  ${careerPathEntryFragment}
`

const careerPathQuizInstanceQuery = gql`
  query careerPathQuizInstance($id: ID!) {
    careerPathQuizInstance(id: $id) {
      id
      status
      leagueQuiz {
        id
        title
        slug
        viewerCanStartQuiz
        questionsCount
        participantCount
        embedPromotionalText
        league {
          id
          slug
          title
          frequency
        }
      }
      completedQuestions {
        ...CompletedCareerPathQuestionFragment
      }
      activeQuestion {
        ...ActiveCareerPathQuestionFragment
      }
      result {
        correctAnswersCount
        totalScore
      }
      leaderboardParticipantCount
      quizType
    }
  }

  ${activeCareerPathQuestionFragment}
  ${completedCareerPathQuestionFragment}
`

// Start CareerPathQuiz

const startCareerPathQuizMutation = gql`
  mutation startCareerPathQuiz($input: StartLeagueQuizInput!) {
    startCareerPathQuiz(input: $input) {
      id
    }
  }
`

export const useStartCareerPathQuizMutation = () => {
  return useMutation<
    { startCareerPathQuiz: { id: string } },
    { input: StartLeagueQuizInput }
  >(startCareerPathQuizMutation)
}

// Next Question

type NextCareerPathQuestionInput = {
  leagueQuizInstanceId: string
}

const nextCareerPathQuestionMutation = gql`
  mutation nextCareerPathQuizQuestion($input: NextCareerPathQuestionInput!) {
    nextCareerPathQuizQuestion(input: $input) {
      id
      completedQuestions {
        ...CompletedCareerPathQuestionFragment
      }
      activeQuestion {
        ...ActiveCareerPathQuestionFragment
      }
      result {
        correctAnswersCount
        totalScore
      }
    }
  }

  ${activeCareerPathQuestionFragment}
  ${completedCareerPathQuestionFragment}
`

export const useNextCareerPathQuestionMutation = () => {
  return useMutation<unknown, { input: NextCareerPathQuestionInput }>(
    nextCareerPathQuestionMutation
  )
}

// Answer CareerPathQuizQuestion

type AnswerCareerPathQuestionInput = {
  leagueQuizInstanceId: string
  questionId: string
  playerId: string
  skip: boolean
}

const answerCareerPathQuizQuestionMutation = gql`
  mutation answerCareerPathQuizQuestion(
    $input: AnswerCareerPathQuestionInput!
  ) {
    answerCareerPathQuizQuestion(input: $input) {
      id
      completedQuestions {
        ...CompletedCareerPathQuestionFragment
      }
      activeQuestion {
        ...ActiveCareerPathQuestionFragment
      }
      result {
        correctAnswersCount
        totalScore
      }
    }
  }

  ${activeCareerPathQuestionFragment}
  ${completedCareerPathQuestionFragment}
`

export const useAnswerCareerPathQuizQuestionMutation = () => {
  const [answerCareerPathQuizQuestion] = useMutation<
    any,
    { input: AnswerCareerPathQuestionInput }
  >(answerCareerPathQuizQuestionMutation)
  return [answerCareerPathQuizQuestion]
}

const viewerLeagueQuizInstanceQuery = gql`
  query viewerLeagueQuizInstance($quizId: String!) {
    viewerLeagueQuizInstance(quizId: $quizId) {
      id
    }
  }
`

export const useViewerLeagueQuizInstance = ({
  quizId,
  skip,
}: {
  quizId: string
  skip: boolean
}) => {
  return useQuery<{
    viewerLeagueQuizInstance?: {
      id: string
    }
  }>(viewerLeagueQuizInstanceQuery, {
    variables: { quizId },
    fetchPolicy: 'cache-and-network',
    skip,
  })
}
