/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Flex, FlexProps } from './Flex'

type PageContentWidth = 'large' | 'narrow'

export const LARGE_WIDTH = 720
const NARROW_WIDTH = 590
export const LARGE_SCREEN_PADDING = 32
export const SMALL_SCREEN_PADDING = 24
export const SUPER_SMALL_SCREEN_PADDING = 12
export const LARGE_WIDTH_WITH_PADDING = LARGE_WIDTH + LARGE_SCREEN_PADDING * 2
export const NARROW_WIDTH_WITH_PADDING = NARROW_WIDTH + LARGE_SCREEN_PADDING * 2

export type PageContentProps = {
  width?: PageContentWidth
  noInnerPadding?: boolean
} & Omit<FlexProps, 'size'>

export const PageContent = ({
  width = 'large',
  noInnerPadding = false,
  ...rest
}: PageContentProps) => {
  return (
    <Flex
      column
      css={[
        css`
          width: ${width === 'large'
            ? `${LARGE_WIDTH_WITH_PADDING}px`
            : `${NARROW_WIDTH_WITH_PADDING}px`};
          margin-left: auto;
          margin-right: auto;
          padding-left: ${noInnerPadding ? 0 : LARGE_SCREEN_PADDING}px;
          padding-right: ${noInnerPadding ? 0 : LARGE_SCREEN_PADDING}px;
          position: relative;

          @media (max-width: ${width === 'large'
              ? `${LARGE_WIDTH_WITH_PADDING - 1}px`
              : `${NARROW_WIDTH_WITH_PADDING - 1}px`}) {
            width: 100%;
          }

          @media (max-width: 599px) {
            padding-left: ${noInnerPadding ? 0 : SMALL_SCREEN_PADDING}px;
            padding-right: ${noInnerPadding ? 0 : SMALL_SCREEN_PADDING}px;
          }

          @media (max-width: 350px) {
            padding-left: ${noInnerPadding ? 0 : SUPER_SMALL_SCREEN_PADDING}px;
            padding-right: ${noInnerPadding ? 0 : SUPER_SMALL_SCREEN_PADDING}px;
          }
        `,
      ]}
      {...rest}
    />
  )
}
