import { ReactComponent as Sleeves0 } from './Sleeves 0.svg'
import { ReactComponent as SleevesA } from './Sleeves A.svg'
import { ReactComponent as SleevesC } from './Sleeves C.svg'
import { ReactComponent as SleevesD } from './Sleeves D.svg'
import { ReactComponent as SleevesE } from './Sleeves E.svg'
import { ReactComponent as SleevesF } from './Sleeves F.svg'

export const sleevesStyles = {
  Sleeves0: {
    icon: <Sleeves0 />,
    usedColors: 0,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  SleevesA: {
    icon: <SleevesA />,
    usedColors: 1,
    width: 30,
    centerIndex: 0,
    rotation: 0,
    offset: 0,
    premium: false,
  },
  SleevesC: {
    icon: <SleevesC />,
    usedColors: 3,
    width: 12,
    centerIndex: 0,
    rotation: 15,
    offset: -26,
    premium: false,
  },
  SleevesE: {
    icon: <SleevesE />,
    usedColors: 2,
    width: 12,
    centerIndex: 1,
    rotation: 15,
    offset: 10,
    premium: false,
  },
  SleevesD: {
    icon: <SleevesD />,
    usedColors: 2,
    width: 30,
    centerIndex: 0,
    rotation: 15,
    offset: -5,
    premium: false,
  },
  SleevesF: {
    icon: <SleevesF />,
    usedColors: 2,
    width: 32,
    centerIndex: undefined,
    rotation: 15 + 180, //reverses color order
    offset: 0,
    premium: false,
  },
}
