import { useState } from 'react'
import { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

export const useCrop = ({
  minHeight,
  minWidth,
}: {
  minHeight: number
  minWidth: number
}) =>
  useState<Crop>({
    aspect: minWidth / minHeight,
    unit: '%',
  })

export function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop
): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width!
    canvas.height = crop.height!
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return reject('Unable to create canvas')
    }
    const pixelRatio = window.devicePixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = true
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    )

    canvas.toBlob(resolve, 'image/png', 1)
  })
}
