/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { ToggleSwitch } from '../../components/ToggleSwitch'
import { useT } from '../../lib/i18n/useT'
import { margin } from '../../styles/margin'
import {
  useNotificationSettings,
  UserNotificationSetting,
  useSetNotificationSettingMutation,
} from '../EditProfile/queries'
import { LoginModalInner } from '../../components/modal/LoginModalInner'
import { Modal } from '../../components/modal/Modal'

export const EnableNotifications = ({
  textColor = 'white',
  viewerIsAnonymous = false,
}: {
  textColor?: string
  viewerIsAnonymous?: boolean
}) => {
  const { data, loading } = useNotificationSettings()
  const settings = data?.viewer?.notificationSettings

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!show) {
      // only show anything if viewer hasn't enabled notifications
      if (settings && !settings.emailNewLeagueQuiz) {
        setShow(true)
      }
    }
  }, [settings, show])

  if (!show || loading || !settings) {
    return null
  }

  return (
    <ToggleWrapper
      initialEmailNewLeagueQuiz={settings.emailNewLeagueQuiz}
      textColor={textColor}
      viewerIsAnonymous={viewerIsAnonymous}
    />
  )
}

const ToggleWrapper = ({
  initialEmailNewLeagueQuiz,
  textColor = 'white',
  viewerIsAnonymous = false,
}: {
  initialEmailNewLeagueQuiz: boolean
  textColor?: string
  viewerIsAnonymous?: boolean
}) => {
  const t = useT()
  const [emailNewLeagueQuiz, setEmailNewLeagueQuiz] = useState(
    initialEmailNewLeagueQuiz
  )
  const [setNotificationSettingMutation] = useSetNotificationSettingMutation()

  const [modalIsOpen, setModalIsOpen] = useState(false)

  if (modalIsOpen) {
    return (
      <Modal closeModal={() => setModalIsOpen(false)}>
        <LoginModalInner closeModal={() => setModalIsOpen(false)} />
      </Modal>
    )
  }

  return (
    <ToggleSwitch
      value={emailNewLeagueQuiz}
      onValue={(value) => {
        if (viewerIsAnonymous) {
          setModalIsOpen(true)
          return
        }

        setEmailNewLeagueQuiz(value)

        setNotificationSettingMutation({
          variables: {
            input: {
              setting: UserNotificationSetting.emailNewLeagueQuiz,
              value: value,
            },
          },
        })
      }}
      label={t(
        'Do you want to receive notifications about new quizzes on email?'
      )}
      textColor={textColor}
      css={margin.top('huge')}
    />
  )
}
