/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from '@emotion/react'
import { memoize } from 'lodash'
import { ComponentPropsWithRef, forwardRef } from 'react'
import { useAuthContext } from '../lib/AuthContext'
import { buttonReset, prettyLetterSpacing } from '../styles/styles'
import { colors, hexOpacity } from '../styles/theme'
import { Flex } from './layout/Flex'
import { Loader } from './Loader'
import { LoginModalInner } from './modal/LoginModalInner'
import { ModalTrigger } from './modal/Modal'
import { Text, styleBySize as textStyleBySize } from './Text'
import { TextNew } from './TextNew'

type ButtonSize = 'small' | 'medium'
export type ButtonVariant =
  | 'green'
  | 'lightGreen'
  | 'white'
  | 'dangerRed'
  | 'black'
  | 'grey'
type ButtonProps = {
  variant?: ButtonVariant
  size?: ButtonSize
  outline?: boolean
  loading?: boolean
  resetStyling?: boolean
  signUpRequired?: boolean
  rounded?: boolean
  shadow?: boolean
  noWrap?: boolean
} & Omit<ComponentPropsWithRef<'button'>, 'color'>

const defaultButtonStyles = css`
  position: relative;
  padding: 6px 16px;
  padding-bottom: 8px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    cursor: initial;
  }
`
const buttonStyles = memoize(
  (
    variant: ButtonVariant,
    size: ButtonSize,
    outline: boolean,
    disabled: boolean,
    rounded: boolean,
    shadow: boolean,
    noWrap: boolean
  ) => {
    const styles: SerializedStyles[] = []

    if (shadow) {
      styles.push(css`
        box-shadow: 0px 4px 4px ${hexOpacity(colors.black, 0.25)};
      `)
    }

    if (size === 'small') {
      styles.push(css`
        padding: 1px 8px;
        padding-bottom: 3px;
        border-radius: 4px;
      `)
    }

    if (rounded) {
      styles.push(css`
        border-radius: 100px;
      `)
    }

    if (noWrap) {
      styles.push(css`
        white-space: nowrap;
      `)
    }

    if (disabled) {
      styles.push(css`
        border: 1px solid ${colors.grey400};
      `)
    } else if (variant === 'green') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid ${colors.green500};
        `)
      } else {
        styles.push(css`
          background-color: ${colors.green500};
          border: 1px solid ${colors.green500};
        `)
      }
    } else if (variant === 'lightGreen') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid #85f49d;
          border-radius: 7px;
        `)
      } else {
        styles.push(css`
          background-color: #85f49d;
          border: 1px solid #85f49d;
          box-shadow: 0px 4px #64bb77;
          margin-top: -4px;
          margin-bottom: 4px;
          border-radius: 7px;
        `)
        // If margin-bottom is not working, then you must increase the height
        // of the container to make the button respect margin-bottom
      }
    } else if (variant === 'white') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid white;
        `)
      } else {
        styles.push(css`
          background-color: white;
          border: 1px solid white;
        `)
      }
    } else if (variant === 'dangerRed') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid ${colors.red400};
        `)
      } else {
        styles.push(css`
          background-color: ${colors.red400};
          border: 1px solid ${colors.red400};
        `)
      }
    } else if (variant === 'black') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid black;
        `)
      } else {
        styles.push(css`
          background-color: black;
          border: 1px solid black;
        `)
      }
    } else if (variant === 'grey') {
      if (outline) {
        styles.push(css`
          background-color: transparent;
          border: 1px solid #323232;
        `)
      } else {
        styles.push(css`
          background-color: #323232;
          border: 1px solid #323232;
        `)
      }
    }

    return styles
  },
  (...args) => args.map(String).join('-')
)

const textStyles = memoize(
  (
    variant: ButtonVariant,
    size: ButtonSize,
    outline: boolean,
    disabled: boolean
  ) => {
    const styles = [textStyleBySize[size]]

    if (disabled) {
      styles.push(css`
        color: ${colors.grey400};
        border-color: ${colors.grey400};
      `)
    } else if (variant === 'green') {
      if (outline) {
        styles.push(css`
          color: ${colors.green500};
        `)
      } else {
        styles.push(css`
          color: white;
        `)
      }
    } else if (variant === 'lightGreen') {
      if (outline) {
        styles.push(css`
          color: #85f49d;
        `)
      } else {
        styles.push(css`
          color: #095218;
        `)
      }
    } else if (variant === 'white') {
      if (outline) {
        styles.push(css`
          color: white;
        `)
      } else {
        styles.push(css`
          color: black;
        `)
      }
    } else if (variant === 'dangerRed') {
      if (outline) {
        styles.push(css`
          color: ${colors.red400};
        `)
      } else {
        styles.push(css`
          color: white;
        `)
      }
    } else if (variant === 'black') {
      if (outline) {
        styles.push(css`
          color: black;
        `)
      } else {
        styles.push(css`
          color: white;
        `)
      }
    } else if (variant === 'grey') {
      if (outline) {
        styles.push(css`
          color: black;
        `)
      } else {
        styles.push(css`
          color: white;
        `)
      }
    }

    return styles
  },
  (...args) => args.map(String).join('-')
)

const LoadingOverlay = ({ rounded = false }: { rounded?: boolean }) => {
  return (
    <Flex
      horizontal="center"
      vertical="center"
      css={css`
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border-radius: ${rounded ? '100px' : '8px'};
        background-color: rgba(255, 255, 255, 0.5);
        cursor: wait;
      `}
    >
      <Loader size={20} delay={0} />
    </Flex>
  )
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const {
      variant = 'black',
      size = 'medium',
      outline = false,
      loading,
      disabled = false,
      className,
      children,
      resetStyling = false,
      signUpRequired = false,
      rounded = false,
      shadow = false,
      noWrap = false,
      ...rest
    } = props

    const resetOnly = resetStyling ? buttonReset : undefined

    const { authUser } = useAuthContext()

    if (
      (signUpRequired && !authUser) || // users must be signed in
      (signUpRequired && authUser && authUser.isAnonymous) // users must be signed in with a non-anonymous account
    ) {
      return (
        <ModalTrigger
          button={({ openModal }) => {
            return (
              <button
                ref={ref}
                type="button"
                disabled={disabled || loading}
                css={
                  resetOnly || [
                    buttonReset,
                    defaultButtonStyles,
                    buttonStyles(
                      variant,
                      size,
                      outline,
                      disabled,
                      rounded,
                      shadow,
                      noWrap
                    ),
                  ]
                }
                className={className}
                {...rest}
                onClick={openModal}
              >
                {typeof children === 'string' ? (
                  <Text css={textStyles(variant, size, outline, disabled)}>
                    {children}
                  </Text>
                ) : (
                  children
                )}
                {loading && <LoadingOverlay rounded={rounded} />}
              </button>
            )
          }}
          modal={({ closeModal }) => (
            <LoginModalInner closeModal={closeModal} />
          )}
        />
      )
    }

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled || loading}
        css={
          resetOnly || [
            buttonReset,
            defaultButtonStyles,
            buttonStyles(
              variant,
              size,
              outline,
              disabled,
              rounded,
              shadow,
              noWrap
            ),
          ]
        }
        className={className}
        {...rest}
      >
        {typeof children === 'string' ? (
          <TextNew css={textStyles(variant, size, outline, disabled)}>
            {children}
          </TextNew>
        ) : (
          children
        )}
        {loading && <LoadingOverlay rounded={rounded} />}
      </button>
    )
  }
)

export const VariableButton = (
  props: {
    buttonType: 'primary' | 'secondary'
    signUpRequired?: boolean
    children: any
  } & (PrimaryButtonProps | SecondaryButtonProps)
) => {
  const { buttonType, children, ...rest } = props

  if (buttonType == 'primary') {
    return <PrimaryButton {...rest}>{props.children}</PrimaryButton>
  } else {
    return <SecondaryButton {...rest}>{props.children}</SecondaryButton>
  }
}

export type PrimaryButtonProps = Omit<ButtonProps, 'outline'>
export const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  function FilledButton(props, ref) {
    return <Button {...props} outline={!!props.disabled} ref={ref} />
  }
)

export type SecondaryButtonProps = Omit<ButtonProps, 'outline'>
export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  SecondaryButtonProps
>(function FilledButton(props, ref) {
  return <Button {...props} outline={true} ref={ref} />
})

export const greenButtonStyle = css`
  background-color: ${colors.green300};
  border: 2px solid ${colors.green400};
  box-shadow: 0px 4px 0px ${colors.green500};
  border-radius: 50px;
  padding: 6px 32px;
`

export const GreenButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function FilledButton(props, ref) {
    return (
      <Button {...props} ref={ref} css={[greenButtonStyle, props.className]}>
        {typeof props.children === 'string' ? (
          <TextNew
            extraStrong
            wide
            italic
            css={css`
              font-size: 17px;
              line-height: 20px;
              white-space: nowrap;
            `}
          >
            {props.children}
          </TextNew>
        ) : (
          props.children
        )}
      </Button>
    )
  }
)

export const IconButton = (
  props: {
    icon?: React.ReactNode
    text: string | React.ReactNode
    iconLeft?: boolean
    signUpRequired?: boolean
    backgroundColor?: string
    gap?: number
    iconBottomMargin?: number
    textColor?: string
    borderColor?: string
    minHeight?: number
  } & (PrimaryButtonProps | SecondaryButtonProps)
) => {
  const {
    backgroundColor = colors.black,
    textColor = colors.white,
    borderColor,
    text,
    iconLeft = false,
    gap = 8,
    iconBottomMargin = 0,
    icon,
    minHeight = 48,

    ...rest
  } = props

  const textIsString = typeof text === 'string'

  const content = () => {
    if (rest.loading) {
      return (
        <Flex
          horizontal="center"
          vertical="center"
          css={css`
            height: ${minHeight}px;
            min-width: ${minHeight}px;
          `}
        >
          <Loader size={20} delay={0} />
        </Flex>
      )
    }

    return (
      <Flex
        css={css`
          flex-direction: ${props.iconLeft ? 'row-reverse' : 'row'};
          padding: 8px 16px;
          min-height: ${minHeight}px;
        `}
        horizontal="space-between"
        vertical="center"
        gap={gap}
      >
        <Flex
          horizontal="center"
          css={css`
            flex: 1;
          `}
        >
          {textIsString ? (
            <TextNew
              weight={700}
              color={textColor}
              css={[
                prettyLetterSpacing,
                css`
                  font-size: 16px;
                  line-height: 20px;
                  white-space: nowrap;
                `,
              ]}
            >
              {text}
            </TextNew>
          ) : (
            text
          )}
        </Flex>
        {icon && (
          <Flex
            css={css`
              color: ${textColor};
              height: 100%;
              aspect-ratio: 1;
              margin-bottom: ${iconBottomMargin}px;
            `}
            horizontal="center"
            vertical="center"
          >
            {icon}
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <Button
      {...rest}
      css={css`
        background-color: ${backgroundColor};
        padding: 0;
        border: ${borderColor ? `1px solid ${borderColor}` : 'none'};
      `}
    >
      {content()}
    </Button>
  )
}
