/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { Flex } from '../../../components/layout/Flex'
import { ClubLogo } from '../../../components/Picture'
import { FcQuizQrCode } from '../../../components/QrCodeComponent'
import { TextNew } from '../../../components/TextNew'
import { useT } from '../../../lib/i18n/useT'
import { Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useNow } from '../../../utils/useNow'
import {
  calculateTimeRemaining,
  noTimeRemaining,
  timeRemainingToString,
  viewWidthToNumber,
} from '../../Admin/Utils'
import {
  getIconImageForClub,
  getTextColorForClub,
} from '../../Challenge/DisplayPage/util'
import { LiveQuizDisplayInstance } from '../../LiveQuiz/queries'
import { useDisplayInstanceParticipantQuery } from './JoinableDisplayLiveQuiz'

export const PendingDisplayLiveQuiz = ({
  instance,
  isLandscape,
}: {
  instance: LiveQuizDisplayInstance
  isLandscape: boolean
}) => {
  const club = instance.club
  const joinUrl = `${window.location.protocol}//${window.location.host}/join/${instance.invitationCode}`

  const participantQuery = useDisplayInstanceParticipantQuery(instance.id)

  useEffect(() => {
    participantQuery.startPolling(2000)

    return () => participantQuery.stopPolling()
  }, [])

  const participantData = participantQuery.data?.getLiveQuizDisplayInstance

  const textColor = club ? getTextColorForClub(club) : colors.black

  const now = useNow({ updateFrequencyInMs: 1000 })

  const remaining = calculateTimeRemaining(now, new Date(instance.startTime))

  const t = useT(instance.locale)

  const noRemainingTime = noTimeRemaining(remaining)

  const participantCount = participantData?.participantCount || 0

  const iconUrl = club && getIconImageForClub(club)

  return (
    <Flex
      column
      vertical="center"
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
      `}
    >
      <TextNew
        italic
        condensed
        shadow
        color={textColor}
        css={css`
          position: absolute;
          top: 5vh;
          right: 1vw;
          font-size: 3vw;
          line-height: 0;
          font-weight: 600;
          margin-right: 1vw;
        `}
      >
        {t('{{count}} player', {
          count: participantCount,
        })}
      </TextNew>

      {iconUrl && (
        <ClubLogo
          url={iconUrl}
          size={viewWidthToNumber('10vw')}
          shape={club.logoShape}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          frameColor={textColor}
        />
      )}

      <Spacer
        css={css`
          height: 5vh;
        `}
      />

      <Flex
        column={!isLandscape}
        vertical={isLandscape ? 'center' : 'flex-start'}
        horizontal="center"
        css={css`
          width: 100%;
          height: ${isLandscape ? '50%' : '100%'};
        `}
      >
        <Flex
          horizontal="center"
          column
          css={[
            css`
              flex: 1;
              height: 100%;
              aspect-ratio: 1;
              max-height: 50vh;
            `,
            !isLandscape &&
              css`
                flex: auto;
                aspect-ratio: 1;
              `,
          ]}
        >
          <TextNew
            condensed
            shadow
            narrowShadow
            color={textColor}
            css={css`
              font-size: 3vw;
              font-weight: 600;
            `}
          >
            {t(`Live kode:`)}
          </TextNew>
          <TextNew
            condensed
            shadow
            narrowShadow
            color={textColor}
            css={css`
              font-size: 3vw;
              font-weight: 600;
              letter-spacing: 0.1em;
            `}
          >
            {instance.invitationCode}
          </TextNew>
          <FcQuizQrCode
            url={joinUrl}
            backgroundColor={club?.backgroundColor ?? colors.white}
            foregroundColor={textColor}
            logoScale={3}
            hideFcQuizLogo
            hideBoxBackground
            css={css`
              padding: 16px;
            `}
          />
        </Flex>
        <Flex
          column
          horizontal="center"
          css={[
            css`
              flex: 1;
            `,

            !isLandscape &&
              css`
                flex: auto;
              `,
          ]}
        >
          <TextNew
            italic
            condensed
            shadow
            color={textColor}
            css={css`
              font-size: 5vw;
              font-weight: 600;
            `}
          >
            {t(`Live-Quiz starts in:`)}
          </TextNew>
          {noRemainingTime ? (
            <TextNew
              italic
              condensed
              shadow
              color={textColor}
              css={css`
                font-size: 9vw;
                font-weight: 600;
              `}
            >
              {t(`Soon`)}
            </TextNew>
          ) : (
            <TextNew
              italic
              condensed
              shadow
              color={textColor}
              css={css`
                font-size: 9vw;
                font-weight: 600;
              `}
            >
              {timeRemainingToString(remaining, t)}
            </TextNew>
          )}
          <Spacer
            css={css`
              height: 2vh;
            `}
          />
          <TextNew
            italic
            condensed
            shadow
            color={textColor}
            textAlign="center"
            css={css`
              font-size: 3vw;
              font-weight: 600;
            `}
          >
            {t(`Sign up and get ready to play!`)}
          </TextNew>
        </Flex>
      </Flex>
    </Flex>
  )
}
