/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Heading } from '../../components/Heading'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'
import { Text } from '../../components/Text'
import { TermsHeader } from './TermsHeader'

const Content = () => {
  return (
    <div
      css={css`
        section {
          margin-bottom: 32px;
        }

        h2 {
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 12px;
        }

        ul {
          list-style: inside;
        }

        li {
          margin-bottom: 4px;
        }
      `}
    >
      <section>
        <Heading level={1}>Terms of service</Heading>
        <Text>
          <p>Updated 02/02/2022</p>
          <p>
            These Terms of Service (the “Terms”) govern your access and use of{' '}
            <Text strong>FC Quiz</Text> (the “Service”).
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          The Agreement
        </Heading>
        <Text>
          <p>By accessing the Service:</p>
          <ul>
            <li>
              You accept these Terms and enter into a legally binding agreement
              (the “Agreement”) with FC Quiz AS, St. Halvards Plass, 0192 Oslo,
              Norway, org. Nr. 927 208 407 (the “Provider”, “we”, “us”).
            </li>
            <li>
              You accept that the Agreement is entered into on an electronic
              medium, and that the same applies to changes in the Agreement.
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          The Service
        </Heading>
        <Text>
          <p>
            The Service allows users of the website and app to participate in
            quizzes and related activities individually or in a social or
            competitive context. The service also allows third parties to create
            and host quizzes and related activities on the service.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Service Access
        </Heading>
        <Text>
          <p>
            To access the Service users sign up on www.fcquiz.app website and/or
            in the Fc Quiz App on one of several platform App Stores.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Accounts
        </Heading>
        <Text>
          <p>
            If you create an account in the Service, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it.
          </p>
          <p>
            You must immediately notify us of any unauthorized uses of your
            account or any other breaches of security.
          </p>
          <p>
            If and as permitted by applicable law, we may, but have no
            obligation to:
          </p>
          <ul>
            <li>
              Ask you to provide a form of government identification (e.g.
              driver{"'"}s license, passport, or such) or other information
            </li>
            <li>
              Undertake checks designed to help verify your identity or
              background
            </li>
            <li>
              Screen you against third-party databases or other sources and
              request reports from service providers.{' '}
            </li>
          </ul>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Acceptable use
        </Heading>
        <Text>
          <p>
            The Service may only be used for lawful purposes, and in accordance
            with these Terms.
          </p>
          <p>
            Harassment, abuse, insults, harm, defame, slander, disparage,
            intimidation or discrimination based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin or disability, as
            well as submitting false or misleading information, is prohibited.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Service Level
        </Heading>
        <Text>
          <p>
            Subject to the terms of this Agreement, the Provider will use
            commercially reasonable efforts to provide the Service.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Duration and Termination
        </Heading>
        <Text>
          <p>
            The Agreement will remain in effect for as long as you have access
            to the Service.
          </p>
          <p>
            The Agreement may be terminated by either party, subject to a 7-day
            written termination notice.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Pricing
        </Heading>
        <Text>
          <p>The Service is currently provided free of charge.</p>
          <p>
            The Provider reserves the right to change the pricing, subject to a
            30-day notice.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Intellectual property rights
        </Heading>
        <Text>
          <p>
            “Intellectual Property Rights” means all present and future rights
            conferred by statute, common law or equity in or in relation to any
            copyright and related rights, trademarks, designs, patents,
            inventions, goodwill and the right to sue for passing off, rights to
            inventions, rights to use, and all other intellectual property
            rights, in each case whether registered or unregistered.
          </p>
          <p>
            The Provider shall own and retain all ownership rights, title and
            interest in the Service, including enhancements made in conjunction
            with this Agreement.Your use of the Service grants you no right or
            license to reproduce or otherwise use any of our or third-party
            trademarks.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Transfer of rights
        </Heading>
        <Text>
          <p>
            You may not assign, resell, sub-license or otherwise transfer or
            delegate any of your rights or obligations hereunder without our
            prior written consent.
          </p>
          <p>
            The Provider may transfer and assign any of its rights and
            obligations under this Agreement without consent.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Changes to these Terms
        </Heading>
        <Text>
          <p>
            The Provider reserves the right to change these Terms, effective
            upon posting of an updated version of this Agreement on the
            Website..
          </p>
          <p>
            In the event of changes, Customers will receive advance notice. In
            the event that such an amendment results in a negative effect for
            you, you shall be notified no later than 30 days before the
            amendment comes into force.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Disclaimer
        </Heading>
        <Text>
          <p>
            The Provider disclaims all warranties, express or implied. The
            Provider does not warrant that the Service will be uninterrupted or
            error free; nor does it make any warranty as to the results that may
            be obtained from use of the Service.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Limitation of liability
        </Heading>
        <Text>
          <p>
            As permitted by governing law, the Provider and its suppliers,
            officers, affiliates, representatives, contractors and employees,
            shall not be held responsible or liable for any loss or cost
            associated with the use of the Service.
          </p>
          <p>
            You agree to indemnify and hold the Provider and its affiliates,
            directors, officers, employees, agents, suppliers and licensors
            harmless from and against any liabilities, losses, damages or costs,
            including reasonable attorneys{"'"} fees, incurred in connection
            with or arising from any third party allegations, claims, actions,
            disputes or demands asserted against them as a result of or relating
            to content, your use of the website and Services or any willful
            misconduct on your part.
          </p>
        </Text>
      </section>

      <section>
        <Heading level={2} looksLikeLevel={3}>
          Governing law
        </Heading>
        <Text>
          <p>
            The Agreement shall be subject to and interpreted in accordance with
            Norwegian law, with Oslo city court (Oslo Tingrett) as legal venue.
          </p>
        </Text>
      </section>
    </div>
  )
}

export const TermsPage = () => {
  return (
    <Layout hideNavbar>
      <TermsHeader />

      <PageContent>
        <Content />
      </PageContent>
    </Layout>
  )
}
